import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from "react-intl";
import classnames from "classnames";

import "../../styles/components/_tooltip.scss";

class TextTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.toggle = this.toggle.bind(this);
    }

    render() {
        
        let primaryCardTranslation = this.props.intl.formatMessage({id: this.props.content});
        return (
            <div className={classnames("tooltip-text", this.props.className)} 
                onClick={this.toggle}
                title={this.state.show ? "" : primaryCardTranslation}>
                <FormattedMessage id={this.props.label} />
                {this.state.show &&
                    <p className="tooltip-text__content">{primaryCardTranslation}</p>
                }
            </div>
        );
    }

    toggle() {
        this.setState({show: !this.state.show});
    }
}


TextTooltip.propTypes = {
    label: PropTypes.string,
    content: PropTypes.string
};

export default injectIntl(TextTooltip);
