import { Grid, GridCell } from '@rmwc/grid';
import BackButton from '../components/common/BackButton';
import { ContractsPath } from '../const/routes';
import Loader from '../components/common/Loader';
import React from 'react';
import BaseFormComponent from '../components/common/BaseFormComponent';
import auth from '../auth/authenticate';
import AlertMessage from '../components/common/AlertMessage';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button } from '@rmwc/button';
import { InvoiceFrequency } from '../const/Invoice';
import { Typography } from '@rmwc/typography';
import InfoTooltip from '../components/common/InfoTooltip';
import CheckboxField from '../components/common/CheckboxField';
import PartnerApiForm from '../components/PartnerApi/PartnerApiForm';
import BaseDialog from '../components/common/BaseDialog';
import { bindActionCreators } from 'redux';
import { fetchContract } from '../actions/contractActions';
import {
    disablePartnerApiAccess,
    enablePartnerApiAccess,
} from '../actions/partnerApiActions';
import { connect } from 'react-redux';
import moment from 'moment/moment';

class EvChargingContractDetails extends BaseFormComponent {
    constructor(props) {
        super(props);

        this.state = {
            isPartnerApiDialogOpen: false,
            showSuccessResponse: false,
            isCompany: auth.isCompany() || false,
            isContractActive: false,
            infoEmail: "",
            invoiceEmail: "",
            deliverEmail: false,
            deliverMail: false,
            deliverEinvoice: false,
            deliverLimitedEinvoice: false,
            serviceRequired: false,
            thiAdded: false,
            evChargingCompensationPaymentReferenceNumber: "",
            evChargingCompensationBankAccountNumber: "",
            evChargingCompensationMargin: "",
            end: "",
            renewPeriod: ""
        };
    }

    componentDidMount() {
        if (!this.props.fetchingContract && !this.props.fetchedContract) {
            this.props.fetchContract(this.props.match.params.id).then(() => {
                const contract = this.props.contract;
                this.setState({
                    invoiceEmail: contract.invoiceEmail,
                    infoEmail: contract.infoEmail,
                    deliverEinvoice: contract.deliverEinvoice,
                    deliverEmail: contract.deliverEmail,
                    deliverMail: contract.deliverMail,
                    deliverLimitedEinvoice: contract.deliverLimitedEinvoice,
                    serviceRequired: contract.serviceRequired,
                    thiAdded: contract.thiAdded,
                    evChargingCompensationPaymentReferenceNumber: contract.evChargingCompensationPaymentReferenceNumber,
                    evChargingCompensationBankAccountNumber: contract.evChargingCompensationBankAccountNumber,
                    evChargingCompensationMargin: contract.evChargingCompensationMargin,
                    renewPeriod: contract.renewPeriod,
                    end: contract.end
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.disablePartnerApiAccessFetching && prevProps.disablePartnerApiAccessFetching && this.props.disablePartnerApiAccessFetched) {
            this.props.fetchContract(this.props.match.params.id);

            this.setState({
                isPartnerApiDialogOpen: false
            });
        }

        if (!this.props.enablePartnerApiAccessFetching && prevProps.enablePartnerApiAccessFetching && this.props.enablePartnerApiAccessFetched) {
            this.props.fetchContract(this.props.match.params.id);

            this.setState({
                isPartnerApiDialogOpen: false
            });
        }

        if (!this.props.fetchingContract && prevProps.fetchingContract && this.props.fetchedContract) {
            this.setState({
                isContractActive: this.props.contract.status === "Active",
            });
        }
    }

    enablePartnerApiAccess(contract) {
        this.props.enablePartnerApiAccess(contract.id).then(() => {
            this.setState({
                showSuccessResponse: true
            });
        });
    }

    disablePartnerApiAccess(contract) {
        this.props.disablePartnerApiAccess(contract.id).then(() => {
            this.setState({
                showSuccessResponse: true
            });
        });
    }

    toggleDialog() {
        this.setState({ isPartnerApiDialogOpen: !this.state.isPartnerApiDialogOpen });
    }

    closeModal() {
        this.setState({ isPartnerApiDialogOpen: false });
    }

    renderErrorMessage() {
        return (
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={<FormattedMessage id="ContractDetails.Error" />}
                />
            </GridCell>
        );
    }

    renderResponseMessages() {
        return (
            <>
                {this.state.showSuccessResponse &&
                    <AlertMessage
                        type={AlertMessage.TYPE_DONE}
                        isSmall={true}
                        scrollToMessage={true}
                        title={<FormattedMessage id="ContractDetails.EvCharging.PartnerApi.Success.Response" />}
                        className="mb-15" />
                }
                {(this.props.disablePartnerApiAccessError || this.props.enablePartnerApiAccessError) &&
                    <AlertMessage type={AlertMessage.TYPE_ALERT}
                                  title={<FormattedMessage id="ContractDetails.EvCharging.PartnerApi.Error.Response" />}
                                  scrollToMessage={true}
                                  className="mb-15"
                    />
                }
            </>
        );
    }

    renderContractDetails() {
        const contract = this.props.contract;
        const frequency = InvoiceFrequency.find(x => x.label === contract.invoiceFrequencyXc);
        const frequencyTranslation = this.props.intl.formatMessage({ id: frequency.value });

        return (
            <>
                <div className="contract-header">
                    <div>
                        <Typography
                            use="headline3"
                            tag="h3"
                            className="mdc-typography mdc-theme--primary mb-15"
                        >
                            <FormattedMessage id="ContractDetails.ContractNr"
                                              values={{number: contract.extraNumber || contract.number}} />
                        </Typography>
                    </div>
                </div>
                <div className="mb-10 mb-mobile-30 mt-30">
                    <table className="table-detail-list">
                        <tbody>
                        <tr>
                            <td className="bold">
                                <FormattedMessage
                                    id={'ContractDetails.EvCharging.ServiceRequired'}/>
                            </td>
                            <td>
                                <CheckboxField
                                    disabled={true}
                                    nativeControlId=""
                                    field={'serviceRequired'}
                                    checked={this.state.serviceRequired}
                                    indeterminate={false}
                                    onChange={this.onChangeCheckbox}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage
                                    id="Contract.EVCharger.THIAdded"/>
                            </td>
                            <td>
                                <CheckboxField
                                    disabled={true}
                                    nativeControlId=""
                                    field={'thiAdded'}
                                    checked={this.state.thiAdded}
                                    indeterminate={false}
                                    onChange={this.onChangeCheckbox}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage id="ContractDetails.EvCharging.CompensationMargin" />
                            </td>
                            <td>
                                {this.state.evChargingCompensationMargin ? `${this.state.evChargingCompensationMargin} EUR/kwh` : "-"}
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage id="Contract.EVCharger.SelfBilling.AccountNo" />
                            </td>
                            <td>
                                {this.state.evChargingCompensationBankAccountNumber ?? "-"}
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage id="Contract.EVCharger.SelfBilling.ReferenceNo" />
                            </td>
                            <td>
                                {this.state.evChargingCompensationPaymentReferenceNumber ?? "-"}
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage
                                    id={'ContractDetails.EvCharging.InvoiceEmail'}/>
                            </td>
                            <td>
                                { contract.invoiceEmail }
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage
                                    id={'ContractDetails.Fuel.InfoEmail'}/>
                            </td>
                            <td>
                                { contract.infoEmail }
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage
                                    id={'ContractDetails.EvCharging.PostalAddress'}/>
                            </td>
                            <td>
                                {contract.postalAddress}
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage
                                    id={'ContractDetails.EvCharging.InvoiceFrequency'}/>
                            </td>
                            <td>
                                {frequencyTranslation}
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <FormattedMessage
                                    id={'ContractDetails.EvCharging.DueDays'}/>
                            </td>
                            <td>
                                {contract.dueDays}
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <div className="d-flex">
                                    <FormattedMessage
                                        id={'ContractDetails.EvCharging.DeliverEmail'}/>
                                    <div className="ml-45">
                                        <InfoTooltip
                                            content={'ContractDetails.EvCharging.Email.Info'}
                                            className="badge-standalone-tooltip"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CheckboxField
                                    disabled={true}
                                    nativeControlId="deliver-email"
                                    field={'deliverEmail'}
                                    checked={this.state.deliverEmail}
                                    indeterminate={false}
                                    onChange={this.onChangeCheckbox}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <div className="d-flex">
                                    <FormattedMessage
                                        id={'ContractDetails.EvCharging.DeliverMail'}/>
                                    <div className="ml-45">
                                        <InfoTooltip
                                            content={'ContractDetails.EvCharging.Email.Info'}
                                            className="badge-standalone-tooltip"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CheckboxField
                                    disabled={true}
                                    nativeControlId="deliver-mail"
                                    field={'deliverMail'}
                                    checked={this.state.deliverMail}
                                    indeterminate={false}
                                    onChange={this.onChangeCheckbox}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <div className="d-flex">
                                    <FormattedMessage
                                        id={'ContractDetails.EvCharging.DeliverEinvoice'}/>
                                    <div className="ml-45">
                                        <InfoTooltip
                                            content={'ContractDetails.EvCharging.Email.Info'}
                                            className="badge-standalone-tooltip"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CheckboxField
                                    disabled={true}
                                    nativeControlId="deliver-einvoice"
                                    field={'deliverEinvoice'}
                                    checked={this.state.deliverEinvoice}
                                    indeterminate={false}
                                    onChange={this.onChangeCheckbox}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="bold">
                                <div className="d-flex">
                                    <FormattedMessage
                                        id={'ContractDetails.EvCharging.DeliverLimitedEinvoice'}/>
                                    <div className="ml-45">
                                        <InfoTooltip
                                            content={'ContractDetails.EvCharging.Email.Info'}
                                            className="badge-standalone-tooltip"/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CheckboxField
                                    disabled={true}
                                    nativeControlId="deliver-limited-einvoice"
                                    field={'deliverLimitedEinvoice'}
                                    checked={this.state.deliverLimitedEinvoice}
                                    indeterminate={false}
                                    onChange={this.onChangeCheckbox}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    renderValidityPeriod() {
        return (
            <Typography use="body2" className="text-bold">
                <span>
                    {this.state.end
                        ? <FormattedMessage id={"Contract.EVCharger.ValidityPeriod"} values={{date: moment(this.state.end).format("DD.MM.YYYY")}} />
                        : <FormattedMessage id={"Contract.EVCharger.NoExpiryDate"} />
                    }
                </span>
                <span>&nbsp;</span>
                <span>
                    {!this.state.end && this.state.renewPeriod &&
                        <FormattedMessage id={"Contract.EVCharger.RenewDate"} values={{years: this.state.renewPeriod}} />
                    }
                </span>
            </Typography>
        )
    }

    renderContractServices() {
        const contract = this.props.contract;
        let username = '';
        let secret = '';
        let hasPartnerApiAccess = false;

        if (this.props.fetchedContract &&  contract.partnerApiUser !== null) {
            username = contract.partnerApiUser.name;
            secret = contract.partnerApiUser.secret;
            hasPartnerApiAccess = true;
        }

        return (
            <>
                {this.state.isCompany &&
                    <div className="mt-30">
                        <GridCell span={12} className="mdc-typography mdc-typography--headline4 mdc-typography--bold mb-25">
                            <FormattedMessage id="ContractDetails.EvCharging.Services.Title" />
                        </GridCell>

                        <PartnerApiForm
                            username={username}
                            secret={secret}
                            hasPartnerApiAccess={hasPartnerApiAccess}
                            onClickButton={this.toggleDialog.bind(this)}
                        />
                    </div>
                }

                <BaseDialog
                    isOpen={this.state.isPartnerApiDialogOpen}
                    onClose={this.closeModal.bind(this)}
                >
                    <div className="text-center">
                        { hasPartnerApiAccess
                            ? <>
                                <h1 className="mdc-typography mdc-typography--headline3 mdc-theme--primary information__head">
                                    <FormattedMessage id= "ContractDetails.EvCharging.Confirm.Deactivate" />
                                </h1>
                                <div className="mt-30">
                                    <Button
                                        className="mdc-button--primary mr-15 mb-mobile-10"
                                        unelevated
                                        onClick={this.disablePartnerApiAccess.bind(this, contract)}
                                    >
                                        {this.props.disablePartnerApiAccessFetching ?
                                            <Loader type={Loader.TYPE_BUTTON} />
                                            :
                                            <FormattedMessage id="Jah" />
                                        }
                                    </Button>
                                    <Button
                                        className="mdc-button--primary"
                                        unelevated
                                        onClick={this.closeModal.bind(this)}
                                    >
                                        <FormattedMessage id="Ei" />
                                    </Button>
                                </div>
                            </>
                            : <>
                                <h1 className="mdc-typography mdc-typography--headline3 mdc-theme--primary information__head">
                                    <FormattedMessage id= "ContractDetails.EvCharging.Confirm.Activate" />
                                </h1>
                                <div className="mt-30">
                                    <Button
                                        className="mdc-button--primary mr-15 mb-mobile-10"
                                        unelevated
                                        onClick={this.enablePartnerApiAccess.bind(this, contract)}
                                    >
                                        {this.props.enablePartnerApiAccessFetching ?
                                            <Loader type={Loader.TYPE_BUTTON} />
                                            :
                                            <FormattedMessage id="General.Yes" />
                                        }
                                    </Button>
                                    <Button
                                        className="mdc-button--primary"
                                        unelevated
                                        onClick={this.closeModal.bind(this)}
                                    >
                                        <FormattedMessage id="General.No" />
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                </BaseDialog>
            </>
        );
    }

    renderContractOrError() {
        const hasContract = !this.props.fetchingContract && this.props.fetchedContract && Object.keys(this.props.contract).length > 0;

        return hasContract
            ? <>
                <GridCell span={12}>
                    {this.renderContractDetails()}
                </GridCell>
                <GridCell span={12}>
                    {this.renderValidityPeriod()}
                </GridCell>
                {this.state.isCompany &&
                    <GridCell span={12}>
                        {this.renderContractServices()}
                    </GridCell>
                }
            </>
            : this.renderErrorMessage();
    }

    render() {
        return (
            <>
                <Grid span={12}>
                    <GridCell span={12}>
                        <BackButton toPath={ContractsPath} label="ContractDetails.Back" />
                    </GridCell>
                    <GridCell span={12}>
                        {this.renderResponseMessages()}
                    </GridCell>

                    {this.props.fetchingContract
                        ? <Loader type={Loader.TYPE_CENTER} />
                        : this.renderContractOrError()
                    }
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        contract: state.contract.data,
        fetchingContract: state.contract.fetching,
        fetchedContract: state.contract.fetched,
        enablePartnerApiAccess: state.enablePartnerApiAccess.data,
        enablePartnerApiAccessFetching: state.enablePartnerApiAccess.fetching,
        enablePartnerApiAccessFetched: state.enablePartnerApiAccess.fetched,
        enablePartnerApiAccessError: state.enablePartnerApiAccess.error,
        disablePartnerApiAccess: state.disablePartnerApiAccess.data,
        disablePartnerApiAccessFetching: state.disablePartnerApiAccess.fetching,
        disablePartnerApiAccessFetched: state.disablePartnerApiAccess.fetched,
        disablePartnerApiAccessError: state.disablePartnerApiAccess.error,
        userRoleInCompany: state.userRoleInCompany.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchContract, enablePartnerApiAccess, disablePartnerApiAccess }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(EvChargingContractDetails));