import React from "react";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";

import { Grid, GridCell } from "@rmwc/grid";
import { fetchNordpoolPrices } from "../actions/stockPriceActions";

import NordpoolFilter from "../components/Nordpool/NordpoolFilter";
import { nordpoolRanges, createRangePresets } from "../components/common/DateRangePreset";
import NordpoolDetails from "../components/Nordpool/NordpoolDetails";
import StockExchangePriceAlertSettings from "../components/Settings/StockExchangePriceAlertSettings";
import auth from "../auth/authenticate";
import { Button } from "@rmwc/button";
import AlertMessage from "../components/common/AlertMessage";

class Nordpool extends React.Component {

    constructor(props) {
        super(props);

        const defaultPreset = createRangePresets(nordpoolRanges, this.props.intl)[0];

        this.state = {
            filter: {
                startDate: moment(defaultPreset.range.startDate),
                endDate: moment(defaultPreset.range.endDate),
                selectedPeriodPreset: defaultPreset,
                selectedPeriodPresetName: defaultPreset.label
            },
            showSettings: false
        }

        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.toggleShowSettings = this.toggleShowSettings.bind(this);
    }

    componentDidMount() {
        this.fetchNordpoolPrices(this.state.filter);
    }

    fetchNordpoolPrices(filter) {
        const params = {
            periodStart: filter.endDate.format("YYYY-MM-DD 00:00:00"),
            periodEnd: filter.startDate.format("YYYY-MM-DD 00:00:00")
        }
        if (!isEqual(this.props.nordpoolPricesParams, params)) {
            return this.props.fetchNordpoolPrices(params);
        }
    }

    onFilterSubmit(filter) {
        this.setState({ filter: filter });
        this.fetchNordpoolPrices(filter);
    }

    toggleShowSettings() {
        this.setState((state) => ({
            showSettings: !state.showSettings
        }));
    }

    shouldDisplayPricesNotice() {
        const today = moment().startOf("day");
        const isNextDayPricesTab = moment(this.state.filter.startDate).startOf("day").isAfter(today);

        if (!isNextDayPricesTab) {
            return false;
        }

        // There can be 1 single item in summer because of daylight saving time
        const hasPrices = this.props.nordpoolPrices?.data?.length > 1;

        return isNextDayPricesTab && !hasPrices;
    }

    render() {
        const {
            nordpoolPricesFetching,
            nordpoolPrices
        } = this.props;
        const { showSettings } = this.state;

        const roleId = auth.getRoleId();

        return (
            <>
                <Grid className="mdc-layout-grid--base">
                    <GridCell desktop={9} tablet={5} phone={4}>
                        <AlertMessage
                            type={AlertMessage.TYPE_WARN}
                            isSmall
                            description={<FormattedHTMLMessage id="Nordpool.NewUniversalPackage.Alert" />}
                        />
                    </GridCell>
                    <GridCell desktop={3} tablet={3} phone={4} className="align-right align-right--desktop" align="middle">
                        <Button
                            className="mdc-toggle-container-mobile"
                            outlined
                            onClick={this.toggleShowSettings}
                            trailingIcon={{
                                icon: "angle-down",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-",
                                className: (showSettings ? "rotate" : "")
                            }}>
                            <FormattedMessage id="Nordpool.Configure" />
                        </Button>
                    </GridCell>
                    <GridCell span={12}>
                        {showSettings &&
                            <StockExchangePriceAlertSettings accountCrmId={roleId} />}
                    </GridCell>
                    <GridCell span={12}>
                        <NordpoolFilter open={true}
                            filter={this.state.filter}
                            onSubmit={this.onFilterSubmit}
                            loading={nordpoolPricesFetching} />
                    </GridCell>
                </Grid>
                <Grid className="mdc-layout-grid--base">
                    <GridCell span={12}>
                        {this.shouldDisplayPricesNotice() ?
                            <AlertMessage
                                type={AlertMessage.TYPE_NOTICE}
                                title={this.props.intl.formatMessage({ id: "Nordpool.Notification.NextDayPrices" })}
                            /> :
                            <NordpoolDetails
                                className="mt-20 mb-60"
                                details={nordpoolPrices}
                                isLoading={nordpoolPricesFetching}
                            />
                        }
                    </GridCell>
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        nordpoolPrices: state.nordpoolPrices.data,
        nordpoolPricesFetching: state.nordpoolPrices.fetching,
        nordpoolPricesFetched: state.nordpoolPrices.fetched,
        nordpoolPricesParams: state.nordpoolPrices.params
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchNordpoolPrices,
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(Nordpool));
