import { useSelector } from "react-redux";
import DashboardEe from "../components/overview/DashboardEe";
import DashboardLv from "../components/overview/DashboardLv";
import OverviewEe from "../components/overview/OverviewEe";
import OverviewLv from "../components/overview/OverviewLv";
import { IsLatvianWeb } from "../helpers/AlternativeDesign";

const Overview = () => {
    const isMobileOrTabletView = useSelector(state => state.ui.isMobileOrTabletView);
    const OverviewComponent = IsLatvianWeb() ? OverviewLv : OverviewEe;
    const DashboardComponent = IsLatvianWeb() ? DashboardLv : DashboardEe;

    return (
        isMobileOrTabletView
            ? <DashboardComponent />
            : <OverviewComponent />
    );
}

export default Overview;
