import { Button } from "@rmwc/button";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const SpinButton = ({ onClick, isLoading = false, isDisabled = false }) => {
    const isMobile = useSelector(state => state.ui.isMobileOrTabletView);

    return (
        <Button
            className={classNames({
                "mobile": isMobile,
                "disabled": isDisabled
            })}
            unelevated
            onClick={onClick}
        >
            <i className={classNames("icon-refresh", {
                "loading": isLoading
            })} />
            <FormattedMessage id="SummerCampaign.Wheel.SpinAndWin" />
        </Button>
    );
}

export default SpinButton;