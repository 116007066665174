
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Typography } from "@rmwc/typography";
import PropTypes from 'prop-types';

import DiscountBadge from "./DiscountBadge";

const DiscountBlock = ({
    title,
    content,
    discount,
    unit,
    isDone,
    contentChildren,
    children
}) => {
    return (
        <>
            <div className="item__badge">
                <DiscountBadge
                    discount={discount}
                    discountUnit={unit}
                    isDone={isDone}
                />
            </div>
            <div className="item__content">
                {title && (
                    <Typography
                        use="headline5"
                        className="mdc-typography title"
                    >
                        <FormattedMessage tagName="b" id={title} />
                    </Typography>
                )}
                {content && (
                    <Typography use="body1" className="mdc-typography body">
                        <FormattedMessage id={content} />
                    </Typography>
                )}
                {contentChildren}
            </div>
            {children}
        </>
    );
};

DiscountBlock.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    unit: PropTypes.string,
    isDone: PropTypes.bool,
    contentChildren: PropTypes.any,
    children: PropTypes.any,
    isLiteral: PropTypes.bool
}

export default injectIntl(DiscountBlock);
