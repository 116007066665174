import BaseIdCodeValidator from "./BaseIdCodeValidator";

class LvIdCodeValidator extends BaseIdCodeValidator {
    // Stands for a century person was born in
    // 0 for XIX, 1 for XX and 2 for XXI
    static ControlNumbers = [1, 2];

    /**
     *  Gets the control number of personal ID.
     *
     *  @return Number
     */
    getControlNumber(code) {
        const multiplier = [1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 1];

        let total = this.calculateCheckSum(code, multiplier);

        return total % 11;
    }

    /**
     *  Validates the Estonian personal ID.
     *
     *  @return boolean
     */
    validate(code) {
        if (code.substring(6, 7) !== "-") {
            return false;
        }

        let formattedCode = code.replace("-", "");

        if (formattedCode.length !== 11) {
            return false;
        }

        let controlNumber = this.getControlNumber(formattedCode);

        return LvIdCodeValidator.ControlNumbers.indexOf(controlNumber) !== -1;
    }
}

export default LvIdCodeValidator;