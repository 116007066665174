import React from "react";
import "../styles/blocks/_giftcard.scss";
import logo from "../images/logo-white.svg";
import { Link, withRouter } from "react-router-dom";
import AlertMessage from '../components/common/AlertMessage';
import { FormattedMessage, injectIntl } from "react-intl";
import { Typography } from "@rmwc/typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCardQR, getBonusCardEncrypted } from "../actions/cardActions";
import moment from "moment";
import LanguageSelector from "../components/LanguageSelector";
import Loader from "../components/common/Loader";
import { StartPath } from "../const/routes";

class GiftCard extends React.Component {
    static dateDisplayFormat = "DD.MM.YYYY";
    static dateFormats = [
        moment.ISO_8601,
        "MM/DD/YYYY",
        "DD.MM.YYYY",
        "DD.MM YYYY",
        "DD MMMM YYYY",
        "DD. MMMM YYYY"
    ];

    componentDidMount() {
        if (!this.props.cardQRFetching && !this.props.cardQRFetched) {
            const urlParams = new URLSearchParams(window.location.search);
            let encodedNumber = urlParams.get('code');
            if (!encodedNumber) {
                const pathWithoutSlash = window.location.pathname.substring(1);
                encodedNumber = pathWithoutSlash.substring(pathWithoutSlash.indexOf('/') + 1);
            }
            this.props.getCardQR(decodeURIComponent(encodedNumber));
        }
    }

    render() {
        return (
            <div className="giftcard">
                <div className="giftcard__language">
                    <LanguageSelector />
                </div>
                <Link to={StartPath} className="link">
                    <img className="giftcard__logo" alt="Alexela logo" src={logo} />
                </Link>
                {this.props.cardQRFetching &&
                    <div className="mt-45">
                        <Loader isWhite={true} />
                    </div>
                }
                {!this.props.cardQRFetching &&
                    <>
                        {this.props.cardQR.base64Image ?
                            <>
                                <Typography
                                    use="headline1"
                                    tag="p"
                                    className="giftcard__title"
                                >
                                    {this.props.intl.formatNumber(
                                        this.props.cardQR.balance,
                                        {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2
                                        }
                                    )}€
                                </Typography>
                                <Typography
                                    use="headline1"
                                    className="mb-25"
                                >
                                    <FormattedMessage id="Cards.GiftCard" />
                                </Typography>
                                <Typography
                                    use="body1"
                                    className="giftcard__highlight mb-30"
                                >
                                    <b>
                                        <FormattedMessage id="Cards.ValidUntil" />{" "}
                                        {moment(this.props.cardQR.expiryDate).format("DD.MM.YYYY")}
                                    </b>
                                </Typography>
                                <img className="giftcard__logo" alt="GiftCard QR" src={this.props.cardQR.base64Image} />
                                <div className="giftcard__terms">
                                    <Typography
                                        use="body1"
                                        className="giftcard__limiter"
                                    >
                                        <b>
                                            <FormattedMessage id="Cards.GiftCard.Terms"/>{" "}
                                        </b>
                                        <FormattedMessage id="Cards.GiftCard.Terms.Content1"/>
                                    </Typography>

                                    <Typography
                                        use="body1"
                                        className=""
                                    >
                                        <FormattedMessage id="Cards.GiftCard.Terms.Content2"/>
                                    </Typography>
                                    <Typography
                                        use="body1"
                                        className="mb-15"
                                    >
                                        <FormattedMessage id="Cards.GiftCard.Terms.Content3"/>
                                    </Typography>
                                    <Typography
                                        use="body1"
                                        className=""
                                    >
                                        <FormattedMessage id="Cards.Contact.Phone" />
                                    </Typography>
                                    <Typography
                                        use="body1"
                                        className=""
                                    >
                                        <FormattedMessage id="Cards.Contact.Email" />
                                    </Typography>
                                </div>
                            </>
                        :
                            <AlertMessage
                                className="giftcard__notice"
                                title={<FormattedMessage id="Cards.GiftCard.NotFound" />}
                            />
                        }
                    </>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cardQRFetching: state.cardQR.fetching,
        cardQRFetched: state.cardQR.fetched,
        cardQR: state.cardQR.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getCardQR,
        getBonusCardEncrypted
    }, dispatch);
}

export default injectIntl(withRouter(
    connect(mapStateToProps, matchDispatchToProps)(GiftCard)
));
