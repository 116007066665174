import { startOfWeek, endOfWeek, addWeeks, addMonths, getYear, startOfYear } from "date-fns";
import moment from "moment";

export const createWeekPresets = (intl, date) => {
    const firstWeek = startOfWeek(startOfYear(date), { weekStartsOn: 1 });

    return Array
        .from({ length: 53 }, (v, k) => k)
        .map(index => {
            const weekStart = startOfWeek(addWeeks(firstWeek, index), { weekStartsOn: 1 });
            const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
            const name = `${moment(weekStart).format("DD.MM")} - ${moment(weekEnd).format("DD.MM")}`;

            return {
                label: name,
                value: name,
                index,
                date: weekStart
            }
        });
}

export const createMonthPresets = (intl, date) =>
    moment.months(intl.locale).map((name, index) => ({
        label: name,
        value: name,
        index,
        date: addMonths(startOfYear(date), index)
    }));

export const createYearPresets = (year) => {

    if (!year) { year = getYear(new Date()); }

    const years = [year - 2, year - 1, year];

    return years.map((year, index) => ({
        label: year,
        value: year,
        index,
        date: new Date(year, 0, 1)
    }));
}
