import { isAndroid, isIOS } from "react-device-detect";

const getHaagisAppLink = () => {
    const googlePlayLink = "https://play.google.com/store/apps/details?id=com.tiki.tikirent";
    const androidIntent = `intent://#Intent;package=com.tiki.tikirent;S.browser_fallback_url=${encodeURIComponent(googlePlayLink)};end`;
    const appLink = "tikirent://haagis";

    if (isIOS) { return appLink; }
    if (isAndroid) { return androidIntent; }// At some point, if Android app supports custom url scheme, could use appLink for android too
    return googlePlayLink;
}

const getElektrilaadijaAppLink = () => {
    const googlePlayLink = "https://play.google.com/store/apps/details?id=com.hastobe.alexela";
    const androidIntent = `intent://#Intent;package=com.hastobe.alexela;S.browser_fallback_url=${encodeURIComponent(googlePlayLink)};end`;
    const appLink = "alexela://laadija";

    if (isIOS) { return appLink; }
    if (isAndroid) { return androidIntent; }
    return googlePlayLink;
}

const getAlexelaAppLink = () => {
    const googlePlayLink = "https://play.google.com/store/apps/details?id=ee.alexela.iseteenindus";
    const androidIntent = `intent://#Intent;package=ee.alexela.iseteenindus;S.browser_fallback_url=${encodeURIComponent(googlePlayLink)};end`;
    const appLink = "ee.alexela.itk://placeholder";

    if (isIOS) { return appLink; }
    if (isAndroid) { return androidIntent; }
    return googlePlayLink;
}

const appLinksHelper = {
    getHaagisAppLink,
    getElektrilaadijaAppLink,
    getAlexelaAppLink
}

export default appLinksHelper;