import React from "react";
import { CardsPath } from "../../const/routes";
import auth from "../../auth/authenticate";
import { Redirect } from "react-router-dom";
import * as queryString from "query-string/index";

const bonuscardOrderReferralLink = "bonuscardOrderReferralLink";

const ReferralLink = {
    setReferralLinkParams($query) {
        const params = queryString.parse($query);
        sessionStorage.setItem(bonuscardOrderReferralLink, JSON.stringify({
            agent : params.agent,
            userGroupCode: params.ug,
            discountCampaignCode: params.dc,
            cardPrefix: params.prefix,
            isRedirectDone: false
        }));
    },
    clearReferralLinkParams() {
        sessionStorage.removeItem(bonuscardOrderReferralLink);
    },
    getReferralLinkParams() {
        if(auth.isAgent()){
            return null;
        }
        return  sessionStorage.getItem(bonuscardOrderReferralLink) ? JSON.parse(sessionStorage.getItem(bonuscardOrderReferralLink)) : null;
    },
    shouldRedirectToCardPath() {
        const referral = this.getReferralLinkParams();
        return  referral && !referral.isRedirectDone;
    },
    setRedirectDone() {
        const currentItem = JSON.parse(sessionStorage.getItem(bonuscardOrderReferralLink));
        currentItem.isRedirectDone = true;
        sessionStorage.setItem(bonuscardOrderReferralLink, JSON.stringify(currentItem));
    },
    redirectToCardPath() {
        const referral = this.getReferralLinkParams();
        if (referral){
            return <Redirect to={{
                pathname: CardsPath,
                state: { params: { fromReferralLink: true } }
                }} />
        }
    },
};
export default ReferralLink;
