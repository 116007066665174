import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import moment from "moment";
import { addYears } from "date-fns";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { fetchElectricityInvoices, fetchPaymentCardInvoices } from "../../actions/invoiceActions";
import { fetchBonuscardTransactions } from "../../actions/transactionActions";
import { getInvoiceDetailsPath, InvoicesPath, TransactionsPath } from "../../const/routes";
import Tabs from "../common/Tabs";
import LinkElement from "../common/LinkElement";
import Currency from "../common/Currency";
import { Statuses } from "../../const/Invoice";
import { ElectricityNew, Fuel, GasNew, CylinderGas, EvCharging } from "../../const/contractType";
import DashboardTable from "./DashboardTable";

const DashboardInvoices = () => {

    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        const now = new Date();
        const endDate = moment(now).format("YYYY-MM-DD 23:59:59");
        const startDate = moment(addYears(now, -1)).format("YYYY-MM-DD 00:00:00");

        dispatch(fetchElectricityInvoices(1, 5, startDate, endDate));
        dispatch(fetchPaymentCardInvoices(1, 5, startDate, endDate));

    }, [dispatch]);

    useEffect(() => {
        const now = new Date();
        const startDate = moment(now).subtract(3, 'months').startOf('month').format("YYYY-MM-DD");
        const endDate = moment(now).format("YYYY-MM-DD");

        dispatch(fetchBonuscardTransactions(1, 5, startDate, endDate, null, ""));
    }, [dispatch]);

    const translateInvoiceContractType = (type) => {
        return [ElectricityNew, GasNew, Fuel, CylinderGas, EvCharging].indexOf(type) >= 0
            ? intl.formatMessage({ id: `Dashboard.Invoices.${type}` }) :
            "";
    }

    const renderInvoiceItem = (invoice, index) => {
        const isInvoicePaid = invoice.status === Statuses.StatusPaid || invoice.status === Statuses.StatusCredited;
        const isPaymentPending = invoice.status === Statuses.StatusPaymentPending;

        const period = moment(invoice.periodEnd).format("MMMM YYYY");

        const contractTypes = (invoice.contractTypes && invoice.contractTypes.length > 0) ? invoice.contractTypes : ["gas"];
        const types = contractTypes
            .map(translateInvoiceContractType)
            .reduce((a, b) => a + ", " + b);

        const canPayInvoice = !isInvoicePaid && !isPaymentPending && invoice.isLatestInvoice;
        const isPaymentPendingLangKey = isPaymentPending
            ? "Overview.Invoices.Status.PaymentPending"
            : "Overview.Invoices.Status.Unpaid";

        return (
            <div
                key={"invoice" + index}
                className={classnames("dashboard-list__item")}
            >
                <LinkElement href={getInvoiceDetailsPath(invoice.id, invoice.type)}
                    className="mdc-typography--bold blue">
                    {invoice.number}
                </LinkElement>
                <span>
                    <span className="capitalize">{types}, {period}</span>
                </span>

                <span className="flex-fill text-right">
                    <Currency value={invoice.sum} />
                </span>
                <span
                    className={classnames(
                        "mdc-typography--bold right",
                        { "green": isInvoicePaid },
                        { "orange": isPaymentPending },
                        { "red": !isInvoicePaid && !isPaymentPending && !canPayInvoice }
                    )}
                >
                    {canPayInvoice
                        ? <Button
                            unelevated
                            className="mdc-button--primary mdc-button--thin mdc-button--inline mdc-button--compact"
                            tag="a"
                            href={getInvoiceDetailsPath(invoice.id, invoice.type)}>
                            <FormattedMessage id="Invoice.Pay" />
                        </Button>
                        : <FormattedMessage
                            id={
                                isInvoicePaid
                                    ? "Overview.Invoices.Status.Paid"
                                    : isPaymentPendingLangKey
                            }
                        />
                    }
                </span>
            </div>
        );
    }

    const renderTransactionItem = (transaction, index) => {
        return (
            <div key={"transaction" + index} className="dashboard-list__item">
                <span>{moment(transaction.transactionDate).format("DD.MM.YYYY")}</span>
                <span>{transaction.station}</span>
                <span className="mdc-typography--bold blue right"><Currency value={transaction.sum} /></span>
            </div>
        );
    }

    return (
        <Grid className="mdc-layout-grid--base">
            <GridCell span={12}>
                <Tabs
                    items={
                        [{
                            label: <FormattedMessage id="Dashboard.Tabs.Invoices" />,
                            contents: <Tabs items={
                                [{
                                    label: <FormattedHTMLMessage id="Dashboard.Tabs.ElmoInvoices" />,
                                    contents: <DashboardTable
                                        reducerName="electricityInvoices"
                                        noItemsMessageKey="Dashboard.Invoices.NotFound"
                                        errorMessageKey="Dashboard.Invoices.Error"
                                        moreLink={InvoicesPath}
                                        itemRenderFunction={renderInvoiceItem} />
                                },
                                {
                                    label: <FormattedHTMLMessage id="Dashboard.Tabs.PaymentcardInvoices" />,
                                    contents: <DashboardTable
                                        reducerName="paymentCardInvoices"
                                        noItemsMessageKey="Dashboard.Invoices.NotFound"
                                        errorMessageKey="Dashboard.Invoices.Error"
                                        moreLink={InvoicesPath}
                                        itemRenderFunction={renderInvoiceItem} />
                                }]
                            } />
                        },
                        {
                            label: <FormattedHTMLMessage id="Dashboard.Tabs.Transactions" />,
                            contents: <DashboardTable
                                reducerName="bonuscardTransactions"
                                noItemsMessageKey="Dashboard.Transactions.NotFound"
                                errorMessageKey="Dashboard.Transactions.Error"
                                moreLink={TransactionsPath}
                                itemRenderFunction={renderTransactionItem} />
                        }]
                    } />

            </GridCell>
        </Grid>
    );
}

export default DashboardInvoices;
