import { Grid, GridCell } from "@rmwc/grid";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { fetchConsumptionLocations } from "../actions/consumptionlocationActions";
import { EnergyType as ContractType, EnergyType as ServiceType} from "../const/EnergyType";
import { hasGasoPermission } from "../actions/contractActions";
import ConsumptionLocationSelect from "../components/ConsumptionLocations/ConsumptionLocationSelect";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@rmwc/typography";
import AlertMessage from "../components/common/AlertMessage";
import Loader from "../components/common/Loader";
import GasoMeterForm from "../components/GasoMeter/GasoMeterForm"

const GasoMeterSubmission = () => {
    const consumptionLocations = useSelector(state => state.consumptionLocations);
    const gasoPermission = useSelector(state => state.hasGasoPermission);
    const dispatch = useDispatch();
    const [selectedLocation, setSelectedLocation] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState([]);

    const locations = consumptionLocations.data?.locations?.filter(x => x.type === ServiceType.Gas) || [];
    const locationsFetching = consumptionLocations.fetching;
    const intl = useIntl();

    useEffect(() => {
        if (gasoPermission.fetched && !gasoPermission.fetching && !gasoPermission.error && !!gasoPermission.data?.hasPermission) {
            dispatch(fetchConsumptionLocations());
        }
    }, [dispatch, gasoPermission]);

    useEffect(() => {
        dispatch(hasGasoPermission());
    }, [dispatch]);

    const consumptionLocationChanged = (value) => {
        const foundLocation = locations.find(x => x.id === value);
        if (!foundLocation) {
            return;
        }
        setSelectedLocation(foundLocation);
        setSelectedValue([foundLocation.id])
    }

    const RenderedLocations = () =>  {
        const locationsList = (locations || [])
            .filter(location => location.type === ContractType.Gas && location.isInContract)
            .map((item, i) =>
                <ConsumptionLocationSelect key={item.id}
                   onChange={consumptionLocationChanged}
                   fieldName="location"
                   index={item.id}
                   hideFirstAvailableContractDate={true}
                   location={{
                       ...item,
                       isInContract: false
                   }}
                   selectedValue={selectedValue}
                   disabled={false}
                   noAddressText={<FormattedMessage id="Contract.Address.Unknown" />}
                />
            );
        return (
            <>
                <Typography className="mb-25" use="headline3">
                    <FormattedMessage id="Contract.Locations.Title" />
                </Typography>
                {locations && locationsList.length < 1 &&
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id={"Contract.Locations.None"}
                            values={{
                                link: <a
                                    href={intl.formatMessage({id: "Contract.Locations.None.Link"})}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FormattedMessage id={ "Contract.Locations.None.Link"} />
                                </a>
                            }}
                        />}
                    />
                }
                <div className="fields-group__list fields-group__list--text">
                    {locationsFetching ? <Loader type={Loader.TYPE_CENTER} /> : locationsList}
                </div>
            </>
        );
    }

    return (
        <Grid className="mdc-layout-grid--base">
            <GridCell span={12}>
                <FormattedMessage id={"Service.GasoMeterSubmission.Description"} />
            </GridCell>
            <GridCell span={12}>
                <RenderedLocations />
            </GridCell>

            <GridCell span={12}>
                {selectedLocation && <GasoMeterForm selectedLocation={selectedLocation} />}
            </GridCell>
        </Grid>
    )
}

export default GasoMeterSubmission;