import { Grid, GridCell } from "@rmwc/grid";
import React from "react";
import FuelTaxiOrderForm from "../components/FuelTaxiOrderForm/FuelTaxiOrderForm";

class FuelTaxiOrder extends React.Component {
    render() {
        return (
            <Grid>
                <GridCell desktop={10} tablet={10} phone={4} className="mdc-layout-grid__cell--start-2-desktop">
                    <FuelTaxiOrderForm />
                </GridCell>
            </Grid>
        );
    }
}

export default FuelTaxiOrder;