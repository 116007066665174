import { CLEAR_SUBSCRIPTION_MESSAGES, PUT_UNSUBSCRIBE } from "../../actions/subscriptionActions";

export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${PUT_UNSUBSCRIBE}_PENDING`: {
            return {...state, fetching: true, error: null, fetched: false};
        }
        case `${PUT_UNSUBSCRIBE}_REJECTED`: {
            let statusCode = action.payload.request && action.payload.request.status ? action.payload.request.status : null;
            return {...state, fetching: false, error: statusCode === 400 ? action.payload.response.data : true};
        }
        case `${PUT_UNSUBSCRIBE}_FULFILLED`: {
            return {...state, fetching: false, fetched: true};
        }
        case CLEAR_SUBSCRIPTION_MESSAGES: {
            return { ...state, error: null, fetched: false }
        }
        default:
            return state;
    }
}