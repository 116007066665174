import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import authMiddleware from "./auth/AuthMiddleware"
import promise from "redux-promise-middleware";
import { updateIntl } from "react-intl-redux";
import { composeWithDevTools } from '@redux-devtools/extension'

import { fetchTranslations } from "./actions/localeActions";
import auth from "./auth/authenticate";
import {
    StartPath,
    LoginPath
} from "./const/routes";
import reducer from "./reducers";
import language from './lang/lang';
import { CachedFilterStorageKey, setDefaultCachedFilter } from "./cachedFilter";

import { screenResize } from "./actions/uiActions";
import { debounce } from "./helpers/RenderHelper";
import { waitForWindowAppVariables, waitForWindowNotificationHubVariables } from "./helpers/appContainerHelper";

const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT_FULFILLED') {
        let logOutState = language.getInitialState();
        logOutState.footer = state.footer;
        return reducer(logOutState, action);
    }

    if (action.type === 'ROLE_SET_FULFILLED') {
        let logOutState = language.getInitialState();
        logOutState.footer = state.footer;
        logOutState.auth = state.auth;
        logOutState.setRole = state.setRole;
        logOutState.roles = state.roles;
        logOutState.appContainer = state.appContainer;
        logOutState.ui = state.ui;
        return reducer(logOutState, action);
    }

    return reducer(state, action);
};

let middleware = [promise, thunk, authMiddleware];
let composedEnhancer = applyMiddleware(...middleware);

if (process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "test") {
    const logger = createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error
    });
    middleware = [...middleware, logger];
    composedEnhancer = composeWithDevTools(applyMiddleware(...middleware));
}


const store = createStore(rootReducer, language.getInitialState(), composedEnhancer);

window.addEventListener('resize', debounce(() => {
    store.dispatch(screenResize(window.innerWidth));
}, 150));

console.debug("Start listening for app variables");
waitForWindowAppVariables(store);
waitForWindowNotificationHubVariables(store);

// update locale translations with API
const lastLocaleUpdated = localStorage.getItem(language.MessagesLastUpdate);
const disableLocaleUpdate = !auth.isLoggedIn() && (window.location.pathname.toLowerCase() === StartPath || window.location.pathname.toLowerCase() === LoginPath);
if (!disableLocaleUpdate && (!lastLocaleUpdated || new Date(Number(lastLocaleUpdated)) < new Date())) {

    fetchTranslations()
        .then((items) => {
            let currentLocaleItems = null;

            items.data.forEach(item => {
                if (item.locale === language.getLang()) {
                    currentLocaleItems = item.messages;
                }
                localStorage.setItem(item.locale + language.Messages, JSON.stringify(item.messages));
            });

            if (currentLocaleItems) {
                store.dispatch(updateIntl({
                    locale: language.getLang(),
                    messages: currentLocaleItems
                }))
            }
            const cacheTime = new Date();
            cacheTime.setMinutes(cacheTime.getMinutes() + 5);
            localStorage.setItem(language.MessagesLastUpdate, cacheTime.getTime());
        });
}

if (!localStorage.getItem(CachedFilterStorageKey)) {
    setDefaultCachedFilter();
}

export default store;