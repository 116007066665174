import React from "react";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { startOfMonth, isThisMonth, addDays } from "date-fns";
import { Grid, GridInner, GridCell } from "@rmwc/grid";
import { fetchConsumptionDetails } from "../actions/consumptionlocationActions";
import AlertMessage from "../components/common/AlertMessage";
import ConsumptionDetails from "../components/ConsumptionDetails/ConsumptionDetails";
import EnergyFilter, { PeriodTypes } from "../components/common/EnergyFilter";
import EnergyWidget from "../components/common/EnergyWidget";
import Tabs from "../components/common/Tabs";
import { locationsOrderFunc, mapToWidgetItems } from "../helpers/energyHelpers";
import { EnergyType } from "../const/EnergyType";

import "../styles/react-components/consumption-page.scss";
import {IsLatvianWeb} from "../helpers/AlternativeDesign";

class Consumption extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterOpen: undefined,
            filter: {
                startDate: moment(startOfMonth(new Date())),
                selectedPeriodType: PeriodTypes.Month,
            }
        }

        this.isFilterDatePartiallyInCurrentMonth = this.isFilterDatePartiallyInCurrentMonth.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
    }

    componentDidMount() {
        this.fetchConsumptionDetails(this.state.filter);
    }

    fetchConsumptionDetails(filter) {
        const params = {
            periodStart: filter.startDate.format("YYYY-MM-DD 00:00:00"),
            periodType: filter.selectedPeriodType
        };
        if (!isEqual(this.props.consumptionDetailsParams, params)) {
            this.props.fetchConsumptionDetails(params);
        }
    }

    onFilterSubmit(filter) {
        this.setState({ filter: filter });
        this.fetchConsumptionDetails(filter);
    }

    isFilterDatePartiallyInCurrentMonth() {
        const { filter: { startDate, selectedPeriodType } } = this.state;

        const jsStartDate = startDate.toDate();

        switch (selectedPeriodType) {
            case PeriodTypes.Week: return isThisMonth(jsStartDate) || isThisMonth(addDays(jsStartDate, 6));
            case PeriodTypes.Month: return isThisMonth(jsStartDate);
            case PeriodTypes.Year: return true;
            default: return false;
        }
    }

    getLocations(type) {
        const { consumptionDetails, consumptionDetailsFetched, intl } = this.props;

        const details = consumptionDetailsFetched &&
            (type === EnergyType.Gas
                ? consumptionDetails.gasConsumption
                : consumptionDetails.electricityConsumption);

        return (
            details && details
                ?.map(x =>
                ({
                    id: x.id || "",
                    name: x.isTotal ? intl.formatMessage({ id: "ConsumptionGraph.All" }) : x.address,
                    isTotal: x.isTotal
                }))
                ?.sort(locationsOrderFunc)
        ) || [];
    }

    renderElectricityConsumptionInfo() {
        const {
            consumptionDetails,
            consumptionDetailsFetched,
            consumptionDetailsFetching,
            consumptionDetailsError
        } = this.props;

        const electricityLocations = this.getLocations(EnergyType.Electricity);

        const hasDetails = consumptionDetailsFetched && !!consumptionDetails;
        const electricityData = hasDetails ? consumptionDetails.electricityConsumption : [];

        const widgetItems = mapToWidgetItems(electricityData, EnergyType.Electricity, this.props.intl.formatMessage({ id: "ConsumptionWidget.AllElectricity" }))
            .sort(locationsOrderFunc);

        return (
            <>
                {!consumptionDetailsFetching && widgetItems.length > 0 && !consumptionDetailsError &&
                    <GridInner className="mdc-layout-grid--base mt-15">
                        {widgetItems.map((x, i) =>
                            <GridCell key={i} desktop={6} tablet={4} phone={4} className="d-flex">
                                <EnergyWidget key={"widget-" + i} {...x} />
                            </GridCell>
                        )}
                    </GridInner>}

                <ConsumptionDetails
                    className="mt-20 mb-60"
                    title={<FormattedMessage id="ConsumptionGraph.ElectricityConsumption" />}
                    locations={electricityLocations}
                    details={electricityData}
                    isLoading={consumptionDetailsFetching}
                    hasError={!!consumptionDetailsError}
                    filter={this.state.filter}
                    type={EnergyType.Electricity} />
            </>
        );
    }

    renderGasConsumptionInfo() {
        const {
            consumptionDetails,
            consumptionDetailsFetched,
            consumptionDetailsFetching,
            consumptionDetailsError
        } = this.props;

        const gasLocations = this.getLocations(EnergyType.Gas);

        const hasDetails = consumptionDetailsFetched && !!consumptionDetails;
        const gasData = hasDetails ? consumptionDetails.gasConsumption : [];

        const widgetItems = mapToWidgetItems(gasData, EnergyType.Gas, this.props.intl.formatMessage({ id: "ConsumptionWidget.AllGas" }))
            .sort(locationsOrderFunc);

        return (
            <>
                <Grid className="mdc-layout-grid--base mt-15">
                    {this.isFilterDatePartiallyInCurrentMonth() &&
                        <GridCell span={12}>
                            <div className="energy-details__disclaimer">
                                <FormattedMessage id="Consumption.DisclaimerGasCoeficent" />
                            </div>
                        </GridCell>}
                    <GridCell span={12}>
                        <div className="energy-details__disclaimer">
                            <FormattedMessage id="Consumption.DisclaimerGasPeriod" />
                        </div>
                    </GridCell>
                    {!consumptionDetailsFetching && widgetItems.length > 0 && !consumptionDetailsError && widgetItems.map((x, i) =>
                        <GridCell key={i} desktop={6} tablet={4} phone={4} className="d-flex">
                            <EnergyWidget key={"widget-" + i} {...x} />
                        </GridCell>
                    )}
                </Grid>

                <ConsumptionDetails
                    className="mt-20 mb-60"
                    title={<FormattedMessage id="ConsumptionGraph.GasConsumption" />}
                    locations={gasLocations}
                    details={gasData}
                    isLoading={consumptionDetailsFetching}
                    hasError={!!consumptionDetailsError}
                    filter={this.state.filter}
                    type={EnergyType.Gas} />
            </>
        );
    }

    renderNoLocationsNotice() {
        return (
            <AlertMessage
                type={AlertMessage.TYPE_NOTICE}
                title={<FormattedMessage id="ConsumptionGraph.NoLocations" />}>
            </AlertMessage>
        );
    }

    renderInfoNotice() {
        return (
            <AlertMessage
                type={AlertMessage.TYPE_NOTICE}
                title={<FormattedMessage id="Consumption.DisclaimerNotice" />}>
            </AlertMessage>
        );
    }

    renderPriceInfoNotice() {
        const isFutureMonth = this.state.filter.startDate.isAfter(new Date(), "month");

        if (isFutureMonth) {
            return null;
        }

        const gasConsumptionLocations = this.props.consumptionDetailsFetched &&
            (this.props.consumptionDetails?.gasConsumption || []);

        if (gasConsumptionLocations.length === 0) {
            return null;
        }

        const locationsWithConsumption = gasConsumptionLocations.filter(g => g.data && g.data.length > 0 && g.id !== null);

        if (locationsWithConsumption.length === 0) {
            return null;
        }

        const locationWithoutPrice = locationsWithConsumption.find(loc => loc.totalPriceWithVat === null);

        if (!locationWithoutPrice) {
            return null;
        }

        return (
            <AlertMessage
                className="mt-10"
                type={AlertMessage.TYPE_NOTICE}
                title={<FormattedMessage id="Consumption.PriceDisclaimer" />}>
            </AlertMessage>
        );
    }

    render() {
        const {
            consumptionDetailsFetching
        } = this.props;

        const isLatvianWeb = IsLatvianWeb();
        const hasElectricityLocations = !isLatvianWeb && this.getLocations(EnergyType.Electricity).length > 0;
        const hasGasLocations = this.getLocations(EnergyType.Gas).length > 0;

        const hasLocations = hasElectricityLocations || hasGasLocations;

        const showGasTabByDefault = hasGasLocations && !hasElectricityLocations && !consumptionDetailsFetching;

        let tabItems = [];

        if (!isLatvianWeb) {
            tabItems.push({
                label: <FormattedMessage id="Consumption.Tabs.Electricity" />,
                contents: hasElectricityLocations
                    ? this.renderElectricityConsumptionInfo()
                    : <Grid className="mdc-layout-grid--base mt-25 mt-mobile-15">
                        <GridCell span={12}>
                            {this.renderNoLocationsNotice()}
                        </GridCell>
                    </Grid>
            });
        }

        tabItems.push({
            label: <FormattedMessage id="Consumption.Tabs.Gas" />,
            contents:
                hasGasLocations
                    ? this.renderGasConsumptionInfo()
                    : <Grid className="mdc-layout-grid--base mt-25 mt-mobile-15">
                        <GridCell span={12}>
                            {this.renderNoLocationsNotice()}
                        </GridCell>
                    </Grid>
        });

        return (
            <>
                <Grid className="mdc-layout-grid--base">
                    <GridCell span={12}>
                        <EnergyFilter
                            open={true}
                            filter={this.state.filter}
                            onSubmit={this.onFilterSubmit}
                            loading={consumptionDetailsFetching} />
                    </GridCell>

                    {!consumptionDetailsFetching &&
                        (hasLocations
                            ? <GridCell span={12}>
                                {this.renderInfoNotice()}
                                {this.renderPriceInfoNotice()}
                              </GridCell>
                            : <GridCell span={12}>{this.renderNoLocationsNotice()}</GridCell>)
                    }
                </Grid>

                {hasLocations &&
                    <Grid className="mdc-layout-grid--base">
                        <GridCell span={12}>
                            <Tabs defaultTabIndex={showGasTabByDefault ? 1 : 0}
                                items={tabItems}
                            />
                        </GridCell>
                    </Grid>}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        consumptionDetails: state.consumptionDetails.data,
        consumptionDetailsFetching: state.consumptionDetails.fetching,
        consumptionDetailsFetched: state.consumptionDetails.fetched,
        consumptionDetailsError: state.consumptionDetails.error,
        consumptionDetailsParams: state.consumptionDetails.params
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchConsumptionDetails,
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(Consumption));
