import {Grid, GridCell} from "@rmwc/grid";
import {useState} from "react";
import {useIntl} from "react-intl";
import {withFeature} from "flagged";
import Tabs from "../components/common/Tabs";
import WheelOfFortune from "../components/SummerCampaign/WheelOfFortune";
import TermsAndConditions from "../components/SummerCampaign/TermsAndConditions";
import Prizes from "../components/SummerCampaign/Prizes";
import {LotteryCampaign2024} from "../const/featureFlags";

// Import style for all tabs
import "../styles/components/_summer-campaign.scss";

const SummerCampaign = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const intl = useIntl();

    const onSelectTab = (index) => setSelectedTab(index);

    return (
        <Grid>
            <GridCell span={12}>
                <Tabs
                    defaultTabIndex={selectedTab}
                    layout="new"
                    items={[
                        {
                            contents: <WheelOfFortune onRedirectToTab={onSelectTab}/>,
                            label: intl.formatMessage({id: "SummerCampaign.Spin"}),
                            onSelect: onSelectTab
                        },
                        {
                            contents: <TermsAndConditions/>,
                            label: intl.formatMessage({id: "SummerCampaign.Terms"}),
                            onSelect: onSelectTab
                        },
                        {
                            contents: <Prizes/>,
                            label: intl.formatMessage({id: "SummerCampaign.Prizes"}),
                            onSelect: onSelectTab
                        },
                    ]}
                />
            </GridCell>
        </Grid>
    );
}

export default withFeature(LotteryCampaign2024)(SummerCampaign);
