import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import Loader from "../components/common/Loader";
import BackButton from "../components/common/BackButton";
import AlertMessage from "../components/common/AlertMessage";
import { postPrepareSignatureEParakstsMobile, postFinalizeSignatureEParakstsMobile } from "../actions/digitalSignatureActions";
import { useEParakstsMobile, useQueryParams } from "../helpers/hooks";
import { ContractSignedPath, StartPath } from "../const/routes";

const EParakstsCallback = () => {
    const [isError, setIsError] = useState(false);

    const prepareSignature = useSelector(state => state.postPrepareSignatureEParakstsMobile);
    const finalizeSignature = useSelector(state => state.postFinalizeSignatureEParakstsMobile);

    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const { code, state, error } = useQueryParams();
    const { isPrepareState, isFinalizeState, finalizeSigning, getCurrentState } = useEParakstsMobile();

    useEffect(() => {
        if (error) {
            setIsError(true);
            return;
        }

        const redirectUri = window.location.origin + window.location.pathname;
        const currentState = getCurrentState();

        if (isPrepareState(state)) {
            dispatch(postPrepareSignatureEParakstsMobile({
                code,
                redirectUri,
                fileToSign: currentState.fileName
            }));
            return;
        }

        if (isFinalizeState(state)) {
            dispatch(postFinalizeSignatureEParakstsMobile({
                ...currentState,
                code,
                redirectUri
            }));
            return;
        }
    }, [dispatch, state, code, error, isPrepareState, isFinalizeState, getCurrentState]);

    useEffect(() => {
        if (prepareSignature.fetched && !prepareSignature.error && prepareSignature.data) {
            finalizeSigning(prepareSignature.data);
        }
    }, [finalizeSigning, prepareSignature]);

    useEffect(() => {
        if (finalizeSignature.fetched && !finalizeSignature.error) {
            history.replace(ContractSignedPath);
        }
    }, [history, finalizeSignature]);

    if (prepareSignature.fetching || finalizeSignature.fetching) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    if (isError || prepareSignature.error || finalizeSignature.error) {
        return (
            <Grid>
                <GridCell span={12}>
                    <BackButton toPath={StartPath} />
                </GridCell>
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={intl.formatMessage({ id: "Contract.Gas.Signing.Error" })}
                    />
                </GridCell>
            </Grid>
        );
    }

    return null;
}

export default EParakstsCallback;