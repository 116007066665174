import React, { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import moment from "moment";
import { Button } from "@rmwc/button";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import {
    ContractsPath,
    getElectricityContractChangePath,
    getElectricityContractRenewalPath,
    getGasContractChangePath
} from "../const/routes";
import {ElectricityNew, Gas, GasNew} from "../const/contractType";
import { fetchElmoContractDetails, downloadElmoFile, downloadElmoFileByFileId } from "../actions/contractActions";
import AlertMessage from "../components/common/AlertMessage";
import BadgeCard from "../components/common/BadgeCard";
import BaseDialog from "../components/common/BaseDialog";
import Loader from "../components/common/Loader";
import Tabs from "../components/common/Tabs";
import DownloadButton from "../components/common/DownloadButton";
import ContractInfoRow from "../components/Contract/ContractInfoRow";
import GreenEnergyService from "../components/ContractDetailsForm/GreenEnergyService";
import { sortByDate } from "../helpers/sortHelper";
import auth from "../auth/authenticate";

import greenEnergyLogoSmall from "../images/rohesarts-logo-small.svg";

import "../styles/blocks/_service.scss";
import { fetchUserStatus } from "../actions/accountActions";
import BackButton from "../components/common/BackButton";

const GreenEnergyModalType = {
    Activate: "activate",
    Deactivate: "deactivate"
}

const RestrictedProductCodes = [
    "EBL",
    "BLUEBL",
    "MMEBL",
    "BBLK",
    "EBLK15",
    "KBL",
    "TARK20",
    "AKPEL"
];

const ElmoContractDetails = () => {
    const [isGreenEnergyModalOpen, setIsGreenEnergyModalOpen] = useState(false);
    const [isActivateSuccess, setIsActivateSuccess] = useState(false);
    const [isDeactivateSuccess, setIsDeactivateSuccess] = useState(false);

    const contract = useSelector(state => state.elmoContractDetails);
    const activateGreenEnergy = useSelector(state => state.activateGreenEnergy);
    const deactivateGreenEnergy = useSelector(state => state.deactivateGreenEnergy);
    const {
        fetching: userStatusFetching,
        fetched: userStatusFetched,
        data: userStatus
    } = useSelector(state => state.userStatus);

    const dispatch = useDispatch();
    const intl = useIntl();
    const { id } = useParams();
    const history = useHistory();
    const isCompany = auth.isCompany();

    const downloadSignedContainer = useCallback(() => {
        if (contract.data.hasSignatureFile) {
            dispatch(downloadElmoFile(contract.data.number, "asice"));
        }
    }, [dispatch, contract.data]);

    const downloadContractPdf = useCallback((fileId, name) => {
        dispatch(downloadElmoFileByFileId(id, fileId, name));
    }, [dispatch, id]);

    const downloadFile = useCallback((file) => {
        if (contract.data.hasSignatureFile) {
            downloadSignedContainer();
        } else {
            downloadContractPdf(file.id, file.name);
        }
    }, [contract.data, downloadContractPdf, downloadSignedContainer]);

    const getActiveContractLocations = useCallback(() => {
        if (!contract.fetched) return [];

        const activeLocations = contract.data.consumptionLocations;
        if (!activeLocations) return [];

        return activeLocations.map((location, index) =>
            <ContractInfoRow contract={contract.data} location={location} key={index} />
        );
    }, [contract]);

    const getFutureContractLocations = useCallback(() => {
        if (!contract.fetched) return [];

        const futureContractChanges = contract.data.futureContractChanges ?? [];

        if (futureContractChanges.length === 0) {
            return [
                <div className="p-10" key={0}>
                    <FormattedMessage id="ContractDetails.Table.NoFutureChanges" />
                </div>
            ];
        }

        return futureContractChanges.map((item, index) => {
            let location = item.consumptionLocation;
            location.product = item.product;
            return <ContractInfoRow contract={contract.data} location={location} key={index} />;
        })
    }, [contract]);

    const getActiveContractPdfButton = useCallback(() => {
        if (!contract.fetched) return null;

        const activeContractFiles = contract.data.files ?? [];
        if (activeContractFiles.length === 0) return null;

        const file = contract.data.files.find(f => f.name.includes(".pdf"));

        return <GridInner className="pt-10">
            <GridCell span={12}>
                <DownloadButton onClick={() => downloadFile(file)}
                >
                    <FormattedMessage id="ContractDetails.Download" />
                </DownloadButton>
            </GridCell>
        </GridInner>;

    }, [contract.data, contract.fetched, downloadFile]);

    const getFutureContractPdfButton = useCallback(() => {
        if (!contract.fetched) return null;

        const futureContractChanges = contract.data.futureContractChanges ?? [];
        if (futureContractChanges.length === 0) return null;

        const orderedChangesWithFiles = [...futureContractChanges]
            .filter(x => (x.files ?? []).length > 0)
            .sort((a, b) => sortByDate(a, b, "start"));
        if (orderedChangesWithFiles.length === 0) return null;

        const file = orderedChangesWithFiles[0].files[0];

        return <GridInner className="pt-10">
            <GridCell span={12} >
                <DownloadButton onClick={() => downloadContractPdf(file.id, file.name)} className="right">
                    <FormattedMessage id="ContractDetails.Download.Pdf" />
                </DownloadButton>
            </GridCell>
        </GridInner>;

    }, [contract, downloadContractPdf]);

    const tabItems = [
        {
            label: intl.formatMessage({ id: "ContractDetails.Tab.Current" }),
            contents: <>
                {getActiveContractPdfButton()}
                {getActiveContractLocations()}
            </>
        },
        {
            label: intl.formatMessage({ id: "ContractDetails.Tab.Future" }),
            contents: <>
                {getFutureContractPdfButton()}
                {getFutureContractLocations()}
            </>
        }
    ];

    useEffect(() => {
        dispatch(fetchElmoContractDetails(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (!activateGreenEnergy.fetching && activateGreenEnergy.fetched) {
            dispatch(fetchElmoContractDetails(id));
            setIsActivateSuccess(true);
            setIsDeactivateSuccess(false);
            setIsGreenEnergyModalOpen(false);
        }

        if (!deactivateGreenEnergy.fetching && deactivateGreenEnergy.fetched) {
            dispatch(fetchElmoContractDetails(id));
            setIsActivateSuccess(false);
            setIsDeactivateSuccess(true);
            setIsGreenEnergyModalOpen(false);
        }
    }, [dispatch, activateGreenEnergy, deactivateGreenEnergy, id]);

    useEffect(() => {
        if (!userStatusFetched && !userStatusFetching) {
            dispatch(fetchUserStatus());
        }
    }, [dispatch, userStatusFetched, userStatusFetching]);

    const isGreen = useCallback(() => {
        if (!contract.data.greenEnergy) return false;

        return moment() >= moment(contract.data.greenEnergy.start) &&
            (contract.data.greenEnergy.end == null || moment() <= moment(contract.data.greenEnergy.end));
    }, [contract.data]);

    const willBeGreenInTheFuture = useCallback(() => {
        if (!contract.data.greenEnergy) return false;

        return moment() < moment(contract.data.greenEnergy.start);
    }, [contract.data]);

    const renderGreenEnergyStatus = () => {
        if (isGreen()) {
            return (
                <span className="additional-service__status green">
                    <FormattedMessage id="ContractDetails.GreenEnergy.Status.Active" />
                </span>
            );
        }

        if (willBeGreenInTheFuture()) {
            return (
                <span className="additional-service__status light-green">
                    <FormattedMessage id="ContractDetails.GreenEnergy.Status.ToBeActive"
                        values={{ date: moment(contract.data.greenEnergy.start).format("DD.MM.YYYY") }} />
                </span>
            );
        }

        return (
            <span className="additional-service__status red">
                <FormattedMessage id="ContractDetails.GreenEnergy.Status.NotActivated" />
            </span>
        );
    }

    /**
     * Green energy section should not be visible for gas contracts.
     * Green energy section should always be visible if green energy is activated for current contract.
     * Green energy section should not be visible if any of the consumption locations has a restricted product.
     * @returns Green energy service block or null
     */
    const renderContractGreenEnergyServiceRow = () => {
        if (contract.data.type === Gas || contract.data.greenEnergy === null) return null;

        const isGreenActive = isGreen() || willBeGreenInTheFuture();

        if (!isGreenActive && contract.data.consumptionLocations.indexOf(c => RestrictedProductCodes.includes(c.product.productCode)) > -1) return null;

        return (
            <BadgeCard
                className="services-wrapper mt-30"
                title={<FormattedMessage id="ContractDetails.GreenEnergy.Service.Title" />}
                description={<FormattedMessage id="ContractDetails.GreenEnergy.Service.Desc" />}
                subTitle={renderGreenEnergyStatus()}
                icon={greenEnergyLogoSmall}
                type="custom"
                highlighted={false}
                contentChildren={
                    <>
                        {contract.data.greenEnergy?.marginal != null &&
                            <p><FormattedMessage id="ContractDetails.GreenEnergy.Service.Marginal" />: {contract.data.greenEnergy?.marginal?.value} {contract.data.greenEnergy?.marginal?.unit}</p>
                        }
                        {contract.data.greenEnergy?.start != null &&
                            <p><FormattedMessage id="ContractDetails.GreenEnergy.Service.StartDate" />: {moment(contract.data.greenEnergy?.start).format("DD.MM.YYYY")}</p>
                        }
                        {contract.data.greenEnergy?.end != null &&
                            <p><FormattedMessage id="ContractDetails.GreenEnergy.Service.EndDate" />: {moment(contract.data.greenEnergy?.end).format("DD.MM.YYYY")}</p>
                        }
                    </>
                }
                actions={
                    <Button onClick={() => setIsGreenEnergyModalOpen(true)}>
                        {!isGreenActive
                            ? <FormattedMessage id="ContractDetails.GreenEnergy.Service.Order" />
                            : <FormattedMessage id="Info" />
                        }
                    </Button>
                }
            />
        );
    }

    const onPackageChangeClick = () => {
        if (contract.data.type === Gas)
            history.push(getGasContractChangePath(id));
        else
            history.push(getElectricityContractChangePath(id));
    }

    const renderContractPackageChangeServiceRow = () => {
        if (!contract.fetched || contract.data.canExtend)
            return null;

        const canChangePackage = contract.data.contractChangeAllowed;
        const descriptionMessageType = contract.data.type === Gas ? GasNew : ElectricityNew;
        const descriptionMessageId = canChangePackage
            ? `ContractDetails.PackageChange.${descriptionMessageType}.Desc`
            : `ContractDetails.PackageChange.${descriptionMessageType}.Desc.CanNot.Change`;

        return (
            <GridCell span={12}>
                <BadgeCard
                    className="services-wrapper"
                    title={<FormattedMessage id="ContractDetails.PackageChange.Title"/>}
                    description={<FormattedMessage id={descriptionMessageId}/>}
                    icon={contract.data.type === Gas ? "gas" : "electricity"}
                    type={BadgeCard.TYPE.NEW}
                    highlighted={false}
                    actions={canChangePackage &&
                        <Button onClick={onPackageChangeClick}>
                            <FormattedMessage id="ContractDetails.PackageChange.Start"/>
                        </Button>
                    }
                />
            </GridCell>
        );
    }

    const renderErrorMessage = () => {
        if (!contract.error) return null;

        return (
            <Grid>
                <GridCell span={12}>
                    <BackButton toPath={ContractsPath} label="ContractDetails.Back" />
                </GridCell>
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="ContractDetails.Error" />}
                    />
                </GridCell>
            </Grid>
        );
    }

    if (contract.error) {
        return renderErrorMessage();
    }

    if (!contract.fetched || contract.fetching) {
        return <Loader type={Loader.TYPE_CENTER} />
    }

    function onRenewClick() {
        history.push(getElectricityContractRenewalPath(id));
    }

    const modalType = (!willBeGreenInTheFuture() && !isGreen()) || (isGreen() && contract.data.greenEnergy?.end != null)
        ? GreenEnergyModalType.Activate
        : GreenEnergyModalType.Deactivate;

    return (
        <Grid>
            <GridCell span={12}>
                <BackButton toPath={ContractsPath} label="ContractDetails.Back" />
            </GridCell>

            {isActivateSuccess && contract.fetched &&
                <GridCell span={12}>
                    <AlertMessage type={AlertMessage.TYPE_DONE}
                        scrollToMessage={true}
                        title={<FormattedMessage id="ContractDetails.GreenEnergy.ActivateSuccess" />} />
                </GridCell>
            }
            {isDeactivateSuccess && contract.fetched &&
                <GridCell span={12}>
                    <AlertMessage type={AlertMessage.TYPE_DONE}
                        scrollToMessage={true}
                        title={<FormattedMessage id="ContractDetails.GreenEnergy.DeactivateSuccess" />} />
                </GridCell>
            }

            <GridCell span={12} className="mdc-theme--primary mb-15">
                <Typography use="headline3">
                    <FormattedMessage id="ContractDetails.ContractNr" values={{ number: contract.data.number }} />
                </Typography>
            </GridCell>
            {contract.fetched && contract.data.canExtend &&
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        description={
                            <GridInner span={12} className="p-0">
                                <GridCell desktop={10} phone={12} tablet={6}>
                                    <b><FormattedMessage id="ContractDetails.ContractIsEnding"/></b>
                                </GridCell>
                                <GridCell desktop={2} phone={12} tablet={2} className="alert--child-right">
                                    <Button unelevated onClick={onRenewClick}>
                                        <FormattedMessage id="ContractDetails.ExtendContract"/>
                                    </Button>
                                </GridCell>
                            </GridInner>
                        }
                    />
                </GridCell>
            }
            <GridCell span={12}>
                <Tabs items={tabItems}/>
            </GridCell>
            <GridCell span={12}>
                {renderContractGreenEnergyServiceRow()}
            </GridCell>
            {renderContractPackageChangeServiceRow()}

            {contract.data.greenEnergy &&
                <BaseDialog
                    isOpen={isGreenEnergyModalOpen}
                    onClose={() => setIsGreenEnergyModalOpen(false)}
                >
                    <GreenEnergyService contract={contract.data} modalType={modalType} />
                </BaseDialog>
            }
        </Grid>
    )
}

export default ElmoContractDetails;
