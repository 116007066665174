import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import OverviewNotices from "../components/common/OverviewNotices";
import { ClientManager, Contacts, Contracts, Invoices, Transactions } from "../components/BusinessOverview";
import TitleWithLoader from "../components/common/TitleWithLoader";

import "../styles/react-components/business-overview.scss";

const BusinessOverview = () => {

    const { fetching: clientManagersFetching } = useSelector(state => state.responsiblePersons);

    return (
        <Grid className="mdc-layout-grid--base mdc-layout-grid--base-narrow grid-gap-48 grid-gap-tablet-32 grid-gap-mobile-24">
            <GridCell span={12} className="grid-gap-32 grid-gap-tablet-24 grid-gap-mpbile-16">
                <GridInner >
                    <GridCell span={12}>
                        <TitleWithLoader
                            title={<FormattedMessage id="BusinessOverview.Contacts.Title" />}
                            isLoading={clientManagersFetching} />
                    </GridCell>
                    <GridCell span={12} >
                        <GridInner>
                            <GridCell desktop={6} tablet={4} phone={4}>

                                <ClientManager />

                            </GridCell>
                            <GridCell desktop={6} tablet={4} phone={4} className="grid-gap-16">

                                <Contacts />

                            </GridCell>
                        </GridInner>
                    </GridCell>
                    <GridCell span={12}>

                        <OverviewNotices />

                    </GridCell>
                </GridInner>
            </GridCell >
            <GridCell span={12} className="grid-gap-32 grid-gap-tablet-24 grid-gap-mobile-16">

                <Invoices />

            </GridCell>
            <GridCell span={12} className="grid-gap-32 grid-gap-tablet-24 grid-gap-mobile-16">

                <Transactions />

            </GridCell>
            <GridCell span={12} className="grid-gap-32 grid-gap-tablet-24 grid-gap-mobile-16">

                <Contracts  />

            </GridCell>
        </Grid >
    );
}

export default injectIntl(BusinessOverview);
