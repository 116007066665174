import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";

export const enablePartnerApiAccess = (contractId) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_PARTNER_API_SUBSCRIBE',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/${contractId}/partnerapi/enable`)
    };
};

export const disablePartnerApiAccess = (contractId) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_PARTNER_API_UNSUBSCRIBE',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/${contractId}/partnerapi/disable`)
    };
};

export const activatePartnerApi = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: 'POST_PARTNER_API_ACTIVATE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/partnerapi/activate`)
    };
}

export const deactivatePartnerApi = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: 'POST_PARTNER_API_DEACTIVATE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/partnerapi/deactivate`)
    };
}

export const hasPartnerApiAccess = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: 'FETCH_PARTNER_API_ACCESS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/partnerapi/access`)
    };
}