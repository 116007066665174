import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import BaseDialog from "../components/common/BaseDialog";
import { ContractsPath, CommunityPath } from "../const/routes";
import { fetchContract, updateContract } from "../actions/contractActions";
import { fetchUserRoleInCompany } from "../actions/accountActions";

import { enablePartnerApiAccess, disablePartnerApiAccess } from "../actions/partnerApiActions";
import { downloadCommunityContractDigiDoc } from "../actions/communityProjectActions";
import auth from "../auth/authenticate";

import SimpleReactValidator from "simple-react-validator";
import TextFieldGroup from "../components/common/TextFieldGroup";
import CheckboxField from "../components/common/CheckboxField";

import "../styles/components/_contract-details.scss";
import BaseFormComponent from "../components/common/BaseFormComponent";
import InfoTooltip from "../components/common/InfoTooltip";
import classnames from "classnames";
import BackButton from "../components/common/BackButton";
import { InvoiceFrequency } from "../const/Invoice";
import PartnerApiForm from "../components/PartnerApi/PartnerApiForm";

class FuelContractDetails extends BaseFormComponent {
    static AccountTypePermanentCredit = "ACCOUNT_TYPE.PERMANENT_CREDIT";
    static AccountTypeDebit = "ACCOUNT_TYPE.DEBIT";

    constructor(props) {
        super(props);

        this.state = {
            isBoardMember: false,
            isEditMode: false,
            isPartneApiDialogOpen: false,
            showSuccessResponse: false,
            isCompany: auth.isCompany() || false,
            isContractActive: false,
            isRegisteredToCommunityProject: false,
            showContractUpdateSuccessResponse: false,
            infoEmail: "",
            invoiceEmail: "",
            deliverEmail: false,
            deliverMail: false,
            deliverEinvoice: false,
            deliverLimitedEinvoice: false,
            isCommunityProjectAvailable: false
        };

        this.downloadContractDigiDoc = this.downloadContractDigiDoc.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    onEditClick() {
        if (this.state.isEditMode) {

            let form = {
                contractId: this.props.contract.id,
                invoiceEmail: this.state.invoiceEmail,
                infoEmail: this.state.infoEmail,
                deliverEmail: this.state.deliverEmail,
                deliverMail: this.state.deliverMail,
                deliverEinvoice: this.state.deliverEinvoice,
                deliverLimitedEinvoice: this.state.deliverLimitedEinvoice

            }

            this.props.updateContract(form).then(() => {
                this.setState({
                    isEditMode: false,
                    showContractUpdateSuccessResponse: true
                })

                this.props.fetchContract(this.props.match.params.id);
            });
        } else {
            this.setState({
                isEditMode: true
            })
        }
    }

    cancelEdit() {
        this.setState({
            isEditMode: false
        })
    }

    componentDidMount() {
        if (this.state.isCompany && !this.props.fetchingUserRoleInCompany && !this.props.fetchedUserRoleInCompany) {
            this.props.fetchUserRoleInCompany().then(() => {
                this.setState({ isBoardMember: this.props.userRoleInCompany?.isBoardMember || false });
            });
        }

        if (!this.props.fetchingContract && !this.props.fetchedContract) {
            this.props.fetchContract(this.props.match.params.id).then(() => {
                const contract = this.props.contract;
                const isCommunityProjectSectionHidden = contract.accountTypeXc === FuelContractDetails.AccountTypeDebit ||
                    (contract.accountTypeXc === FuelContractDetails.AccountTypePermanentCredit && contract.limit === 0);

                this.setState({
                    invoiceEmail: contract.invoiceEmail,
                    infoEmail: contract.infoEmail,
                    deliverEinvoice: contract.deliverEinvoice,
                    deliverEmail: contract.deliverEmail,
                    deliverMail: contract.deliverMail,
                    deliverLimitedEinvoice: contract.deliverLimitedEinvoice,
                    isCommunityProjectAvailable: !isCommunityProjectSectionHidden
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.disablePartnerApiAccessFetching && prevProps.disablePartnerApiAccessFetching && this.props.disablePartnerApiAccessFetched) {
            this.props.fetchContract(this.props.match.params.id);

            this.setState({
                isPartneApiDialogOpen: false
            });
        }

        if (!this.props.enablePartnerApiAccessFetching && prevProps.enablePartnerApiAccessFetching && this.props.enablePartnerApiAccessFetched) {
            this.props.fetchContract(this.props.match.params.id);

            this.setState({
                isPartneApiDialogOpen: false
            });
        }

        if (!this.props.fetchingContract && prevProps.fetchingContract && this.props.fetchedContract) {
            this.setState({
                isContractActive: this.props.contract.status === "Active",
                isRegisteredToCommunityProject: this.props.contract.isRegisteredToCommunityProject
            });
        }
    }

    enablePartnerApiAccess(contract) {
        this.props.enablePartnerApiAccess(contract.id).then(() => {
            this.setState({
                showSuccessResponse: true
            });
        });
    }

    disablePartnerApiAccess(contract) {
        this.props.disablePartnerApiAccess(contract.id).then(() => {
            this.setState({
                showSuccessResponse: true
            });
        });
    }

    toggleDialog() {
        this.setState({ isPartneApiDialogOpen: !this.state.isPartneApiDialogOpen });
    }

    closeModal() {
        this.setState({ isPartneApiDialogOpen: false });
    }

    downloadContractDigiDoc() {
        const data = {
            customerName: this.props.contract.customerName,
            contractNumber: this.props.contract.number
        };

        this.props.downloadCommunityContractDigiDoc(data);
    }

    renderErrorMessage() {
        return (
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={<FormattedMessage id="ContractDetails.Error" />}
                />
            </GridCell>
        );
    }

    renderCommunityProjectSection() {
        return (
            <Grid>
                <GridCell span={12} className="mdc-typography mdc-typography--subtitle1">
                    <FormattedMessage id="ContractDetails.Fuel.Services.Community" />
                </GridCell>

                {this.state.isRegisteredToCommunityProject &&
                    this.renderAlreadyCommunityMemberSection()
                }
                {!this.state.isRegisteredToCommunityProject &&
                    <>
                        <GridCell span={12}>
                            <div className="information__description lighter">
                                <FormattedMessage id="ContractDetails.Fuel.Community.Desc" />
                            </div>
                            <hr className="divider divider--secondary" />
                        </GridCell>
                        <GridCell span={12}>
                            <Link to={{
                                    pathname: CommunityPath,
                                    state: {
                                        contractNumber: this.props.contract.number
                                    }
                                }}
                                className={classnames(!this.state.isContractActive ? "link-disabled" : "")}
                            >
                                <Button
                                    className="mdc-button--primary"
                                    unelevated
                                    disabled={!this.state.isContractActive}
                                >
                                    <FormattedMessage id="ContractDetails.Fuel.Community.Join" />
                                </Button>
                            </Link>
                        </GridCell>
                    </>
                }
            </Grid>
        );
    }

    renderAlreadyCommunityMemberSection() {
        return (
            <>
            <GridInner className="pt-15">
                <GridCell className="d-flex">
                    <FormattedMessage id="ContractDetails.Fuel.Community.ContractFile" />
                    <Button
                        className="mdc-button--text mdc-button--secondary bold"
                        onClick={this.downloadContractDigiDoc}>
                        <i className="icon icon-pdf pl-15"></i>
                        <FormattedMessage id="ContractDetails.Fuel.Community.ContractDigiDoc" />
                    </Button>
                </GridCell>
            </GridInner>
            <GridCell span={12} className="pt-15 wrapper--relative">
                <AlertMessage
                    type={this.state.isContractActive
                        ? AlertMessage.TYPE_DONE
                        : AlertMessage.TYPE_NOTICE}
                    isSmall={true}
                    title={this.state.isContractActive
                        ? <FormattedMessage id="ContractDetails.Fuel.Community.AlreadyRegistered" />
                        : <FormattedMessage id="ContractDetails.Fuel.Community.ContractExpired" />}
                >
                    <Button
                        className="mdc-button--text mdc-button--text-overlayed right mdc-button--secondary pr-24"
                        trailingIcon={{
                            icon: "arrow-right",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                        }}
                        tag={Link}
                        to={CommunityPath}
                    >
                        {this.state.isContractActive
                            ? <FormattedMessage id="ContractDetails.Fuel.Community.Visit" />
                            : <FormattedMessage id="ContractDetails.Fuel.Community.Statistics" />
                        }
                    </Button>
                </AlertMessage>
            </GridCell>
            </>
        );
    }

    renderResponseMessages() {
        return (
            <>
                {this.state.showContractUpdateSuccessResponse &&
                    <AlertMessage
                        type={AlertMessage.TYPE_DONE}
                        isSmall={true}
                        scrollToMessage={true}
                        title={<FormattedMessage id="Lepingu andmed uuendatud" />}
                        className="mb-15" />
                }

                {this.props.updateContractError &&
                    <AlertMessage type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id="Lepingu uuendamine ebaõnnestus" />}
                        scrollToMessage={true}
                        className="mb-15"
                    />
                }

                {this.state.showSuccessResponse &&
                    <AlertMessage
                        type={AlertMessage.TYPE_DONE}
                        isSmall={true}
                        scrollToMessage={true}
                        title={<FormattedMessage id="ContractDetails.Fuel.PartnerApi.Success.Response" />}
                        className="mb-15" />
                }
                {(this.props.disablePartnerApiAccessError || this.props.enablePartnerApiAccessError) &&
                    <AlertMessage type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id="ContractDetails.Fuel.PartnerApi.Error.Response" />}
                        scrollToMessage={true}
                        className="mb-15"
                    />
                }
            </>
        );
    }

    renderContractDetails() {
        const contract = this.props.contract;
        const frequency = InvoiceFrequency.find(x => x.label === contract.invoiceFrequencyXc);
        const frequencyTranslation = this.props.intl.formatMessage({ id: frequency.value });

        return (
            <>
                <div className="contract-header">
                    <div>
                        <Typography
                            use="headline3"
                            tag="h3"
                            className="mdc-typography mdc-theme--primary mb-15"
                        >
                            <FormattedMessage id="ContractDetails.ContractNr"
                                        values={{number: contract.extraNumber || contract.number}} />
                        </Typography>
                    </div>
                </div>
                <div className="mb-10 mb-mobile-30 mt-30">
                    <table className="table-detail-list">
                        <tbody>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id="ContractDetails.Fuel.Limit" />
                                </td>
                                <td>
                                    {contract.limit}
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.LimitBalance"} />
                                </td>
                                <td>
                                    {contract.limitBalance}
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.BookingAmount"} />
                                </td>
                                <td>
                                    {contract.bookingAmount}
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.CurrentBalance"} />
                                </td>
                                <td>
                                    {contract.currentBalance}
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.InvoiceEmail"} />
                                </td>
                                <td>
                                    {this.state.isEditMode
                                    ? <TextFieldGroup
                                            label="Test"
                                            value={this.state.invoiceEmail}
                                            field="invoiceEmail"
                                            onChange={this.onChange}
                                            rules="required|email"
                                            noLabel={true}
                                        />
                                    : contract.invoiceEmail
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.InfoEmail"} />
                                </td>
                                <td>
                                    {this.state.isEditMode
                                        ? <TextFieldGroup
                                                label="Test"
                                                value={this.state.infoEmail}
                                                field="infoEmail"
                                                onChange={this.onChange}
                                                rules="required|email"
                                                noLabel={true}
                                            />
                                        : contract.infoEmail
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.PostalAddress"} />
                                </td>
                                <td>
                                    {contract.postalAddress}
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.InvoiceFrequency"} />
                                </td>
                                <td>
                                    {frequencyTranslation}
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <FormattedMessage id={"ContractDetails.Fuel.DueDays"} />
                                </td>
                                <td>
                                    {contract.dueDays}
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <div className="d-flex">
                                        <FormattedMessage id={"ContractDetails.Fuel.DeliverEmail"} />
                                        <div className="ml-45">
                                            <InfoTooltip content={"ContractDetails.Fuel.Email.Info"} className="badge-standalone-tooltip" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <CheckboxField
                                        disabled={true} //!this.state.isEditMode
                                        nativeControlId="deliver-email"
                                        field={"deliverEmail"}
                                        checked={this.state.deliverEmail}
                                        indeterminate={false}
                                        onChange={this.onChangeCheckbox}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <div className="d-flex">
                                        <FormattedMessage id={"ContractDetails.Fuel.DeliverMail"} />
                                        <div className="ml-45">
                                            <InfoTooltip content={"ContractDetails.Fuel.Email.Info"} className="badge-standalone-tooltip" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <CheckboxField
                                        disabled={true} //!this.state.isEditMode
                                        nativeControlId="deliver-mail"
                                        field={"deliverMail"}
                                        checked={this.state.deliverMail}
                                        indeterminate={false}
                                        onChange={this.onChangeCheckbox}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <div className="d-flex">
                                        <FormattedMessage id={"ContractDetails.Fuel.DeliverEinvoice"} />
                                        <div className="ml-45">
                                            <InfoTooltip content={"ContractDetails.Fuel.Email.Info"} className="badge-standalone-tooltip" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <CheckboxField
                                        disabled={true}
                                        nativeControlId="deliver-einvoice"
                                        field={"deliverEinvoice"}
                                        checked={this.state.deliverEinvoice}
                                        indeterminate={false}
                                        onChange={this.onChangeCheckbox}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="bold">
                                    <div className="d-flex">
                                        <FormattedMessage id={"ContractDetails.Fuel.DeliverLimitedEinvoice"} />
                                        <div className="ml-45">
                                            <InfoTooltip content={"ContractDetails.Fuel.Email.Info"} className="badge-standalone-tooltip" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <CheckboxField
                                        disabled={true}
                                        nativeControlId="deliver-limited-einvoice"
                                        field={"deliverLimitedEinvoice"}
                                        checked={this.state.deliverLimitedEinvoice}
                                        indeterminate={false}
                                        onChange={this.onChangeCheckbox}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {this.state.isEditMode &&
                    <Button
                        className="mt-15 mr-24 ml-mobile-10 mr-mobile-10"
                        outlined
                        onClick={this.cancelEdit.bind(this)}
                    >
                        <FormattedMessage id="ContractsPage.AddContract.Cancel" />
                    </Button>
                }

                {this.state.isBoardMember &&
                    <Button
                    className="mt-15 mr-24 mb-mobile-15 ml-mobile-10 mr-mobile-10"
                    outlined
                    onClick={this.onEditClick.bind(this)}
                >
                    <FormattedMessage
                        id={this.state.isEditMode
                            ? "ContractsPage.AddContract.UpdateUserData"
                            : "ContractsPage.AddContract.EditUserData"}
                    />
                </Button>
                }
            </>
        );
    }


    renderContractServices() {
        const contract = this.props.contract;
        let username = '';
        let secret = '';
        let hasPartnerApiAccess = false;

        if (this.props.fetchedContract &&  contract.partnerApiUser !== null) {
            username = contract.partnerApiUser.name;
            secret = contract.partnerApiUser.secret;
            hasPartnerApiAccess = true;
        }

        return (
                <>
                    {this.state.isCompany &&
                        <div className="mt-30">
                            <GridCell span={12} className="mdc-typography mdc-typography--headline4 mdc-typography--bold mb-25">
                                <FormattedMessage id="ContractDetails.Fuel.Services.Title" />
                            </GridCell>

                            {this.state.isCommunityProjectAvailable && this.renderCommunityProjectSection()}

                            <PartnerApiForm
                                username={username}
                                secret={secret}
                                hasPartnerApiAccess={hasPartnerApiAccess}
                                onClickButton={this.toggleDialog.bind(this)}
                            />
                        </div>
                    }

                    <BaseDialog
                        isOpen={this.state.isPartneApiDialogOpen}
                        onClose={this.closeModal.bind(this)}
                    >
                        <div className="text-center">
                            { hasPartnerApiAccess
                                ? <>
                                    <h1 className="mdc-typography mdc-typography--headline3 mdc-theme--primary information__head">
                                        <FormattedMessage id= "ContractDetails.Fuel.Confirm.Deactivate" />
                                    </h1>
                                    <div className="mt-30">
                                        <Button
                                            className="mdc-button--primary mr-15 mb-mobile-10"
                                            unelevated
                                            onClick={this.disablePartnerApiAccess.bind(this, contract)}
                                        >
                                            {this.props.disablePartnerApiAccessFetching ?
                                                <Loader type={Loader.TYPE_BUTTON} />
                                                :
                                                <FormattedMessage id="Jah" />
                                            }
                                        </Button>
                                        <Button
                                            className="mdc-button--primary"
                                            unelevated
                                            onClick={this.closeModal.bind(this)}
                                        >
                                            <FormattedMessage id="Ei" />
                                        </Button>
                                    </div>
                                </>
                                : <>
                                    <h1 className="mdc-typography mdc-typography--headline3 mdc-theme--primary information__head">
                                        <FormattedMessage id= "ContractDetails.Fuel.Confirm.Activate" />
                                    </h1>
                                    <div className="mt-30">
                                        <Button
                                            className="mdc-button--primary mr-15 mb-mobile-10"
                                            unelevated
                                            onClick={this.enablePartnerApiAccess.bind(this, contract)}
                                        >
                                            {this.props.enablePartnerApiAccessFetching ?
                                                <Loader type={Loader.TYPE_BUTTON} />
                                                :
                                                <FormattedMessage id="General.Yes" />
                                            }
                                        </Button>
                                        <Button
                                            className="mdc-button--primary"
                                            unelevated
                                            onClick={this.closeModal.bind(this)}
                                        >
                                            <FormattedMessage id="General.No" />
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    </BaseDialog>
                </>
        );
    }

    renderContractOrError() {
        const hasContract = !this.props.fetchingContract && this.props.fetchedContract && Object.keys(this.props.contract).length > 0;

        return hasContract
            ? <>
                <GridCell span={12}>
                    {this.renderContractDetails()}
                </GridCell>
                {this.state.isCompany &&
                    <GridCell span={12}>
                        {this.renderContractServices()}
                    </GridCell>
                }
            </>
            : this.renderErrorMessage();
    }

    render() {
        return (
            <>
                <Grid span={12}>
                    <GridCell span={12}>
                        <BackButton toPath={ContractsPath} label="ContractDetails.Back" />
                    </GridCell>
                    <GridCell span={12}>
                        {this.renderResponseMessages()}
                    </GridCell>

                    {this.props.fetchingContract
                        ? <Loader type={Loader.TYPE_CENTER} />
                        : this.renderContractOrError()
                    }
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        contract: state.contract.data,
        fetchingContract: state.contract.fetching,
        fetchedContract: state.contract.fetched,
        updateContract: state.updateContract.data,
        fetchingUpdateContract: state.updateContract.fetching,
        fetchedUpdateContract: state.updateContract.fetched,
        updateContractError: state.updateContract.error,
        enablePartnerApiAccess: state.enablePartnerApiAccess.data,
        enablePartnerApiAccessFetching: state.enablePartnerApiAccess.fetching,
        enablePartnerApiAccessFetched: state.enablePartnerApiAccess.fetched,
        enablePartnerApiAccessError: state.enablePartnerApiAccess.error,
        disablePartnerApiAccess: state.disablePartnerApiAccess.data,
        disablePartnerApiAccessFetching: state.disablePartnerApiAccess.fetching,
        disablePartnerApiAccessFetched: state.disablePartnerApiAccess.fetched,
        disablePartnerApiAccessError: state.disablePartnerApiAccess.error,
        userRoleInCompany: state.userRoleInCompany.data,
        fetchingUserRoleInCompany: state.userRoleInCompany.fetching,
        fetchedUserRoleInCompany: state.userRoleInCompany.fetched
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchContract, enablePartnerApiAccess, disablePartnerApiAccess, updateContract, fetchUserRoleInCompany, downloadCommunityContractDigiDoc}, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(FuelContractDetails));