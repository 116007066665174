import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import "../../styles/react-components/datepicker.scss";
import { defaultRanges, createRangePresets } from "./DateRangePreset";
import SelectAutoCompleteField from "./SelectAutoCompleteField";

class SelectDateRangePreset extends React.Component {

    render() {
        return (
            <SelectAutoCompleteField
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.props.onChange}
                field={this.props.field}
                label={this.props.label}
                options={this.props.options}
                noFilter={true} />
        );
    }
}

SelectDateRangePreset.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
};

SelectDateRangePreset.defaultProps = {
    options: createRangePresets(defaultRanges),
    value: createRangePresets(defaultRanges)[0].label,
    placeholder: "General.Select",
};

export default injectIntl(SelectDateRangePreset);