import { SCREEN_RESIZE } from "../../actions/uiActions";

const TABLET_BREAKPOINT = 1024;

function isMobileOrTabletView(screenWidth) {
    return !!screenWidth && screenWidth < TABLET_BREAKPOINT;
}

const initialScreenWidth = typeof window === 'object' ? window.innerWidth : null;

export default function reducer(
    state = {
        screenWidth: initialScreenWidth,
        isMobileOrTabletView: isMobileOrTabletView(initialScreenWidth)
    },
    action
) {
    switch (action.type) {
        case SCREEN_RESIZE:
            return {
                ...state,
                screenWidth: action.payload.screenWidth,
                isMobileOrTabletView: isMobileOrTabletView(action.payload.screenWidth)
            };
        default:
            return state;
    }
}