import { Button } from "@rmwc/button";
import { GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { resetCachedFilterToDefault } from "../../cachedFilter";
import Loader from "../common/Loader";

const DEFAULT_LOADING_TIME_SECONDS = 2;

const CachedFilterSettings = () => {
    const [isResetting, setIsResetting] = useState(false);
    const [timer, setTimer] = useState(DEFAULT_LOADING_TIME_SECONDS);
    const [intervalId, setIntervalId] = useState(null);
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        if (timer === 0) {
            clearInterval(intervalId);
            setIntervalId(null);
            setIsResetting(false);
            setTimer(DEFAULT_LOADING_TIME_SECONDS);
            setIsDone(true);
        }
    }, [timer, intervalId]);

    const resetCachedFilters = () => {
        const interval = setInterval(() => setTimer(seconds => seconds - 1), 1000);
        setIntervalId(interval);
        setIsResetting(true);
        resetCachedFilterToDefault();
    }

    return (
        <GridInner className="mt-30">
            <GridCell span={12}>
                <Typography
                    use="headline3"
                    className="mdc-typography mdc-theme--primary mb-mobile-20"
                >
                    <FormattedMessage id="Settings.CachedFilter.Title" />
                </Typography>
            </GridCell>
            <GridCell span={12}>
                {!isDone &&
                <Button unelevated onClick={resetCachedFilters}>
                    {isResetting
                        ? <Loader type={Loader.TYPE_BUTTON} />
                        : <FormattedMessage id="Settings.CachedFilter.Reset" />
                    }
                </Button>
                }
                {isDone &&
                    <>
                        <FormattedMessage id="Settings.CachedFilter.Done" />
                        <span className="checkmark-green"></span>
                    </>
                    }
            </GridCell>
        </GridInner>
    );
}

export default CachedFilterSettings;