import { NewInvoice, NewOccInvoice } from "../const/notificationType";
import { getElectricityInvoiceDetailsPath, getPaymentCardInvoiceDetailsPath } from "../const/routes";

const getNotificationActionInfo = (notificationType, metaInfo, history, intl) => {

    switch (notificationType) {
        case NewInvoice:
            const invoiceId = metaInfo["InvoiceId"];
            return !!invoiceId
                ? {
                    action: () => history.push(getElectricityInvoiceDetailsPath(invoiceId)),
                    text: intl.formatMessage({ id: "Notifications.Actions.OpenInvoice" })
                }
                : {};
        case NewOccInvoice:
            const occInvoiceId = metaInfo["InvoiceId"];
            return !!occInvoiceId
                ? {
                    action: () => history.push(getPaymentCardInvoiceDetailsPath(occInvoiceId)),
                    text: intl.formatMessage({ id: "Notifications.Actions.OpenInvoice" })
                }
                : {};
        default: return {};
    }
}

export { getNotificationActionInfo };