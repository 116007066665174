import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift'
import classnames from 'classnames';
import TextField, { Input } from '@material/react-text-field';
import { MenuList, MenuListItem, MenuListItemText } from '@material/react-menu';
import Loader from "../common/Loader";
import ValidationHelper from "../../helpers/validationHelper";
import { useIntl } from 'react-intl';

const StationSearchField = ({ field, value, label, onChange, onInputChange, type, options, afterChange, disabled, searchPlaceholder, isLoading, placeholder, validator, rules, iconTranslation }) => {
    const intl = useIntl();
    let input = null;

    let labelTranslated = label ? intl.formatMessage({ id: label }) : null;
    const isObject = (item) => (typeof item === 'object');
    const selectedItemChanged = (prevItem, item) => {
        let isItemChanged = prevItem !== item;

        if (isItemChanged && afterChange) {
            afterChange();
        }

        return isItemChanged;
    };

    const stateChanged = (changes) => {
        if (changes.isOpen && !!input) {
            input.inputElement.focus();
        }
    };

    const selectedItemEquals = (selectedItem, item) => {
        if (selectedItem && item && selectedItem.value && item.value) {
            return selectedItem.value === item.value;
        }

        return selectedItem === item;
    };

    const itemToString = (item) => {
        if (!item) {
            return "";
        }

        return !item.address
            ? item.name
            : item.address;
    };

    const autoComplete = ({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex, selectedItem, clearSelection, toggleMenu }) => {
        const clearSelectedText = () => {
            clearSelection();
            toggleMenu();
        };

        const trailingIcon = value
            ? <span className="icon-close"></span>
            : null;

        const { helperText } = ValidationHelper.getFieldValidationItems(validator, rules, labelTranslated, value);

        return (<div className="mdc-menu-surface--anchor mdc-menu-search">
            <TextField
                outlined
                helperText={helperText}
                disabled={disabled}
                label={labelTranslated}
                leadingIcon={<i className="icon-search"></i>}
                trailingIcon={isLoading ? <Loader type={Loader.TYPE_TEXT_FIELD} /> : trailingIcon}
                onTrailingIconSelect={value ? clearSelectedText : null}>
                <Input
                    ref={elem => (input = elem)}
                    {...getInputProps({
                        autoComplete: "off",
                        name: field,
                        type: type
                    })} />
            </TextField>
            <div {...getMenuProps({ className: classnames("mdc-menu mdc-menu-surface full-width", { "mdc-menu-surface--open": isOpen }) })}>

                <MenuList className="mdc-list--select mdc-list--verbose">
                    {isOpen && options
                        ? options
                            .map((item, index) => (
                                <MenuListItem
                                    {...getItemProps({
                                        key: isObject(item) ? item.id : index,
                                        index,
                                        item,
                                        className: classnames({
                                            "mdc-list-item--selected": selectedItemEquals(selectedItem, item),
                                            "mdc-list-item--activated": highlightedIndex === index
                                        })
                                    })}
                                >
                                    <div className={classnames("icon",
                                        "mdc-list-item-container",
                                        iconTranslation(item)
                                    )}>
                                        <MenuListItemText primaryText={item.name} />
                                        <MenuListItemText className="mdc-list-item--small" primaryText={item.address} />
                                    </div>
                                </MenuListItem>
                            ))
                        : null}
                </MenuList>
            </div>
        </div>);
    }

    return (
        <Downshift
            selectedItemChanged={selectedItemChanged}
            selectedItem={value}
            onStateChange={stateChanged}
            onChange={onChange}
            itemToString={itemToString}
            onInputValueChange={onInputChange}
        >
            {autoComplete}
        </Downshift>);
}

StationSearchField.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),
    label: PropTypes.string,
    searchPlaceholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onChange: PropTypes.func.isRequired,
    afterChange: PropTypes.func,
    onInputChange: PropTypes.func.isRequired,
    iconTranslation: PropTypes.func,
    type: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    validator: PropTypes.object,
    rules: PropTypes.string
};

StationSearchField.defaultProps = {
    type: "search",
    searchPlaceholder: "",
    isLoading: false
};

export default StationSearchField;

