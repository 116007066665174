import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@rmwc/button";
import { Dialog, DialogTitle, DialogButton, DialogContent } from "@rmwc/dialog";
import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import Checkbox from '@material/react-checkbox';
import Loader from "../common/Loader";
import AlertMessage from "../common/AlertMessage";
import TextFieldGroup from "../common/TextFieldGroup";
import AddressForm from "../AddressForm/AddressForm";
import { useValidator } from "../../helpers/hooks";
import { orderCardPrivateCustomer } from "../../actions/cardActions";
import { Active } from "../../const/ContractStatus";
import { Fuel } from "../../const/contractType";

const NewCardDialogPrivateCustomer = ({ isOpen, onClose, onSuccess }) => {
    const contracts = useSelector(state => state.contracts);
    const orderCard = useSelector(state => state.orderCard);

    const [cardName, setCardName] = useState("");
    const [deliveryAddress, setDeliveryAddress] = useState({});
    const [isPlasticCard, setIsPlasticCard] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [contractId, setContractId] = useState();
    const [isError, setIsError] = useState(false);

    const intl = useIntl();
    const dispatch = useDispatch();
    const [validator, ] = useValidator();

    useEffect(() => {
        const currentActiveContract = contracts.data.find(c => c.status === Active && c.type === Fuel);

        if (!currentActiveContract) {
            onClose();
            return;
        }

        setDeliveryAddress({
            county: currentActiveContract.deliveryAddressCounty,
            city: currentActiveContract.deliveryAddressTown,
            street: currentActiveContract.deliveryAddressStreet,
            postalCode: currentActiveContract.deliveryAddressPostalCode
        });
        setContractId(currentActiveContract.id);
    }, [contracts.data]);

    useEffect(() => {
        if (orderCard.error) {
            setIsLoading(false);
            setIsError(true);
            return;
        }

        if (orderCard.fetched) {
            setIsLoading(false);
            onSuccess("Cards.Ordered");
            onClose();
        }
    }, [orderCard])

    const renderDialogTitle = () => {
        return (
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss" />
            </DialogTitle>
        );
    }

    const sendRequest = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        setIsLoading(true);

        dispatch(orderCardPrivateCustomer({
            contractId: parseInt(contractId),
            ...deliveryAddress,
            cardName: cardName,
            isPlasticCard: isPlasticCard
        }));
    }

    return (
        <Dialog open={isOpen} onClose={onClose} className="mdc-dialog--info">
            {renderDialogTitle()}
            <DialogContent>
                {isLoading ? <Loader type={Loader.TYPE_CENTER} /> :
                    <Grid className="mdc-layout-grid--padded">
                        <GridCell span={12}>
                            <Typography
                                use="headline4"
                                className="mdc-typography mdc-theme--primary"
                            >
                                <FormattedMessage id="Cards.Title.OrderCard" />
                            </Typography>
                            <FormattedMessage id="Cards.NewCard.Notice.Activate" />
                            {isError &&
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_ALERT}
                                    title={<FormattedMessage id="Cards.PlasticCardDialog.Error" />}
                                />
                            }
                        </GridCell>
                        <GridCell span={12}>
                            <TextFieldGroup
                                field="card-name"
                                onChange={(e) => setCardName(e.target.value)}
                                value={cardName}
                                dontTranslateLabel={true}
                                label={intl.formatMessage({
                                    id: "Cards.Title.CardName",
                                })}
                                validator={validator}
                                rules="required"
                            />
                        </GridCell>
                        <GridCell align="middle">
                            <div className="mdc-form-field field">
                                <Checkbox
                                    field="is-plastic-card"
                                    nativeControlId="is-plastic-card"
                                    name="is-plastic-card"
                                    checked={isPlasticCard}
                                    indeterminate={false}
                                    onChange={(e) =>
                                        setIsPlasticCard(e.target.checked)
                                    }
                                />
                                <label htmlFor="is-plastic-card">
                                    <FormattedMessage id="Cards.IsPlastic" />
                                </label>
                            </div>
                        </GridCell>
                        {isPlasticCard &&
                            <GridCell span={12}>
                                <AddressForm
                                    label="Cards.DeliveryAddress"
                                    onChange={setDeliveryAddress}
                                    address={deliveryAddress}
                                    validator={validator}
                                />
                            </GridCell>
                        }
                        <GridCell span={12}>
                            <Button
                                type="submit"
                                onClick={sendRequest}
                                unelevated
                                className="mdc-button--primary mb-30 left"
                            >
                                <i className="icon-floppy" />
                                <FormattedMessage id="Cards.SendOrder" />
                            </Button>
                        </GridCell>
                    </Grid>
                }
            </DialogContent>
        </Dialog>
    );
}

export default NewCardDialogPrivateCustomer;