import { Grid, GridCell } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import React from 'react';
import { Button } from '@rmwc/button';
import {useDispatch, useSelector} from 'react-redux';
import {
    getFuelPlusElectricityInfo,
    registerFuelPlusElectricity,
} from '../../actions/campaignActions';

export const FuelPlusElectricityRegister = () => {
    const dispatch = useDispatch();
    const { fetched } = useSelector(state => state.fuelPlusElectricityRegister)

    const onActivate = () => {
        dispatch(registerFuelPlusElectricity())
    }
    if (fetched) {
        dispatch(getFuelPlusElectricityInfo())
    }
    return (
        <Grid>
            <GridCell desktop={6} tablet={4}>
                <Typography use="headline3" className="mdc-typography mdc-theme--primary" >
                    <FormattedMessage id="FuelPlusElectricity.Register.Title"/>
                </Typography>
                <Typography use="body1" className="mdc-typography mt-20">
                    <FormattedHTMLMessage id="FuelPlusElectricity.Register.Text"/>
                </Typography>
                <Button
                    className="mt-50"
                    unelevated
                    trailingIcon={{
                        icon: "angle-right",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                    }}
                    onClick={onActivate}>
                    <FormattedMessage id="FuelPlusElectricity.Register.Submit"/>
                </Button>
            </GridCell>
            <GridCell desktop={6} tablet={4}>
                <div className="fuel-plus-electricity-banner fuel-plus-electricity-banner--fuelling"></div>
            </GridCell>
        </Grid>
    )
}