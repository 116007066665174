import auth from "../auth/authenticate";
import BusinessAPI from './BusinessApi';

export const fetchBanner = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'FETCH_BANNER',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/accountBanner`)
    };
};

export const setBannerHidden = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'PUT_HIDE_BANNER',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/accountBanner/hide`)
    };
}

export const resetBannerForEveryone = () => {
    // Just in case and extra check here
    if (!auth.isAdmin) {
        return {
            type: 'PUT_RESET_BANNERS',
            payload: {}
        };
    }

    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'PUT_RESET_BANNERS',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/accountBanner/show-for-all`)
    };
}