import { GridCell, GridInner } from "@rmwc/grid";
import AddressForm from "./AddressForm";
import TextFieldGroup from "../common/TextFieldGroup";
import { useEffect, useState } from "react";
import { Typography } from "@rmwc/typography";
import { FormattedMessage } from "react-intl";
import { CrmCounty, CrmCountyLatvia } from "../../const/counties";
import { IsLatvianWeb } from "../../helpers/AlternativeDesign";
import SelectField from "../common/SelectField";
import GoogleAddressAutoCompleteForm from "./GoogleAddressAutoCompleteForm";
import { isNullOrEmpty } from "../../helpers/objectHelper";

export const AddressField = {
    Country: "country",
    County: "county",
    Parish: "parish",
    City: "city",
    Street: "street",
    HouseNr: "houseNr",
    ApartmentNr: "apartmentNr",
    PostalCode: "postalCode"
}

const INITIAL_ADDRESS = {
    country: IsLatvianWeb() ? "Latvija" : "Eesti",
    county: "",
    parish: "",
    city: "",
    street: "",
    houseNr: "",
    apartmentNr: "",
    postalCode: ""
}

const AddressFormExtended = ({ className, rules, onChange, address, validator, isManualInsert = false }) => {
    const [countyOptions, setCountyOptions] = useState();

    useEffect(() => {
        const counties = IsLatvianWeb() ? CrmCountyLatvia : CrmCounty;
        setCountyOptions(Object.values(counties).map(val => ({
            label: val,
            value: val,
            selected: val === (address?.county || "")
        })));
    }, [address]);

    const onChangeField = (name, value) => {
        const editedAddress = ({...address, [name]: value });
        onChange?.(editedAddress);
    }

    const onAddressSearch = (address) => {
        onChange?.(address);
    }

    const isLatvianWeb = IsLatvianWeb();
    const searchRules = isManualInsert ? null : (rules || null);
    const manualRules = isManualInsert ? "required" : null;
    const addressData = address || INITIAL_ADDRESS;

    if (isNullOrEmpty(addressData)) {
        return null;
    }

    return (
        <>
            {!isManualInsert ?
                <GridCell span={12}>
                    {isLatvianWeb
                        ? <GoogleAddressAutoCompleteForm
                            onChange={onAddressSearch}
                            address={addressData} />
                        : <AddressForm
                            className={className}
                            label="AddressForm.Address"
                            rules={searchRules}
                            onChange={onAddressSearch}
                            address={addressData}
                            validator={validator} />
                    }
                </GridCell> :
                <>
                    <GridCell span={12} className="mt-10">
                        <Typography use="subtitle1">
                            <FormattedMessage id="AddressForm.FormTitle" />
                        </Typography>
                    </GridCell>
                    <GridInner>
                        <GridCell span={6}>
                            {isLatvianWeb ?
                                <TextFieldGroup
                                    field={AddressField.County}
                                    onChange={(e, c) => onChangeField(AddressField.County, e.target.value)}
                                    validator={validator}
                                    rules={isLatvianWeb ? null : manualRules}
                                    value={addressData.county}
                                    label="AddressForm.County"
                                /> :
                                <SelectField
                                    rules={manualRules}
                                    field={AddressField.County}
                                    label="AddressForm.County"
                                    value={addressData.county}
                                    onChange={(c, d, e) => onChangeField(AddressField.County, e.currentTarget.value)}
                                    enhanced
                                    menu
                                    outlined
                                    options={countyOptions}
                                />
                            }
                        </GridCell>
                        <GridCell span={6}>
                            <TextFieldGroup
                                field={AddressField.Parish}
                                onChange={(e, c) => onChangeField(AddressField.Parish, e.target.value)}
                                validator={validator}
                                rules={isLatvianWeb ? null : manualRules}
                                value={addressData.parish}
                                label="AddressForm.Parish"
                            />
                        </GridCell>
                        <GridCell span={6}>
                            <TextFieldGroup
                                field={AddressField.City}
                                onChange={(e, c) => onChangeField(AddressField.City, e.target.value)}
                                validator={validator}
                                rules={manualRules}
                                value={addressData.city}
                                label="AddressForm.City"
                            />
                        </GridCell>
                        <GridCell span={6}>
                            <TextFieldGroup
                                field={AddressField.Street}
                                onChange={(e, c) => onChangeField(AddressField.Street, e.target.value)}
                                validator={validator}
                                rules={manualRules}
                                value={addressData.street}
                                label="AddressForm.Street"
                            />
                        </GridCell>
                        <GridCell span={6}>
                            <TextFieldGroup
                                field={AddressField.HouseNr}
                                onChange={(e, c) => onChangeField(AddressField.HouseNr, e.target.value)}
                                value={addressData.houseNr}
                                label="AddressForm.HouseNr"
                            />
                        </GridCell>
                        <GridCell span={6}>
                            <TextFieldGroup
                                field={AddressField.ApartmentNr}
                                onChange={(e, c) => onChangeField(AddressField.ApartmentNr, e.target.value)}
                                value={addressData.apartmentNr}
                                label="AddressForm.ApartmentNr"
                            />
                        </GridCell>
                        <GridCell span={6}>
                            <TextFieldGroup
                                field={AddressField.PostalCode}
                                onChange={(e, c) => onChangeField(AddressField.PostalCode, e.target.value)}
                                validator={validator}
                                rules={manualRules}
                                value={addressData.postalCode}
                                label="AddressForm.PostalCode"
                            />
                        </GridCell>
                    </GridInner>
                </>
            }
        </>
    );
}

export default AddressFormExtended;