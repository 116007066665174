import { GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import React from "react";
import { FormattedMessage } from "react-intl";

const ContractFuelConditions = ({ model }) => {
    if (!model.conditions) {
        return null;
    }

    return (
        <>
            <GridCell span={12} className="mt-20 mdc-typography mdc-typography--headline4 mdc-typography--bold">
                <FormattedMessage id="ContractsPage.Conditions.Title" />
                <hr className="divider divider--secondary" />
            </GridCell>
            <GridCell span={12} className="white-space-pre-line">
                <Typography use="body1">
                    {model.conditions}
                </Typography>
            </GridCell>
        </>
    );
}

export default ContractFuelConditions;