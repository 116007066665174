export default function reducer(state = {
    data: [],
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case "FETCH_SIGNED_CONTRACTS_PENDING": {
            return { ...state, fetching: true };
        }
        case "FETCH_SIGNED_CONTRACTS_REJECTED": {
            return { ...state, fetching: false, error: action.payload };
        }
        case "FETCH_SIGNED_CONTRACTS_FULFILLED": {
            return { ...state, fetching: false, fetched: true, data: action.payload.data };
        }
        default:
            return state;
    }
}