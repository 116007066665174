const templateEt = {
    "accepted": "Väli \":attribute\" peab olema valitud.",
    "after": ":attribute peab olema suurem kui :date.",
    "after_or_equal": ":attribute peab olema suurem kui või võrduma :date.",
    "alpha": ":attribute võib sisaldada ainult tähti.",
    "alpha_num": ":attribute võib sisaldada ainult tähti ja numbreid.",
    "alpha_num_dash": ":attribute võib sisalda ainult tähti, numbreid ja kriipse.",
    "alpha_num_dash_space": ":attribute võib sisalda ainult tähti, numbreid ja kriipse ja tühikuid.",
    "alpha_num_space": ":attribute võib sisalda ainult tähti, numbreid ja tühikuid.",
    "alpha_space": ":attribute võib sisaldada ainult tähti ja tühikuid.",
    "array": ":attribute peab olema massiiv.",
    "before": ":attribute peab olema enne :date.",
    "before_or_equal": ":attribute peab olema enne või sellel kuupäeval - :date.",
    "between": ":attribute peab olema vahemikus :min ja :max:type.",
    "boolean": ":attribute peab olema tõeväärtus.",
    "card_exp": ":attribute peab olema kehtiv aegumiskuupäev.",
    "card_num": ":attribute peab olema kehtiv krediitkaardi number.",
    "cashback": "Palun sisestage \":attribute\" ilma punktide ja tühikuteta!",
    "coop": "Palun sisestage \":attribute\" ilma punktide ja tühikuteta!",
    "currency": ":attribute peab olema kehtiv valuuta.",
    "date": ":attribute peab olema kuupäev.",
    "date_equals": ":attribute peab olema :date kuupäeval.",
    "email": ":attribute pole korrektses formaadis!",
    "idCode": ":attribute ei ole valiidne.",
    "in": ":attribute peab olema :values.",
    "integer": ":attribute peab olema täisarv.",
    "max": ":attribute peab olema mitte suurem kui :max tähemärki pikk.",
    "min": ":attribute peab olema vähemalt :min tähemärki pikk.",
    "not_in": ":attribute ei tohi olla :values.",
    "not_regex": ":attribute ei tohi vastata nõutavale mustrile.",
    "numeric": ":attribute peab olema number.",
    "phone": ":attribute pole korrektses formaadis.",
    "regex": "Väli \":attribute\" ei vasta vajalikule mustrile.",
    "required": "Väli \":attribute\" on kohustuslik.",
    "requiredIf": "Väli \":attribute\" on kohustuslik.",
    "size": ":attribute peab olema :size:type.",
    "string": ":attribute peab olema string.",
    "typeof": ":attribute pole :type tüüpi.",
    "url": ":attribute peab olema URL."
};

export default templateEt;