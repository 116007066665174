import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import classnames from "classnames";
import { IconButton } from "@rmwc/icon-button";

import ValidationHelper from "../../helpers/validationHelper";
import { defaultIconProps } from './Icon';


const FileField = ({ field, label, value, fileTypes, onChange, classNames, validator, rules, showErrorMessage, validationLabel, emptyText, disabled, invalid }) => {
    const intl = useIntl();
    let labelTranslated = label ? intl.formatMessage({ id: label }) : null;
    let validationLabelTranslated = validationLabel ? intl.formatMessage({ id: validationLabel }) : null;
    let emptyTextTranslated = emptyText ? intl.formatMessage({ id: emptyText }) : null;
    const { validationMessage } = ValidationHelper.getFieldValidationItemsRmwc(validator, rules, validationLabelTranslated || labelTranslated, value);

    const inputRef = useRef(null);

    const [file, setFile] = useState(null);

    const handleChange = (e) => {
        const file = e.target.files && e.target.files[0];
        setFile(file);
        onChange(file);
    }

    const clear = () => {
        inputRef.current.value = null;
        setFile(null);
        onChange(null);
    }

    return <div>
        <div className="field-label">{labelTranslated}</div>
        <div className={classnames("file-field", { "file-field--invalid": invalid || validationMessage })}>
            <label className="file-field__input-wrapper">
                <input
                    ref={inputRef}
                    type="file"
                    name={field}
                    onChange={handleChange}
                    accept={fileTypes}
                    className={classNames}
                    disabled={disabled} />
                <span className="file-field__custom-input">
                    {!file
                        ? emptyTextTranslated
                        : file.name}
                </span>
            </label >
            <IconButton
                className="file-field__clear"
                onIcon={{
                    ...defaultIconProps,
                    icon: "close"
                }}
                onClick={clear} />
        </div>
        {showErrorMessage && validationMessage && <div>{validationMessage}</div>}
    </div>
}

FileField.propTypes = {
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    classNames: PropTypes.string,
    validator: PropTypes.object,
    rules: PropTypes.string,
    showErrorMessage: PropTypes.bool,
    validationLabel: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    emptyText: PropTypes.string,
};

FileField.defaultProps = {
    showErrorMessage: true,
    disabled: false,
    fileTypes: "*"
};

export default FileField;
