import React from "react";
import auth from "../../auth/authenticate";
import SelectField from "../common/SelectField";

const UserContributionFilter = ({ options, selected, onChange }) => {
    if (!options || options.length === 0) {
        return null;
    }

    const isCompany = auth.isCompany() || false;

    if (!isCompany) {
        return null;
    }

    const onContributionFilterChange = (afterChange, field, e) => {
        onChange?.(e.target.value);
    };

    return <SelectField
        field="contribution-type-selection"
        disabled={false}
        value={selected}
        onChange={onContributionFilterChange}
        enhanced
        menu
        outlined
        options={options}
        className="mt-clear mb-0 mdc-select--vertical-aligned"
    />
}

export default UserContributionFilter;