import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from 'prop-types';
import { GridCell } from "@rmwc/grid";
import Checkbox from '@material/react-checkbox';
import TextFieldGroup from "../common/TextFieldGroup";
import { CardApplicationType, PaymentCardStatus } from "../../const/cardApplication";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import SelectField from "../common/SelectField";

const FIELD_LIMIT = "FieldLimit";
const FIELD_IS_PLASTIC_CARD = "FieldIsPlasticCard";
const PERSON_DEFAULT_LIMIT = 300;
const PERSON_CARD_LIMITS = [
    {
        selected: false,
        label: "200",
        value: 200
    },
    {
        selected: false,
        label: "300",
        value: 300
    },
    {
        selected: false,
        label: "500",
        value: 500
    }
];

const ContractFuelLimits = ({
    model,
    isReadOnly = false,
    isCompany = false,
    setLimitData,
    validator
}) => {
    const [limit, setLimit] = useState(PERSON_DEFAULT_LIMIT);
    const [isPlasticCard, setIsPlasticCard] = useState(false);
    const [forceUpdate, setForceUpdate] = useState();

    useEffect(() => {
        if (!isNullOrEmpty(model) && model.cardStatus === PaymentCardStatus.PENDING_SIGNATURE) {
            setLimit(model.limit);
            setIsPlasticCard(model.isVirtual === false);
            setLimitData(({
                monthlyLimit: model.limit,
                isPlasticCard: model.isVirtual === false
            }));
        } else {
            setLimitData(({
                monthlyLimit: limit,
                isPlasticCard: isPlasticCard
            }));
        }
    }, [model, isCompany, isReadOnly, isPlasticCard, limit, forceUpdate]);

    const updateData = (target, field) => {
        switch (field) {
            case FIELD_LIMIT:
                setLimit(target.value);
                setLimitData(prev => ({
                    ...prev,
                    monthlyLimit: target.value
                }));
                break;
            case FIELD_IS_PLASTIC_CARD:
                setIsPlasticCard(target.checked);
                setLimitData(prev => ({
                    ...prev,
                    isPlasticCard: target.checked
                }));
                break;
            default:
                break;
        }
    }

    const validate = () => {
        if (!validator.allValid()) {
            setForceUpdate(true);
        }
    }

    const onLimitChange = (afterChange, field, e) => {
        setLimit(e.target.value);
        setLimitData(prev => ({
            ...prev,
            monthlyLimit: parseInt(e.target.value) || null
        }));
    }

    if (model.applicationType === CardApplicationType.CYLINDER_GAS_OFFER) {
        return null;
    }

    return (
        <>
            <GridCell span={12} className="mt-20 mdc-typography mdc-typography--headline4 mdc-typography--bold">
                <FormattedMessage id="ContractsPage.Limits.Title" />
            </GridCell>
            {model.applicationType === CardApplicationType.APPLICATION &&
                <GridCell span={12} className="mdc-typography mdc-typography--subtitle1">
                    <FormattedMessage id="ContractsPage.Limits.SubTitle" />
                    <hr className="divider divider--secondary" />
                </GridCell>
            }
            <GridCell span={6}>
                {(isCompany || isReadOnly) &&
                    <TextFieldGroup
                        id="number"
                        field={FIELD_LIMIT}
                        value={limit}
                        type="number"
                        label="ContractsPage.Limits.MonthlyLimit"
                        onChange={(e) => updateData(e.target, FIELD_LIMIT)}
                        validator={validator}
                        rules="required|minLimit:100|maxLimit:100000"
                        onBlur={validate}
                        disabled={isReadOnly}
                    />
                }
                {!isCompany && !isReadOnly &&
                    <SelectField
                        field={FIELD_LIMIT}
                        name="dropdown"
                        value={limit}
                        onChange={onLimitChange}
                        options={PERSON_CARD_LIMITS}
                        label="ContractsPage.Limits.MonthlyLimit"
                        disabled={isReadOnly}
                    >
                    </SelectField>
                }
            </GridCell>
            <GridCell span={6}></GridCell>
            {!isCompany &&
                <>
                    <GridCell span={6}>
                        <div className="mdc-form-field field mt-20">
                            <Checkbox
                                field={FIELD_IS_PLASTIC_CARD}
                                nativeControlId="card-wish"
                                name="cbWantPlastic"
                                checked={isPlasticCard}
                                indeterminate={false}
                                onChange={e => updateData(e.target, FIELD_IS_PLASTIC_CARD)}
                                disabled={isReadOnly}
                            />
                            <label htmlFor="card-wish">
                                <FormattedMessage id="ContractsPage.Limits.PlasticCardCbx" />
                            </label>
                        </div>
                    </GridCell>
                    <GridCell span={6}></GridCell>
                </>
            }
        </>
    );
}

ContractFuelLimits.propTypes = {
    model: PropTypes.object.isRequired,
    setLimitData: PropTypes.func.isRequired,
    validator: PropTypes.instanceOf(SimpleReactValidator).isRequired,
    isReadOnly: PropTypes.bool,
    isCompany: PropTypes.bool
};

export default ContractFuelLimits;