import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import SimpleReactValidator from "simple-react-validator";

const useValidator = (customValidator = {}) => {
    const [, forceUpdate] = useState(0);
    const intl = useIntl();

    const validator = useRef(new SimpleReactValidator({
        element: false,
        validators: customValidator,
        locale: intl.locale,
        autoForceUpdate: { forceUpdate: () => forceUpdate(prev => prev + 1) }
    }));

    return [validator.current, forceUpdate];
};

export default useValidator;