import React, { useCallback, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AlertMessage from "../common/AlertMessage";
import { InvoicesPath } from "../../const/routes";
import Currency from "../common/Currency";
import { fetchElectricityInvoices, fetchPaymentCardInvoices } from "../../actions/invoiceActions";
import { isInvoiceNotPaidAndDueDateExceeded } from "../../helpers/invoiceHelper";

const UnpaidInvoicesSection = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [unpaidSum, setUnpaidSum] = useState(0);

    const elmoInvoices = useSelector(state => state.electricityInvoices);
    const occInvoices = useSelector(state => state.paymentCardInvoices);

    const dispatch = useDispatch();

    const getDates = () => {
        const start = moment().subtract(6, 'months').startOf('month');
        const end = moment();

        return {
            start: start.format("YYYY-MM-DD 00:00:00"),
            end: end.format("YYYY-MM-DD 23:59:59")
        };
    }

    const getUnpaidSumFromInvoice = (invoiceItems) => {
        return invoiceItems
            ?.filter(item => isInvoiceNotPaidAndDueDateExceeded(item.status, item.deadline))
            ?.reduce((sum, item) => sum + item.totalToBePaid, 0) || 0;
    }

    const calculateSum = useCallback(() => {
        const elmoSum = getUnpaidSumFromInvoice(elmoInvoices.data);
        const occSum = getUnpaidSumFromInvoice(occInvoices.data);

        return elmoSum + occSum;
    }, [elmoInvoices.data, occInvoices.data]);

    const fetchData = useCallback(() => dispatch => {
        const dates = getDates();
        dispatch(fetchElectricityInvoices(1, 50, dates.start, dates.end));
        dispatch(fetchPaymentCardInvoices(1, 50, dates.start, dates.end));
    }, []);

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch, fetchData]);

    useEffect(() => {
        setUnpaidSum(calculateSum());
        setIsLoading(false);
    }, [elmoInvoices.data, occInvoices.data, calculateSum]);

    if (isLoading || unpaidSum <= 0) {
        return null;
    }

    return (
        <div className="p-24">
            <AlertMessage
                isSmall
                type={AlertMessage.TYPE_ALERT}
                title={<FormattedMessage id="Overview.Invoices.Unpaid.Title" values={{
                    sum: <Currency value={unpaidSum} />
                }} />}
                description={
                    <div className="link-card--content-url">
                        <Link to={InvoicesPath}>
                            <FormattedMessage id="Overview.Invoices.Unpaid.LinkText" />
                        </Link>
                        <i className="icon icon-arrow-right" />
                    </div>
                }
            />
        </div>
    );
}

export default UnpaidInvoicesSection;