export const StatusPaid = "Paid";
export const StatusPartiallyPaid = "PartiallyPaid";
export const StatusCredited = "Credited";
export const StatusPaymentPending = "PaymentPending";
export const StatusUnpaid = "Unpaid";

export const Statuses = { StatusPaid, StatusPartiallyPaid, StatusCredited, StatusPaymentPending, StatusUnpaid };

const ElectricityAndGas = "ElectricityAndGas";
const PaymentCard = "PaymentCard";
const EvCharging = "EvCharging";

export const InvoiceType = { ElectricityAndGas, PaymentCard, EvCharging };

export const COMPANY_USER_INVOICE_MAX_LIMIT = 1000;

export const InvoiceFrequency = [
    {
        label: "INVOICE_FREQUENCY.FOUR_TIMES_PER_MONTH",
        value: "ContractDetails.InvoiceFrequency.FourPerMonth",
        numeric: 4
    },
    {
        label: "INVOICE_FREQUENCY.ONCE_PER_MONTH",
        value: "ContractDetails.InvoiceFrequency.OncePerMonth",
        numeric: 1
    },
    {
        label: "INVOICE_FREQUENCY.TWICE_PER_MONTH",
        value: "ContractDetails.InvoiceFrequency.TwicePerMonth",
        numeric: 2
    }
];
