const StatusOk = "CARD_STATUS.OK";
const StatusInProduction = "CARD_STATUS.IN_PRODUCTION";
const StatusClosed = "CARD_STATUS.CLOSED";
const StatusClosedStoplist = "CARD_STATUS.CLOSED_WITH_STOPLIST";
const StatusClosedLostOrStolen = "CARD_STATUS.LOST_OR_STOLEN";
const StatusClosedWithContract = "CARD_STATUS.CLOSED_WITH_CONTRACT";
const StatusClosedByClient = "CARD_STATUS.CLOSED_BY_CLIENT";
const StatusInactive = "CARD_STATUS.INACTIVE";

export {
    StatusOk,
    StatusInProduction,
    StatusClosed,
    StatusClosedStoplist,
    StatusClosedLostOrStolen,
    StatusClosedWithContract,
    StatusClosedByClient,
    StatusInactive
}