import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { GridCell, GridInner } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import PropTypes from 'prop-types';
import SimpleReactValidator from "simple-react-validator";
import Authenticate from "../../auth/authenticate";
import { AuthenticationType } from "../../const/authenticationType";
import AlertMessage from "../common/AlertMessage";
import Loader from "../common/Loader";
import SkIdLoader from "../common/SkIdLoader";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import { useDigitalSigning } from "../../helpers/hooks";

import "../../styles/components/_contract-digital-sign.scss";

const ContractDigitalSign = ({
    getFileToSign,
    isModelValidToSign,
    onDownloadPreview,
    disabled = false,
    onDocumentSigned,
    validator,
    extraButton,
    children
}) => {
    const [isError, setIsError] = useState(false);
    const [isSigningCompleted, setIsSigningCompleted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [signingMethod, setSigningMethod] = useState();
    const [personalCodeForSigning, setPersonalCodeForSigning] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);

    const dispatch = useDispatch();
    const [challengeCode, startSigningProcess] = useDigitalSigning();

    useEffect(() => {
        const authConfig = Authenticate.getAuthConfig();
        authConfig.getUser().then(user => {
            setSigningMethod(user.profile?.idp);
            setPersonalCodeForSigning(user.profile?.national_id_number);
            setMobileNumber(user.profile?.phone);
        });
    }, []);

    const onStartSigning = async () => {
        const isModelValid = isModelValidToSign?.() ?? true;
        if (!isModelValid) {
            return;
        }

        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        setIsLoading(true);

        try {
            const fileToSign = await dispatch(getFileToSign());

            if (isNullOrEmpty(fileToSign)) {
                setIsError(true);
                setIsLoading(false);
                setIsSigningCompleted(true);
                return;
            }

            await startSigningProcess({
                personalCodeForSigning: personalCodeForSigning,
                mobileNumber: mobileNumber,
                signingMethod: signingMethod
            }, fileToSign, (data) => onDocumentSigned(({...data, signingMethod})));
        } catch (err) {
            console.error(err);
            setIsError(true);
            setIsLoading(false);
            setIsSigningCompleted(true);
        }
    }

    return (
        <>
            {isLoading &&
                <GridCell span={12}>
                    {(signingMethod === AuthenticationType.SmartId || signingMethod === AuthenticationType.MobileId)
                        ? <SkIdLoader challengeCode={challengeCode} type={signingMethod} />
                        : <Loader type={Loader.TYPE_CENTER} />
                    }
                </GridCell>
            }
            {!isLoading && isSigningCompleted &&
                <GridCell span={12}>
                    <AlertMessage
                        type={isError ? AlertMessage.TYPE_ALERT : AlertMessage.TYPE_DONE}
                        title={<FormattedMessage id={isError
                            ? "ContractsPage.Error.SigningFailed"
                            : "ContractsPage.Notice.SigningSuccessful"} />}
                    />
                </GridCell>
            }
            {!isLoading && !isError && !isSigningCompleted &&
                <GridCell span={12} className="mt-20 p-10">
                    <GridInner>
                        <GridCell desktop={6} tablet={4} phone={12} align="middle">
                            <div className="mdc-form-field">
                                {children}
                            </div>
                        </GridCell>
                        <GridCell desktop={6} tablet={4} phone={12} align="middle" className="digital-sign--button-group">
                            <Button
                                type="button"
                                outlined
                                onClick={() => onDownloadPreview?.()}
                                className="digital-sign--preview-button">
                                <FormattedMessage id="ContractsPage.Contract.Preview" />
                            </Button>
                            {extraButton}
                            <Button
                                type="button"
                                unelevated
                                onClick={onStartSigning} disabled={disabled}>
                                <FormattedMessage id="ContractsPage.Contract.SignDigitally" />
                            </Button>
                        </GridCell>
                    </GridInner>
                </GridCell>
            }
        </>
    )
}

ContractDigitalSign.propTypes = {
    getFileToSign: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onDocumentSigned: PropTypes.func,
    validator: PropTypes.instanceOf(SimpleReactValidator).isRequired,
    onDownloadPreview: PropTypes.func
};

export default ContractDigitalSign;