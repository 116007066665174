import moment from "moment";

const sortByDate = (a, b, key, desc = true) => {
    const dateA = moment(a[key]);
    const dateB = moment(b[key]);

    if (dateA.isAfter(dateB)) { return desc ? -1 : 1; }
    if (dateA.isBefore(dateB)) { return desc ? 1 : -1; }
    return 0;
}

export {sortByDate};
