import { useCallback } from "react";
import auth from "../../auth/authenticate";

const useScrollToRef = (appOnly = false) => {
    const isApp = auth.isAppSession();
    const scrollToRef = useCallback((ref, timeMs = 0) => {
        if (appOnly && !isApp) {
            return;
        }

        setTimeout(() => {
            ref?.current?.scrollIntoView({ behavior: "smooth" });
        }, timeMs);
    }, [appOnly, isApp]);

    return scrollToRef;
}

export default useScrollToRef;