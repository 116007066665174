import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import cardImage from "../../images/card.png";
import { fetchCardsDiscounts } from "../../actions/discountActions";
import { fetchConsumptionLocations } from "../../actions/consumptionlocationActions";
import {
    CardsPath,
    ContractGasPath,
    ContractElectricityPath,
    getCardDetailsPath,
    SummerCampaignPath
} from "../../const/routes";
import ReferralLink from "../common/ReferralLink";
import Alert, { AlertType } from "../common/Alert";
import CardQrButton from "../common/CardQrButton";
import {fetchActiveOffers} from "../../actions/offerActions";
import { fetchFamilyGroup } from "../../actions/familyGroupActions";
import getPathName from "../../helpers/offerHelper";
import { Feature, useFeatures } from "flagged";
import {FamilyGroupAsPublic} from "../../const/featureFlags";
import { FamilyGroupSection } from "../FamilyGroup";
import "../../styles/react-components/overviewBanners.scss";
import {getActiveTicketCount} from "../../actions/campaignActions";

const OverviewBanners = ({ isMobileOrTablet }) => {
    const DEFAULT_DISCOUNT = "3 s/l";

    const cardsDiscounts = useSelector(state => state.cardsDiscounts);
    const locations = useSelector(state => state.consumptionLocations);
    const activeOffers = useSelector(state => state.activeOffers);
    const familyGroup = useSelector(state => state.familyGroup);
    const digitalStampsSummary = useSelector(state => state.digitalStampsSummary);
    const activeTicketCount = useSelector(state => state.activeTicketCount);

    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const features = useFeatures();

    useEffect(() => {
        if (!cardsDiscounts.fetching && !cardsDiscounts.fetched) {
            dispatch(fetchCardsDiscounts());
        }

        if (!locations.fetching && !locations.fetched) {
            dispatch(fetchConsumptionLocations());
        }

        if (!activeOffers.fetching && !activeOffers.fetched) {
            dispatch(fetchActiveOffers());
        }

        if ((features.FamilyGroupAsPublic || features.FamilyGroupAsHidden) && !familyGroup.fetching && !familyGroup.fetched) {
            dispatch(fetchFamilyGroup());
        }

        if (features.LotteryCampaign && !activeTicketCount.fetching && !activeTicketCount.fetched) {
            dispatch(getActiveTicketCount())
        }
    }, [dispatch, cardsDiscounts, locations, activeOffers, familyGroup, activeTicketCount, features])

    const redirectToOrderCard = () => {
        ReferralLink.setReferralLinkParams(location.search);
        history.push(CardsPath);
    }

    const renderOrderCardBanner = () => {
        return (
            <Alert
                imageSrc={cardImage}
                imageAlt="card"
                title={<FormattedMessage id="Overview.OrderCard.Text" />}
                action={redirectToOrderCard}
                actionText={<FormattedMessage id="Overview.OrderCard.Link" />}
            />
        );
    }

    const renderContinuePrecontractBanner = () => {
        const precontract = locations.data.precontracts[0];
        return (
            <Alert
                type={AlertType.YELLOW}
                iconName={precontract.type}
                title={<FormattedMessage id="Overview.Precontract.Text" />}
                action={precontract.type === 'gas'
                    ? ContractGasPath
                    : ContractElectricityPath}
                actionText={<FormattedMessage id="Overview.Precontract.Link" />} />
        );
    }

    const primaryCard = useMemo(() => {
        return !cardsDiscounts.fetching &&
            !cardsDiscounts.error &&
            Object.values(cardsDiscounts.data).find(x => x.bonuscardIsPrimary);
    }, [cardsDiscounts.fetching, cardsDiscounts.error, cardsDiscounts.data]);

    const onRegularDiscountNoticeClick = () => {
        const primaryCardDiscount = primaryCard;
        const cardNumber = !!primaryCardDiscount && primaryCardDiscount.bonuscardNr;

        if (!cardNumber) { return; }

        const cardDiscountsPath = getCardDetailsPath(cardNumber);
        history.push(cardDiscountsPath);
    }

    const renderRegularDiscountNotice = () => {
        const primaryCardDiscount = primaryCard;
        const cardNumber = !!primaryCardDiscount && primaryCardDiscount.bonuscardNr;
        const discount = digitalStampsSummary.data?.bonus ?? DEFAULT_DISCOUNT;

        return (
            <div className="d-flex d-flex--inline align-items-center" >
                <Alert className="alert--discount mr-20"
                    onClick={onRegularDiscountNoticeClick}
                    title={<FormattedMessage id="Dashboard.DiscountBanner" />}
                    content={
                        <>
                            {discount}
                        </>
                    }
                />
                <CardQrButton cardNumber={cardNumber} />
            </div>
        );
    }

    const renderOffer = (offer) => {
        return (
            <GridCell span={12} key={offer.id}>
                <Link to={{
                    pathname: getPathName(offer.applicationType, offer.id),
                    state: {applicationType: offer.applicationType}
                }}>
                    <Alert title={<FormattedMessage id={`Overview.Notice.${offer.applicationType}`} values={{ sum: offer.limit }}/>}/>
                </Link>

            </GridCell>
        )
    }

    const renderOffers = () => {
        return activeOffers.data?.map(renderOffer);
    }

    const renderLotteryCampaign = () => {
        if (!features.LotteryCampaign || activeTicketCount.data <= 0)
            return;

        return (
            <GridCell span={12}>
                <Link to={{
                    pathname: SummerCampaignPath
                }}>
                    <div className={`overview-campaign-banner overview-campaign-banner-image--${intl.locale}`}/>
                </Link>

            </GridCell>
        )
    }

    const hasNoDiscountCards = Object.keys(cardsDiscounts.data).length < 1 &&
        !cardsDiscounts.fetching &&
        !cardsDiscounts.error;

    const hasPrecontracts = locations.fetched && locations.data?.precontracts && locations.data.precontracts?.length > 0;
    const hasOffers = activeOffers.fetched && activeOffers.data?.length > 0;
    const hasFamilyGroupInvitation = familyGroup.fetched && familyGroup.data?.isPendingApplication;

    const showRegularCampaignDiscountOnMobile = isMobileOrTablet;

    const hasBanner = hasNoDiscountCards || hasPrecontracts || showRegularCampaignDiscountOnMobile || hasOffers || hasFamilyGroupInvitation;

    if (!hasBanner) {
        return null;
    }

    return (
        <Grid className="mdc-layout-grid--base mb-tablet-20">
            {renderLotteryCampaign()}

            <Feature name={FamilyGroupAsPublic}>
                <GridCell span={12}>
                    <FamilyGroupSection />
                </GridCell>
            </Feature>
            {hasNoDiscountCards &&
                <GridCell span={12}>
                    {renderOrderCardBanner()}
                </GridCell>
            }

            {hasOffers && renderOffers()}

            {showRegularCampaignDiscountOnMobile &&
                <GridCell span={12}>
                    {renderRegularDiscountNotice()}
                </GridCell>
            }

            {hasPrecontracts &&
                <GridCell span={12}>
                    {renderContinuePrecontractBanner()}
                </GridCell>
            }
        </Grid>
    );
}

export default OverviewBanners;