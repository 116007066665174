import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { Icon } from "@rmwc/icon";
import "../../styles/react-components/pagination.scss";
import auth from "../../auth/authenticate";

class Pagination extends React.Component {
    //number of pages between before and after buttons
    static DEFAULT_PAGES_TO_SHOW = 5;
    //number of pages before and after selected page => (pages_to_show - 1) / 2
    static DEFAULT_PAGE_NEIGHBOURS = 2;
    static ON_CLICK_PREVIOUS = "previous";
    static ON_CLICK_NEXT = "next";
    static POSSIBLE_PAGE_SIZES = [10, 20, 50];

    constructor(props) {
        super(props);

        this.state = {
            pageSize: auth.getPageSize()
        };
    }

    componentDidMount() {
        const { selectedPage, total, onChange } = this.props;

        if (!selectedPage || selectedPage < 1) {
            onChange({ page: 1, pageSize: this.state.pageSize });
        }

        const totalPages = Math.ceil(total / this.state.pageSize) || 1;

        if (selectedPage > totalPages) {
            onChange({ page: totalPages, pageSize: this.state.pageSize });
        }
    }

    changePageSize(limit) {
        auth.setPageSize(limit);
        this.setState({pageSize: limit});
        this.props.onChange({ page: 1, pageSize: limit });
    }

    onClick = (page, selectedPage, totalPages, e) => {
        e.preventDefault();

        if (page === Pagination.ON_CLICK_PREVIOUS) {
            page = selectedPage > 1 ? selectedPage - 1 : 1;
        }
        if (page === Pagination.ON_CLICK_NEXT) {
            page = selectedPage < totalPages ? selectedPage + 1 : totalPages;
        }

        if (page === this.props.selectedPage) {
            return;
        }

        if (this.props.scrollToRef && this.props.scrollToRef.current) {
            this.props.scrollToRef.current.scrollIntoView({behavior: 'smooth'});
        }

        this.props.onChange({ page: page, pageSize: this.state.pageSize });
    };

    range = (start, end) => {
        return [...Array(1 + end - start).keys()].map(v => start + v);
    };

    getVisiblePageNumbers = (totalPages, selectedPage) => {
        if (totalPages > Pagination.DEFAULT_PAGES_TO_SHOW) {
            const startPage = Math.max(
                1,
                selectedPage - Pagination.DEFAULT_PAGE_NEIGHBOURS
            );

            const endPage = Math.min(
                totalPages,
                selectedPage + Pagination.DEFAULT_PAGE_NEIGHBOURS
            );

            let pages = this.range(startPage, endPage);

            const hasLeftSpill = startPage > Pagination.DEFAULT_PAGE_NEIGHBOURS;
            const hasRightSpill = totalPages - endPage > 1;
            const spillOffset = Pagination.DEFAULT_PAGES_TO_SHOW - pages.length;

            if (hasLeftSpill && !hasRightSpill) {
                const extraPages = this.range(
                    startPage - spillOffset,
                    startPage - 1
                );
                pages = [...extraPages, ...pages];
            } else if (!hasLeftSpill && hasRightSpill) {
                const extraPages = this.range(
                    endPage + 1,
                    endPage + spillOffset
                );
                pages = [...pages, ...extraPages];
            } else {
                pages = [...pages];
            }

            return [...pages];
        }

        return this.range(1, totalPages);
    };

    renderPreviousButton = (selectedPage, totalPages) => {
        return (
            <li
                className={classnames("previous", {
                    disabled: selectedPage === 1
                })}
            >
                <Link
                    to="#"
                    onClick={this.onClick.bind(
                        this,
                        Pagination.ON_CLICK_PREVIOUS,
                        selectedPage,
                        totalPages
                    )}
                >
                    <Icon
                        icon={{
                            icon: "arrow-left",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                            className: "left"
                        }}
                    />
                </Link>
            </li>
        );
    };

    renderNextButton = (selectedPage, totalPages) => {
        return (
            <li
                className={classnames("next", {
                    disabled: selectedPage === totalPages
                })}
            >
                <Link
                    to="#"
                    onClick={this.onClick.bind(
                        this,
                        Pagination.ON_CLICK_NEXT,
                        selectedPage,
                        totalPages
                    )}
                >
                    <Icon
                        icon={{
                            icon: "arrow-left",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                            className: "rotate right"
                        }}
                    />
                </Link>
            </li>
        );
    };

    renderFirstButton = (selectedPage, totalPages) => {
        return (
            <li
                className={classnames("previous", {
                    disabled: selectedPage === 1
                })}
            >
                <Link
                    to="#"
                    onClick={this.onClick.bind(
                        this,
                        1,
                        selectedPage,
                        totalPages
                    )}
                >
                    {"<<"}
                </Link>
            </li>
        );
    };

    renderLastButton = (selectedPage, totalPages) => {
        return (
            <li
                className={classnames("next", {
                    disabled: selectedPage === totalPages
                })}
            >
                <Link
                    to="#"
                    onClick={this.onClick.bind(
                        this,
                        totalPages,
                        selectedPage,
                        totalPages
                    )}
                >
                    {">>"}
                </Link>
            </li>
        );
    };

    render() {
        const { total, selectedPage } = this.props;

        const totalPages = Math.ceil(total / this.state.pageSize) || 1;

        if (!selectedPage || selectedPage > totalPages || selectedPage < 1) {
            return null;
        }

        const pages = this.getVisiblePageNumbers(totalPages, selectedPage);

        if (total === 0) {
            return null;
        }

        return (
            <div className="pager">
                <ul>
                    {this.renderFirstButton(selectedPage, totalPages)}
                    {this.renderPreviousButton(selectedPage, totalPages)}
                    {pages.map((page, index) => (
                        <li
                            key={"pagination" + index}
                            className={classnames("page", {
                                active: page === selectedPage
                            })}
                        >
                            <Link
                                to="#"
                                onClick={this.onClick.bind(
                                    this,
                                    page,
                                    selectedPage,
                                    totalPages
                                )}
                            >
                                {page}
                            </Link>
                        </li>
                    ))}
                    {this.renderNextButton(selectedPage, totalPages)}
                    {this.renderLastButton(selectedPage, totalPages)}
                </ul>
                <ul>
                    <li>
                        <FormattedMessage id="General.ResultsOnPage"/>
                    </li>
                    {Pagination.POSSIBLE_PAGE_SIZES.map(pageSize => (
                        <li
                            key={"pageSize" + pageSize}
                            className={classnames({
                                active: this.state.pageSize === pageSize
                            })}
                        >
                            <Link
                                to="#"
                                onClick={this.changePageSize.bind(
                                    this,
                                    pageSize
                                )}
                            >
                                {pageSize}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

Pagination.defaultProps = {
    total: 0,
    selectedPage: 1
};

Pagination.propTypes = {
    total: PropTypes.number.isRequired,
    selectedPage: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    scrollToRef: PropTypes.object // When changing the page then scroll to the element
};

export default Pagination;
