export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null,
    hasError: false
}, action) {
    switch (action.type) {
        case "VALIDATE_COMPANY_CREDIT_SCORE_PENDING": {
            return {...state, error: null, fetching: true, hasError: false};
        }
        case "VALIDATE_COMPANY_CREDIT_SCORE_REJECTED": {
            return {...state, fetching: false, error: action.payload.response.data, hasError: true};
        }
        case "VALIDATE_COMPANY_CREDIT_SCORE_FULFILLED": {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data, hasError: false};
        }
        default:
            return state;
    }
}