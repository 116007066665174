import React from "react";
import classnames from 'classnames';
import BaseFormComponent from "./BaseFormComponent";
import PropTypes from 'prop-types';

class AlertMessageInline extends BaseFormComponent {
    static TYPE_NOTICE = "notice";
    static TYPE_ALERT = "alert";
    static TYPE_DONE = "done";
    static TYPE_WARN = "warn";

    static CLASSES = {
        [AlertMessageInline.TYPE_NOTICE]: {
            className: "message--notice",
            icon: "icon-alert"
        },
        [AlertMessageInline.TYPE_ALERT]: {
            className: "message--alert",
            icon: "icon-alert"
        },
        [AlertMessageInline.TYPE_DONE]: {
            className: "message--done",
            icon: "icon-done"
        },
        [AlertMessageInline.TYPE_WARN]: {
            className: "message--warn",
            icon: "icon-alert"
        }
    };

    constructor(props) {
        super(props);
        let { head, description, className, type, scrollToMessage } = props;
        this.ref = props.inneRref || React.createRef();

        if (!AlertMessageInline.CLASSES[type]) {
            type = AlertMessageInline.TYPE_NOTICE;
        }

        this.state = {
            head,
            description,
            className,
            type,
            scrollToMessage
        };

        this.isInViewport = this.isInViewport.bind(this);
    }

    /**
     * Check if an element is in viewport
     *
     * @param {number} [offset]
     *
     * @returns {boolean}
     */
    isInViewport(offset = 0) {
        if (!this.ref) return false;
        const top = this.ref.current.getBoundingClientRect().top;

        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    componentDidMount() {
        if (this.state.scrollToMessage) {
            if (!this.isInViewport(-100)) {
                this.scrollToRef(this.ref)
            }
        }
    }

    render() {
        let classNames = classnames(
            "message message--inline",
            AlertMessageInline.CLASSES[this.state.type].className,
            this.state.className,
            {
                "align": !this.state.description
            });

        return (
            <div ref={this.ref} className={classNames}>
                <span className={classnames("icon", AlertMessageInline.CLASSES[this.state.type].icon)} />
                {this.state.head && <h4 className="message__head">
                    <b>{this.state.head}</b>
                </h4>}

                {this.state.description && <div className="message__description">
                    <p>{this.state.description}</p>
                </div>}

                {this.props.children && <div className="message__description">
                    {this.props.children}
                </div>}
            </div>
        );
    }
}

AlertMessageInline.propTypes = {
    type: PropTypes.string,
    innerRef: PropTypes.object,
    scrollToMessage: PropTypes.bool
};

export default AlertMessageInline;