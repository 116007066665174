import { GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { useCallback, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchResponsiblePersons } from "../../actions/responsiblePersonsActions";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import { formatPhoneNumber } from "../../helpers/phoneHelper";
import AlertMessage from "../common/AlertMessage";
import Icon from "../common/Icon";
import LinkElement from "../common/LinkElement";
import defaultImage from "../../images/default-profile-image.png";

const BusinessOverviewClientManager = () => {
    const dispatch = useDispatch();

    const {
        data: clientManagers,
        fetched: clientManagersFetched,
        fetching: clientManagersFetching,
        error: clientManagersError
    } = useSelector(state => state.responsiblePersons);

    const hasClientManagersError = !clientManagersFetching && !!clientManagersError;

    const fetchData = useCallback(() => {
        dispatch(fetchResponsiblePersons());
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const renderContactCard = (clientManager, i) => {
        const imageUrl = clientManager.imageUrl || defaultImage;

        return <div key={i} className="contact-card">
            <div style={{ backgroundImage: `url('${imageUrl}')` }}
                className="contact-card__image" ></div>
            <div className="contact-card__content">
                {!!clientManager.name &&
                    <Typography use="body1" className="mdc-typography mdc-typography--bold">
                        {clientManager.name}
                    </Typography>}
                {!!clientManager.email &&
                    <LinkElement className="mdc-theme--primary" href={`mailto:${clientManager.email}`}>
                        {clientManager.email}
                    </LinkElement>}
                {!isNullOrEmpty(clientManager.phones) &&
                    <Typography use="body1">
                        {clientManager.phones
                            .map(x => formatPhoneNumber(x))
                            .reduce((a, b) => a + "; " + b)}
                    </Typography>}
            </div>
        </div>
    };

    return <GridInner>
        <GridCell span={12}>
            <div className="contact-info-item">
                <Icon name="user-round" className="contact-info-item__icon" />
                <div className="contact-info-item__content">
                    <Typography use="body1">
                        <FormattedMessage id="BusinessOverview.ClientManager.YourClientManager" />
                    </Typography>
                    {!clientManagersFetching && hasClientManagersError &&
                        <AlertMessage
                            className="mt-16"
                            type={AlertMessage.TYPE_ALERT}
                            isSmall={true}
                            title={<FormattedMessage id="BusinessOverview.ClientManagers.Error" />} />}

                    {clientManagersFetched &&
                        (isNullOrEmpty(clientManagers)
                            ? <AlertMessage
                                className="mt-16"
                                type={AlertMessage.TYPE_NOTICE}
                                isSmall={true}
                                title={<FormattedMessage id="BusinessOverview.ClientManagers.NoItems" />} />
                            : clientManagers.map(renderContactCard)
                        )}
                </div>
            </div>
        </GridCell >
    </GridInner >;
}

export default injectIntl(BusinessOverviewClientManager);