import { IsLatvianWeb } from "../helpers/AlternativeDesign"
import ServiceEe from "./ServiceEe";
import ServiceLv from "./ServiceLv";

const Service = () => {
    const isLatvianWeb = IsLatvianWeb();

    return (
        isLatvianWeb
            ? <ServiceLv />
            : <ServiceEe />
    );
}

export default Service;