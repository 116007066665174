import { hasAllEmptyProperties, isNullOrEmpty, typeOf } from "./objectHelper";

/**
 * Returns the string representation of a CRM address object
 * @param {object} crmAddress
 * @returns {String} CRM address as one line string
 */
const getAddressAsString = (crmAddress) => {
    if (crmAddress && !isNullOrEmpty(crmAddress) && !hasAllEmptyProperties(crmAddress)) {
        const streetSection = getStreetAsString(crmAddress);

        return (`${streetSection}` +
            `${addPartIfExists(crmAddress.parish)}` +
            `${addPartIfExists(crmAddress.city)}` +
            `${addPartIfExists(crmAddress.county)}` +
            `${crmAddress.postalCode}`).replace(/(, )$/, "");
    }

    return "";
}

/**
 * Returns the string representation of the street part of a CRM address object
 * @param {object} crmAddress
 * @returns {String} street address part of a CRM address as one line string
 */
const getStreetAsString = (crmAddress) => {
    if (!isNullOrEmpty(crmAddress) && !hasAllEmptyProperties(crmAddress)) {
        const apartmentSection = crmAddress.apartmentNr ? `-${crmAddress.apartmentNr}` : "";
        const houseSection = crmAddress.houseNr ? ` ${crmAddress.houseNr}${apartmentSection}` : "";
        return crmAddress.street ? `${crmAddress.street}${houseSection}, ` : "";
    }

    return "";
}

const addPartIfExists = (part) => {
    return part ? `${part}, ` : "";
}

const isString = (val) => {
    return typeof val === 'string';
}

const postalCodeRegex = /^\d{5}$/;

const isAddressValid = (crmAddress) => {
    if (crmAddress === null ||
        crmAddress === undefined ||
        typeOf(crmAddress) !== "object" ||
        Object.keys(crmAddress).length === 0) {
        return false;
    }

    if (!isString(crmAddress.postalCode) &&
        !postalCodeRegex.test(crmAddress.postalCode)) {
        return false;
    }

    if (!isString(crmAddress.county) ||
        crmAddress.county.length === 0) {
        return false;
    }

    if (!isString(crmAddress.city) ||
        crmAddress.city.length === 0) {
        return false;
    }

    return true;
}

const addressRegex = /^.+,.+\d{5}$/;

const isAddressObjectValidRegex = (address) => {
    const addressString = getAddressAsString(address);
    return isAddressStringValidRegex(addressString);
}

const isAddressStringValidRegex = (addressString) => {
    return addressRegex.test(addressString);
}

export {
    getAddressAsString,
    getStreetAsString,
    isAddressValid,
    isAddressObjectValidRegex,
    isAddressStringValidRegex
}