import { FETCH_BASKETBALL_PROJECT_STATISTICS } from "../../actions/basketballCommunityProjectActions";

export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_BASKETBALL_PROJECT_STATISTICS}_PENDING`: {
            return {...state, fetching: true, fetched: false, error: null};
        }
        case `${FETCH_BASKETBALL_PROJECT_STATISTICS}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${FETCH_BASKETBALL_PROJECT_STATISTICS}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default: {}
    }

    return state;
}
