import auth from "../../auth/authenticate"
import {resetCachedFilterToDefault} from "../../cachedFilter";

export default function reducer(state={
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case "ROLE_SET_PENDING": {
            return {...state, fetching: true};
        }
        case "ROLE_SET_REJECTED": {
            return {...state, fetching: false, error: action.payload};
        }
        case "ROLE_SET_FULFILLED": {
            auth.setRole(action.payload);
            resetCachedFilterToDefault();
            return {...state, fetching: false, fetched: true};
        }
        default:
            return state;
    }
}