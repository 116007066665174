import React from 'react';
import classnames from 'classnames';
import TextField, { Input } from '@material/react-text-field';
import { MenuList, MenuListItem, MenuListItemText } from '@material/react-menu';
import Loader from "./Loader";
import ValidationHelper from "../../helpers/validationHelper";
import { injectIntl } from 'react-intl';

class SelectAutoCompleteFieldOverApi extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            searchString: "",
            timeout: null,
            inputId1: "SelectAutoCompleteFieldOverApi" + new Date().getTime(),
            inputId2: "SelectAutoCompleteFieldOverApi2" + new Date().getTime()
        };

        this.toggleMenuOnClick = this.toggleMenuOnClick.bind(this);
        this.clearSelectedText = this.clearSelectedText.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.handleSearchDelegate = this.handleSearchDelegate.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggleMenuOnClick() {
        if (this.props.disabled) {
            return;
        }

        this.setState({ isOpen: !this.state.isOpen });

        //Hack to focus on input
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => document.getElementById(this.state.inputId2).focus(), 1);

    }

    clearSelectedText() {
        if (this.props.disabled) {
            return;
        }

        this.setState({ searchString: "" });
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            searchString: value
        });

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.handleSearchDelegate(), 400);
    }

    handleSearchDelegate() {
        if (this.props.isLoading) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.handleSearchDelegate(), 100);
        } else {
            this.props.onSearch(this.state.searchString);
        }
    }

    selectedItemEquals(selected, item) {
        return selected.value === item.value;
    }

    onSelected(item) {
        this.props.onSelected(item);
        this.setState({ isOpen: false });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.state.isOpen && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    render() {
        const label = this.props.label
            ? this.props.intl.formatMessage({ id: this.props.label })
            : null;

        const field = this.props.field || null;

        const { onBlur, validationMessage, helperText, isRequired } =
            ValidationHelper.getFieldValidationItems(this.props.validator, this.props.rules, field, this.props.selected.value);
        const hasLabelSubText = !!this.props.labelSubText;
        return (
            <div className="mdc-menu-surface--anchor mdc-menu-search" ref={this.wrapperRef}>
                <TextField
                    helperText={helperText}
                    disabled={this.props.disabled}
                    outlined
                    label={
                        hasLabelSubText
                            ? <>
                                <span className="text">
                                    {this.props.intl.formatMessage({ id: this.props.label })}
                                </span>
                                <span className="sub-text">
                                    {this.props.intl.formatMessage({ id: this.props.labelSubText })}
                                </span>
                            </>
                            : label
                    }
                    className={classnames("mdc-menu-search--holder",
                        {
                            "mdc-menu-search--focused": this.state.isOpen,
                            "mdc-text-field--disabled": this.props.disabled,
                            "label-has-sub-text": hasLabelSubText
                        })}
                    trailingIcon={this.props.isLoading
                        ? <Loader type={Loader.TYPE_TEXT_FIELD} />
                        : <span className="mdc-menu-search__dropdown-icon"></span>}
                >
                    <Input
                        id={this.state.inputId1}
                        required={isRequired}
                        onBlur={onBlur}
                        isValid={!validationMessage}
                        onClick={this.toggleMenuOnClick}
                        readOnly={true}
                        field={this.props.field}
                        value={this.props.selected.value || this.props.intl.formatMessage({ id: this.props.selectPlaceholder })}
                    />
                </TextField>
                <div className={classnames("mdc-menu mdc-menu-surface full-width", { "mdc-menu-surface--open": this.state.isOpen })}>
                    <TextField
                        outlined
                        noLabel={true}
                        label={this.props.intl.formatMessage({ id: this.props.searchPlaceholder })}
                        leadingIcon={<i className="icon-search"></i>}
                        trailingIcon={this.state.searchString ? <span className="icon-close"></span> : null}
                        onTrailingIconSelect={this.state.searchString ? this.clearSelectedText : null}>
                        <Input
                            id={this.state.inputId2}
                            value={this.state.searchString}
                            onChange={this.handleChange}
                        />
                    </TextField>
                    <MenuList className="mdc-list--select">
                        {this.state.isOpen
                            ? this.props.options
                                .map((item, index) => (
                                    <MenuListItem
                                        key={index}
                                        className={classnames({
                                            "mdc-list-item--selected": this.selectedItemEquals(this.props.selected, item),
                                        })}
                                        onClick={() => this.onSelected(item)}
                                    >
                                        <MenuListItemText primaryText={item.value} />
                                    </MenuListItem>
                                ))
                            : null}
                    </MenuList>
                </div>
            </div>
        );
    }
}

export default injectIntl(SelectAutoCompleteFieldOverApi)