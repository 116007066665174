export default function reducer(state = {
    data: [],
    fetching: false,
    fetched: false,
    error: null,
    isExtendCard: false
}, action) {
    if (action.type.startsWith("POST_ORDER_BONUS_CARD") && action.payload && action.payload.config && action.payload.config.data) {
        state.isExtendCard = JSON.parse(action.payload.config.data).bonusCardNr !== undefined;
    }
    switch (action.type) {
        case "POST_ORDER_BONUS_CARD_PENDING": {
            return { ...state, fetching: true, fetched: false, error: null };
        }
        case "POST_ORDER_BONUS_CARD_REJECTED": {
            return { ...state, fetching: false, error: action.payload };
        }
        case "POST_ORDER_BONUS_CARD_FULFILLED": {
            return { ...state, fetching: false, fetched: true, data: action.payload.data };
        }
        default:
            return state;
    }
}