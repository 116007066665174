import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "../../styles/components/_link-card.scss";

const LinkCard = ({ icon, title, description, links, badge }) => {
    const isExternal = (link) => {
        return !link.url.startsWith("/");
    }

    const renderLink = (link) => {
        if (link.action) {
            return (<a href="#" onClick={link.action}>{link.text}</a>);
        }

        if (isExternal(link)) {
            return (<a href={link.url} target="_blank" rel="noopener noreferrer">{link.text}</a>);
        }

        return (<Link to={link.url}>{link.text}</Link>);
    };

    return (
        <div className="link-card">
            <div className="link-card--icon">
                <div className={`${icon}`} >
                    {badge && <span className="badge">{badge}</span>}
                </div>
            </div>
            <div className="link-card--content">
                <div className="link-card--content-title">{title}</div>
                <div className="link-card--content-description">{description}</div>
                {links.map((link, index) => {
                    return (
                        <div key={index} className="link-card--content-url">
                            {renderLink(link)}
                            <i className="icon icon-arrow-right" />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

LinkCard.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    })).isRequired
}

export default LinkCard;