class BaseIdCodeValidator {
    //Get check sum of id code
    calculateCheckSum(code, multiplier) {
        let total = 0;

        for (let i = 0; i < multiplier.length; i++) {
            total += code.charAt(i) * multiplier[i];
        }

        return total;
    }

    validate(code) {
        return false;
    }
}

export default BaseIdCodeValidator;