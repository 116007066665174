import auth from '../../auth/authenticate';
import axios from 'axios';

export default function reducer(state={
    user: auth.getUser(),
    isLoggedIn: auth.isLoggedIn(),
    role: auth.getRole(),
    loggingIn:false,
    hasError: false,
    isLoggingOut: false,
    isSmsLogin: false
}, action) {
    switch (action.type) {
        case "UPDATE_USER": {
            return {...state, user: auth.getUser(), role: auth.getRole(), isLoggedIn: auth.isLoggedIn()};
        }
        case "LOG_IN_PENDING": {
            return {...state, isLoggedIn: false, loggingIn: true, hasError: false};
        }
        case "LOG_IN_FULFILLED": {
            return {
                ...state,
                isLoggedIn: true,
                loggingIn: false,
                user: action.payload.data.user,
                role: action.payload.data.role,
                hasError: false,
                isNewUser: action.payload.data.isNewUser,
                isSmsLogin: action.payload.data.isSmsLogin
            };
        }
        case "LOG_IN_REJECTED": {
            if (axios.isCancel(action.payload)) {
                return {...state, isLoggedIn: false, loggingIn: false, hasError: false};
            }
            return {...state, isLoggedIn: false, loggingIn: false, hasError: true};
        }
        case "LOG_OUT_PENDING": {
            return {...state, isLoggingOut: true, hasError: false};
        }
        case "LOG_OUT_FULFILLED": {
            return {...state, isLoggingOut: false, isLoggedIn: false, loggingIn: false, user: null, role: null, isNewUser: false, isSmsLogin: false };
        }
        case "LOG_OUT_REJECTED": {
            return {...state, isLoggingOut: false, loggingIn: false, isLoggedIn: auth.isLoggedIn(), hasError: true, user: auth.getUser()};
        }
        default:
            return state;
    }
}

