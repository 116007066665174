/**
 * Gets the object type https://stackoverflow.com/a/28475133
 * @param {Object} obj
 * @returns type of the underlying object
 */
function typeOf(obj) {
    return {}.toString.call(obj).split(' ')[1].slice(0, -1).toLowerCase();
}

/**
 * Check if object is null, undefined, or empty
 * @param {Object} obj
 * @returns true if object is null, undefined, or empty
 */
const isNullOrEmpty = (obj) => {
    if (obj === undefined || obj === null) {
        return true;
    }
    if (typeOf(obj) === "object") {
        return Object.keys(obj).length === 0;
    }
    if (typeOf(obj) === "array") {
        return !obj.length;
    }
    return false;
}

/**
 * Check if object has all properties set to either null, undefined, or ""
 * @param {Object} obj
 * @returns {boolean}
 */
const hasAllEmptyProperties = (obj) => Object.values(obj).every(value => {
    return value === null || value === undefined || value === "";
});

/**
 * Value based comparison of two objects
 * @param {Object} objOne first object
 * @param {Object} objTwo second object
 * @returns {Boolean} true if objects have the same values
 */
const areEqual = (objOne, objTwo) => {
    const props1 = Object.getOwnPropertyNames(objOne);
    const props2 = Object.getOwnPropertyNames(objTwo);

    if (props1.length !== props2.length) {
        return false;
    }

    for (const element of props1) {
        let val1 = objOne[element];
        let val2 = objTwo[element];
        let areObjects = typeOf(val1) === "object" && typeOf(val2) === "object";

        if ((areObjects && !areEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
}

export {
    areEqual,
    isNullOrEmpty,
    hasAllEmptyProperties,
    typeOf
}
