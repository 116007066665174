import React from "react";
import {FormattedMessage,} from 'react-intl';
import { Grid, GridCell } from '@rmwc/grid';
import { Typography } from "@rmwc/typography";
import { FuelPlusElectricityPath, ServicePath} from "../const/routes";
import { Button } from "@rmwc/button";
import {
    RedirectToFuelPlusElectricity
} from "../components/FuelPlusElectricity/FuelPlusElectricitySign";

class ContractThankYou extends React.Component{
    getRedirectPath() {
        if (localStorage.getItem(RedirectToFuelPlusElectricity) === "true") {
            return FuelPlusElectricityPath;
        }

        return ServicePath;
    }

    render() {
        return (
            <Grid>
                <GridCell span={8}>
                    <Typography use="headline3 mb-30">
                        <FormattedMessage id="Contract.ThankYou.TitleEmail" />
                    </Typography>
                    <Typography use="body1">
                        <FormattedMessage id="Contract.ThankYou.InfoEmail" />
                    </Typography>
                    <Button
                        className="mdc-button--secondary mt-30"
                        outlined
                        tag="a"
                        href={this.getRedirectPath()}
                    >
                        <FormattedMessage id="Contract.ThankYou.BackToServices" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}

export default ContractThankYou;