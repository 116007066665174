import { CLEAR_SUBSCRIPTION_MESSAGES, FETCH_SUBSCRIPTION } from "../../actions/subscriptionActions";

export default function reducer(state={
    data: null,
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_SUBSCRIPTION}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${FETCH_SUBSCRIPTION}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${FETCH_SUBSCRIPTION}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        case CLEAR_SUBSCRIPTION_MESSAGES: {
            return { ...state, error: null }
        }
        default:
            return state;
    }
}