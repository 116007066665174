import React from "react";
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { StartPath } from "../const/routes";
import { useSelector } from "react-redux";
import Loader from "../components/common/Loader";

const NotFound = () => {
  const setRole = useSelector(state => state.setRole);

  return (
    <Grid className="mdc-layout-grid--base">
      <GridInner>
        <GridCell phone={4} tablet={8} desktop={8} className="mdc-layout-grid__cell--main push" >
          {setRole.fetching ? <Loader type={Loader.TYPE_CENTER} /> :
            <div className="information upper down">
            <Typography use="headline2" className="mdc-typography down mdc-theme--primary">
              <FormattedMessage children={msg => <>{msg}</>} id="Error.PageNotFound" />
            </Typography>
            <div className="information__description">
              <Typography use="body1" className="head uppercase">
                <Button className="mdc-button--link mdc-theme--secondary left"
                  icon={<Icon className="mdc-button__icon icon icon-arrow-left left" />}
                  tag={Link} to={StartPath} label={<FormattedMessage id="Contract.Footer.BackToMain.Button" />}
                />
              </Typography>
            </div>
          </div>
          }
        </GridCell>
      </GridInner>
    </Grid>
  );
}

export default NotFound;