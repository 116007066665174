import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@rmwc/button";
import { fetchConsumptionLocations } from "../actions/consumptionlocationActions";
import { hasGasoPermission, registerGasoPermission, clearRegisterGasoPermission } from "../actions/contractActions";
import { ContractGasPath, ContractsPath, ReadingsGasPath } from "../const/routes";
import { capitalize } from "../helpers/stringHelper";
import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import AlertMessage from "../components/common/AlertMessage";
import Loader from "../components/common/Loader";
import BadgeCard from "../components/common/BadgeCard";
import { useFeatures } from 'flagged';
import "../styles/blocks/_service.scss";
import "../styles/components/_badge-card.scss";

const ServiceType = {
    Gas: "gas",
    GasoMeterSubmission: "gasoMeterSubmission",
}

const ServiceLv = () => {
    const features = useFeatures();
    const consumptionLocations = useSelector(state => state.consumptionLocations);
    const gasoPermission = useSelector(state => state.hasGasoPermission);
    const registerPermission = useSelector(state => state.registerGasoPermission);

    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const registerGasoPermissionFailed = ((registerPermission.fetched && !registerPermission.data?.hasPermission) || registerPermission.error);
    const gasoPermissionCheckFailed = gasoPermission.error;

    useEffect(() => {
        if (gasoPermission.fetched && !gasoPermission.fetching && !gasoPermission.error && !!gasoPermission.data?.hasPermission) {
            dispatch(fetchConsumptionLocations());
        }
    }, [dispatch, gasoPermission]);

    useEffect(() => {
        if (registerPermission.fetched && !registerPermission.fetching && !registerPermission.error && !!registerPermission.data?.hasPermission) {
            dispatch(clearRegisterGasoPermission());
            history.push(ContractGasPath);
        }
    }, [dispatch, history, registerPermission]);

    useEffect(() => {
        dispatch(hasGasoPermission());
    }, [dispatch]);

    const onRegisterPermission = () => {
        dispatch(registerGasoPermission());
    }

    const renderServices = () => {
        const precontracts = consumptionLocations.data?.precontracts || [];
        const locations = consumptionLocations.data?.locations?.filter(x => x.type === ServiceType.Gas) || [];
        const hasInProgressGas = precontracts.some(x => x.type === ServiceType.Gas);
        const hasPermission = (gasoPermission.fetched && gasoPermission.data?.hasPermission === true) ||
                              (registerPermission.fetched && registerPermission.data?.hasPermission === true);

        const serviceArray = [];

        if (!hasPermission) {
            serviceArray.push({
                type: ServiceType.Gas,
                activeType: BadgeCard.TYPE.PERMISSION_REQUIRED,
                highlighted: true
            });

            return renderServiceRows(serviceArray);
        }

        if (hasInProgressGas) {
            serviceArray.push({
                type: ServiceType.Gas,
                activeType: BadgeCard.TYPE.INPROGRESS,
                highlighted: true
            });
        }

        if (!hasInProgressGas && locations.some(x => !x.isInContract)) {
            serviceArray.push({
                type: ServiceType.Gas,
                activeType: BadgeCard.TYPE.NEW,
                highlighted: true
            });
        }

        if (locations.some(x => x.isInContract)) {
            serviceArray.push({
                type: ServiceType.Gas,
                activeType: BadgeCard.TYPE.DONE
            });
            if (features.GasoMeterSubmit) {
                serviceArray.push({
                    type: ServiceType.GasoMeterSubmission,
                    activeType: BadgeCard.TYPE.GASO_METER_SUBMISSION,
                    icon: 'gas',
                    highlighted: true,
                });
            }
        }

        if (locations.length === 0) {
            serviceArray.push({
                type: ServiceType.Gas,
                activeType: BadgeCard.TYPE.NONE
            });
        }

        return renderServiceRows(serviceArray);
    }

    const renderServiceRows = (serviceArray) => {
        return (
            <div className="service-wrapper">
                {serviceArray.map((service, index) =>
                    <div key={index} className="service-item">
                        {renderRow(service)}
                    </div>
                )}
            </div>
        );
    }

    const renderLocation = (location, index) => {
        return (
            <div className="service-item__location" key={index} >
                <Typography use="body1" className="mdc-typography mdc-typography--body1--bold">
                    {location.eicCode}
                </Typography>
                {location.address &&
                    <Typography use="body1" className="mdc-typography">
                        {location.address}
                    </Typography>
                }
            </div>
        );
    }

    const renderRow = (service) => {
        const locations = consumptionLocations.data?.locations || [];
        const { type, activeType, highlighted, icon } = service;
        const serviceType = capitalize(type);
        const canNotLoadConsumptionLocations = consumptionLocations.error;
        const isServiceDisabled = gasoPermissionCheckFailed || registerGasoPermissionFailed;

        return (
            <BadgeCard
                title={<FormattedMessage id={`Service.${serviceType}`} />}
                description={<FormattedMessage id={`Service.${serviceType}.Description`} />}
                subTitle={
                    <>
                        {canNotLoadConsumptionLocations ?
                            <FormattedMessage id="Service.ConsumptionLocationsError" /> :
                            <>
                                {activeType === BadgeCard.TYPE.INPROGRESS &&
                                    <FormattedMessage id={`Service.${serviceType}.InProgress`} />
                                }
                            </>
                        }
                    </>
                }
                icon={icon ?? type}
                type={canNotLoadConsumptionLocations || isServiceDisabled ? BadgeCard.TYPE.DISABLED : activeType}
                highlighted={highlighted}
                contentChildren={
                    <>
                        {activeType === BadgeCard.TYPE.DONE &&
                            locations
                                .filter((x) => x.type === ServiceType.Gas && x.isInContract)
                                .map(renderLocation)
                        }
                    </>
                }
                actions={
                    <>
                        {activeType === BadgeCard.TYPE.PERMISSION_REQUIRED && (
                            <Button onClick={onRegisterPermission} disabled={isServiceDisabled}>
                                <FormattedMessage id="Service.Contract.RegisterPermission" />
                            </Button>
                        )}
                        {activeType === BadgeCard.TYPE.NEW && (
                            <Button tag="a" href={ContractGasPath} disabled={isServiceDisabled}>
                                <FormattedMessage id="Service.Contract.Create" />
                            </Button>
                        )}
                        {activeType === BadgeCard.TYPE.DONE && (
                            <Button tag="a" href={ContractsPath}>
                                <FormattedMessage id="Service.Contract.Info" />
                            </Button>
                        )}
                        {activeType === BadgeCard.TYPE.INPROGRESS && (
                            <Button tag="a" href={ContractGasPath}>
                                <FormattedMessage id="Service.Contract.Continue" />
                            </Button>
                        )}
                        {activeType === BadgeCard.TYPE.GASO_METER_SUBMISSION && (
                            <Button tag="a" href={ReadingsGasPath}>
                                <FormattedMessage id="Service.GasoMeterSubmission.Submit" />
                            </Button>
                        )}
                    </>
                }
            />
        );
    };

    const renderErrorMessage = () => {
        let errorMessage = "";

        if (gasoPermissionCheckFailed) {
            errorMessage = "Service.Contract.PermissionCheck.Error";
        }

        if (registerGasoPermissionFailed) {
            errorMessage = "Service.Contract.RegisterPermission.Error";
        }

        if (!errorMessage) {
            return null;
        }

        return (
            <AlertMessage
                className="mb-15"
                type={AlertMessage.TYPE_ALERT}
                title={intl.formatMessage({ id: errorMessage })}
            />
        );
    }

    const renderSuccessMessage = () => {
        if (registerPermission.fetched && registerPermission.data?.hasPermission === true) {
            return (
                <AlertMessage
                    className="mb-15"
                    type={AlertMessage.TYPE_DONE}
                    title={intl.formatMessage({ id: "Service.Contract.RegisterPermission.Success" })}
                />
            );
        }

        return null;
    }

    if (consumptionLocations.fetching || gasoPermission.fetching || registerPermission.fetching) {
        return (
            <Loader type={Loader.TYPE_CENTER} />
        );
    }

    return (
        <Grid>
            <GridCell span={12}>
                {renderSuccessMessage()}
                {renderErrorMessage()}
                {renderServices()}
            </GridCell>
        </Grid>
    )
}

export default ServiceLv;