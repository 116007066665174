import { FETCH_PURCHASE_RIGHT, RESET_PURCHASE_RIGHT } from "../../actions/fuelTaxiOrderActions";

export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_PURCHASE_RIGHT}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${FETCH_PURCHASE_RIGHT}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${FETCH_PURCHASE_RIGHT}_FULFILLED`: {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data};
        }
        case RESET_PURCHASE_RIGHT: {
            return {...state, fetching: false, fetched: false, error: null, data: {}};
        }
        default:
            return state;
    }
}