import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Grid, GridCell } from "@rmwc/grid";
import RoleDialog from "../../LoginForm/RoleDialog";
import {IsLatvianWeb} from "../../../helpers/AlternativeDesign";
import logo from "../../../images/logo-white.svg";
import { StartPath, ProductionPath, ConsumptionPath } from '../../../const/routes';
import LinkElement from "../../common/LinkElement";
import { FormattedMessage, useIntl } from "react-intl";
import auth from "../../../auth/authenticate";
import { useDispatch, useSelector } from "react-redux";
import MenuNotification from "../../menu/MenuNotification";
import MenuProfile from "../../menu/MenuProfile";
import { PrimaryMenuItem } from "../../../const/MenuDesktop";
import MenuItem from "../../menu/MenuItem";
import { Drawer, DrawerHeader, DrawerContent, DrawerTitle } from "@rmwc/drawer";
import { Button } from "@rmwc/button";
import { fetchSignedContracts } from "../../../actions/contractActions";
import { fetchRoles, setRole } from "../../../actions/accountActions";
import { GasNew, ElectricityNew } from "../../../const/contractType";
import useMenu from "../../../helpers/hooks/useMenu";
import { getMenuIndex } from "../../../helpers/menuHelper";
import Loader from "../../common/Loader";

const HeaderDesktop = () => {
    const [isRoleDialogOpen, setIsRoleDialogOpen] = useState(false);
    const [menu, setMenu] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tempSelectedMenuItemIndex, setTempSelectedMenuItemIndex] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [businessRoles, setBusinessRoles] = useState([]);
    const [privateRoles, setPrivateRoles] = useState([]);
    const contracts = useSelector(state => state.signedContracts);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const menuSelection = useSelector(state => state.menuSelection);

    const AlexelaLogoAlt = "Alexela logo";

    const userIsLoggedIn = isLoggedIn && auth.isLoggedIn();
    const currentRoleId = auth.getRoleId();
    const currentUserId = auth.getUserId();
    const isCompany = auth.isCompany();
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [appMenu, setMenuSelection] = useMenu();

    useEffect(() => {
        const secondaryMenu = appMenu.filter(item => item.isDisplayed !== false);

        if (isCompany) {
            setMenuSelection({ root: PrimaryMenuItem.BusinessCustomer });
        }

        const hasGasOrElectricityContract = contracts.data
            .find(c => c.type === GasNew || c.type === ElectricityNew) !== undefined;

        if (!hasGasOrElectricityContract) {
            const menuCopy = JSON.parse(JSON.stringify(secondaryMenu));
            menuCopy[0].children = menuCopy[0].children?.filter(item =>
                item.path !== ProductionPath && item.path !== ConsumptionPath);
            setMenu(menuCopy);
        } else {
            setMenu(secondaryMenu);
        }
    }, [contracts.data, isCompany, appMenu, setMenuSelection]);

    useEffect(() => {
        if (userIsLoggedIn) {
            dispatch(fetchSignedContracts());
            dispatch(fetchRoles())
                .then(({ value }) => {
                    const businessRoles = value.data?.filter(item => item.isCompany) || [];
                    setBusinessRoles(businessRoles);
                    const privateRoles = value.data?.filter(item => !item.isCompany) || [];
                    setPrivateRoles(privateRoles);
                });
        }
    }, [dispatch, userIsLoggedIn]);

    useEffect(() => {
        if (menu) {
            const index = getMenuIndex(menu, location.pathname);
            if (index > -1) {
                setMenuSelection({ secondary: menu[index] });
            }
        }
    }, [location, menu, setMenuSelection]);

    const onCloseRolesDialog = () => {
        setIsRoleDialogOpen(false);
    }

    const onClickPrimaryMenuItem = (item) => {
        if (item === PrimaryMenuItem.HomePage) {
            return;
        }

        if (businessRoles.length > 0 && item === PrimaryMenuItem.BusinessCustomer) {
            if (businessRoles.length > 1) {
                setIsRoleDialogOpen(true);
                return;
            }

            if (currentRoleId === businessRoles[0].crmId) {
                return;
            }

            setIsLoading(true);
            dispatch(setRole(businessRoles[0].crmId))
                .finally(() => setIsLoading(false));
        } else if (item === PrimaryMenuItem.PrivateCustomer) {
            if (privateRoles.length > 0) {
                setIsRoleDialogOpen(true);
                return;
            }

            if (menuSelection.root === item) {
                return;
            }

            setIsLoading(true);
            dispatch(setRole(currentUserId))
                .then(() => history.go(0))
                .finally(() => setIsLoading(false));
        }
    }

    const onClickSecondaryMenuItem = (index) => {
        if (menu[index].children) {
            setTempSelectedMenuItemIndex(index);
            setIsDrawerOpen(true);
        } else {
            setMenuSelection({ secondary: menu[index] });
        }
    }

    const onDrawerItemClicked = () => {
        setMenuSelection({ secondary: menu[tempSelectedMenuItemIndex] });
        setIsDrawerOpen(false);
    }

    const getTertiaryMenuItems = () => {
        return menu[tempSelectedMenuItemIndex]?.children || [];
    }

    return (
        <header className="header__outer">
            {isLoading && <Loader type={Loader.TYPE_COVER} />}
            <div className="header__top">
                <Grid>
                    <GridCell span={12} className="nav--secondary">
                        <Link to={StartPath} className="header__logo">
                            <img alt={AlexelaLogoAlt} src={logo} />
                        </Link>
                        <ul>
                            <li>
                                <LinkElement onClick={() => onClickPrimaryMenuItem(PrimaryMenuItem.HomePage)}
                                    href={intl.formatMessage({ id: "TopContent.AlexelaUrl" })} target="_blank">
                                    <FormattedMessage id="TopContent.Navigation.Web" />
                                </LinkElement>
                                {!IsLatvianWeb() &&
                                    <>
                                        <LinkElement
                                            className={menuSelection.root === PrimaryMenuItem.PrivateCustomer ? "selected" : ""}
                                            onClick={() => onClickPrimaryMenuItem(PrimaryMenuItem.PrivateCustomer)}>
                                            <FormattedMessage id="TopContent.Selfservice.Private"/>
                                        </LinkElement>
                                        <LinkElement
                                            className={menuSelection.root === PrimaryMenuItem.BusinessCustomer ? "selected" : ""}
                                            onClick={() => onClickPrimaryMenuItem(PrimaryMenuItem.BusinessCustomer)}>
                                            <FormattedMessage id="TopContent.Selfservice.Business"/>
                                        </LinkElement>
                                    </>
                                }
                            </li>
                            {userIsLoggedIn &&
                                <li>
                                    <MenuNotification />
                                    <MenuProfile />
                                </li>
                            }
                        </ul>
                    </GridCell>
                </Grid>
            </div>
            <div className="header__bottom">
                <Grid className="pb-0 pt-0">
                    <GridCell span={12} className="nav--primary">
                        <div className="mdc-list">
                            {menu.map((item, index) =>
                                <MenuItem
                                    href={!!item.children ? null : item.path}
                                    label={item.translationKey}
                                    key={"navigation" + index}
                                    onClick={() => onClickSecondaryMenuItem(index)}
                                    isSelected={!!menuSelection.secondary?.path && item.path === menuSelection.secondary?.path}
                                />
                            )}
                        </div>
                    </GridCell>
                </Grid>
            </div>
            <RoleDialog
                isOpen={isRoleDialogOpen}
                onClose={onCloseRolesDialog}
            />
            <Drawer
                modal
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                className="drawer-desktop"
            >
                <DrawerHeader>
                    <DrawerTitle>
                        <div className="drawer-desktop--title">
                            <Button icon={{
                                icon: "close",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-",
                                className: "close-icon"
                            }} onClick={() => setIsDrawerOpen(false)} />
                            <img alt={AlexelaLogoAlt} src={logo} />
                        </div>
                    </DrawerTitle>
                </DrawerHeader>
                <DrawerContent>
                    <ul className="drawer-desktop--content">
                        {getTertiaryMenuItems().map((item, index) =>
                            <li key={index}>
                                <Link to={item.path} onClick={() => onDrawerItemClicked()}>
                                    <FormattedMessage id={item.translationKey} />
                                </Link>
                            </li>
                        )}
                    </ul>
                </DrawerContent>
            </Drawer>
        </header>
    );
}

export default HeaderDesktop;