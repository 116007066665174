import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Typography } from "@rmwc/typography";
import { bindActionCreators } from 'redux';
import { Button } from "@rmwc/button";
import BaseFormComponent from '../components/common/BaseFormComponent';
import {unsubscribeFromEmails, fetchSubscriptionByCode } from "../actions/subscriptionActions";
import "../styles/blocks/_unsubscribe.scss";
import logo from "../images/logo-white.svg";
import { Link } from "react-router-dom";
import LanguageSelector from "../components/LanguageSelector";
import Loader from "../components/common/Loader";
import AlertMessage from '../components/common/AlertMessage';


class UnsubscribeEmails extends BaseFormComponent {

    constructor(props) {
        super(props);
        this.state = {
            showSuccessMessage: false,
        }
        this.fetchSubscription = this.fetchSubscription.bind(this);
        this.unsubscribeFromEmails = this.unsubscribeFromEmails.bind(this);
        this.renderSubscription = this.renderSubscription.bind(this);
    }

    fetchSubscription() {
        this.props.fetchSubscriptionByCode(this.props.match.params.code)
    }

    unsubscribeFromEmails() {
        this.props.unsubscribeFromEmails(this.props.subscription.contactCrmId).then(() => {
            this.setState({ showSuccessMessage: true });
        });
    }

    componentDidMount() {
        if (this.props.match.params.code && !this.props.subscriptionFetched && !this.props.subscriptionFetching) {
            this.fetchSubscription();
        }
    }

    renderSubscription() {
        return (
            <>
                {this.props.subscriptionFetching ?
                    <div className="mt-45">
                        <Loader isWhite={true} />
                    </div>
                :
                    <>
                        {this.props.subscriptionFetched ?
                            <>
                                {this.props.subscription.marketingAcceptance ?
                                    <>
                                        <div className="unsubscribe__terms">
                                            <Typography
                                                use="headline2"
                                                className="unsubscribe__limiter"
                                            >
                                                <FormattedMessage id="Unsubscribe.MarketingAcceptanceStatus.True"/>
                                            </Typography>

                                            <Typography
                                                use="subtitle2"
                                                className="mt-30 mb-25"
                                            >
                                                <FormattedMessage id="Unsubscribe.Confirm.Content1"/>
                                            </Typography>
                                            <Button className="mdc-button" unelevated onClick={this.unsubscribeFromEmails} >
                                                <Typography
                                                use="body1"
                                                className=""
                                            >
                                                <FormattedMessage id="Unsubscribe.Confirm.ButtonText"/>
                                            </Typography>
                                            </Button>
                                        </div>
                                    </>
                                :
                                    <div className="unsubscribe__terms">
                                            <Typography
                                                use="headline2"
                                                className="unsubscribe__limiter"
                                            >
                                                <FormattedMessage id="Unsubscribe.MarketingAcceptanceStatus.False"/>
                                            </Typography>
                                    </div>
                                }
                            </>
                            :
                            <>
                                <AlertMessage
                                    className="unsubscribe__notice"
                                    title={<FormattedMessage id="Unsubscribe.CodeNotFound" />}
                                />
                            </>
                        }
                    </>
                }
            </>
        )
    }

    render() {
        return (
            <div className="unsubscribe">
                <div className="unsubscribe__language">
                    <LanguageSelector />
                </div>
                <Link to="/" className="link">
                    <img className="unsubscribe__logo" alt="Alexela logo" src={logo} />
                </Link>
                {this.state.showSuccessMessage ?
                <>
                    <AlertMessage
                        className="unsubscribe__notice"
                        title={<FormattedMessage id="Unsubscribe.SuccessMessage" />}
                    />
                </>
                :
                <>{this.renderSubscription()}</>
                }
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        subscriptionFetched: state.subscription.fetched,
        subscriptionFetching: state.subscription.fetching,
        subscriptionErrors: state.subscription.error,
        subscription: state.subscription.data,


        unsubscribeFromEmailsFetched: state.unsubscribeFromEmails.fetched,
        unsubscribeFromEmailsFetching: state.unsubscribeFromEmails.fetching,
        unsubscribeFromEmailsErrors: state.unsubscribeFromEmails.error,
        unsubscribeFromEmailsRegister: state.unsubscribeFromEmails.data,

    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchSubscriptionByCode,
        unsubscribeFromEmails,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(UnsubscribeEmails);
