import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Grid, GridCell } from "@rmwc/grid";
import AlertMessage from "../components/common/AlertMessage";
import { Button } from "@rmwc/button";
import { StartPath } from "../const/routes";

const ErrorPage = () => {
    const intl = useIntl();
    const history = useHistory();

    return (
        <Grid>
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_ALERT}
                    title={intl.formatMessage({ id: "General.Error" })}
                />
            </GridCell>
            <GridCell span={12}>
                <Button unelevated onClick={() => history.replace(StartPath)}>
                    <FormattedMessage id="General.BackToMainPage" />
                </Button>
            </GridCell>
        </Grid>
    );
}

export default ErrorPage;