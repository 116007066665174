import language from "../lang/lang";

export function MapLang(lang) {
    if (lang === language.Et) {
        return "et-EE";
    } else if (lang === language.En) {
        return "en-US";
    } else if (lang === language.Ru) {
        return "ru-RU";
    } else if (lang === language.Lv) {
        return "lv-LV";
    }
    return lang;
}

export function FromMapLang(lang) {
    if (lang === "et-EE") {
        return language.Et;
    } else if (lang === "en-US") {
        return language.En;
    } else if (lang === "ru-RU") {
        return language.Ru;
    } else if (lang === "lv-LV") {
        return language.Lv;
    }
    return language.En;
}

export function MapLangToCultureXc(lang) {
    if (lang === language.Et) {
        return "CULTURE.ET-EE";
    } else {
        return "CULTURE.EN-GB";
    }
}