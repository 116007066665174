import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Typography } from "@rmwc/typography";
import { IconButton } from "@rmwc/icon-button";
import { injectIntl } from "react-intl";
import moment from "moment";

import { Icon } from "@rmwc/icon";
import { Button } from "@rmwc/button";

import {
    Test,
    NewInvoice,
    NewOccInvoice,
    Marketing,
    Community,
    LoyaltyProgram,
    FamilyGroup
} from "../../const/notificationType";
import { defaultIconProps } from "../common/Icon";

const NotificationItem = ({ item, cta, markReadAction, loading, actionAs }) => {

    const isAlert = () => item.level === "alert";

    const getIcon = () => {
        if (isAlert()) return "alert";

        switch (item.type) {
            case Test: return "clipboard";
            case NewInvoice:
            case NewOccInvoice: return "document-m";
            case Marketing: return item.subType === "Birthday"
                ? "birthday-cake"
                : "bullhorn";
            case Community: return "trees";
            case LoyaltyProgram: return "stamp";
            case FamilyGroup: return "bullhorn";
            default: return "info";
        }
    }

    const createdOnMoment = moment(item.createdOn);
    const creationTimeString = createdOnMoment.calendar();
    const creationTime = createdOnMoment.format("DD.MM.yyyy HH:mm:ss");

    const isItemClickable = actionAs === "click" && !!cta && !!cta.action;
    const onItemClick = () => {
        if (actionAs === "click" && !!cta.action) {
            cta.action();
        }
    }

    return (
        <div className={classnames(
            "notification-item",
            `notification-item--${item.type}`,
            `notification-item--${item.level}`,
            {
                "notification-item--unread": !item.isRead,
                "notification-item--clickable": isItemClickable,
                "notification-item--has-no-action": !cta?.action
            },
        )}
            onClick={onItemClick}>
            <div className="notification-item__icon">
                <Icon icon={{
                    ...defaultIconProps,
                    icon: getIcon()
                }} />
            </div>

            <div className="notification-item__content">
                <Typography className="mdc-typography notification-item__title">
                    {item.title}
                </Typography>
                <Typography
                    className="mdc-typography notification-item__text">
                    {item.text}
                </Typography>
                <Typography className="mdc-typography notification-item__date" title={creationTime}>
                    {creationTimeString}
                </Typography>
            </div>

            {!!cta && !!cta.action && actionAs === "button" &&
                <div className="notification-item__action">
                    <Button
                        unelevated={isAlert()}
                        outlined={!isAlert()}
                        className="mdc-button--thin mdc-button--primary"
                        onClick={cta.action}>
                        {cta.text || "Ava"}
                    </Button>
                </div>}

            <div className="notification-item__action notification-item__read-status">
                <IconButton
                    checked
                    disabled={item.isRead}
                    onIcon={{
                        ...defaultIconProps,
                        icon: (item.isRead ? "eye-open" : "eye-closed")
                    }}
                    onClick={markReadAction}
                ></IconButton>
            </div>
        </div>);
};

NotificationItem.propTypes = {
    item: PropTypes.shape({
        text: PropTypes.string.isRequired,
        createdOn: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
            PropTypes.instanceOf(moment),
        ]),
        type: PropTypes.string,
        level: PropTypes.string,
        isRead: PropTypes.bool
    }),
    markReadAction: PropTypes.func,
    loading: PropTypes.bool,
    cta: PropTypes.shape({
        action: PropTypes.func,
        text: PropTypes.string
    }),
    actionAs: PropTypes.oneOf(["button", "click"])
}

NotificationItem.defaultProps = {
    item: {
        text: "-",
        type: "generic",
        level: "info",
    },
    actionAs: "button"
}

export default injectIntl(NotificationItem);
