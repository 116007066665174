import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";
import BaseFormComponent from "../common/BaseFormComponent";
import SimpleReactValidator from "simple-react-validator";
import { Typography } from "@rmwc/typography";
import TextFieldGroup from "../common/TextFieldGroup";

import { bindCoopCard } from "../../actions/cardActions";
import { coopCardPrefix } from "../../helpers/cardHelpers";

class BindCoopCard extends BaseFormComponent {
    static BIND_COOP_CARD_NUMBER = "bindCoopCardNumber";

    constructor(props) {
        super(props);

        this.state = {
            [BindCoopCard.BIND_COOP_CARD_NUMBER]: ""
        }
        this.onSubmitBindCoopCard = this.onSubmitBindCoopCard.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale,
            validators: {
                min: {
                    rule: (val, params, validator) => validator.helpers.size(this.addCardPrefix(val)) >= parseInt(params[0]),
                    messageReplace: (msg, params) => msg.replace(":min", params[0])
                },
                max: {
                    rule: (val, params, validator) => validator.helpers.size(this.addCardPrefix(val)) <= parseInt(params[0]),
                    messageReplace: (msg, params) => msg.replace(":max", params[0])
                }
            }
        });
    }

    onSubmitBindCoopCard(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.props.bindCoopCard(this.addCardPrefix(this.state[BindCoopCard.BIND_COOP_CARD_NUMBER]));
    }

    addCardPrefix(value) { return coopCardPrefix + value; }

    render() {
        return (
            <Grid className="mdc-layout-grid--base">
                <GridCell span={10} className="mdc-layout-grid__cell--pull">
                    <div className="information upper">
                        <Typography
                            use="headline2"
                            className="mdc-typography mdc-theme--primary information__head upper"
                        >
                            <FormattedMessage id="Cards.BindCoopCard" />
                        </Typography>
                        <div className="information__description lighter">
                            <Typography use="body3">
                                <FormattedMessage id="Cards.BindCoopCard.Info" />
                            </Typography>
                        </div>
                    </div>
                    {this.props.coopCardBindError != null && this.errors(this.props.coopCardBindError, "Cards.BindBonusCard.Error")}

                    <Grid tag="form" span={12} className="mdc-layout-grid--base" noValidate onSubmit={this.onSubmitBindCoopCard}>
                        <GridCell span={12}>
                            <TextFieldGroup
                                field={BindCoopCard.BIND_COOP_CARD_NUMBER}
                                onChange={this.onChange}
                                validator={this.validator}
                                rules="required|numeric|min:19|max:19|regex:^\d+$"
                                value={this.state[BindCoopCard.BIND_COOP_CARD_NUMBER]}
                                label="Cards.BindCoopCard.CardNumber"
                                type="number"
                                icon={coopCardPrefix}
                                classNames={"mdc-text-field--coop-card-number"}
                            />
                        </GridCell>
                        <GridCell span={12}>
                            <Button
                                type="submit"
                                disabled={this.props.coopCardBindSaving}
                                unelevated
                                className="mdc-button--primary right"
                            >
                                {this.props.coopCardBindSaving ?
                                    <Loader type={Loader.TYPE_BUTTON} />
                                    :
                                    <FormattedMessage id="Cards.BindCoopCard.BindCard" />
                                }
                            </Button>
                        </GridCell>
                    </Grid>
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        coopCardBindSaving: state.bindCoopCard.fetching,
        coopCardBindError: state.bindCoopCard.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        { bindCoopCard },
        dispatch
    );
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(BindCoopCard));