import React from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import PropTypes from 'prop-types';
import { PaymentCardStatus } from "../../const/cardApplication";
import AlertMessage from "../common/AlertMessage";
import { Grid, GridCell } from "@rmwc/grid";

const DEFAULT_DAYS_AS_PENDING = 90;

const ContractFuelPendingPage = ({ paymentCardStatus, hasValidEvChargingContract = false }) => {
    const intl = useIntl();
    const { cardStatus } = paymentCardStatus;

    const renderMatchingNotice = () => {
        if (hasValidEvChargingContract) {
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={intl.formatMessage({ id: "ContractsPage.Notice.ExistingEvContract" })}
                />
            );
        }

        if (cardStatus === PaymentCardStatus.PENDING_DECISION) {
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={intl.formatMessage({ id: "ContractsPage.Notice.ApplicationPending" })}
                    description={intl.formatMessage({ id: "ContractsPage.Notice.ApplicationPendingDescription" })}
                />
            );
        }

        if (cardStatus === PaymentCardStatus.REJECTED) {
            const rejectedToDate = moment(paymentCardStatus.rejectedValidToDate ?? new Date()).startOf("day");
            const currentDate = moment().startOf("day");
            const daysRemaining = rejectedToDate.diff(currentDate, "days") || DEFAULT_DAYS_AS_PENDING;
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={intl.formatMessage({ id: "ContractsPage.Notice.ApplicationUnsuccessful" })}
                    description={intl.formatMessage({ id: "ContractsPage.Notice.ApplicationUnsuccessfulDescription" }, { days: daysRemaining })}
                />
            );
        }
    }

    return (
        <Grid>
            <GridCell span={12}>
                {renderMatchingNotice()}
            </GridCell>
        </Grid>
    );
}

ContractFuelPendingPage.propTypes = {
    paymentCardStatus: PropTypes.object.isRequired,
    hasValidEvChargingContract: PropTypes.bool
};

export default ContractFuelPendingPage;