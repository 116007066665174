import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { GridCell, GridInner } from "@rmwc/grid";

import { deleteDeviceRegistration, fetchDeviceRegistrations, fetchHubInstallation } from "../../actions/pushNotificationActions";
import Loader from "../common/Loader";
import { Button } from "@rmwc/button";
import AlertMessage from "../common/AlertMessage";

const NotificationHubInstallations = () => {
    const dispatch = useDispatch();

    const deviceRegistrations = useSelector(state => state.getDeviceRegistrations);
    const hubInstallations = useSelector(state => state.hubInstallations);
    const { fetching: deletingDeviceRegistration } = useSelector(state => state.deleteDeviceRegistration);

    const hasItems = deviceRegistrations.fetched && deviceRegistrations.data && deviceRegistrations.data.length > 0;
    const hasNoItems = deviceRegistrations.fetched && (!deviceRegistrations.data || deviceRegistrations.data.length <= 0);

    useEffect(() => {
        if (!deviceRegistrations.fetching && !deviceRegistrations.fetched && !deviceRegistrations.error) {
            dispatch(fetchDeviceRegistrations());
        }
    }, [deviceRegistrations.fetched, deviceRegistrations.fetching, deviceRegistrations.error, dispatch]);

    const loadHubInstallation = (installationId) => {
        if (!!installationId) {
            dispatch(fetchHubInstallation(installationId));
        }
    }

    const deletePushNotificationRegistration = (installationId) => {
        if (!!installationId) {
            dispatch(deleteDeviceRegistration(installationId))
                .then(() => dispatch(fetchDeviceRegistrations()));
        }
    }

    const hubInstallationLoaded = (installationId) => Object.keys(hubInstallations.data).indexOf(installationId) >= 0;

    return (
        <GridInner>
            {deviceRegistrations.fetching &&
                <GridCell span={12}>
                    <Loader type={Loader.TYPE_CENTER} />
                </GridCell>}

            {hasNoItems &&
                <GridCell span={12}>
                    <AlertMessage
                        isSmall={true}
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="Debug.PushNotification.NoInstallations" />} />
                </GridCell>}

            {hasItems &&
                <GridCell span={12}>
                    {deviceRegistrations.data?.map(item =>
                        <GridInner key={item.installationId} className="mb-36">
                            <GridCell desktop={5} tablet={3} phone={4}>
                                <div>
                                    <b>Installation id:</b> {item.installationId}
                                </div>
                                <div className="word-wrap">
                                    <b>Push channel:</b> {item.pushChannel}
                                </div>
                                <div>
                                    <b>Platform:</b> {item.platform}
                                </div>
                                <div>
                                    <b>Loodud:</b> {item.createdAt}
                                </div>
                                <div>
                                    <b>Muudetud:</b> {item.lastUpdatedAt}
                                </div>
                                <div>
                                    <b>App version:</b> {item.appVersion ?? "1"}
                                </div>
                            </GridCell>
                            <GridCell desktop={5} tablet={3} phone={4}>
                                <b>Notification hubi installatsioon</b>
                                <Button className="mdc-button--thin ml-12 ml-mobile-clear"
                                    unelevated
                                    disabled={hubInstallations.fetching}
                                    onClick={() => loadHubInstallation(item.installationId)}>
                                    {hubInstallationLoaded(item.installationId)
                                        ? <FormattedMessage id="Debug.PushNotification.ReloadFromHub" defaultMessage="Lae uuesti" />
                                        : <FormattedMessage id="Debug.PushNotification.LoadFromHub" defaultMessage="Lae" />}
                                </Button>
                                {hubInstallationLoaded(item.installationId) &&
                                    <div className="pre word-wrap mt-8">
                                        {JSON.stringify(hubInstallations.data[item.installationId], null, 2)}
                                    </div>}
                            </GridCell>
                            <GridCell desktop={2} tablet={2} phone={4}>
                                <Button className="mdc-button--red"
                                    unelevated
                                    disabled={deletingDeviceRegistration}
                                    onClick={() => deletePushNotificationRegistration(item.installationId)}>
                                    {deletingDeviceRegistration
                                        ? <Loader type={Loader.TYPE_BUTTON} />
                                        : <FormattedMessage id="Debug.PushNotification.RemoveRegistration" defaultMessage="Kustuta" />}
                                </Button>
                            </GridCell>
                        </GridInner>)}
                </GridCell>}
        </GridInner>);
}

export default NotificationHubInstallations;