import { useState } from "react";
import { useIntl } from "react-intl";
import { getSigningCertificate, sign } from "@web-eid/web-eid-library/web-eid";
import { postPrepareSignature, postFinalizeSignature } from "../../actions/digitalSignatureActions";

const useWebeID = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { locale } = useIntl();

    const signIdCard = (fileToSign) => async (dispatch) => {
        setIsLoading(true);

        try {
            const {
                certificate,
                supportedSignatureAlgorithms
            } = await getSigningCertificate({ locale });

            const prepareSigningResponse = await dispatch(postPrepareSignature({
                base64Certificate: certificate,
                supportedSignatureAlgorithms,
                fileName: fileToSign.fileName
            }));

            if (prepareSigningResponse.value.status !== 200) {
                throw new Error("Could not prepare signing process");
            }

            const { hash, hashFunction, fileName } = prepareSigningResponse.value.data;

            const {
                signature,
                signatureAlgorithm
            } = await sign(certificate, hash, hashFunction, { locale });

            const finalizeSignatureResponse = await dispatch(postFinalizeSignature({
                signature,
                signatureAlgorithm,
                fileName
            }));

            if (finalizeSignatureResponse.value.status !== 200) {
                throw new Error("Could not finalize signature");
            }
        } finally {
            setIsLoading(false);
        }
    }

    return [signIdCard, isLoading];
}

export default useWebeID;