import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const POST_BASKETBALL_COMMUNITY_PROJECT_REGISTER = 'POST_BASKETBALL_COMMUNITY_PROJECT_REGISTER';
export const FETCH_CUSTOMER_BASKETBALL_PROJECT_CONTRIBUTION = 'FETCH_CUSTOMER_BASKETBALL_PROJECT_CONTRIBUTION';
export const FETCH_BASKETBALL_PROJECT_STATISTICS = 'FETCH_BASKETBALL_PROJECT_STATISTICS';
export const FETCH_BASKETBALL_CLUBS = 'FETCH_BASKETBALL_CLUBS';


export const basketballCommunityProjectRegister = (preferences) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: POST_BASKETBALL_COMMUNITY_PROJECT_REGISTER,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/basketballCommunityProject/register-basketball-project`, preferences)
    };
};

export const fetchCustomerBasketballProjectContribution = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: FETCH_CUSTOMER_BASKETBALL_PROJECT_CONTRIBUTION,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/basketballCommunityProject/customer-contribution`)
    };
};

export const resetCustomerBasketballProjectContribution = () => {
    return {
        type: `${FETCH_CUSTOMER_BASKETBALL_PROJECT_CONTRIBUTION}_RESET`
    };
};

export const fetchBasketballProjectStatistics = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_BASKETBALL_PROJECT_STATISTICS,
        payload: BusinessApi.get(`api/${accountId}/${roleId}/basketballCommunityProject/statistics`)
    };
};

export const fetchBasketballClubs = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_BASKETBALL_CLUBS,
        payload: BusinessApi.get(`api/${accountId}/${roleId}/basketballCommunityProject/clubs`)
    };
};
