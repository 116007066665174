import auth from "../auth/authenticate";
import BusinessApi from './BusinessApi';
import {
    directFileDownloadAction,
    directFileDownloadWithBodyAction,
} from '../helpers/fileDownloadHelpers';

export const fetchEvChargingOffers = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'GET_EV_CHARGING_OFFERS',
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/evCharging/offers`)
    };
}

export const downloadEvChargingOfferAgentPdf = (id, agentId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/evCharging/offers/${id}/agent/${agentId}`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = "contract_preview.pdf";
            directFileDownloadAction(endpoint, fileName);
        } else {
            dispatch({
                type: 'DOWNLOAD_EV_CHARGING_CONTRACT_AGENT_PDF',
                payload: BusinessApi.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
}

export const fetchEvChargingPdfPreview = (model) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/evCharging/contract-preview`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const token = auth.getToken();
            if (!!token) {
                const body = {
                    token: token.jwt,
                    model: JSON.stringify( {
                        ...model,
                        fileName: "contract_preview.pdf",
                        download: 1,
                        origin: window.location.hostname
                    })
                }
                directFileDownloadWithBodyAction(endpoint, body);
            }
        } else {
            dispatch({
                type: 'DOWNLOAD_EV_CHARGING_CONTRACT_PDF',
                payload: BusinessApi.get(endpoint, {
                    params: {
                        model: JSON.stringify(model)
                    },
                    responseType: "arraybuffer"
                })
            });
        }
    }
}

export const prepareEvChargingContractForSigning = (model) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PREPARE_EV_CHARGING_CONTRACT',
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/evCharging/signing/prepare`, model)
    }
}

export const finalizeEvChargingContract = (id) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_EV_CHARGING_CONTRACT',
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/evCharging/signing/finalize`, { id })
    }
}