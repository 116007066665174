import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchCards } from "../../actions/cardActions";
import { StatusInactive } from "../../const/cardStatus";
import { CardStatusType } from "../../const/paymentCardFilter";
import { CardsPath } from "../../const/routes";
import AlertMessage from "../common/AlertMessage";

const InactiveCardsSection = () => {
    const cards = useSelector(state => state.cards);
    const [hasInactiveCards, setHasInactiveCards] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!cards.fetched && !cards.error && !cards.fetching) {
            dispatch(fetchCards({
                pageNr: 1,
                limit: 50,
                cardStatusType: CardStatusType.StatusOpen
            }));
        }
    }, [dispatch, cards]);

    useEffect(() => {
        const inactiveCards = cards.data?.items?.find(c => c.cardStatusXc === StatusInactive);
        setHasInactiveCards(inactiveCards !== undefined);
    }, [cards.data])

    if (!hasInactiveCards) {
        return null;
    }

    return (
        <div className="p-24">
            <AlertMessage
                isSmall
                type={AlertMessage.TYPE_NOTICE}
                title={<FormattedMessage id="Overview.Cards.PleaseActivate.Title" />}
                description={
                    <div className="link-card--content-url">
                        <Link to={{
                            pathname: CardsPath,
                            state: { tabIndex: 1 }
                        }}>
                            <FormattedMessage id="Overview.Cards.PleaseActivate.LinkText" />
                        </Link>
                        <i className="icon icon-arrow-right" />
                    </div>
                }
            />
        </div>
    );
}

export default InactiveCardsSection;