import React, { useEffect, useState } from "react";
import { Button } from "@rmwc/button";
import { FormattedMessage } from "react-intl";
import { getCardPin } from "../../actions/cardActions";
import CryptoJS from "crypto-js";
import auth from "../../auth/authenticate";

const HiddenPin = <>&bull;&bull;&bull;&bull;</>;
const DEFAULT_TIME = 15; // default time on screen (sek)

const PaymentCardPin = ({ cardNumber }) => {
    const [isPinDisplayed, setIsPinDisplayed] = useState(false);
    const [pin, setPin] = useState("0000");
    const [timer, setTimer] = useState(DEFAULT_TIME);
    const [intervalId, setIntervalId] = useState(null);
    const [isCardPinError, setIsCardPinError] = useState(false);

    const user = auth.getUserData();
    const canViewPin = auth.isCompany() || user.user.crmId === user.role.crmId;

    useEffect(() => {
        if (timer === 0) {
            clearInterval(intervalId);
            setIntervalId(null);
            setIsPinDisplayed(false);
            setTimer(DEFAULT_TIME);
            setIsCardPinError(false);
        }
    }, [timer, intervalId]);

    const onShowPin = () => {
        if (!canViewPin) {
            setIsCardPinError(true);
            return;
        }

        const interval = setInterval(() => setTimer(seconds => seconds - 1), 1000);
        setIntervalId(interval);
        fetchPin();
    }

    const fetchPin = async () => {
        try {
            const data = await getCardPin(cardNumber);
            const decryptedPin = decryptPin(data.pinCode);
            setPin(decryptedPin);
            setIsPinDisplayed(true);
        } catch {
            setIsCardPinError(true);
        }
    }

    const decryptPin = (decryptedPin) => {
        const userData = auth.getUserData();
        const idCode = userData.user.nationalIdentityNumber;
        const decryptKey = idCode.repeat(3).slice(0, -1);
        const pinBytes = CryptoJS.AES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(decryptedPin)
        }, CryptoJS.enc.Utf8.parse(decryptKey), {
            iv: CryptoJS.lib.WordArray.create(null, 16)
        });
        return pinBytes.toString(CryptoJS.enc.Utf8);
    }

    return (
        <>
            {isCardPinError &&
                <div className="mdc-theme--alert">
                    <FormattedMessage id={canViewPin ? "Cards.Error.Pin" : "Cards.Error.PinNotAllowed" } />
                </div>
            }
            {!isCardPinError &&
                <div className="d-flex d-flex--inline align-items-center">
                    <div className="pr-10">
                        <span
                            style={isPinDisplayed ? {} : { letterSpacing: "0.2rem" }}
                            className={isPinDisplayed && isCardPinError ? "mdc-theme--alert" : ""}>
                            {isPinDisplayed ? pin : HiddenPin}
                        </span>
                    </div>
                    <Button unelevated onClick={onShowPin} disabled={isPinDisplayed} className="mdc-button mdc-button--tiny">
                        {isPinDisplayed ? timer : <FormattedMessage id="Cards.ShowPin" />}
                    </Button>
                </div>
            }
        </>
    );
}

export default PaymentCardPin;