export const SET_USING_APP = "SET_USING_APP";
export const SET_APP_VERSION = "SET_APP_VERSION";
export const SET_NOTIFICATION_HUB_VARIBLES = "SET_NOTIFICATION_HUB_VARIBLES";

export const setUsingApp = (value, time) => {
    return {
        type: SET_USING_APP,
        payload: { usingApp: value, time: time }
    }
}

export const setAppVersion = (value, time) => {
    return {
        type: SET_APP_VERSION,
        payload: { appVersion: value, time: time }
    }
}

export const setNotificationHubVariables = (notificationHub, time) => {
    return {
        type: SET_NOTIFICATION_HUB_VARIBLES,
        payload: { notificationHub: notificationHub, time: time }
    }
}