import {MarketingPushNotifications, MarketingSmsMessages} from "../../components/MarketingNotifications";
import {Grid, GridCell} from "@rmwc/grid";
import {Button} from "@rmwc/button";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";
import {useState} from "react";

const MarketingNotifications = () => {
    const [isSmsTabActive, setIsSmsTabActive] = useState(false);

    const toggleTabs = () => {
        setIsSmsTabActive(!isSmsTabActive);
    }

    return (
        <>
            <Grid className="mdc-layout-grid--base mdc-layout-grid--mobile-full mdc-layout-grid--no-gap">
                <GridCell desktop={6} tablet={4} phone={2}>
                    <Button
                        className={classNames("mdc-button-tab-underlined mdc-button--tab mdc-button--wide", {"active": !isSmsTabActive})}
                        onClick={() => toggleTabs()}>
                        <FormattedMessage id="MarketingNotifications.PushNotifications"/>
                    </Button>
                </GridCell>
                <GridCell desktop={6} tablet={4} phone={2}>
                    <Button
                        className={classNames("mdc-button-tab-underlined mdc-button--tab mdc-button--wide", {"active": isSmsTabActive})}
                        onClick={() => toggleTabs()}>
                        <FormattedMessage id="MarketingNotifications.SMSMessages"/>
                    </Button>
                </GridCell>
            </Grid>
            {!isSmsTabActive
                ? <MarketingPushNotifications/>
                : <MarketingSmsMessages/>}
        </>
    )
}

export default MarketingNotifications