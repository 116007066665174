import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";
import CardTransactionFilter from "../../components/transactions/CardTransactionFilter";
import { fetchCardTransactions } from "../../actions/transactionActions";
import { fetchBookings } from "../../actions/bookingActions";
import AlertMessage from "../../components/common/AlertMessage";
import "../../styles/react-components/table.scss";
import "../../styles/blocks/_filter-form.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Loader from "../../components/common/Loader";
import moment from "moment";
import Pagination from "../../components/common/Pagination";
import CardTransactionsExport from "../../components/transactions/CardTransactionsExport";
import CardTransactionRow from "../../components/transactions/CardTransactionRow";
import CardBookingRow from "../../components/booking/CardBookingRow";
import auth from "../../auth/authenticate";

import { getTransactionsCardFilter, setTransactionsCardFilter } from "../../cachedFilter";
import { fetchContracts } from "../../actions/contractActions";
import { ServicePath } from "../../const/routes";
import { Fuel } from "../../const/contractType";
import CardTransactionsPrintReceipts from './CardTransactionsPrintReceipts';

class CardTransactionContainer extends React.Component {
  static TRANSACTIONS_PER_PAGE = 10;
  static BOOKINGS_PER_PAGE = 10;

  constructor(props) {
    super(props);

    this.toggleFilter = this.toggleFilter.bind(this);
    this.onFilterSubmit = this.onFilterSubmit.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.renderTransactionRow = this.renderTransactionRow.bind(this);
    this.renderBookingRow = this.renderBookingRow.bind(this);
    this.scrollToRef = React.createRef();

    const cachedFilter = getTransactionsCardFilter();

    this.initialState = {
      filterOpen: true,
      filter: {
        startDate: moment().subtract(6, "months").startOf("month"),
        endDate: moment(),
        card: cachedFilter.card,
        selectedPeriodPreset: cachedFilter.selectedPeriodPreset,
        selectedPeriodPresetName: cachedFilter.selectedPeriodPresetName,
        invoiceNumber: cachedFilter.invoiceNumber,
        cardName: cachedFilter.cardName,
        contractNumber: cachedFilter.contractNumber,
        ...props.filter,
      },
      selectedPage: 1,
      selectedPageSize: auth.getPageSize() || CardTransactionContainer.TRANSACTIONS_PER_PAGE,
      openedTransactions: []
    };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.fetchTransactions(this.state.selectedPage, this.state.selectedPageSize, this.state.filter);
    this.fetchBookings(this.state.filter);
    this.props.fetchContracts();
  }

  toggleFilter = () => {
    this.setState(prevState => ({ filterOpen: !prevState.filterOpen }));
  };

  onFilterSubmit(filter) {
    let page = 1;
    this.setState({ selectedPage: page, filter: filter });
    this.fetchTransactions(page, this.state.selectedPageSize, filter);
    this.fetchBookings(filter);
    setTransactionsCardFilter(filter);
  }

  fetchBookings(filter) {
    this.props.fetchBookings(
      filter.startDate?.format("YYYY-MM-DD"),
      filter.endDate?.format("YYYY-MM-DD"),
      filter.card
    );
  }

  fetchTransactions(page, pageSize, filter) {
    this.props.fetchCardTransactions(
      page,
      pageSize,
      filter.startDate?.format("YYYY-MM-DD"),
      filter.endDate?.format("YYYY-MM-DD"),
      filter.card,
      filter.invoiceNumber,
      filter.cardName,
      filter.contractNumber
    );
  }

  onPageChange({ page, pageSize }) {
    this.setState(
      { selectedPage: page, selectedPageSize: pageSize },
      this.fetchTransactions.bind(this, page, pageSize, this.state.filter)
    );
  }

  renderTransactionsHeader() {
    const showFilter = this.state.filterOpen;

    return (
      <>
        <GridCell span={12}>
          <CardTransactionsExport filter={this.state.filter} />
          <CardTransactionsPrintReceipts filter={this.state.filter} />
          <Button
            className={classnames(
              "mdc-button--secondary mdc-button--text uppercase other filter-form__link",
              { active: showFilter }
            )}
            onClick={this.toggleFilter}
            disabled={false}
            icon={{
              icon: "angle-down",
              strategy: "className",
              basename: "icon",
              prefix: "icon-",
            }}
          >
            <FormattedMessage id="Transactions.Filter" />
          </Button>

        </GridCell>
        <GridCell span={12}>
          <CardTransactionFilter
            open={showFilter}
            toggle={this.toggleFilter}
            onSubmit={this.onFilterSubmit}
            filter={this.state.filter}
          />
        </GridCell>
        <GridCell span={12}>
          {this.renderTransactionMessages()}
        </GridCell>
      </>
    );
  }

  renderTransactionMessages = () => {
    const transactionsNotFound = !this.props.transactionsFetching && this.props.transactionsFetched &&
      (!this.props.totalTransactions || this.props.totalTransactions < 1);

    const bookingsFound = this.props.bookings.length > 0;

    return (
      <>
        {transactionsNotFound &&
          <AlertMessage
            type={AlertMessage.TYPE_NOTICE}
            title={<FormattedMessage id="Transactions.NotFound" />}
          />
        }

        {!bookingsFound &&
          <AlertMessage
            type={AlertMessage.TYPE_NOTICE}
            title={<FormattedMessage id="Bookings.NotFound" />}
          />
        }
      </>
    )
  };


  renderBookingsList() {
    const bookings = this.props.bookings || [];
    return (
      <div>
        <GridCell span={12} className="mdc-typography mdc-typography--headline4 mdc-typography--bold mb-25">
          <FormattedMessage id="Transactions.Subtitle.Bookings" />
        </GridCell>
        <div className="table--scrollable-desktop">
          <table className="table table--primary">
            <thead className="hidden-mobile">
              <tr className="disabled light">
                <th className="w-12">
                  <FormattedMessage id="Transactions.Table.DateTime" />
                </th>
                <th className="w-21">
                  <FormattedMessage id="Transactions.Table.Card" />
                </th>
                <th>
                  <FormattedMessage id="Transactions.Table.CardName" />
                </th>
                <th>
                  <FormattedMessage id="Transactions.Table.ServicePoint" />
                </th>
                <th className="text-right pull-right">
                  <FormattedMessage id="Transactions.Table.Sum" />
                </th>
                <th>
                  <FormattedMessage id="Transactions.Table.Receipt" />
                </th>
              </tr>
            </thead>
            <tbody className="table--card-body">
              {bookings.map(this.renderBookingRow)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderTransactionsList() {
    const transactions = this.props.transactions || [];
    return (
      <div>
        <GridCell span={12} className="mdc-typography mdc-typography--headline4 mdc-typography--bold mb-25">
          <FormattedMessage id="Transactions.Subtitle" />
        </GridCell>
        <div className="table--scrollable-desktop table--transactions">
          <table className="table table--primary">
            <thead className="hidden-mobile">
              <tr className="table-heading">
                <th className="w-12">
                  <FormattedMessage id="Transactions.Table.DateTime" />
                </th>
                <th className="w-21">
                  <FormattedMessage id="Transactions.Table.Card" />
                </th>
                <th>
                  <FormattedMessage id="Transactions.Table.CardName" />
                </th>
                <th>
                  <FormattedMessage id="Transactions.Table.ServicePoint" />
                </th>
                <th className="text-right pull-right">
                  <FormattedMessage id="Transactions.Table.Sum" />
                </th>
                <th>
                  <FormattedMessage id="Transactions.Table.DigitalStamps" />
                </th>
                <th>
                  <FormattedMessage id="Transactions.Table.Receipt" />
                </th>
                <th>
                </th>
              </tr>
            </thead>
            <tbody className="table--card-body">
              {transactions.map(this.renderTransactionRow)}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderBookingRow(booking, index) {
    return (
      <CardBookingRow key={"booking" + index} booking={booking} />
    );
  }

  renderTransactionRow(transaction, index) {
    return (
      <CardTransactionRow key={"transaction" + index} transaction={transaction} />
    );
  }

  renderNoCardsAlert() {
    return <AlertMessage
      className="mt-40"
      type={AlertMessage.TYPE_NOTICE}
      title={
        <FormattedMessage
          id="Invoices.PaymentCard.NoContract"
          values={{
            link:
              <Link to={ServicePath}>
                <FormattedMessage id="Invoices.PaymentCard.NoContract.LinkText" />
              </Link>
          }}
        />
      }
    />
  }

  render() {
    const hasTransactions = this.props.totalTransactions > 0;
    const hasBookings = this.props.bookings.length > 0;
    const hasFuelContracts = this.props.contracts.find(c => c.type === Fuel) !== undefined;

    if (this.props.contractsFetching) {
      return <Loader type={Loader.TYPE_CENTER} />;
    }

    if (!hasFuelContracts) {
      return this.renderNoCardsAlert();
    }

    return (
      <Grid className="mdc-layout-grid--base mb-60">
        {this.renderTransactionsHeader()}
        <GridCell span={12} ref={this.scrollToRef}>
          {this.props.bookingsFetching
            ? <Loader type={Loader.TYPE_CENTER} />
            : (hasBookings && this.renderBookingsList(this.props.bookings))
          }
        </GridCell>
        <GridCell span={12} ref={this.scrollToRef}>
          {this.props.transactionsFetching ?
            <Loader type={Loader.TYPE_CENTER} />
            : (hasTransactions && this.renderTransactionsList(this.props.transactions))
          }
        </GridCell>
        <GridCell span={12}>
          <Pagination
            total={this.props.totalTransactions || 0}
            selectedPage={this.state.selectedPage}
            onChange={this.onPageChange}
            scrollToRef={this.scrollToRef}
          />
        </GridCell>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionsFetching: state.cardTransactions.fetching,
    transactionsFetched: state.cardTransactions.fetched,
    transactions: state.cardTransactions.data,
    totalTransactions: state.cardTransactions.total,

    bookings: state.bookings.data,
    bookingsFetching: state.bookings.fetching,
    bookingsFetched: state.bookings.fetched,

    contractsFetching: state.contracts.fetching,
    contractsFetched: state.contracts.fetched,
    contracts: state.contracts.data,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCardTransactions,
      fetchBookings,
      fetchContracts,
    },
    dispatch
  );
}

export default injectIntl(
  connect(mapStateToProps, matchDispatchToProps)(CardTransactionContainer)
);
