import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import { GridInner, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";

import NordpoolTable from "./NordpoolTable";
import NordpoolGraph from "./NordpoolGraph";
import { formatUnit } from "../../helpers/energyHelpers";

class NordpoolDetails extends React.Component {
    render() {
        const { title, isLoading, details, className } = this.props;

        const datapoints = Object.keys(details).length > 0 ? details.data : [];
        const priceFormat = Object.keys(details).length > 0 ? details.priceFormat : "";

        const hasDataPoints = datapoints.length > 0;

        return (
            <>
                <GridInner className={classnames("energy-details", className)} >
                    {!!title &&
                        <GridCell span={12} align="middle" className="d-flex mb-mobile-30">

                            <Typography use="headline4" className="mdc-typography mdc-theme--primary energy-details__title mb-mobile-15" >
                                {title}
                            </Typography>
                        </GridCell>}

                    {!isLoading && !hasDataPoints &&
                        <GridCell span={12}>
                            <FormattedMessage id="Nordpool.Graph.NoData" />
                        </GridCell>}

                    {!isLoading && hasDataPoints &&
                        <>
                            <GridCell span={12}>
                                <NordpoolGraph
                                    data={datapoints}
                                    priceUnit={formatUnit(priceFormat)} />
                            </GridCell>

                            <GridCell span={12}>
                                <NordpoolTable
                                    {...details}
                                    isLoading={isLoading}
                                />
                            </GridCell>
                        </>}
                </GridInner>
            </>
        );
    }
}

NordpoolDetails.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    details: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({
                price: PropTypes.number,
                timestamp: PropTypes.string,
            })
        ),
        priceFormat: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    className: PropTypes.string,
}

export default injectIntl(NordpoolDetails);
