import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber, injectIntl } from "react-intl";

const Currency = ({ fractionalDigits, value, currency, zeroWithoutFractions }, props) => {
    let minFractionalDigits = props.minFractionalDigits || fractionalDigits;
    let maxFractionalDigits = props.maxFractionalDigits || fractionalDigits;

    if (value === 0 && zeroWithoutFractions) {
        minFractionalDigits = 0;
        maxFractionalDigits = 0;
    }

    return (
        <FormattedNumber
            value={value}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
            minimumFractionDigits={minFractionalDigits}
            maximumFractionDigits={maxFractionalDigits}
            {...props} />
    );
}

Currency.defaultProps = {
    fractionalDigits: 2,
    currency: "EUR",
    zeroWithoutFractions: false
};

Currency.propTypes = {
    fractionalDigits: PropTypes.number,
    value: PropTypes.number,
    currency: PropTypes.string,
    zeroWithoutFractions: PropTypes.bool
}

export default injectIntl(Currency);