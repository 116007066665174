import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";
import { fetchCommunityProjectStatistics, fetchCustomerContribution } from "../../actions/communityProjectActions";
import LinkToCommunityPage from "../CommunityProject/LinkToCommunityPage";
import ContributionSection from "./ContributionSection";

class CommunityContribution extends React.Component {
    componentDidMount() {
        if (!this.props.communityProjectStatisticsFetching && !this.props.communityProjectStatisticsFetched && !this.props.communityProjectStatisticsError) {
            this.props.fetchCommunityProjectStatistics();
        }
        if (!this.props.customerContributionFetching && !this.props.customerContributionFetched && !this.props.customerContributionError) {
            this.props.fetchCustomerContribution();
        }
    }

    renderLink() {
        const { customerContribution, customerContributionFetched, showLink } = this.props;

        if (showLink && customerContributionFetched) {
            return (
                <div className="contribution-link-mobile">
                    <LinkToCommunityPage isUserJoined={customerContribution.isActive} />
                </div>
            )
        }

        return null;
    }

    renderCommunityProjectContribution = () => {
        const { communityProjectStatistics, showInfo, titleId } = this.props;

        const membersProps = {
            iconClassName: "icon-members",
            number: communityProjectStatistics.participationCount || '0',
            title: "Community.Title.Members",
            info: showInfo && "Community.Info.Members",
        };

        const treeProps = {
            iconClassName: "icon-trees",
            number: communityProjectStatistics.treesPlanted || '0',
            title: "Community.Title.PlantedTrees",
            info: showInfo && "Community.Info.PlantedTrees",
        };

        const co2Props = {
            iconClassName: "icon-co2",
            number: Math.round(this.props.communityProjectStatistics.reducedEmissions) || '0',
            title: "Community.Title.LessCO2",
            info: showInfo && "Community.Info.LessCo2",
        };

        return (
            <Grid className="mdc-layout-grid--base mb-60">
                <GridCell span={12}>
                    {!this.props.isMobile && this.renderLink()}
                    <Typography
                        use="headline3"
                        tag="h3"
                        className="mdc-typography mdc-theme--primary mb-mobile-15"
                    >
                        <FormattedMessage id={titleId} />
                    </Typography>
                </GridCell>

                <ContributionSection
                    firstProps={membersProps}
                    secondProps={treeProps}
                    thirdProps={co2Props}
                />

                {this.props.isMobile &&
                    <GridCell span={12}>
                        {this.renderLink()}
                    </GridCell>
                }
            </Grid>
        );
    }

    render() {
        return (
            this.renderCommunityProjectContribution()
        );
    }
}

CommunityContribution.defaultProps = {
    showInfo: true,
    titleId: "Community.Alexela"
}

CommunityContribution.propTypes = {
    showInfo: PropTypes.bool,
    showLink: PropTypes.bool,
    titleId: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        communityProjectStatisticsFetching: state.communityProjectStatistics.fetching,
        communityProjectStatisticsFetched: state.communityProjectStatistics.fetched,
        communityProjectStatisticsError: state.communityProjectStatistics.error,
        communityProjectStatistics: state.communityProjectStatistics.data,
        customerContributionFetching: state.customerContribution.fetching,
        customerContributionFetched: state.customerContribution.fetched,
        customerContributionError: state.customerContribution.error,
        customerContribution: state.customerContribution.data,
        isMobile: state.ui.isMobileOrTabletView,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCommunityProjectStatistics,
        fetchCustomerContribution,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(CommunityContribution);
