import "./styles/react-components/layout.scss"; // Must be the first line, or styles might get combined incorrectly.
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-intl-redux";
import { RMWCProvider } from "@rmwc/provider";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import Layout from "./Layout";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import './appInsights';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
import FeatureFlagsProvider from "./featureFlags";

const app = document.getElementById("root");

ReactDOM.render(
	<RMWCProvider
		typography={{
			defaultTag: "div",
			headline1: "h1", headline2: "h2", headline3: "h3", headline4: "h4",
			body1: "p", body2: "p", body3: "p"
		}}
	>
		<FeatureFlagsProvider>
			<Provider store={store}>
				<Router>
					<ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
						<Layout/>
					</ErrorBoundary>
				</Router>
			</Provider>
		</FeatureFlagsProvider>
	</RMWCProvider>,
	app
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
