import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import ContractsList from "../components/Contract/ContractsList";
import { Grid, GridCell } from "@rmwc/grid";
import { fetchContracts } from "../actions/contractActions";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import { ServicePath } from "../const/routes";

import "../styles/react-components/table.scss";

const Contracts = () => {
    const contracts = useSelector(state => state.contracts);
    const dispatch = useDispatch();
    const location = useLocation();

    const hasContracts = contracts.data?.length > 0;

    useEffect(() => {
        if (!contracts.fetching && !contracts.fetched && !contracts.error) {
            dispatch(fetchContracts());
        }
    }, [dispatch, contracts]);

    useEffect(() => {
        if (location.state?.disableCache) {
            dispatch(fetchContracts(false));
        }
    }, [dispatch, location.state?.disableCache]);

    const renderContracts = () => {
        return contracts.data.length > 0 ? (
            <main>
                <ContractsList items={contracts.data} />
            </main>
        ) : null;
    }

    const renderNoItemsMessage = () => {
        return (
            <FormattedMessage
                id="ContractsPage.Contracts.NotFound"
                values={{
                    location:
                        <Link to={ServicePath} >
                            <FormattedMessage id="ContractsPage.Contracts.NotFound.Link.Here" />
                        </Link>
                }}
            />
        );
    }

    return (
        <Grid className="mdc-layout-grid--base">
            <GridCell span={12}>
                {contracts.fetching &&
                    <Loader type={Loader.TYPE_CENTER} />}

                {!contracts.fetching && !hasContracts &&
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={renderNoItemsMessage()}
                    />}

                {renderContracts()}
            </GridCell>
        </Grid>
    );
}

export default Contracts;