import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class Loader extends React.Component {
    static TYPE_TEXT_FIELD = "mdc-text-field__icon";
    static TYPE_SELECT_FIELD = "mdc-select-field__icon";
    static TYPE_BUTTON = "btn-loading";
    static TYPE_CENTER = "lds-roller--center";
    static TYPE_COVER = "lds-roller--center lds-roller--cover";
    static TYPE_INLINE = "lds-roller--inline";

    render() {
        return (
            <>
                {this.props.type === Loader.TYPE_COVER &&
                    <div className="loader--background" ></div>}
                <div className={
                    classnames(
                        "lds-roller",
                        this.props.type,
                        `lds-roller--${this.props.size}`,
                        { "lds-roller--white": this.props.isWhite })}
                    style={{ zIndex: 100 }}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </>
        );
    }
}

Loader.defaultProps = { size: "large" };

Loader.propTypes = {
    type: PropTypes.string,
    isWhite: PropTypes.bool,
    size: PropTypes.oneOf(["xs","small", "medium", "large"]),
    inline: PropTypes.bool
};

export default (Loader);
