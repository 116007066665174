import React from "react";
import "../styles/blocks/_contract.scss";
import "../styles/components/_information.scss";
import {useParams} from "react-router-dom";
import ProductChange from "../components/Contract/ProductChange";
import {Electricity} from "../const/contractType";

const ContractProductChange = ({contractType = Electricity}) => {
    const {id} = useParams();

    return (
        <ProductChange contractType={contractType} isRenewal={false} contractId={id} />
    )
}

export default ContractProductChange;
