import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../components/common/Loader";
import SelectField from "../components/common/SelectField";
import CheckboxField from "../components/common/CheckboxField";

import "../styles/react-components/communityProject.scss";

import {
    basketballCommunityProjectRegister,
    fetchBasketballClubs,
    fetchCustomerBasketballProjectContribution
} from "../actions/basketballCommunityProjectActions";
import {BasketballContribution, BasketballUserContribution} from "../components/CommunityProject";

const contributionOptions = [
    {
        label: <FormattedMessage id="Community.ContributionType.CentsPerLitre" values={{ cents: 0 }} />,
        value: 0
    },
    {
        label: <FormattedMessage id="Community.ContributionType.CentsPerLitre" values={{cents: 1}} />,
        value: 1
    },
    {
        label: <FormattedMessage id="Community.ContributionType.CentsPerLitre" values={{cents: 2}} />,
        value: 2
    },
    {
        label: <FormattedMessage id="Community.ContributionType.CentsPerLitre" values={{cents: 3}} />,
        value: 3
    },
    {
        label: <FormattedMessage id="Community.ContributionType.CentsPerLitre" values={{cents: 4}} />,
        value: 4
    },
    {
        label: <FormattedMessage id="Community.ContributionType.CentsPerLitre" values={{cents: 5}} />,
        value: 5
    }
];

const BasketballCommunityProject = () => {
    const [selectedBasketballClub, setSelectedBasketballClub] = useState();
    const [selectedContributionAmount, setSelectedContributionAmount] = useState();
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);
    const [basketballProjectClubOptions, setBasketballProjectClubOptions] = useState([]);

    const dispatch = useDispatch();
    const intl = useIntl();

    const {
        data: basketballProjectClubs,
        fetching: basketballProjectClubsFetching,
        fetched: basketballProjectClubsFetched
    } = useSelector(state => state.basketballProjectClubs);

    const {
        data: basketballProjectCustomerContribution,
        fetching: basketballProjectCustomerContributionFetching,
        fetched: basketballProjectCustomerContributionFetched
    } = useSelector(state => state.basketballProjectCustomerContribution);

    const basketballCommunityProjectRegisterFetching = useSelector(state => state.basketballCommunityProjectRegister.fetching)

    useEffect(() => {
        dispatch(fetchBasketballClubs());
        dispatch(fetchCustomerBasketballProjectContribution());
    }, [dispatch]);

    useEffect(() => {
        if (!basketballProjectClubsFetching && basketballProjectClubsFetched) {
            setBasketballProjectClubOptions(basketballProjectClubs?.map((item) => ({label: item.name, value: item.id})));
        }
    }, [basketballProjectClubsFetching, basketballProjectClubsFetched, basketballProjectClubs]);

    const onBasketballClubChanged = (_afterChange, _field, e) => {
        setSelectedBasketballClub(e.target.value);
    }

    const onContributionAmountChanged = (_afterChange, _field, e) => {
        setSelectedContributionAmount(e.target.value);
    }

    const onTermsCheckBoxChanged = (e) => {
        setIsTermsChecked(e.target.checked);
    }

    const registerUser = () => {
        let data = {
            contributionCentsPerLitre: selectedContributionAmount,
            basketballClubId: selectedBasketballClub
        }

        dispatch(basketballCommunityProjectRegister(data))
            .then(() => {
                dispatch(fetchCustomerBasketballProjectContribution());
                setIsNewUser(true);
            });
    }

    const isActiveUser = () => {
        if (basketballProjectCustomerContributionFetched) {
            return basketballProjectCustomerContribution.isActive;
        }
        return false;
    }

    const renderRegisterPage = () => {
        return (
            <Grid>
                <GridCell span={12}>
                    <Typography
                        use="headline2"
                        className="mdc-typography mdc-theme--primary contribution-shifted"
                    >
                        <FormattedMessage id="Community.Basketball.Title" />
                    </Typography>

                    <Typography
                        use="headline4 mt-20"
                        className="mdc-typography mdc-theme--primary contribution-first-step"
                    >
                        <FormattedMessage id="Community.Basketball.AlreadyCustomer" />
                    </Typography>

                    <Typography
                        use="headline4 mt-20"
                        className="mdc-typography mdc-theme--primary contribution-second-step"
                    >
                        <FormattedMessage id="Community.Basketball.Join" />
                    </Typography>
                </GridCell>

                <GridCell span={12} className="contribution-shifted">
                    <Typography use="body1" className="mdc-typography">
                        <FormattedMessage id="Community.Basketball.DiscountInfo" />
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        <FormattedMessage id="Community.Basketball.DonateInfo" />
                    </Typography>
                    <GridInner>
                        <GridCell span={4} className="mt-15">
                            <SelectField
                                field="contributions"
                                label="Community.Basketball.Select.BasketballClub"
                                value={selectedBasketballClub}
                                onChange={onBasketballClubChanged}
                                enhanced
                                menu
                                outlined
                                options={basketballProjectClubOptions} />
                        </GridCell>
                    </GridInner>
                    <GridInner>
                        <GridCell span={3} className="mt-15">
                            <SelectField
                                field="contributions"
                                label="Community.Basketball.Select.ContributionAmount"
                                value={selectedContributionAmount}
                                onChange={onContributionAmountChanged}
                                enhanced
                                menu
                                outlined
                                options={contributionOptions} />
                        </GridCell>
                    </GridInner>
                </GridCell>
                <GridCell span={12} className="mt-20 contribution-shifted">
                    <CheckboxField
                        nativeControlId="acceptTos"
                        field="acceptTos"
                        checked={isTermsChecked}
                        indeterminate={false}
                        onChange={onTermsCheckBoxChanged}
                        label={
                            <label htmlFor="acceptTos">
                                <FormattedMessage
                                    id="Community.TOS"
                                    values={{
                                        terms: (
                                            <a href={intl.formatMessage({ id: "Community.BasketballProject.AlexelaUrl" })}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <FormattedMessage id="Community.Basketball.AlexelaTermsLink" />
                                            </a>
                                        )
                                    }}
                                />
                            </label>
                        }
                    />
                </GridCell>
                <GridCell span={12} className="contribution-shifted">
                    <Button
                        className="mt-20"
                        unelevated
                        onClick={registerUser}
                        disabled={!isTermsChecked || !selectedContributionAmount}
                    >
                        { basketballCommunityProjectRegisterFetching 
                            ? <Loader type={Loader.TYPE_BUTTON} />
                            : <FormattedMessage id="Community.Button.Confirm" />
                        }
                    </Button>
                </GridCell>
            </Grid>
        );
    }

    const renderBanner = () => {
        return (
            <Grid>
                <GridCell desktop={6} tablet={4}>
                    <Typography use="body1" className="mdc-typography">
                        <FormattedMessage id="Community.Basketball.Banner.Description" />
                    </Typography>
                </GridCell>
                <GridCell desktop={6} tablet={4}>
                    <div className="contribution-banner-2 contribution-banner-2--basketball"></div>
                </GridCell>
            </Grid>
        );
    }

    const renderContribution = () => {
        return (
            <Grid>
                <GridCell span={12}>
                    <Typography
                        use="headline2"
                        className="mdc-typography mdc-theme--primary"
                    >
                        <FormattedMessage id="Community.Basketball.MyPermanentDonation" />
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        {basketballProjectCustomerContribution.contributionCentsPerLitre > 0
                            ? <FormattedMessage id="Community.Basketball.Donate"
                                                values={{cents: basketballProjectCustomerContribution.contributionCentsPerLitre}}/>
                            : <FormattedMessage id="Community.Basketball.ZeroDonate"/>}
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        <FormattedMessage
                            id="Community.Basketball.AlexelaTermsIsLocated"
                            values={{
                                location: <a href={intl.formatMessage({id: "Community.Project.AlexelaUrl"})}
                                             target="_blank" rel="noopener noreferrer">
                                    <FormattedMessage id="Community.Here"/>
                                </a>
                            }}
                        />
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        <FormattedMessage id="Community.Basketball.Unsubscribe" />
                    </Typography>
                </GridCell>
            </Grid>
        )
    }

    const renderView = () => {
        return (
            <>
                {renderBanner()}
                {isActiveUser() && <BasketballUserContribution />}
                <BasketballContribution isNewUser={isNewUser} />
                {isActiveUser() ? renderContribution() : renderRegisterPage()}
            </>
        );
    }

    return (
        <>
            { basketballProjectCustomerContributionFetching || basketballProjectClubsFetching
                ? <Loader type={Loader.TYPE_CENTER}/> 
                : renderView()
            }
        </>
    );
}

export default BasketballCommunityProject;
