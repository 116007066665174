import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import "../styles/react-components/table.scss";
import BaseFormComponent from "../components/common/BaseFormComponent";
import Pagination from "../components/common/Pagination";
import { fetchPayments } from "../actions/paymentActions";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import PaymentFilter from "../components/Payment/PaymentFilter";
import exportToExcel from "../components/common/ExcelExport";
import FilterButton from "../components/common/FilterButton";
import auth from "../auth/authenticate";
import TableList from "../components/common/TableList";

import { getPaymentsFilter, setPaymentsFilter } from "../cachedFilter";

class Payments extends BaseFormComponent {
    static TableHeaders = [
        {
            label: "Payment.Title.Date",
            colspan: 1
        },
        {
            label: "Payment.Title.PaymentType",
            colspan: 1
        },
        {
            label: "Payment.Title.Amount",
            colspan: 1
        },
        {
            label: "Payment.Title.Bank",
            colspan: 1
        },
        {
            label: "Payment.Title.ContractNumber",
            colspan: 1
        }
    ];

    constructor(props) {
        super(props);

        const cachedFilter = getPaymentsFilter();

        this.state = {
            selectedPage: 1,
            showFilter: true,
            filter: {
                startDate: moment().subtract(4, "months").startOf("month"),
                endDate: moment(),
                bankName: cachedFilter.bankName,
                paymentTypeXc: cachedFilter.paymentTypeXc,
                contractNumber: cachedFilter.contractNumber,
                startAmount: cachedFilter.startAmount
            },
        };
        this.onPageChange = this.onPageChange.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
    }

    componentDidMount() {
        this.fetchList()
    }

    onPageChange({ page }) {
        this.setState(
          { selectedPage: page },
          this.fetchList.bind(this)
        );
    }

    onFilterSubmit(filter) {
        this.setState({ selectedPage: 1, filter: filter },
            this.fetchList.bind(this));
        setPaymentsFilter(filter);
    }

    exportToExcel() {
        const payments = this.props.payments.items.map((item) => {
            item.date = moment(item.date).format("DD.MM.YYYY");
            item.paymentType = this.props.intl.formatMessage({ id: `Payment.Type.${item.paymentTypeXc}` })
            return item;
        });
        const columns = [
            {
                field: 'date',
                title: this.props.intl.formatMessage({ id: "Payment.Title.Date" }),
            },
            {
                field: 'paymentType',
                title: this.props.intl.formatMessage({ id: "Payment.Title.PaymentType" }),
            },
            {
                field: 'amount',
                title: this.props.intl.formatMessage({ id: "Payment.Title.Amount" }),
            },
            {
                field: 'bankName',
                title: this.props.intl.formatMessage({ id: "Payment.Title.Bank" }),
            },
            {
                field: 'contractNumber',
                title: this.props.intl.formatMessage({ id: "Payment.Title.ContractNumber" }),
            },
        ];

        exportToExcel(columns, payments, "payments.xlsx")
    }

    fetchList() {
        this.props.fetchPayments({
            pageNr: this.state.selectedPage,
            limit: auth.getPageSize(),
            ...this.state.filter,
            startDate: this.state.filter.startDate.format("YYYY-MM-DD"),
            endDate: this.state.filter.endDate.format("YYYY-MM-DD")
        });
    }

    renderTableRowJson(item) {
        const date = moment(item.date, "YYYY-MM-DD");

        return {
            content: [
                {
                    colspan: 1,
                    content: date.format("DD.MM.YYYY")
                },
                {
                    colspan: 1,
                    content: this.props.intl.formatMessage({ id: `Payment.Type.${item.paymentTypeXc}` }),
                },
                {
                    colspan: 1,
                    content: `${item.amount > 0 ? this.props.intl.formatNumber(item.amount, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }) : 0} €`
                },
                {
                    colspan: 1,
                    content: item.bankName
                },
                {
                    colspan: 1,
                    content: item.contractNumber
                }
            ]
        };
    }

    render() {
        const isLoading = this.props.fetching;
        const hasItems = this.props.payments.items && this.props.payments.items.length > 0;

        const rows = this.props.payments.items || [];
        const tableRowsJson = rows.map(item => this.renderTableRowJson(item));
        return (
            <Grid className="mdc-layout-grid--base">
                <GridCell span={12}>
                    <FilterButton
                        className="right mb-mobile-10"
                        active={this.state.showFilter}
                        callback={this.onChangeByField.bind(this, 'showFilter')}
                    />
                    {hasItems &&
                        <Button className="mdc-button--outlined right mr-mobile-up-10 hidden-mobile" onClick={this.exportToExcel}>
                            <i className="icon-download" />
                            <FormattedMessage id="Cards.Export"/>
                        </Button>
                    }
                </GridCell>
                <GridCell span={12}>
                    <PaymentFilter
                        open={this.state.showFilter}
                        onSubmit={this.onFilterSubmit}
                        filter={this.state.filter}
                    />
                </GridCell>
                <GridCell span={12}>
                    {isLoading &&
                        <Loader type={Loader.TYPE_CENTER} />}

                    {!isLoading && !hasItems &&
                        <AlertMessage
                            className="mb-30"
                            type={AlertMessage.TYPE_NOTICE}
                            title={<FormattedMessage id="Payment.NotFound"/>}
                        />}

                    {rows.length > 0 &&
                        <>
                            <TableList
                                headers={Payments.TableHeaders}
                                itemContent={tableRowsJson}
                            />
                             <Pagination
                                total={this.props.payments.total || 0}
                                selectedPage={this.state.selectedPage}
                                onChange={this.onPageChange}
                            />
                        </>
                    }
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        payments: state.payments.data,
        fetching: state.payments.fetching,
        fetched: state.payments.fetched,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchPayments }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(Payments)
);
