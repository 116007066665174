import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl"
import { useLocation } from "react-router-dom";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { getDigitalStampsSummary } from "../actions/digitalStampsActions";
import { fetchContracts } from "../actions/contractActions";
import { fetchPaymentCardStatus } from "../actions/cardActions";
import { fetchRecentElectricityInvoices } from "../actions/invoiceActions";
import AlertMessage from "../components/common/AlertMessage";
import Loader from "../components/common/Loader";
import {
    DigitalStampsBonus,
    DigitalStampsConverter,
    DigitalStampsHistory,
    DigitalStampsLoyaltyInfo
} from "../components/DigitalStamps";
import { ElectricityNew, GasNew } from "../const/contractType";
import { Active } from "../const/ContractStatus";
import { CompletedFormStatus } from "../const/cardApplication";
import { useScrollToRef } from "../helpers/hooks";

import "../styles/components/_digital-stamps.scss";

const DigitalStamps = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const location = useLocation();
    const scrollToRef = useScrollToRef(false);
    const scollRef = useRef(null);

    const stampsSummary = useSelector(state => state.digitalStampsSummary);
    const contracts = useSelector(state => state.contracts);
    const postStamps = useSelector(state => state.convertStamps);
    const postTransferToCard = useSelector(state => state.postTransferToCard);
    const paymentCardStatus = useSelector(state => state.paymentCardStatus);
    const ui = useSelector(state => state.ui);

    const newContractStatus = location?.state?.contractStatus;

    const [hasElectricityContract, setHasElectricityContract] = useState(false);
    const [hasGasContract, setHasGasContract] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isConversionError, setIsConversionError] = useState(false);

    const fetchData = () => dispatch => {
        dispatch(getDigitalStampsSummary());
        dispatch(fetchPaymentCardStatus());
        dispatch(fetchRecentElectricityInvoices());
        dispatch(fetchContracts());
    }

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch]);

    useEffect(() => {
        setHasElectricityContract(contracts.data?.find(c => c.status === Active && !c.isVirtualAccount && c.type === ElectricityNew));
        setHasGasContract(contracts.data?.find(c => c.status === Active && !c.isVirtualAccount && c.type === GasNew));
    }, [contracts.data]);

    useEffect(() => {
        setIsLoading(stampsSummary.fetching && !stampsSummary.fetched);
    }, [stampsSummary.fetching, stampsSummary.fetched]);

    useEffect(() => {
        setIsError(stampsSummary.error || contracts.error || paymentCardStatus.error);
    }, [stampsSummary.error, contracts.error, paymentCardStatus.error]);

    useEffect(() => {
        setIsConversionError(postStamps.error || postTransferToCard.error);
        scrollToRef(scollRef);
    }, [postStamps.error, postTransferToCard.error, scrollToRef]);

    useEffect(() => {
        if ((postStamps.fetched && !postStamps.fetching && !postStamps.error) ||
            (postTransferToCard.fetched && !postTransferToCard.fetching && !postTransferToCard.error)) {
            dispatch(getDigitalStampsSummary());
        }
    }, [dispatch, postStamps, postTransferToCard]);

    useEffect(() => {
        if (newContractStatus) {
            dispatch(fetchContracts(false));
        }
    }, [dispatch, newContractStatus]);

    const renderContent = () => {
        if (isError) return null;

        return (
            <>
                <GridCell span={12} className={ui.isMobileOrTabletView ? "" : "align-right"}>
                    <div className="link-card--content-url uppercase">
                        <a href={intl.formatMessage({ id: "DigitalStamps.Link.LoyaltyProgramUrl" })} target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id={ui.isMobileOrTabletView
                                ? "DigitalStamps.Link.LoyaltyProgramShort"
                                : "DigitalStamps.Link.LoyaltyProgram"}
                            />
                        </a>
                        <i className="icon icon-arrow-right" />
                    </div>
                </GridCell>
                <GridCell span={12}>
                    <DigitalStampsBonus />
                </GridCell>

                <GridCell span={12}>
                    <GridInner>
                        <GridCell span={6} className="pl-15">
                            <DigitalStampsLoyaltyInfo />
                        </GridCell>
                        <GridCell span={6} className="digital-stamp-advert">
                            <FormattedMessage id="DigitalStamps.Notice.Advert" />
                        </GridCell>
                    </GridInner>
                </GridCell>

                {hasElectricityContract &&
                    <GridCell span={12}>
                        <AlertMessage
                            type={AlertMessage.TYPE_DONE}
                            title={intl.formatMessage({ id: "DigitalStamps.Bonus.Electricity" })}
                            isSmall
                        />
                    </GridCell>
                }

                {hasGasContract &&
                    <GridCell span={12} className="pt-15">
                        <AlertMessage
                            type={AlertMessage.TYPE_DONE}
                            title={intl.formatMessage({ id: "DigitalStamps.Bonus.Gas" })}
                            isSmall
                        />
                    </GridCell>
                }

                <GridCell span={12}>
                    <DigitalStampsConverter />
                </GridCell>

                <GridCell span={12}>
                    <DigitalStampsHistory />
                </GridCell>
            </>
        );
    }

    const renderPostSigningMessage = () => {
        let messageId = "";
        let messageType = AlertMessage.TYPE_NOTICE;

        switch (newContractStatus) {
            case CompletedFormStatus.ACCEPTED:
                messageId = "DigitalStamps.Alert.NewCardAccepted";
                messageType = AlertMessage.TYPE_DONE;
                break;
            case CompletedFormStatus.REJECTED:
                messageId = "DigitalStamps.Alert.NewCardRejected";
                break;
            case CompletedFormStatus.SENT_TO_REVIEW:
                messageId = "DigitalStamps.Alert.NewCardReview";
                break;
            case CompletedFormStatus.TECHNICAL_ERROR:
            default:
                messageId = "DigitalStamps.Alert.NewCardTechnicalError";
                messageType = AlertMessage.TYPE_ALERT;
                break;
        }

        window.history.replaceState({}, "");

        return <AlertMessage isSmall={true} type={messageType} title={intl.formatMessage({ id: messageId })} />
    }

    const renderPostConvertSuccessMessage = () => {
        let messageId = "";

        if (postStamps.fetched) {
            messageId = "DigitalStamps.Alert.ConversionSuccess";
        }

        if (!messageId) return null;

        scrollToRef(scollRef);

        return (
            <AlertMessage
                isSmall={true}
                type={AlertMessage.TYPE_DONE}
                title={intl.formatMessage({ id: messageId })}
            />
        );
    }

    const renderErrorMessage = () => {
        if (isError) {
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_ALERT}
                    title={<FormattedMessage id="DigitalStamps.Error.Summary" />}
                />
            )
        }

        return null;
    }

    const renderConversionErrorMessage = () => {
        if (!isConversionError) return null;

        return <AlertMessage
            isSmall
            type={AlertMessage.TYPE_ALERT}
            title={intl.formatMessage({ id: "DigitalStamps.Alert.ConvertStampsError" })}
        />
    }

    return (
        <>
            {isLoading && <Loader type={Loader.TYPE_CENTER} />}
            {!isLoading &&
                <Grid className="digital-stamps-grid">
                    <GridCell span={12} ref={scollRef}>
                        {renderErrorMessage()}
                        {renderConversionErrorMessage()}
                        {postStamps.fetched && renderPostConvertSuccessMessage()}
                        {newContractStatus && renderPostSigningMessage()}
                    </GridCell>

                    {renderContent()}
                </Grid>
            }
        </>
    );
}

export default DigitalStamps;