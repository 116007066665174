import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import BarAndLineChart from "../common/BarAndLineChart";
import { EnergyType } from "../../const/EnergyType";

class ConsumptionGraph extends React.Component {
    get colors() {
        return {
            consumption: "#58BBEF", // light blue
            unitPrice: "#EC008C", // pink
            price: "#172057", // dark blue
        }
    }

    getPrecisionForPeriodType(type) {
        switch (type) {
            case "year": return "month";
            case "month": return "day"
            default: return "hour";
        }
    }

    render() {
        const { data, isTotalConsumption, amountUnit, priceUnit, pricePerUnitUnit, periodType, type } = this.props;


        const series = [
            {
                label: this.props.intl.formatMessage({ id: "ConsumptionGraph.Labels.Price" }),
                points: data.map(x => ({ x: x.timestamp, y: x.priceWithVat })),
                unit: priceUnit,
                alignToAxis: "right",
                type: "line",
                color: this.colors.price,
                hidden: true,
            },
            {
                label: this.props.intl.formatMessage({ id: "ConsumptionGraph.Labels.Consumption" }),
                points: data.map(x => ({ x: x.timestamp, y: x.amount })),
                unit: amountUnit,
                alignToAxis: "left",
                type: "bar",
                color: this.colors.consumption,
            },
        ];

        // Only show unit price for specific locations
        if (!isTotalConsumption) {
            const unitPriceLine = {
                label: this.props.intl.formatMessage({ id: "ConsumptionGraph.Labels.UnitPrice" }),
                points: data.map(x => ({ x: x.timestamp, y: x.pricePerUnitWithVat })),
                unit: pricePerUnitUnit,
                alignToAxis: "right",
                type: "line",
                color: this.colors.unitPrice,
            };
            series.splice(0, 0, unitPriceLine);
        }

        const axes = [
            { position: "bottom", type: "time" },
            { position: "left", type: "linear", unit: amountUnit },
            { position: "right", type: "linear", unit: priceUnit }
        ];

        return (
            <div className="energy-graph">
                <BarAndLineChart
                    series={series}
                    axes={axes}
                    yTickCount={4}
                    yUnitsAboveGraph={true}
                    precision={this.getPrecisionForPeriodType(periodType)}
                    precisionOptions={{
                        day: type === EnergyType.Gas
                            ? {
                                tooltipLabel: "dddd D. MMMM kk:mm",
                                xAxisLabel: "dd DD.MM 07:00"
                            }
                            : null,
                        month: {
                            xAxisLabel: "DD.MM"
                        }
                    }} />
            </div>
        );
    }
}

ConsumptionGraph.defaultProps = {
    data: [],
}

ConsumptionGraph.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.number,
            pricePerUnitWithVat: PropTypes.number,
            priceWithVat: PropTypes.number,
            timestamp: PropTypes.string,
        })
    ),
    amountUnit: PropTypes.string,
    pricePerUnitUnit: PropTypes.string,
    priceUnit: PropTypes.string,
    isTotalConsumption: PropTypes.bool,
    periodType: PropTypes.string,
    type: PropTypes.oneOf(Object.values(EnergyType)),
}

function mapStateToProps(state) {
    return {};
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(ConsumptionGraph));
