import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl } from "react-intl";

import { setBonuscardToPrimary } from "../../actions/cardActions";

import Loader from "../common/Loader";
import BaseFormComponent from "../common/BaseFormComponent";

class CardToPrimary extends  BaseFormComponent {

    constructor(props) {
        super(props);

        this.state = {

        };

        this.submitSetPrimary = this.submitSetPrimary.bind(this);
    }

    submitSetPrimary() {
        this.props.setBonuscardToPrimary(this.props.selectedCardNr);
    }

    render() {
        return (
            <Grid className="mdc-layout-grid--base">
                <GridCell span={12}>
                    <Typography
                        use="headline2"
                        className="mdc-typography mdc-theme--primary information__head upper"
                    >
                        <FormattedMessage
                            id="Overview.SetPrimary.Title"
                            values={{
                                cardNr: this.props.primaryCardNr,
                            }}
                        />
                    </Typography>

                    <Typography use="body3">
                        <FormattedMessage
                                id="Overview.SetPrimary.Text"
                                values={{
                                    currentCard: this.props.primaryCardNr,
                                    selectedCard: this.props.selectedCardNr
                                }}
                            />
                    </Typography>

                    <Button
                        disabled={false}
                        unelevated
                        className="mdc-button--primary left mt-40"
                        onClick={this.submitSetPrimary}
                    >
                        {this.props.bonuscardToPrimaryFetching ?
                            <Loader type={Loader.TYPE_BUTTON}/>
                            :
                        <FormattedMessage id="Overview.SetPrimary.Btn" />}
                    </Button>
                </GridCell>
            </Grid>
        );
    }
    
}

function mapStateToProps(state) {
    return {
        bonuscardToPrimaryFetching: state.setBonuscardToPrimary.fetching,
        bonuscardToPrimaryFetched: state.setBonuscardToPrimary.fetched,
        bonuscardToPrimaryError: state.setBonuscardToPrimary.error,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        { setBonuscardToPrimary }, 
        dispatch
    );
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(CardToPrimary));
