import BusinessApi from './BusinessApi';

export const PUT_UNSUBSCRIBE = 'PUT_UNSUBSCRIBE';
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const CLEAR_SUBSCRIPTION_MESSAGES = 'CLEAR_SUBSCRIPTION_MESSAGES';

export const fetchSubscriptionByCode = (code) => {
    return {
        type: FETCH_SUBSCRIPTION,
        payload: BusinessApi.get(`/api/subscription/getByCode?code=${code}`)
    };
};

export const unsubscribeFromEmails = (contactCrmId) => {
    return {
        type: PUT_UNSUBSCRIBE,
        payload: BusinessApi.post(`/api/subscription/unsubscribe?contactCrmId=${contactCrmId}`)
    };
};

export const clearSubscriptionMessages = () => {
    return {
        type: CLEAR_SUBSCRIPTION_MESSAGES
    };
};
