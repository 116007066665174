import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import SelectAutoCompleteField from "../common/SelectAutoCompleteField";
import {injectIntl} from 'react-intl';

const ServicePointSelect = ({
       field,
       value,
       label,
       placeholder,
       onChange,
       areServicePointsLoading,
       servicePoints,
       intl,
       servicePoint
   }) => {
    return (
        <SelectAutoCompleteField
            placeholder={intl.formatMessage({
                id: placeholder
            })}
            isLoading={areServicePointsLoading}
            value={value === undefined ? servicePoint : value}
            onChange={onChange}
            field={field}
            label={label}
            options={servicePoints}/>
    );
};

ServicePointSelect.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    areServicePointsLoading: PropTypes.bool.isRequired,
    servicePoints: PropTypes.array.isRequired,
    servicePoint: PropTypes.object,
    intl: PropTypes.object.isRequired
};

ServicePointSelect.defaultProps = {
    label: "General.Select.Station",
    placeholder: "General.Select"
};

function mapStateToProps(state) {
    return {
        areServicePointsLoading: state.servicePoints.fetching,
        servicePoints: state.servicePoints.data
    };
}

export default injectIntl(connect(mapStateToProps)(ServicePointSelect));
