import { GET_STATIONS_FOR_CLIENT } from "../../actions/digitalStampsActions";

export default function reducer(state={
    data: [],
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${GET_STATIONS_FOR_CLIENT}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${GET_STATIONS_FOR_CLIENT}_REJECTED`: {
            return {...state, fetching: false, fetched: false, error: action.payload};
        }
        case `${GET_STATIONS_FOR_CLIENT}_FULFILLED`: {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}