import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import BaseFormComponent from '../common/BaseFormComponent';
import TextAreaGroup from "../common/TextAreaGroup";
import { putTranslation } from "../../actions/localeActions";
import { Button } from "@rmwc/button";
import CheckboxField from "../common/CheckboxField";
import { Country } from "../../const/country";

class TranslationItem extends BaseFormComponent {
    static fieldEn = "en";
    static fieldEt = "et";
    static fieldRu = "ru";
    static fieldLv = "lv";
    static fieldKey = "key";

    static fieldToBeDeleted = "toBeDeleted";

    timeout = null;

    constructor(props) {
        super(props);

        this.state = {
            [TranslationItem.fieldEt]: props.item.valueEt || "",
            [TranslationItem.fieldEn]: props.item.valueEn || "",
            [TranslationItem.fieldRu]: props.item.valueRu || "",
            [TranslationItem.fieldLv]: props.item.valueLv || "",
            [TranslationItem.fieldKey]: props.item.keyPart,
            isNew: !props.item.keyPart,
            [TranslationItem.fieldToBeDeleted]: false
        };
        this.onTextAreaChange = this.onTextAreaChange.bind(this);
        this.handlePutDelegate = this.handlePutDelegate.bind(this);
        this.getModel = this.getModel.bind(this);
        this.create = this.create.bind(this);
        this.handleChangeDelete = this.handleChangeDelete.bind(this);
    }

    getModel() {
        return {
            countryCode: this.props.item.countryCode,
            keyGroup: this.props.item.keyGroup,
            keyPart: this.state[TranslationItem.fieldKey],
            valueEt: this.state[TranslationItem.fieldEt],
            valueEn: this.state[TranslationItem.fieldEn],
            valueRu: this.state[TranslationItem.fieldRu],
            valueLv: this.state[TranslationItem.fieldLv]
        }
    }

    create() {
        if (this.props.canCreate(this.state[TranslationItem.fieldKey])) {
            const model = this.getModel();
            this.props.putTranslation(model)
                .then(() => {
                    this.props.create(model)
                    this.setState({
                        [TranslationItem.fieldEt]: "",
                        [TranslationItem.fieldEn]: "",
                        [TranslationItem.fieldRu]: "",
                        [TranslationItem.fieldLv]: "",
                        [TranslationItem.fieldKey]: "",
                    })
                })
        }
    }

    onTextAreaChange(e, callback) {
        this.onChange(e, callback);

        if (!this.state.isNew) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.handlePutDelegate, 400);
        }
    }

    handlePutDelegate() {
        if (this.props.putLocaleFetching) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.handlePutDelegate, 100);
        } else {
            this.props.putTranslation(this.getModel());
        }
    }

    handleChangeDelete(e) {
        this.onChangeCheckbox(e);
        this.props.delete(this.props.item);
    }

    render() {
        return (
            <div className="translation-row">
                {this.state.isNew ?
                    <div className="translation-row__new-header">
                        <div className="item-1">
                            <FormattedMessage id="TranslationSettings.Action" />
                        </div>
                        <div>
                            <FormattedMessage id="TranslationSettings.Key" />
                        </div>
                        <div>
                            {this.props.countryCode === Country.EE
                                ? <FormattedMessage id="TranslationSettings.Est" />
                                : <FormattedMessage id="TranslationSettings.Lat" />
                            }
                        </div>
                        <div>
                            <FormattedMessage id="TranslationSettings.Eng" />
                        </div>
                        <div>
                            <FormattedMessage id="TranslationSettings.Rus" />
                        </div>
                    </div>
                    :
                    <div className="translation-row__header">
                        <span className="translation-group">{this.props.item.keyGroup}</span>
                        {!this.state.isNew &&
                            <span>{this.state[TranslationItem.fieldKey]}</span>
                        }
                        <div className="translation-row__header__last-item">
                            <CheckboxField
                                nativeControlId={TranslationItem.fieldToBeDeleted}
                                field={TranslationItem.fieldToBeDeleted}
                                checked={this.state[TranslationItem.fieldToBeDeleted]}
                                indeterminate={false}
                                label={
                                    <FormattedMessage id="TranslationSettings.Delete" />
                                }
                                onChange={this.handleChangeDelete}
                            />
                        </div>
                    </div>
                }

                <div className="translation-row__content">
                    {this.state.isNew &&
                        <>
                            <Button
                                className="translation-create"
                                onClick={this.create}
                                disabled={!this.props.canCreate(this.state[TranslationItem.fieldKey])}
                                icon={{
                                    icon: "icon-check",
                                    strategy: "className",
                                    basename: "icon",
                                }}>
                                <FormattedMessage id={"TranslationSettings.Create"} />
                            </Button>
                            <TextAreaGroup
                                field={TranslationItem.fieldKey}
                                noLabel={true}
                                rows={5}
                                onChange={this.onTextAreaChange}
                                value={this.state[TranslationItem.fieldKey] ?? ""}
                            />
                        </>
                    }
                    {this.props.countryCode === Country.EE
                        ? <TextAreaGroup
                            field={TranslationItem.fieldEt}
                            noLabel={true}
                            rows={5}
                            onChange={this.onTextAreaChange}
                            value={this.state[TranslationItem.fieldEt]}
                        />
                        : <TextAreaGroup
                            field={TranslationItem.fieldLv}
                            noLabel={true}
                            rows={5}
                            onChange={this.onTextAreaChange}
                            value={this.state[TranslationItem.fieldLv]}
                        />
                    }
                    <TextAreaGroup
                        field={TranslationItem.fieldEn}
                        noLabel={true}
                        rows={5}
                        onChange={this.onTextAreaChange}
                        value={this.state[TranslationItem.fieldEn]}
                    />
                    <TextAreaGroup
                        field={TranslationItem.fieldRu}
                        noLabel={true}
                        rows={5}
                        onChange={this.onTextAreaChange}
                        value={this.state[TranslationItem.fieldRu]}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        putLocaleFetching: state.putLocale.fetching,
        putLocaleFetched: state.putLocale.fetched,
        putLocaleError: state.putLocale.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        putTranslation,
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(TranslationItem));