import { saveAs } from "file-saver";
import { getFileNameFromResponseHeaders } from "../../helpers/fileDownloadHelpers";
import { DOWNLOAD_COMMUNITY_PROJECT_CONTRACT_PREVIEW_PDF } from "../../actions/communityProjectActions";

export default function reducer(state = {
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${DOWNLOAD_COMMUNITY_PROJECT_CONTRACT_PREVIEW_PDF}_PENDING`: {
            return { ...state, fetching: true };
        }
        case `${DOWNLOAD_COMMUNITY_PROJECT_CONTRACT_PREVIEW_PDF}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${DOWNLOAD_COMMUNITY_PROJECT_CONTRACT_PREVIEW_PDF}_FULFILLED`: {
            const data = action.payload.data;
            const fileName = getFileNameFromResponseHeaders(action.payload.headers) || "leping_eelvaade.pdf";

            saveAs(new Blob([data], { type: "application/pdf;charset=utf-8" }), fileName);

            return {
                ...state,
                fetching: false,
                fetched: true
            };
        }
        default:
            return state;
    }
}
