import React from "react";
import classNames from "classnames";

const DigitalStampsBonusIndicatorCell = ({ isActive = false}) => {
    return (
        <div className="digital-stamp-bonus-cell">
            <div className={classNames("digital-stamp-bonus-cell--inner", { "active": isActive })}>
                <i className="icon icon-stamp" />
            </div>
            {isActive &&
                <div className="checkmark">
                    <i className="icon icon-check-s" />
                </div>
            }
        </div>
    )
}

export default DigitalStampsBonusIndicatorCell;