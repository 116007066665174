import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";
import { List } from "@rmwc/list";
import { Button } from "@rmwc/button";
import { Dialog, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import Currency from "../common/Currency";
import RadioField from "../common/RadioField";
import { ContractFuelPath } from "../../const/routes";
import { transferToCard } from "../../actions/digitalStampsActions";
import { Fuel } from "../../const/contractType";
import { Active } from "../../const/ContractStatus";

const DigitalStampsLoyaltyInfo = ({ isClient = false }) => {
    const [isOpen, setIsOpen] = useState();
    const [validContracts, setValidContracts] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState();

    const contracts = useSelector(state => state.contracts.data);
    const postTransferToCard = useSelector(state => state.postTransferToCard);
    const data = useSelector(state => state.digitalStampsSummary.data);

    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const availableContracts = contracts.filter(c => c.status === Active && c.isVirtualAccount === false && c.type === Fuel);
        setValidContracts(availableContracts);
        setSelectedContractId(availableContracts?.[0]?.id);
    }, [contracts]);

    const getFullNameDiscountText = () => {
        const discountLevelText = `${data?.customerName} - ${intl.formatMessage({ id: "DigitalStamps.Info.Level"})} ${data?.loyaltySegment}`;
        return `${discountLevelText} (${data?.bonus})`;
    }

    const onApplyCardContract = () => {
        history.push(ContractFuelPath);
    }

    const onConvertToCard = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }

    const onConfirmTransfer = () => {
        dispatch(transferToCard(selectedContractId));
        setIsOpen(false);
    }

    return (
        <>
            <GridCell span={12} className="digital-stamps-loyalty">
                {isClient &&
                    <GridCell span={12} className="digital-stamps-loyalty--name-main">
                        {getFullNameDiscountText()}
                    </GridCell>
                }
                <GridCell span={12} className="digital-stamps-loyalty--name-sub">
                    <i className="icon icon-stamp" />
                    <span>{`${intl.formatMessage({ id: "DigitalStamps.Info.StampsLeft" })} `}</span>
                    <b className="text-bold">{data?.availableStamps}</b>
                    {data?.stampsValidTo &&
                        <span>{`(${intl.formatMessage({ id: "DigitalStamps.Info.ValidTo" })} ${moment(data?.stampsValidTo).format("DD.MM.YYYY")})`}</span>
                    }
                </GridCell>
                <GridCell span={12} className="digital-stamps-loyalty--name-sub">
                    <i className="icon icon-card" />
                    <span>{`${intl.formatMessage({ id: "DigitalStamps.Info.OnCard" })} `}</span>
                    <b><Currency value={data?.paymentCardBalance ?? 0} /></b>
                    {!isClient && data?.hasActivePaymentCardContract === false &&
                        <Button unelevated className="mdc-button--tiny ml-10" onClick={onApplyCardContract}>
                            <FormattedMessage id="DigitalStamps.Info.Apply" />
                        </Button>
                    }
                </GridCell>
                <GridCell span={12} className="digital-stamps-loyalty--name-sub">
                    <i className="icon icon-wallet" />
                    <span>{`${intl.formatMessage({ id: "DigitalStamps.Info.OnBalance" })} `}</span>
                    <b><Currency value={data?.virtualAccountBalance ?? 0} /></b>
                    {!isClient &&
                        <Button
                            unelevated
                            className="mdc-button--tiny ml-10"
                            onClick={onConvertToCard}
                            disabled={
                                !data?.virtualAccountBalance ||
                                data?.virtualAccountBalance <= 0 ||
                                data?.hasActivePaymentCardContract === false ||
                                postTransferToCard.fetching
                            }
                        >
                            <FormattedMessage id="DigitalStamps.Info.AddToBalance" />
                        </Button>
                    }
                </GridCell>
            </GridCell>

            <Dialog open={isOpen} onClose={onClose} className="digital-stamps-converter--dialog">
                <DialogTitle className="mdc-dialog__title--compact">
                    <DialogButton
                        trailingIcon={{
                            icon: "close",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                            className: "mdc-theme--secondary"
                        }}
                        className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                        action="dismiss"
                    />
                </DialogTitle>
                <DialogContent>
                    <Grid className="mdc-layout-grid--padded">
                        <GridCell span={12}>
                            <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                                <FormattedMessage id="DigitalStamps.LoyaltyDialog.Title" />
                            </Typography>
                            <div className="mt-30 mb-30">
                                <FormattedMessage id="DigitalStamps.LoyaltyDialog.Description" />
                            </div>
                        </GridCell>
                        {validContracts.length > 1 &&
                            <GridCell span={12}>
                                <List>
                                    {validContracts.map((contract, index) => {
                                        return (
                                            <li className="form-fields-list__radio-item" key={index}>
                                                <RadioField
                                                    index={index}
                                                    fieldName={contract.id}
                                                    fieldValue={contract.id}
                                                    fieldLabel={`${contract.number} (${contract.name})`}
                                                    selectedValue={selectedContractId}
                                                    onChange={() => setSelectedContractId(contract.id)}
                                                />
                                            </li>
                                        )
                                    })}
                                </List>
                            </GridCell>
                        }
                        <GridCell span={6}>
                            <Button
                                unelevated
                                onClick={onConfirmTransfer}
                            >
                                <FormattedMessage id="DigitalStamps.LoyaltyDialog.ButtonConfirm" />
                            </Button>
                        </GridCell>
                        <GridCell span={6}>
                        <Button
                                outlined
                                onClick={() => setIsOpen(false)}
                            >
                                <FormattedMessage id="DigitalStamps.LoyaltyDialog.ButtonCancel" />
                            </Button>
                        </GridCell>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default DigitalStampsLoyaltyInfo;