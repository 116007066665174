import { GridCell } from "@rmwc/grid";
import ContributionCard from "../common/ContributionCard";

const ContributionSection = ({
    firstProps,
    secondProps,
    thirdProps,
}) => {

    return (
        <>
            <GridCell span={4}>
                <ContributionCard {...firstProps} />
            </GridCell>
            <GridCell span={4} className="contribution-separator--community">
                <ContributionCard {...secondProps} />
            </GridCell>
            <GridCell span={4}>
                <ContributionCard {...thirdProps} />
            </GridCell>
        </>
    )
}

export default ContributionSection;
