import { Button } from "@rmwc/button";
import { FormattedMessage } from "react-intl";
import Authenticate from "../auth/authenticate";
import AlertMessage from "../components/common/AlertMessage";
import { Grid, GridCell } from "@rmwc/grid";

const SmsLogin = () => {
    const logOut = () => {
        Authenticate.startLogout();
    }

    return (
        <Grid className="d-flex justify-content-center align-items-center max-view-height">
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={<FormattedMessage id="LoginPage.SmsLogin.AppVersionNotice" />}
                />
            </GridCell>
            <GridCell span={12}>
                <Button
                    className="mdc-button "
                    onClick={logOut}
                    unelevated
                >
                    <FormattedMessage id="Header.LogOut" />
                </Button>
            </GridCell>
        </Grid>
    );
}

export default SmsLogin;