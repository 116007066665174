import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../auth/authenticate';
import { CardsPath, MainContactPath } from "../../const/routes";
import ContactUpdateCheck from "./ContactUpdateCheck";
import ReferralLink from "./ReferralLink";

export default function PrivateRoute({ component: Component, ...rest }) {

    const redirectToLogin = !auth.isLoggedIn();
    const redirectToContactUpdate = redirectToLogin ? false : ContactUpdateCheck.isUpdateNeeded();
    const mainContactId = redirectToLogin ? null : auth.getMainContactId();
    const isAgent = auth.isAgent();
    const redirectToCardPath = ReferralLink.shouldRedirectToCardPath();
    const requestedLocation = auth.getRequestedLocation();

    return (
        <Route {...rest} render={props => (() => {
            if (redirectToLogin) {
                return ( <Redirect to={{ pathname: "/Login" , state: { from: props.location }}} /> )
            } else if (redirectToContactUpdate && !isAgent && props.location.pathname !== MainContactPath) {
                return ( <Redirect to={{pathname: MainContactPath, state: { params: { contactId: mainContactId, requestedLocation: requestedLocation }}}} /> )
            } else if (redirectToCardPath && props.location.pathname !== CardsPath && props.location.pathname !== MainContactPath) {
                return ReferralLink.redirectToCardPath();
            } else {
                return ( <Component {...props} /> )
            }
        })()}
        />
    );
}