import PropTypes from 'prop-types';
import BaseFormComponent from "../common/BaseFormComponent";
import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import Product from "../../components/Contract/Product";
import AlertMessage from "../../components/common/AlertMessage";
import classnames from "classnames";
import Dialog from "../../components/common/BaseDialog";
import { IconButton } from "@rmwc/icon-button";
import { Electricity } from "../../const/contractType";
import auth from "../../auth/authenticate";
import { fetchUserStatus } from "../../actions/accountActions";
import { trackEvent } from '../../appInsights';
import rohesartsLogoSmall from "../../images/rohesarts-logo-small.svg";
import { IsLatvianWeb } from '../../helpers/AlternativeDesign';

const getFilterFunctions = (contractType) => ({
    'stock_price': x => x.type === 'stock_price',
    'fixed_price': x => contractType === Electricity
        ? (x.type === 'fixed_price' || x.type === 'payback' || x.type === 'fix_degrade') && x.fineAmount === 0
        : x.type === 'fixed_price' || x.type === 'payback' || x.type === 'fix_degrade',
    'dynamic_price': x => x.type === 'dynamic_price',
    'fixed_price_fine': x => x.type === 'fixed_price' && x.fineAmount > 0,
    'universal_price': x => x.type === 'universal_price' || x.type === 'universal_dynamic_price'
})

class ProductSelection extends BaseFormComponent {
    static productType = {
        fixedDegrade: "fix_degrade",
        fixedPrice: "fixed_price",
        stockPrice: "stock_price"
    };

    constructor(props) {
        super(props);

        this.state = {
            productFilter: this.props.selectedProductFilter,
            green: this.props.isGreen,
            network: false,
            isCompany: auth.isCompany(),
            filterFunctions: getFilterFunctions(this.props.contractType),
            userCrmId: auth.getUserId()
        }
    }

    componentDidMount() {
        if (!this.props.userStatusFetched && !this.props.userStatusFetching) {
            this.props.fetchUserStatus();
        }

        this.setState({
            network: this.isNetworkToggled(this.props.selectedProductFilter)
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedProductFilter !== this.props.selectedProductFilter) {
            this.setState({
                productFilter: this.props.selectedProductFilter
            });
        }
    }

    isElectricity() {
        return this.props.contractType === Electricity;
    }

    toggleFilter(key) {
        const currentState = this.state[key];

        this.setState({
            [key]: !this.state[key],
        });

        this.props.onFilterToggle(key);

        const trackedEvent = this.isElectricity()
            ? "electricityProductSelectionToggleFilter"
            : "gasProductSelectionToggleFilter";

        trackEvent(trackedEvent, {
            crmId: this.state.userCrmId,
            toggledField: key,
            toggleValue: !currentState
        });
    }

    toggleDialog(field) {
        this.setState({ [field]: !this.state[field] });
    }

    changeProductTab(tab) {
        this.setState({
            productFilter: tab,
        });

        this.props.onFilterChange(tab);
    }

    onProductChange(product) {
        this.props.onChange(product);
    }

    renderDisabledProductsMessage() {
        return (
            <AlertMessage type={AlertMessage.TYPE_NOTICE}
                isSmall={true}
                title={<FormattedMessage id="Contract.Product.DisabledMessage" />} />
        );
    }

    isAet(product) {
        return product.company.toUpperCase() === "AET";
    }

    isNetworkToggled(tab) {
        if (this.props.productFromUrl) {
            return this.props.products.find(p => p.code === this.props.productFromUrl && this.isAet(p));
        }

        return this.hasAetProducts(tab);
    }

    hasAetProducts(tab) {
        return this.props.products
            .filter(this.state.filterFunctions[tab])
            .some(p => this.isAet(p));
    }

    renderProductsBlock() {
        let panelClassName = this.props.isGreen ? "panel-green-container" : "panel-container"
        let products = this.props.products;

        let showTabs = {};
        for (const key in this.state.filterFunctions) {
            showTabs[key] = products.some(this.state.filterFunctions[key]);
        }

        let productFilter = this.state.productFilter;
        if (!productFilter || !products.some(this.state.filterFunctions[productFilter])) {
            for (const key in this.state.filterFunctions) {
                if (products.some(this.state.filterFunctions[key])) {
                    productFilter = key;
                    products = products.filter(this.state.filterFunctions[key]);
                    break;
                }
            }
        }

        products = products.filter(this.state.filterFunctions[productFilter]);
        const hasSomeAET = products.some(item => this.isAet(item));
        let network = this.state.network;

        // extra check for package change
        // different company products should not be shown
        // if this.props.showNetworkToggle is false, we know it's package change, else it is regular flow (creating new contract)
        if (!this.props.showNetworkToggle) {
            const productsAet = products.filter(item => this.isAet(item));
            const productsNotAet = products.filter(item => !this.isAet(item));

            // Since toggle is hidden we have to set the toggle automatically
            if (productsAet.length > 0 && productsNotAet.length === 0) {
                network = true;
            }

            if (productsAet.length === 0 && productsNotAet.length > 0) {
                network = false;
            }

            // if package change, then different type of product are not allowed
            if (productsAet.length > 0 && productsNotAet.length > 0) {
                products = [];
            }
        }

        if (network) {
            products = products.filter(item => this.isAet(item));
        } else {
            products = products.filter(item => !this.isAet(item));
        }

        let isProductDisabled = false;
        if (this.props.clientContractConsumptionLocationsFetched
            && this.props.clientContractConsumptionLocations.consumptionLocations.length > 1
            && this.state.productFilter === "fixed_price_fine") {
            isProductDisabled = true;
        }

        const productsList = products
            .map(item =>
                <Product key={item.id}
                    onChange={this.onProductChange.bind(this, item)}
                    fieldName={this.props.fieldName}
                    product={item}
                    isGas={!this.isElectricity()}
                    isGreen={this.props.isGreen}
                    isLatvian={IsLatvianWeb()}
                    selectedValue={this.props.selectedValue}
                    disabled={isProductDisabled}
                />);
        return (
            <>
                {productsList.length > 0 ?
                    <>
                        <GridCell span={12}>
                            <div className="contract-tab-container left">
                                {showTabs['fixed_price_fine'] &&
                                    <div
                                        className={classnames("mdc-button-tab-underlined", {
                                            "active": productFilter === 'fixed_price_fine'
                                        })}
                                        onClick={this.changeProductTab.bind(this, "fixed_price_fine")}>
                                        <FormattedMessage id="Contract.Product.FixedFine" />
                                    </div>
                                }
                                {showTabs['stock_price'] &&
                                    <div
                                        className={classnames("mdc-button-tab-underlined", {
                                            "active": productFilter === 'stock_price'
                                        })}
                                        onClick={this.changeProductTab.bind(this, "stock_price")}>
                                        <FormattedMessage id="Contract.Product.Market" />
                                    </div>
                                }

                                {showTabs['fixed_price'] &&
                                    <div
                                        className={classnames("mdc-button-tab-underlined", {
                                            "active": productFilter === 'fixed_price'
                                        })}
                                        onClick={this.changeProductTab.bind(this, "fixed_price")}>
                                        <FormattedMessage id="Contract.Product.Fixed" />
                                    </div>
                                }
                                {showTabs['dynamic_price'] &&
                                    <div
                                        className={classnames("mdc-button-tab-underlined", {
                                            "active": productFilter === 'dynamic_price'
                                        })}
                                        onClick={this.changeProductTab.bind(this, "dynamic_price")}>
                                        <FormattedMessage id="Contract.Product.DynamicPrice" />
                                    </div>
                                }
                                {showTabs['universal_price'] &&
                                    <div
                                        className={classnames("mdc-button-tab-underlined", {
                                            "active": productFilter === 'universal_price'
                                        })}
                                        onClick={this.changeProductTab.bind(this, "universal_price")}>
                                        <FormattedMessage id="Contract.Product.UniversalPrice" />
                                    </div>
                                }
                            </div>

                            <div className="contract-tab-container right">
                                {this.isElectricity() && this.props.showGreenEnergyToggle &&
                                    <div className="contract-tab-container__item">
                                        <div className="contract-green-energy-logo-small"
                                            onClick={this.toggleDialog.bind(this, "greenDialog")}>
                                            <img alt="Rohesärts väike logo" src={rohesartsLogoSmall} />
                                        </div>

                                        <span className="contract-padded-label">
                                            <FormattedMessage id="Contract.Product.GreenEnergy" />
                                        </span>

                                        <div
                                            className={classnames("mdc-button-toggle", {
                                                "active": this.props.isGreen
                                            })}
                                            onClick={this.toggleFilter.bind(this, "green")}>
                                        </div>
                                    </div>
                                }
                                {hasSomeAET && this.props.showNetworkToggle &&
                                    <div className="contract-tab-container__item">
                                        <IconButton
                                            className="contract-icon"
                                            onIcon={{
                                                icon: "info-fill",
                                                strategy: "className",
                                                basename: "icon",
                                                prefix: "icon-"
                                            }}
                                            onClick={this.toggleDialog.bind(this, "networkDialog")}
                                        />

                                        <span className="contract-padded-label">
                                            <FormattedMessage id="Contract.Product.NetworkInvoice" />
                                        </span>
                                        <div
                                            className={classnames("mdc-button-toggle", {
                                                "active": this.state.network
                                            })}
                                            onClick={this.toggleFilter.bind(this, "network")}>
                                        </div>
                                    </div>
                                }
                            </div>
                            <Dialog isOpen={this.state.networkDialog} onClose={this.toggleDialog.bind(this, "networkDialog")}>
                                <div className="information">
                                    <Typography
                                        use="headline2"
                                    >
                                        <FormattedMessage id="Contract.Network.Dialog.Title" />
                                    </Typography>
                                    <div className="information__description lighter">
                                        <FormattedMessage id="Contract.Network.Dialog.Description" />
                                    </div>
                                </div>
                            </Dialog>

                            <Dialog isOpen={this.state.greenDialog} onClose={this.toggleDialog.bind(this, "greenDialog")}>
                                <div className="information">
                                    <Typography
                                        use="headline2"
                                    >
                                        <FormattedMessage id="Contract.GreenEnergy.Dialog.Title" />
                                    </Typography>
                                    <div className="information__description lighter">
                                        <FormattedMessage id="Contract.GreenEnergy.Dialog.Description" />
                                    </div>
                                </div>
                            </Dialog>
                        </GridCell>

                        <GridCell span={12}>
                            {this.props.clientContractConsumptionLocationsFetched
                                && this.props.clientContractConsumptionLocations.consumptionLocations.length > 1
                                && this.state.productFilter === "fixed_price_fine" &&

                                this.renderDisabledProductsMessage()
                            }
                        </GridCell>

                        <GridCell className={panelClassName} span={12}>
                            {productsList}
                        </GridCell>
                    </>
                    : <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_NOTICE}
                            scrollToMessage={true}
                            title={<FormattedMessage id="Contract.Product.NoProducts" />} />
                    </GridCell>
                }
            </>
        );
    }


    render() {
        const { isCompany, productFilter } = this.state;
        const { userStatusFetched, userStatus } = this.props;

        return (
            <>
                {userStatusFetched &&
                    isCompany &&
                    userStatus && !userStatus.isHomeuser &&
                    productFilter === "universal_price" &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_ALERT}
                            isSmall={true}
                            title={<FormattedMessage id="Contract.Product.UniversalProduct.CompanyUserMessage" />} />
                    </GridCell>}

                {this.renderProductsBlock()}
            </>
        )
    }
}

ProductSelection.propTypes = {
    products: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    onFilterToggle: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    contractType: PropTypes.string.isRequired,
    showGreenEnergyToggle: PropTypes.bool,
    showNetworkToggle: PropTypes.bool,
    isGreen: PropTypes.bool,
    isNetwork: PropTypes.bool,
    selectedProductFilter: PropTypes.string
};

ProductSelection.defaultProps = {
    showGreenEnergyToggle: true,
    showNetworkToggle: true,
    isGreen: false,
    isNetwork: false,
    selectedProductFilter: "universal_price",
};

function mapStateToProps(state) {
    return {
        userStatus: state.userStatus.data,
        userStatusFetching: state.userStatus.fetching,
        userStatusFetched: state.userStatus.fetched,

        clientContractConsumptionLocations: state.clientContractConsumptionLocations.data,
        clientContractConsumptionLocationsFetching: state.clientContractConsumptionLocations.fetching,
        clientContractConsumptionLocationsFetched: state.clientContractConsumptionLocations.fetched,
        clientContractConsumptionLocationsError: state.clientContractConsumptionLocations.error,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchUserStatus
    }, dispatch);
}


export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(ProductSelection));
export { getFilterFunctions };