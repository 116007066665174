import React from "react";
import { MainContactPath } from "../../const/routes";
import auth from "../../auth/authenticate";
import { Redirect } from "react-router-dom";

const ContactUpdateCheck = {
    isUpdateNeeded() {
        let role = auth.getRole();
        return role.contactDataUpdateRequired;
    },
    redirectToContactUpdate(contactId){
        return <Redirect to={{
            pathname: MainContactPath,
            state: { params: { contactId: contactId } }
            }} />
    }
};
export default ContactUpdateCheck;
