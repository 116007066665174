import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const fetchFamilyGroup = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "FETCH_FAMILY_GROUP",
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/familyGroup`)
    }
}

export const createFamilyGroup = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "CREATE_FAMILY_GROUP",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/familyGroup`, data)
    }
}

export const clearCreateFamilyGroup = () => {
    return {
        type: "CREATE_FAMILY_GROUP_CLEAR"
    }
}

export const acceptInvitation = (groupId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "ACCEPT_FAMILY_GROUP_INVITATION",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/familyGroup/accept-invitation`, { groupId })
    }
}

export const rejectInvitation = (groupId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "REJECT_FAMILY_GROUP_INVITATION",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/familyGroup/reject-invitation`, { groupId })
    }
}

export const deleteGroup = (groupId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "DELETE_FAMILY_GROUP",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/familyGroup/delete`, { groupId })
    }
}

export const removeMember = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "REMOVE_FAMILY_GROUP_MEMBER",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/familyGroup/remove-member`, data)
    }
}

export const clearDeleteGroup = () => {
    return {
        type: "DELETE_FAMILY_GROUP_CLEAR"
    }
}

export const clearAcceptInvite = () => {
    return {
        type: "ACCEPT_FAMILY_GROUP_INVITATION_CLEAR"
    }
}

export const clearRejectInvite = () => {
    return {
        type: "REJECT_FAMILY_GROUP_INVITATION_CLEAR"
    }
}

export const leaveFamilyGroup = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "LEAVE_FAMILY_GROUP",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/familyGroup/remove-member`, data)
    }
}

export const clearLeaveFamilyGroup = () => {
    return {
        type: "LEAVE_FAMILY_GROUP_CLEAR"
    }
}