export default function reducer(state={
    data: null,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case "LEAVE_FAMILY_GROUP_PENDING": {
            return {...state, fetching: true};
        }
        case "LEAVE_FAMILY_GROUP_REJECTED": {
            return {...state, fetching: false, fetched: false, error: action.payload};
        }
        case "LEAVE_FAMILY_GROUP_FULFILLED": {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data};
        }
        case "LEAVE_FAMILY_GROUP_CLEAR": {
            return {data: null, fetching: false, fetched: false, error: null};
        }
        default:
            return state;
    }
}