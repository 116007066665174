const RequiredIfValidatorRule = {
    rule() {
        return {
            requiredIf: {
                message: 'The :attribute is required.',
                rule: (val, params, validator) => {
                    let condition = params[0];
                    if (!condition) {
                        return false;
                    }

                    return !validator.helpers.isBlank(val);
                },
                required: true
            }
        };
    }
};

export default RequiredIfValidatorRule;