import { SUBMIT_GASO_METER } from "../../actions/gasoMeterActions";

export default function reducer(state = {
    data: null,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${SUBMIT_GASO_METER}_PENDING`: {
            return { ...state, fetching: true, error: null, fetched: false };
        }
        case `${SUBMIT_GASO_METER}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload.response?.data?.errorMessage ?? "", fetched: false };
        }
        case `${SUBMIT_GASO_METER}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, error: null };
        }
        default:
            return state;

    }
}