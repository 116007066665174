import { useState } from "react";
import { useDispatch } from "react-redux";
import useWebeID from "./useWebeID"
import useEParakstsMobile from "./useEParakstsMobile";
import {
    postPrepareSignatureSmartId,
    postFinalizeSignatureSmartId,
    postPrepareSignatureMobileId,
    postFinalizeSignatureMobileId
} from "../../actions/digitalSignatureActions";
import { AuthenticationType } from "../../const/authenticationType";
import useEParakstsEID from "./useEParakstsEID";

/**
 * The callback function after the document has been successfully signed
 * @callback onDocumentSignedCallback
 */

/**
 * Information about the signing method and user data
 * @typedef {Object} SigningData
 * @property {String} signingMethod The signing method, see AuthenticationType
 * @property {String} personalCodeForSigning The user's national identity number
 * @property {String} mobileNumber User's mobile number for signing with Mobile-ID
 */

/**
 * The data about the file that should be included in the signature container.
 * Can include additional properties when needed.
 * @typedef {Object} FileToSign
 * @property {String} fileName The file's name that is to be signed (will also be used as the container name). It must exist in Azure storage before signing.
 */

/**
 * Starts the digital signing process
 * @callback startSigningProcess
 * @param {SigningData} signingData Information about the signing method and user data
 * @param {FileToSign} fileToSign The data about the file that should be included in the signature container. Can include additional properties when needed.
 * @param {onDocumentSignedCallback} onDocumentSigned
 */

/**
 * Hook that allows to sign documents
 * @returns {[String, startSigningProcess]} Challenge code and the signing start function
 */
const useDigitalSigning = () => {
    const [challengeCode, setChallengeCode] = useState();

    const dispatch = useDispatch();
    const [signIdCard,] = useWebeID();
    const { prepareSigning } = useEParakstsMobile();
    const signEParakstsEID = useEParakstsEID();

    const startSigningProcess = async (signingData, fileToSign, onDocumentSigned) => {
        if (!signingData?.signingMethod) {
            throw new Error("signing method not found");
        }

        switch (signingData.signingMethod) {
            case AuthenticationType.IdCard:
                await dispatch(signIdCard(fileToSign));
                break;
            case AuthenticationType.SmartId:
                await dispatch(signSmartId(fileToSign, signingData));
                break;
            case AuthenticationType.MobileId:
                await dispatch(signMobileId(fileToSign, signingData));
                break;
            case AuthenticationType.EParakstsMobile:
                // This is where the code execution stops
                // as the function redirects the user to an external page
                prepareSigning(fileToSign, signingData.signingMethod);
                break;
            case AuthenticationType.EParakstsIdCard:
                await dispatch(signEParakstsEID(fileToSign));
                break;
            default:
                throw new Error("Unrecognized signing method");
        }

        // After document has been signed successfully
        await dispatch(onDocumentSigned?.(fileToSign));
    }

    const signSmartId = (fileData, userData) => async (dispatch) => {
        if (!userData?.personalCodeForSigning || !fileData.fileName) {
            throw new Error("invalid data");
        }

        const response = await dispatch(postPrepareSignatureSmartId({
            fileName: fileData.fileName,
            personalCode: userData.personalCodeForSigning
        }));

        await dispatch(finalizeSignature(response, postFinalizeSignatureSmartId));
    };

    const signMobileId = (fileData, userData) => async (dispatch) => {
        if (!userData?.personalCodeForSigning || !userData?.mobileNumber || !fileData.fileName) {
            throw new Error("invalid data");
        }

        const response = await dispatch(postPrepareSignatureMobileId({
            fileName: fileData.fileName,
            personalCode: userData.personalCodeForSigning,
            phoneNumber: userData.mobileNumber
        }));

        await dispatch(finalizeSignature(response, postFinalizeSignatureMobileId));
    };

    const finalizeSignature = (response, postFinalizeFunc) => async (dispatch) => {
        if (response.value.status !== 200) {
            throw new Error("request failed");
        }

        const { challengeCode, fileName, sessionId } = response.value.data;
        setChallengeCode(challengeCode);

        const data = {
            fileName,
            sessionId
        };

        try {
            await dispatch(postFinalizeSignature(data, postFinalizeFunc));
        } catch (error) {
            if (error.message && error.message === "Network Error") {
                await dispatch(postFinalizeSignature(data, postFinalizeFunc));
            } else {
                throw error;
            }
        }
    };

    const postFinalizeSignature = (data, postFinalizeFunc) => async dispatch => {
        const finalizeResponse = await dispatch(postFinalizeFunc(data));

        if (finalizeResponse.value.status !== 200) {
            throw new Error("finalizing signature failed");
        }
    }

    return [challengeCode, startSigningProcess];
}

export default useDigitalSigning;