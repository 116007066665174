import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from "react-intl";
import moment from 'moment';
import InlineTooltip, { TooltipPosition, TooltipType } from '../common/InlineTooltip';
import RadioField from "../common/RadioField";

import '../../styles/components/_fields-group.scss';

const ConsumptionLocationSelect = ({ fieldName, index, onChange, selectedValue, location, noAddressText, hideFirstAvailableContractDate, isPenaltyProduct, isDynamicUniversalProduct, disabled }) => {

    const intl = useIntl();
    const onLabelClick = (locationId) => {
        if (+locationId !== +selectedValue[0] && !isDisabled) {
            onChange(locationId);
        }
    }
    const hasStartDates = location.contractStartDates !== null;

    const penaltyStartDate = (isPenaltyProduct && hasStartDates && !!location.contractStartDates.penaltyStartDate)
        ? location.contractStartDates.penaltyStartDate
        : null;

    const universalDynamicPriceStartDate = (isDynamicUniversalProduct && hasStartDates && !!location.contractStartDates.universalDynamicTypeStartDate)
        ? location.contractStartDates.universalDynamicTypeStartDate
        : null;

    const contractStartDate = location.contractStartDates !== null &&
        (penaltyStartDate || universalDynamicPriceStartDate || location.contractStartDates.startDate);

    const isDisabled = location.isInContract || disabled;

    // TODO: override isinContract and disabled and set them both to false if override is set
    // if (showLocationsWithContracts) {
    //     location.isInContract = false;
    //     disabled = false;
    // }

    return (
        <div className="fields-group__list-item">
            <div className={classnames({ "mdc-text-field--disabled": isDisabled })}>
                <RadioField
                    wrapperClasses={"override-mdc-form-field"}
                    index={index}
                    fieldName={fieldName}
                    fieldValue={location.id}
                    selectedValue={selectedValue}
                    disabled={isDisabled}
                    onChange={onChange.bind(this, location.id)}
                />
            </div>
            <label className={classnames("text", { "disabled": isDisabled })} htmlFor={"loc_" + location.id} onClick={() => onLabelClick(location.id)}>
                <span className="primary-text">{location.eicCode}</span>
                <span>{location.address || noAddressText}&nbsp;&nbsp;</span>
                {!hideFirstAvailableContractDate && contractStartDate !== null && !isDisabled &&
                    <span><b><FormattedMessage id="ConsumptionLocation.Contract.FirstPossibleStartDate" /></b>&nbsp;{moment(contractStartDate).format("DD.MM.YYYY")}</span>
                }
                {!!location.isInContract &&
                    <span><b><FormattedMessage id="ConsumptionLocation.LocationInContract" /></b></span>}
                {!!location.hasPenaltyProduct &&
                    <div className="ml-10">
                        <InlineTooltip
                            text={intl.formatMessage({ id: "ConsumptionLocation.Contract.PenaltyAppliesTooltip" })}
                            position={TooltipPosition.Left}
                            type={TooltipType.Alert}
                        >
                            <span className="location-penalty">
                                <FormattedMessage id="ConsumptionLocation.Contract.PenaltyApplies" />
                            </span>
                        </InlineTooltip>
                    </div>
                }
            </label>
        </div >
    );
}

ConsumptionLocationSelect.propTypes = {
    fieldName: PropTypes.string.isRequired,
    index: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.array,
    location: PropTypes.object,
    noAddressText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    hideFirstAvailableContractDate: PropTypes.bool,
    isPenaltyProduct: PropTypes.bool,
    isDynamicUniversalProduct: PropTypes.bool // temporary variable for UT2
};

ConsumptionLocationSelect.defaultProps = {
    index: 1,
    selectedValue: [],
    hideFirstAvailableContractDate: false,
    isPenaltyProduct: false,
    isDynamicUniversalProduct: false,
};

export default ConsumptionLocationSelect;
