import auth from "../../auth/authenticate"

export default function reducer(state={
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case "JWT_REFRESH_FULFILLED": {
            auth.setTokenFromHeader(action.payload.headers)
            return {...state, fetching: false, fetched: true};
        }
        case "JWT_REFRESH_REJECTED": {
            return {...state, fetching: false, fetched: true};
        }
        default:
            return state;
    }
}