import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {FormattedMessage, injectIntl} from "react-intl";
import  logo from  '../../images/logo-white.svg';
import {StartPath} from '../../const/routes';
import { setAgentRole } from "../../actions/accountActions";
import RoleDialog from "../LoginForm/RoleDialog";
import LanguageSelector from "../LanguageSelector";
import auth from "../../auth/authenticate";
import { Button } from "@rmwc/button";

class Header extends React.Component {

    constructor(props) {
        super(props);

        let data = auth.getUser();
        let name = data ? `${data.firstName} ${data.lastName}`: "";

        this.state = {
            openRolesDialog: false,
            name: name
        }
    }

    exitAgentView() {
        if (this.props.agentRoleFetching) {
            return;
        }

        this.props.setAgentRole()
        .then(() => {
            this.props.history.push(StartPath);
        });
    }

    closeRoles() {
        this.setState({ openRolesDialog: false });
    }

    toggleRoles() {
        this.setState({ openRolesDialog: !this.state.openRolesDialog });
    }

    render() {
        const logoAltText = this.props.intl.formatMessage({
            id: 'ContractPage.Logo.Alt'
        })

        return (
            <div className="header-agent__container">
                <div className="header-agent__top">
                    <img alt={logoAltText} src={ logo } />
                    <div>
                        <LanguageSelector />
                    </div>
                </div>
                <div className="header-agent__name">
                    <span>{this.state.name}</span>
                </div>
                <div className="header-agent__menu">
                    <span onClick={this.exitAgentView.bind(this)} className="icon icon-arrow-left">
                        <FormattedMessage id="RoleChooseDialog.Agent.Exit" />
                    </span>

                    <Button unelevated onClick={this.toggleRoles.bind(this)}>
                        <FormattedMessage id="RoleChooseDialog.Agent.ChangeRole" />
                    </Button>
                </div>
                <RoleDialog
                    key={"roleDialog" + this.state.openRolesDialog}
                    isOpen={this.state.openRolesDialog}
                    onClose={this.closeRoles.bind(this)}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        agentRoleFetching: state.setAgentRole.fetching
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ setAgentRole }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(Header)));