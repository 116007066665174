import moment from "moment";
const dateFormats = [
    moment.ISO_8601,
    "MM/DD/YYYY",
    "DD.MM.YYYY",
    "DD.MM YYYY",
    "DD MMMM YYYY",
    "DD. MMMM YYYY"
];

export function getDaysSince(inputDate) {
    const date = moment(inputDate, dateFormats, true);

    return date.isValid()
        ? moment(new Date()).diff(date, 'days')
        : 0;
}