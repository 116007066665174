import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import '../../styles/react-components/contract-footer.scss';
import { Link } from "react-router-dom";

class Footer extends React.Component {
    render() {
        return (
            <>
                <hr className="divider divider--secondary divider--rotate" />
                <div className="mdc-layout-grid__inner">
                    <div
                        className="mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop mdc-layout-grid__cell--main empty">
                        <div className="action-buttons action-buttons--last mobile-center">
                            <Link className="mdc-button mdc-button--link mdc-theme--secondary left" to="/">
                                <i className="mdc-button__icon icon icon-arrow-left left" aria-hidden="true"></i>
                                <b className="mdc-button__label"><FormattedMessage id="Contract.Footer.BackToMain.Button"  /></b>
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}



export default injectIntl((Footer));