import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import DatePickerRange from "../common/DatePickerRange";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl  } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BaseFormComponent from "../common/BaseFormComponent";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import SelectAutoCompleteField from "../../components/common/SelectAutoCompleteField";
import ServicePointSelect from "../../components/servicePoints/ServicePointSelect";
import { fetchServicePoints } from "../../actions/stationActions";
import { fetchBonusCards } from "../../actions/cardActions";
import { defaultRanges, createRangePresets } from "../common/DateRangePreset";
import SelectDateRangePreset from "../common/SelectDateRangePreset";

class BonuscardTransactionFilter extends BaseFormComponent {
  static FieldPeriodStart = "filter.startDate";
  static FieldPeriodEnd = "filter.endDate";
  static FieldServicePointSelected = "filter.servicePoint";
  static FieldBonuscardSelected = "filter.bonuscard";
  static FieldSelectedPeriodPreset = "filter.selectedPeriodPreset";

  constructor(props) {
    super(props);
    this.onFilterPeriodStartDateChange = this.onFilterPeriodStartDateChange.bind(
      this
    );
    this.onFilterPeriodEndDateChange = this.onFilterPeriodEndDateChange.bind(
      this
    );
    this.onFilterServicePointChange = this.onFilterServicePointChange.bind(
      this
    );
    this.onFilterBonuscardChange = this.onFilterBonuscardChange.bind(
      this
    );
    this.onFilterPeriodPresetChange = this.onFilterPeriodPresetChange.bind(
      this
    );
    this.filter = this.filter.bind(this);

    this.state = {
      periodPresets: createRangePresets(defaultRanges, this.props.intl),
      filter: {
        startDate: this.props.filter.startDate,
        endDate: this.props.filter.endDate,
        bonuscard: this.props.filter.bonuscard,
        servicePoint: this.props.filter.servicePoint,
        selectedPeriodPreset: this.props.filter.selectedPeriodPreset,
        selectedPeriodPresetName: this.props.filter.selectedPeriodPresetName, 
      },
    };
  }

  componentDidMount() {

    if (!this.props.areServicePointsFetched) {
      this.props.fetchServicePoints();
    }
    if (!this.props.areBonuscardsFetched) {
      this.props.fetchBonusCards();
    }
  }

  onFilterPeriodStartDateChange(value) {
    this.setState((prev) => ({
      filter: { 
        ...prev.filter,
        startDate: moment(value),
        selectedPeriodPresetName: null,
      },
    }));
  }

  onFilterPeriodEndDateChange(value) {
    this.setState((prev) => ({
      filter: { 
        ...prev.filter,
        endDate: moment(value),
        selectedPeriodPresetName: null,
      },
    }));
  }

  onFilterPeriodPresetChange(value) {
    if (value) {
      let period = this.state.periodPresets[+value.value];
      if (period) {
        this.setState((prev) => ({
          filter: {
            ...prev.filter,
            selectedPeriodPreset: period,
            selectedPeriodPresetName: period.label,
            startDate: moment(period.range.startDate),
            endDate: moment(period.range.endDate),
          },
        }));
      }
    }
  }

  onFilterServicePointChange(value) {
    this.setState((prev) => ({
      filter: { ...prev.filter, servicePoint: value },
    }));
  }

  onFilterBonuscardChange(value) {
    this.setState((prev) => ({
      filter: { ...prev.filter, bonuscard: value },
    }));
  }
  filter() {
    this.props.onSubmit(this.state.filter);
  }

  render() {
    return (
      <Grid
        className={classnames("filter-form", {
          "filter-form--open": this.props.open,
        })}
      >
        <GridCell desktop={4} tablet={4} phone={4} >
          <DatePickerRange
            label="Transactions.Filter.Period"
            fieldStart={BonuscardTransactionFilter.FieldPeriodStart}
            fieldEnd={BonuscardTransactionFilter.FieldPeriodEnd}
            startOnChange={this.onFilterPeriodStartDateChange}
            endOnChange={this.onFilterPeriodEndDateChange}
            startValue={this.state.filter.startDate.toDate()}
            endValue={this.state.filter.endDate.toDate()}
            maxStartDate={this.state.filter.endDate.toDate()}
            minEndDate={this.state.filter.startDate.toDate()}
          />
        </GridCell>
        <GridCell desktop={4} tablet={4} phone={4} >
        <SelectDateRangePreset
            onChange={this.onFilterPeriodPresetChange}
            field={BonuscardTransactionFilter.FieldSelectedPeriodPreset}
            value={this.state.filter.selectedPeriodPresetName}
            options={this.state.periodPresets}
            label= "General.Space"
            placeholder={this.props.intl.formatMessage({
              id: 'Transactions.Period.Select'
            })}
          />
        </GridCell>
        <GridCell desktop={4} tablet={4} phone={4} >
          <ServicePointSelect
            value={this.state.filter.servicePoint}
            field={BonuscardTransactionFilter.FieldServicePointSelected}
            onChange={this.onFilterServicePointChange}
            label="Transactions.Filter.ServicePoint"
            placeholder="General.Select.Station"
          />
        </GridCell>
        <GridCell desktop={4} tablet={4} phone={4} >
          <SelectAutoCompleteField
            placeholder={this.props.intl.formatMessage({
              id: 'Transactions.Card.Select'
            })}
            isLoading={this.props.areBonuscardsFetching}
            value={this.state.filter.bonuscard}
            onChange={v => this.onChangeByField(BonuscardTransactionFilter.FieldBonuscardSelected, v)}
            field={BonuscardTransactionFilter.FieldBonuscardSelected}
            label="Transactions.Filter.Bonuscard"
            options={this.props.bonuscardNumbers} />
        </GridCell>
        <GridCell desktop={8} tablet={8}>
          <div>
            <Button unelevated onClick={this.filter} className="right mt-25">
              <i className="icon-wave" />
              <FormattedMessage id="General.Filter" />
            </Button>
          </div>
        </GridCell>
      </Grid>
    );
  }
}

BonuscardTransactionFilter.propTypes = {
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  filter: PropTypes.object,
};

function toBonuscardNumberArray(bonuscards){
  let map = bonuscards.reduce(function (map, obj) {
    map[obj.number] = obj;
    return map;
  }, {});
  return Object.keys(map);
}

function mapStateToProps(state) {
  return {
    bonuscardNumbers: toBonuscardNumberArray(state.bonusCards.data),
    areBonuscardsFetched: state.bonusCards.fetched,
    areBonuscardsFetching: state.bonusCards.fetching,
    servicePoints: state.servicePoints.data,
    areServicePointsFetched: state.servicePoints.fetched,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchServicePoints,
    fetchBonusCards
  }, dispatch);
}

export default injectIntl(connect(
  mapStateToProps,
  matchDispatchToProps
)(BonuscardTransactionFilter));
