import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from "@rmwc/icon";
import { Button } from "@rmwc/button";

export const AlertType = {
    DEFAULT: "default",
    YELLOW: "yellow",
    GREEN: "green"
}

const Alert = ({ type, iconName, imageSrc, imageAlt, title, content, action, actionText, className, ...props }) => {

    const isString = (x) => typeof x === 'string';

    const hasAction = useMemo(() => !!action, [action]);

    return (
        <div className={classnames(
            "alert alert--mobile-small",
            `alert--${type}`,
            className
        )}
            {...props}
        >
            {!!iconName &&
                <Icon
                    icon={{
                        icon: iconName,
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }}
                />
            }
            {!!imageSrc &&
                <img src={imageSrc} alt={imageAlt} className="alert__image" />}

            {!!title &&
                <div className="alert__title">
                    {title}
                </div>
            }

            {!!content &&
                <div className="alert__content nowrap">
                    {content}
                </div>
            }

            {hasAction &&
                (isString(action)
                    ? <Button
                        tag="a"
                        unelevated
                        href={action}
                        className="mdc-button--secondary alert__action-button">
                        {actionText}
                    </Button>
                    : <Button
                        onClick={action}
                        unelevated
                        className="mdc-button--secondary alert__action-button">
                        {actionText}
                    </Button>
                )
            }
        </div >
    );

}

Alert.defaultProps = {
    type: AlertType.DEFAULT,
    imageAlt: "",
    action: undefined
}

Alert.propTypes = {
    type: PropTypes.oneOf(Object.values(AlertType)),
    className: PropTypes.any,
    iconName: PropTypes.string,
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    actionText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Alert;