import { SEND_ORDER } from "../../actions/fuelTaxiOrderActions";

export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${SEND_ORDER}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${SEND_ORDER}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${SEND_ORDER}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}