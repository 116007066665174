import { POST_DIGITAL_STAMPS_CLIENT_ADD_CUSTOMER } from "../../actions/digitalStampsActions";

export default function reducer(state={
    data: null,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${POST_DIGITAL_STAMPS_CLIENT_ADD_CUSTOMER}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${POST_DIGITAL_STAMPS_CLIENT_ADD_CUSTOMER}_REJECTED`: {
            return {...state, fetching: false, fetched: false, error: action.payload};
        }
        case `${POST_DIGITAL_STAMPS_CLIENT_ADD_CUSTOMER}_FULFILLED`: {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}