import { PUT_USER_NOTIFICATIONS_READ } from "../../actions/notificationActions";

export default function reducer(state = {
    data: false,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${PUT_USER_NOTIFICATIONS_READ}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${PUT_USER_NOTIFICATIONS_READ}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${PUT_USER_NOTIFICATIONS_READ}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}