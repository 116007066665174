import { startOfWeek, startOfMonth, endOfWeek, endOfMonth, addDays, addMonths, startOfYear, endOfYear } from "date-fns";

const presetTomorrow = {
    label: "Filter.PeriodPreset.Day.Tomorrow",
    range: {
        startDate: addDays(new Date(), 1),
        endDate: addDays(new Date(), 1),
    }
};

const presetToday = {
    label: "Filter.PeriodPreset.Day.Today",
    range: {
        startDate: new Date(),
        endDate: new Date(),
    },
};

const presetYesterday = {
    label: 'Filter.PeriodPreset.Day.Yesterday',
    range: {
        startDate: addDays(new Date(), -1),
        endDate: addDays(new Date(), -1),
    },
};

const presetWeekThis = {
    label: "Filter.PeriodPreset.Week.This",
    range: {
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
    },
};

const presetWeekLast = {
    label: 'Filter.PeriodPreset.Week.Last',
    range: {
        startDate: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
        endDate: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
    },
};

const presetMonthThis = {
    label: 'Filter.PeriodPreset.Month.This',
    range: {
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
    },
};

const presetMonthLast = {
    label: 'Filter.PeriodPreset.Month.Last',
    range: {
        startDate: startOfMonth(addMonths(new Date(), -1)),
        endDate: endOfMonth(addMonths(new Date(), -1)),
    },
};

const presetYearThis = {
    label: 'Filter.PeriodPreset.Year.This',
    range: {
        startDate: startOfYear(new Date()),
        endDate: endOfYear(new Date()),
    },
};

const presetYearLast = {
    label: 'Filter.PeriodPreset.Year.Last',
    range: {
        startDate: startOfYear(addMonths(startOfYear(new Date()), -1)),
        endDate: endOfYear(addMonths(startOfYear(new Date()), -1)),
    },
};


export const defaultRanges = [
    presetWeekThis,
    presetWeekLast,
    presetMonthThis,
    presetMonthLast,
];

export const consumptionRanges = [
    presetToday,
    presetYesterday,
    presetWeekThis,
    presetWeekLast,
];

export const productionRanges = [
    presetToday,
    presetYesterday,
    presetWeekThis,
    presetWeekLast,
];

export const nordpoolRanges = [
    presetToday,
    presetTomorrow,
];

export const statisticsRanges = [
    presetYearThis,
    presetYearLast,
    presetMonthLast,
    presetMonthThis,
];

export function createRangePresets(ranges, intl) {
    if (intl) {
        return Object.values(ranges).map((item, index) => ({
            label: intl.formatMessage({ id: item.label }),
            value: index,
            range: item.range,
        }));
    }
    return Object.values(ranges).map((item, index) => ({
        label: item.label,
        value: index,
        range: item.range,
    }));
}

