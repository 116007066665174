import {POST_PRINT_PAPER_RECEIPT} from "../../actions/accountActions";

export default function reducer(state={
    data: null,
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${POST_PRINT_PAPER_RECEIPT}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${POST_PRINT_PAPER_RECEIPT}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${POST_PRINT_PAPER_RECEIPT}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}