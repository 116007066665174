import React, { useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";
import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";
import BaseLogin from "../components/common/BaseLogin";
import Loader from "../components/common/Loader";
import auth from "../auth/authenticate";
import language from "../lang/lang";
import { UsingApp, AppIsCorrectVersion, IsCombinedAppContainer } from "../helpers/AlternativeDesign";
import BaseDialog from "../components/common/BaseDialog";
import { fetchTranslations } from "../actions/localeActions";
import { sendMessageToAppContainer } from "../helpers/appContainerHelper";

const Login = () => {
    const location = useLocation();
    const intl = useIntl();
    const showStopLogin = UsingApp() && !AppIsCorrectVersion();
    const requestedLocation = location?.state?.from?.pathname;

    const login = useCallback(() => {
        console.debug("stating login")
        auth.startLogIn(intl.locale || language.getDefaultLocale(), null, null, undefined, undefined, requestedLocation);
    }, [intl.locale, requestedLocation]);

    const loginWithDelayInApp = useCallback(() => {
        if (UsingApp() && IsCombinedAppContainer()) {
            sendMessageToAppContainer("logininfo", { "ssoCheck": true }, 500);
            setTimeout(() => login(), 600);
        } else {
            login();
        }
    }, [login]);

    useEffect(() => {
        if ((!UsingApp() || AppIsCorrectVersion()) && !auth.isLoggedIn()) {
            const lastLocaleUpdated = localStorage.getItem(language.MessagesLastUpdate);
            if (!lastLocaleUpdated || new Date(Number(lastLocaleUpdated)) < new Date()) {
                // Got to be sure, that new users download translations before starting login process.
                // Otherwise they might not get any, until the next page refresh
                fetchTranslations()
                    .then((items) => {
                        items.data.forEach(item => {
                            localStorage.setItem(item.locale + language.Messages, JSON.stringify(item.messages));
                        });

                        const cacheTime = new Date();
                        cacheTime.setMinutes(cacheTime.getMinutes() + 5);
                        localStorage.setItem(language.MessagesLastUpdate, cacheTime.getTime());
                        loginWithDelayInApp();
                    }).catch(() => {
                        loginWithDelayInApp();
                    });
            } else {
                loginWithDelayInApp();
            }
        }
    }, [loginWithDelayInApp]);

    const renderTitle = () => {
        return (
            <Grid className="mdc-layout-grid--mobile-vertical">
                <GridCell span={12}>
                    <Typography
                        use="headline2"
                        tag="h2"
                        className="mdc-typography mdc-theme--primary mdc-theme-desktop-margin"
                    >
                        <FormattedMessage
                            id="LoginPage.Title"
                        />
                    </Typography>
                    <Loader type={Loader.TYPE_CENTER} />
                    <BaseDialog isOpen={showStopLogin}>
                        <Typography
                            use="headline2"
                            tag="h2"
                            className="mdc-typography mdc-theme--primary mdc-theme-desktop-margin"
                        >
                            <FormattedMessage
                                id="LoginPage.UpdateApp.Title"
                            />
                        </Typography>
                        <FormattedMessage
                            id="LoginPage.UpdateApp.Description"
                        />
                    </BaseDialog>
                </GridCell>
            </Grid>
        );
    }

    return (
        <BaseLogin>
            {renderTitle()}
        </BaseLogin>
    )
}

export default Login;
