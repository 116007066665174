import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "@rmwc/button";
import { Grid, GridCell } from "@rmwc/grid";
import Checkbox from '@material/react-checkbox';
import TextFieldGroup from "../common/TextFieldGroup";
import IdCodeValidatorRule from "../../validators/IdCodeValidatorRule";
import EeIdCodeValidator from "../../validators/EeIdCodeValidator";
import { postAddCustomer, postBindBonuscard } from "../../actions/digitalStampsActions";
import { useValidator } from "../../helpers/hooks";
import AlertMessage from "../common/AlertMessage";

const fieldSsn = "ssn";
const fieldName = "name";
const fieldLastName = "lastName";
const fieldPhoneNumber = "phonenumber";
const fieldEmail = "email";
const fieldMarketing = "marketing";
const fieldStationId = "stationId";
const fieldBonuscardNumber = "bonuscardNumber"

const INITIAL_STATE = {
    [fieldSsn]: "",
    [fieldName]: "",
    [fieldLastName]: "",
    [fieldEmail]: "",
    [fieldPhoneNumber]: "",
    [fieldStationId]: null,
    [fieldBonuscardNumber]: "",
    [fieldMarketing]: false
};

const DigitalStampsClientCustomerCreationForm = ({ personalCode, stationId, bonuscardNumber, onClose }) => {
    const [formData, setFormData] = useState(INITIAL_STATE);

    const dispatch = useDispatch();
    const [validator,] = useValidator({
        ...IdCodeValidatorRule.rule(),
        tos: {
            message: "*",
            rule: (val) => {
                return val;
            }
        }
    });

    useEffect(() => {
        const idCodeValidator = new EeIdCodeValidator();

        if (idCodeValidator.validate(personalCode)) {
            setFormData(prev => ({ ...prev, [fieldSsn]: personalCode }));
        }
    }, [personalCode]);

    useEffect(() => {
        if (stationId) {
            setFormData(prev => ({ ...prev, [fieldStationId]: stationId }));
        }
    }, [stationId]);

    useEffect(() => {
        if (bonuscardNumber) {
            console.debug("Setting bonuscard number to form", bonuscardNumber);
            setFormData(prev => ({ ...prev, [fieldBonuscardNumber]: bonuscardNumber }));
        }
    }, [bonuscardNumber]);

    const submit = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        if (formData[fieldBonuscardNumber].length === 16) {
            dispatch(postBindBonuscard(formData));
        }
        else {
            dispatch(postAddCustomer(formData));
        }

        setFormData(INITIAL_STATE);

        onClose?.(formData[fieldSsn]);
    }

    const handleChange = (e) => {
        let { name, value, checked } = e.target;

        value = value.trim();

        if (name === fieldMarketing) {
            value = checked;
        }

        const statusCopy = { ...formData };
        statusCopy[name] = value;
        setFormData(statusCopy);
    }

    return (
        <Grid className="form--main">
            <GridCell span={12}>
                <AlertMessage
                    isSmall
                    type={AlertMessage.TYPE_WARN}
                    title={<FormattedMessage id="DigitalStampsClient.Notice.NoAlexelaData" />}
                />
            </GridCell>
            <GridCell span={6}>
                <TextFieldGroup
                    field={fieldSsn}
                    onChange={handleChange}
                    validator={validator}
                    rules="required|idCode:EE"
                    value={formData[fieldSsn]}
                    label="DigitalStampsClient.PersonalCode"
                />
            </GridCell>
            <GridCell span={6}></GridCell>
            <GridCell span={6}>
                <TextFieldGroup
                    field={fieldName}
                    onChange={handleChange}
                    validator={validator}
                    rules={["required", { regex: /^[a-zа-я \-õüöäšž]+$/i }]}
                    value={formData[fieldName]}
                    label="DigitalStampsClient.Form.Name"
                />
            </GridCell>
            <GridCell span={6}>
                <TextFieldGroup
                    field={fieldLastName}
                    onChange={handleChange}
                    validator={validator}
                    rules={["required", { regex: /^[a-zа-я \-õüöäšž]+$/i }]}
                    value={formData[fieldLastName]}
                    label="DigitalStampsClient.Form.LastName"
                />
            </GridCell>
            <GridCell span={6}>
                <TextFieldGroup
                    field={fieldEmail}
                    onChange={handleChange}
                    validator={validator}
                    rules={["required", { min: 5 }, { regex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@((?:(?!alexela.ee).))[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ }]}
                    value={formData[fieldEmail]}
                    label="DigitalStampsClient.Form.Email"
                />
            </GridCell>
            <GridCell span={6}>
                <TextFieldGroup
                    field={fieldPhoneNumber}
                    onChange={handleChange}
                    validator={validator}
                    rules={["required", { min: 7 }, { max: 8 }, { regex: /^\d+$/ }]}
                    value={formData[fieldPhoneNumber]}
                    label="DigitalStampsClient.Form.Phone"
                />
            </GridCell>
            <GridCell span={12}>
                <div className="mdc-form-field field mt-20">
                    <Checkbox
                        field={fieldMarketing}
                        nativeControlId={fieldMarketing}
                        name={fieldMarketing}
                        checked={formData[fieldMarketing]}
                        indeterminate={false}
                        onChange={handleChange}
                    />
                    <label htmlFor={fieldMarketing}>
                        <FormattedMessage id="DigitalStampsClient.Form.Marketing" />
                    </label>
                </div>
            </GridCell>
            <GridCell span={12}>
                <Button unelevated onClick={submit}>
                    <FormattedMessage id="DigitalStampsClient.Form.Create" />
                </Button>
            </GridCell>
        </Grid>
    );
}

export default DigitalStampsClientCustomerCreationForm;
