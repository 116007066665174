import React from "react";
import { useSelector } from "react-redux";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { Typography } from "@rmwc/typography";
import { isWebview } from "../../helpers/appContainerHelper";
import { IsNewMobileAppWebView } from "../../helpers/AlternativeDesign";

const DebugVariables = () => {

    const appContainer = useSelector(state => state.appContainer);

    const getPlatform = () => {
        if (isAndroid) {
            return "Android";
        }

        return isIOS ? "iOS" : "-";
    }

    return <>
        <Typography use="headline4" className="mdc-typography mt-12 mb-12">window muutujad</Typography>
        <div className="pre word-wrap">
            isMobile: {!isMobile ? "false" : `true (${getPlatform()})`}
            <br />
            isNewAppWebView: {IsNewMobileAppWebView().toString()}
            <br />
            --- ( window || localstorage )
            <br />
            window.UsingApp: {(window.UsingApp || "undefined").toString()} || {localStorage.getItem("usingapp")}
            <br />
            window.AppVer2: {(window.AppVer2 || "undefined").toString()} || {localStorage.getItem("usingnewestapp")}
            <br />
            window.AppVersion: {window.AppVersion || "undefined"} || {localStorage.getItem("appVersion")}
            <br />
            ---
            <br />
            window.NotificationHub.InstallationId:  {((!!window.NotificationHub && window.NotificationHub.InstallationId) || "undefined").toString()}
            <br />
            window.NotificationHub.PushChannel: {((!!window.NotificationHub && window.NotificationHub.PushChannel) || "undefined").toString()}
        </div>

        <Typography use="headline4" className="mdc-typography mt-12 mb-12">state.appContainer</Typography>
        <div className="pre word-wrap">
            {JSON.stringify(appContainer, null, 2)}
        </div>

        <Typography use="headline4" className="mdc-typography mt-12 mb-12">Muud</Typography>
        <div className="pre word-wrap">
            UserAgent: {window.navigator.userAgent}
            <br />
            Is standalone: {window.navigator.standalone ? "true" : "false"}
            <br />
            Is webview (from ua string): {isWebview() ? "true" : "false"}
        </div>
    </>
};

export default DebugVariables;