import React from "react";
import AlertMessage from "./AlertMessage";
import {FormattedMessage} from "react-intl";

class BaseFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onChangeByField = this.onChangeByField.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onAutoCompleteSelectChange = this.onAutoCompleteSelectChange.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onMultiCheckboxChange = this.onMultiCheckboxChange.bind(this);
        this.mapField = this.mapField.bind(this);
        this.getFormData = this.getFormData.bind(this);
        this.scrollToRef = this.scrollToRef.bind(this);
    }

    errors(errors, fallbackMessage) {
        return Array.isArray(errors) ? this.errorsList(errors) : this.technicalError(fallbackMessage);
    }

    errorsFromObject(errors, ref) {
        let errorsList = [];
        for (let key in errors) {
            if (errors.hasOwnProperty(key)) {
                if (errors[key]) {
                    errorsList.push(<li key={key}>{errors[key]}</li>)
                }
            }
        }

        if (errorsList.length < 1) {
            return;
        }

        return (<AlertMessage className="mb-15" inneRref={ref} type={AlertMessage.TYPE_ALERT} scrollToMessage={true}>
            <ul>
                {errorsList}
            </ul>
        </AlertMessage>);
    }

    technicalError(fallbackMessage) {
        return <AlertMessage className="mb-15" type={AlertMessage.TYPE_ALERT}
                             scrollToMessage={true}
                             title={<FormattedMessage id={fallbackMessage || "Error.Technical"}/>}/>
    }

    errorsList(errors) {
        if (errors.length < 1) {
            return;
        }

        let errorsList = errors.map((error, i) => <li key={i}>{error.errorMessage}</li>);

        return (
            <AlertMessage className="mb-15" type={AlertMessage.TYPE_ALERT} scrollToMessage={true}>
                <ul>
                    {errorsList}
                </ul>
            </AlertMessage>
        );
    }

    getFormData(e) {
        e.preventDefault();
        let data = {};

        if (!this.props.formFields) {
            return data;
        }

        this.props.formFields.map(field => (data = this.mapField(field, data)));

        return data;
    }

    getData() {
        let data = {};

        if (!this.props.formFields) {
            return data;
        }

        this.props.formFields.map(field => (data = this.mapField(field, data)));

        return data;
    }

    mapField(fieldName, result) {
        let keys = fieldName.split('.');
        let value = this.state;

        keys.reduce(function (r, e, i, arr) {
            r[e] = (r[e] || (arr[i + 1] ? {} : value[keys[i]]));
            value = value[keys[i]];
            return r[e];
        }, result);

        return result;
    }

    closeDialog(name) {
        this.updateState(name, false);
    }

    scrollToRef(ref) {
        ref.current.scrollIntoView({behavior: 'smooth'})
    }

    openDialog(name, e) {
        e.preventDefault();
        e.stopPropagation();
        this.updateState(name, true);
    }

    onMultiCheckboxChange(val, e, callback) {
        let name = e.target.name;
        let isChecked = e.target.checked;
        let selectedValues = [...this.state[name]];
        let index = selectedValues.indexOf(val);

        if (!isChecked) {
            if (index > -1) {
                selectedValues.splice(index, 1)
            }
        } else if (index > -1) {
            return;
        } else {
            selectedValues.push(val);
        }

        this.updateState(name, selectedValues, callback)
    }

    onChange(e, callback) {
        let name = e.target.name;
        let val = e.target.value;
        this.updateState(name, val, callback);
    }

    onChangeByField(field, value) {
        this.updateState(field, value);
    }

    onChangeCheckbox(e) {
        let name = e.target.name;
        let val = e.target.checked;

        this.updateState(name, val);
    }

    onAutoCompleteSelectChange(field, item) {
        this.updateState(field, item);
    }

    onSelectChange(afterChange, field, e) {
        let currentValue = e.currentTarget.value;
        if (afterChange) {
            this.updateState(field, currentValue, afterChange.bind(this, currentValue));
        } else {
            this.updateState(field, currentValue);
        }
    }

    mergeValue(name, val) {
        let keys = name.split('.');
        let stateCopy = Object.assign({}, this.state);

        if (keys.length === 2) {
            stateCopy[keys[0]][keys[1]] = val;
        } else {
            stateCopy[name] = val;
        }

        return stateCopy;
    }


    updateState(name, val, callback) {
        let stateCopy = this.mergeValue(name, val);

        this.setState(stateCopy, callback);
    }

    updateStateValues(states) {
        let stateCopy = Object.assign({}, this.state);

        for (const key in states) {
            stateCopy[key] = states[key];
        }

        this.setState(stateCopy);
    }
}

export default BaseFormComponent;