import React from "react";
import { useSelector } from "react-redux";
import { Grid, GridCell } from "@rmwc/grid";
import Header from "../../components/layout/Header";
import HeroSection from "../../components/layout/HeroSection";
import { Redirect, useLocation } from 'react-router-dom';
import auth from "../../auth/authenticate";
import { StartPath } from "../../const/routes";
import { FormattedMessage } from "react-intl";

const BaseLogin = ({ children, redirectTo, disableRedirect = false }) => {
    const location = useLocation();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    if (!disableRedirect && isLoggedIn && auth.isLoggedIn() && !auth.isPassive()) {
        if (redirectTo) {
            return <Redirect to={redirectTo}/>;
        }

        if (location.state === undefined || location.state.from === undefined || location.state.from.pathname === undefined) {
            return <Redirect to={StartPath} />;
        }

        return <Redirect to={location.state.from.pathname + (location.state.from.search || "")} />;
    }

    return (
        <div>
            <Header />
            <HeroSection title={<FormattedMessage id="TopContent.Title" />} />
            <Grid className="mdc-layout-grid--base">
                <GridCell span={12}>
                    <main>
                        {children}
                    </main>
                </GridCell>
            </Grid>
        </div>
    );
}

export default BaseLogin;
