import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { Typography } from "@rmwc/typography";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { fetchCustomerContribution } from "../../actions/communityProjectActions";
import LinkToCommunityPage from "../CommunityProject/LinkToCommunityPage";
import { getDaysSince } from "../../helpers/dateHelper";
import BaseFormComponent from "../common/BaseFormComponent";
import UserContributionFilter from "./UserContributionFilter";
import auth from "../../auth/authenticate";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import ContributionSection from "./ContributionSection";

const BONUSCARDS_LABEL = "Community.Contribution.CardList.BonusCards";
const PAYMENTCARD_LABEL = "Community.Contribution.CardList.Item";
const BONUSCARD = "bonuscard";

class UserContribution extends BaseFormComponent {
    constructor(props) {
        super(props);

        this.state = {
            isCompany: auth.isCompany() || false,
            selectedContribution: null,
            contractDropdownOptions: []
        }

        this.onChangeContributionContract = this.onChangeContributionContract.bind(this);
    }

    componentDidMount() {
        if (!this.props.customerContributionFetching && !this.props.customerContributionFetched) {
            this.props.fetchCustomerContribution();
        }

        if (this.state.isCompany) {
            this.setContractOptions();
        }
    }

    setContractOptions() {
        if (this.props.customerContributionFetched) {
            let activeContracts = this.props.customerContribution.contracts
                .filter(c => c.isActive === true)
                .map((c, i) => {
                    return {
                        label: this.props.intl.formatMessage({ id: PAYMENTCARD_LABEL }, { num: c.extraNumber }),
                        value: c.contractNumber,
                        selected: i === 0
                    };
                });

            if (this.props.customerContribution.bonusCard.isActive) {
                activeContracts = activeContracts.concat([{
                    label: this.props.intl.formatMessage({ id: BONUSCARDS_LABEL }),
                    value: BONUSCARD,
                    selected: false
                }]);
            }

            this.setState({ contractDropdownOptions: activeContracts });

            if (isNullOrEmpty(activeContracts)) {
                return;
            }

            this.setState({selectedContribution: activeContracts[0].value});
        }
    }

    onChangeContributionContract(contractNumber) {
        this.setState({ selectedContribution: contractNumber });
    }

    renderCommunityProjectContribution = () => {
        const { customerContribution, customerContributionFetched, showLink, showInfo, titleId } = this.props;

        const contribution = !this.state.isCompany || this.state.selectedContribution === BONUSCARD
            ? customerContribution?.bonusCard
            : customerContribution?.contracts.find(c => c.contractNumber === parseInt(this.state.selectedContribution)) || null;

        const daysSince = contribution?.isActive ? getDaysSince(contribution.participationStart) : 0;

        const durationProps = {
            iconClassName: "icon-duration",
            number: daysSince.toString(),
            numberUnit: "Community.Unit.Day",
            title: "Community.Title.Member",
            info: showInfo && "Community.Info.Member"
        };

        const treesProps = {
            iconClassName: "icon-trees",
            number: contribution?.treesPlanted || '0',
            title: "Community.Title.PlantedTrees",
            info: showInfo && "Community.Info.PlantedTreesByYou",
        };

        const treeHomeProps = {
            iconClassName: "icon-location",
            name: contribution?.county || "-",
            title: "Community.Title.MyTreeHome",
            info: showInfo && "Community.Info.MyTreeHome",
        };

        return (
            <Grid className="mdc-layout-grid--base mb-60">
                <GridCell span={12}>
                    {showLink && customerContributionFetched &&
                        <LinkToCommunityPage isUserJoined={contribution?.isActive === true} />
                    }
                    <GridInner>
                        <GridCell span={6} phone={12} align="middle">
                            <Typography
                                use="headline3"
                                tag="h3"
                                className="mdc-typography mdc-theme--primary mb-mobile-15"
                            >
                                <FormattedMessage id={titleId} />
                            </Typography>
                        </GridCell>
                        <GridCell span={3} phone={12} className="contribution-filter">
                            <UserContributionFilter
                                selected={this.state.selectedContribution}
                                options={this.state.contractDropdownOptions}
                                onChange={this.onChangeContributionContract}
                            />
                        </GridCell>
                    </GridInner>
                </GridCell>

                <ContributionSection
                    firstProps={durationProps}
                    secondProps={treesProps}
                    thirdProps={treeHomeProps}
                />
            </Grid>
        );
    }

    render() {
        return (
            this.renderCommunityProjectContribution()
        );
    }
}

UserContribution.defaultProps = {
    showInfo: true,
    titleId: "Community.Me"
}

UserContribution.propTypes = {
    showLink: PropTypes.bool,
    showInfo: PropTypes.bool,
    titleId: PropTypes.string,
}

function mapStateToProps(state) {
    return {
        customerContributionFetching: state.customerContribution.fetching,
        customerContributionFetched: state.customerContribution.fetched,
        customerContribution: state.customerContribution.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCustomerContribution,
    }, dispatch);
}

export default injectIntl(
    withRouter(
        connect(mapStateToProps, matchDispatchToProps)(UserContribution)
    )
);
