import {Grid, GridCell} from "@rmwc/grid";
import TableList from "../common/TableList";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import Loader from "../common/Loader";
import React, {useEffect, useRef, useState} from "react";
import {fetchSmsMessageLog} from "../../actions/notificationActions";
import Pagination from "../common/Pagination";
import auth from "../../auth/authenticate";

export const MarketingSmsSentSmsesList = () => {
    const smsMessagesLog = useSelector(state => state.marketingSmsMessageLog);
    const sendSms = useSelector(state => state.sendMarketingSms);
    const dispatch = useDispatch();
    const scrollToRef = useRef();

    const [filter, setFilter] = useState({
        page: 1,
        pageSize: auth.getPageSize(),
    });

    const TableHeaders = [
        {label: "MarketingSmsSentSmsesList.TableHeader.DateAdded"},
        {label: "MarketingSmsSentSmsesList.TableHeader.AddedBy"},
        {label: "MarketingSmsSentSmsesList.TableHeader.SmsContent"},
        {label: "MarketingSmsSentSmsesList.TableHeader.SentCount"}
    ];

    const refresh = () => {
        dispatch(fetchSmsMessageLog(filter.page, filter.pageSize));
    };

    const onPageChange = ({ page, pageSize }) => {
        setFilter({ ...filter, page: page, pageSize: pageSize });
    }

    useEffect(() => {
        if (!smsMessagesLog.fetching && !smsMessagesLog.fetched && !smsMessagesLog.error) {
            refresh();
        }
    });

    useEffect(() => {
        refresh();
    }, [sendSms.fetched, filter])

    const getTableRow = (item) => {
        return {
            content: [
                {content: moment(item.createdAt).format("DD. MMM yyyy HH:mm")},
                {content: item.createdByFullName},
                {content: item.content},
                {content: item.sentCount}
            ]
        }
    };

    return (
        <Grid>
            {smsMessagesLog.fetching && <Loader type={Loader.TYPE_COVER}/>}

            <GridCell span={12} ref={scrollToRef}>
                {smsMessagesLog.fetched &&
                    <TableList
                        headers={TableHeaders}
                        itemContent={smsMessagesLog.data.map(getTableRow)}
                    />}
            </GridCell>
            <GridCell span={12}>
                <Pagination
                    total={smsMessagesLog.total || 0}
                    selectedPage={filter.page}
                    onChange={onPageChange}
                    scrollToRef={scrollToRef} />
            </GridCell>
        </Grid>
    )
}