import {Grid, GridCell} from "@rmwc/grid";
import {Button} from "@rmwc/button";
import {FormattedMessage} from "react-intl";
import {MarketingSmsTemplateDialog} from "./MarketingSmsTemplateDialog";
import {fetchSmsTemplates} from "../../actions/notificationActions";
import {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {MarketingSmsTemplatesList} from "./MarketingSmsTemplatesList";

export const MarketingSmsTemplates = () => {
    const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const dialogRef = useRef();

    const createNewTemplate = () => {
        if (dialogRef.current) {
            dialogRef.current.reset();
            setIsTemplateDialogOpen(true);
        }
    };

    const editTemplate = (item) => {
        if (dialogRef.current) {
            dialogRef.current.setContent(item);
            setIsTemplateDialogOpen(true);
        }
    };

    const onClose = () => {
        setIsTemplateDialogOpen(false);
        dispatch(fetchSmsTemplates());
    }

    return (
        <>
            <Grid>
                <GridCell span={12}>
                    <MarketingSmsTemplatesList onEditButtonClick={editTemplate}/>
                </GridCell>
                <GridCell span={12}>
                    <Button unelevated className="right" onClick={createNewTemplate}>
                        <FormattedMessage id="MarketingSmsMessages.CreateNewTemplate"/>
                    </Button>
                </GridCell>
            </Grid>

            <MarketingSmsTemplateDialog isOpen={isTemplateDialogOpen} onClose={onClose} ref={dialogRef}/>
        </>
    )
}