import {CardApplicationType} from "../const/cardApplication";
import {
    getCardContractOfferPath,
    getCylinderGasContractOfferPath,
    getEvChargingContractOfferPath, StartPath
} from "../const/routes";

const getPathName = (type, id) => {
    switch (type) {
        case CardApplicationType.OFFER:
            return getCardContractOfferPath(id);
        case CardApplicationType.CYLINDER_GAS_OFFER:
            return getCylinderGasContractOfferPath(id);
        case CardApplicationType.EV_CHARGING_OFFER:
            return getEvChargingContractOfferPath(id);
        default:
            return StartPath;
    }
}
export default getPathName;