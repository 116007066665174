import React from "react";
import ContactChange from "../components/Settings/ContactChange";
import { fetchContact } from "../actions/accountActions";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../styles/react-components/overview.scss";
import { SettingsPath, StartPath } from "../const/routes";
import { Redirect } from 'react-router-dom';
import auth from "../auth/authenticate";

class MainContact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contactId: (props.location && props.location.state && props.location.state.params.contactId) || null,
            redirect: false,
            requestedLocation: (props.location && props.location.state && props.location.state.params.requestedLocation) || null
        };
        this.afterSave = this.afterSave.bind(this);
    }

    afterSave() {
        auth.setContactDataUpdated();
        this.setState({ redirect: true});
    }

    componentDidMount() {
        if ( this.state.contactId && (!this.props.isContactLoaded || this.props.contact.crmId !== this.state.contactId) && !this.props.isContactLoading) {
            this.props.fetchContact(this.state.contactId);
        }
    }

    render() {
        if (this.state.redirect) {
            this.state = {
                ...this.state,
                redirect: false,
                contactId: null,
            }
            return (<Redirect to={{
                pathname: this.state.requestedLocation || StartPath,
                state: { params: { finishedContactSaving: true } }
            }} />)
        }
        if (!this.state.contactId) {
            return (<Redirect to={{
                pathname: SettingsPath
            }} />)
        }
        return (
            <React.Fragment>
                {this.props.isContactLoaded && this.props.contact &&
                    <ContactChange contact={this.props.contact}
                        finish={this.afterSave}
                        title={"Settings.User.ValidateContactData"}
                    />
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        contact: state.contact.data,
        isContactLoaded: state.contact.fetched,
        isContactLoading: state.contact.fetching,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchContact
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(MainContact)
);
