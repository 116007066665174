import { ContractElectricityPath, ContractGasPath } from "../const/routes";

import { Electricity } from "../const/contractType";

export function isLinkActive(currentPath, path, children) {
    return currentPath === path || (children && children.some(x => x.path === currentPath));
};

export function GetContractLink(contractType) {
    return contractType === Electricity ? ContractElectricityPath : ContractGasPath;
}

export const getMenuIndex = (menuItems, path) => {
    for (let i = 0; i < menuItems.length; i++) {
        const index = getMenuIndexUtil(menuItems[i], path, i);
        if (index > -1) return index;
    }

    return -1;
}

const getMenuIndexUtil = (currentChild, path, index) => {
    if (currentChild.path === path || (currentChild.path !== "/" && path.includes(currentChild.path))) return index;
    if (!currentChild.children) return -1;

    for (let child of currentChild.children) {
        if (getMenuIndexUtil(child, path, index) > -1) {
            return index;
        }
    }

    return -1;
}