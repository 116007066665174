import React from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LinkElement from "../../common/LinkElement";
import { Grid, GridCell } from "@rmwc/grid";
import { injectIntl, FormattedMessage } from "react-intl";
import { Typography } from "@rmwc/typography";
import "../../../styles/react-components/footer.scss";
import { fetchFooter } from "../../../actions/menuActions";
import isoLogo from "../../../images/iso.png";
import { isNullOrEmpty } from "../../../helpers/objectHelper";

class FooterMenu extends React.Component {
    componentDidMount() {
        if (!this.props.fetching && !this.props.fetched) {
            this.props.fetchFooter();
        }
    }

    renderLink = (target, message, type, values = null) => {
        return target ? (
            <LinkElement href={target} target="_blank" rel="noopener noreferrer">
                <Typography use={type} className="footer-item">
                    {message !== null &&
                        <FormattedMessage id={message} values={values} />
                    }

                </Typography>
            </LinkElement>
        ) : (
            <Typography use={type} className="footer-item">
                {message !== null &&
                    <FormattedMessage id={message} values={values} />
                }
            </Typography>
        );
    };

    renderSectionTitle = (index) => {
        switch (index) {
            case 0:
                return <FormattedMessage id="Footer.Menu.CustomerSupport" />;
            case 1:
                return <FormattedMessage id="Footer.Menu.AboutUs" />;
            case 2:
                return <FormattedMessage id="Footer.Menu.FollowUs" />;
            default:
                return null;
        }
    }

    renderSocialMediaIcon = (href, label) => {
        let socialMediaTitle = label?.toLowerCase();
        if (!socialMediaTitle) {
            return null;
        }

        return (
            <a href={href} key={label} className="footer-social-media-item" target="_blank" rel="noopener noreferrer">
                <i className={`icon-${socialMediaTitle}`} />
                <p>{label}</p>
            </a>
        );
    }

    render() {
        const { data } = this.props;

        const footerTiles = !isNullOrEmpty(data) ? data.slice(1).filter((x) => x.children && x.children.length > 0) : [];
        let privacyLink = (!isNullOrEmpty(data) && data.length > 0) ? data[data.length - 1] : {};
        if (privacyLink && !privacyLink.label) {
            // placeholder if CMS sends an empty label
            privacyLink.label = this.props.intl.formatMessage({ id: "General.Privacy" });
        }
        const contactLocation = (!isNullOrEmpty(data) && data[0]) || "";

        return (
            <>
                {this.props.fetched &&
                    <div className="footer__menu hidden-tablet">
                        <Grid>
                            <GridCell span={3}>
                                <div className="footer-icon"></div>
                                {this.renderLink(null, contactLocation.children[0].label, "body2")}
                                {this.renderLink(null, "Footer.Menu.AlexelaRegCode2", "body2", {regCode: contactLocation.children[1].label})}
                                {this.renderLink(null, "Footer.Menu.AlexelaVatCode", "body2", {vatCode: contactLocation.children[2].label})}
                            </GridCell>
                            {footerTiles.map((navigation, index) => (
                                <GridCell desktop={3} tablet={2} key={"navigation" + index}>
                                    {navigation.children &&
                                        <Typography className="footer-section-title" use="subtitle1">
                                            {this.renderSectionTitle(index)}
                                        </Typography>
                                    }
                                    <div className={index === 2 ? "footer-social-media" : ""}>
                                        {navigation.children &&
                                            navigation.children.map((subnavigation, childIndex) => {
                                                return (
                                                    <React.Fragment key={childIndex + "link" + index}>
                                                        {index === 2
                                                            ? this.renderSocialMediaIcon(subnavigation.href, subnavigation.label)
                                                            : this.renderLink(subnavigation.href, subnavigation.label, "body2")}
                                                    </React.Fragment>
                                                );
                                            })}
                                    </div>
                                </GridCell>
                            ))}
                        </Grid>
                    </div>
                }

                {privacyLink && (
                    <Grid className="pt-10 hidden-tablet pb-10">
                        <GridCell className="footer-bottom" span={12}>
                            <div className="footer-bottom__item">
                                <Typography use="body2">
                                    <FormattedMessage id="General.CopyRight" />
                                </Typography>
                            </div>
                            <div className="footer-bottom__item">
                                <LinkElement href={privacyLink.href} target="_blank" rel="noopener noreferrer">
                                    <Typography use="body2" className="footer-bottom__item--privacy">
                                        {privacyLink.label !== null &&
                                            <FormattedMessage id={privacyLink.label} />
                                        }
                                    </Typography>
                                </LinkElement>
                            </div>
                            <div className="footer-bottom__item">
                                <img className="footer-logo" alt="ISO 9001" src={isoLogo} />
                            </div>
                        </GridCell>
                    </Grid>
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        fetching: state.footer.fetching,
        fetched: state.footer.fetched,
        data: state.footer.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchFooter }, dispatch);
}

export default injectIntl(
    withRouter(connect(mapStateToProps, matchDispatchToProps)(FooterMenu))
);
