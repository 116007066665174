import React from "react";
import { Grid, GridInner, GridCell } from "@rmwc/grid";
import { FormattedMessage, injectIntl } from "react-intl";
import { Typography } from "@rmwc/typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from '@rmwc/button';
import { fetchAccount, fetchContacts, putAccount, putContacts } from "../actions/accountActions";
import BaseFormComponent from "../components/common/BaseFormComponent";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import StaticField from "../components/common/StaticField";
import classnames from 'classnames';
import SimpleReactValidator from "simple-react-validator";
import { ContactPath } from "../const/routes";
import { Link } from "react-router-dom";
import StockExchangePriceAlertSettings from "../components/Settings/StockExchangePriceAlertSettings";
import NotificationSettings from "../components/Settings/NotificationSettings";
import { IsLatvianWeb } from "../helpers/AlternativeDesign";
import auth from "../auth/authenticate";
import CachedFilterSettings from "../components/Settings/CachedFilterSettings";
import ConfirmDialog from "../components/common/ConfirmDialog";
import CreateContactDialog from "../components/Settings/CreateContactDialog";
import AddressFormExtended from "../components/AddressForm/AddressFormExtended";
import { areEqual } from "../helpers/objectHelper";
import PaperReceiptSettings from "../components/Settings/PaperReceiptSettings";
import {Feature} from "flagged";
import {PrintPaperReceiptSetting} from "../const/featureFlags";

class UserSettings extends BaseFormComponent {
    static FieldTerms = "acceptedTerms";

    constructor(props) {
        super(props);

        this.state = {
            [UserSettings.FieldTerms]: false,
            showErrors: false,
            finishedContactSaving: props.location?.state?.params?.finishedContactSaving || false,
            address: {},
            isManualAddressInput: false,
            showAddressResponse: false,
            isAdmin: auth.isAdmin(),
            isCompany: auth.isCompany(),
            isConfirmDialogOpen: false,
            crmIdToBeDeleted: null,
            isCreateContactDialogOpen: false,
            isLatvianWeb: IsLatvianWeb()
        };

        this.renderCardOwnerBlock = this.renderCardOwnerBlock.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.submit = this.submit.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
        this.uiErrorsRef = React.createRef();
    }

    componentDidMount() {
        this.props.fetchContacts();
        this.props.fetchAccount()
            .then(({ value }) => this.setState({ address: value.data.address }));
    }

    componentDidUpdate(prevProps, prevState) {
        if (!areEqual(prevProps.account.address, this.props.account.address)) {
            this.setState({
                address: this.props.account.address
            });
        }

        if (prevProps.isPutAccountLoading && !this.props.isPutAccountLoading) {
            this.setState({
                showAddressResponse: true
            });
        }
    }

    submit() {
        this.setState({
            showErrors: false
        });
        if (this.props.isPutAccountLoading) {
            return;
        }
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.setState(
                { showErrors: true },
                this.scrollToRef.bind(this, this.uiErrorsRef)
            );
            return;
        }

        const body = {
            LegalAddressCountry: this.state.isLatvianWeb ? "Latvija" : "Eesti",
            LegalAddressCity: this.state.address.city,
            LegalAddressState: this.state.address.county,
            LegalAddressStreet: this.state.address.street,
            LegalAddressCode: this.state.address.postalCode,
            LegalAddressHouseNo: this.state.address.houseNr,
            LegalAddressAppartmentNo: this.state.address.apartmentNr,
            LegalAddressParish: this.state.address.parish,
            GroupId: this.props.account.crmId
        };

        this.props.putAccount(body)
            .then(() => {
                this.scrollToRef.bind(this, this.uiErrorsRef)
            });
    }

    onConfirmContactDelete() {
        this.setState({ isConfirmDialogOpen: false });

        const contact = this.state.contactToBeDeleted;
        contact.isPassive = true;
        contact.id = contact.crmId;

        this.props.putContacts(contact)
            .then(() => {
                this.props.fetchContacts();
                this.scrollToRef.bind(this, this.uiErrorsRef);
            });
    }

    onContactCreated() {
        this.setState({ isCreateContactDialogOpen: false });
        this.props.fetchContacts();
    }

    onOpenConfirmDialog(contact) {
        this.setState({
            isConfirmDialogOpen: true,
            contactToBeDeleted: contact
        });
    }

    updateAddress(address) {
        this.setState({ address });
    }

    get contacts() {
        return this.props.contacts && this.props.account.companyCode
            ? Object.entries(this.props.contacts)
                .filter(([key, value]) => this.state.isCompany || value.idCode === this.props.account.companyCode)
            : [];
    }

    renderCardOwnerBlock() {
        return (
            <GridCell>
                <GridInner
                    className={classnames(
                        "mdc-layout-grid--base mdc-layout-grid--form",
                        {
                            "loader loader--background loader--light": this
                                .props.isAccountLoading
                        }
                    )}
                >
                    <StaticField
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-3-desktop"
                        value={this.props.account.name}
                        label={
                            <FormattedMessage id="Settings.User.Name" />
                        }
                    />
                    <StaticField
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-3-desktop"
                        value={this.props.account.companyCode}
                        label={
                            <FormattedMessage id="Settings.User.IdentificationCode" />
                        }
                    />
                </GridInner>
            </GridCell>
        );
    }

    renderContactRow(contact, crmId) {
        return (
            <tr key={crmId}>
                <td className="wrap">
                    {`${contact.firstName} ${contact.lastName}`}
                </td>
                <td className="icon icon-user-mobile">
                    {contact.idCode || "-"}
                </td>
                <td className="icon icon-mail">
                    {contact.email}
                </td>
                <td className="icon icon-phone">
                    {contact.mobile}
                </td>
                <td>
                    {this.state.isCompany ?
                        <Button
                            className="p-0 mdc-button--text"
                            trailingIcon={{
                                icon: "close",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-",
                                className: "icon-red"
                            }}
                            onClick={() => this.onOpenConfirmDialog(contact)}
                        >
                            <FormattedMessage id="Settings.User.DeleteContact" />
                        </Button> :
                        <Link
                            onClick={() => this.setState({ contactId: crmId })}
                            to={{
                                pathname: ContactPath,
                                state: { params: { contactId: crmId, contact: contact } }
                            }}
                        >
                            <FormattedMessage id="Settings.User.ChangeInfo" />
                        </Link>
                    }
                </td>
            </tr>
        );
    }

    render() {
        const isLoading = this.props.areContactsFetching || this.props.putContactsLoading;

        return (
            <Grid>
                <GridCell span={12}>
                    {this.state.showErrors &&
                        this.errorsFromObject(
                            this.validator.getErrorMessages(),
                            this.uiErrorsRef
                        )
                    }
                    <Typography
                        use="headline3"
                        className="mdc-typography mdc-theme--primary mb-25"
                    >
                        <FormattedMessage id="Settings.User.MyInfo" />
                    </Typography>
                    {this.state.showAddressResponse && this.props.putAccountError && this.errors(this.props.putAccountError, "Settings.User.Error")}
                    {this.state.showAddressResponse && this.props.isPutAccountLoaded &&
                        <AlertMessage
                            type={AlertMessage.TYPE_DONE}
                            scrollToMessage={true}
                            isSmall={true}
                            title={<FormattedMessage id="Settings.User.Saved" />}
                            className="mb-15" />
                    }
                    {this.renderCardOwnerBlock()}
                </GridCell>
                {this.props.isAccountLoaded &&
                    <GridCell span={12}>
                        <AddressFormExtended
                            className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
                            rules="required"
                            onChange={this.updateAddress}
                            address={this.state.address}
                            validator={this.validator}
                            isManualInsert={this.state.isManualAddressInput}
                        />
                    </GridCell>
                }
                <GridCell span={12} className="d-flex align-items-center justify-content-between mb-35">
                    <Button
                        unelevated
                        disabled={!this.props.isAccountLoaded}
                        onClick={this.submit}>
                        {this.props.isPutAccountLoading
                            ? <Loader type={Loader.TYPE_BUTTON} />
                            : <FormattedMessage id="Settings.User.Save" />
                        }
                    </Button>
                    <Button
                        onClick={() => this.setState({ isManualAddressInput: !this.state.isManualAddressInput})}
                        trailingIcon={{
                            icon: this.state.isManualAddressInput ? "search" : "write",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                        }}
                    >
                        <FormattedMessage id={
                            this.state.isManualAddressInput
                                ? "AddressForm.AddSearch"
                                : "AddressForm.AddManually"
                            } />
                    </Button>
                </GridCell>
                    <GridCell span={12} className="mb-35">
                        <Typography
                            use="headline3"
                            className="mdc-typography mdc-theme--primary mb-25 d-flex justify-content-between"
                        >
                            <FormattedMessage id="Settings.User.Contacts" />
                            {this.state.isCompany && !isLoading &&
                                <Button
                                    outlined
                                    onClick={() => this.setState({ isCreateContactDialogOpen: true })}
                                    icon={{
                                        icon: "plus",
                                        strategy: "className",
                                        basename: "icon",
                                        prefix: "icon-"
                                    }}
                                >
                                    <FormattedMessage id="Settings.User.AddNewContact" />
                                </Button>
                            }
                        </Typography>

                        {isLoading && <Loader type={Loader.TYPE_CENTER} />}

                        {!isLoading && this.props.areContactsFetched && this.contacts.length > 0 &&
                            <>
                                {this.state.finishedContactSaving &&
                                    <AlertMessage
                                        type={AlertMessage.TYPE_DONE}
                                        scrollToMessage={true}
                                        isSmall={true}
                                        title={<FormattedMessage id="Settings.User.Saved" />}
                                        className="mb-15"
                                    />
                                }

                                <div className="table--scrollable-desktop mt-45 mt-mobile-30">
                                    <table className="table table--cards contacts">
                                        <thead className="hidden-mobile">
                                            <tr>
                                                <th>
                                                    <b>
                                                        <FormattedMessage id="Settings.User.Name" />
                                                    </b>
                                                </th>
                                                <th>
                                                    <b>
                                                        <FormattedMessage id="Settings.User.IdentificationCode" />
                                                    </b>
                                                </th>
                                                <th>
                                                    <b>
                                                        <FormattedMessage id="Settings.User.Email" />
                                                    </b>
                                                </th>
                                                <th>
                                                    <b>
                                                        <FormattedMessage id="Settings.User.Phone" />
                                                    </b>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.contacts.map(([key, value]) => this.renderContactRow(value, key))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                    </GridCell>

                {/* StockExchangePriceAlert*/}
                {this.props.isAccountLoaded && !this.state.isLatvianWeb &&
                    <GridCell span={12}>
                        <StockExchangePriceAlertSettings accountCrmId={this.props.account.crmId} />
                    </GridCell>
                }

                {/* Push notifications */}
                {!this.state.isLatvianWeb &&
                    <>
                        <GridCell span={12}>
                            <NotificationSettings/>
                        </GridCell>

                        <Feature name={PrintPaperReceiptSetting}>
                            <GridCell span={12}>
                                <PaperReceiptSettings/>
                            </GridCell>
                        </Feature>
                    </>
                }

                {/* Cached filter */}
                <GridCell span={12}>
                    <CachedFilterSettings />
                </GridCell>

                <ConfirmDialog
                    isOpen={this.state.isConfirmDialogOpen}
                    onClose={() => this.setState({ isConfirmDialogOpen: false })}
                    text={<FormattedMessage id="Settings.User.ConfirmDelete" />}
                    onAccept={() => this.onConfirmContactDelete()}
                    onReject={() => this.setState({ isConfirmDialogOpen: false })}
                />

                <CreateContactDialog
                    isOpen={this.state.isCreateContactDialogOpen}
                    onClose={() => this.setState({ isCreateContactDialogOpen: false })}
                    onSuccess={() => this.onContactCreated()}
                />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAccountLoading: state.account.fetching,
        isAccountLoaded: state.account.fetched,
        account: state.account.data,
        contacts: state.contacts.data,
        areContactsFetched: state.contacts.fetched,
        areContactsFetching: state.contacts.fetching,
        isPutAccountLoaded: state.putAccount.fetched,
        putAccountError: state.putAccount.error,
        isPutAccountLoading: state.putAccount.fetching,
        putContactsLoaded: state.putContacts.fetched,
        putContactsError: state.putContacts.error,
        putContactsLoading: state.putContacts.fetching
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAccount,
        fetchContacts,
        putAccount,
        putContacts
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(UserSettings)
);
