import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { fetchCountyContribution } from "../../actions/communityProjectActions";
import CountyMapSvg from "./CountyMapSvg";
import { injectIntl } from "react-intl";
import { Typography } from "@rmwc/typography";
import {coloredTreeRanges} from "./TreeRangeColor";

class CountyContributionMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            colorRanges: coloredTreeRanges
        };
    }

    componentDidMount() {
        if (!this.props.countyContributionStatisticsFetching && !this.props.countyContributionStatisticsFetched) {
            this.props.fetchCountyContribution().then(() => {
                if (this.props.countyContributionStatistics.colorScheme !== null) {
                    this.setState({ colorRanges: this.props.countyContributionStatistics.colorScheme });
                }
            });
        }
    }

    get counties() {
        return this.props.countyContributionStatistics.contributions
            ? Object.entries(this.props.countyContributionStatistics.contributions)
            : [];
    }
    get coloredRanges() {
        return Object.values(this.state.colorRanges).map((item) => ({
            label: item.label,
            from: item.range.from,
            to: item.range.to,
            color: item.colorHex
        }));
    }

    get coloredCounties() {
        const result = this.counties.reduce((arr, curr) => {
            const range = this.coloredRanges.filter(bObj => {
                return curr[1].treesPlanted >= bObj.from && curr[1].treesPlanted <= bObj.to;
            })[0]
            arr.push({ countyName: curr[1].countyName, treesPlanted: curr[1].treesPlanted, color: range.color });
            return arr;
            }, []);
            return result;
    }

    renderColorScale( range ) {
        return (
            <GridCell desktop={2} mobile={1} tablet={1}
                className="contribution-color-scale-item"
                key={range.from} style={{background:range.color}}>
                    <Typography
                        use="headline5"
                        tag="h3"
                        className="mdc-typography mb-mobile-15"
                    >
                        {range.label}
                    </Typography>
            </GridCell>
        );
    }

    render() {
        if (this.coloredCounties && this.coloredCounties.length > 0)
        {
            return (
                <Grid className="mdc-layout-grid--base mb-60">
                    <GridCell desktop={2} tablet={2} mobile={0}></GridCell>
                    <GridCell span={8}>
                        <CountyMapSvg
                            contributions={this.coloredCounties}
                            showTreeIcons={true}
                        />
                    </GridCell>
                    <GridCell span={12} className="mt-30">
                        <GridInner className="contribution-color-scale">
                            <GridCell desktop={1} tablet={2} mobile={0}></GridCell>
                                {this.coloredRanges.map((range) => this.renderColorScale(range))}
                            <GridCell desktop={1} tablet={1} mobile={0}></GridCell>
                        </GridInner>
                    </GridCell>
                </Grid>
           );
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        countyContributionStatisticsFetching: state.countyCommunityProjectStatistics.fetching,
        countyContributionStatisticsFetched: state.countyCommunityProjectStatistics.fetched,
        countyContributionStatistics: state.countyCommunityProjectStatistics.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCountyContribution
    }, dispatch);
}

export default injectIntl(
    withRouter(
        connect(mapStateToProps, matchDispatchToProps)(CountyContributionMap)
    )
);