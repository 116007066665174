import BusinessAPI from './BusinessApi';
import auth from '../auth/authenticate';

export const PUT_ALERT = 'PUT_ALERT';
export const FETCH_ALERT='FETCH_ALERT';
export const FETCH_ALERTS = 'FETCH_ALERTS';

export const fetchAlert = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: FETCH_ALERT,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/StockExchangePriceAlert`)
    };
};

export const fetchAlerts = (email, crmId) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: FETCH_ALERTS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/StockExchangePriceAlert/search-stock-exchange-price-alerts`, {
            params: {
                crmId: crmId,
                email: email
            }
        })
    };
}

export const putAlert = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: PUT_ALERT,
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/StockExchangePriceAlert/set-stock-exchange-price-alert`, data)
    }
}
