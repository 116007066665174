
import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import classnames from "classnames";

import CardQrCodeOverlay from "../common/CardQrCodeOverlay";
import { IconButton } from "@rmwc/icon-button";

class CardQrButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOverlayOpen: false
        }

        this.openQrOverlay = this.openQrOverlay.bind(this);
        this.closeQrOverlay = this.closeQrOverlay.bind(this);

    }

    openQrOverlay() {
        this.setState({ isOverlayOpen: true });
    }

    closeQrOverlay() {
        this.setState({ isOverlayOpen: false });
    }

    render() {
        const { isOverlayOpen } = this.state;
        const { cardNumber, className } = this.props;

        const hasCard = !!cardNumber;

        return (
            <>
                <IconButton
                    checked
                    onIcon={{
                        icon: "qr",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }}
                    onClick={this.openQrOverlay}
                    disabled={!hasCard}
                    className={classnames(
                        "card-qr-button",
                        className,
                        { "card-qr-button--disabled": !hasCard })} />

                {isOverlayOpen &&
                    <CardQrCodeOverlay
                        cardNumber={cardNumber}
                        onClose={this.closeQrOverlay} />
                }
            </>
        );
    }
}


function mapStateToProps(state) {
    return {};
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default injectIntl(
    //withRouter(
    connect(mapStateToProps, matchDispatchToProps)(CardQrButton)
    //)
);
