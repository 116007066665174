import React from "react";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';
import { fetchAccount, fetchContacts, putAccount } from "../actions/accountActions";
import { fetchSalepoints, fetchSalepointTaxonomies, searchSalepoints } from "../actions/stationActions";
import classnames from 'classnames';
import StationSearchField from "../components/Station/StationSearchField";
import StationFilter from "../components/Station/StationFilter";
import Map from '../components/common/Map';
import Loader from "../components/common/Loader";

import "../styles/react-components/station.scss";
import StationInfo from "../components/Station/StationInfo";

class Stations extends React.Component {

    timeout = null;

    serviceIcons = {
        "convenience-store": "icon-convenience-store",
        "cylinder-gas": "icon-cylinder-gas",
        "car-wash": "icon-car-wash",
        "trailer-hire": "icon-trailer-2",
        "air-and-water": "icon-air-water",
        "vacuum-cleaner": "icon-vacuum",
        "windshield-cleaner": "icon-windshield",
        "diesel-fast-filling": "icon-diesel-fast-station",
        "insurance": "icon-insurance",
        "wc": "icon-wc",
        "harbour-filling-station": "icon-harbour-station"
    }

    fuelIcons = {
        "95": "icon-95",
        "98": "icon-98",
        "adblue": "icon-adblue",
        "cng": "icon-cng",
        "d": "icon-diesel",
        "edk": "icon-edk",
        "lng": "icon-lng",
        "lpg": "icon-lpg",
        "electricity": "icon-electricity"
    }

    static SALE_POINT_TYPE_STATION = "filling-stations";
    static SALE_POINT_TYPE_STORE = "convenience-stores";

    salePointTypeIcons = {
        [Stations.SALE_POINT_TYPE_STATION]: "icon-gasoline-2",
        [Stations.SALE_POINT_TYPE_STORE]: "icon-burger",
        "partner-filling-station": "icon-user",
        "car-wash-stations": "icon-car-wash",
        "sale-cylinder-gas": "icon-gas"
    }

    constructor(props) {
        super(props);

        this.state = {
            searchString: '',
            station: null,
            serviceFilters: [],
            fuelFilters: [],
            view: 'map',
            mapCoord: {
                lat: 58.8,
                lng: 25
            },
            mapZoom: 7,
            focusMapZoom: 14,
            filtersOpen: false,
            searchOpen: false,
        };
        this.refreshStations = this.refreshStations.bind(this);
        this.setStation = this.setStation.bind(this);
        this.goMap = this.goMap.bind(this);
        this.goList = this.goList.bind(this);
        this.getCategoryIcon = this.getCategoryIcon.bind(this);
        this.toggleFuelFilter = this.toggleFuelFilter.bind(this);
        this.toggleServiceFilter = this.toggleServiceFilter.bind(this);
        this.toggleFiltersOpen = this.toggleFiltersOpen.bind(this);
        this.toggleSearchOpen = this.toggleSearchOpen.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchDelegate = this.handleSearchDelegate.bind(this);
        this.renderSearchAndFilter = this.renderSearchAndFilter.bind(this);
        this.renderListItem = this.renderListItem.bind(this);
    }

    componentDidMount() {
        if (!this.props.stationsFetching && !this.props.stationsFetched) {
            this.refreshStations();
        }
        if (!this.props.taxonomiesFetching && !this.props.taxonomiesFetched) {
            this.props.fetchSalepointTaxonomies();
        }
    }

    refreshStations() {
        if (!this.props.stationsFetching) {
            this.props.fetchSalepoints(this.state.serviceFilters, this.state.fuelFilters);
        }
    }

    setStation(value, changeViewToMap) {
        let resetFilters = value && this.stationItems.filter(x => x.id === value.id).length <= 0;

        this.setState({
            station: value,
            mapCoord: value ? this.stationCoord(value) : this.state.mapCoord,
            mapZoom: 15,
            serviceFilters: resetFilters
                ? []
                : this.state.serviceFilters,
            fuelFilters: resetFilters
                ? []
                : this.state.fuelFilters,
            view: changeViewToMap ? 'map' : this.state.view
        }, this.refreshStations);
    }

    goMap() {
        this.setState({ view: "map" });
    }

    goList() {
        this.setState({ view: "list" });
    }

    handleSearchChange(value) {
        this.setState({
            searchString: value
        });

        clearTimeout(this.timeout);

        if (!value || value.length <= 3) {
            return;
        }

        this.timeout = setTimeout(this.handleSearchDelegate, 400);
    }

    handleSearchDelegate() {
        if (this.props.isLoading) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(this.handleSearchDelegate, 100);
        } else {
            this.props.searchSalepoints(this.state.searchString);
        }
    }

    toggleServiceFilter(filter) {
        let filters = [...this.state.serviceFilters];

        if (filters.includes(filter)) {
            filters.splice(filters.indexOf(filter), 1);
        } else {
            filters = filters.concat([filter]);
        }

        this.setState({
            serviceFilters: filters
        }, this.refreshStations);
    }

    toggleFuelFilter(filter) {
        let filters = [...this.state.fuelFilters];

        if (filters.includes(filter)) {
            filters.splice(filters.indexOf(filter), 1);
        } else {
            filters = filters.concat([filter]);
        }

        this.setState({
            fuelFilters: filters
        }, this.refreshStations);
    }

    toggleFiltersOpen() {
        this.setState({
            filtersOpen: !this.state.filtersOpen,
            searchOpen: false
        });
    }

    toggleSearchOpen() {
        this.setState({
            searchOpen: !this.state.searchOpen,
            filtersOpen: false
        });
    }

    stationCoord(item) {
        return {
            lat: item.latitude,
            lng: item.longitude
        }
    }


    getCategoryIcon(item) {
        const defaultIcon = "icon-location";
        if (item.salePointTypes &&
            !!item.salePointTypes.find(x => x === Stations.SALE_POINT_TYPE_STATION) &&
            !!item.salePointTypes.find(x => x === Stations.SALE_POINT_TYPE_STORE)) {
            return this.salePointTypeIcons[Stations.SALE_POINT_TYPE_STATION];
        }

        if (!item.salePointTypes || (item.salePointTypes && item.salePointTypes.length !== 1)) {
            return defaultIcon;
        }

        return this.salePointTypeIcons[item.salePointTypes[0]] ?? defaultIcon;
    }

    get stationItems() {
        return this.props.stations
            .map((x) => {
                const coords = this.stationCoord(x);
                return {
                    key: x.id,
                    coords: coords,
                    latitude: coords.lat,
                    longitude: coords.lng,
                    name: x.name,
                    address: x.address,
                    serviceIcons: x.services
                        .map(svc => this.serviceIcons[svc])
                        .filter(i => !!i),
                    fuelIcons: x.fuels
                        .map(fuel => this.fuelIcons[fuel])
                        .filter(i => !!i)
                }
            });
    }

    renderListItem(station) {
        return (
            <StationInfo
                key={'list-item' + station.id}
                {...station}
                headerIsToggle={true}
                links={
                    <Button
                        onClick={() => this.setStation(station, true)}>
                        <FormattedMessage id="Stations.LocationOnMap" />
                    </Button>
                } >
            </StationInfo>
        );
    }

    renderSearchAndFilter() {
        const { station, fuelFilters, serviceFilters, filtersOpen, searchOpen } = this.state;
        const { searchResults, searchResultsFetching, taxonomiesFetched, taxonomiesFetching, taxonomies } = this.props;

        const hasTaxonomies = taxonomiesFetched && taxonomies && taxonomies.services.length > 0;
        let stationItems = this.stationItems;

        return (
            <GridInner>
                <GridCell desktop={6} tablet={8} phone={4} className="mdc-layout-grid__cell--start-4-desktop">
                    <div className="stations__filter-toggles">
                        <Button className={classnames({ "active": searchOpen })}
                            onClick={this.toggleSearchOpen}
                            icon={{
                                icon: "search",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-"
                            }} >
                            <FormattedMessage id="Stations.Search" />
                        </Button>
                        <Button
                            className={classnames({ "active": filtersOpen })}
                            onClick={this.toggleFiltersOpen}
                            trailingIcon={{
                                icon: "angle-down",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-",
                                className: (filtersOpen ? "rotate" : "")
                            }}>
                            <FormattedMessage id="Invoices.Filter" />
                        </Button>
                    </div>
                </GridCell>

                {searchOpen &&
                    <GridCell desktop={8} tablet={8} phone={4} className="mdc-layout-grid__cell--start-3-desktop">
                        <StationSearchField
                            placeholder={this.props.intl.formatMessage({
                                id: "Stations.Search"
                            })}
                            isLoading={searchResultsFetching}
                            value={station}
                            onChange={(station) => this.setStation(station, true)}
                            onInputChange={this.handleSearchChange}
                            field={"station"}
                            iconTranslation={this.getCategoryIcon}
                            options={searchResults} />
                    </GridCell>}


                {filtersOpen &&
                    <>
                        {taxonomiesFetching &&
                            <GridCell span={12}>
                                <Loader type={Loader.TYPE_CENTER} />
                            </GridCell>}

                        {hasTaxonomies &&
                            <>
                                <GridCell span={12}>
                                    <Typography use="body1" className="mdc-typography text-center">
                                        <FormattedHTMLMessage
                                            id="Stations.FilteredCount"
                                            values={{ count: stationItems.length }} />
                                    </Typography>
                                </GridCell>
                                <GridCell span={12}>
                                    {taxonomies.services && taxonomies.services.length > 0 &&
                                        <div className="stations__filters">
                                            {taxonomies.services.map(x =>
                                                <StationFilter
                                                    key={'filter-' + x.machineName}
                                                    text={x.name}
                                                    icon={this.serviceIcons[x.machineName]}
                                                    isActive={serviceFilters.includes(x.machineName)}
                                                    onClick={this.toggleServiceFilter.bind(this, x.machineName)} />
                                            )}
                                        </div>}
                                    {taxonomies.fuels && taxonomies.fuels.length > 0 &&
                                        <div className="stations__filters">
                                            {taxonomies.fuels.map(x =>
                                                <StationFilter
                                                    key={'filter-' + x.machineName}
                                                    text={x.name}
                                                    icon={this.fuelIcons[x.machineName]}
                                                    isActive={fuelFilters.includes(x.machineName)}
                                                    onClick={this.toggleFuelFilter.bind(this, x.machineName)} />)}
                                        </div>}
                                </GridCell>
                            </>}
                    </>}
            </GridInner >
        );
    }

    render() {
        const { view, station, mapCoord, mapZoom, focusMapZoom } = this.state;
        const { stationsFetching, } = this.props;

        let stationItems = this.stationItems;

        return (
            <>
                <Grid className="mdc-layout-grid--base">
                    <GridCell span={12}>
                        {this.renderSearchAndFilter()}
                    </GridCell>
                </Grid>

                <Grid className="mdc-layout-grid--base mdc-layout-grid--mobile-full mdc-layout-grid--no-gap">

                    <GridCell desktop={6} tablet={4} phone={2}>
                        <Button
                            className={classnames("mdc-button-tab-underlined mdc-button--tab mdc-button--wide", {
                                "active": view === 'map'
                            })}
                            onClick={this.goMap}>
                            <FormattedMessage id="Stations.Map" />
                        </Button>
                    </GridCell>
                    <GridCell desktop={6} tablet={4} phone={2}>
                        <Button
                            className={classnames("mdc-button-tab-underlined mdc-button--tab mdc-button--wide", {
                                "active": view === 'list'
                            })}
                            onClick={this.goList}>
                            <FormattedMessage id="Stations.List" />
                        </Button>
                    </GridCell>

                    <GridCell span={12} hidden={view !== 'map'}>
                        <div className="stations-map-container">
                            {stationsFetching &&
                                <Loader type={Loader.TYPE_COVER} />}
                            <Map items={stationItems}
                                center={mapCoord}
                                zoom={mapZoom}
                                focusZoom={focusMapZoom}
                            />
                        </div>
                    </GridCell>

                    <GridCell span={12} hidden={view !== 'list'}>
                        {stationsFetching &&
                            <Loader type={Loader.TYPE_CENTER} />}

                        {stationItems && stationItems.length > 0 &&
                            <div className="stations-list">
                                {station
                                    ? this.renderListItem(station, -1)
                                    : stationItems && stationItems.length > 0 && stationItems
                                        .map(this.renderListItem)
                                }
                            </div>}
                    </GridCell>
                </Grid >
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAccountLoading: state.account.fetching,
        isAccountLoaded: state.account.fetched,
        account: state.account.data,
        contacts: state.contacts.data,
        areContactsFetched: state.contacts.fetched,
        isPutAccountLoaded: state.putAccount.fetched,
        putAccountError: state.putAccount.error,
        isPutAccountLoading: state.putAccount.fetching,

        stationsFetching: state.salepoints.fetching,
        stationsFetched: state.salepoints.fetched,
        stations: state.salepoints.data,

        taxonomiesFetching: state.salepointTaxonomies.fetching,
        taxonomiesFetched: state.salepointTaxonomies.fetched,
        taxonomies: state.salepointTaxonomies.data,

        searchResultsFetching: state.salepointSearch.fetching,
        searchResultsFetched: state.salepointSearch.fetched,
        searchResults: state.salepointSearch.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAccount,
        fetchContacts,
        putAccount,
        fetchSalepoints,
        fetchSalepointTaxonomies,
        searchSalepoints,
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(Stations)
);
