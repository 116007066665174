import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const InvoiceLine = ({ name, value, italic, bold, className }) => {
    return (
        <div className={classNames(
            "invoice__line",
            {
                "invoice__line--bold": bold,
                "invoice__line--italic": italic
            },
            className
        )}>
            <div className="name">
                {name}
            </div>
            <div className="mdc-typography value">
                {value}
            </div>
        </div>
    );
}

InvoiceLine.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.any,
    italic: PropTypes.bool,
    bold: PropTypes.bool,
    className: PropTypes.any,
}

export default InvoiceLine;