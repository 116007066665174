import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";

export const FETCH_HUB_INSTALLATION = 'FETCH_HUB_INSTALLATION';
export const FETCH_DEVICE_REGISTRATION_LIST = 'FETCH_DEVICE_REGISTRATION_LIST';
export const POST_DEVICE_REGISTRATION = 'POST_DEVICE_REGISTRATION';
export const PUT_DEVICE_REGISTRATION = 'PUT_DEVICE_REGISTRATION';
export const DELETE_DEVICE_REGISTRATION = 'DELETE_DEVICE_REGISTRATION';

export const FETCH_TEST_NOTIFICATIONS = 'FETCH_TEST_NOTIFICATIONS';

export const FETCH_PUSH_NOTIFICATION_SETTINGS = 'FETCH_PUSH_NOTIFICATION_SETTINGS';
export const SAVE_PUSH_NOTIFICATION_SETTINGS = 'SAVE_PUSH_NOTIFICATION_SETTINGS';
export const CLEAR_SAVED_PUSH_NOTIFICATION_MESSAGES = 'CLEAR_SAVED_PUSH_NOTIFICATION_MESSAGES';
export const SEND_TEST_NOTIFICATION = 'SEND_TEST_NOTIFICATION';

export const fetchDeviceRegistrations = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_DEVICE_REGISTRATION_LIST,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/pushnotification/app-registration/list`)
    };
}

export const postDeviceRegistration = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: POST_DEVICE_REGISTRATION,
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/pushnotification/app-registration`, payload)
    };
};

export const resetDeviceRegistration = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: PUT_DEVICE_REGISTRATION,
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/pushnotification/app-registration`, payload)
    };
};

export const deleteDeviceRegistration = (installationId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: DELETE_DEVICE_REGISTRATION,
        payload: BusinessAPI.delete(`/api/${accountId}/${roleId}/pushnotification/app-registration`, {
            params: { installationId: installationId }
        })
    };
}

export const fetchHubInstallation = (installationId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_HUB_INSTALLATION,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/pushnotification/hub-installation`, {
            params: { installationId: installationId }
        })
    };
}

export const fetchTestNotificationsAsync = (page, limit) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_TEST_NOTIFICATIONS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/pushnotification/test-notifications`, {
            params: {
                pageNr: page,
                limit: limit
            }
        })
    };
}

export const fetchPushNotificationSettings = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_PUSH_NOTIFICATION_SETTINGS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/pushnotification/settings`)
    };
}

export const savePushNotificationSettings = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: SAVE_PUSH_NOTIFICATION_SETTINGS,
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/pushnotification/settings`, payload)
    };
}

export const clearSavedPushNotificationMessages = () => {
    return { type: CLEAR_SAVED_PUSH_NOTIFICATION_MESSAGES }
}

export const sendTestNotification = (payload) => {
    const accountId = auth.getUserId();
    const roleId = auth.getRoleId();

    return {
        type: SEND_TEST_NOTIFICATION,
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/pushnotification/test`, payload)
    };
}