import { useEffect } from "react";
import { ErrorPagePath } from "../const/routes";
import { useHistory } from "react-router-dom";
import { trackException } from "../appInsights";

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
    const history = useHistory();

    useEffect(() => {
        trackException(error);
        resetErrorBoundary();
        history.replace(ErrorPagePath);
    });

    return null;
}

export default ErrorBoundaryFallback;