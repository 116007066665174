export default function reducer(state={
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case "POST_PREPARE_ELECTRICITY_CONTRACT_OFFER_SIGNING_PENDING": {
            return {...state, fetching: true};
        }
        case "POST_PREPARE_ELECTRICITY_CONTRACT_OFFER_SIGNING_REJECTED": {
            return {...state, fetching: false, error: action.payload};
        }
        case "POST_PREPARE_ELECTRICITY_CONTRACT_OFFER_SIGNING_FULFILLED": {
            return {...state, error: null, fetching: false, fetched: true, data: action.payload.data};
        }
        default: {}
    }

    return state;
}