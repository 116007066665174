import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, GridCell } from "@rmwc/grid";
import { fetchBasketballProjectStatistics } from "../../actions/basketballCommunityProjectActions";
import ContributionSection from "./ContributionSection";
import AlertMessage from "../common/AlertMessage";
import { FormattedMessage } from "react-intl";
import {Typography} from "@rmwc/typography";
import LinkToCommunityPage from "./LinkToCommunityPage";
import {BasketballCommunityPath} from "../../const/routes";

export const BasketballContribution = ({isNewUser = false, showLink = false, titleId = "Community.Alexela"}) => {
    const dispatch = useDispatch();

    const basketballProjectStatistics = useSelector(state => state.basketballProjectStatistics);

    useEffect(() => {
        if (!basketballProjectStatistics.fetched && !basketballProjectStatistics.fetching && !basketballProjectStatistics.error) {
            dispatch(fetchBasketballProjectStatistics());
        }
    }, [dispatch]);

    const membersProps = {
        iconClassName: "icon-members",
        number: basketballProjectStatistics.data?.participationCount || "0",
        title: "Community.Basketball.Title.Members",
        info: "Community.Basketball.Info.Members",
    };

    const contributionProps = {
        iconClassName: "icon-basketball-euros",
        number: basketballProjectStatistics.data?.contributionAmount || "0",
        numberUnit: "€",
        title: "Community.Basketball.Title.ContributionAmount",
        info: "Community.Basketball.Info.ContributionAmount",
    };

    const contributionsThisYearProps = {
        iconClassName: "icon-basketball-money",
        number: basketballProjectStatistics.data?.contributionAmountThisYear || "0",
        numberUnit: "€",
        title: "Community.Basketball.Title.ContributionAmountThisYear",
        info: "Community.Basketball.Info.ContributionAmountThisYear",
    };

    const renderLink = () => {
        if (!showLink) {
            return null;
        }

        return (
            <div className="contribution-link-mobile">
                <LinkToCommunityPage overrideLinkHref={BasketballCommunityPath}
                                     linkTextId="Community.BasketballCommunity"
                                     joinLinkTextId="Community.Basketball.Join"/>
            </div>
        )
    }

    return (
        <Grid className="mdc-layout-grid--base mb-60">
            { isNewUser &&
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_DONE}
                        isSmall={true}
                        title={<FormattedMessage id="Community.Basketball.Register.Success" />} />
                </GridCell>
            }
            <GridCell span={12}>
                {renderLink()}
                <Typography
                    use="headline3"
                    tag="h3"
                    className="mdc-typography mdc-theme--primary mb-mobile-15"
                >
                    <FormattedMessage id={titleId} />
                </Typography>
            </GridCell>
            <ContributionSection
                firstProps={membersProps}
                secondProps={contributionProps}
                thirdProps={contributionsThisYearProps}
            />
        </Grid>
    );
}
