import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@rmwc/typography";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { GridCell, GridInner } from "@rmwc/grid";
import { Button } from "@rmwc/button";

import AlertMessage from "../common/AlertMessage";
import Dialog from "../common/BaseDialog";
import Loader from "../common/Loader";

import { fetchInvoicePaymentMethods } from "../../actions/invoiceActions";
import {IsLatvianWeb} from "../../helpers/AlternativeDesign";

const InvoicePaymentMethodsModal = ({ invoiceId, invoiceType, isOpen, onClose }) => {

    const total = useSelector(state => state.invoicePaymentMethods.data.initialAmount);
    const paymentMethods = useSelector(state => state.invoicePaymentMethods.data.items);
    const paymentMethodsFetching = useSelector(state => state.invoicePaymentMethods.fetching);

    // Using payment link to check which one was clicked because this is most likely to be unique
    const [clickedPaymentMethodLink, setClickedPaymentMethodLink] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen) {
            dispatch(fetchInvoicePaymentMethods(invoiceId, invoiceType));
        }
    }, [isOpen, invoiceId, invoiceType, dispatch]);


    const cardPaymentMethods = !!paymentMethods && paymentMethods.filter(x => !x.countryCode);
    const countryCode = IsLatvianWeb() ? "LV" : "EE";
    const bankPaymentMethods = !!paymentMethods && paymentMethods.filter(x => x.countryCode === countryCode);

    const onPaymentMethodClick = (paymentMethod) => {
        setClickedPaymentMethodLink(paymentMethod.paymentLink);
        window.location.href = paymentMethod.paymentLink;
    }

    const isPaymentMethodClicked = (paymentMethod) => paymentMethod.paymentLink === clickedPaymentMethodLink;

    const renderPaymentMethod = (paymentMethod) =>
        <Button
            title={paymentMethod.displayName}
            className="mdc-button mdc-button--outlined mdc-button--thin mdc-button--wide"
            disabled={!!clickedPaymentMethodLink}
            icon={{
                strategy: "custom",
                render: () => isPaymentMethodClicked(paymentMethod)
                    ? <Loader type={Loader.TYPE_COVER} size="small" />
                    : <></>
            }}
            onClick={() => onPaymentMethodClick(paymentMethod)} >
            <img src={paymentMethod.logoUrl} alt={paymentMethod.displayName} />
        </Button>;

    const renderError = () =>
        <GridInner>
            <GridCell span={12}>
                <AlertMessage type={AlertMessage.TYPE_ALERT}>
                    <FormattedMessage id="Payment.Methods.Error" />
                </AlertMessage>
            </GridCell>
        </GridInner>;

    const renderPaymentMethodSelection = () =>
        <GridInner className="invoice__payment-methods">
            <GridCell span={12}>
                <Typography use="headline3" className="mdc-typography mdc-typography--bold text-left">
                    <FormattedMessage id="Payment.Total" />: {total} €
                </Typography>
            </GridCell>

            {!!cardPaymentMethods &&
                cardPaymentMethods.length > 0 &&
                <>
                    <GridCell span={12}>
                        <Typography
                            use="headline4"
                            className="mdc-typography text-left">
                            <FormattedMessage id="Payment.Methods.Subtitle.Card" />
                        </Typography>
                    </GridCell>
                    {cardPaymentMethods.map((x, i) =>
                    (
                        <GridCell desktop={4} tablet={4} phone={2}
                            key={"method_card_" + i}>
                            {renderPaymentMethod(x)}
                        </GridCell>
                    ))}
                </>
            }

            {!!bankPaymentMethods &&
                bankPaymentMethods.length > 0 &&
                <>
                    <GridCell span={12}>
                        <Typography
                            use="headline4"
                            className="mdc-typography text-left">
                            <FormattedMessage id="Payment.Methods.Subtitle.Bank" />
                        </Typography>
                    </GridCell>

                    {bankPaymentMethods.map((x, i) =>
                    (
                        <GridCell desktop={4} tablet={4} phone={2}
                            key={"method_banklink_" + i}>
                            {renderPaymentMethod(x)}
                        </GridCell>
                    ))}
                </>
            }
        </GridInner>;

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            preventOutsideDismiss={true}>

            {paymentMethodsFetching &&
                <Loader type={Loader.TYPE_CENTER} />}

            {!paymentMethodsFetching &&
                ((!paymentMethods || paymentMethods.length <= 0)
                    ? renderError()
                    : renderPaymentMethodSelection()
                )
            }
        </Dialog>
    );
}

InvoicePaymentMethodsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
}

export default injectIntl(InvoicePaymentMethodsModal);