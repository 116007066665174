import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import List from "../components/Account/AuthorizedUserList";
import { Grid, GridCell } from "@rmwc/grid";
import { fetchAuthorizedUsers, fetchAuthorizedContracts, fetchCanSetAuthorizations } from "../actions/accountActions";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";

import "../styles/react-components/table.scss";

class AuthorizedUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            canViewPage: false
        }
    }

    componentDidMount() {
        if (!this.props.authorizedUsersFetching && !this.props.authorizedUsersFetched) {
            this.props.fetchAuthorizedUsers();
        }

        if (!this.props.authorizedContractsFetching && !this.props.authorizedContractsFetched) {
            this.props.fetchAuthorizedContracts();
        }

        this.props.fetchCanSetAuthorizations()
            .then(x => this.setState({canViewPage: x.value.data}))
            .catch(() => {});
    }

    onSuccess() {
        this.props.fetchAuthorizedUsers();
        this.props.fetchAuthorizedContracts();
    }

    render() {
        const isLoading = this.props.authorizedUsersFetching || this.props.authorizedContractsFetching;
        const hasItems = this.props.authorizedUsers && this.props.authorizedUsers.length > 0;

        if (!this.state.canViewPage) {
            return null;
        }

        return (
            <Grid className="mdc-layout-grid--base">
                <GridCell span={12}>

                    {isLoading &&
                        <Loader type={Loader.TYPE_CENTER} />
                    }
                    {!isLoading && !hasItems &&
                        <AlertMessage
                            className="mb-30"
                            type={AlertMessage.TYPE_NOTICE}
                            title={<FormattedMessage id="AuthorizedUser.NotFound"/>}
                        />
                    }
                    <main>
                        <List
                            items={this.props.authorizedUsers}
                            onSuccess={this.onSuccess.bind(this)}
                        />
                    </main>
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        authorizedUsers: state.authorizedUser.data,
        authorizedUsersFetching: state.authorizedUser.fetching,
        authorizedUsersFetched: state.authorizedUser.fetched,

        authorizedContractsFetched: state.authorizedContracts.fetched,
        authorizedContractsFetching: state.authorizedContracts.fetching
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchAuthorizedUsers, fetchAuthorizedContracts, fetchCanSetAuthorizations }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(AuthorizedUsers)
);
