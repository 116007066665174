import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Checkbox from "@material/react-checkbox";
import PropTypes from "prop-types";

const ContractTermsCheckbox = ({
    checked = false,
    disabled = false,
    onSetIsTermsAcceptedChanged,
    titleKey,
    termsValues
}) => {
    const intl = useIntl();

    const mapTermsValues = () => {
        if (!termsValues) return null;

        return termsValues.reduce((list, value) => {
            list[value.termsKey] =
                <a
                    href={intl.formatMessage({ id: value.termsLinkKey })}
                    target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id={value.termsLinkTextKey} />
                </a>
            return list;
        }, {});
    }

    return (
        <>
            <Checkbox
                nativeControlId="accept-terms"
                name="accept-terms"
                checked={checked}
                disabled={disabled}
                indeterminate={false}
                onChange={onSetIsTermsAcceptedChanged}
            />
            <label htmlFor="accept-terms">
                <FormattedMessage id={titleKey}
                    values={mapTermsValues()}
                />
            </label>
        </>
    );
}

ContractTermsCheckbox.propTypes = {
    checked: PropTypes.bool,
    onSetIsTermsAcceptedChanged: PropTypes.func,
    titleKey: PropTypes.string.isRequired,
    termsValues: PropTypes.arrayOf(PropTypes.shape({
        termsKey: PropTypes.string.isRequired,
        termsLinkKey: PropTypes.string.isRequired,
        termsLinkTextKey: PropTypes.string.isRequired
    })),
    disabled: PropTypes.bool
}

export default ContractTermsCheckbox;