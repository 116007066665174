import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Grid, GridCell } from "@rmwc/grid";
import { Dialog, DialogTitle, DialogContent, DialogButton } from "@rmwc/dialog";
import { List, ListItem, ListItemText } from "@rmwc/list";
import { Typography } from "@rmwc/typography";
import { Icon } from "@rmwc/icon";
import { Button } from "@rmwc/button";
import "../../styles/react-components/dialog.scss";
import { fetchRoles, setRole, setAgentRole, getUserCompanyRoles } from "../../actions/accountActions";
import auth from "../../auth/authenticate";
import Loader from "../common/Loader";
import { updateUser } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import BaseFormComponent from "../common/BaseFormComponent";
import SimpleReactValidator from "simple-react-validator";
import { StartPath } from "../../const/routes";
import IdCodeValidatorRule from "../../validators/IdCodeValidatorRule";
import AlertMessage from "../common/AlertMessage";

class RoleDialog extends BaseFormComponent {
    static CrmId = "crmId";
    static SSN = "ssn";
    static Regcode = "regcode";

    constructor(props) {
        super(props);

        const userData = auth.getUserData();

        this.state = {
            crmId: "",
            ssn: "",
            regcode: "",
            authError: false,
            authSuccess: false,
            showAgentModal: userData && userData.roles && userData.roles
                .some(x => x === "Admin" || x === "Agent"),
            showAdminModal: userData && userData.roles && userData.roles
                .some(x => x === "Admin")
        }
        this.chooseRole = this.chooseRole.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onGetRoles = this.onGetRoles.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });

        this.agentValidator = new SimpleReactValidator({
            validators: { ...IdCodeValidatorRule.rule() },
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    componentDidMount() {
        if (!this.props.roles && this.props.isOpen) {
            this.props.fetchRoles();
        }
    }

    closeModal() {
        this.props.onClose();
    }

    chooseRole(roleId) {
        if (auth.getRole().crmId === roleId) {
            this.closeModal();
            return;
        }
        this.props.setRole(roleId)
            .then(this.props.onClose)
            .then(this.props.updateUser);
    }

    chooseAgentRole(ssn) {
        if (!this.agentValidator.allValid()) {
            this.agentValidator.showMessages();
            return;
        }

        this.props.setAgentRole(ssn)
            .then(this.props.onClose)
            .then(this.props.updateUser)
            .then(() => {
                this.props.history.push(StartPath);
            });
    }

    onGetRoles() {
        this.setState({
            authError: false,
            authSuccess: false
        })
        this.props.getUserCompanyRoles()
        .then(() => {
            this.props.fetchRoles();
            this.setState({
                authSuccess: true
            })
        })
        .catch(() => {
            this.setState({
                authError: true
            })
        })
    }

    renderDialogTitle() {
        return (
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss" />
            </DialogTitle>
        );
    }

    renderRoles(roles) {
        return roles.map(
            (role) => {
                return (
                    <ListItem
                        onClick={this.chooseRole.bind(this, role.crmId)}
                        key={role.crmId}
                        tabIndex="0"
                    >
                        <ListItemText>
                            <span className="mdc-list-item__primary-text marked">
                                {role.accountName}
                            </span>
                            {role.isBusiness &&
                                <span className="mdc-list-item__secondary-text">
                                    {role.personalCode}
                                </span>
                            }
                        </ListItemText>
                        <Icon
                            icon={{
                                icon: "arrow-left",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-"
                            }}
                        />
                    </ListItem>
                );
            }
        )
    }

    render() {
        let defaultRoles = [
            {
                crmId: auth.getUserId(),
                accountName:
                    this.props.user ? `${this.props.user.firstName} ${this.props.user.lastName}` : ""
            }
        ];
        const privateRoles = this.props.roles
            ? defaultRoles.concat(this.props.roles.filter(x => !x.isCompany))
            : defaultRoles;
        const businessRoles = this.props.roles
            ? this.props.roles.filter(x => x.isCompany)
            : [];

        const showDoubleView = this.state.showAgentModal || this.state.showAdminModal;

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.onClose}
                className={showDoubleView ? "mdc-dialog--wide" : ""}
            >
                {this.renderDialogTitle()}
                <DialogContent>
                    {this.props.isSettingRole || this.props.agentRoleFetching || this.props.isFetching || this.props.createAuthFetching ? <Loader type={Loader.TYPE_CENTER}/> : 
                    <Grid>
                        <GridCell span={12} >
                            <Typography use="headline4" className="mdc-typography mdc-theme--primary information__head upper">
                                <FormattedMessage id="RoleChooseDialog.HelloPerson" />
                                {this.props.user ? this.props.user.firstName : ""}
                            </Typography>
                            <div className="information__description lighter">
                                <FormattedMessage id="RoleChooseDialog.Title" />
                            </div>
                        </GridCell>
                        <GridCell phone={4} tablet={showDoubleView ? 4 : 8} desktop={showDoubleView ? 6 : 12}>
                            <h3 className="mdc-list-group__header">
                                <FormattedMessage id="RoleChooseDialog.PrivateClient" />
                            </h3>
                            <List className="mdc-list-group--roles mdc-list--two-line mb-25">
                                {this.renderRoles(privateRoles)}
                            </List>
                            <h3 className="mdc-list-group__header">
                                <FormattedMessage id="RoleChooseDialog.BusinessClient" />
                            </h3>
                            {businessRoles &&
                                businessRoles.length > 0 && (
                                    <List className="mdc-list-group--roles mdc-list--two-line mb-25">
                                        {this.renderRoles(businessRoles)}
                                    </List>
                                )
                            }
                            <div className="information__description lighter mb-25">
                                <FormattedMessage id="RoleChooseDialog.BusinessClient.LoadBusinessesDescription" />
                            </div>
                            {this.state.authError &&
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_ALERT}
                                    title={<FormattedMessage id="RoleChooseDialog.BusinessClient.LoadBusinessesError"/>}
                                    className="mb-25"
                                />
                            }
                            {this.state.authSuccess &&
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_DONE}
                                    title={<FormattedMessage id="RoleChooseDialog.BusinessClient.LoadBusinessesOk" />}
                                    className="mb-25"
                                />
                            }
                            <Button
                                type="submit"
                                onClick={this.onGetRoles}
                                unelevated
                                disabled={this.props.userCompanyRoles.fetching}
                                className="mdc-button--primary right">
                                <FormattedMessage id="RoleChooseDialog.BusinessClient.LoadBusinesses" />
                            </Button>
                        </GridCell>
                        {showDoubleView &&
                            <GridCell phone={4} tablet={4} desktop={6}>
                                {this.state.showAdminModal &&
                                    <>
                                        <h3 className="mdc-list-group__header">
                                            <FormattedMessage id="RoleChooseDialog.Administrator" />
                                        </h3>
                                        <div className="information__description lighter mb-25">
                                            <FormattedMessage id="RoleChooseDialog.Administrator.Description" />
                                        </div>
                                        <TextFieldGroup
                                            field={RoleDialog.CrmId}
                                            onChange={this.onChange}
                                            validator={this.validator}
                                            rules="required"
                                            value={this.state.crmId}
                                            label="RoleChooseDialog.Administrator.CrmId"
                                        />
                                        <Button type="submit"
                                            disabled={!this.state.crmId}
                                            onClick={this.chooseRole.bind(this, this.state.crmId)}
                                            unelevated
                                            className="mdc-button--primary right">
                                            <FormattedMessage id="RoleChooseDialog.Administrator.ChooseRole" />
                                        </Button>
                                    </>
                                }
                                <h3 className="mdc-list-group__header">
                                    <FormattedMessage id="RoleChooseDialog.Agent" />
                                </h3>
                                <TextFieldGroup
                                    field={RoleDialog.SSN}
                                    onChange={this.onChange}
                                    validator={this.agentValidator}
                                    rules="required|idCode:EE"
                                    value={this.state.ssn}
                                    type="number"
                                    label="RoleChooseDialog.Agent.SSN"
                                />
                                <Button type="submit"
                                    disabled={!this.state.ssn}
                                    onClick={this.chooseAgentRole.bind(this, this.state.ssn)}
                                    unelevated
                                    className="mdc-button--primary right">
                                    <FormattedMessage id="RoleChooseDialog.Agent.Choose" />
                                </Button>
                            </GridCell>
                        }
                    </Grid>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        roles: state.roles.data,
        user: state.auth.user,
        roleFetched: state.setRole.fetched,
        isFetching: state.roles.fetching,
        isSettingRole: state.setRole.fetching,
        agentRoleFetching: state.setAgentRole.fetching,
        userCompanyRoles: state.userCompanyRoles
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchRoles,
        setRole,
        updateUser,
        setAgentRole,
        getUserCompanyRoles
    }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(RoleDialog)));