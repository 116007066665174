import {Typography} from "@rmwc/typography";
import React from "react";
import {useFeatures} from "flagged";

export const EnabledFeatures = () => {
    const features = useFeatures();

    return (
        <>
            <Typography use="headline4" className="mdc-typography mt-12 mb-12">Enabled features</Typography>

            <div className="pre word-wrap">
                {Object.keys(features).map(key => <div key={key}>{key} => {features[key] ? "enabled" : "disabled"}</div>)}
            </div>
        </>
    )
}