import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";
import BaseFormComponent from "../common/BaseFormComponent";
import SimpleReactValidator from "simple-react-validator";
import { Typography } from "@rmwc/typography";
import TextFieldGroup from "../common/TextFieldGroup";

import { bindBonusCard } from "../../actions/cardActions";

class BindCard extends BaseFormComponent {
    static BIND_BONUSCARD_NUMBER = "bindBonuscardNumber";

    constructor(props) {
        super(props);

        this.state = {
            [BindCard.BIND_BONUSCARD_NUMBER]: "9233"
        };
        this.onSubmitBindBonusCard = this.onSubmitBindBonusCard.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    onSubmitBindBonusCard(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.props.bindBonusCard(this.state[BindCard.BIND_BONUSCARD_NUMBER], this.props.userGroupCode);
    }

    render() {
        return (
            <Grid className="mdc-layout-grid--base">
                <GridCell span={10} className="mdc-layout-grid__cell--pull">
                    <div className="information upper">
                        <Typography
                            use="headline2"
                            className="mdc-typography mdc-theme--primary information__head upper"
                        >
                            <FormattedMessage id="Cards.BindBonusCard" />
                        </Typography>
                        <div className="information__description lighter">
                            <Typography use="body3">
                                <FormattedMessage id="Cards.BindBonusCard.Information" />
                            </Typography>
                        </div>
                    </div>
                    {this.props.bonusCardBindError != null && this.errors(this.props.bonusCardBindError, "Cards.BindBonusCard.Error")}

                    <Grid tag="form" span={12} className="mdc-layout-grid--base" noValidate onSubmit={this.onSubmitBindBonusCard}>
                        <GridCell span={12}>
                            <TextFieldGroup
                                field={BindCard.BIND_BONUSCARD_NUMBER}
                                onChange={this.onChange}
                                validator={this.validator}
                                rules="required|numeric|min:16|regex:^\d+$"
                                value={this.state[BindCard.BIND_BONUSCARD_NUMBER]}
                                label="Cards.BindBonusCard.CardNumber"
                                type="number"
                            />
                        </GridCell>
                        <GridCell span={12}>
                            <Button
                                type="submit"
                                disabled={this.props.bonusCardBindSaving}
                                unelevated
                                className="mdc-button--primary right"
                            >
                                {this.props.bonusCardBindSaving ?
                                    <Loader type={Loader.TYPE_BUTTON} />
                                    :
                                    <FormattedMessage id="Cards.BindBonusCard.BindCard" />
                                }
                            </Button>
                        </GridCell>
                    </Grid>
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        bonusCardBindSaving: state.bindBonusCard.fetching,
        bonusCardBindError: state.bindBonusCard.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        { bindBonusCard }, 
        dispatch
    );
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(BindCard));
