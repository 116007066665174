import BusinessApi from './BusinessApi';
import auth from "../auth/authenticate";

export const FETCH_SERVICE_POINTS = 'FETCH_SERVICE_POINTS';
export const FETCH_SALE_POINTS = 'FETCH_SALE_POINTS';
export const SEARCH_SALE_POINTS = 'SEARCH_SALE_POINTS';
export const FETCH_SALE_POINT_TAXONOMIES = 'FETCH_SALE_POINT_TAXONOMIES';

export const fetchServicePoints = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_SERVICE_POINTS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/stations/service-points`)
    };
};

export const fetchSalepoints = (services, fuels) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: FETCH_SALE_POINTS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/stations/sale-points-filter`, {
            params: {
                services: services,
                fuels: fuels
            },
            paramsSerializer: {
                indexes: null
            }
        })
    }
}

export const searchSalepoints = (query) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: SEARCH_SALE_POINTS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/stations/sale-points-search`, {
            params: {
                query: query
            }
        })
    }
}

export const fetchSalepointTaxonomies = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: FETCH_SALE_POINT_TAXONOMIES,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/stations/sale-point-taxonomies`)
    }
}
