import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import "../styles/react-components/table.scss";
import TableList from "../components/common/TableList";
import Loader from "../components/common/Loader";
import exportToExcel from "../components/common/ExcelExport";
import auth from "../auth/authenticate";
import StatisticsFilter from "../components/Statistics/StatisticsFilter";
import { fetchContractStatistics, fetchContracts } from "../actions/contractActions";
import language from "../lang/lang";

import { getStatisticsFilter, setStatisticsFilter } from "../cachedFilter";

class Statistics extends React.Component {

    static TableHeaders = [
        {
            label: "Statistics.Table.Type",
            colspan: 1
        },
        {
            label: "Statistics.Table.Mark",
            colspan: 1
        },
        {
            label: "Statistics.Table.Amount",
            colspan: 1
        },
        {
            label: "Statistics.Table.StationPrice",
            colspan: 1
        },
        {
            label: "Statistics.Table.Discount",
            colspan: 1
        },
        {
            label: "Statistics.Table.PurchasePrice",
            colspan: 1
        },
        {
            label: "Statistics.Table.Vat",
            colspan: 1
        },
        {
            label: "Statistics.Table.PurchasePriceWoVat",
            colspan: 1
        }
    ];


    constructor(props) {
        super(props);

        const cachedFilter = getStatisticsFilter();

        this.state = {
            isCompany: auth.isCompany(),
            selected: null,
            filterOpen: true,
            filter: {
                dateFrom: moment().subtract(4, "months").startOf("month"),
                dateTo: moment(),
                selectedPeriodPreset: cachedFilter.selectedPeriodPreset,
                selectedPeriodPresetName: cachedFilter.selectedPeriodPresetName,
                selectedContracts: cachedFilter.selectedContracts,
                contractName: cachedFilter.contractName
            }
        };

        this.toggleFilter = this.toggleFilter.bind(this);
        this.onFilterSubmit = this.onFilterSubmit.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
    }

    componentDidMount() {
        if (this.state.isCompany) {
            if (!this.props.fetchingContracts && !this.props.fetchedContracts) {
                this.props.fetchContracts();
            }
        }
    }

    renderTableRowJson(transaction) {
        const result = {
            content: [
                {
                    content: transaction.type
                },
                {
                    content: transaction.mark
                },
                {
                    content: transaction.amount
                },
                {
                    content: transaction.stationPrice
                },
                {
                    content: transaction.discount
                },
                {
                    content: transaction.purchasePrice
                },
                {
                    content: transaction.vat
                },
                {
                    content: transaction.purchasePriceWoVat
                }
            ]
        };
        return result;
    }

    renderStatisticsTable(statistics) {
        if (!this.props.contractStatistics || this.props.contractStatistics.length < 1) {
            return null;
        }

        const transactions = statistics.transactions;
        const summary = statistics.summary;

        const tableHeaders = [
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.Type" }),
                colspan: 1
            },
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.Mark" }),
                colspan: 1
            },
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.Amount" }),
                colspan: 1
            },
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.StationPrice" }),
                colspan: 1
            },
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.Discount" }),
                colspan: 1
            },
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.PurchasePrice" }),
                colspan: 1
            },
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.Vat" }),
                colspan: 1
            },
            {
                label: this.props.intl.formatMessage({ id: "Statistics.Table.PurchasePriceWoVat" }),
                colspan: 1
            },
        ];
        const tableRowsJson = transactions.map(this.renderTableRowJson);

        tableRowsJson.push({
            content: [
                {
                    content: <span className="bold">
                        {this.props.intl.formatMessage({ id: "Statistics.Table.Sum" })}
                    </span>
                },
                {
                    content: ""
                },
                {
                    content: <span className="bold">
                        {summary.amount}
                    </span>
                },
                {
                    content: <span className="bold">
                        {summary.stationPrice}
                    </span>
                },
                {
                    content: <span className="bold">
                        {summary.discount}
                    </span>
                },
                {
                    content: <span className="bold">
                        {summary.purchasePrice}
                    </span>
                },
                {
                    content: <span className="bold">
                        {summary.vat}
                    </span>
                },
                {
                    content: <span className="bold">
                        {summary.purchasePriceWoVat}
                    </span>
                }
            ]
        });

        return (
            <TableList
                key={"table"}
                headers={tableHeaders}
                itemContent={tableRowsJson} />
        );
    }

    toggleFilter = () => {
        this.setState({ filterOpen: !this.isFilterOpen() });
    };

    toContractNumberArray(contracts) {
        let map = contracts.reduce(function (map, obj) {
            map[obj.number] = obj;
            return map;
        }, {});
        return Object.keys(map);
    }

    toContractIdArray(contracts) {
        let map = contracts.reduce(function (map, obj) {
            map[obj.id] = obj;
            return map;
        }, {});
        return Object.keys(map);
    }

    onFilterSubmit(filter) {
        if (this.state.isCompany) {
            this.setState({ filter: filter });

            if (!this.props.fetchingContracts) {
                let contractIds = this.toContractIdArray(filter.selectedContracts).join(",");
                let contractNumbers = this.toContractNumberArray(filter.selectedContracts).join(",");
                this.props.fetchContractStatistics(
                    contractNumbers,
                    contractIds,
                    filter.contractName,
                    filter.dateFrom.format("YYYY-MM-DD"),
                    filter.dateTo.format("YYYY-MM-DD"),
                    this.props.intl.locale || language.getDefaultLocale()
                );
            }
            setStatisticsFilter(filter);
        }
    }

    exportToExcel() {
        let result = [];
        this.props.contractStatistics.transactions.forEach(transaction => {
            const detailedTransaction = {
                type: transaction.type,
                mark: transaction.mark,
                amount: transaction.amount,
                stationPrice: transaction.stationPrice,
                discount: transaction.discount,
                purchasePrice: transaction.purchasePrice,
                vat: transaction.vat,
                purchasePriceWoVat: transaction.purchasePriceWoVat
            }
            result.push(detailedTransaction);
        });

        let summary = this.props.contractStatistics.summary;
        summary.type = this.props.intl.formatMessage({ id: "Statistics.Table.Sum" });

        result.push(summary);

        const columns = [
            {
                field: 'type',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.Type" }),
            },
            {
                field: 'mark',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.Mark" }),
            },
            {
                field: 'amount',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.Amount" }),
            },
            {
                field: 'stationPrice',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.StationPrice" }),
            },
            {
                field: 'discount',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.Discount" }),
            },
            {
                field: 'purchasePrice',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.PurchasePrice" }),
            },
            {
                field: 'vat',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.Vat" }),
            },
            {
                field: 'purchasePriceWoVat',
                title: this.props.intl.formatMessage({ id: "Statistics.Table.PurchasePriceWoVat" }),
            },
        ];

        exportToExcel(columns, result, "Statistika.xlsx")
    }

    render() {
        const hasItems = this.props.contractStatistics?.transactions !== undefined && this.props.contractStatistics?.transactions.length > 0;

        return (
            <Grid>
                <GridCell span={12}>
                    {hasItems &&
                        <Button outlined className="right hidden-mobile mr-mobile-up-10" onClick={this.exportToExcel}>
                            <i className="icon-download" />
                            <FormattedMessage id="Invoices.Export" />
                        </Button>
                    }
                </GridCell>

                <GridCell span={12}>
                    <StatisticsFilter
                        open={true}
                        toggle={this.toggleFilter}
                        onSubmit={this.onFilterSubmit}
                        filter={this.state.filter}
                    />
                </GridCell>

                <GridCell span={12}>
                    {this.props.contractStatisticsFetching
                        ? <Loader type={Loader.TYPE_CENTER} />
                        : this.renderStatisticsTable(this.props.contractStatistics)
                    }
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        contractStatistics: state.contractStatistics.data,
        contractStatisticsFetching: state.contractStatistics.fetching,
        contractStatisticsFetched: state.contractStatistics.fetched,

        contracts: state.contracts.data,
        fetchingContracts: state.contracts.fetching,
        fetchedContracts: state.contracts.fetched,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchContracts,
        fetchContractStatistics
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(Statistics)
);