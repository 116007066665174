import React from "react";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
class CardBookingRow extends React.Component {

  constructor(props) {
    super(props);

    const date = props.booking.posTime && moment(props.booking.posTime);
    const isOlderThenMonth = moment().diff(date, 'months') > 1;

    this.state = {

      date: date,
      isOlderThenMonth: isOlderThenMonth
    };

  }

  renderBookingSum = (sum, unit) => {
    return `${this.props.intl.formatNumber(sum, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })} ${unit ? unit : "€"}`;
  };

  render() {
    const booking = this.props.booking;
    const date = this.state.date;
    const googleQuery = encodeURIComponent("Alexela " + booking.station);

    return (
      <React.Fragment>
        <tr>
        <td className="hidden-desktop wrap">{booking.station}</td>
          <td className="table--card-body-info">{date && date.format("DD.MM.YYYY HH:mm")}</td>
          <td className="show-open">{booking.cardNumber}</td>
          <td className="show-open">{booking.cardName}</td>
          <td className="show-open hidden-desktop">
            <a className="icon icon-angle-right" href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${googleQuery}`} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="Transactions.Table.ServicePoint.GoThere" />
            </a>
          </td>
          <td className="hidden-mobile">{booking.station}</td>
          <td className="text-right pull-right">
            {this.renderBookingSum(booking.amount, null)}
          </td>
          <td className="hidden-mobile">

          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default injectIntl(CardBookingRow);
