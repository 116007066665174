import React from "react";
import { LoginPath, CardsPath } from "../const/routes";
import ReferralLink from "./../components/common/ReferralLink";
import { Redirect } from "react-router-dom";

class CardOrderReferral extends React.Component{

    render() {
        ReferralLink.setReferralLinkParams(this.props.location.search);
        return ( <Redirect to={{ pathname: LoginPath , state: { from: CardsPath }}} /> );
    }
}

export default CardOrderReferral;