import { FETCH_CUSTOMER_CONTRIBUTION } from "../../actions/communityProjectActions";

export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_CUSTOMER_CONTRIBUTION}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${FETCH_CUSTOMER_CONTRIBUTION}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${FETCH_CUSTOMER_CONTRIBUTION}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        case `${FETCH_CUSTOMER_CONTRIBUTION}_RESET`: {
            return {...state, fetching: false, fetched: false, error: null, data: null};
        }
        default:
            return state;
    }
}