
import contentDisposition from "content-disposition";
import auth from "../auth/authenticate";
import BusinessApi from "../actions/BusinessApi";

export function getFileNameFromResponseHeaders(headers) {
    const cdString = headers["Content-Disposition"] || headers["content-disposition"];

    if (!cdString)
        return null;

    const cdObject = contentDisposition.parse(cdString);

    return !!cdObject && !!cdObject.parameters && !!cdObject.parameters.filename
        ? cdObject.parameters.filename
        : null;
}

export function directFileDownloadWithBodyAction(url, body) {

    const uri = BusinessApi.getUri({
        url,
        params: body
    })

    downloadGET(uri);

    return { type: "DIRECT_FILE_DOWNLOAD" };
}

export function directFileDownloadAction(endpoint, fileName, parameters) {
    const token = auth.getToken();

    if (!!token) {
        parameters = {
            ...parameters,
            token: token.jwt,
            download: 1,
            origin: window.location.hostname,
        };
    }

    if (!!fileName) {
        parameters = { ...parameters, fileName: fileName };
    }

    const uri = BusinessApi.getUri({
        url: endpoint,
        params: parameters
    })

    downloadGET(uri);

    return { type: "DIRECT_FILE_DOWNLOAD" };
}

export function directFileDownloadPostAction(endpoint, model) {
    const token = auth.getToken();

    const parameters = !!token
        ? {
            token: token.jwt,
            download: 1,
            origin: window.location.hostname
        }
        : {};

    const uri = BusinessApi.getUri({
        url: endpoint,
        params: parameters
    })

    downloadPOST(uri, model);

    return { type: "DIRECT_FILE_DOWNLOAD" };
}


function downloadGET(url) {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.download = "";

    // console.log(link)

    // It needs to be added to the DOM so it can be clicked
    document.body.appendChild(link);
    link.click();

    // To make this work on Firefox we need to wait
    // a little while before removing it.
    setTimeout(() => {
        document.body.removeChild(link);
    }, 0);
}

function downloadPOST(url, model) {

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);

    for (const key in model) {
        if (model.hasOwnProperty(key)) {
            let input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = model[key];
            form.appendChild(input);
        }
    }
    console.log(form)
    document.body.appendChild(form);

    form.submit();
    document.body.removeChild(form);
}
