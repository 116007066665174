import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AlertMessage from "./AlertMessage";
import { fetchActiveOffers } from "../../actions/offerActions";
import getPathName from "../../helpers/offerHelper";

const OverviewNotices = () => {
    const offers = useSelector(state => state.activeOffers);

    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        dispatch(fetchActiveOffers());
    }, [dispatch]);

    const createNoticeItem = (offer) => {
        return (
            <div className="pt-10" key={offer.id}>
                <AlertMessage
                    isSmall
                    type={AlertMessage.TYPE_NOTICE}
                    title={intl.formatMessage({ id: `Overview.Notice.${offer.applicationType}` }, { sum: offer.limit })}
                    description={
                        <div className="link-card--content-url">
                            <Link to={{
                                pathname: getPathName(offer.applicationType, offer.id),
                                state: {
                                    applicationType: offer.applicationType
                                }
                            }}>
                                {intl.formatMessage({ id: "Overview.Notice.ViewOffer" })}
                            </Link>
                            <i className="icon icon-arrow-right" />
                        </div>
                    }
                />
            </div>
        );
    }

    const renderOfferNotices = () => {
        const { data, fetched } = offers;

        if (!fetched) {
            return null;
        }

        return data.map(offer => createNoticeItem(offer));
    }

    return (
        <>
            {renderOfferNotices()}
        </>
    );
}

export default OverviewNotices;