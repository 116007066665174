import { ElectricityNew, GasNew, Fuel } from "./const/contractType";
import { InProgress, Active } from "./const/ContractStatus";
import { CardStatusType } from "./const/paymentCardFilter";

const CachedFilterStorageKey = "cachedFilter";
const InvoicesKey = "invoices";
const InvoicesCardKey = "card";
const TransactionsKey = "transactions";
const TransactionsBonusCardKey = "bonusCard";
const TransactionsCardKey = "card";
const ContractsKey = "contracts";
const CardsKey = "cards";
const PaymentsKey = "payments";
const StatisticsKey = "statistics";

const DefaultCachedFilter = {
    invoices: {
        card: {
            invoiceNumber: "",
            contractNumber: "",
            contractName: "",
            status: ""
        }
    },
    transactions: {
        bonusCard: {
            bonuscard: null,
            servicePoint: null,
            selectedPeriodPreset: null,
            selectedPeriodPresetName: null
        },
        card: {
            card: null,
            servicePoint: null,
            selectedPeriodPreset: null,
            selectedPeriodPresetName: null,
            invoiceNumber: "",
            cardName: "",
            contractNumber: ""
        }
    },
    contracts: {
        contractExtraNumber: "",
        contractType: [ElectricityNew, GasNew, Fuel],
        contractStatus: [InProgress, Active],
    },
    cards: {
        startDate: null,
        endDate: null,
        cardNumber: null,
        cardStatusType: CardStatusType.StatusOpen,
        contractNumber: null,
        cardName: null
    },
    payments: {
        bankName: "",
        paymentTypeXc: "",
        contractNumber: null,
        startAmount: null
    },
    statistics: {
        selectedPeriodPreset: null,
        selectedPeriodPresetName: null,
        selectedContracts: [],
        contractName: ""
    }
}

const setDefaultCachedFilter = () => {
    if (!localStorage.getItem(CachedFilterStorageKey)) {
        localStorage.setItem(CachedFilterStorageKey, JSON.stringify(DefaultCachedFilter));
    }
}

const resetCachedFilterToDefault = () => {
    localStorage.removeItem(CachedFilterStorageKey);
    setDefaultCachedFilter();
}

const setCachedFilter = (filter) => {
    localStorage.setItem(CachedFilterStorageKey, JSON.stringify(filter));
}

const getCachedFilter = () => {
    const filter = localStorage.getItem(CachedFilterStorageKey)
    if (filter) {
        return JSON.parse(filter);
    }

    setDefaultCachedFilter();
    return DefaultCachedFilter;
}

const getFilterByKey = (key, subKey) => {
    const filter = getCachedFilter();
    const filterByKey = filter[key];

    if (subKey) {
        return filterByKey[subKey];
    }

    return filterByKey;
}

const setFilterByKey = (key, subKey, filter) => {
    const cachedFilter = getCachedFilter();
    let filterByKey = cachedFilter[key];

    if (subKey) {
        filterByKey[subKey] = {...filterByKey[subKey], ...filter};
    } else {
        filterByKey = {...filterByKey, ...filter};
        cachedFilter[key] = filterByKey;
    }

    setCachedFilter(cachedFilter);
}

const getInvoicesCardFilter = () => {
    return getFilterByKey(InvoicesKey, InvoicesCardKey);
}

const getTransactionsBonusCardFilter = () => {
    return getFilterByKey(TransactionsKey, TransactionsBonusCardKey);
}

const getTransactionsCardFilter = () => {
    return getFilterByKey(TransactionsKey, TransactionsCardKey);
}

const getContractsFilter = () => {
    return getFilterByKey(ContractsKey);
}

const getCardsFilter = () => {
    return getFilterByKey(CardsKey);
}

const getPaymentsFilter = () => {
    return getFilterByKey(PaymentsKey);
}

const getStatisticsFilter = () => {
    return getFilterByKey(StatisticsKey);
}

const setInvoicesCardFilter = (filter) => {
    return setFilterByKey(InvoicesKey, InvoicesCardKey, filter);
}

const setTransactionsBonusCardFilter = (filter) => {
    return setFilterByKey(TransactionsKey, TransactionsBonusCardKey, filter);
}

const setTransactionsCardFilter = (filter) => {
    return setFilterByKey(TransactionsKey, TransactionsCardKey, filter);
}

const setContractsFilter = (filter) => {
    return setFilterByKey(ContractsKey, null, filter);
}

const setCardsFilter = (filter) => {
    return setFilterByKey(CardsKey, null, filter);
}

const setPaymentsFilter = (filter) => {
    return setFilterByKey(PaymentsKey, null, filter);
}

const setStatisticsFilter = (filter) => {
    return setFilterByKey(StatisticsKey, null, filter);
}

const getInvoicesCardDefaultFilter = () => {
    return {...DefaultCachedFilter.invoices.card};
}

const getTransactionsBonusCardDefaultFilter = () => {
    return {...DefaultCachedFilter.transactions.bonusCard};
}

const getTransactionsCardDefaultFilter = () => {
    return {...DefaultCachedFilter.transactions.card};
}

const getContractsDefaultFilter = () => {
    return {...DefaultCachedFilter.contracts};
}

const getCardsDefaultFilter = () => {
    return {...DefaultCachedFilter.cards};
}

const getPaymentsDefaultFilter = () => {
    return {...DefaultCachedFilter.payments};
}

const getStatisticsDefaultFilter = () => {
    return {...DefaultCachedFilter.statistics};
}

export {
    CachedFilterStorageKey,
    setDefaultCachedFilter,
    resetCachedFilterToDefault,
    getCachedFilter,
    getInvoicesCardFilter,
    getTransactionsBonusCardFilter,
    getTransactionsCardFilter,
    getContractsFilter,
    getCardsFilter,
    getPaymentsFilter,
    getStatisticsFilter,
    setInvoicesCardFilter,
    setTransactionsBonusCardFilter,
    setTransactionsCardFilter,
    setContractsFilter,
    setCardsFilter,
    setPaymentsFilter,
    setStatisticsFilter,
    getInvoicesCardDefaultFilter,
    getTransactionsBonusCardDefaultFilter,
    getTransactionsCardDefaultFilter,
    getContractsDefaultFilter,
    getCardsDefaultFilter,
    getPaymentsDefaultFilter,
    getStatisticsDefaultFilter
}