import React from "react";
import { FormattedMessage } from 'react-intl';
import TextFieldGroup from "../../components/common/TextFieldGroup";
import PhoneFieldGroup from "../../components/common/PhoneFieldGroup";
import StaticField from "../../components/common/StaticField";
import BaseFormComponent from "../../components/common/BaseFormComponent";
import classnames from 'classnames';
import SimpleReactValidator from "simple-react-validator";
import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import ContactSelect from "../contact/ContactSelect";

class BaseContractJoin extends BaseFormComponent {
    static FieldContactId = "contact.crmId";
    static FieldContactEmail = "contact.email";
    static FieldSelectedContact = "selectedContact";
    static FieldContactMobile = "contact.mobile";
    static FieldPlasticCard = "plasticCard";
    static FieldPromotionCode = "promotionCode";
    static FieldTermsNotifications = "terms.notifications";
    static FieldTermsAccept = "terms.accept";
    static FieldTermsAddressCity = "address.city";
    static FieldTermsAddressCounty = "address.county";
    static FieldTermsAddressStreet = "address.street";
    static FieldTermsAddressParish = "address.parish";
    static FieldTermsAddressPostalCode = "address.postalCode";
    static FieldTermsAddressHouseNr = "address.houseNr";
    static FieldTermsAddressApartmentNr = "address.apartmentNr";
    static ELECTRICITY_LOCATION_TYPE = "electricity";
    static GAS_LOCATION_TYPE = "gas";

    constructor(props) {
        super(props);

        this.state = {
            selectedContact: "",
            contact: {
                email: "",
                crmId: "",
                mobile: ""
            },
            address: {
                city: "",
                county: "",
                street: "",
                postalCode: "",
                houseNr: "",
                apartmentNr: "",
                parish: ""
            },
            plasticCard: false,
            promotionCode: '',
            terms: {
                notifications: false,
                accept: false
            },
            isTermsDialogOpen: false,
            showErrors: false
        };

        this.updateAddress = this.updateAddress.bind(this);
        this.updateStateValues = this.updateStateValues.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onContactSelectChange = this.onContactSelectChange.bind(this);

        this.renderErrorsBlock = this.renderErrorsBlock.bind(this);
        this.uiErrorsRef = React.createRef();

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale,

            validators: {
                phoneformat: {
                  message: this.props.intl.formatMessage({id: 'Error.Validator.Phoneformat'}),
                  rule: (val, params, validator) => {
                     return validator.helpers.testRegex(val,/^\d{7,14}$/i)
                  },
                  messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        let state = {};
        let update = false;

        if (prevProps.account !== this.props.account) {
            update = true;
            state.address = this.props.account.address;
        }

        if (prevProps.mainContact !== this.props.mainContact) {
            update = true;
            state.contact = {...this.props.mainContact}
        }

        // This is only for step1. Move it
        if (this.props.stepData && prevProps.stepData !== this.props.stepData) {
            update = true;
            state.terms = this.props.stepData;
        }

        if (update) {
            this.setState({ ...state });
        }
    }

    onContactSelectChange(value) {
        if (value) {
            this.setState({ contact: { ...this.props.contacts[value] } });
        }
    }

    updateAddress(address) {
        this.setState({ address: address });
    }

    renderErrorsBlock() {
        return (<>{this.props.errors != null &&
        this.errors(this.props.errors)}
            {this.state.showErrors &&
            this.errorsFromObject(
                this.validator.getErrorMessages(),
                this.uiErrorsRef
            )}</>);
    }

    renderInformationBlock(titleId, contentId) {
        return (
            <div className="information mb-60 mb-mobile-45">
                <h1 className="mdc-typography mdc-typography--headline1 mdc-theme--primary information__head">
                    <FormattedMessage id={titleId}/>
                </h1>
                <div className="information__description">
                    <FormattedMessage tagName="p" id={contentId}/>
                </div>
            </div>
        );
    }

    renderTitleBlock(translationId, classNames) {
        return (
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <h2 className={classnames("mdc-typography mdc-typography--headline2 mdc-theme--primary", classNames)}>
                    <FormattedMessage id={translationId} />
                </h2>
            </div>
        );
    }

    renderTitleWithInformationBlock(titleId, contentId) {
        return (
            <GridCell span={12}>
                <div className="information clear">
                    <Typography
                        use="headline2"
                        className="mdc-typography down mdc-theme--primary up"
                    >
                        <FormattedMessage id={titleId}/>
                    </Typography>
                    <div className="information__description">
                        <FormattedMessage
                            tagName="p"
                            id={contentId}
                        />
                    </div>
                </div>
            </GridCell>)
    }

    renderCardOwnerBlock() {
        return (
            <GridCell
                className="mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop">
                <Grid
                    className={classnames(
                        "mdc-layout-grid--base mdc-layout-grid--form",
                        {
                            "loader loader--background loader--light": this
                                .props.isAccountLoading
                        }
                    )}
                >
                    <StaticField
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-3-desktop"
                        value={this.props.account.name}
                        label={
                            <FormattedMessage
                                id="Contract.Step1.Account.Name"
                            />
                        }
                    />
                    <StaticField
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-2 mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-3-desktop"
                        value={this.props.account.companyCode}
                        label={
                            <FormattedMessage
                                id="Contract.Step1.Account.IdentificationCode"
                            />
                        }
                    />
                </Grid>
            </GridCell>
        );
    }

    renderContactBlock(hasContacts) {
        return (
            <>
                {hasContacts &&
                <GridCell tablet={8} desktop={6}>
                    <ContactSelect
                        wrapperClassName="mt-40 mb-30"
                        className="mt-40 mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-6-desktop"
                        field={BaseContractJoin.FieldSelectedContact}
                        value={this.state.selectedContact}
                        onChange={this.onSelectChange}
                        afterChange={this.onContactSelectChange}
                        label="Contract.Step1.Contact.Fullname" />
                </GridCell>
                }
                <GridCell tablet={8} desktop={12}>
                    <TextFieldGroup
                        field={BaseContractJoin.FieldContactEmail}
                        validator={this.validator}
                        rules="required|email"
                        onChange={this.onChange}
                        value={this.state.contact.email || ""}
                        label="Contract.Step1.Contact.Email" />
                </GridCell>
                <GridCell tablet={8} desktop={6}>
                    <PhoneFieldGroup
                        validator={this.validator}
                        rules="required|phoneformat"
                        field={BaseContractJoin.FieldContactMobile}
                        onUpdate={this.updateState.bind(this)}
                        value={this.state.contact.mobile || ""}
                        label="Contract.Step1.Contact.Phone"/>
                </GridCell>
            </>
        );
    }
}

export default BaseContractJoin;
