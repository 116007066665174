import List from "@material/react-list";
import { Button } from "@rmwc/button";
import { Dialog, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import { GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useValidator } from "../../helpers/hooks";
import RadioField from "../common/RadioField";
import TextFieldGroup from "../common/TextFieldGroup";
import { putUserWithRole } from "../../actions/accountActions";
import { useDispatch } from "react-redux";
import AlertMessage from "../common/AlertMessage";

const RolesRadioButtons = [
    {
        fieldName: "Admin",
        fieldLabel: "UserManagement.Field.Radio.Admin"
    },
    {
        fieldName: "Agent",
        fieldLabel: "UserManagement.Field.Radio.Agent"
    },
    {
        fieldName: "User",
        fieldLabel: "UserManagement.Field.Radio.User"
    }
];

const FieldSsn = "isikukood";

const UserManagementDialog = ({ isOpen, onClose, selectedUser }) => {
    const [ssn, setSsn] = useState("");
    const [userGroupCode, setUserGroupCode] = useState("");
    const [roles, setRoles] = useState(RolesRadioButtons[0].fieldName);
    const [isAlreadyInRoleError, setIsAlreadyInRoleError] = useState(false);

    const dispatch = useDispatch();
    const [validator, ] = useValidator();

    useEffect(() => {
        setIsAlreadyInRoleError(false);
        setSsn(selectedUser?.ssn || "");
        setUserGroupCode(selectedUser?.userGroupCode || "");
        setRoles(selectedUser?.roles || RolesRadioButtons[0].fieldName);
    }, [selectedUser]);

    const onChangeSsn = (e) => {
        setSsn(e.target.value);
    }

    const onChangeGroupNumber = (e) => {
        setUserGroupCode(e.target.value);
    }

    const onSubmit = () => {
        if (selectedUser === null && !validator.allValid()) {
            validator.showMessages();
            return;
        }

        if (selectedUser?.roles === roles) {
            setIsAlreadyInRoleError(true);
            return;
        }

        const data = {
            ssn,
            roles,
            userGroupCode: userGroupCode || null
        };

        dispatch(putUserWithRole(data));
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            onClose={() => onClose(false)}
            className="mdc-dialog--simple"
        >
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss"
                />
            </DialogTitle>
            <DialogContent>
                <GridCell span={12} className="mb-30">
                    <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                        <FormattedMessage id="UserManagement.Dialog.Title" />
                    </Typography>
                </GridCell>
                {selectedUser &&
                    <GridCell span={12} className="mb-30">
                        <Typography use="headline5">
                            {`${selectedUser.fullName} (${selectedUser.ssn})`}
                        </Typography>
                    </GridCell>
                }
                {selectedUser === null &&
                    <GridCell span={12}>
                        <TextFieldGroup
                            field={FieldSsn}
                            onChange={onChangeSsn}
                            validator={validator}
                            rules="required"
                            value={ssn}
                            type="text"
                            label="UserManagement.Field.PersonalCode"
                        />
                    </GridCell>
                }
                <GridCell span={12}>
                    <TextFieldGroup
                        field="group_number"
                        onChange={onChangeGroupNumber}
                        value={userGroupCode}
                        type="number"
                        label="UserManagement.Field.UserGroupNumber"
                    />
                </GridCell>
                <GridCell span={12}>
                    <Typography use="body1" className="mdc-typography mt-20 mdc-typography--bold">
                        <FormattedMessage id="UserManagement.Field.Role" />
                    </Typography>
                    <List className="mt-20">
                        {RolesRadioButtons.map((item, index) =>
                            <li className="form-fields-list__radio-item" key={index}>
                                <RadioField
                                    index={index}
                                    fieldName={item.fieldName}
                                    fieldValue={item.fieldName}
                                    fieldLabel={
                                        <div className="mdc-typography mt-5 mdc-typography--body1">
                                            <FormattedMessage id={item.fieldLabel} />
                                        </div>
                                    }
                                    selectedValue={roles}
                                    onChange={() => setRoles(item.fieldName)}
                                />
                            </li>
                        )}
                    </List>
                </GridCell>
                <GridCell span={12}>
                    {isAlreadyInRoleError &&
                        <AlertMessage
                            isSmall={true}
                            type={AlertMessage.TYPE_ALERT}
                            title={<FormattedMessage id="UserManagement.Dialog.Error.AlreadyInRole" />}
                        />
                    }
                </GridCell>
                <GridCell span={12} className="mt-30">
                    <Button
                        type="submit"
                        onClick={onSubmit}
                        unelevated
                        className="mdc-button--primary"
                    >
                        <i className="icon-floppy" />
                        <FormattedMessage id="UserManagement.Dialog.Add" />
                    </Button>
                </GridCell>
            </DialogContent>
        </Dialog>
    );
}

export default UserManagementDialog;