// appInsights.js

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
// import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from 'history';

// const browserHistory = createBrowserHistory({ basename: '' });
// const reactPlugin = new ReactPlugin();

const appInsights = !!process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING
    ? new ApplicationInsights({
        config: {
            connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
            loggingLevelConsole: process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "test" ? 2 : 0, // log errors and warning in test/local, nothing in live
            disableFetchTracking: true
            // extensions: [reactPlugin],
            // extensionConfig: {
            //   [reactPlugin.identifier]: { history: browserHistory },
            // },
        },
    })
    : null;

if (appInsights) {
    appInsights.loadAppInsights();
}

const trackEvent = (name, properties) => {
    if (!appInsights) return;
    appInsights.trackEvent({ name: name, properties: properties });
}

const trackInfo = (message) => {
    if (!appInsights) return;
    appInsights.trackTrace({ message: message, severityLevel: 1 });
}

const trackError = (message) => {
    if (!appInsights) return;
    appInsights.trackTrace({ message: message, severityLevel: 3 });
}

const trackException = (error) => {
    if (!appInsights) return;
    appInsights.trackException({ error: error });
}


export { /*reactPlugin,*/ trackEvent, trackInfo, trackError, trackException };