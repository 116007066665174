import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import Contract from "../components/Contract/Contract";
import ContractSign from "../components/Contract/ContractSign";
import AlertMessage from "../components/common/AlertMessage";
import { Gas } from "../const/contractType";
import auth from "../auth/authenticate";

class ContractGas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProduct: null,
            showProducts: true,
            isCompany: auth.isCompany(),
        };
        this.toggleView = this.toggleView.bind(this);
    }

    toggleView(selectedProduct) {
        this.setState(prev => ({
            showProducts: !prev.showProducts,
            selectedProduct: selectedProduct
        }));
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                {this.state.isCompany &&
                    <Grid>
                        <GridCell span={12}>
                            <AlertMessage
                                title={<FormattedMessage id="Contract.Gas.ContactClientService" />}
                            />
                        </GridCell>
                    </Grid>
                }
                {!this.state.isCompany &&
                    (this.state.showProducts
                        ? <Contract
                            contractType={Gas}
                            goNext={this.toggleView}
                            productCode={this.props.match?.params?.productCode ?? null}
                        />
                        : <ContractSign
                            contractType={Gas}
                            selectedProduct={this.state.selectedProduct}
                            goBack={this.toggleView}
                        />
                    )
                }
            </>
        );
    }
}
export default ContractGas;