import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Icon } from "@rmwc/icon";
import { Typography } from "@rmwc/typography";

class BadgeCard extends React.Component {

    static TYPE = {
        DONE: "done",
        NEW: "new",
        INPROGRESS: "inprogress",
        NONE: "none",
        DISABLED: "disabled",
        CUSTOM: "custom",
        PERMISSION_REQUIRED: "permissionRequired",
        GASO_METER_SUBMISSION : "gasoMeterSubmission",
    };

    renderBadge(icon, badgeType) {
        return (
            <div
                className={classnames(
                    "badge-card__badge badge badge--discount badge--medium",
                    badgeType
                )}
            >
                {badgeType === BadgeCard.TYPE.CUSTOM ?
                    <img className="badge-custom-img" alt="" src={icon} />
                    :
                    <Icon
                        icon={{
                            icon: icon,
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-"
                        }}
                    />

                }

            </div>
        );
    }

    render() {
        const { title, subTitle, description, icon, type, actions, contentChildren, highlighted, className } = this.props;

        return (
            <div className={classnames("badge-card", className,
                {
                    "badge-card--highlighted": highlighted,
                    [`badge-card--${type}`]: type !== BadgeCard.TYPE.NONE,
                    "badge-card--no-actions": !actions || actions.length <= 0
                }
            )}>
                <div className="badge-card-body">
                    {this.renderBadge(icon, type)}

                    <div className="wrapper">
                        <div className="badge-card__content">
                            <Typography
                                use="headline4"
                                tag="h4"
                                className="title"
                            >
                                {title}
                            </Typography>
                            {!!subTitle &&
                                <Typography
                                    use="body1"
                                    className="mdc-typography mdc-typography--body1--bold">
                                    {subTitle}
                                </Typography>}
                            <Typography use="body1" className="mdc-typography">
                                {description}
                            </Typography>
                            {contentChildren}
                        </div>
                        {!highlighted &&
                            <div className="badge-card__actions">
                                {actions}
                            </div>}
                    </div>
                </div>
                {highlighted &&
                    <div className="badge-card-footer">
                        <div className="badge-card__actions">
                            {actions}
                        </div>
                    </div>}
            </div >
        );
    }
}

BadgeCard.defaultProps = {
    highlighted: false
}

BadgeCard.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subTitle: PropTypes.any,
    description: PropTypes.any,
    icon: PropTypes.string,
    type: PropTypes.oneOf([BadgeCard.TYPE.DONE, BadgeCard.TYPE.NEW, BadgeCard.TYPE.INPROGRESS, BadgeCard.TYPE.NONE, BadgeCard.TYPE.DISABLED, BadgeCard.TYPE.PERMISSION_REQUIRED, BadgeCard.TYPE.CUSTOM, BadgeCard.TYPE.GASO_METER_SUBMISSION]),
    contentChildren: PropTypes.any,
    actions: PropTypes.any,
    highlighted: PropTypes.bool,
    className: PropTypes.string
}

export default BadgeCard;
