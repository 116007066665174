import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import BaseFormComponent from "../common/BaseFormComponent";
import PropTypes from "prop-types";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import { FormattedMessage, injectIntl } from "react-intl";

class StockPriceAlertFilter extends BaseFormComponent {

    static FieldEmail = "filter.email";
    static FieldCrmId= "filter.crmId";

    constructor(props) {
        super(props);

        this.filter = this.filter.bind(this);

        this.state = {
            filter: {...this.props.filter}
        };
    }

    filter() {
        this.props.onSubmit(this.state.filter);
    }

    render() {
        return (
            <Grid className="mdc-layout-grid--base mb-60">
                <GridCell span={4}>
                    <TextFieldGroup
                        field={StockPriceAlertFilter.FieldEmail}
                        onChange={this.onChange}
                        value={this.state.filter.email || ""}
                        type="email"
                        placeholder={this.props.intl.formatMessage({
                            id: "StockPriceAlerts.Filter.Email"
                        })}
                        label="StockPriceAlerts.Filter.Email" />
                </GridCell>
                <GridCell span={4}>
                    <TextFieldGroup
                        field={StockPriceAlertFilter.FieldCrmId}
                        onChange={this.onChange}
                        value={this.state.filter.crmId || ""}
                        type="number"
                        placeholder={this.props.intl.formatMessage({
                            id: "StockPriceAlerts.Filter.CrmId"
                        })}
                        label="StockPriceAlerts.Filter.CrmId" />
                </GridCell>
                <GridCell span={4}>
                    <Button
                        unelevated
                        onClick={this.filter}
                        className="mdc-button--primary mt-35"
                    >
                        <FormattedMessage id="General.Search" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}

StockPriceAlertFilter.propTypes = {
    onSubmit: PropTypes.func,
    filter: PropTypes.object
};

export default injectIntl(StockPriceAlertFilter);