import React from "react";
import classnames from "classnames";
import { CollapsibleList, SimpleListItem } from "@rmwc/list";
import { Icon } from "@rmwc/icon";
import { Button } from "@rmwc/button";
import PropTypes from "prop-types";
import LinkElement from "../common/LinkElement";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { isLinkActive } from '../../helpers/menuHelper';
import { Typography } from "@rmwc/typography";

class MenuItemMobile extends React.Component {
    toggle = () => {
        if (this.props.item === this.props.selectedItem) {
            this.props.setItem(null);
        } else {
            this.props.setItem(this.props.item);
        }
    };

    isActive = () => {
        return this.props.item === this.props.selectedItem;
    };

    hasNewItem = () => {
        return !!this.props.item.children.find(o => o.isNew);
    };

    render() {
        return (
            <>
                {this.props.item.children ?
                    <CollapsibleList
                        className="mdc-list-submenu"
                        ref={this.setWrapperRef}
                        handle={
                            <Button
                                className={classnames(
                                    "mdc-list-item mdc-list-submenu",
                                    {
                                        active: this.isActive()
                                    }
                                )}
                                onClick={this.toggle}
                            >
                                <LinkElement href={this.props.item.path} className={classnames({
                                    "menu-active": isLinkActive(this.props.location.pathname, this.props.item.path, this.props.item.children)
                                })}>
                                    <FormattedMessage id={this.props.item.name} />
                                </LinkElement>
                                {!this.isActive() && this.hasNewItem() &&
                                <Icon className="mdc-drawer--new_item-icon">
                                </Icon>
                                }
                                <Icon className={classnames("icon arrow",
                                    {
                                        "menu-active": isLinkActive(this.props.location.pathname, this.props.item.path, this.props.item.children)
                                    })} />
                            </Button>
                        }
                        open={this.props.item === this.props.selectedItem}
                    >
                        {this.props.item.children &&
                            this.props.item.children.map((item, index) => {
                                return (
                                    <LinkElement
                                        key={"item" + index}
                                        className="nav__link"
                                        href={item.path}
                                    >
                                        <SimpleListItem><FormattedMessage id={item.name}/>
                                        {item.isNew && <Typography use="body1" className="mdc-drawer--new_item-text"><FormattedMessage id="Menu.New"/></Typography> }
                                        </SimpleListItem>
                                    </LinkElement>
                                );
                            })}
                    </CollapsibleList>

                    :
                    <LinkElement href={this.props.item.path} className={classnames("mdc-list-submenu--no-children", {
                        "active": isLinkActive(this.props.location.pathname, this.props.item.path, this.props.item.children)
                    })}>
                        <FormattedMessage id={this.props.item.name} />
                    </LinkElement>
                }
            </>

        );
    }
}

MenuItemMobile.propTypes = {
    setItem: PropTypes.func,
    selectedItem: PropTypes.object,
    item: PropTypes.object
};

export default injectIntl(withRouter(MenuItemMobile));
