import React from "react";
import {FormattedMessage,} from 'react-intl';
import {Grid, GridCell} from '@rmwc/grid';
import {Typography} from "@rmwc/typography";
import {ContractsPath} from "../const/routes";
import {Button} from "@rmwc/button";

const ContractProductChangeThankYou = ({ isRenewal = false }) => {
    return (
        <Grid>
            <GridCell span={8}>
                <Typography use="headline3 mb-30">
                    <FormattedMessage
                        id={isRenewal ? "ContractProductChange.ThankYou.RenewalTitle" : "ContractProductChange.ThankYou.Title"}/>
                </Typography>
                <Typography use="body1">
                    <FormattedMessage id={"ContractProductChange.ThankYou.Desc"}/>
                </Typography>
                <Button
                    className="mdc-button--secondary mt-30"
                    outlined
                    tag="a"
                    href={ContractsPath}
                >
                    <FormattedMessage id="ContractProductChange.ThankYou.Back"/>
                </Button>
            </GridCell>
        </Grid>
    );
};

export default ContractProductChangeThankYou;