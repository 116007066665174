import classNames from "classnames";
import React from "react";

import "../../styles/components/_inline-tooltip.scss";

export const TooltipPosition = {
    Left: "left",
    Right: "right"
};

export const TooltipType = {
    Info: "tooltip-info",
    Alert: "tooltip-alert"
}

const InlineTooltip = ({ position, text, type, children, className }) => {
    const getTooltipIcon = () => {
        return <i className={classNames("icon icon-info-fill", `tooltip-${position}`, type)} data-tooltip={text} />
    }

    return (
        <div className={classNames("inline-tooltip", className)}>
            {position === TooltipPosition.Left && getTooltipIcon()}
            {children}
            {position === TooltipPosition.Right && getTooltipIcon()}
        </div>
    );
}

export default InlineTooltip;