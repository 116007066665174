import { useDispatch } from "react-redux";
import { downloadEvChargingOfferAgentPdf } from "../actions/evChargingActions";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

const ContractEVChargingAgent = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { id, agentId } = useParams();

    const onDownload = () => {
        dispatch(downloadEvChargingOfferAgentPdf(id, agentId));
    }

    return (
        <Grid>
            <GridCell span={12}>
                <Button
                    unelevated
                    label={intl.formatMessage( { id: "Contract.EvCharging.Agent.DownloadPdf" })}
                    onClick={onDownload}
                />
            </GridCell>
        </Grid>
    );
}

export default ContractEVChargingAgent;