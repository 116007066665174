import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const LinkElement = ({ href, className, children, ...props }) => {
    return (
        (href && href.startsWith("/")) ? 
        <Link {...props} className={className} to={href}>
            {children}
        </Link>
        :
        <a {...props} className={className} href={href}>
            {children}
        </a>
    );
};

LinkElement.propTypes = {
        href: PropTypes.string,
        className: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
            PropTypes.string
        ]),
    };
    
export default LinkElement;