import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Button } from "@rmwc/button";

import "../../styles/components/_tabs.scss";

const Tabs = ({ items, defaultTabIndex, layout = "default" }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);
    const isMobile = useSelector(state => state.ui.isMobileOrTabletView);

    const cssClass = layout === "new"
        ? "tabs-new"
        : "tabs";

    useEffect(() =>
        setActiveTabIndex(defaultTabIndex),
        [defaultTabIndex]);

    const isActiveTab = (tabIndex) => activeTabIndex === tabIndex;

    const onTabClick = (index, item) => {
        setActiveTabIndex(index);
        if (item.onSelect) {
            item.onSelect(index);
        }
    }

    const renderTabItems = () => (
        <div className={classnames(`${cssClass}__nav`, {
            "desktop": !isMobile
        })}>
            {items.map((item, i) =>
            (
                <div key={'tab-' + i} className={`${cssClass}__nav__item`}>
                    <Button className={
                        classnames(
                            {
                                "mdc-button-tab-underlined mdc-button--wide mdc-button--tab-overview uppercase": layout === "default",
                                "active": isActiveTab(i)
                            }
                        )}
                        disabled={item.disabled}
                        onClick={() => onTabClick(i, item)}
                        icon={!!item.icon &&
                        {
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                            ...item.icon
                        }}
                    >
                        {item.label}
                    </Button>
                </div>
            ))
            }
        </div >
    );

    const renderAllTabs = () =>
        items.map((item, i) =>
            <div key={i}
                className={classnames(
                    `${cssClass}__content`,
                    { "hidden": !isActiveTab(i) })}>
                {item.contents}
            </div>
        );

    return (
        <>
            {renderTabItems()}
            {renderAllTabs()}
        </>
    );
}

Tabs.defaultProps = {
    defaultTabIndex: 0,
};

Tabs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            contents: PropTypes.any,
            onSelect: PropTypes.func,
            disabled: PropTypes.bool,
            icon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        })),
    defaultTabIndex: PropTypes.number,
    layout: PropTypes.oneOf(["default", "new"]),
}

export default Tabs;
