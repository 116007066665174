import { useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { useIntl } from "react-intl";
import { GridCell, GridInner } from "@rmwc/grid";
import { AddressField } from "./AddressFormExtended";
import { getAddressAsString } from "../../helpers/addressHelper";
import TextFieldGroup from "../common/TextFieldGroup";
import { IsLatvianWeb } from "../../helpers/AlternativeDesign";
import Loader from "../common/Loader";

import "../../styles/components/_google-address-autocomplete.scss";

const GoogleAddressAutoCompleteForm = ({ onChange, address }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API,
        libraries: ["places"]
    });

    const [autocomplete, setAutocomplete] = useState(null);
    const [apartmentNr, setApartmentNr] = useState("");
    const inputElement = document.getElementById("search-input");
    const options = useMemo(() => ({
        componentRestrictions: { country: ["lv"] },
        fields: ["address_components", "formatted_address"],
        types: ["street_address", "street_number", "premise"]
    }), []);
    const intl = useIntl();

    const findAddressField = (addressObj, fieldName) => {
        return addressObj.find(part => part.types.includes(fieldName));
    }

    const parseGoogleAddressComponents = useCallback((address) => {
        const postalCode = findAddressField(address, "postal_code")?.long_name;
        return {
            [AddressField.Country]: "Latvija",
            [AddressField.County]: findAddressField(address, "administrative_area_level_1")?.long_name,
            [AddressField.Parish]: findAddressField(address, "administrative_area_level_2")?.long_name || findAddressField(address, "sublocality_level_1")?.long_name,
            [AddressField.City]: findAddressField(address, "locality")?.long_name,
            [AddressField.Street]: findAddressField(address, "route")?.long_name || findAddressField(address, "street_address")?.long_name,
            [AddressField.HouseNr]: findAddressField(address, "street_number")?.long_name || "",
            [AddressField.ApartmentNr]: apartmentNr,
            [AddressField.PostalCode]: IsLatvianWeb() ? "LV-" + postalCode : postalCode
        }
    }, [apartmentNr]);

    const onChangeAddress = useCallback((newPlace) => {
        const parsedAddress = parseGoogleAddressComponents(newPlace);
        onChange?.(parsedAddress);
    }, [onChange, parseGoogleAddressComponents]);

    const onChangeApartmentNr = (newApartmentNr) => {
        setApartmentNr(newApartmentNr);
        onChange?.({
            ...address,
            apartmentNr: newApartmentNr
        });
    }

    useEffect(() => {
        if (inputElement) {
            inputElement.value = getAddressAsString(address);
        }
    }, [inputElement, address]);

    const onPlaceChanged = useCallback(() => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            const address = place.address_components;

            if (address) {
                onChangeAddress(address);
            }
        }
    }, [autocomplete, onChangeAddress]);

    if (!isLoaded) {
        return <Loader type={Loader.TYPE_COVER} />
    }

    return (
        <GridInner>
            <GridCell span={10}>
                <label htmlFor="search-input">
                    {intl.formatMessage({ id: "AddressForm.Address" })}*
                </label>
                <Autocomplete options={options} onPlaceChanged={onPlaceChanged} onLoad={setAutocomplete}>
                    <input
                        id="search-input"
                        placeholder={intl.formatMessage({
                            id: "AddressForm.Search.Address",
                        })}
                    />
                </Autocomplete>
            </GridCell>
            <GridCell span={2}>
                <TextFieldGroup
                    field="apartment-no"
                    onChange={(e, _) => onChangeApartmentNr(e.target.value)}
                    value={apartmentNr || address?.apartmentNr}
                    label="AddressForm.ApartmentNr"
                />
            </GridCell>
        </GridInner>
    );
}

export default GoogleAddressAutoCompleteForm;