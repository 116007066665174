const DigitalStampConversionType = {
    PaymentCard: "PaymentCard",
    Balance: "Balance",
    Electricity: "Electricity",
    Gas: "Gas",
    Community: "Community"
};

const ConversionRate = {
    Money: 10,
    Trees: 2.5
};

const DigitalStampParams = {
    DefaultSelectedAmount: 100,
    MinAmountAllowedForConverting: 100,
    MinAmountAllowedForCommunity: 25
};

export {
    DigitalStampConversionType,
    ConversionRate,
    DigitalStampParams
}