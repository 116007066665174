import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

import BarAndLineChart from "../common/BarAndLineChart";

import "../../styles/components/_nordpool-graph.scss";
import AlertMessage from "../common/AlertMessage";

class NordpoolGraph extends React.Component {
    render() {
        const { data, priceUnit, screenWidth } = this.props;

        const isMobileView = screenWidth < 768;
        const dataPoints = (!!data && data.length > 0 && data.map(x => ({ x: x.timestamp, y: x.price }))) || [];

        const series = [
            {
                label: this.props.intl.formatMessage({ id: "Nordpool.Graph.Labels.Price" }),
                points: dataPoints,
                unit: priceUnit,
                alignToAxis: "left",
                type: "line",
                color: "#172057",
            }
        ];

        const axes = [
            {
                position: "bottom", type: "time", options: {
                    ticks: {
                        maxTicksLimit: 12,
                        maxRotation: 0,
                        minRotation: 0,
                    },
                    time: {
                        displayFormats: {
                            hour: isMobileView ? "kk" : "kk:mm"
                        }
                    }
                }
            },
            { position: "left", type: "linear", unit: priceUnit }
        ];

        return (
            <div className="nordpool-graph">
                {dataPoints.length > 0
                    ? <BarAndLineChart
                        series={series}
                        axes={axes}
                        yTickCount={4}
                        yUnitsAboveGraph={true}
                        showLegend={false}
                        precision="hour" />
                    : <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="Nordpool.NoData" />} />}
            </div>
        );
    }
}

NordpoolGraph.defaultProps = {
    data: [],
}

NordpoolGraph.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        price: PropTypes.number,
        timestamp: PropTypes.string
    })),
    priceUnit: PropTypes.string,
}


function mapStateToProps(state) {
    return {
        screenWidth: state.ui.screenWidth,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(NordpoolGraph));
