import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import PropTypes from 'prop-types';
import { GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";

const ContractTerms = (props) => {
    const { termsTextId, termsTitleId } = props;

    return (
        <GridCell span={12} className="field--colored p-0">
            <div className="message d-flex align-items-center justify-content-start">
                <span className="icon icon-contract" />
                <Typography use="headline4" className="mdc-typography--bold">
                    <FormattedMessage id={termsTitleId}/>
                </Typography>
            </div>
            <Typography tag="div" className="mdc-typography--bold p-15 mdc-typography--textblock">
                <FormattedHTMLMessage  id={termsTextId}/>
            </Typography>
        </GridCell>
    );
}

ContractTerms.propTypes = {
    termsTextId: PropTypes.string.isRequired,
    termsTitleId: PropTypes.string.isRequired
};

export default ContractTerms;