import { isAndroid, isIOS } from "react-device-detect";
import { GetAppVersion, IsCombinedAppContainer, UsingApp } from "./AlternativeDesign";

const getDeviceRegistrationInfo = () => {
    const getPlatform = () => {
        if (isAndroid) { return "android"; }
        else if (isIOS) { return "ios"; }
        else { return "platform_not_supported"; }
    }

    const isOldContainer = UsingApp() && !IsCombinedAppContainer();

    const installationId = isOldContainer
        ? window.localStorage.getItem("notificationHub_installationId")
        : !!window.NotificationHub && window.NotificationHub.InstallationId;
    const pushChannel = isOldContainer
        ? localStorage.getItem("notificationHub_pushChannel")
        : !!window.NotificationHub && window.NotificationHub.PushChannel;
    const platform = getPlatform();
    const appVersion = GetAppVersion();

    if (!installationId || !pushChannel || pushChannel === 'null') {
        // If these properties have not been set at all, don't attempt to register
        return null;
    }

    return {
        platform: platform,
        installationId: installationId,
        channel: pushChannel,
        appVersion: appVersion
    };
};

export { getDeviceRegistrationInfo };