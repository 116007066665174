import React from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import Currency from "../common/Currency";

const ElectricityInvoiceServicesTable = ({ invoiceItems }) => {

    const renderTableRow = (item, i) => {
        const cost = item.useCostWithoutVat
            ? item.cost
            : item.costWithVat;

        return (
            <tr key={i} >
                <td className="name wrap">
                    {item.name}
                </td>
                <td className="period">
                    <span className="hidden-desktop">
                        <FormattedMessage id="Invoice.Table.Period" />
                    </span>
                    <span>
                        {item.description}
                    </span>
                </td>
                <td className="price text-right">
                    <span className="mdc-typography hidden-desktop">
                        <FormattedMessage id="Invoice.Table.PriceVat" />
                    </span>
                    {<Currency value={cost} fractionalDigits={3} />}
                </td>
            </tr>
        );
    }

    return (
        <table className="table-list" >
            <thead>
                <tr>
                    <th><FormattedMessage id="Invoice.Table.ServiceName" /></th>
                    <th className="w-18"><FormattedMessage id="Invoice.Table.Period" /></th>
                    <th className="w-18 text-right"><FormattedMessage id="Invoice.Table.PriceVat" /></th>
                </tr>
            </thead>
            <tbody>
                {invoiceItems && invoiceItems.map(renderTableRow)}
            </tbody>
        </table>
    );
};

ElectricityInvoiceServicesTable.propTypes = {
    invoiceItems: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            description: PropTypes.string,
            type: PropTypes.string,
            cost: PropTypes.number,
            costWithVat: PropTypes.number,
            order: PropTypes.number
        })
    )
}

export default injectIntl(ElectricityInvoiceServicesTable);
