import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { bindActionCreators } from "redux";
import classnames from "classnames";

import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";

import "../../styles/react-components/table.scss";
import "../../styles/react-components/overview.scss";

import Loader from "../common/Loader";
import SelectField from "../common/SelectField";
import OverallDiscount from "./discount/OverallDiscount";
import DiscountBlock from "./discount/DiscountBlock";
import BaseFormComponent from "../common/BaseFormComponent";
import AlertMessage from "../common/AlertMessage";
import { isCreditcard } from "../../helpers/cardHelpers";
import BaseDialog from "../common/BaseDialog";

import { fetchCardsDiscounts } from "../../actions/discountActions";
import { getDigitalStampsSummary } from "../../actions/digitalStampsActions";
import { ServicePath, ContractsPath } from "../../const/routes";
import CardQrButton from "../common/CardQrButton";
import AlertMessageInline from "../common/AlertMessageInline";
import Alert, { AlertType } from "../common/Alert";
import CardToPrimary from "../cards/CardToPrimary"
import { Active } from "../../const/ContractStatus";
import { ElectricityNew, GasNew } from "../../const/contractType";
import auth from "../../auth/authenticate";

class OverviewDiscountChart extends BaseFormComponent {
    static FieldSelectedCard = "selectedCardNr";
    static PERMANENT_BONUS_DEFAULT = 0.024;
    static QUANTITY_BONUS_DEFAULT = 0.01;
    static ENERGY_CONTRACT_BONUS_DEFAULT = 0.01;
    static CREDIT_CARD_BONUS = 0.06;
    static COFFEE_CONSUMPTION_GOAL = 7;
    static FUEL_CONSUMPTION_GOAL = 70;
    static COFFEE_PERMANENT_BONUS_PERCENTAGE = 16;

    constructor(props) {
        super(props);

        this.state = {
            selectedCardNr: "",
            selectedCard: {
                permanentBonus: OverviewDiscountChart.PERMANENT_BONUS_DEFAULT,
                energyContractBonus: OverviewDiscountChart.ENERGY_CONTRACT_BONUS_DEFAULT,
                quantityBonus: OverviewDiscountChart.QUANTITY_BONUS_DEFAULT,
                coffeePermanentBonusPercentage: OverviewDiscountChart.COFFEE_PERMANENT_BONUS_PERCENTAGE,
                bonuscardIsPrimary: false
            },
            isSetPrimaryDialogOpen: false,
            showCardToPrimaryResponse: false,
            showPrimaryCardBanner: false,
            isCompany: auth.isCompany()
        };

        this.afterCardSelect = this.afterCardSelect.bind(this);
        this.getPrimaryCardNr = this.getPrimaryCardNr.bind(this);
        this.getGasPriceDiscountUnit = this.getGasPriceDiscountUnit.bind(this);
        this.renderTitleBlock = this.renderTitleBlock.bind(this);
        this.renderCreditcardDiscounts = this.renderCreditcardDiscounts.bind(this);
        this.renderHomecardDiscounts = this.renderHomecardDiscounts.bind(this);
        this.renderPermanentBonusBlock = this.renderPermanentBonusBlock.bind(this);
        this.closeSetPrimaryDialog = this.closeSetPrimaryDialog.bind(this);
    }

    componentDidMount() {
        if (!this.props.digitalStampsSummaryFetching && !this.props.digitalStampsSummaryFetched) {
            this.props.getDigitalStampsSummary();
        }

        this.props.fetchCardsDiscounts();
    }

    componentDidUpdate(prevProps) {
        if (!this.state.selectedCardNr && Object.keys(this.props.cardsDiscounts).length > 0) {
            // props.selectedCardNr has value when component is shown in card details view
            const selectedCardNumber = this.props.selectedCardNr;

            if (!!selectedCardNumber) {
                let state = {
                    selectedCardNr: selectedCardNumber,

                    selectedCard: this.cardsDiscountsContainsCard(selectedCardNumber)
                        ? this.props.cardsDiscounts[selectedCardNumber]
                        : {
                            permanentBonus: 0,
                            energyContractBonus: 0,
                            quantityBonus: 0,
                            coffeePermanentBonusPercentage: OverviewDiscountChart.COFFEE_PERMANENT_BONUS_PERCENTAGE
                        }
                };

                this.setState({ ...state });

            } else if (prevProps.cardsDiscounts !== this.props.cardsDiscounts) {
                const selectedCardNr = Object.keys(this.props.cardsDiscounts)[0];

                let state = {
                    selectedCardNr: selectedCardNr,
                    selectedCard: this.props.cardsDiscounts[selectedCardNr]
                };

                this.setState({ ...state });
            }
        }

        if (!this.props.bonuscardToPrimaryFetching && prevProps.bonuscardToPrimaryFetching && this.state.isSetPrimaryDialogOpen) {
            this.props.fetchCardsDiscounts().then(() => {

                const selectedCardNr = Object.keys(this.props.cardsDiscounts)[0];

                let state = {
                    showCardToPrimaryResponse: true,
                    isSetPrimaryDialogOpen: false,
                    showPrimaryCardBanner: true,
                    selectedCardNr: selectedCardNr,
                    selectedCard: this.props.cardsDiscounts[selectedCardNr]
                }

                this.setState({ ...state });
            });
        }
    }

    getPrimaryCardNr() {
        if (this.props.cardsDiscountsFetched) {
            const bonuscards = this.props.cardsDiscounts;
            const primaryCardNr = Object.values(bonuscards)
                .filter(x => x.bonuscardIsPrimary === true)
                .map(card => {
                    return card.bonuscardNr;
                })
            return primaryCardNr[0];
        }

    }

    cardsDiscountsContainsCard(cardNumber) {
        return this.props.cardsDiscountsFetched && !!cardNumber && Object.keys(this.props.cardsDiscounts).includes(cardNumber);
    }

    getGasPriceDiscountUnit() {
        return this.props.intl.formatMessage({ id: "Overview.Discount.Gas.PriceUnit" });
    }

    afterCardSelect(value) {
        let discountInfo = Object.assign({}, this.props.cardsDiscounts[value]);
        this.setState({ selectedCard: discountInfo });
    }

    toggleSetPrimaryDialog() {
        this.setState({ isSetPrimaryDialogOpen: true });
    }

    closeSetPrimaryDialog() {
        this.setState({
            isSetPrimaryDialogOpen: false
        });
    }

    renderSetPrimaryDialog() {
        return (
            <BaseDialog
                isOpen={this.state.isSetPrimaryDialogOpen}
                onClose={this.closeSetPrimaryDialog.bind(this)}
            >
                <CardToPrimary primaryCardNr={this.getPrimaryCardNr()} selectedCardNr={this.state.selectedCardNr} />
            </BaseDialog>
        );
    }

    renderTitleBlock() {
        const { selectedCard, selectedCardNr } = this.state;
        const { isMobileOrTabletView } = this.props;

        let bonusCards = Object.keys(this.props.cardsDiscounts).map(card => {
            return { value: card, label: card };
        });
        let bonusCardsSelectVisible = bonusCards.length > 0 || this.props.cardsDiscountsFetching;
        return (
            <Grid className={classnames("mdc-layout-grid--base mt-30 mt-tablet-clear mt-mobile-clear", { "hidden-tablet": !bonusCardsSelectVisible })}>
                {this.props.bonuscardToPrimaryError &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_ALERT}
                            scrollToMessage={true}
                            title={<FormattedMessage id="Overview.SetPrimary.Error" />} />
                    </GridCell>}
                {this.state.showCardToPrimaryResponse && this.props.bonuscardToPrimaryFetched &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_DONE}
                            isSmall={true}
                            scrollToMessage={true}
                            title={<FormattedMessage id="Overview.SetPrimary.Success" />} />
                    </GridCell>}
                <GridCell span={12}>
                    <div
                        className="d-flex align-items-center align-items-center--desktop justify-content-between mb-35 mb-mobile-15">
                        <Typography
                            use="headline3"
                            tag="h3"
                            className={classnames("mdc-typography mdc-theme--primary mb-mobile-15", { "hidden-tablet": bonusCardsSelectVisible })}
                        >
                            <FormattedMessage id="Overview.Benefits" />
                        </Typography>
                        {bonusCardsSelectVisible &&
                            <div className="align-right--desktop d-flex align-items-center align-items-center--desktop w-mobile-100 ">
                                <Typography
                                    use="body1"
                                    tag="p"
                                    className="mdc-typography mr-15 mb-mobile-5"
                                >
                                    <FormattedMessage id="Overview.Benefits.ChooseBonusCard" />
                                </Typography>

                                <div className="d-flex d-flex--inline w-mobile-100 align-items-center">
                                    <SelectField
                                        field={OverviewDiscountChart.FieldSelectedCard}
                                        onChange={this.onSelectChange}
                                        afterChange={this.afterCardSelect}
                                        value={this.state.selectedCardNr}
                                        className="mdc-select--outlined mdc-select--no-label"
                                        options={bonusCards}
                                        isLoading={this.props.cardsDiscountsFetching}
                                        disabled={bonusCards.length < 2}
                                    />

                                    <CardQrButton
                                        cardNumber={selectedCardNr}
                                        className="card-qr-button--bonuscard-selection" />

                                </div>

                                {!isMobileOrTabletView && this.props.cardsDiscountsFetched
                                    && !selectedCard.bonuscardIsPrimary &&
                                    <Button
                                        className="mdc-button--secondary mb-mobile-10 ml-15"
                                        outlined
                                        onClick={this.toggleSetPrimaryDialog.bind(this)}
                                    >
                                        <FormattedMessage id="Overview.SetPrimary.Btn" />
                                    </Button>
                                }

                            </div>
                        }
                    </div>
                </GridCell>
            </Grid>
        );
    }

    renderCreditcardDiscounts() {
        const { isMobileOrTabletView } = this.props;

        return (
            <>
                {isMobileOrTabletView &&
                    <GridInner className="discount__chart">
                        <GridCell span={12}>
                            <div className="item">
                                <DiscountBlock
                                    content="Overview.Discount.CreditCard.Title"
                                    discount={OverviewDiscountChart.CREDIT_CARD_BONUS * 100}
                                    unit={this.getGasPriceDiscountUnit()}
                                    isDone={true}
                                    contentChildren={
                                        <Button
                                            className="mdc-button--secondary mdc-button--thin"
                                            tag="a"
                                            target="_blank"
                                            href={this.props.intl.formatMessage({ id: "Overview.Discount.Creditcard.Url" })}
                                        >
                                            <FormattedMessage id="Overview.Discount.CreditCard.Link" />
                                        </Button>
                                    }
                                />
                            </div>
                        </GridCell>
                    </GridInner>
                }

                {!isMobileOrTabletView &&
                    <>
                        <OverallDiscount
                            discount={OverviewDiscountChart.CREDIT_CARD_BONUS * 100}
                            unit={this.getGasPriceDiscountUnit()}
                            isCreditCard={true}
                        />

                        <div className="discount__creditcard mt-20">
                            <Typography
                                use="body1"
                                className="mdc-typography mb-15"
                            >
                                <FormattedMessage id="Overview.Discount.CreditCard.Title" />
                            </Typography>
                            <Button
                                className="mdc-button--secondary mdc-button--text uppercase other"
                                trailingIcon={{
                                    icon: "arrow-left",
                                    strategy: "className",
                                    basename: "icon",
                                    prefix: "icon-",
                                    className: "rotate"
                                }}
                                tag="a"
                                target="_blank"

                                href={this.props.intl.formatMessage({ id: "Overview.Discount.Creditcard.Url" })}
                            >
                                <FormattedMessage id="Overview.Discount.CreditCard.Link" />
                            </Button>
                        </div>
                    </>}
            </>
        );
    }

    renderPermanentBonusBlock(isDone, discount) {
        return (
            <DiscountBlock
                title="Overview.Discount.Main.Title"
                content="Overview.Discount.Main.Content"
                discount={discount}
                unit={this.getGasPriceDiscountUnit()}
                isDone={isDone}
            />
        );
    }

    renderElectricityGasMobileDiscountBlock(isDone, isElectricity) {
        const button =
            <div className="energy-discount-btn-container">
                <Button
                    className="mdc-button--secondary mdc-button--thin"
                    tag={Link}
                    to={isDone ? ContractsPath : ServicePath}
                >
                    {isDone
                        ? <FormattedMessage id="Overview.Discount.ElectricityOrGas.ButtonContracts" />
                        : <FormattedMessage id="Overview.Discount.ElectricityOrGas.Button" />
                    }
                </Button>
                <Button
                    className="mdc-button--secondary mdc-button--thin"
                    tag={Link}
                    to={ContractsPath}
                >
                    <FormattedMessage id="Overview.Discount.ElectricityOrGas.ChangeProduct" />
                </Button>
            </div>

        const titleId = isElectricity ? "Overview.Discount.Electricity.Title" : "Overview.Discount.Gas.Title";
        const contentId = isElectricity ? "Overview.Discount.Electricity.Content" : "Overview.Discount.Gas.Content";

        return (
            <DiscountBlock
                title={titleId}
                content={contentId}
                discount="+5 dt"
                unit=""
                isDone={isDone}
                contentChildren={button}
            />
        )
    }

    renderHomecardDiscounts() {
        const { cardsDiscounts, contracts } = this.props;
        const { selectedCard } = this.state;

        const cardsCount = Object.keys(cardsDiscounts).length;
        const isPermanentBonusDone = cardsCount > 0 && selectedCard.permanentBonus > 0;

        const hasElectricityContractBonus = contracts.find(c => c.status === Active && c.type === ElectricityNew) !== undefined;
        const hasGasContractBonus = contracts.find(c => c.status === Active && c.type === GasNew) !== undefined;

        const discount = this.props.digitalStampsSummary?.bonus ?? "3 s/l";

        return (
            <GridInner className="discount__chart">
                <GridCell span={12} className="mt-15 mt-mobile-clear">
                    <div className="item">
                        { this.props.isMobileOrTabletView &&
                            this.renderPermanentBonusBlock(isPermanentBonusDone, discount)
                        }
                    </div>
                </GridCell>

                <GridCell span={12} className="summary hidden-tablet">
                    <OverallDiscount
                        discount={discount}
                        unit={this.getGasPriceDiscountUnit()}
                    />
                </GridCell>

                <GridCell span={12}>
                    {this.props.isMobileOrTabletView &&
                        <>
                            <div className="item item--energy">
                                {this.renderElectricityGasMobileDiscountBlock(hasElectricityContractBonus, true)}
                            </div>
                            <div className="item item--energy">
                                {this.renderElectricityGasMobileDiscountBlock(hasGasContractBonus, false)}
                            </div>
                        </>
                    }
                </GridCell>
            </GridInner>

        );
    }

    renderNoDiscountInfoForCardMessage(cardNumber) {
        return (
            <AlertMessageInline type={AlertMessageInline.TYPE_NOTICE}
                head={<FormattedMessage
                    id="Overview.Discount.SelectedCardNotFound"
                    values={{ cardNr: cardNumber }} />}
            />
        );
    }

    render() {
        const { cardsDiscounts, isMobileOrTabletView } = this.props;
        const { selectedCard, selectedCardNr } = this.state;

        const cardsCount = Object.keys(cardsDiscounts).length;

        const isCreditCard = cardsCount > 0 && isCreditcard(this.state.selectedCardNr);

        const hasDiscountsForSelectedCard = this.cardsDiscountsContainsCard(selectedCardNr);

        return (
            <>
                {this.renderTitleBlock()}
                {this.renderSetPrimaryDialog()}

                {this.props.discountFetching
                    ? (
                        <Loader type={Loader.TYPE_CENTER} />
                    )
                    : (
                        <Grid className="discount-row mdc-layout-grid--base mb-75 mb-tablet-clear mb-mobile-clear">

                            {!isMobileOrTabletView && selectedCard.bonuscardIsPrimary && !this.state.isCompany &&
                                <GridCell span={12}>
                                    <Alert
                                        type={AlertType.GREEN}
                                        title={<FormattedMessage id="Cards.PrimaryCard.Label" />}
                                    />
                                </GridCell>
                            }

                            {this.props.cardsDiscountsError &&
                                <GridCell span={12}>
                                    <AlertMessage
                                        type={AlertMessage.TYPE_ALERT}
                                        title={<FormattedMessage id="Overview.Discount.Error" />}
                                    />
                                </GridCell>}

                            {!!selectedCardNr && !hasDiscountsForSelectedCard &&
                                <GridCell span={12}>
                                    {this.renderNoDiscountInfoForCardMessage(selectedCardNr)}
                                </GridCell>}

                            <GridCell span={12} className={classnames("discount__wrapper",
                                {
                                    "discount__wrapper--creditcard": isCreditCard
                                })}>
                                {isCreditCard &&
                                    this.renderCreditcardDiscounts()}

                                {!isCreditCard &&
                                    this.renderHomecardDiscounts()}

                                {isMobileOrTabletView && !selectedCard.bonuscardIsPrimary &&
                                    <Button
                                        className="mdc-button--secondary mb-mobile-10 mt-10"
                                        outlined
                                        onClick={this.toggleSetPrimaryDialog.bind(this)}
                                    >
                                        <FormattedMessage id="Overview.SetPrimary.Btn" />
                                    </Button>
                                }

                                {isMobileOrTabletView && selectedCard.bonuscardIsPrimary && !this.state.isCompany &&
                                    <GridCell span={12}>
                                        <Alert
                                            type={AlertType.GREEN}
                                            title={<FormattedMessage id="Cards.PrimaryCard.Label" />}
                                            className="mt-10" />
                                    </GridCell>
                                }
                            </GridCell>
                        </Grid>
                    )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        cardsDiscountsFetching: state.cardsDiscounts.fetching,
        cardsDiscountsFetched: state.cardsDiscounts.fetched,
        cardsDiscountsError: state.cardsDiscounts.error,
        cardsDiscounts: state.cardsDiscounts.data,

        discountFetching: false,

        isMobileOrTabletView: state.ui.isMobileOrTabletView,

        bonuscardToPrimaryFetching: state.setBonuscardToPrimary.fetching,
        bonuscardToPrimaryFetched: state.setBonuscardToPrimary.fetched,
        bonuscardToPrimaryError: state.setBonuscardToPrimary.error,

        digitalStampsSummary: state.digitalStampsSummary.data,
        digitalStampsSummaryFetching: state.digitalStampsSummary.fetching,
        digitalStampsSummaryFetched: state.digitalStampsSummary.fetched,

        contracts: state.contracts.data
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCardsDiscounts,
        getDigitalStampsSummary
    }, dispatch);
}

export default injectIntl(
    withRouter(
        connect(mapStateToProps, matchDispatchToProps)(OverviewDiscountChart)
    )
);
