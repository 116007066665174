import React from "react";
import classnames from "classnames";
import { Typography } from "@rmwc/typography";
import { FormattedMessage, useIntl } from "react-intl";

const ContributionCard = ({ number, numberUnit, title, name, titleClassName, iconClassName }) => {
    const intl = useIntl();

    const formatNumber = (number) => {
        const formatter = Intl.NumberFormat(intl.locale, { maximumFractionDigits: 1 })

        // older safari things can't find the formatter. I don't even know
        if (!formatter.formatToParts) {
            return '';
        }
        const parts = formatter.formatToParts(number);

        const addSpaceBetweenThousands = (value) => {
            const r = /(\d+)(\d{3})/;
            while (r.test(value)) {
                value = value.replace(r, '$1 $2');
            }
            return value;
        }

        return parts
            .map(({ type, value }) => {
                switch (type) {
                    case 'integer': return value.length > 3 ? addSpaceBetweenThousands(value) : value;
                    case 'group': return ' '; // use space as thousands separator
                    default: return value;
                }
            })
            .reduce((string, part) => string + part);
    }

    return (
        <div className="contribution-card">
            <div className="contribution-card__inner">
                <div className="contribution-card__icon-wrapper">
                    <div className={classnames(
                        "contribution-card__icon",
                        iconClassName
                    )} />
                </div>
                <div className="contribution-card__content">
                    {name &&
                        <Typography use="headline3" tag="strong" className="mdc-typography contribution-card__content__data">
                            {name}
                        </Typography>
                    }
                    {number &&
                        <Typography use="headline3" tag="strong" className="mdc-typography contribution-card__content__data">
                            {formatNumber(number)} {numberUnit ? intl.formatMessage({ id: numberUnit }, { count: number }) : ""}
                        </Typography>
                    }
                    {title &&
                        <Typography use="headline6" className={classnames(
                            "mdc-typography contribution-card__content__title",
                            titleClassName
                        )}>
                            <FormattedMessage id={title} values={{ count: number }} />
                        </Typography>
                    }
                </div>
            </div>
        </div>
    );
};

export default ContributionCard;
