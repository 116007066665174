import auth from "../auth/authenticate";
import { directFileDownloadAction } from "../helpers/fileDownloadHelpers";
import BusinessAPI from './BusinessApi';

export const fetchBonusCards = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_BONUS_CARDS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/bonuscard/bonuscards`)
    };
};

export const fetchCards = (filter) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_CARDS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/card`, {
            params: filter
        })
    };
};
export const fetchAllCards = (filter) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_ALL_CARDS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/card/all`, {
            params: filter
        })
    };
};

export const fetchPaymentCardStatus = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: 'FETCH_PAYMENT_CARD_STATUS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/card/status`)
    };
}

export const fetchCardNumbers = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_CARD_NUMBERS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/card/GetCardsByCustomer`)
    };
};

export const orderCard = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'POST_CARD',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card`, data)
    };
};

export const orderCardReset = () => {
    return {
        type: 'POST_CARD_RESET'
    }
}

export const orderCardPrivateCustomer = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: 'POST_CARD',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/order-private`, data)
    };
}

export const updateCards = (model) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'PUT_CARDS',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/card`, model)
    };
};

export const bindBonusCard = (bonusCardNr, userGroupCode) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'PUT_BIND_BONUS_CARD',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/bonuscard/bind`, {
            bonusCardNr: bonusCardNr,
            userGroupCode: userGroupCode
        })
    };
};

export const bindCoopCard = (coopCardNr) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'PUT_BIND_COOP_CARD',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/bonuscard/bind-coop-card`, {
            coopCardNr: coopCardNr
        })
    };
}

export const orderBonusCard = (order) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'POST_ORDER_BONUS_CARD',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/bonuscard/order`, order)
    };
};

export const getBonusCard = (code) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'GET_BONUS_CARD',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/bonuscard/${code}`)
    };

};

export const getBonusCardEncrypted = (code) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'GET_BONUS_CARD',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/bonuscard`, {
            params: {
                code: code,
            }
        }
        )
    };
};

export const getCardQR = (number) => {
    return {
        type: 'GET_CARD_QR',
        payload: BusinessAPI.get(`/api/card/giftcard`, {
            params: {
                number: number,
            }
        }
        )
    };
};

export const getBonusCardQR = (number) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'GET_BONUS_CARD_QR',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/bonuscard/${number}/qr-code`)
    };
};

export const setBonuscardToPrimary = (bonusCardNr) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'PUT_SET_BONUS_CARD_PRIMARY',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/bonuscard/${bonusCardNr}/set-primary`)
    };
};

export const updateUserGroupCode = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'PUT_UPDATE_USER_GROUP',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/bonuscard/user-group-code`, data)
    };
};


export const validatePersonCreditScore = (requestedLimit) => {
    const userData = auth.getUserData();
    const accountId = userData.user.crmId;
    const roleId = userData.role.crmId;
    const personalCode = userData.user.nationalIdentityNumber;

    const body = {
        personalCode,
        requestedLimit
    };

    return {
        type: 'VALIDATE_PERSON_CREDIT_SCORE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/validate-credit-score/person`, body)
    };
};

export const validateCompanyCreditScore = (regCode) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    const body = {
        registryCode: regCode
    };

    return {
        type: 'VALIDATE_COMPANY_CREDIT_SCORE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/validate-credit-score/company`, body)
    };
};

export const postProcessPersonCardApplication = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PROCESS_PERSON_CARD_APPLICATION',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/process/person`, data)
    };
}

export const postPendingCompanyCardApplication = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PENDING_COMPANY_CARD_APPLICATION',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/pending/company`, data)
    };
}

export const postAcceptedPersonCardApplication = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_ACCEPTED_PERSON_CARD_APPLICATION',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/accepted/person`, data)
    };
}

export const postAcceptedCompanyCardApplication = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_ACCEPTED_COMPANY_CARD_APPLICATION',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/accepted/company`, data)
    };
}

export const postFinalizeAcceptedCardApplication = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_ACCEPTED_CARD_APPLICATION',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/finalize`, data)
    };
}

export const downloadPersonCardContractPreviewPdf = (model) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/card/contract-preview/person`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = "contract_preview.pdf";
            directFileDownloadAction(endpoint, fileName);
        } else {
            dispatch({
                type: 'DOWNLOAD_PERSON_CARD_CONTRACT_PREVIEW_PDF',
                payload: BusinessAPI.get(endpoint, {
                    params: model,
                    responseType: "arraybuffer"
                })
            });
        }
    }
};

export const downloadCompanyCardContractPreviewPdf = (model) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/card/contract-preview/company`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = `contract_preview.pdf`;
            directFileDownloadAction(endpoint, fileName, model);
        } else {
            dispatch({
                type: 'DOWNLOAD_COMPANY_CARD_CONTRACT_PREVIEW_PDF',
                payload: BusinessAPI.get(endpoint, {
                    params: {
                        model: JSON.stringify(model)
                    },
                    responseType: "arraybuffer"
                })
            });
        }
    }
};

export const activateCard = (cardNumber) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_ACTIVATE_CARD',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/activate`, { cardNumber })
    };
}

export const getCardPin = (cardNumber) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return BusinessAPI.get(`/api/${accountId}/${roleId}/card/pin/${cardNumber}`)
        .then(resp => resp.data);
}

export const orderPlasticCard = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'ORDER_PLASTIC_CARD',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/card/order-plastic`, data)
    };
}