import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import { Button } from "@rmwc/button";

import InvoicePaymentMethodsModal from "./InvoicePaymentMethodsModal";

import {
    InvoiceType,
    Statuses,
    COMPANY_USER_INVOICE_MAX_LIMIT
} from "../../const/Invoice";
import auth from "../../auth/authenticate";


const InvoicePaymentButton = ({ invoice, type }) => {

    const [isPaymentMethodsDialogOpen, setPaymentMethodsDialogOpen] = useState(false);
    const paymentMethodsFetching = useSelector(state => state.invoicePaymentMethods.fetching);

    const onPaymentButtonClick = () => !isDisabled && setPaymentMethodsDialogOpen(true);

    const closeModal = () => setPaymentMethodsDialogOpen(false);

    const isStatusAlreadyPaid =
        invoice.status === Statuses.StatusPaid ||
        invoice.status === Statuses.StatusCredited ||
        invoice.status === Statuses.StatusPaymentPending;

    const isAmountOverLimitForCompanyUser =
        auth.isCompany() && +invoice.toBePaid >= COMPANY_USER_INVOICE_MAX_LIMIT;

    const isElmoInvoiceAndNotLatest =
        type === InvoiceType.ElectricityAndGas && !invoice.isLatestInvoice;

    const isDisabled =
        (isStatusAlreadyPaid ||
            isElmoInvoiceAndNotLatest ||
            +invoice.toBePaid <= 0 ||
            isAmountOverLimitForCompanyUser ||
            paymentMethodsFetching);

    return (
        <>
            <Button
                onClick={onPaymentButtonClick}
                disabled={isDisabled}
                unelevated
                className="mdc-button--small ml-45 ml-mobile-clear mb-mobile-15 invoice__actions__button--pay"
            >
                <FormattedMessage id="Invoice.Pay" />
            </Button>
            <InvoicePaymentMethodsModal
                invoiceId={invoice.id}
                invoiceType={invoice.type}
                isOpen={isPaymentMethodsDialogOpen}
                onClose={closeModal} />
        </>
    );
}

InvoicePaymentButton.propTypes = {
    type: PropTypes.oneOf(Object.values(InvoiceType)),
}

export default InvoicePaymentButton;
