import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { FormattedMessage } from "react-intl";
import classnames from 'classnames';
import { MobileView } from "react-device-detect";

import { Typography } from "@rmwc/typography";

import LinkElement from "../../common/LinkElement"
import Icon from "../../common/Icon";
import { TransactionsPath, CardsPath, StationsPath, StartPath, ServicePath, InvoicesPath } from "../../../const/routes";
import { isLinkActive } from '../../../helpers/menuHelper';
import { IsLatvianWeb, IsNewMobileAppWebView } from "../../../helpers/AlternativeDesign";
import { redirectToAppContainer } from "../../../helpers/appContainerHelper";

import "../../../styles/react-components/footer.scss";

class FooterMenuMobile extends React.Component {
  render() {
    const isLatvianWeb = IsLatvianWeb();
    const isNewMobileAppWebView = IsNewMobileAppWebView();

    return (
      <MobileView>
        <div className="nav-bottom">
          <LinkElement
            className={classnames("nav-bottom-item", {
              "active": isLinkActive(this.props.location.pathname, StartPath)
            })}
            href={StartPath}
          >
            <Icon name="dashboard" />
            <Typography use="body1" className="mdc-typography nav-bottom-item-text">
              <FormattedMessage id="Footer.Menu.Workspace" />
            </Typography>
          </LinkElement>

          <LinkElement
            className={classnames("nav-bottom-item", {
              "active": isLinkActive(this.props.location.pathname, ServicePath)
            })}
            href={ServicePath}>
            <Icon name="services-thin" />
            <Typography use="body1" className="mdc-typography nav-bottom-item-text">
              <FormattedMessage id="Menu.Service" />
            </Typography>
          </LinkElement>

          {!isLatvianWeb &&
            <>
              {!this.props.isCombinedAppContainer &&
                <>
                  <LinkElement
                    className={classnames("nav-bottom-item", {
                      "active": isLinkActive(this.props.location.pathname, StationsPath)
                    })}
                    href={StationsPath}
                  >
                    <Icon name="fuel-pump" />
                    <Typography use="body1" className="mdc-typography nav-bottom-item-text">
                      <FormattedMessage id="Menu.Stations" />
                    </Typography>
                  </LinkElement>

                  <LinkElement
                    className={classnames("nav-bottom-item", {
                      "active": isLinkActive(this.props.location.pathname, CardsPath)
                    })}
                    href={CardsPath}
                  >
                    <Icon name="card-thin" />
                    <Typography use="body1" className="mdc-typography nav-bottom-item-text">
                      <FormattedMessage id="Menu.HomeCard" />
                    </Typography>
                  </LinkElement>

                  <LinkElement
                    className={classnames("nav-bottom-item", {
                      "active": isLinkActive(this.props.location.pathname, TransactionsPath)
                    })}
                    href={TransactionsPath}
                  >
                    <Icon name="transactions-thin" />
                    <Typography use="body1" className="mdc-typography nav-bottom-item-text">
                      <FormattedMessage id="Menu.Transactions" />
                    </Typography>
                  </LinkElement>
                </>
              }

              {this.props.isCombinedAppContainer &&
                <>
                  <LinkElement
                    className={classnames("nav-bottom-item", {
                      "active": isLinkActive(this.props.location.pathname, StationsPath)
                    })}
                    href={StationsPath}
                  >
                    <Icon name="map" />
                    <Typography use="body1" className="mdc-typography nav-bottom-item-text">
                      <FormattedMessage id="Menu.Map" />
                    </Typography>
                  </LinkElement>

                  <LinkElement
                    className={classnames("nav-bottom-item", {
                      "active": isLinkActive(this.props.location.pathname, InvoicesPath)
                    })}
                    href={InvoicesPath}
                  >
                    <Icon name="invoices-thin" />
                    <Typography use="body1" className="mdc-typography nav-bottom-item-text">
                      <FormattedMessage id="Menu.Invoices" />
                    </Typography>
                  </LinkElement>

                  {!isNewMobileAppWebView &&
                    <LinkElement
                      className={classnames("nav-bottom-item")}
                      onClick={redirectToAppContainer}
                    >
                      <Icon name="wallet-new" />
                      <Typography use="body1" className="mdc-typography nav-bottom-item-text">
                        <FormattedMessage id="Menu.ToContainer" />
                      </Typography>
                    </LinkElement>
                  }
                </>
              }
            </>
          }
        </div>
      </MobileView>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isCombinedAppContainer: state.appContainer.isCombinedAppContainer
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(FooterMenuMobile));
