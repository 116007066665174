
const storageKey = "isCampaignLogin";

const setCampaignLoginFlag = () => {
    localStorage.setItem(storageKey, true);
}

const isCampaignLogin = () => {
    return localStorage.getItem(storageKey) !== null;
}

const removeCampaignLoginFlag = () => {
    localStorage.removeItem(storageKey);
}

export { setCampaignLoginFlag, removeCampaignLoginFlag, isCampaignLogin };