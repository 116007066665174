import React from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import moment from 'moment';
import BaseFormComponent from "../common/BaseFormComponent";
import { Button } from "@rmwc/button";
import { GridCell, GridInner } from "@rmwc/grid";
import AuthDialog from "./AuthorizationDialog";
import AlertMessage from "../common/AlertMessage";
import TableList from "../common/TableList";
import auth from "../../auth/authenticate";

class AuthorizedUserList extends BaseFormComponent {
    static TableHeaders = [
        {
            label: "AuthorizedUser.Name",
            colspan: 1
        },
        {
            label: "AuthorizedUser.Start",
            colspan: 1
        },
        {
            label: "AuthorizedUser.End",
            colspan: 2
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            itemsToDialog: [],
            showSuccess: false,
            openDialog: false,
            currentUser: auth.getUser()
        };
        this.openCreateDialog = this.openCreateDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.selectCallback = this.selectCallback.bind(this);

        this.listRef = React.createRef();
    }

    selectCallback(indexes) {
        this.setState({
            openDialog: true,
            itemsToDialog: indexes.map((index) => {
                return this.props.items[index];
            }),
            showSuccess: false
        });
    }

    openCreateDialog() {
        this.setState({
            openDialog: true,
            itemsToDialog: [],
            showSuccess: false
        });
    }

    closeDialog() {
        this.setState({
            openDialog: false
        });
    }

    onSuccess() {
        this.listRef.current?.clearCache();
        this.setState({ showSuccess: true });
        this.props.onSuccess();
    }

    renderTableRowJson(item) {
        const startDate = moment(item.validFrom, "YYYY-MM-DD");
        const endDate = moment(item.validTo, "YYYY-MM-DD");

        return {
            content: [
                {
                    colspan: 1,
                    content: `${item.name} (${item.ssn})`
                },
                {
                    colspan: 1,
                    content: startDate.format("DD.MM.YYYY")
                },
                {
                    colspan: 1,
                    content: item.validTo ? endDate.format("DD.MM.YYYY") : "-"
                }
            ]
        };
    }

    render() {
        let rows = this.props.items || [];
        const tableRowsJson = rows.map(item => this.renderTableRowJson(item));
        const canCurrentUserAddAuthorizations = rows.some(user => user.ssn === this.state.currentUser?.nationalIdentityNumber);

        return (
            <>
            <GridInner>
                <GridCell span={12}>
                    {this.state.showSuccess &&
                        <AlertMessage
                            className="mb-30"
                            isSmall={true}
                            type={AlertMessage.TYPE_DONE}
                            title={<FormattedMessage id="AuthorizedUser.Success"/>}
                        />
                    }
                    <Button className="mdc-button--outlined right" onClick={this.openCreateDialog}>
                        <i className="icon-plus" />
                        <FormattedMessage id="AuthorizedUser.Create"/>
                    </Button>
                </GridCell>
                <GridCell span={12}>
                    {rows.length > 0 &&
                        <>
                            <TableList
                                headers={AuthorizedUserList.TableHeaders}
                                selectable={true}
                                selectableLabel={"AuthorizedUser.Change"}
                                itemContent={tableRowsJson}
                                selectCallback={this.selectCallback}
                                changeRow="AuthorizedUser.Change"
                                changeRowCallback={(index) => {this.selectCallback([index])}}
                            />
                        </>
                    }
                </GridCell>
            </GridInner>
            <AuthDialog
                key={"roleDialog" + this.state.openDialog}
                items={this.state.itemsToDialog}
                isOpen={this.state.openDialog}
                onClose={this.closeDialog}
                onSuccess={this.onSuccess}
                canUserAddAuthorizations={canCurrentUserAddAuthorizations}
            />
            </>
        );
    }
}

export default injectIntl(AuthorizedUserList);