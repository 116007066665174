import { GET_BONUSCARD_STATUS, GET_BONUSCARD_STATUS_CLEAR } from "../../actions/digitalStampsActions";

export default function reducer(state={
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${GET_BONUSCARD_STATUS}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${GET_BONUSCARD_STATUS}_REJECTED`: {
            return {...state, fetching: false, fetched: false, error: action.payload};
        }
        case `${GET_BONUSCARD_STATUS}_FULFILLED`: {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data};
        }
        case GET_BONUSCARD_STATUS_CLEAR: {
            return {data: {}, fetching: false, fetched: false, error: null};
        }
        default:
            return state;
    }
}