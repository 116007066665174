import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Button } from "@rmwc/button";

const DigitalStampsConvertOption = ({ onConvert, icon, title, description, tooltip, sumDescription, convertedSum, isCurrency = true, isDisabled = false, children }) => {
    const postStamps = useSelector(state => state.convertStamps);
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(postStamps.fetching);
    }, [postStamps.fetching]);

    return (
        <Grid className="digital-stamps-card">
            <GridCell span={12}>
                <GridInner className="container-padding">
                    <GridCell span={12} className="icon-wrapper">
                        <div className={icon}>
                            <i className={`icon icon-${icon}`} />
                        </div>
                    </GridCell>
                    <GridCell span={12} className="title">
                        <FormattedMessage id={title} />
                    </GridCell>
                    <GridCell span={12} className="description">
                        <FormattedMessage id={description} />
                    </GridCell>
                </GridInner>
            </GridCell>
            <GridCell span={12} className="separator">
                <GridInner className="container-padding">
                    <GridCell span={12} className="convert-box-header">
                        <FormattedMessage id="DigitalStamps.Option.InputAmountHint" />
                        <i className="icon icon-info-fill" data-tooltip={intl.formatMessage({ id: tooltip })} />
                    </GridCell>
                    <GridCell span={12} className="child-item">
                        {children}
                    </GridCell>
                    <GridCell span={12} className="super-bold-text">
                        <span>=</span>
                    </GridCell>
                    <GridCell span={12} className="sum-description">
                        <FormattedMessage id={sumDescription} />
                    </GridCell>
                    <GridCell span={12} className="super-bold-text">
                        <span>
                            {isCurrency
                                ? `${convertedSum} €`
                                : convertedSum
                            }
                        </span>
                    </GridCell>
                </GridInner>
            </GridCell>
            <GridCell span={12} className="digital-stamps-converter">
                <div className="digital-stamps-converter--button">
                    <Button
                        unelevated
                        onClick={() => onConvert?.()}
                        disabled={isDisabled || isLoading}
                    >
                        <FormattedMessage id="DigitalStampsClient.Button.Convert" />
                    </Button>
                </div>
            </GridCell>
        </Grid>
    )
}

export default DigitalStampsConvertOption;