import React from 'react';
import PropTypes from 'prop-types';

const StaticField = ({className, value, label}) => {
    return (
        <div className={className}>
            <div className = "mdc-typography mdc-typography--subtitle1" >
                { label }
            </div>
            <div className="mdc-typography mdc-typography--body1">{ value }</div>
        </div>
    );
}

StaticField.propTypes = {
    value: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    className: PropTypes.string,
};

export default StaticField;
