export const Initial = "initial";
export const WaitingForSCA = "waiting_for_sca";
export const SentForProcessing = "sent_for_processing";
export const Abandoned = "abandoned";
export const Failed = "failed";
export const Settled = "settled";
export const Authorised = "authorized";
export const Voided = "voided";
export const Refunded = "refunded";
export const ChargedBack = "charged_back";
export const ThreeDSConfirmed = "3ds_confirmed";

const Statuses = { Initial, WaitingForSCA, SentForProcessing, Abandoned, Failed, Settled, Authorised, Voided, Refunded, ChargedBack, ThreeDSConfirmed };
export default Statuses;