import { Button } from "@rmwc/button";
import { Dialog, DialogContent } from "@rmwc/dialog";
import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { FormattedMessage } from "react-intl";

const ConfirmDialog = ({ isOpen, text, description, onAccept, onReject }) => {
    return (
        <Dialog
            open={isOpen}
            className="mdc-dialog--confirm"
        >
            <DialogContent>
                <Grid>
                    <GridCell span={12} className="mb-30">
                        <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                            {text}
                        </Typography>
                    </GridCell>
                    {description &&
                        <GridCell span={12} className="mb-30">
                            {description}
                        </GridCell>
                    }
                    <GridCell span={6}>
                        <Button
                            className="mdc-button--wide"
                            unelevated
                            onClick={onAccept}
                        >
                            <FormattedMessage id="General.Yes" />
                        </Button>
                    </GridCell>
                    <GridCell span={6}>
                        <Button
                            className="mdc-button--wide"
                            outlined
                            onClick={onReject}
                        >
                            <FormattedMessage id="General.No" />
                        </Button>
                    </GridCell>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmDialog;