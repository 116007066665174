import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const getCustomerCouponBalance = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "GET_CUSTOMER_COUPON_BALANCE",
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/customerCoupon/balance`)
    };
}