import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import BarAndLineChart from "../common/BarAndLineChart";

class ProductionGraph extends React.Component {
    getPrecisionForPeriodType(type) {
        switch (type) {
            case "year": return "month";
            case "month": return "day"
            default: return "hour";
        }
    }

    render() {
        const { data, amountUnit, periodType } = this.props;

        const series = [
            {
                label: this.props.intl.formatMessage({ id: "Production.Graph.Labels.Production" }),
                points: data.map(x => ({ x: x.timestamp, y: x.amount })),
                unit: amountUnit,
                alignToAxis: "left",
                type: "bar",
                color: "#58BBEF",
            },
        ];

        const axes = [
            { position: "bottom", type: "time" },
            { position: "left", type: "linear", unit: amountUnit },
        ];

        return (
            <div className="energy-graph">
                <BarAndLineChart
                    series={series}
                    axes={axes}
                    yTickCount={4}
                    yUnitsAboveGraph={true}
                    precision={this.getPrecisionForPeriodType(periodType)} />
            </div>
        );
    }
}

ProductionGraph.defaultProps = {
    data: [],
}

ProductionGraph.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.number,
            timestamp: PropTypes.string,
        })
    ),
    amountUnit: PropTypes.string,
    isTotalProduction: PropTypes.bool,
    periodType: PropTypes.string,
}

function mapStateToProps(state) {
    return {};
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(ProductionGraph));
