import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";
import BaseLogin from "../components/common/BaseLogin";
import { finishLogin } from "../actions/authActions"
import auth from "../auth/authenticate";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import { Button } from "@rmwc/button";
import { LoginPath, SmsLoginPagePath } from "../const/routes";
import { FromMapLang } from "../helpers/LangHelper";
import language from "../lang/lang";
import ReferralLink from "../components/common/ReferralLink";

const LoginCallback = () => {
    const [isError, setIsError] = useState(false);
    const authState = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const campaignInfo = auth.getLoginCampaignInfo();
    const isPassiveUser = auth.isPassive();
    const requestedLocation = auth.getRequestedLocation();

    useEffect(() => {
        const loginUser = () => async dispatch => {
            const mgr = auth.getAuthConfig();

            try {
                if (auth.isLoggedIn()) return;

                const user = await mgr.signinRedirectCallback();

                if (user?.access_token) {
                    let model = campaignInfo;
                    model.accessToken = user.access_token;
                    model.usingBonuscardOrderLink = ReferralLink.getReferralLinkParams() != null;
                    model.isSmsLogin = user.profile.idp === "sms";
                    if (user.profile.locale) {
                        language.setLang(FromMapLang(user.profile.locale));
                    }

                    await dispatch(finishLogin(model));

                    console.debug("finished login")
                }
            } catch (error) {
                console.error(error);
                setIsError(true);
            }
        }

        dispatch(loginUser());
    }, [dispatch]);

    return (
        <BaseLogin redirectTo={authState.isSmsLogin ? SmsLoginPagePath : campaignInfo.redirectUrl || requestedLocation}>
            <Grid className="mdc-layout-grid--mobile-vertical">
                <GridCell span={12}>
                    <Typography
                        use="headline2"
                        tag="h2"
                        className="mdc-typography mdc-theme--primary mdc-theme-desktop-margin"
                    >
                        <FormattedMessage id="LoginPage.Title" />
                    </Typography>
                    {authState.isAuthError || isError
                        ?
                        <>
                            <AlertMessage
                                type={AlertMessage.TYPE_ALERT}
                                title={
                                    <FormattedMessage id={isPassiveUser
                                        ? "Error.Validator.PassiveUser"
                                        : "LoginPage.Failed"}
                                    />
                                }
                                isSmall={true}
                                scrollToMessage={true}
                            />
                            {!isPassiveUser &&
                                <Button className="mt-25" unelevated tag="a" href={LoginPath}>
                                    <FormattedMessage id="LoginPage.TryAgain" />
                                </Button>
                            }
                        </>
                        : <Loader type={Loader.TYPE_CENTER} />
                    }
                </GridCell>
            </Grid>
        </BaseLogin>
    );
}

export default LoginCallback;
