import React, {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {Grid, GridCell, GridInner} from "@rmwc/grid";
import LinkCard from "../common/LinkCard";
import DigitalStampsBonusIndicator from "./DigitalStampsBonusIndicator";
import {ConversionRate} from "../../const/digitalStamps";
import {FamilyGroupPath, TransactionsPath} from "../../const/routes";
import auth from "../../auth/authenticate";
import {FamilyGroupForm} from "../FamilyGroup";
import {clearCreateFamilyGroup, clearDeleteGroup, fetchFamilyGroup} from "../../actions/familyGroupActions";
import {useFeature} from "flagged";
import {FamilyGroupAsPublic} from "../../const/featureFlags";
import BaseDialog from "../common/BaseDialog";

const DigitalStampsBonus = () => {
    const stampsPerFreeItem = 20;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [familyGroupLinkTextId, setFamilyGroupLinkTextId] = useState("DigitalStamps.FamilyGroupLink.CreateUrlText");
    const stampsSummary = useSelector(state => state.digitalStampsSummary);
    const familyGroup = useSelector(state => state.familyGroup);
    const ui = useSelector(state => state.ui);
    const intl = useIntl();
    const dispatch = useDispatch();
    const isFamilyGroupPublic = useFeature(FamilyGroupAsPublic);

    const onOpenDialog = () => {
        dispatch(clearCreateFamilyGroup());
        dispatch(clearDeleteGroup());
        setIsDialogOpen(true);
    }

    const onClose = () => {
        setIsDialogOpen(false);
        dispatch(fetchFamilyGroup());
    }

    const getBadgeCount = () => {
        return Math.floor(stampsSummary.data?.availableStamps / stampsPerFreeItem);
    }

    useEffect(() => {
        const role = auth.getRole();
        const isHeadOfFamily = familyGroup.data?.members?.find(value => value.isHeadOfFamily && value.personalCode === role.nationalIdentityNumber);

        if (isHeadOfFamily) {
            setFamilyGroupLinkTextId("DigitalStamps.FamilyGroupLink.AddMembersUrlText");
        } else if (familyGroup.data?.members?.length > 0) {
            setFamilyGroupLinkTextId("DigitalStamps.FamilyGroupLink.ViewUrlText");
        } else {
            setFamilyGroupLinkTextId("DigitalStamps.FamilyGroupLink.CreateUrlText");
        }
    }, [familyGroup]);

    useEffect(() => {
        if (isFamilyGroupPublic) {
            dispatch(fetchFamilyGroup());
        }
    }, [dispatch, isFamilyGroupPublic]);

    return (
        <>
            <Grid className="digital-stamps-bonus">
                <GridCell span={12}>
                    <GridInner>
                        <GridCell desktop={1} tablet={0} phone={0}></GridCell>
                        <GridCell desktop={3} tablet={12} phone={12} className="digital-stamps-bonus--total">
                            <GridCell span={12} className="digital-stamps-bonus--total-main">
                                <div className="text-center">
                                    <FormattedMessage id="DigitalStamps.Bonus.Stamps.IHaveCollected"/>
                                </div>
                                <span className="digital-stamps-bonus--total-main--count">
                                {stampsSummary.data?.availableStamps ?? 0}
                            </span>
                                <span><FormattedMessage id="DigitalStamps.Bonus.Stamps" /></span>
                            </GridCell>
                            <GridCell span={12} className="digital-stamps-bonus--total-sub">
                                {`(${(stampsSummary.data?.availableStamps ?? 0) / ConversionRate.Money}€)`}
                            </GridCell>
                        </GridCell>
                        <GridCell desktop={1} tablet={0} phone={0} className="digital-stamps-bonus--separator"/>
                        <GridCell desktop={6} tablet={12} phone={12} className="digital-stamps-bonus--indicator">
                            <DigitalStampsBonusIndicator/>
                        </GridCell>
                        <GridCell desktop={1} tablet={0} phone={0}></GridCell>
                    </GridInner>
                </GridCell>
                <GridCell span={12} className={ui.isMobileOrTabletView ? "" : "mt-30"}>
                    <GridInner>
                        {stampsSummary.data?.availableStamps >= stampsPerFreeItem &&
                            <GridCell desktop={6} phone={12}>
                                <LinkCard
                                    icon="icon-gift"
                                    title={intl.formatMessage({id: "DigitalStamps.FreeItems.Title"})}
                                    description={intl.formatMessage({id: "DigitalStamps.FreeItems.Description"})}
                                    links={[]}
                                    badge={getBadgeCount()}
                                />
                            </GridCell>}
                        {isFamilyGroupPublic && <GridCell desktop={6} phone={12}>
                            <LinkCard
                                icon="icon-members"
                                title={intl.formatMessage({id: "DigitalStamps.FamilyGroupLink.Title"})}
                                description={intl.formatMessage({id: "DigitalStamps.FamilyGroupLink.Description"})}
                                links={[
                                    {
                                        action: onOpenDialog,
                                        url: FamilyGroupPath,
                                        text: intl.formatMessage({id: familyGroupLinkTextId})
                                    }
                                ]}
                            />
                        </GridCell>}
                        <GridCell desktop={6} phone={12}>
                            <LinkCard
                                icon="icon-calculator"
                                title={intl.formatMessage({id: "DigitalStamps.CalculatorLink.Title"})}
                                description={intl.formatMessage({id: "DigitalStamps.CalculatorLink.Description"})}
                                links={[
                                    {
                                        url: intl.formatMessage({id: "DigitalStamps.CalculatorLink.UrlPath"}),
                                        text: intl.formatMessage({id: "DigitalStamps.CalculatorLink.UrlText"})
                                    }
                                ]}
                            />
                        </GridCell>
                        <GridCell desktop={6} phone={12}>
                            <LinkCard
                                icon="icon-list"
                                title={intl.formatMessage({id: "DigitalStamps.TransactionsLink.Title"})}
                                description={intl.formatMessage({id: "DigitalStamps.TransactionsLink.Description"})}
                                links={[
                                    {
                                        url: TransactionsPath,
                                        text: intl.formatMessage({id: "DigitalStamps.TransactionsLink.UrlText"})
                                    }
                                ]}
                            />
                        </GridCell>
                    </GridInner>
                </GridCell>
            </Grid>
            <BaseDialog isOpen={isDialogOpen} onClose={onClose}>
                <FamilyGroupForm onClose={onClose} isModal />
            </BaseDialog>
        </>
    )
}

export default DigitalStampsBonus;