export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case "FETCH_ALERT_PENDING": {
            return {...state, fetching: true};
        }
        case "FETCH_ALERT_REJECTED": {
            return {...state, fetching: false, error: action.payload};
        }
        case "FETCH_ALERT_FULFILLED": {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}