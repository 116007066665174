import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import TextFieldGroup from "../common/TextFieldGroup";
import SelectAutoCompleteFieldOverApi from "../common/SelectAutoCompleteFieldOverApi";
import SelectAutoCompleteField from "../common/SelectAutoCompleteField";
import { fetchAddress, fetchAddressForLiving } from "../../actions/addressActions";
import Loader from "../common/Loader";
import classnames from "classnames";
import { GridCell, GridInner } from "@rmwc/grid";
import { getAddressAsString } from '../../helpers/addressHelper';

class AddressForm extends React.Component {
    state = {
        address: this.props.address,
        appartments: []
    };

    constructor(props) {
        super(props);

        this.className = this.props.className;
        this.onSelected = this.onSelected.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.selectedAddress = this.selectedAddress.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.address !== this.props.address) {
            this.setState({
                address: this.props.address
            });
        }
    }

    onSelected(selectedObject) {
        this.setState({
            address: {
                county: selectedObject.address.maakond,
                parish: selectedObject.address.asustusyksus,
                city: selectedObject.address.omavalitsus,
                street: selectedObject.address.liikluspind
                    || selectedObject.address.nimi
                    || selectedObject.address.vaikekoht,
                houseNr: selectedObject.address.aadress_nr,
                apartmentNr: selectedObject.address.kort_nr || "",
                postalCode: selectedObject.address.sihtnumber,
                addressId: selectedObject.address.adr_id
            },
            appartments: selectedObject.address.appartments || [],
        }, () => this.props.onChange(this.state.address));
    }

    onSearch(keyWord) {
        if (!this.props.isAddressLoading && keyWord.length > 2) {
            if  (this.props.searchBuildings) {
                this.props.fetchAddress(keyWord);
            } else {
                this.props.fetchAddressForLiving(keyWord);
            }
        }
    }

    updateApartment(value) {
        const address = this.state.address;
        address.apartmentNr = value || "";
        this.setState({ address: address }, () => this.props.onChange(this.state.address));
    }

    get addressList() {
        if (this.props.addressData.addresses && this.props.addressData.addresses.length > 0) {
            const addresses = this.props.addressData.addresses
                .filter(x => x.sihtnumber)
                .map(item => {
                    let streetSection = item.liikluspind || item.nimi || item.vaikekoht;
                    const itemAddressNr = item.aadress_nr ? ` ${item.aadress_nr}` : '';
                    streetSection = streetSection ? `${streetSection}${itemAddressNr}, ` : '';
                    return {
                        value: `${streetSection}${item.asustusyksus
                            ? item.asustusyksus + ", "
                            : ''}${item.omavalitsus}, ${item.maakond}, ${item.sihtnumber}`,
                        address: item
                    };
                })
            return addresses;
        }
        return [];
    }

    selectedAddress() {
        const addressAsString = getAddressAsString(this.state.address);
        if (addressAsString) {
            return {
                value: addressAsString
            }
        }

        return {};
    }

    get appartments() {
        return this.state.appartments.map(x => x.tahis);
    }

    render() {
        const hasApartments = this.state.address.apartmentNr || this.state.appartments.length > 0;
        const apartmentSpan = this.props.apartmentSpan || 2;
        const addressSpan = 12 - apartmentSpan;

        return (
            <div className={classnames(this.className, { "loader loader--block loader--light": this.props.isLoading })}>
                {this.props.isLoading && <Loader />}
                <GridInner>
                    <GridCell phone={4} tablet={6} desktop={hasApartments ? addressSpan : 12}>
                        <SelectAutoCompleteFieldOverApi
                            validator={this.props.validator}
                            isLoading={this.props.isLoading || this.props.isAddressLoading}
                            rules={this.props.rules}
                            label={this.props.label}
                            labelSubText={this.props.labelSubText}
                            selectPlaceholder="AddressForm.Search.Address"
                            searchPlaceholder="AddressForm.Search.Address"
                            field={this.props.field || "address"}
                            options={this.addressList}
                            selected={this.selectedAddress()}
                            onSelected={this.onSelected}
                            onSearch={this.onSearch}
                            disabled={this.props.disabled} />
                    </GridCell>
                    {hasApartments &&
                        <GridCell phone={apartmentSpan} tablet={apartmentSpan} desktop={apartmentSpan}>
                            {this.state.appartments.length > 0 &&
                                <SelectAutoCompleteField
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'General.Select'
                                    })}
                                    searchPlaceholder={this.props.intl.formatMessage({
                                        id: 'AddressForm.Search'
                                    })}
                                    value={this.state.address.apartmentNr}
                                    onChange={this.updateApartment.bind(this)}
                                    field="apartmentNr"
                                    label={this.props.label ? "AddressForm.ApartmentNr" : null}
                                    options={this.appartments}
                                    disabled={this.props.disabled}
                                    validator={this.props.validator}
                                    rules={this.props.rules} />
                            }
                            {this.state.appartments.length === 0 && this.state.address.apartmentNr &&
                                <TextFieldGroup
                                    label="AddressForm.ApartmentNr"
                                    value={this.state.address.apartmentNr}
                                    onChange={() => { }}
                                    field=""
                                    disabled={true}
                                    noLabel={!this.props.label}
                                />
                            }
                        </GridCell>
                    }
                </GridInner>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAddressLoading: state.address.fetching,
        isAddressLoaded: state.address.fetched,
        addressData: state.address.data
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAddress,
        fetchAddressForLiving
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(AddressForm));
