import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";

import "../../styles/react-components/marketing-notifications.scss";
import { AddMarketingNotificationsForm, MarketingNotificationsList } from "./index";
import { defaultIconProps } from "../common/Icon";
import BaseDialog from "../common/BaseDialog";

const MarketingPushNotifications = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const formRef = useRef();

    const copyTitleAndContentToForm = (item) => {
        if (formRef.current) {
            formRef.current.copyText(item);
            setIsDialogOpen(true);
        }
    }

    return (<>
        <Grid>
            <GridCell span={12}>
                <Button
                    outlined
                    icon={{
                        ...defaultIconProps,
                        icon: "plus"
                    }}
                    className="right"
                    onClick={() => setIsDialogOpen(true)}>
                    <FormattedMessage id="MarketingNotifications.OpenModal" />
                </Button>
            </GridCell>
            <GridCell span={12} >
                <MarketingNotificationsList onReuseButtonClick={copyTitleAndContentToForm} />
            </GridCell>
        </Grid>

        <BaseDialog
            isOpen={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            preventOutsideDismiss={true}>
            <AddMarketingNotificationsForm ref={formRef} />
        </BaseDialog>
    </>
    );
}

export default MarketingPushNotifications;