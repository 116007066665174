import React from "react";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { startOfMonth } from "date-fns";

import { Grid, GridInner, GridCell } from "@rmwc/grid";
import { fetchProductionDetails } from "../actions/consumptionlocationActions";

import ProductionDetails from "../components/Production/ProductionDetails";
import EnergyWidget from "../components/common/EnergyWidget";
import EnergyFilter from "../components/common/EnergyFilter";
import AlertMessage from "../components/common/AlertMessage";
import { locationsOrderFunc, mapToWidgetItems } from "../helpers/energyHelpers";
import { EnergyType } from "../const/EnergyType";

class Production extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterOpen: undefined,
            filter: {
                startDate: moment(startOfMonth(new Date())),
                selectedPeriodType: "month",
            }
        }

        this.onFilterSubmit = this.onFilterSubmit.bind(this);
    }

    componentDidMount() {
        this.fetchProductionDetails(this.state.filter);
    }

    fetchProductionDetails(filter) {
        const params = {
            periodStart: filter.startDate.format("YYYY-MM-DD 00:00:00"),
            periodType: filter.selectedPeriodType
        };
        if (!isEqual(this.props.productionDetailsParams, params)) {
            this.props.fetchProductionDetails(params);
        }
    }

    onFilterSubmit(filter) {
        this.setState({ filter: filter });
        this.fetchProductionDetails(filter);
    }

    getLocations() {
        const { productionDetails, productionDetailsFetched, intl } = this.props;

        return (productionDetailsFetched &&
            productionDetails.electricityProduction
                ?.map(x =>
                ({
                    id: x.id || "",
                    name: x.isTotal ? intl.formatMessage({ id: "ConsumptionGraph.All" }) : x.address,
                    isTotal: x.isTotal
                }))
                ?.sort(locationsOrderFunc)
        ) || [];
    }

    renderElectricityProductionInfo() {
        const {
            productionDetails,
            productionDetailsFetched,
            productionDetailsFetching,
            productionDetailsError
        } = this.props;

        const electricityLocations = this.getLocations();

        const hasDetails = productionDetailsFetched && !!productionDetails;
        const electricityData = hasDetails ? productionDetails.electricityProduction : [];

        const widgetItems = mapToWidgetItems(electricityData, EnergyType.Electricity, this.props.intl.formatMessage({ id: "Production.Widget.All" }))
            .sort(locationsOrderFunc);

        return (
            <>
                {!productionDetailsFetching && widgetItems.length > 0 &&
                    <GridInner className="mdc-layout-grid--base mt-25 mt-mobile-15">
                        <GridCell span={12}>
                            <div className="energy-details__disclaimer">
                                <FormattedMessage id="Production.Disclaimer" />
                            </div>
                        </GridCell>
                        {!productionDetailsError && widgetItems.map((x, i) =>
                            <GridCell key={i} desktop={4} tablet={4} phone={4} className="d-flex">
                                <EnergyWidget key={"widget-" + i} {...x} />
                            </GridCell>
                        )}
                    </GridInner>}

                <ProductionDetails
                    className="mt-20 mb-60"
                    title={<FormattedMessage id="Production.Graph.ElectricityProduction" />}
                    locations={electricityLocations}
                    details={electricityData}
                    isLoading={productionDetailsFetching}
                    hasError={!!productionDetailsError}
                    filter={this.state.filter}
                    type={EnergyType.Electricity} />
            </>
        );
    }

    renderNoLocationsNotice() {
        return (
            <AlertMessage
                type={AlertMessage.TYPE_NOTICE}
                title={<FormattedMessage id="Production.Graph.NoLocations" />}>
            </AlertMessage>
        );
    }

    render() {
        const {
            productionDetailsFetching,
        } = this.props;

        const hasLocations = this.getLocations().length > 0;

        return (
            <>
                <Grid className="mdc-layout-grid--base">

                    <GridCell span={12}>
                        <EnergyFilter
                            open={true}
                            filter={this.state.filter}
                            onSubmit={this.onFilterSubmit}
                            loading={productionDetailsFetching} />
                    </GridCell>

                    {!productionDetailsFetching && !hasLocations &&
                        <GridCell span={12}>
                            {this.renderNoLocationsNotice()}
                        </GridCell>}
                </Grid>

                {hasLocations &&
                    <Grid className="mdc-layout-grid--base">
                        <GridCell span={12}>
                            {hasLocations
                                ? this.renderElectricityProductionInfo()
                                : <GridInner className="mdc-layout-grid--base mt-25 mt-mobile-15">
                                    <GridCell span={12}>
                                        {this.renderNoLocationsNotice()}
                                    </GridCell>
                                </GridInner>}
                        </GridCell>
                    </Grid>}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        productionDetails: state.productionDetails.data,
        productionDetailsFetching: state.productionDetails.fetching,
        productionDetailsFetched: state.productionDetails.fetched,
        productionDetailsError: state.productionDetails.error,
        productionDetailsParams: state.productionDetails.params
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchProductionDetails,
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(Production));
