import { SET_APP_VERSION, SET_NOTIFICATION_HUB_VARIBLES, SET_USING_APP } from "../../actions/appContainerActions";
import { UsingApp, GetAppVersion, IsCombinedAppContainer } from "../../helpers/AlternativeDesign";

export default function reducer(state = {
    usingApp: UsingApp(),
    usingAppSetTime: null,
    appVersion: GetAppVersion(),
    appVersionSetTime: null,
    notificationHub: { installationId: null, pushChannel: null },
    notificationHubSetTime: null,
    isCombinedAppContainer: IsCombinedAppContainer(),
}, action) {
    switch (action.type) {
        case SET_USING_APP:
            return {
                ...state,
                usingApp: String(action.payload.usingApp).toLowerCase() === "true",
                usingAppSetTime: action.payload.time,
                isCombinedAppContainer: IsCombinedAppContainer(state.appVersion, action.payload.usingApp),
            };
        case SET_APP_VERSION:
            return {
                ...state,
                appVersion: action.payload.appVersion,
                appVersionSetTime: action.payload.time,
                isCombinedAppContainer: IsCombinedAppContainer(action.payload.appVersion, state.usingApp)
            };
        case SET_NOTIFICATION_HUB_VARIBLES:
            return {
                ...state,
                notificationHub: action.payload.notificationHub,
                notificationHubSetTime: action.payload.time
            };
        default:
            return state;
    }
}
