import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material/react-checkbox';
import {useIntl} from "react-intl";
import ValidationHelper from "../../helpers/validationHelper";
import classnames from 'classnames';

const CheckboxField = ({field, checked, label, onChange, className, indeterminate, noLabel, validator, rules, nativeControlId, validationLabel, disabled, containerClassName}) => {
    const intl = useIntl();
    let labelTranslated = validationLabel ? intl.formatMessage({id: validationLabel}) : "";
    const {onBlur, validationMessage, isRequired} = ValidationHelper.getFieldValidationItems(validator, rules, labelTranslated, checked);
    const isObject = (item) => (typeof item === 'object');

    if (!isObject(label)) {
        let labelTranslated = label ? intl.formatMessage({id: label}) : "";
        label = (<label htmlFor={nativeControlId || field}>{labelTranslated}</label>);
    }
    
    return (
        <>
            <div className={classnames("mdc-form-field field", containerClassName)}>
                <Checkbox className={classnames(className, {"mdc-checkbox--invalid" : !!validationMessage})}
                          nativeControlId={nativeControlId || field}
                          required={isRequired}
                          onBlur={onBlur}
                          checked={checked}
                          name={field}
                          indeterminate={indeterminate}
                          onChange={onChange}
                          disabled={disabled}
                />
                {!noLabel && label}
            </div>
            {!!validationMessage && <div>{ValidationHelper.getHelperMessage(validationMessage, "mdc-checkbox-helper-text")}</div>}

        </>
    );
}

CheckboxField.propTypes = {
    field: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    nativeControlId: PropTypes.string,
    indeterminate: PropTypes.bool,
    disabled: PropTypes.bool,
    validator: PropTypes.object,
    rules: PropTypes.string,
    noLabel: PropTypes.bool,
    validationLabel: PropTypes.string
};

CheckboxField.defaultProps = {
    indeterminate: false,
    noLabel: false,
    disabled: false,
    label: '',
    validationLabel: ''
};

export default CheckboxField;
