import AddressApi from './AddressApi';

export const fetchAddress = (addressInput) => {
    return {
        type: "FETCH_ADDRESS",
        payload: AddressApi.get('/inaadress/gazetteer?appartment=1&features=KATASTRIYKSUS&address='+encodeURIComponent(addressInput))
    };
};

export const fetchAddressForLiving = (addressInput) => {
    return {
        type: "FETCH_ADDRESS",
        payload: AddressApi.get('/inaadress/gazetteer?appartment=1&unik=1&features=EHITISHOONE&address='+encodeURIComponent(addressInput))
    };
};

