export default function reducer(state={
    data: {
        address: {
            country: "",
            city: "",
            county: "",
            street: "",
            postalCode: "",
            houseNr: "",
            apartmentNr: "",
            parish: ""
        }
    },
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case "FETCH_ACCOUNT_PENDING": {
            return {...state, fetching: true};
        }
        case "FETCH_ACCOUNT_REJECTED": {
            return {...state, fetching: false, error: action.payload};
        }
        case "FETCH_ACCOUNT_FULFILLED": {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}