import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift'
import classnames from 'classnames';
import TextField, { Input } from '@material/react-text-field';
import { MenuList, MenuListItem, MenuListItemText } from '@material/react-menu';
import Loader from "./Loader";
import ValidationHelper from "../../helpers/validationHelper";
import { useIntl } from 'react-intl';

const SelectAutoCompleteField = ({ field, value, label, onChange, type, options, afterChange, disabled, searchPlaceholder, isLoading, placeholder, validator, rules, noFilter }) => {
    const intl = useIntl();
    let input = null;
    let inputId1 = "SelectAutoCompleteField" + new Date().getTime();
    let inputId2 = "SelectAutoCompleteField2" + new Date().getTime();

    let labelTranslated = label ? intl.formatMessage({ id: label }) : null;
    const isObject = (item) => (typeof item === 'object');
    const selectedItemChanged = (prevItem, item) => {
        let isItemChanged = prevItem !== item;

        if (isItemChanged && afterChange) {
            afterChange();
        }

        return isItemChanged;
    };

    const stateChanged = (changes) => {
        if (changes.isOpen && !!input) {
            input.inputElement.focus();
        }
    };

    const selectedItemEquals = (selectedItem, item) => {
        if (selectedItem && item && selectedItem.value && item.value) {
            return selectedItem.value === item.value;
        }

        return selectedItem === item;
    };

    const itemToString = (item) => {
        // Only do this when no label. This is otherwise too expensive.
        if (item && item.value && !item.label) {
            let result = options.filter(obj => {
                return obj.value === item.value;
            });

            if (result.length > 0) {
                return result[0].label;
            }
        }

        return isObject(item) ? item?.label || "" : item || "";
    };

    const autoComplete = ({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex, selectedItem, clearSelection, toggleMenu, itemToString }) => {
        const clearSelectedText = () => {
            clearSelection();
            toggleMenu();
        };

        const toggleMenuOnClick = () => {
            if (disabled) {
                return;
            }
            toggleMenu();
        };

        const filterOptions = (item) => {
            if (noFilter) { return true; }

            let label = item;

            if (isObject(item)) {
                label = item.label;
            }

            return !inputValue || label.toLowerCase().includes(inputValue.toLowerCase());
        };

        const { onBlur, validationMessage, helperText, isRequired } = ValidationHelper.getFieldValidationItems(validator, rules, labelTranslated, value);

        return (<div className={classnames("mdc-menu-surface--anchor mdc-menu-search", { "mdc-menu-search--no-filter": noFilter })} >
            <TextField
                helperText={!isOpen ? helperText : ""}
                disabled={disabled}
                outlined
                label={labelTranslated}
                className={classnames("mdc-menu-search--holder", { "mdc-menu-search--focused": isOpen, "mdc-text-field--disabled": disabled })}
                trailingIcon={isLoading ? <Loader type={Loader.TYPE_TEXT_FIELD} /> : <span className="mdc-menu-search__dropdown-icon"></span>}
            >
                <Input
                    id={inputId1}
                    required={isRequired}
                    onBlur={onBlur}
                    isValid={isOpen || !validationMessage}
                    onClick={toggleMenuOnClick}
                    readOnly={true}
                    value={itemToString(value) || placeholder}
                />
            </TextField>
            <div {...getMenuProps({ className: classnames("mdc-menu mdc-menu-surface full-width", { "mdc-menu-surface--open": isOpen }) })}>
                {!noFilter &&
                    <TextField
                        outlined
                        noLabel={true}
                        label={searchPlaceholder}
                        leadingIcon={<i className="icon-search"></i>}
                        trailingIcon={value ? <span className="icon-close"></span> : null}
                        onTrailingIconSelect={value ? clearSelectedText : null}>
                        <Input
                            id={inputId2}
                            ref={elem => (input = elem)}
                            {...getInputProps({
                                autoComplete: "none",
                                name: field,
                                type: type
                            })} />
                    </TextField>}
                <MenuList className="mdc-list--select">
                    {isOpen
                        ? options
                            .filter(filterOptions)
                            .map((item, index) => (
                                <MenuListItem
                                    {...getItemProps({
                                        key: isObject(item) ? item.value : index,
                                        index,
                                        item,
                                        className: classnames({
                                            "mdc-list-item--selected": selectedItemEquals(selectedItem, item),
                                            "mdc-list-item--activated": highlightedIndex === index
                                        })
                                    })}
                                >
                                    <MenuListItemText primaryText={isObject(item) ? item.label : item} />
                                </MenuListItem>
                            ))
                        : null}
                </MenuList>
            </div>
        </div >);
    }

    return (
        <Downshift
            selectedItemChanged={selectedItemChanged}
            selectedItem={value}
            onStateChange={stateChanged}
            onChange={onChange}
            itemToString={itemToString}
        >
            {autoComplete}
        </Downshift>);
}

SelectAutoCompleteField.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),
    label: PropTypes.string,
    searchPlaceholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    placeholder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onChange: PropTypes.func.isRequired,
    afterChange: PropTypes.func,
    type: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    validator: PropTypes.object,
    rules: PropTypes.string,
    noFilter: PropTypes.bool,
};

SelectAutoCompleteField.defaultProps = {
    type: "search",
    searchPlaceholder: "",
    isLoading: false,
    noFilter: false,
};

export default SelectAutoCompleteField;

