const CrmCounty = {
    Harju: "Harju maakond",
    Hiiu: "Hiiu maakond",
    IdaViru: "Ida-Viru maakond",
    Jogeva: "Jõgeva maakond",
    Jarva: "Järva maakond",
    Laane: "Lääne maakond",
    LaaneViru: "Lääne-Viru maakond",
    Polva: "Põlva maakond",
    Parnu: "Pärnu maakond",
    Rapla: "Rapla maakond",
    Saare: "Saare maakond",
    Tartu: "Tartu maakond",
    Valga: "Valga maakond",
    Viljandi: "Viljandi maakond",
    Voru: "Võru maakond"
}

const CrmCountyLatvia = {
    Adazu: "Ādažu novads",
    Aizkraukles: "Aizkraukles novads",
    Aluksnes: "Alūksnes novads",
    Augsdaugavas: "Augšdaugavas novads",
    Balvu: "Balvu novads",
    Bauskas: "Bauskas novads",
    Cesu: "Cēsu novads",
    Dienvidkurzemes: "Dienvidkurzemes novads",
    Dobeles: "Dobeles novads",
    Gulbenes: "Gulbenes novads",
    Jekbpils: "Jēkabpils novads",
    Jelgavas: "Jelgavas novads",
    Kekavas: "Ķekavas novads",
    Kaslavas: "Krāslavas novads",
    Kuldigas: "Kuldīgas novads",
    Limbazu: "Limbažu novads",
    Livanu: "Līvānu novads",
    Ludzas: "Ludzas novads",
    Madonas: "Madonas novads",
    Marupes: "Mārupes novads",
    Ogres: "Ogres novads",
    Olaines: "Olaines novads",
    Preilu: "Preiļu novads",
    Rezeknes: "Rēzeknes novads",
    Ropazu: "Ropažu novads",
    Salaspils: "Salaspils novads",
    Saldus: "Saldus novads",
    Saulkrastu: "Saulkrastu novads",
    Siguldas: "Siguldas novads",
    Smiltenes: "Smiltenes novads",
    Talsu: "Talsu novads",
    Tukuma: "Tukuma novads",
    Valkas: "Valkas novads",
    Valmieras: "Valmieras novads",
    Varaklanu: "Varakļānu novads",
    Ventspils: "Ventspils novads"
}

export {
    CrmCounty,
    CrmCountyLatvia
}