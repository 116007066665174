import { Grid, GridCell } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import React from 'react';
import alexelaQrAppImage from "../../images/alexela-qr-app.svg";

export const FuelPlusElectricityRegistered = () => {
    return (
        <Grid>
            <GridCell desktop={6} tablet={4}>
                <Typography use="headline3" className="mdc-typography mdc-theme--primary">
                    <FormattedMessage id="FuelPlusElectricity.Registered.Title"/>
                </Typography>
                <Typography use="body1" className="mdc-typography mt-20">
                    <FormattedHTMLMessage id="FuelPlusElectricity.Registered.TextTop"/>
                </Typography>
                <div className="fuel-plus-electricity__image-wrapper">
                    <img src={alexelaQrAppImage} alt="Instructions"/>
                </div>
                <Typography use="body1" className="mdc-typography mt-20">
                    <FormattedHTMLMessage id="FuelPlusElectricity.Registered.TextBottom"/>
                </Typography>
            </GridCell>
            <GridCell desktop={6} tablet={4}>
                <div className="fuel-plus-electricity-banner fuel-plus-electricity-banner--fuelling"></div>
            </GridCell>
        </Grid>
    )
}