import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import { GridInner, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";

import { downloadProductionDetailsFile } from "../../actions/consumptionlocationActions";
import SelectField from "../common/SelectField";
import ProductionTable from "./ProductionTable";
import ProductionGraph from "./ProductionGraph";
import { formatUnit } from "../../helpers/energyHelpers";
import BaseFormComponent from "../common/BaseFormComponent";
import AlertMessage from "../common/AlertMessage";

class ProductionDetails extends BaseFormComponent {

    constructor(props) {
        super(props);

        this.state = {
            selectedLocationId: this.hasSingleLocation
                ? props.locations.filter(x => !x.isTotal)[0].id
                : props.locations.filter(x => x.isTotal)[0].id
        };

        this.handleExport = this.handleExport.bind(this);
        this.isSelectedLocation = this.isSelectedLocation.bind(this);
        this.renderLocationsSelection = this.renderLocationsSelection.bind(this);
    }

    get hasSingleLocation() {
        return !!this.props.locations && this.props.locations.filter(x => !x.isTotal).length === 1;
    }

    isSelectedLocation(id) {
        return +id === +this.state.selectedLocationId;
    }

    renderLocationsSelection() {
        const { locations } = this.props;
        const { selectedLocationId } = this.state;

        const dropdownItems = locations
            .map((x, i) => ({
                selected: this.isSelectedLocation(x.id),
                value: "" + x.id,
                label: x.name ? x.name : `Koht ${i}`,
                isTotal: x.isTotal
            }));

        if (this.hasSingleLocation) {
            const index = dropdownItems.findIndex(x => x.isTotal);
            dropdownItems.splice(index, 1);
        }

        return (
            <div className="energy-details__locations-wrapper">
                <SelectField
                    field="selectedLocationId"
                    name="dropdown"
                    value={!selectedLocationId ? "" : "" + selectedLocationId}
                    onChange={this.onSelectChange}
                    enhanced
                    menu
                    outlined
                    addEmptyOption={true}
                    options={dropdownItems}
                    className={classnames("mdc-select--no-margin energy-details__locations")}
                >
                </SelectField>
            </div>
        );
    }

    handleExport(fileType) {
        const { filter } = this.props;
        const { selectedLocationId } = this.state;

        const startDate = filter.startDate.format("YYYY-MM-DD 00:00:00");
        const periodType = filter.selectedPeriodType;

        this.props.downloadProductionDetailsFile(startDate, selectedLocationId, periodType, fileType);
    }

    render() {
        const { selectedLocationId } = this.state;
        const { title, isLoading, hasError, details, className, filter } = this.props;

        const isLocationSelected = !!selectedLocationId;

        const productionData = isLocationSelected
            ? details.find(x => this.isSelectedLocation(x.id))
            : details.find(x => x.isTotal);

        const datapoints = !!productionData ? productionData.data : [];
        const amountFormat = !!productionData ? productionData.amountFormat : "";

        const hasDataPoints = datapoints.length > 0;

        const showErrorMessage = !isLoading && hasError;
        const showNoItemsMessage = !isLoading && !hasError && !hasDataPoints;
        const showData = !isLoading && !hasError && hasDataPoints;

        return (
            <>
                <GridInner className={classnames("energy-details", className)} >
                    <GridCell span={12} align="middle" className="d-flex mb-mobile-30">
                        {!!title &&
                            <Typography use="headline4" className="mdc-typography mdc-theme--primary energy-details__title mb-mobile-15" >
                                {title}
                            </Typography>}
                        {this.renderLocationsSelection()}
                    </GridCell>

                    {showErrorMessage &&
                        <GridCell span={12}>
                            <AlertMessage
                                type={AlertMessage.TYPE_ALERT}
                                isSmall={true}
                                title={<FormattedMessage id="Production.Graph.Error" />} />
                        </GridCell>}

                    {showNoItemsMessage &&
                        <GridCell span={12}>
                            <AlertMessage
                                type={AlertMessage.TYPE_NOTICE}
                                isSmall={true}
                                title={<FormattedMessage id="Production.Graph.NoData" />} />
                        </GridCell>}

                    {showData &&
                        <>
                            <GridCell span={12}>
                                <ProductionGraph
                                    data={datapoints}
                                    amountUnit={formatUnit(amountFormat)}
                                    isTotal={!isLocationSelected}
                                    periodType={filter.selectedPeriodType} />
                            </GridCell>

                            <GridCell span={12}>
                                <ProductionTable
                                    {...productionData}
                                    isLoading={isLoading}
                                    onExportClick={this.handleExport}
                                    periodType={filter.selectedPeriodType}
                                />
                            </GridCell>
                        </>}
                </GridInner>
            </>
        );
    }
}

ProductionDetails.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    locations: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            isTotal: PropTypes.bool,
            name: PropTypes.string,
        }),
    ),
    details: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(null)]),
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    amount: PropTypes.number,
                    timestamp: PropTypes.string,
                })
            ),
            isTotal: PropTypes.bool,
            amountFormat: PropTypes.string,
            totalAmount: PropTypes.number,
        })),
    isLoading: PropTypes.bool,
    hasError: PropTypes.bool,
    filter: PropTypes.shape({
        startDate: PropTypes.any,
        selectedPeriodType: PropTypes.any
    }),
    className: PropTypes.string,
}

function mapStateToProps(state) {
    return {};
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        downloadProductionDetailsFile
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(ProductionDetails));
