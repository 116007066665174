import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from 'react-router-dom';
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import Checkbox from "@material/react-checkbox";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../components/common/AlertMessage";
import Loader from "../components/common/Loader";
import {
    ContractUserData,
    ContractFuelConditions,
    ContractFuelDiscounts,
    ContractFuelLimits,
    ContractTerms,
    ContractFuelCreditScore,
    ContractDigitalSign,
    ContractFuelPendingPage
} from "../components/Contract";
import { fetchAccount, fetchContacts } from "../actions/accountActions";
import {
    fetchPaymentCardStatus,
    postProcessPersonCardApplication,
    postPendingCompanyCardApplication,
    postAcceptedPersonCardApplication,
    postAcceptedCompanyCardApplication,
    postFinalizeAcceptedCardApplication,
    downloadPersonCardContractPreviewPdf,
    downloadCompanyCardContractPreviewPdf
} from "../actions/cardActions";
import {
    downloadCylinderGasOfferPreviewPdf,
    fetchActiveOffers,
    rejectOffer,
    resetRejectOffer
} from "../actions/offerActions";
import { CardApplicationType, CompletedFormStatus, PaymentCardStatus } from "../const/cardApplication";
import { ContractsPath, DigitalStampsPath, PaymentCardsPath, ServicePath, StartPath } from "../const/routes";
import { useFormInput, useTimedRedirect, useValidator, useScrollToRef } from "../helpers/hooks";
import { isNullOrEmpty } from "../helpers/objectHelper";
import { Typography } from "@rmwc/typography";
import { isAddressValid, isAddressStringValidRegex } from "../helpers/addressHelper";
import BackButton from "../components/common/BackButton";
import DebtNotice from "../components/common/DebtNotice";
import { EvCharging } from "../const/contractType";
import { Active } from "../const/ContractStatus";
import {fetchContracts} from "../actions/contractActions";

const REDIRECT_DELAY_MS = 10000;
const CYLINDER_GAS_OFFER_URL = "cylindergas";
const CARD_OFFER_URL = "card";

const ContractFuel = () => {
    const role = useSelector(state => state.auth.role);
    const contacts = useSelector(state => state.contacts);
    const account = useSelector(state => state.account);
    const ui = useSelector(state => state.ui);
    const paymentCardStatus = useSelector(state => state.paymentCardStatus);
    const offers = useSelector(state => state.activeOffers);
    const processPersonCardApplication = useSelector(state => state.postProcessPersonCardApplication);
    const rejectOfferState = useSelector(state => state.rejectOffer);
    const contracts = useSelector(state => state.contracts);

    const scrollRef = useRef();

    const dispatch = useDispatch();
    const location = useLocation();
    const intl = useIntl();
    const redirectToPage = useTimedRedirect();
    const scrollToRef = useScrollToRef();
    const agent = new URLSearchParams(location.search).get("agent");

    const [commonValidator, ] = useValidator({
        minLimit: {
            message: intl.formatMessage({id: "Error.Validator.MinLimit"}),
            rule: (val, params) => {
               return val != null && parseInt(val) >= parseInt(params[0]);
            },
            messageReplace: (message, params) => message.replace(':min', params[0])
        },
        maxLimit: {
            message: intl.formatMessage({id: "Error.Validator.MaxLimit"}),
            rule: (val, params) => {
               return val != null && parseInt(val) <= parseInt(params[0]);
            },
            messageReplace: (message, params) => message.replace(':max', params[0])
        },
        phoneformat: {
            message: intl.formatMessage({id: "Error.Validator.Phoneformat"}),
            rule: (val, _, validator) => {
               return validator.helpers.testRegex(val, /^\d{7,14}$/i);
            }
        },
        address: {
            message: intl.formatMessage({id: "Error.Validator.AddressInvalid"}),
            rule: (val) => {
                return isAddressStringValidRegex(val);
            }
        }
    });

    const isFromDigitalStamps = location.state?.isFromDigitalStamps;
    const isCompany = role.isCompany || false;
    const [isFormDisplayed, setIsFormDisplayed] = useState(true);
    const [isFormValid, setIsFormValid] = useState(true);

    const [clientData, setClientData] = useState({});
    const [limitsData, setLimitsData] = useState({
        monthlyLimit: null,
        isPlasticCard: false
    });
    const [isCreditScoreAccepted, setIsCreditScoreAccepted] = useState(false);
    const [isFormCompleted, setIsFormCompleted] = useState(false);
    const [isCreditScoreError, setIsCreditScoreError] = useState(false);
    const [creditScore, setCreditScore] = useState({});
    const [isLimitDisabled, setIsLimitDisabled] = useState(false);
    const [isTermsAccepted, onSetIsTermsAcceptedChanged] = useFormInput(false);
    const [isSignaturePending, setIsSignaturePending] = useState(false);
    const [model, setModel] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isOfferRejected, setIsOfferRejected] = useState(false);
    const [isError, setIsError] = useState(false);

    const fetchData = () => dispatch => {
        dispatch(fetchAccount());
        dispatch(fetchContacts());
    }

    const parseUrl = useCallback(() => {
        const urlParams = location.pathname.split("/");
        const regCode = urlParams.slice(-1)[0];
        const id = parseInt(urlParams.slice(-2)[0]);
        const type = urlParams[3];

        return { id, regCode, type }
    }, [location.pathname]);

    const isOffer = useCallback(() => {
        const urlParams = parseUrl();
        return location.state?.applicationType ||
            (urlParams.type === CYLINDER_GAS_OFFER_URL || urlParams.type === CARD_OFFER_URL);
    }, [parseUrl, location.state?.applicationType]);

    const hasValidEvChargingContract = useMemo(() => {
        return !!contracts.data?.filter(c => c.type === EvCharging && c.status === Active)?.length;
    }, [contracts.data]);

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch, isCompany]);

    const loadOffer = useCallback(() => {
        if (isNullOrEmpty(offers.data) && !offers.fetched && !offers.fetching) {
            dispatch(fetchActiveOffers());
        }
    }, [dispatch, offers.data, offers.fetched, offers.fetching]);

    const loadApplication = useCallback(() => {
        if (isNullOrEmpty(paymentCardStatus.data) && !paymentCardStatus.fetched && !paymentCardStatus.fetching) {
            dispatch(fetchPaymentCardStatus());
        }
        if (isNullOrEmpty(contracts.data) && !contracts.fetched && !contracts.fetching) {
            dispatch(fetchContracts());
        }
    }, [dispatch, paymentCardStatus.data, paymentCardStatus.fetched, paymentCardStatus.fetching, contracts.data, contracts.fetched, contracts.fetching]);

    useEffect(() => {
        if (isOffer()) {
            loadOffer();
        } else {
            loadApplication();
        }
    }, [isOffer, loadOffer, loadApplication]);

    useEffect(() => {
        if (!isOffer() &&
            paymentCardStatus.fetched && !paymentCardStatus.fetching && !isNullOrEmpty(paymentCardStatus.data) &&
            !contracts.fetching && contracts.fetched) {
            const isDisplayed = paymentCardStatus.data && (isCompany ||
                paymentCardStatus.data.cardStatus === PaymentCardStatus.NONE ||
                paymentCardStatus.data.cardStatus === PaymentCardStatus.PENDING_SIGNATURE) &&
                !hasValidEvChargingContract;

            const isPendingSignature = paymentCardStatus.data &&
                paymentCardStatus.data.cardStatus === PaymentCardStatus.PENDING_SIGNATURE;

            setIsSignaturePending(isPendingSignature);
            setIsFormDisplayed(isDisplayed);
            setModel(paymentCardStatus.data);
        }
    }, [contracts, isCompany, paymentCardStatus, isOffer, hasValidEvChargingContract]);

    useEffect(() => {
        if (isOffer() && offers.fetched && !offers.fetching && !isNullOrEmpty(offers.data)) {
            const urlParams = parseUrl();
            const type = urlParams.type === "card"
                ? CardApplicationType.OFFER
                : CardApplicationType.CYLINDER_GAS_OFFER;

            const offer = urlParams?.id
                ? offers.data.find(o => o.id === urlParams.id && o.applicationType === type)
                : offers.data.find(o => o.applicationType === location.state?.applicationType);

            if (!offer) {
                setIsFormDisplayed(false);
                redirectToPage(StartPath, {}, 0);
            } else {
                const isPendingSignature = offer && offer.cardStatus === PaymentCardStatus.PENDING_SIGNATURE;

                setIsSignaturePending(isPendingSignature);
                setIsFormDisplayed(!isNullOrEmpty(offer));
                setModel(offer);
            }
        }
    }, [offers, isOffer, location.pathname, location.state, redirectToPage, parseUrl]);

    useEffect(() => {
        setIsLoading(contacts.fetching || account.fetching || paymentCardStatus.fetching || offers.fetching);
    }, [contacts.fetching, account.fetching, paymentCardStatus.fetching, offers.fetching]);

    useEffect(() => {
        if (rejectOfferState.error) {
            setIsError(true);
            redirectToPage(StartPath);
            dispatch(resetRejectOffer());
        } else if (rejectOfferState.fetched) {
            redirectToPage(ServicePath, {}, 0);
            dispatch(resetRejectOffer());
        }
    }, [dispatch, rejectOfferState.error, rejectOfferState.fetched, redirectToPage]);

    const validateUserData = () => {
        let isAllValid = true;

        if (!isAddressValid(clientData.address)) {
            isAllValid = false;
        }

        // Only plastic card contracts should have a delivery address
        if (limitsData.isPlasticCard && !isAddressValid(clientData.deliveryAddress)) {
            isAllValid = false;
        }

        setIsFormValid(isAllValid);

        if (!isAllValid || !commonValidator.allValid()) {
            commonValidator.showMessages();
            throw new Error();
        }
    }

    const onBeforeCreditCheck = () => {
        validateUserData();
        setIsLimitDisabled(true);
    }

    const onSetClientData = (data) => {
        setIsFormValid(true);
        setClientData(data);
    }

    const createPrivateContractRequestModel = (creditScoreObj) => {
        const addressModel = createAddressModel();
        return {
            id: model.id,
            clientCrmId: parseInt(clientData.clientCrmId),
            email: clientData.email,
            emailDataCarrier: clientData.dataCarrier,
            limit: parseInt(limitsData.monthlyLimit ?? 0),
            signerEmail: clientData.signerEmail,
            phone: clientData.phone,
            personalCode: clientData.code,
            creditScoreError: creditScoreObj.scoreError || null,
            creditScoreValue: creditScoreObj.scoreValue || null,
            isVirtual: !limitsData.isPlasticCard,
            fullName: clientData.fullName,
            agent: agent,
            invoiceFrequencyXc: model.invoiceFrequencyXc,
            ...addressModel
        };
    }

    const createCompanyContractRequestModel = (data) => {
        const addressModel = createAddressModel();
        return {
            id: model.id,
            clientCrmId: parseInt(clientData.clientCrmId),
            email: clientData.email,
            emailDataCarrier: clientData.dataCarrier,
            limit: parseInt(limitsData.monthlyLimit ?? 0),
            phone: clientData.phone,
            registryCode: clientData.code,
            companyName: clientData.fullName,
            signerEmail: clientData.signerEmail,
            creditLimitSum: data?.creditLimitSum,
            creditRatingCode: data?.creditRatingCode,
            creditRatingText: data?.creditRatingText,
            probabilityOfInsolvency: data?.probabilityOfInsolvency,
            products: model.products,
            agent: agent,
            conditions: model.conditions,
            applicationType: model.applicationType || CardApplicationType.APPLICATION,
            invoiceFrequencyXc: model.invoiceFrequencyXc,
            dueDays: parseInt(model.dueDays),
            ...addressModel
        };
    }

    const createAddressModel = () => {
        return {
            addressStreet: clientData.address?.street,
            addressHouseNo: clientData.address?.houseNr,
            addressApartmentNo: clientData.address?.apartmentNr,
            addressCityTown: clientData.address?.city,
            addressCounty: clientData.address?.county,
            addressParish: clientData.address?.parish,
            addressPostalCode: parseInt(clientData.address?.postalCode ?? 0),
            deliveryAddressStreet: clientData.deliveryAddress?.street,
            deliveryAddressHouseNo: clientData.deliveryAddress?.houseNr,
            deliveryAddressApartmentNo: clientData.deliveryAddress?.apartmentNr,
            deliveryAddressCityTown: clientData.deliveryAddress?.city,
            deliveryAddressCounty: clientData.deliveryAddress?.county,
            deliveryAddressPostalCode: parseInt(clientData.deliveryAddress?.postalCode ?? 0),
            deliveryAddressParish: clientData.deliveryAddress?.parish
        };
    }

    const onAcceptedCreditScore = async (creditScore) => {
        setCreditScore(creditScore);
        setIsCreditScoreAccepted(true);
        return CompletedFormStatus.ACCEPTED;
    }

    const handlePendingCreditScore = (data, handlerFn) => async dispatch => {
        if (!isFormCompleted) {
            try {
                const { value } = await dispatch(handlerFn(data));

                return value.data?.isRejected
                    ? CompletedFormStatus.REJECTED
                    : CompletedFormStatus.SENT_TO_REVIEW;
            } catch (error) {
                return CompletedFormStatus.TECHNICAL_ERROR;
            } finally {
                setIsFormCompleted(true);
            }
        }
    }

    const handleCompanyCreditScore = (data) => async dispatch => {
        const model = createCompanyContractRequestModel(data);
        return dispatch(postPendingCompanyCardApplication(model));
    }

    const handlePendingPersonCreditScore = (data) => async dispatch => {
        const model = createPrivateContractRequestModel(data);
        return dispatch(postProcessPersonCardApplication(model));
    }

    const onPendingPersonCreditScore = (data) => async dispatch => {
        const result = await dispatch(handlePendingCreditScore(data, handlePendingPersonCreditScore));

        redirectToPage(StartPath, { contractStatus: result }, REDIRECT_DELAY_MS);

        return result;
    }

    const onPendingCompanyCreditScore = (data) => async dispatch => {
        const result = await dispatch(handlePendingCreditScore(data, handleCompanyCreditScore));

        redirectToPage(StartPath, { contractStatus: result }, REDIRECT_DELAY_MS);

        return result;
    }

    const validateSigningModel = () => {
        try {
            validateUserData();
            return true;
        } catch {
            scrollToRef(scrollRef);
            return false;
        }
    }

    const onPrepareSigning = () => async dispatch => {
        try {
            if (isCompany) {
                const model = createCompanyContractRequestModel();
                const { value } = await dispatch(postAcceptedCompanyCardApplication(model));
                return value.data;
            } else {
                const model = createPrivateContractRequestModel(creditScore);
                const { value } = await dispatch(postAcceptedPersonCardApplication(model));
                return value.data;
            }
        } catch {
            return null;
        }
    }

    const activateContract = (signedData) => async dispatch => {
        await dispatch(postFinalizeAcceptedCardApplication({
            cardApplicationId: signedData.cardApplicationId,
            applicationType: model.applicationType || CardApplicationType.APPLICATION
        }));
        setIsFormCompleted(true);

        let pageToRedirect;
        if (isFromDigitalStamps) {
            pageToRedirect = ui.isMobileOrTabletView ? DigitalStampsPath : StartPath;
        } else {
            pageToRedirect = model.applicationType === CardApplicationType.CYLINDER_GAS_OFFER
                ? ContractsPath
                : PaymentCardsPath;
        }

        redirectToPage(pageToRedirect, { contractStatus: CompletedFormStatus.ACCEPTED }, 3000);
    }

    const isFormReadOnly = () => {
        return isFormCompleted || isCreditScoreAccepted || isSignaturePending;
    }

    const isSigningComponentDisabled = () => {
        return !isTermsAccepted || isOfferRejected;
    }

    const onDownloadPreviewPdf = () => {
        if (isCompany) {
            const model = createCompanyContractRequestModel(limitsData);
            if (!isNullOrEmpty(model)) {
                switch (model.applicationType) {
                    case CardApplicationType.APPLICATION:
                    case CardApplicationType.OFFER:
                        dispatch(downloadCompanyCardContractPreviewPdf(model));
                        break;
                    case CardApplicationType.CYLINDER_GAS_OFFER:
                        dispatch(downloadCylinderGasOfferPreviewPdf(model));
                        break;
                    default:
                        break;
                }
            }
        } else {
            const model = createPrivateContractRequestModel(limitsData);
            if (!isNullOrEmpty(model)) {
                dispatch(downloadPersonCardContractPreviewPdf(model));
            }
        }
    }

    const renderTermsCheckbox = () => {
        return (
            <>
                <Checkbox
                    nativeControlId="accept-terms"
                    name="cbAcceptTerms"
                    checked={false}
                    indeterminate={false}
                    onChange={onSetIsTermsAcceptedChanged}
                />
                <label htmlFor="accept-terms">
                    <FormattedMessage id="ContractsPage.Contract.AgreeTerms"
                        values={{
                            terms: <a
                                href={intl.formatMessage({ id: "ContractsPage.AddContract.TermsLink" })}
                                target="_blank" rel="noopener noreferrer">
                                <FormattedMessage id="ContractsPage.Contract.Terms" />
                            </a>
                        }}
                    />
                </label>
            </>
        );
    }

    const renderTitle = () => {
        let key;
        switch (model.applicationType) {
            case CardApplicationType.APPLICATION:
                key = "ContractsPage.AddContract.PaymentCard";
                break;
            case CardApplicationType.OFFER:
                key = "ContractsPage.AddContract.CardOffer";
                break;
            case CardApplicationType.CYLINDER_GAS_OFFER:
                key = "ContractsPage.AddContract.CylinderGas";
                break;
            default:
                key = "ContractsPage.AddContract.PaymentCard";
                break;
        }

        return <FormattedMessage id={key} />
    }

    const onRejectOfferClick = () => {
        dispatch(rejectOffer(model.id));
        setIsOfferRejected(true);
    }

    const renderGenericError = () => {
        return (
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_ALERT}
                    title={<FormattedMessage id="ContractsPage.GenericError.Title" />}
                    description={<FormattedMessage id="ContractsPage.GenericError.Description" />}
                />
            </GridCell>
        );
    }

    if (isLoading) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    if (account.data.hasDebt) {
        return <DebtNotice/>;
    }

    return (
        <>
            {!isFormDisplayed && <ContractFuelPendingPage paymentCardStatus={model} hasValidEvChargingContract={hasValidEvChargingContract} />}
            {(account.error || contacts.error) &&
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={<FormattedMessage id="ContractsPage.Notice.TechnicalError.Title" />}
                    description={<FormattedMessage id="ContractsPage.Notice.TechnicalError.Description" />}
                />
            }
            {isFormDisplayed && contacts.fetched && account.fetched &&
                <Grid className="mdc-layout-grid--base mdc-layout-grid--base-narrow pb-30">
                    <GridCell span={12}>
                        <BackButton toPath={ServicePath} label="ContractsPage.Back" />
                    </GridCell>
                    <GridCell span={12} className="mdc-typography mdc-typography--headline3 mdc-typography--bold mb-25">
                        {renderTitle()}
                    </GridCell>
                    <div ref={scrollRef} />
                    <GridCell span={12} className="mdc-typography mdc-typography--headline4 mdc-typography--bold mb-25">
                        {isCompany
                            ? <FormattedMessage id="ContractsPage.AddContract.CompanyInfo" />
                            : <FormattedMessage id="ContractsPage.AddContract.ContactInfo" />
                        }
                    </GridCell>
                    <ContractUserData
                        contacts={contacts.data}
                        account={account.data}
                        additionalData={model}
                        setUserData={onSetClientData}
                        isReadOnly={isFormReadOnly() && model.applicationType !== CardApplicationType.OFFER}
                        isCompany={isCompany}
                        hasDeliveryAddress={limitsData.isPlasticCard || false}
                        validator={commonValidator}
                    />
                    {!isFormValid &&
                        <GridCell span={12}>
                            <Typography use="body1" className="mdc-typography--bold field-required">
                                <i className="icon icon-alert-small pr-10"/>
                                <FormattedMessage id="ContractsPage.Notice.FillAllFields" />
                            </Typography>
                        </GridCell>
                    }
                    <ContractFuelLimits
                        model={model}
                        setLimitData={setLimitsData}
                        validator={commonValidator}
                        isReadOnly={isFormReadOnly() || isLimitDisabled}
                        isCompany={isCompany}
                    />
                    <ContractFuelDiscounts model={model} />
                    <ContractFuelConditions model={model} />
                    {!isSignaturePending &&
                        <ContractFuelCreditScore
                            validator={commonValidator}
                            requestedLimit={limitsData?.monthlyLimit}
                            onRejectedCreditScore={onPendingPersonCreditScore}
                            onAcceptedCreditScore={onAcceptedCreditScore}
                            onCompanyCreditScore={onPendingCompanyCreditScore}
                            isLoading={processPersonCardApplication.fetching}
                            setCreditScoreError={setIsCreditScoreError}
                            isCompany={isCompany}
                            onBeforeClick={onBeforeCreditCheck}
                        />
                    }
                    {((isCreditScoreAccepted && !isCreditScoreError) || isSignaturePending) &&
                        <>
                            <ContractTerms
                                termsTitleId="ContractsPage.Contract.TermsAndConditions"
                                termsTextId="ContractsPage.PaymentCard.TermsText"
                            />
                            {isError ?
                                renderGenericError() :
                                <ContractDigitalSign
                                    isModelValidToSign={() => validateSigningModel()}
                                    disabled={isSigningComponentDisabled()}
                                    validator={commonValidator}
                                    getFileToSign={onPrepareSigning}
                                    onDocumentSigned={activateContract}
                                    onDownloadPreview={onDownloadPreviewPdf}
                                    extraButton={isOffer() ?
                                        <Button
                                            disabled={isOfferRejected}
                                            outlined
                                            className="digital-sign--preview-button"
                                            onClick={onRejectOfferClick}
                                        >
                                            <FormattedMessage id="ContractsPage.Offer.Reject" />
                                        </Button> : null
                                    }
                                >
                                    {renderTermsCheckbox()}
                                </ContractDigitalSign>
                            }
                        </>
                    }
                </Grid>
            }
        </>
    );
}

export default ContractFuel;