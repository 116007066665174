export default function reducer(state = {
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case "FETCH_PAYMENT_CARD_STATUS_PENDING": {
            return { ...state, fetching: true, error: null };
        }
        case "FETCH_PAYMENT_CARD_STATUS_REJECTED": {
            return { ...state, fetching: false, error: action.payload };
        }
        case "FETCH_PAYMENT_CARD_STATUS_FULFILLED": {
            return { ...state, fetching: false, fetched: true, data: action.payload.data };
        }
        default:
            return state;
    }
}