import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const GET_DIGITAL_STAMPS_SUMMARY = "GET_DIGITAL_STAMPS_SUMMARY";
export const GET_DIGITAL_STAMPS_SUMMARY_CLEAR = "GET_DIGITAL_STAMPS_SUMMARY_CLEAR";
export const GET_DIGITAL_STAMPS_TRANSACTIONS = "GET_DIGITAL_STAMPS_TRANSACTIONS";
export const GET_DIGITAL_STAMPS_TRANSACTIONS_CLEAR = "GET_DIGITAL_STAMPS_TRANSACTIONS_CLEAR";
export const GET_CONTRACT_SUMMARY_CLIENT = "FETCH_CONTRACTS";
export const POST_CONVERT_STAMPS = "POST_CONVERT_STAMPS";
export const POST_CONVERT_PRODUCT = "POST_CONVERT_PRODUCT";
export const POST_DIGITAL_STAMPS_CLIENT_ADD_CUSTOMER = "POST_DIGITAL_STAMPS_CLIENT_ADD_CUSTOMER";
export const GET_STATIONS_FOR_CLIENT = "GET_STATIONS_FOR_CLIENT";
export const POST_TRANSFER_TO_CARD = "POST_TRANSFER_TO_CARD";
export const GET_DIGITAL_STAMPS_PRODUCTS_CLIENT = "GET_DIGITAL_STAMPS_PRODUCTS_CLIENT";
export const GET_RECENT_ELECTRICITY_INVOICES = 'FETCH_RECENT_ELECTRICITY_INVOICES';
export const GET_BONUSCARD_STATUS = "GET_BONUSCARD_STATUS";
export const GET_BONUSCARD_STATUS_CLEAR = "GET_BONUSCARD_STATUS_CLEAR";
export const POST_BIND_BONUSCARD = "POST_BIND_BONUSCARD";
export const GET_CAMPAIGN_PRIZES_CLIENT = "GET_CAMPAIGN_PRIZES_CLIENT";
export const POST_REDEEM_CAMPAIGN_PRIZE_CLIENT = "POST_REDEEM_CAMPAIGN_PRIZE_CLIENT";

export const getDigitalStampsSummary = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: GET_DIGITAL_STAMPS_SUMMARY,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/digitalStamps/summary`)
    };
}

export const getDigitalStampsSummaryForClient = (personalCode) => {
    return {
        type: GET_DIGITAL_STAMPS_SUMMARY,
        payload: BusinessApi.get(`/api/digitalStampsClient/summary?code=${personalCode}`)
    };
}

export const clearDigitalStampsSummaryForClient = () => {
    return {
        type: GET_DIGITAL_STAMPS_SUMMARY_CLEAR
    };
}

export const getDigitalStampsTransactions = ({ dateFrom = "", dateTo }) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: GET_DIGITAL_STAMPS_TRANSACTIONS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/digitalStamps/transactions?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    };
}

export const getDigitalStampsTransactionsForClient = ({ dateFrom = "", dateTo, personalCode }) => {
    return {
        type: GET_DIGITAL_STAMPS_TRANSACTIONS,
        payload: BusinessApi.get(`/api/digitalStampsClient/transactions?dateFrom=${dateFrom}&dateTo=${dateTo}&personalCode=${personalCode}`)
    };
}

export const clearDigitalStampsTransactionsForClient = () => {
    return {
        type: GET_DIGITAL_STAMPS_TRANSACTIONS_CLEAR
    };
}

export const getContractSummaryClient = (crmAccountId, personalCode) => {
    return {
        type: GET_CONTRACT_SUMMARY_CLIENT,
        payload: BusinessApi.get(`/api/digitalStampsClient/contracts?crmAccountId=${crmAccountId}&personalCode=${personalCode}`)
    };
}

export const getRecentElectricityInvoices = (crmAccountId, months = 3) => {
    return {
        type: GET_RECENT_ELECTRICITY_INVOICES,
        payload: BusinessApi.get(`/api/digitalStampsClient/invoices?crmAccountId=${crmAccountId}&months=${months}`)
    }
}

export const convertStampsClient = (data) => {
    return {
        type: POST_CONVERT_STAMPS,
        payload: BusinessApi.post(`/api/digitalStampsClient/convert-stamps`, data)
    };
}

export const convertStamps = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: POST_CONVERT_STAMPS,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/digitalStamps/convert-stamps`, data)
    };
}

export const clearConvertStamps = () => {
    return {
        type: `${POST_CONVERT_STAMPS}_CLEAR`
    };
}

export const postAddCustomer = (data) => {
    return {
        type: POST_DIGITAL_STAMPS_CLIENT_ADD_CUSTOMER,
        payload: BusinessApi.post("api/digitalStampsClient/add-customer", data)
    }
};

export const getStations = () => {
    // we get stations from Client Tablet API
    return {
        type: GET_STATIONS_FOR_CLIENT,
        payload: BusinessApi.get("api/clienttablet/stations")
    }
};

export const transferToCard = (contractId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: POST_TRANSFER_TO_CARD,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/digitalStamps/transfer-balance`, { contractId: contractId })
    };
}

export const getProductsClient = () => {
    return {
        type: GET_DIGITAL_STAMPS_PRODUCTS_CLIENT,
        payload: BusinessApi.get("/api/digitalStampsClient/products")
    };
}

export const convertProductClient = (data) => {
    return {
        type: POST_CONVERT_PRODUCT,
        payload: BusinessApi.post(`/api/digitalStampsClient/convert-product`, data)
    };
}

export const getBonuscardStatus = (number) => {
    return {
        type: GET_BONUSCARD_STATUS,
        payload: BusinessApi.get(`/api/digitalStampsClient/bonuscard-status/${number}`)
    };
}

export const clearBonuscardStatus = () => {
    return {
        type: GET_BONUSCARD_STATUS_CLEAR
    };
}

export const postBindBonuscard = (data) => {
    return {
        type: POST_BIND_BONUSCARD,
        payload: BusinessApi.post(`/api/digitalStampsClient/bind-bonuscard`, data)
    };
}

export const getCampaignPrizesClient = (crmAccountId) => {
    return {
        type: GET_CAMPAIGN_PRIZES_CLIENT,
        payload: BusinessApi.get(`/api/digitalStampsClient/campaign-prizes?code=${crmAccountId}`)
    };
}

export const redeemCampaignPrizeClient = (data) => {
    return {
        type: POST_REDEEM_CAMPAIGN_PRIZE_CLIENT,
        payload: BusinessApi.post(`/api/digitalStampsClient/redeem-campaign-prize`, data)
    };
}