export default function reducer(state={
    data: null,
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `HAS_GASO_PERMISSION_PENDING`: {
            return {...state, fetching: true};
        }
        case `HAS_GASO_PERMISSION_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `HAS_GASO_PERMISSION_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}