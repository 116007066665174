import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";

import { ButtonIcon, Button } from "@rmwc/button";
import { Typography } from "@rmwc/typography";

import Loader from "./Loader";
import { getBonusCardQR } from "../../actions/cardActions";
import cardImage from "../../images/card.png";
import qrGuideImage from "../../images/qr-guide.png";

import "../../styles/components/_card-qr-overlay.scss";

class CardQrCodeOverlay extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showInstructions: false
        };

        this.openInstructions = this.openInstructions.bind(this);
        this.closeInstructions = this.closeInstructions.bind(this);
    }

    componentDidMount() {
        if (!this.props.bonuscardQRFetching && !!this.props.cardNumber) {
            this.refreshQR();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cardNumber !== this.props.cardNumber) {
            this.refreshQR();
        }
    }

    refreshQR() {
        this.props.getBonusCardQR(this.props.cardNumber);
    }

    openInstructions() {
        this.setState({ showInstructions: true });
    }

    closeInstructions() {
        this.setState({ showInstructions: false });
    }

    addSpaceWithIntervals = (value, interval) => {
        value = "" + value;
        const r = new RegExp(`(\\d+)(\\d{${interval}})`);
        while (r.test(value)) {
            value = value.replace(r, '$1 $2');
        }
        return value;
    }

    renderInstructionsOverlay() {
        return (
            <div className="card-qr-overlay card-qr-overlay--instructions">
                <Typography use="headline3" className="mdc-typography card-qr-overlay__title mdc-theme--primary mb-24 mt-24" >
                    <FormattedMessage id="Card.QR.Instructions.Title" />
                </Typography>
                <div className="card-qr-overlay__image-wrapper">
                    <img src={qrGuideImage} alt="Instructions" />
                </div>
                <div className="card-qr-overlay__text mt-32 mb-32">
                    <FormattedHTMLMessage id="Card.QR.Instructions.Text" />
                </div>
                <Button
                    onClick={this.closeInstructions}
                    className="mdc-button mdc-button--secondary mdc-button--thin mdc-button--wide"
                    outlined>
                    <FormattedMessage id="Card.QR.Instructions.Back" />
                </Button>
            </div >
        );
    }

    render() {
        const { cardNumber, bonuscardQRFetched, bonuscardQRFetching } = this.props;
        const { showInstructions } = this.state;

        return (
            showInstructions
                ? this.renderInstructionsOverlay()
                : <div className="card-qr-overlay card-qr-overlay--code">
                    <div className="card-qr-overlay__info">
                        <ButtonIcon
                            onClick={this.openInstructions}
                            icon={{
                                icon: "info-fill",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-",
                            }}></ButtonIcon>
                    </div>
                    <div className="card-qr-overlay__card">
                        <img src={cardImage} alt="" />
                        <div className="number">
                            {this.addSpaceWithIntervals(cardNumber, 4)}
                        </div>
                    </div>
                    <div className="card-qr-overlay__image-wrapper">
                        {bonuscardQRFetching
                            ? <div className="qr-loader">
                                <Loader type={Loader.TYPE_CENTER} isWhite />
                            </div>
                            : (bonuscardQRFetched &&
                                <img src={this.props.bonuscardQR.base64Image} alt="QR code" />)
                        }
                    </div>

                    <div className="card-qr-overlay__footer">
                        <div className="card-qr-overlay__close-button">
                            <ButtonIcon
                                onClick={this.props.onClose}
                                icon={{
                                    icon: "close",
                                    strategy: "className",
                                    basename: "icon",
                                    prefix: "icon-",
                                }}></ButtonIcon>
                        </div>
                    </div>
                </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        bonuscardQR: state.bonuscardQR.data,
        bonuscardQRFetching: state.bonuscardQR.fetching,
        bonuscardQRFetched: state.bonuscardQR.fetched,
        bonuscardQRError: state.bonuscardQR.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getBonusCardQR
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(CardQrCodeOverlay));
