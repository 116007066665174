import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {FormattedMessage, injectIntl} from 'react-intl';
import TextFieldGroup from "../../components/common/TextFieldGroup";
import BaseFormComponent from '../../components/common/BaseFormComponent';
import { fetchAlerts, putAlert} from "../../actions/stockExchangePriceAlertActions";
import { Button } from "@rmwc/button";
import PropTypes from 'prop-types';
import { Typography } from "@rmwc/typography";
import SelectField from "../common/SelectField";

export const active = [
        {key: true, value: "Jah"},
        {key: false, value: "Ei"}
    ];

class StockPriceAlertList extends BaseFormComponent {

    static FieldEmail = "alert.email";
    static FieldLimit = "alert.exchangePriceLimit"
    static FieldIsActive = "alert.isActive";

    constructor(props) {
        super(props);

        this.state = {
            alerts: this.props.items,
            filter: this.props.filter,
            editMode: {
                status: false,
                key: "",
            },
            alert: {},
            selectedActivity: {},
            activityValue: ""
        };

        this.onChangeDropdown = this.onChangeDropdown.bind(this);
    }

    onChangeDropdown(afterChange, field, e) {
        let index = e.detail.index;
        let stateCopy = Object.assign({}, this.state);
        let selected = active[index];
        let value = selected ? selected.value : null;
        stateCopy.activityValue = value;
        stateCopy.selectedActivity = selected;
        this.setState({
            activityValue: value,
            selectedActivity: selected
        });
    }

    editAlert(alert) {
        let selectedActivity;
        let activityValue;

        if (alert) {
            selectedActivity = active.find(c => alert.isActive === c.key);
            activityValue = selectedActivity ? selectedActivity.value : active[0].value;
        }

        this.setState({
            editMode: {
                status: true,
                key: alert.accountCrmId
            },
            alert: {
                accountCrmId: alert.accountCrmId,
                email: alert.email,
                exchangePriceLimit: alert.exchangePriceLimit,
                isActive: alert.isActive,
            },
            selectedActivity: selectedActivity,
            activityValue: activityValue
        });
    }

    saveAlert(alert) {
         let data = {
            AccountCrmId: this.state.alert.accountCrmId,
            IsActive: this.state.selectedActivity.key,
            ExchangePriceLimit: this.state.alert.exchangePriceLimit,
            Email: this.state.alert.email
        };

        this.props.putAlert(data).then(() => {
            this.props.fetchAlerts(this.state.alert.email, this.state.filter.crmId).then(() => {
                this.setState({
                    editMode: {
                        status: false,
                        key: ""
                    },
                    alert: alert,
                    alerts: this.props.stockPriceAlerts
                });
            });
        });
    }

    cancelEditing() {
        this.setState({
            editMode: {
                status: false,
                key: ""
            },
            alert: {},
            selectedActivity: "",
            activityValue: ""
        })
    }

    createListItems(selected) {
        let options = active ? active.map(
            c => {
                return ({
                    selected: c.value === selected,
                    value: c.value,
                    label: c.value
                })
            }
        ) : [];

        return options;
    }

    renderAlertTableRow(alert, i) {
        const dropdownItems = this.createListItems(alert.isActive);

        return (
            <tr key={alert.accountCrmId}>
                <td>
                    {alert.accountCrmId}
                </td>
                <td className="w-25">
                    {this.state.editMode.status && this.state.editMode.key === alert.accountCrmId
                        ? <TextFieldGroup field={StockPriceAlertList.FieldEmail}
                                        value={this.state.alert.email}
                                        onChange={this.onChange}
                                        type="email"
                                        noLabel={true}
                                        rules="required"
                                        label="StockPriceAlerts.Table.Email"
                                        showErrorMessage={false}/>
                        : alert.email
                    }
                </td>
                <td>
                    {this.state.editMode.status && this.state.editMode.key === alert.accountCrmId
                        ? <TextFieldGroup field={StockPriceAlertList.FieldLimit}
                                        value={this.state.alert.exchangePriceLimit}
                                        onChange={this.onChange}
                                        type="number"
                                        noLabel={true}
                                        rules="required|numeric|min:19|max:19|regex:^\d+$"
                                        label="StockPriceAlerts.Table.CrmId"
                                        showErrorMessage={false}/>
                        : alert.exchangePriceLimit
                    }
                </td>
                <td>
                    {this.state.editMode.status && this.state.editMode.key === alert.accountCrmId
                        ? <SelectField
                            field={StockPriceAlertList.FieldIsActive}
                            onChange={this.onChangeDropdown}
                            value={this.state.activityValue}
                            options={dropdownItems}
                            isLoading={false}
                            disabled={false}
                            className="mdc-select--no-margin"
                        />
                        :
                        <>
                            {alert.isActive
                                ? <FormattedMessage id="General.Yes" />
                                : <FormattedMessage id="General.No" />
                            }
                        </>
                    }
                </td>
                <td>
                    <FormattedMessage id="StockPriceAlerts.Env.Alexela" />
                </td>
                <td>
                    {this.state.editMode.status && this.state.editMode.key === alert.accountCrmId
                        ?
                        <>
                            <Button
                                unelevated
                                className="mdc-button--thin"
                                onClick={this.saveAlert.bind(this, alert)}
                            >
                                <FormattedMessage id="StockPriceAlerts.Save" />
                            </Button>
                            &nbsp;
                            <Button
                                unelevated
                                className="mdc-button--thin"
                                onClick={this.cancelEditing.bind(this)}
                            >
                                <FormattedMessage id="StockPriceAlerts.Cancel" />
                            </Button>
                        </>
                        :
                        <Button
                            unelevated
                            className="mdc-button--thin"
                            disabled={this.state.editMode.status}
                            onClick={this.editAlert.bind(this, alert)}
                        >
                            <FormattedMessage id="StockPriceAlerts.Change" />
                        </Button>
                    }
                </td>
            </tr>
        );
    }

    renderAlertsTable(alerts) {
        return (
            <div className="table-wrapper mb-30 mb-mobile-30">
                <table className="table table--list">
                    <thead>
                        <tr>
                            <th className="w-18">
                                <Typography use="subtitle1" className="mdc-typography">
                                    <FormattedMessage id="StockPriceAlerts.Table.CrmId" />
                                </Typography>
                            </th>
                            <th className="w-18">
                                <Typography use="subtitle1" className="mdc-typography">
                                    <FormattedMessage id="StockPriceAlerts.Table.Email" />
                                </Typography>
                            </th>
                            <th className="w-18">
                                <Typography use="subtitle1" className="mdc-typography">
                                    <FormattedMessage id="StockPriceAlerts.Table.PriceLimit" />
                                </Typography>
                            </th>
                            <th className="w-18">
                                <Typography use="subtitle1" className="mdc-typography">
                                    <FormattedMessage id="StockPriceAlerts.Table.IsActive" />
                                </Typography>
                            </th>
                            <th className="w-18">
                                <Typography use="subtitle1" className="mdc-typography">
                                    <FormattedMessage id="StockPriceAlerts.Table.Env" />
                                </Typography>
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {alerts}
                    </tbody>
                </table>
            </div>
        );
    }


    render() {
        const tableRows = this.props.items.map((item, i) => this.renderAlertTableRow(item, i));
        return (
            <>
                {tableRows.length > 0 &&
                    <>
                        <Typography
                            className="mdc-typography mdc-theme--primary down"
                            use="headline3"
                        >
                            <FormattedMessage id="StockPriceAlerts.Title" />
                        </Typography>
                        {this.renderAlertsTable(tableRows)}
                    </>
                }
            </>
        );
    }
}

StockPriceAlertList.propTypes = {
    items: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        isPutAlertFetching: state.putAlert.fetching,
        isPutAlertFetched: state.putAlert.fetched,
        putAlertError: state.putAlert.error,
        stockPriceAlerts: state.stockPriceAlerts.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        putAlert,
        fetchAlerts
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(StockPriceAlertList));
