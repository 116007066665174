import {FETCH_CARDS_DISCOUNTS}  from '../../actions/discountActions';

export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_CARDS_DISCOUNTS}_PENDING`: {
            return {...state, fetching: true, error: null};
        }
        case `${FETCH_CARDS_DISCOUNTS}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${FETCH_CARDS_DISCOUNTS}_FULFILLED`: {
            let cardsDiscounts = action.payload.data;
            let mappedCardsDiscounts = cardsDiscounts.reduce(function(map, obj) {
                map[obj.bonuscardNr] = obj;
                return map;
            }, {});
            return {...state, fetching: false, fetched: true, data: mappedCardsDiscounts};
        }
        default:
            return state;
    }
}