const BankIdentifierStartIndex = 4;
const BankIdentifierEndPosition = 6;
const BankIdentifierLength = 2;
const AccountNumberLength = 20;

const BankIcons = {
    "10": "seb",
    "42": "coop",
    "22": "swed",
    "96": "luminor",
    "17": "luminor",
    "77": "lhv"
};

const getBankByAccountNumber = (banks, accountNumber) => {
    let correctBank = {};
    if (banks && accountNumber && accountNumber.length >= BankIdentifierEndPosition) {
        const iban = accountNumber.substr(
            BankIdentifierStartIndex,
            BankIdentifierLength
        );

        banks.forEach(bank => {
            if (bank.ibanId === iban) {
                correctBank = bank;
            }
        })
    }

    return correctBank;
};

const getBankIconByIBAN = (iban) => {
    if (BankIcons.hasOwnProperty(iban)) {
        return BankIcons[iban];
    }
    return null;
}

export {
    AccountNumberLength,
    getBankByAccountNumber,
    getBankIconByIBAN
};
