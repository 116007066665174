import React from 'react';
import {HelperText} from '@material/react-text-field';
import classnames from 'classnames';
import {SelectHelperText} from '@material/react-select';

const ValidationHelper = {
    getFieldValidationItems(validator, rules, label, value) {
        let validationMessage = "";
        let onBlur = () => {};
        let helperText = null;
        let isRequired = false;

        if (validator && !!rules) {
            if (label) {
                let validationField = this.getValidationField(label);
                validationMessage = validator.message(validationField, value, rules);

                onBlur = () => {
                    validator.showMessageFor(validationField)
                };
            }

            helperText = validationMessage ?
                <HelperText className="mdc-text-field-helper-text--validation-msg"><i className="icon icon-alert-small"></i>{this.setAttributeUpperCase(validationMessage)}</HelperText>
                : null;

            isRequired = this.isRequired(rules);
        }

        return {
            onBlur,
            validationMessage,
            helperText,
            isRequired
        };
    },

    // Method for rmwc TextField
    getFieldValidationItemsRmwc(validator, rules, label, value) {
        let validationModel = this.getFieldValidationItems(validator, rules, label, value);
        let helperText = validationModel.validationMessage ? <><i className="icon icon-alert-small"></i>{this.setAttributeUpperCase(validationModel.validationMessage)}</> : null;
        let helperTextObject = {
            persistent: false,
            validationMsg: true,
            children:  helperText
        };

        validationModel.helperText = helperTextObject;

        return validationModel;
    },

    //hack to make selected attribute names first letter uppercase
    //just add the needed word into dictionary
    setAttributeUpperCase(message) {
        const dict = ["coop"];
        let result = message;

        if (message) {
            dict.forEach(word => {
                if (message.includes(word)) {
                    result = result.indexOf(word) > 0
                    ? result.substring(0, result.indexOf(word)) + result.charAt(result.indexOf(word)).toUpperCase() + result.slice(result.indexOf(word) + 1)
                    : result.charAt(result.indexOf(word)).toUpperCase() + result.slice(result.indexOf(word) + 1);
                }
                else {
                    return result;
                }
            });
        }
        return result;
    },

    getSelectHelperText(validationMessage) {
        return validationMessage ?
            <SelectHelperText persistent className="mdc-select-helper-text--validation-msg"><i className="icon icon-alert-small"></i>{this.setAttributeUpperCase(validationMessage)}</SelectHelperText>
            : <SelectHelperText></SelectHelperText>;
    },

    getHelperMessage(validationMessage, className) {
        return validationMessage ?
            <HelperText validation persistent className={classnames("mdc-theme--error", className)}><i className="icon icon-alert-small"></i>{this.setAttributeUpperCase(validationMessage)}</HelperText>
            : null;
    },

    getValidationField(label) {
        return label.replace(/ /g, "_").toLowerCase()
    },

    isRequired(rules) {
        return rules.indexOf('required') !== -1;
    },

    getBlockValidationItems(validator, rules, label, value) {
        let validationField = this.getValidationField(label);
        let validationMessage = validator.message(validationField, value, rules);

        return {
            helperText: this.getHelperMessage(validationMessage, ""),
            validationMessage,
            isRequired: this.isRequired(rules)
        }
    }
};

export default ValidationHelper;