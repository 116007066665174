import { ConversionRate } from "../const/digitalStamps";

const convertStampsToMoney = (stampsAmount, maxAvailableStamps) => {
    if (stampsAmount > maxAvailableStamps || !isWholeNumber(stampsAmount)) return 0;
    return stampsAmount / ConversionRate.Money;
}

const convertStampsToTrees = (stampsAmount) => {
    return stampsAmount / ConversionRate.Trees;
}

const isWholeNumber = (value) => {
    return (value - Math.floor(value)) === 0;
}

export {
    convertStampsToMoney,
    convertStampsToTrees,
    isWholeNumber
}