import React from "react";
import classnames from "classnames";
import { Button } from "@rmwc/button";

const StationFilter = ({ text, icon, isActive, onClick }) => {

    return (
        <Button
            className={classnames("station-filter", {
                "active": isActive
            })}       
            onClick={onClick}
            icon={{
                icon: icon,
                strategy: "className",
                basename: "icon",
            }}>
            {text}
        </Button>
    )
}

export default StationFilter;