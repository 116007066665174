import { FETCH_PAYMENTCARD_INVOICES } from "../../actions/invoiceActions";

export default function reducer(state = {
    data: [],
    fetching: false,
    fetched: false,
    error: null,
    hasError: false,
}, action) {
    switch (action.type) {
        case `${FETCH_PAYMENTCARD_INVOICES}_PENDING`: {
            return { ...state, fetching: true, hasError: false };
        }
        case `${FETCH_PAYMENTCARD_INVOICES}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload, hasError: true };
        }
        case `${FETCH_PAYMENTCARD_INVOICES}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                hasError: false,
                data: action.payload.data.items,
                total: action.payload.data.total
            };
        }
        default:
            return state;
    }
}