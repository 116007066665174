import { useState } from "react";

/**
 * onChange function that is passed to the input
 * @function onChange
 * @param {Event} event the event that is targeted
 */

/**
 * This hook is used with a form input to track the input value.
 * Optionally a callback setState function can be provided that will set the current value
 * with the callback function as well.
 * When the callbackPropertyName is provided, the callback function is used to update the
 * state of an object and the current state value is added to the callbackPropertyName property.
 * @param {string} initialValue initial state value
 * @param {function} [callback] a React setState function
 * @param {string} [callbackPropertyName] the property of the callback state object to be updated
 * @returns {[string, onChange]} An array with the current value and onChange function
 */
const useFormInput = (initialValue, callback, callbackPropertyName) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (event) => {
        let newValue;
        const targetType = event.target.type;
        if (targetType === "checkbox") {
            newValue = event.target.checked;
        } else {
            newValue = event.target.value;
        }

        setValue(newValue);
        if (callback) {
            if (callbackPropertyName) {
                callback(previousState => ({
                    ...previousState,
                    [callbackPropertyName]: newValue || null
                }))
            } else {
                callback(newValue);
            }   
        }
    };

    return [value, onChange];
};

export default useFormInput;