import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from "react-router-dom";
import { GridCell, Grid } from "@rmwc/grid";
import TextFieldGroup from "../common/TextFieldGroup";
import BaseFormComponent from '../common/BaseFormComponent';
import { Button } from "@rmwc/button";
import {
    fetchGroups,
    fetchGroupContent,
    fetchClearCache,
    deleteTranslation,
    fetchUnfinishedLocale,
    fetchSyncDataToTest,
    fetchSearchResults
} from "../../actions/localeActions";
import TranslationItem from "./TranslationItem";
import Loader from "../common/Loader";
import language from '../../lang/lang';
import SelectAutoCompleteField from "../common/SelectAutoCompleteField";

import '../../styles/blocks/_translation.scss';
import { Country } from "../../const/country";

class TranslationSettings extends BaseFormComponent {
    static fieldNewGroup = "newTranslationGroup";
    static fieldSearchTranslation = "searchTranslation";

    constructor(props) {
        super(props);
        this.state = {
            selectedGroup: "",
            groups: [],
            [TranslationSettings.fieldNewGroup]: "",
            [TranslationSettings.fieldSearchTranslation]: "",
            rows: [],
            typingTimeout: 0
        };

        this.onDropDownChange = this.onDropDownChange.bind(this);
        this.createNewGroup = this.createNewGroup.bind(this);
        this.fetchUnfinishedLocales = this.fetchUnfinishedLocales.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.createItem = this.createItem.bind(this);
        this.canCreate = this.canCreate.bind(this);
        this.clearCache = this.clearCache.bind(this);
        this.delete = this.delete.bind(this);
        this.syncToTest = this.syncToTest.bind(this);
        this.onSearchChanged = this.onSearchChanged.bind(this);
    }

    componentDidMount() {
        if (!this.props.localeGroupFetched && !this.props.localeGroupFetching) {
            this.props.fetchGroups(this.props.countryCode);
        } else {
            this.setState({
                groups: this.props.localeGroup
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.localeGroup !== this.props.localeGroup) {
            this.setState({
                groups: this.props.localeGroup
            })
        }

        if (prevProps.localeGroupContent !== this.props.localeGroupContent) {
            this.setState({
                rows: this.props.localeGroupContent
            })
        }

        if (prevProps.localeUnfinishedContent !== this.props.localeUnfinishedContent) {
            this.setState({
                rows: this.props.localeUnfinishedContent,
                selectedGroup: ""
            })
        }

        if (prevProps.localeSearchResults !== this.props.localeSearchResults) {
            this.setState({
                rows: this.props.localeSearchResults,
                selectedGroup: ""
            })
        }
    }

    onDropDownChange(item) {
        const value = item ? item.value || "" : "";
        if (value && this.state.selectedGroup !== value) {
            this.setState({
                selectedGroup: value,
                [TranslationSettings.fieldSearchTranslation]: ""
            });

            this.props.fetchGroupContent(this.props.countryCode, value);
        }
    }

    onSearchChanged(e) {
        const self = this;

        if (this.state.selectedGroup) {
            this.setState({ selectedGroup: "" });
        }

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({
            [TranslationSettings.fieldSearchTranslation]: e.target.value,
            typingTimeout: setTimeout(function () {
                self.props.fetchSearchResults(self.props.countryCode, self.state[TranslationSettings.fieldSearchTranslation])
            }, 1000)
        });
    }

    createNewGroup() {
        let value = this.state[TranslationSettings.fieldNewGroup].replace(/\s/g,'');
        if (this.state.groups.includes(value)) {
            this.setState({
                selectedGroup: value
            })
            this.props.fetchGroupContent(this.props.countryCode, value);
        } else {
            this.setState({
                groups: [...this.state.groups, value],
                selectedGroup: value,
                rows: []
            })
        }
    }

    fetchUnfinishedLocales() {
        const compareLocales = this.props.countryCode === Country.LV ? "lv,en,ru" : "et,en,ru";
        this.props.fetchUnfinishedLocale(this.props.countryCode, compareLocales);
    }

    setCountryCode(countryCode) {
        this.setState({
            countryCode,
            groups: [],
            rows: []
        }, () => {
            this.props.fetchGroups(this.props.countryCode);
            if (this.state[TranslationSettings.fieldSearchTranslation]) {
                this.props.fetchSearchResults(this.props.countryCode, this.state[TranslationSettings.fieldSearchTranslation]);
            } else {
                this.props.fetchGroupContent(this.props.countryCode, this.state.selectedGroup);
            }
        });
    }

    renderHeader() {
        const dropdownItems = this.state.groups
        .map(x => ({
            selected: this.state.selectedGroup === x,
            value: x,
            label: x
        }));

        return (
            <>
                <GridCell span={3}>
                    <SelectAutoCompleteField
                        placeholder={this.props.intl.formatMessage({
                            id: 'General.Select'
                        })}
                        searchPlaceholder={this.props.intl.formatMessage({
                            id: 'AddressForm.Search'
                        })}
                        value={this.state.selectedGroup}
                        onChange={this.onDropDownChange}
                        field="group"
                        options={dropdownItems}
                        label=" "
                    />
                </GridCell>
                <GridCell span={3}>
                    <TextFieldGroup
                        field={TranslationSettings.fieldSearchTranslation}
                        label="TranslationSettings.Search"
                        onChange={this.onSearchChanged}
                        value={this.state[TranslationSettings.fieldSearchTranslation]}
                    />
                </GridCell>
                <GridCell span={3}>
                    <TextFieldGroup
                        field={TranslationSettings.fieldNewGroup}
                        label="TranslationSettings.NewGroup"
                        onChange={this.onChange}
                        value={this.state[TranslationSettings.fieldNewGroup]}
                    />
                </GridCell>
                <GridCell span={1} className="d-flex justify-content-center align-items-center mt-15">
                    <Button
                        disabled={!this.state[TranslationSettings.fieldNewGroup].trim()}
                        onClick={this.createNewGroup}
                        unelevated
                        className="mdc-button--primary"
                    >
                        <FormattedMessage id="TranslationSettings.Create" />
                    </Button>
                </GridCell>
                <GridCell span={2} className="d-flex justify-content-center align-items-center mt-15">
                    <Button
                        onClick={this.fetchUnfinishedLocales}
                        unelevated
                        className="mdc-button--primary"
                    >
                        <FormattedMessage id="TranslationSettings.Unfinished" />
                    </Button>
                </GridCell>
            </>
        );
    }

    deleteItem(item) {
        const key = this.state.rows.findIndex(x => x.keyPart === item.keyPart && x.keyGroup === item.keyGroup && x.countryCode === item.countryCode)
        let rows = [...this.state.rows];
        rows[key].delete = rows[key].delete ? false : true;
        this.setState({
            rows: rows
        });
    }

    delete() {
        const itemsToDelete = this.state.rows.filter(x => x.delete)
        if (itemsToDelete.length > 0) {
            this.props.deleteTranslation(this.props.countryCode, itemsToDelete)
            .then(() => {
                this.setState({
                    rows: this.state.rows.filter(x => !x.delete)
                })
            });
        }
    }

    createItem(item) {
        this.setState({
            rows: [...this.state.rows, item]
        })
    }

    canCreate(key) {
        return key && this.state.rows.findIndex(x => x.keyPart === key) < 0;
    }

    clearCache() {
        this.props.fetchClearCache(this.props.countryCode)
        .then(() => {
            localStorage.removeItem(language.MessagesLastUpdate);
            window.location.reload();
        });
    }

    syncToTest() {
        this.props.fetchSyncDataToTest(this.props.countryCode);
    }

    renderItems() {
        const emptyItem = {
            countryCode: this.props.countryCode,
            keyGroup: this.state.selectedGroup,
            valueEt: "",
            valueEn: "",
            valueRu: ""
        };

        const isLoading = this.props.localeGroupContentFetching ||
            this.props.localeUnfinishedFetching ||
            this.props.deleteLocaleFetching ||
            this.props.localeSearchFetching;

        if (isLoading) {
            return (
                <GridCell span={12}>
                    <Loader type={Loader.TYPE_CENTER} />
                </GridCell>
            );
        }

        return (
            <GridCell span={12} className="translation-items">
                {(this.state.selectedGroup || this.state.rows.length > 0) &&
                    <div className="translation-header-row">
                        <div>
                        {
                            this.props.countryCode === Country.EE
                                ? <FormattedMessage id="TranslationSettings.Est" />
                                : <FormattedMessage id="TranslationSettings.Lat" />
                        }
                        </div>
                        <div>
                            <FormattedMessage id="TranslationSettings.Eng" />
                        </div>
                        <div>
                            <FormattedMessage id="TranslationSettings.Rus" />
                        </div>
                    </div>
                }
                {this.state.rows.map((item) =>
                    <TranslationItem key={item.country + item.keyGroup + item.keyPart} item={item} delete={this.deleteItem} countryCode={this.props.countryCode}/>
                )}
                {this.state.selectedGroup &&
                    <TranslationItem key={'new-key-' + this.props.countryCode} item={emptyItem} create={this.createItem} canCreate={this.canCreate} countryCode={this.props.countryCode}/>
                }
            </GridCell>
        );
    }

    render() {
        return (
            <Grid>
                {this.renderHeader()}
                {this.renderItems()}
                <GridCell span={12}>
                    <Button
                        onClick={this.clearCache}
                        unelevated
                        className="mdc-button--primary mt-35"
                    >
                        <FormattedMessage id="TranslationSettings.ClearCache" />
                    </Button>
                    <Button
                        onClick={this.syncToTest}
                        unelevated
                        className="mdc-button--primary mt-35 ml-15"
                    >
                        <FormattedMessage id="TranslationSettings.SyncToTest" />
                    </Button>
                    {this.state.rows.length > 0 &&
                        <Button
                            className="translation-delete right mt-35"
                            onClick={this.delete}
                            tabIndex="-1"
                            icon={{
                                icon: "icon-alert",
                                strategy: "className",
                                basename: "icon",
                            }}>
                            <FormattedMessage id={"TranslationSettings.Delete"} />
                        </Button>
                    }
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        localeGroup: state.localeGroup.data,
        localeGroupFetching: state.localeGroup.fetching,
        localeGroupFetched: state.localeGroup.fetched,
        localeGroupError: state.localeGroup.error,
        localeGroupContent: state.localeGroupContent.data,
        localeGroupContentFetching: state.localeGroupContent.fetching,
        localeGroupContentError: state.localeGroupContent.error,
        localeClearCacheFetching: state.localeClearCache.fetching,
        deleteLocaleFetching: state.deleteLocale.fetching,
        localeUnfinishedContent: state.localeUnfinished.data,
        localeUnfinishedFetching: state.localeUnfinished.fetching,
        localeSearchResults: state.localeSearchResults.data,
        localeSearchFetching: state.localeSearchResults.fetching
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchGroups,
        fetchGroupContent,
        fetchClearCache,
        deleteTranslation,
        fetchUnfinishedLocale,
        fetchSyncDataToTest,
        fetchSearchResults
    }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(TranslationSettings)));