import React from "react";
import Menu, { MenuList } from "@material/react-menu";
import { ListItem } from "@rmwc/list";
import LinkElement from "../common/LinkElement";
import { injectIntl, FormattedMessage } from "react-intl";
import classnames from "classnames";
import { isLinkActive } from '../../helpers/menuHelper';
import { withRouter } from "react-router-dom";

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchor: undefined
        };
    }

    open = () => {
        this.setState({
            open: !this.state.open,
            anchor: this.refs.listItem
        });
    };

    close = () => {
        this.setState({
            open: false
        });
    };

    renderMenu = items => {
        if (!items || !items.length) {
            return;
        }
        return (
            <Menu
                anchorElement={this.state.anchor}
                open={this.state.open}
                className="mdc-menu--secondary"
            >
                <MenuList>
                    {items.map((item, index) => (
                        <LinkElement key={"menulink" + index} href={item.path}>
                           <ListItem className="mdc-list-item">
                                <FormattedMessage id={item.name} />
                            </ListItem>
                        </LinkElement>
                    ))}
                </MenuList>
            </Menu>
        );
    };

    render() {
        return (
            <>
                <div
                    onMouseEnter={this.open}
                    onMouseLeave={this.close}
                    key={this.props.label}
                    className={classnames("nav__item", {
                        "active": isLinkActive(this.props.location.pathname, this.props.href, this.props.children)
                    })}
                    ref="listItem"
                >
                    <LinkElement
                        onClick={this.props.onClick}
                        href={this.props.href}
                        className={classnames("nav__link", { "selected": this.props.isSelected || false })}
                    >
                        <FormattedMessage id={this.props.label} />
                        {this.renderMenu(this.props.children)}
                    </LinkElement>
                </div>
            </>
        );
    }
}

export default injectIntl(withRouter(MenuItem));
