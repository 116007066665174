import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import qs from "qs";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";

import { getInvoiceDetailsPath } from "../const/routes";
import AlertMessage from "../components/common/AlertMessage";
import Loader from "../components/common/Loader";
import { fetchInvoicePaymentStatus } from "../actions/invoiceActions";
import Statuses from "../helpers/paymentHelper";

class InvoicePaymentResponse extends React.Component {

    componentDidMount() {
        const paymentReference = this.getQueryParameter("payment_reference");
        const invoiceType = this.getQueryParameter("invoiceType");

        this.props.fetchInvoicePaymentStatus(paymentReference, invoiceType);
    }

    getQueryParameter(name) {
        return qs.parse(this.props.location.search, { ignoreQueryPrefix: true })[name];
    }

    isPaymentSuccessful(status) {
        return status === Statuses.Settled;
    }

    isPaymentInprogress(status) {
        return !status ||
            status === Statuses.WaitingForSCA ||
            status === Statuses.Initial ||
            status === Statuses.SentForProcessing;
    }

    isPaymentFailed(status) {
        return !this.isPaymentSuccessful(status) &&
            !this.isPaymentInprogress(status);
    }

    renderSuccessNotice() {
        return (<AlertMessage
            type={AlertMessage.TYPE_DONE}
            isSmall={true}
            title={<FormattedMessage id="InvoicePaymentResponse.Success.Title" />}
            description={<FormattedMessage id="InvoicePaymentResponse.Success.Description" />}
        />);
    }

    renderErrorNotice() {
        return (<AlertMessage
            type={AlertMessage.TYPE_ALERT}
            title={<FormattedMessage id="InvoicePaymentResponse.Failed.Title" />}
            description={<FormattedMessage id="InvoicePaymentResponse.Failed.Description" />}
        />);
    }

    renderInProgressNotice() {
        return (<AlertMessage
            type={AlertMessage.TYPE_DONE}
            isSmall={true}
            title={<FormattedMessage id="InvoicePaymentResponse.InProgress.Title" />}
            description={<FormattedMessage id="InvoicePaymentResponse.InProgress.Description" />}
        />);
    }

    render() {
        const { invoicePaymentStatusFetching, invoicePaymentStatusFetched, invoicePaymentStatus, invoicePaymentStatusError } = this.props;

        console.debug("status", invoicePaymentStatus);
        console.log("error", invoicePaymentStatusError);
        console.debug("success", this.isPaymentSuccessful(invoicePaymentStatus.paymentState));
        console.debug("progress", this.isPaymentInprogress(invoicePaymentStatus.paymentState));
        console.debug("failed", this.isPaymentFailed(invoicePaymentStatus.paymentState));

        const invoiceId = this.getQueryParameter("invoiceId");
        const invoiceType = this.getQueryParameter("invoiceType");

        return (
            <Grid className="mdc-layout-grid--base mt-tablet-20 mt-mobile-24">
                <GridCell span={8} className="mdc-layout-grid__cell--start-3-desktop">
                    {invoicePaymentStatusFetching &&
                        <Loader type={Loader.TYPE_CENTER} />}

                    {!invoicePaymentStatusFetching &&
                        <>
                            {!!invoicePaymentStatusError &&
                                this.renderErrorNotice()}

                            {invoicePaymentStatusFetched &&
                                <>
                                    {(this.isPaymentInprogress(invoicePaymentStatus.paymentState)) &&
                                        this.renderInProgressNotice()}

                                    {this.isPaymentFailed(invoicePaymentStatus.paymentState) &&
                                        this.renderErrorNotice()}

                                    {this.isPaymentSuccessful(invoicePaymentStatus.paymentState) &&
                                        this.renderSuccessNotice()}
                                </>
                            }
                            <Button
                                className="mdc-button--secondary mt-30"
                                outlined
                                tag="a"
                                href={getInvoiceDetailsPath(invoiceId, invoiceType)}
                            >
                                <FormattedMessage id="InvoicePaymentResponse.BackToInvoice" />
                            </Button>
                        </>
                    }
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        invoicePaymentStatusFetching: state.invoicePaymentStatus.fetching,
        invoicePaymentStatusFetched: state.invoicePaymentStatus.fetched,
        invoicePaymentStatus: state.invoicePaymentStatus.data,
        invoicePaymentStatusError: state.invoicePaymentStatus.error,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ fetchInvoicePaymentStatus }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(InvoicePaymentResponse)
);
