import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";

const InvoicePdfButton = ({ pdfUrl, onClick, loading }) => {

    const button = (props) => <Button
        className="mdc-button--link mdc-theme--secondary mdc-button--small"
        icon={loading ?
            {
                strategy: "custom",
                render: () => <Loader type={Loader.TYPE_BUTTON} />
            }
            : {
                icon: "pdf",
                strategy: "className",
                basename: "icon",
                prefix: "icon-",
            }}
        disabled={loading}
        {...props}>
        <FormattedMessage id="Invoice.SavePdf" />
    </Button>

    return (!!pdfUrl && button({ href: pdfUrl, tag: "a" })) ||
        (!!onClick && button({ onClick: onClick }));
}

InvoicePdfButton.propTypes = {
    pdfUrl: PropTypes.string,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
}

export default InvoicePdfButton;