const IsTrueConditionValidatorRule = {
    rule() {
        return {
            isTrue: {
                message: ':message',
                rule: (val, params) => {
                    const condition = params[0]?.condition;
                    console.log(params, condition)
                    return condition;
                },
                messageReplace: (message, params) => message.replace(":message", params[0]?.message),
                required: true
            }
        };
    }
};

export default IsTrueConditionValidatorRule;