import { Grid, GridCell } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Button } from '@rmwc/button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ContractElectricityPath } from '../../const/routes';

export const RedirectToFuelPlusElectricity = "redirectToFuelPlusElectricity";

export const FuelPlusElectricitySign = () => {
    const history = useHistory();
    const redirect = () => {
        localStorage.setItem(RedirectToFuelPlusElectricity, true);
        history.push(ContractElectricityPath);
    }

    return (
        <Grid>
            <GridCell desktop={6} tablet={4}>
                <Typography use="headline3" className="mdc-typography mdc-theme--primary" >
                    <FormattedMessage id="FuelPlusElectricity.Sign.Title"/>
                </Typography>
                <Typography use="body1" className="mdc-typography mt-20">
                    <FormattedHTMLMessage id="FuelPlusElectricity.Sign.Text"/>
                </Typography>
                <Button
                    className="mt-50"
                    unelevated
                    trailingIcon={{
                        icon: "angle-right",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                    }}
                    onClick={redirect}>
                    <FormattedMessage id="FuelPlusElectricity.Sign.Redirect"/>
                </Button>
            </GridCell>
            <GridCell desktop={6} tablet={4}>
                <div className="fuel-plus-electricity-banner fuel-plus-electricity-banner--fuelling"></div>
            </GridCell>
        </Grid>
    )
}