import { FETCH_SERVICE_POINTS } from "../../actions/stationActions";

export default function reducer(
    state = {
        data: [],
        fetching: false,
        fetched: false,
        error: null,
    },
    action
) {
    switch (action.type) {
        case `${FETCH_SERVICE_POINTS}_PENDING`: {
            return { ...state, fetching: true };
        }
        case `${FETCH_SERVICE_POINTS}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${FETCH_SERVICE_POINTS}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload.data,
            };
        }
        default:
            return state;
    }
}
