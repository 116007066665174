import { Grid, GridCell } from "@rmwc/grid";
import UnpaidInvoicesSection from "./UnpaidInvoicesSection";
import InactiveCardsSection from "./InactiveCardsSection";
import DigitalStamps from "../../pages/DigitalStamps";
import OverviewContribution from "../CommunityProject/OverviewContribution";
import {FamilyGroupSection} from "../FamilyGroup";
import OverviewNotices from "../common/OverviewNotices";
import {useFeatures} from "flagged";

const OverviewEe = () => {
    const features = useFeatures();

    return (
        <Grid>
            {(features.FamilyGroupAsHidden || features.FamilyGroupAsPublic) &&
                <GridCell span={12}>
                    <FamilyGroupSection/>
                </GridCell>
            }
            <GridCell span={12}>
                <OverviewNotices />
            </GridCell>
            <GridCell span={12}>
                <DigitalStamps />
            </GridCell>
            <GridCell span={12}>
                <InactiveCardsSection />
            </GridCell>
            <GridCell span={12}>
                <UnpaidInvoicesSection />
            </GridCell>
            <GridCell span={12}>
                <OverviewContribution />
            </GridCell>
        </Grid>
    );
};

export default OverviewEe;
