export const SummerCampaignPrize = {
    Tub: "Tub",
    FreeCoffeeYear: "FreeCoffeeYear",
    FreeCoffee: "FreeCoffee",
    Cup: "Cup",
    Beans: "Beans",
    TryAgain: "TryAgain",
    NoWin: "NoWin",
    Money2: "Money2",
    Money5: "Money5",
    Money10: "Money10",
    Money20: "Money20",
    Money100: "Money100",
}

export const SummerCampaignMoneyMap = {
    [SummerCampaignPrize.Money2]: 2,
    [SummerCampaignPrize.Money5]: 5,
    [SummerCampaignPrize.Money10]: 10,
    [SummerCampaignPrize.Money20]: 20,
    [SummerCampaignPrize.Money100]: 100,
}

export const SummerCampaignTabs = {
    WheelOfFortune: "WheelOfFortune",
    TermsAndConditions: "TermsAndConditions",
    Prizes: "Prizes",
}

export const SummerCampaignTabsMap = {
    [SummerCampaignTabs.WheelOfFortune]: 0,
    [SummerCampaignTabs.TermsAndConditions]: 1,
    [SummerCampaignTabs.Prizes]: 2,
}