import { Dialog, DialogContent, DialogTitle } from "@rmwc/dialog";
import { Button } from "@rmwc/button";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { SummerCampaignMoneyMap, SummerCampaignPrize } from "../../const/summerCampaign";
import { isMoneyPrize, isRedirectablePrize, PrizeImageMap } from "../../helpers/summerCampaignHelper";

import WinBgDark from "../../images/summer_campaign/modal_win_bg_dark.svg";
import WinBgLight from "../../images/summer_campaign/modal_win_bg_light.svg";
import TryAgainBgDark from "../../images/summer_campaign/modal_tryagain_bg_dark.svg";
import TryAgainBgLight from "../../images/summer_campaign/modal_tryagain_bg_light.svg";
import NoWinBgDark from "../../images/summer_campaign/modal_nowin_bg_dark.svg";
import NoWinBgLight from "../../images/summer_campaign/modal_nowin_bg_light.svg";

const backgroundMap = {
    [SummerCampaignPrize.TryAgain]: [`url('${TryAgainBgDark}')`, `url('${TryAgainBgLight}')`],
    [SummerCampaignPrize.NoWin]: [`url('${NoWinBgDark}')`, `url('${NoWinBgLight}')`],
    [SummerCampaignPrize.Beans]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.Cup]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.FreeCoffee]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.FreeCoffeeYear]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.Money2]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.Money5]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.Money10]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.Money20]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
    [SummerCampaignPrize.Money100]: [`url('${WinBgDark}')`, `url('${WinBgLight}')`],
};

const PrizeModal = ({ isOpen, onClose, onCloseWithRedirect, prize = SummerCampaignPrize.NoWin }) => {
    const isPrizeMoney = useMemo(() => isMoneyPrize(prize), [prize]);

    return (
        <Dialog
            open={isOpen}
            className="summer-campaign summer-campaign__prize-modal"
            style={{
                "--bg-image-dark": backgroundMap[prize][0],
                "--bg-image-light": backgroundMap[prize][1],
            }}
        >
            <DialogTitle>
                <Button onClick={onClose}>
                    <i className="icon icon-close" />
                </Button>
            </DialogTitle>
            <DialogContent>
                {prize !== SummerCampaignPrize.NoWin &&
                    <h2>
                        <FormattedMessage id="SummerCampaign.Modal.Congratulations" />
                    </h2>
                }
                {prize === SummerCampaignPrize.NoWin &&
                    <h2 className="no-win">
                        <FormattedMessage id="SummerCampaign.Modal.NoWin" />
                    </h2>
                }
                {isPrizeMoney &&
                    <div className="prize-name">
                        <span className="money-amount">{SummerCampaignMoneyMap[prize]}</span>
                        <span className="money-euro">€</span>
                    </div>
                }
                {!isPrizeMoney && prize !== SummerCampaignPrize.NoWin &&
                    <img className="prize-image" src={PrizeImageMap[prize]} alt="prize" />
                }
                <p className="prize-description">
                    <FormattedMessage id={`SummerCampaign.Modal.Win.${isPrizeMoney ? "Money" : prize}`} values={{
                        amount: SummerCampaignMoneyMap[prize]
                    }} />
                </p>
                <div className="dialog-button">
                    <Button onClick={onCloseWithRedirect}>
                        {!isRedirectablePrize(prize)
                            ? <FormattedMessage id="SummerCampaign.Modal.OkBtn" />
                            : <FormattedMessage id="SummerCampaign.Modal.MyPrizesBtn" />
                        }
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default PrizeModal;