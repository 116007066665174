import { useCallback } from "react";
import Lang from "../../lang/lang";

const eParakstsStateStorageKey = "eParakstsSingingState";
const State = {
    Prepare: "prepare",
    Finalize: "finalize"
}
const SupportedUiLocales = ["lv", "en", "ru"];

const AcrValues = "urn:eparaksts:authentication:flow:mobileid";
const AuthScope = "urn:safelayer:eidas:sign:identity:profile";
const SignScope = "urn:safelayer:eidas:sign:identity:use:server";
const RequestUrl = process.env.REACT_APP_EPARAKSTS_URL + "trustedx-authserver/oauth/lvrtc-eipsign-as?";
const RedirectUri = window.location.origin + "/eparaksts/callback";

const useEParakstsMobile = () => {
    const getAuthorizationCodeUrl = (data, signingMethod) => {
        const stateId = crypto.randomUUID();

        localStorage.setItem(eParakstsStateStorageKey, JSON.stringify({
            stateId,
            contractData: data.contractData,
            fileName: data.fileName,
            type: State.Prepare,
            signingMethod
        }));

        const language = Lang.getLang();

        const queryParams = new URLSearchParams({
            response_type: "code",
            client_id: process.env.REACT_APP_EPARAKSTS_CLIENT_ID,
            state: stateId,
            redirect_uri: RedirectUri,
            scope: AuthScope,
            acr_values: AcrValues,
            ui_locales: SupportedUiLocales[language] ?? Lang.Lv
        });

        const url = RequestUrl + queryParams.toString();

        return url;
    }

    const getCurrentState = useCallback(() => JSON.parse(localStorage.getItem(eParakstsStateStorageKey)), []);

    const getPinCodeAuthorizationCodeUrl = useCallback((data) => {
        const stateId = crypto.randomUUID();
        const currentState = getCurrentState();

        localStorage.setItem(eParakstsStateStorageKey, JSON.stringify({
            ...currentState,
            stateId,
            digest: data.digest,
            digestAlgorithm: data.digestAlgorithm,
            signIdentityId: data.signIdentityId,
            type: State.Finalize,
            sessionId: data.sessionId,
            authenticationCertificate: data.authenticationCertificate
        }));
        const language = Lang.getLang();

        const queryParams = new URLSearchParams({
            response_type: "code",
            client_id: process.env.REACT_APP_EPARAKSTS_CLIENT_ID,
            state: stateId,
            redirect_uri: RedirectUri,
            scope: SignScope,
            acr_values: AcrValues,
            ui_locales: SupportedUiLocales[language] ?? Lang.Lv,
            sign_identity_id: data.signIdentityId,
            digests_summary: data.digestSummary,
            digests_summary_algorithm: data.digestSummaryAlgorithm
        });

        const url = RequestUrl + queryParams.toString();

        return url;
    }, [getCurrentState]);

    const prepareSigning = (data, signingMethod) => {
        const redirectUrl = getAuthorizationCodeUrl(data, signingMethod);
        window.location.href = redirectUrl;
    }

    const finalizeSigning = useCallback((data) => {
        const redirectUrl = getPinCodeAuthorizationCodeUrl(data);
        window.location.href = redirectUrl;
    }, [getPinCodeAuthorizationCodeUrl]);

    const isPrepareState = useCallback((state) => {
        const storageState = JSON.parse(localStorage.getItem(eParakstsStateStorageKey));

        return storageState.type === State.Prepare && state === storageState.stateId;
    }, []);

    const isFinalizeState = useCallback((state) => {
        const storageState = JSON.parse(localStorage.getItem(eParakstsStateStorageKey));

        return storageState.type === State.Finalize && state === storageState.stateId;
    }, []);

    return { prepareSigning, finalizeSigning, isPrepareState, isFinalizeState, getCurrentState };
}

export default useEParakstsMobile;