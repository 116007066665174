import React from "react";
import AlertMessage from "../common/AlertMessage";
import Checkbox from '@material/react-checkbox';
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { GridCell } from '@rmwc/grid';

class PlasticCardSelection extends React.Component {
    render() {
        return (
            <>
                <GridCell span={12}>
                    <AlertMessage
                        title={<FormattedMessage id="Cards.PlasticCard.Notice.Title" />}
                    />
                </GridCell>
                <GridCell span={12}>
                    <div className="mdc-form-field field">
                        <Checkbox
                            nativeControlId='card-wish'
                            name={this.props.checkboxName}
                            checked={this.props.plasticCard}
                            indeterminate={false}
                            onChange={this.props.onChangeCheckbox}
                        />
                        <label htmlFor='card-wish'>
                            <FormattedMessage id="Cards.PlasticCard.WantPlastic.Checkbox" />
                        </label>
                    </div>
                    <div
                        className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent information__description lighter medium">
                        <FormattedMessage id="Cards.PlasticCard.WantPlastic.Details" />
                    </div>
                </GridCell>
            </>
        );
    }
}

PlasticCardSelection.propTypes = {
    plasticCard: PropTypes.string,
    onChangeCheckbox: PropTypes.func,
    checkboxName: PropTypes.string
};

export default PlasticCardSelection;