import {Grid, GridCell} from "@rmwc/grid";
import AlertMessage from "./AlertMessage";
import {FormattedMessage} from "react-intl";
import React from "react";

const DebtNotice = () => {
    return (
        <Grid>
            <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_NOTICE}
                    title={<FormattedMessage id="Service.Alert.HasDebt" />}
                />
            </GridCell>
        </Grid>
    )
}

export default DebtNotice;