import { GET_ACTIVE_OFFERS } from "../../actions/offerActions";

export default function reducer(state={
    data: [],
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${GET_ACTIVE_OFFERS}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${GET_ACTIVE_OFFERS}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${GET_ACTIVE_OFFERS}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, error: null, data: action.payload.data};
        }
        default:
            return state;
    }
}