import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import SimpleReactValidator from "simple-react-validator";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { GridCell, GridInner } from "@rmwc/grid";
import { Icon } from '@rmwc/icon';
import { Button } from "@rmwc/button";
import Checkbox from '@material/react-checkbox';
import AlertMessage from "../common/AlertMessage";
import Loader from "../common/Loader";
import { useFormInput } from "../../helpers/hooks";
import { validateCompanyCreditScore, validatePersonCreditScore } from "../../actions/cardActions";
import { CompletedFormStatus, CompletedMessages } from "../../const/cardApplication";
import { StartPath } from "../../const/routes";
import { isNullOrEmpty } from "../../helpers/objectHelper";

const ContractFuelCreditScore = ({
    account,
    isLoading = false,
    isCompany = false,
    requestedLimit,
    validator,
    setCreditScoreError,
    onRejectedCreditScore,
    onAcceptedCreditScore,
    onCompanyCreditScore,
    personCreditScoreFetching,
    companyCreditScoreFetching,
    scrollToElement,
    onBeforeClick
}) => {
    const dispatch = useDispatch();
    const [isCreditInfoAgreementChecked, onIsCreditInfoAgreementCheckedChanged] = useFormInput(false);
    const [alertMessage, setAlertMessage] = useState(CompletedMessages[CompletedFormStatus.TECHNICAL_ERROR]);
    const [isCreditScoreValidated, setIsCreditScoreValidated] = useState(false);

    const validateCreditScore = (limitData) => async dispatch => {
        if (!isNullOrEmpty(limitData)) {
            try {
                let messageType;
                if (isCompany && account?.companyCode) {
                    const { value } = await dispatch(validateCompanyCreditScore(account.companyCode));
                    messageType = await dispatch(onCompanyCreditScore?.(value.data));
                } else {
                    const { value } = await dispatch(validatePersonCreditScore(limitData));

                    if (!value.data.isCreditScoreAccepted) {
                        messageType = await dispatch(onRejectedCreditScore?.(value.data));
                    } else {
                        messageType = await onAcceptedCreditScore?.(value.data);
                    }
                }

                changeAlertMessage(messageType);
            } catch (error) {
                setCreditScoreError?.(true);
            }
        }

        setIsCreditScoreValidated(true);
    };

    const changeAlertMessage = (messageType) => {
        if (messageType) {
            setAlertMessage(CompletedMessages[messageType]);
        }
    }

    const onEvaluateCreditworthinessClick = () => {
        try {
            onBeforeClick?.();

            if (!validator.allValid()) {
                validator.showMessages();
                return;
            }

            dispatch(validateCreditScore(requestedLimit));
            scrollToElement?.();
        } catch {}
    }

    return (
        <>
            {personCreditScoreFetching || companyCreditScoreFetching || isLoading ?
                <GridCell span={12}>
                    <Loader type={Loader.TYPE_CENTER} />
                </GridCell> :
                <>
                    {isCreditScoreValidated &&
                        <>
                            <GridCell span={12}>
                                <AlertMessage
                                    type={alertMessage.type}
                                    title={<FormattedMessage id={alertMessage.title} />}
                                    description={alertMessage.description
                                        ? <FormattedMessage id={alertMessage.description} />
                                        : ""}
                                />
                            </GridCell>
                            {alertMessage.type === AlertMessage.TYPE_ALERT &&
                                <GridCell span={12}>
                                    <Button className="mdc-button--link mdc-theme--secondary left"
                                        icon={<Icon className="mdc-button__icon icon icon-arrow-left left" />}
                                        tag={Link} to={StartPath} label={<FormattedMessage id="Contract.Footer.BackToMain.Button" />}
                                    />
                                </GridCell>
                            }
                        </>
                    }
                    {!isCreditScoreValidated &&
                        <GridCell span={12} className="field--colored">
                            <GridInner className="pt-30 pb-30">
                                <GridCell span={6} phone={4} align="middle">
                                    <div className="mdc-form-field">
                                        <Checkbox
                                            nativeControlId="creditinfo-agreement"
                                            name="cbCreditInfoAgreement"
                                            checked={false}
                                            indeterminate={false}
                                            onChange={onIsCreditInfoAgreementCheckedChanged}
                                            className="mb-0"
                                        />
                                        <label htmlFor="creditinfo-agreement">
                                            <FormattedMessage id="ContractsPage.CreditInfo.Agreement" />
                                        </label>
                                    </div>
                                </GridCell>
                                <GridCell span={3} phone={0}></GridCell>
                                <GridCell span={3} phone={4} align="middle" className="text-left">
                                    <Button unelevated
                                        onClick={onEvaluateCreditworthinessClick}
                                        disabled={!isCreditInfoAgreementChecked}
                                    >
                                        <FormattedMessage id="ContractsPage.CreditInfo.Evaluate" />
                                    </Button>
                                </GridCell>
                            </GridInner>
                        </GridCell>
                    }
                </>
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        account: state.account.data,
        personCreditScoreFetching: state.validatePersonCreditScore.fetching,
        companyCreditScoreFetching: state.validateCompanyCreditScore.fetching
    };
};

ContractFuelCreditScore.propTypes = {
    requestedLimit: PropTypes.number,
    validator: PropTypes.instanceOf(SimpleReactValidator).isRequired,
    setCreditScoreError: PropTypes.func,
    onRejectedCreditScore: PropTypes.func,
    onAcceptedCreditScore: PropTypes.func,
    onCompanyCreditScore: PropTypes.func,
    isLoading: PropTypes.bool,
    isCompany: PropTypes.bool,
    onBeforeClick: PropTypes.func,
    scrollToElement: PropTypes.func
};

export default injectIntl(connect(mapStateToProps)(ContractFuelCreditScore));