import { Button } from "@rmwc/button"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom";

const BackButton = ({ toPath, label }) => {
    return (
        <Button
            className="mdc-button--text mdc-button--secondary"
            icon={{
                icon: "arrow-left",
                strategy: "className",
                basename: "icon",
                prefix: "icon-",
            }}
            tag={Link}
            to={toPath}
        >
            <FormattedMessage id={label || "General.Back"} />
        </Button>
    );
}

export default BackButton;