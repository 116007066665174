import { isWebview } from "./appContainerHelper";
import { Country } from "../const/country";

export function IsLatvianWeb() {
    return window.location.hostname.includes("alexela.lv");
}

export function GetCurrentCountryCode() {
    return IsLatvianWeb() ? Country.LV : Country.EE;
}

const isTrue = (value) => String(value).toLowerCase() === "true";
const getAppMajorVersion = (value) => !!value && value.length > 0 && value !== "null" && +value.split(".")[0];

export function UsingApp() {
    const usingAppFromLocalStorage = localStorage.getItem("usingapp");
    const usingAppFromWindow = !!window.UsingApp;

    return isTrue(usingAppFromLocalStorage) ||
        isTrue(usingAppFromWindow) ||
        isWebview();
}

export function AppIsCorrectVersion() {
    return true;
}

export function GetAppVersion() {
    // App version 1 means old app container
    const appVersion = (localStorage.getItem("appVersion") || window.AppVersion) ?? "1";
    return appVersion;
}

export function IsCombinedAppContainer(appVersion = undefined, usingApp = undefined) {
    if (appVersion === undefined) { appVersion = GetAppVersion(); }
    if (usingApp === undefined) { usingApp = UsingApp(); }

    const majorVersion = getAppMajorVersion(appVersion);
    const isNewAppVersion = !isNaN(majorVersion) && !!majorVersion && majorVersion > 1;

    // AppVer2 was variable that was set by old app container. If this is set, user s most likely using old container
    const isOldAppVersion = typeof AppVer2 !== "undefined" || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.AppVer2)

    return usingApp && (isNewAppVersion || !isOldAppVersion);
}

export function IsNewMobileAppWebView() {
    if (!UsingApp()) { return false; }

    const appVersion = GetAppVersion();
    const majorVersion = getAppMajorVersion(appVersion);

    return !isNaN(majorVersion) && !!majorVersion && majorVersion >= 4;
}