import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { FormattedMessage, useIntl } from "react-intl";
import CheckboxField from "../../components/common/CheckboxField";
import TableList from "../../components/common/TableList";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import AppCampaignDialog from "./AppCampaignDialog";
import { AppCampaignIllustrationsMap } from "../../helpers/appCampaignHelper";
import { getAppCampaigns } from "../../actions/campaignActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import AlertMessage from "../../components/common/AlertMessage";

import "../../styles/components/_app-campaigns.scss";

const AppCampaigns = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState();
    const [showInactive, setShowInactive] = useState(false);

    const campaigns = useSelector(state => state.appCampaigns);
    const upsertCampaign = useSelector(state => state.upsertAppCampaign);

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!campaigns.fetched && !campaigns.fetching && !campaigns.error) {
            dispatch(getAppCampaigns());
        }
    }, [dispatch, campaigns]);

    const tableHeaders = useMemo(() => ([
        {
            label: intl.formatMessage({ id: 'Campaign.App.Table.Icon' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Campaign.App.Table.TextKey' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Campaign.App.Table.CtaKey' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Campaign.App.Table.CtaLinkKey' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: intl.formatMessage({ id: 'Campaign.App.Table.ValidPeriod' }),
            colspan: 1,
            className: "text-center",
        },
        {
            label: null,
            colspan: 1,
        }
    ]), [intl]);

    const getTableRows = () => {
        return campaigns.data.map((data) => ({
            content: [
                {
                    colspan: 1,
                    content: <img width={50} height={50} src={AppCampaignIllustrationsMap[data.illustration]} alt={data.illustration} />,
                    className: "illustration",
                },
                {
                    colspan: 1,
                    content: data.textValue,
                    className: "text-center",
                },
                {
                    colspan: 1,
                    content: data.ctaValue,
                    className: "text-center",
                },
                {
                    colspan: 1,
                    content: data.ctaLinkValue,
                    className: "text-center",
                },
                {
                    colspan: 1,
                    content: `${moment(data.validFrom).format("DD.MM.YYYY")} - ${data.validTo ? moment(data.validTo).format("DD.MM.YYYY") : "..." }`,
                    className: "text-center",
                },
            ]
        }))
    }

    const onEditRow = (index) => {
        setItemToEdit(campaigns.data[index]);
        setIsDialogOpen(true);
    }

    const onAddNew = () => {
        setItemToEdit(null);
        setIsDialogOpen(true);
    }

    const onCloseDialog = () => {
        setItemToEdit(null);
        setIsDialogOpen(false);
    }

    const onCampaignSubmitted = (isSuccess) => {
        if (isSuccess) {
            dispatch(getAppCampaigns(showInactive));
        }

        setItemToEdit(null);
    }

    const onToggleInactive = () => {
        const newValue = !showInactive;
        dispatch(getAppCampaigns(newValue));
        setShowInactive(newValue);
    }

    if (campaigns.fetching) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    return (
        <>
            <Grid>
                {upsertCampaign.error &&
                    <GridCell span={12}>
                        <AlertMessage
                            isSmall={true}
                            type={AlertMessage.TYPE_ALERT}
                            title={upsertCampaign.error ?? intl.formatMessage({ id: "Campaign.App.Error" })}
                        />
                    </GridCell>
                }
                <GridCell span={6}>
                    <CheckboxField
                        nativeControlId="inactive-items"
                        field="inactive-items"
                        checked={showInactive}
                        indeterminate={false}
                        onChange={onToggleInactive}
                    />
                    <label htmlFor="inactive-items">
                        <FormattedMessage id="Campaign.App.ShowInactiveCbx" />
                    </label>
                </GridCell>
                <GridCell span={6}>
                    <Button outlined className="right" onClick={onAddNew}>
                        <i className="icon-plus" />
                        <FormattedMessage id="Campaign.App.AddNewBtn" />
                    </Button>
                </GridCell>
                <GridCell span={12}>
                    <GridInner>
                        <GridCell span={12}>
                            <TableList
                                className="app-campaigns"
                                headers={tableHeaders}
                                itemContent={getTableRows()}
                                changeRow={intl.formatMessage({ id: 'Campaign.App.Table.EditBtn' })}
                                changeRowCallback={onEditRow}
                            />
                        </GridCell>
                    </GridInner>
                </GridCell>
            </Grid>

            <AppCampaignDialog
                isOpen={isDialogOpen}
                onClose={onCloseDialog}
                item={itemToEdit}
                onSubmitted={onCampaignSubmitted}
            />
        </>
    );
}

export default AppCampaigns;