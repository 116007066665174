import React from "react";
import { Typography } from "@rmwc/typography";
import LinkElement from "./LinkElement";
import Loader from "./Loader";

const TitleWithLoader = ({ title, isLoading, href, linkText }) =>
    <div className="d-flex justify-content-between align-items-center align-items-center--desktop">
        <span className="d-flex align-items-center">
            <Typography use="headline3" className="mdc-typography--highlighted mdc-typography--bold" style={{ textTransform: "none" }}>
                {title}
            </Typography>
            {isLoading &&
                <Loader type={Loader.TYPE_INLINE} size="small" />}
        </span>
        {!!href &&
            <LinkElement href={href} className="mdc-button mdc-button--outlined mt-mobile-16">
                {linkText}
            </LinkElement>}
    </div>;

export default TitleWithLoader;