import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";
import { directFileDownloadAction } from '../helpers/fileDownloadHelpers';

export const GET_ACTIVE_OFFERS = "GET_ACTIVE_OFFERS";
export const REJECT_OFFER = "REJECT_OFFER";
export const RESET_REJECT_OFFER = "RESET_REJECT_OFFER";

export const fetchActiveOffers = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: GET_ACTIVE_OFFERS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/offer/list`)
    };
}

export const rejectOffer = (offerId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: REJECT_OFFER,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/offer/reject`, { offerId })
    };
}

export const resetRejectOffer = () => {
    return {
        type: RESET_REJECT_OFFER
    };
}

export const downloadCylinderGasOfferPreviewPdf = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    const endpoint = `/api/${accountId}/${roleId}/offer/pdf-preview/`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            directFileDownloadAction(endpoint, "cylinder_gas_contract_preview.pdf");
        } else {
            dispatch({
                type: "DOWNLOAD_PDF_FILE",
                payload: BusinessApi.get(endpoint, {
                    params: {
                        model: JSON.stringify(data)
                    },
                    responseType: "arraybuffer"
                })
            });
        }
    }
}