import { useParams } from "react-router-dom";
import ProductChange from "../components/Contract/ProductChange";

const ContractRenewal = () => {
    const {id} = useParams();

    return (
        <ProductChange isRenewal={true} contractId={id}/>
    );
}

export default ContractRenewal;
