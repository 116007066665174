import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@rmwc/typography";
import { SkIdMessageType, SkType } from "../../const/skIdStatusType";
import { AuthenticationType } from "../../const/authenticationType";
import "../../styles/components/_smart-id.scss";

const DEFAULT_TIMEOUT_SECONDS = 60;

const SkIdLoader = (props) => {
    const { challengeCode, timeout, type } = props;
    const [timeoutSeconds, setTimeoutSeconds] = useState(timeout ?? DEFAULT_TIMEOUT_SECONDS);
    const [messageType, setMessageType] = useState(SkIdMessageType.Pending);

    useEffect(() => {
        const timer = timeoutSeconds > 0 && setInterval(() => {
            setTimeoutSeconds(timeoutSeconds - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeoutSeconds]);

    useEffect(() => {
        if (challengeCode) {
            setMessageType(SkIdMessageType.Pin);
        }
    }, [challengeCode])

    return (
        <div className="p-10 field--colored">
            <div className="d-flex align-items-center">
            <Typography use="subtitle1" className="bold">
                <FormattedMessage id={messageType === SkIdMessageType.Pending
                    ? "SmartId.Info.CheckingAccount"
                    : "SmartId.Info.ControlCode"}
                    values={{
                        type: type === AuthenticationType.MobileId ? SkType.MobileId : SkType.SmartId
                    }}
                />
            </Typography>
            {challengeCode &&
                    <Typography use="subtitle1" className="bold smart-id--control-code">
                        <span className="">{challengeCode}</span>
                    </Typography>
            }
            </div>
            <Typography use="body1" className="pt-10">
                <FormattedMessage id="SmartId.Info.Waiting" />
                {timeoutSeconds && <span>&nbsp;{timeoutSeconds}</span>}
            </Typography>
        </div>
    );
}

export default SkIdLoader;