import React from "react";
import PropTypes from 'prop-types';
import { injectIntl } from "react-intl";
import classnames from "classnames";

import "../../styles/components/_tooltip.scss";

class InfoTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.toggle = this.toggle.bind(this);
    }

    render() {
        
        let primaryCardTranslation = this.props.intl.formatMessage({id: this.props.content});
        return (
            <div className={classnames(
                    "tooltip-info icon icon-info-fill",
                    this.props.className
                )} 
                onClick={this.toggle}
                title={this.state.show ? "" : primaryCardTranslation}>
                {this.state.show &&
                    <div className="tooltip-info__wrapper">
                        
                        <p className="tooltip-text__content">{primaryCardTranslation}</p>
                    </div>
                }
            </div>
        );
    }

    toggle() {
        this.setState({show: !this.state.show});
    }
}


InfoTooltip.propTypes = {
    content: PropTypes.string,
    className: PropTypes.string
};

export default injectIntl(InfoTooltip);
