import { FETCH_NORDPOOL_PRICES } from "../../actions/stockPriceActions";

export default function reducer(state = {
    data: {},
    params: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_NORDPOOL_PRICES}_PENDING`: {
            return { ...state, fetching: true };
        }
        case `${FETCH_NORDPOOL_PRICES}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${FETCH_NORDPOOL_PRICES}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, data: action.payload.data, params: action.payload.params };
        }
        default:
            return state;
    }
}