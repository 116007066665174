import { Grid, GridCell } from "@rmwc/grid";
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { Redirect, useLocation, useParams } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import { ContractDigitalSign, ContractTermsCheckbox } from "../components/Contract";
import {
    downloadElmoContractPdf,
    prepareElmoContractOfferSinging,
    finalizeElmoContractOfferSinging,
    fetchElmoContractDetails
} from "../actions/contractActions";
import { verifyUserSigningRight } from "../actions/accountActions";
import AlertMessage from "../components/common/AlertMessage";
import { ContractsPath, StartPath } from "../const/routes";
import auth from "../auth/authenticate";
import { useFormInput, useTimedRedirect } from "../helpers/hooks";
import Loader from "../components/common/Loader";
import ContractInfoRow from "../components/Contract/ContractInfoRow";
import BackButton from "../components/common/BackButton";

const allowedPathRegex = /\/service\/contract\/(?:electricity|gas)\/offer\/\d+\/\d+$/;

const ElmoContractOffer = () => {
    const [isSigned, setIsSigned] = useState(false);
    const [isError, setIsError] = useState(false);
    const [contractType, setContractType] = useState();
    const [isTermsAccepted, onSetIsTermsAcceptedChanged] = useFormInput(false);

    const contract = useSelector(state => state.elmoContractDetails);
    const finalize = useSelector(state => state.finalizeElmoContractOfferSinging);
    const signingRight = useSelector(state => state.verifyUserSigningRight);
    const setRole = useSelector(state => state.setRole);

    const { id } = useParams();
    const validator = new SimpleReactValidator();
    const intl = useIntl();
    const dispatch = useDispatch();
    const redirectToPage = useTimedRedirect();
    const isCompany = auth.isCompany();
    const { pathname } = useLocation();

    useEffect(() => {
        if (isSigned || isError) {
            const redirectTo = isSigned ? ContractsPath : StartPath;
            const state = isSigned ? { disableCache: true } : null;
            redirectToPage(redirectTo, state, 10000);
        }
    }, [isSigned, isError, redirectToPage]);

    useEffect(() => {
        const pathComponents = pathname.split('/');

        if (pathComponents.length >= 5) {
            const offerPathIndex = pathComponents.indexOf("offer");

            if (offerPathIndex === -1) return;

            const id = pathComponents[offerPathIndex + 1];
            dispatch(fetchElmoContractDetails(id));
        }
    }, [dispatch, pathname]);

    useEffect(() => {
        if (!contract.fetching && contract.fetched) {
            setContractType(contract.data.type);
        }
    }, [contract]);

    useEffect(() => {
        if (!signingRight.fetching && !signingRight.fetched && !signingRight.error) {
            dispatch(verifyUserSigningRight());
        }
    }, [dispatch, signingRight]);

    useEffect(() => {
        if (!finalize.fetching && finalize.fetched && !finalize.error) {
            setIsSigned(true);
        }
    }, [finalize]);

    const onPrepareSigning = () => async dispatch => {
        try {
            const { value } = await dispatch(prepareElmoContractOfferSinging({
                contractId: parseInt(id),
                contractNumber: contract.data.number,
                contractType: contractType,
                signingDeadline: contract.data.signingDeadline
            }));
            return value.data;
        } catch {
            setIsError(true);
            return null;
        }
    }

    const onDocumentSigned = (data) => async (dispatch) => {
        await dispatch(finalizeElmoContractOfferSinging({
            contractId: parseInt(id),
            contractNumber: contract.data.number,
            contractType: contractType,
            signingMethod: data.signingMethod
        }));
    }

    const onDownloadPreviewPdf = () => {
        dispatch(downloadElmoContractPdf(id));
    }

    const isOverSigningDeadline = () => {
        if (contract.data?.signingDeadline) {
            return moment().isAfter(contract.data.signingDeadline, 'day');
        }

        return false;
    }

    const hasNoBusinessBeingHere = () => {
        return setRole.error;
    }

    const hasNoSigningRight = () => {
        return signingRight.error;
    }

    const isLoading = () => {
        return contract.fetching || signingRight.fetching || setRole.fetching;
    }

    const isAlreadySigned = () => {
        return contract.data?.signedDate != null;
    }

    const renderSigningComponent = () => {
        if (isLoading()) {
            return <Loader type={Loader.TYPE_CENTER} />;
        }

        if (hasNoBusinessBeingHere()) {
            return <Redirect to={StartPath} />
        }

        if (contract.error) {
            return <AlertMessage
                type={AlertMessage.TYPE_ALERT}
                title={intl.formatMessage({ id: "Contract.ElectricityOffer.Alert.Error" })}
            />
        }

        if (isOverSigningDeadline()) {
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_WARN}
                    title={intl.formatMessage({ id: "Contract.ElectricityOffer.Alert.OverDeadline" })}
                />
            );
        }

        if (hasNoSigningRight() && !auth.isAdmin()) {
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_WARN}
                    title={intl.formatMessage({ id: "Contract.ElectricityOffer.Alert.NoSigningRight" })}
                />
            );
        }

        if (isAlreadySigned()) {
            return (
                <AlertMessage
                    type={AlertMessage.TYPE_WARN}
                    title={intl.formatMessage({ id: "Contract.ElectricityOffer.Alert.AlreadySigned" })}
                />
            );
        }

        return (
            <ContractDigitalSign
                validator={validator}
                getFileToSign={onPrepareSigning}
                onDocumentSigned={onDocumentSigned}
                onDownloadPreview={onDownloadPreviewPdf}
                disabled={contract.fetching || !isTermsAccepted}
            >
                <ContractTermsCheckbox
                    checked={isTermsAccepted}
                    onSetIsTermsAcceptedChanged={onSetIsTermsAcceptedChanged}
                    titleKey="Contract.ElectricityOffer.AgreeTerms"
                    termsValues={[
                        {
                            termsKey: "TOSLink",
                            termsLinkKey: "Contract.ElectricityOffer.TOSLink.Terms",
                            termsLinkTextKey: "Contract.ElectricityOffer.TOSLink.TermsText"
                        },
                        {
                            termsKey: "priceLink",
                            termsLinkKey: "Contract.ElectricityOffer.TOSLink.PriceList",
                            termsLinkTextKey: "Contract.ElectricityOffer.TOSLink.Text",
                        }
                    ]}
                />
            </ContractDigitalSign>
        );
    }

    const renderContractInfo = () => {
        if (isLoading()) {
            return null;
        }

        if (!contract.fetching && contract.fetched) {
            return contract.data?.consumptionLocations?.map((location, index) => {
                return (
                    <div className="p-10" key={index}>
                        <ContractInfoRow contract={contract.data} location={location} />
                    </div>
                )
            });
        }

        return null;
    }

    if (!isCompany || !allowedPathRegex.test(pathname)) {
        return <Redirect to={StartPath} />;
    }

    return (
        <Grid>
            <GridCell>
                <BackButton toPath={ContractsPath} label="ContractDetails.Back" />
            </GridCell>
            {contract.data?.hasDebt ?
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={intl.formatMessage({id: "Contract.ElectricityOffer.Alert.HasDebt"})}/>
                </GridCell>
                : <>
                    <GridCell span={12}>
                        {renderContractInfo()}
                    </GridCell>
                    <GridCell span={12}>
                        {!isSigned && !isError && renderSigningComponent()}
                        {(isError || isSigned) &&
                            <AlertMessage
                                type={isSigned ? AlertMessage.TYPE_DONE : AlertMessage.TYPE_ALERT}
                                title={intl.formatMessage({
                                    id: isSigned
                                        ? "Contract.ElectricityOffer.Alert.Success"
                                        : "Contract.ElectricityOffer.Alert.Error"
                                })}
                                description={intl.formatMessage({id: "Contract.ElectricityOffer.Alert.Redirect"})}
                            />
                        }
                    </GridCell>
                </>
            }
        </Grid>
    );
}

export default ElmoContractOffer;