import { saveAs } from "file-saver";
import { getFileNameFromResponseHeaders } from "../../helpers/fileDownloadHelpers";
import { FETCH_CARD_RECEIPTS_PDF } from '../../actions/transactionActions';

export default function reducer(state = {
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_CARD_RECEIPTS_PDF}_PENDING`: {
            return { ...state, fetching: true };
        }
        case `${FETCH_CARD_RECEIPTS_PDF}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${FETCH_CARD_RECEIPTS_PDF}_FULFILLED`: {
            const data = action.payload.data;
            const fileName = getFileNameFromResponseHeaders(action.payload.headers) || "receipt.pdf";

            saveAs(new Blob([data], { type: "application/pdf" }), fileName);

            return {
                ...state,
                fetching: false,
                fetched: true
            };
        }
        default:
            return state;
    }
}
