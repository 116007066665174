import React from 'react';
import PropTypes from 'prop-types';
import Radio, {NativeRadioControl} from '@material/react-radio';
import {FormattedMessage} from 'react-intl';

const RadioField = ({ index, fieldName, fieldValue, fieldLabel, selectedValue, onChange, wrapperClasses, formattedMessageId, disabled }) => {
    const messageFromId = formattedMessageId ? <FormattedMessage id={formattedMessageId} /> : null;
    const thisLabel = fieldLabel ? fieldLabel : messageFromId;
    return (
        <Radio label={thisLabel} key={index} wrapperClasses={wrapperClasses}>
            <NativeRadioControl
                name={fieldName}
                value={fieldValue + ''}
                id={fieldName + index}
                onChange={onChange}
                checked={selectedValue + '' === fieldValue + ''}
                disabled={disabled}
            />
        </Radio>);
};

RadioField.propTypes = {
    index: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    fieldLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array
    ]),
    wrapperClasses: PropTypes.string,
    formattedMessageId: PropTypes.string
};

RadioField.defaultProps = {
    index: 1
};

export default RadioField;