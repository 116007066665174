import React from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { PaymentInterval } from "../../const/evChargingItems";

import "../../styles/blocks/_ev-contract.scss";

const ContractAdditionalService = ({ service }) => {
    const intl = useIntl();

    const getServiceLabel = () => {
        const productName = service.optionName;
        const unitPrice = service.price ? `${service.price}€ + ${intl.formatMessage({ id: "Contract.EVCharger.SelfBilling.VAT" })}` : "";
        const unitName = service.unitTypeName
            ? " / " + service.unitTypeName
            : "";
        const priceWithUnit = unitPrice ? `(${unitPrice}${unitName})` : "";
        return `${productName} ${priceWithUnit}`;
    }

    return (
        <div className="additional-service-item">
            {getServiceLabel()}
            {service.selectedPeriodXc && Object.keys(PaymentInterval).includes(service.selectedPeriodXc) &&
                <div className="payment-interval-wrapper">
                    <div className="payment-interval-wrapper--title">
                        <FormattedMessage id="Contract.AdditionalService.PaymentInterval.Title" />
                    </div>
                    <div className="mr-15">
                        {intl.formatMessage({id: PaymentInterval[service.selectedPeriodXc]})}
                    </div>
                </div>
            }
        </div>
    );
}

export default ContractAdditionalService;
