import {FETCH_RESPONSIBLE_PERSONS } from "../../actions/responsiblePersonsActions";

export default function reducer(state = {
    data: [],
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_RESPONSIBLE_PERSONS}_PENDING`: {
            return { ...state, error: null, fetching: true };
        }
        case `${FETCH_RESPONSIBLE_PERSONS}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${FETCH_RESPONSIBLE_PERSONS}_FULFILLED`: {
            return { ...state, fetching: false, error: null, fetched: true, data: action.payload.data };
        }
        default:
            return state;
    }
}