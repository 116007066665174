import { useCallback, useEffect, useState } from "react";
import { useEParakstsMobile, useTimedRedirect } from "../helpers/hooks";
import { Gas } from "../const/contractType";
import { useDispatch, useSelector } from "react-redux";
import { finalizeElmoContractOfferSinging } from "../actions/contractActions";
import { useHistory } from "react-router-dom";
import { ContractThankYouPath, StartPath } from "../const/routes";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import { useIntl } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";

const ContractSigned = () => {
    const [isError, setIsError] = useState(false);

    const postFinalizeElmoContract = useSelector(state => state.finalizeElmoContractOfferSinging);

    const { getCurrentState } = useEParakstsMobile();
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const timedRedirect = useTimedRedirect();

    const finalizeElmoContract = useCallback((data, signingMethod) => {
        dispatch(finalizeElmoContractOfferSinging({
            contractId: data.contractId,
            contractNumber: data.contractNumber,
            contractType: data.contractType,
            signingMethod: signingMethod
        }));
    }, [dispatch]);

    useEffect(() => {
        const { contractData, signingMethod } = getCurrentState();

        switch (contractData.contractType) {
            case Gas:
                finalizeElmoContract(contractData, signingMethod);
                break;
            default:
                setIsError(true);
                timedRedirect(StartPath);
                break;
        }
    }, [history, getCurrentState, finalizeElmoContract, timedRedirect]);

    useEffect(() => {
        if (postFinalizeElmoContract.fetched && !postFinalizeElmoContract.error) {
            history.push(ContractThankYouPath);
        }

        if (postFinalizeElmoContract.error) {
            timedRedirect(StartPath);
        }
    }, [history, timedRedirect, postFinalizeElmoContract]);

    if (postFinalizeElmoContract.fetching) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    if (postFinalizeElmoContract.error || isError) {
        return (
            <Grid>
                <GridCell span={12}>
                <AlertMessage
                    type={AlertMessage.TYPE_ALERT}
                    title={intl.formatMessage({ id: "Contract.Finalize.Failed" })}
                    description={intl.formatMessage({ id: "Contract.Finalize.WaitForRedirect" })}
                />
                </GridCell>
            </Grid>
        );
    }

    return null;
}

export default ContractSigned;