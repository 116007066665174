import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber, injectIntl } from "react-intl";

const Number = ({ fractionalDigits, value, unit }, props) => {
    const minFractionalDigits = props.minFractionalDigits || fractionalDigits;
    const maxFractionalDigits = props.maxFractionalDigits || fractionalDigits;

    const number =
        <FormattedNumber value={value}
            minimumFractionDigits={minFractionalDigits}
            maximumFractionDigits={maxFractionalDigits}
            {...props} />;

    return !!unit ?
        <>{number} {unit}</>
        : number;
}

Number.defaultProps = {
    fractionalDigits: 2
};

Number.propTypes = {
    fractionalDigits: PropTypes.number,
    unit: PropTypes.string
}

export default injectIntl(Number);