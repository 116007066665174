import auth from "../auth/authenticate";
import BusinessAPI from './BusinessApi';

export const sendHintEmail = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'SEND_HINT_EMAIL',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/hint/send`, data)
    };
}

//TODO:need reducer
export const fetchHintHistory = (filter) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'FETCH_HINT_HISTORY',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/hint/history`, {params: filter})
    };
}

//TODO:need reducer
export const fetchHintHistoryAgentList = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'FETCH_HINT_AGENT_LIST',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/hint/agent/list`)
    };
}
