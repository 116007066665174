import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { GridCell, GridInner } from "@rmwc/grid";
import moment from "moment";

import { fetchTestNotificationsAsync } from "../../actions/pushNotificationActions";
import TableList from "../common/TableList";
import Loader from "../common/Loader";
import AlertMessage from "../common/AlertMessage";
import Pagination from "../common/Pagination";

import auth from "../../auth/authenticate";

const TableHeaders = [
    { label: <span>Tüüp</span> },
    { label: <span>Loodud</span> },
    { label: <span>Saadetud</span> },
    { label: <span>Keel</span>, colspan: 2 }
];

const TableHeadersMobile = [
    { label: <span>Saadetud</span> },
    { label: <span>Keel</span>, colspan: 2 },
];

const TestNotificationsList = () => {

    const [filter, setFilter] = useState({
        page: 1,
        pageSize: auth.getPageSize()
    });

    const dispatch = useDispatch();
    const scrollToRef = useRef();
    const listRef = useRef();

    const {
        fetching,
        fetched,
        error,
        data,
        total
    } = useSelector(state => state.testNotifications);

    const hasItems = fetched && data && data.length > 0;
    const hasNoItems = fetched && (!data || data.length <= 0);

    const refresh = () => {
        listRef.current?.clearCache();
        dispatch(fetchTestNotificationsAsync(filter.page, filter.pageSize));
    };

    const onPageChange = ({ page, pageSize }) => {
        setFilter({ ...filter, page: page, pageSize: pageSize });
        refresh();
    }

    useEffect(() => {
        if (!fetching && !fetched && !error) {
            refresh();
        }
    }, []);

    const renderRow = (title, content, isLast) =>
        <div className={classNames("d-tr", { "border-bottom": !isLast })}>
            <div className="d-td bold p-15 p-mobile-4">
                {title}
            </div>
            <div className="d-td p-15 p-mobile-4">
                {content}
            </div>
        </div>;

    const renderMessage = (message) =>
        !!message
            ? <div className="pre word-wrap">
                {JSON.stringify(JSON.parse(message), null, 2)}
            </div>
            : <></>;

    const renderSentOnDate = (sentOnDate) =>
        !sentOnDate
            ? <FormattedMessage id="Debug.SentTestNotifications.NotSent" defaultMessage="Saatmata" />
            : moment(sentOnDate).format("DD.MM.yyyy HH:mm:ss");


    const getTableRow = (item) => {
        return {
            content: [
                { content: <FormattedMessage id={`Settings.Notifications.Type.${item.type}`} /> },
                { content: moment(item.createdOn).format("DD.MM.yyyy HH:mm:ss") },
                { content: renderSentOnDate(item.sentOn) },
                { content: <FormattedMessage id={`General.Language.${item.sentLanguage}`} /> }
            ],
            openedClassName: "no-padding field--colored",
            expanded: [
                {
                    colspan: 6,
                    content:
                        <div className="d-table w-full">
                            {renderRow("Vana hub", renderMessage(item.messageOld))}
                            {renderRow("Uus hub", renderMessage(item.messageNew))}
                            {renderRow("Uus hub, kohalik", renderMessage(item.messageNewLocal), true)}
                        </div>
                }
            ]
        };
    }

    const getTableRowMobile = (item) => {

        return {
            content: [
                { content: renderSentOnDate(item.sentOn) },
                { content: <FormattedMessage id={`General.Language.${item.sentLanguage}`} /> },
            ],
            openedClassName: "no-padding field--colored",
            expanded: [
                {
                    colspan: 3,
                    content:
                        <div className="d-table w-full">
                            {renderRow("Tüüp", <FormattedMessage id={`Settings.Notifications.Type.${item.type}`} />)}
                            {renderRow("Loodud", moment(item.createdOn).format("DD.MM.yyyy HH:mm:ss"))}
                            {renderRow("Saadetud", renderSentOnDate(item.sentOn))}
                            {renderRow("Keel", <FormattedMessage id={`General.Language.${item.sentLanguage}`} />)}
                            {renderRow("Vana hub", renderMessage(item.messageOld))}
                            {renderRow("Uus hub", renderMessage(item.messageNew))}
                            {renderRow("Uus hub", renderMessage(item.messageNewLocal), true)}
                        </div>
                }
            ]
        };
    }


    return (
        <>
            <GridInner>
                <GridCell span={12} style={{ position: "relative" }}>
                    <GridInner>
                        {fetching &&
                            <Loader type={Loader.TYPE_COVER} />}

                        {hasNoItems &&
                            <GridCell span={12} ref={scrollToRef}>
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_NOTICE}
                                    title={<FormattedMessage id="Debug.SentTestNotifications.NoItems" />} />
                            </GridCell>}

                        {hasItems &&
                            <GridCell span={12} ref={scrollToRef}>
                                <TableList
                                    innerRef={listRef}
                                    headers={TableHeaders}
                                    itemContent={data.map(getTableRow)}
                                    mobileHeaders={TableHeadersMobile}
                                    mobileItemContent={data.map(getTableRowMobile)}
                                    selectable={false}
                                    expandable={true}
                                />
                            </GridCell>}

                        <GridCell span={12}>
                            <Pagination
                                total={total || 0}
                                selectedPage={filter.page}
                                onChange={onPageChange}
                                scrollToRef={scrollToRef} />
                        </GridCell>
                    </GridInner>
                </GridCell>
            </GridInner>
        </>
    );
}

export default TestNotificationsList;