import { isAndroid, isIOS, isMobile } from "react-device-detect";

export function SendGoalEvent(eventCategory) {
    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'goal', {
            'event_category': 'choose-station'
        });
    }
}

export function TagPageView(location) {
    let environment = "web";
    let usingApp = localStorage.getItem("usingapp") || false;
    if (isMobile && !usingApp) {
        environment = "hybridApp";
    } else if (isAndroid) {
        environment = "androidApp";
    }  else if (isIOS) {
        environment = "iOSApp";
    }

    if (typeof window.gtag !== 'undefined') {
        window.gtag({
            "event": "customPageview",
            "virtualPageURL": "/" + environment + location,
            "virtualPageTitle": location,
            "environment": environment
        });
    }
}

export function SendActionEvent(event) {
    let eventData = {};
    let environment = "web";
    let usingApp = localStorage.getItem("usingapp") || false;
    if (isMobile && !usingApp) {
        environment = "hybridApp";
    } else if (isAndroid) {
        environment = "androidApp";
    }  else if (isIOS) {
        environment = "iOSApp";
    }

    switch (event) {
        case 'CardCreating' :
            eventData = {
                "event": "customViewHomeCardOwner",
                "productName" : "Home Card",
                "step": 1,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/home-card/step-1",
                "virtualPageTitle" : "Step 1 – Owner details"
            }
            break;
        case 'CardFinalized' :
            eventData = {
                "event": "customViewHomeCardThanks",
                "productName" : "Home Card",
                "step": 2,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/home-card/step-2",
                "virtualPageTitle" : "Step 2 – Thank you"
            }
            break;
        case 'ElectricityProductSelection' :
            eventData = {
                "event": "customViewElectricityStart",
                "productName" : "Electricity",
                "step": 1,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/electricity/step-1",
                "virtualPageTitle" : "Step 1 – Electricity package"
            }
            break;
        case 'ElectricityCreateOffer' :
            eventData = {
                "event": "customViewElectricityContract",
                "productName" : "Electricity",
                "step": 2,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/electricity/step-2",
                "virtualPageTitle" : "Step 2 – Signing Contract"
            }
            break;
        case 'ElectricityFinalized' :
            eventData = {
                "event": "customViewElectricityThanks",
                "productName" : "Electricity",
                "step": 3,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/electricity/step-3",
                "virtualPageTitle" : "Step 3 – Thank you"
            }
            break;
        case 'GasProductSelection' :
            eventData = {
                "event": "customViewGasStart",
                "productName" : "Gas",
                "step": 1,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/gas/step-1",
                "virtualPageTitle" : "Step 1 – Gas package"
            }
            break;
        case 'GasCreateOffer' :
            eventData = {
                "event": "customViewGasContract",
                "productName" : "Gas",
                "step": 2,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/gas/step-2",
                "virtualPageTitle" : "Step 2 – Signing Contract"
            }
            break;
        case 'GasFinalized' :
            eventData = {
                "event": "customViewGasThanks",
                "productName" : "Gas",
                "step": 3,
                "country": "Estonia",
                "virtualPageURL" : "/" + environment + "/funnel/gas/step-3",
                "virtualPageTitle" : "Step 3 – Thank you"
            }
            break;
        default:
            return;
    }
    if (typeof window.gtag !== 'undefined') {
        window.gtag(eventData);
    }
}