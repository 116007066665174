import axios from "axios";

export default function reducer(state={
    data: [],
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case "FETCH_ELECTRICITY_PRODUCTS_PENDING": {
            return {...state, fetching: true};
        }
        case "FETCH_ELECTRICITY_PRODUCTS_REJECTED": {
            if (axios.isCancel(action.payload)) {
                return {...state, fetching: true, fetched: false, error: null};
            }

            return {...state, fetching: false, error: action.payload};
        }
        case "FETCH_ELECTRICITY_PRODUCTS_FULFILLED": {
            return {...state, fetching: false, fetched: true, data: action.payload.data, error: null};
        }
        default:
            return state;
    }
}