import BusinessApi from './BusinessApi';
import { UsingApp, IsLatvianWeb } from "../helpers/AlternativeDesign";
import auth from "../auth/authenticate";

export const finishLogin = (data) => {
    data.usingApp = UsingApp();
    data.country = IsLatvianWeb() ? "LV" : "EE";

    return {
        type: "LOG_IN",
        payload: BusinessApi.post('/api/Login/finish', data),
    };
};

export const refreshJwt = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: "JWT_REFRESH",
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/login/refreshJwt`),
    };
};

export const logout = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: "LOG_OUT",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/login/logout`)
    };
};

export const updateUser = () => {
    return {
        type: "UPDATE_USER",
        payload: ""
    };
};

export const updateCampaignLogin = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "UPDATE_CAMPAIGN_LOGIN",
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/login/updateCampaignLogin`, data)
    };
}