
import { useEffect, useMemo, useState } from "react"
import { Grid, GridCell } from "@rmwc/grid";
import classNames from "classnames";
import AlertMessage from "../common/AlertMessage";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Typography } from "@rmwc/typography";
import { List, ListItem, ListItemGraphic, ListItemMeta } from "@rmwc/list";
import TextFieldGroup from "../common/TextFieldGroup";
import { Button } from "@rmwc/button";
import { useDispatch, useSelector } from "react-redux";
import { useValidator } from "../../helpers/hooks";
import IdCodeValidatorRule from "../../validators/IdCodeValidatorRule";
import auth from "../../auth/authenticate";
import {
    fetchFamilyGroup,
    createFamilyGroup,
    deleteGroup,
    clearDeleteGroup,
    clearCreateFamilyGroup,
    removeMember,
    acceptInvitation,
    rejectInvitation,
    clearAcceptInvite,
    clearRejectInvite,
    leaveFamilyGroup,
    clearLeaveFamilyGroup
} from "../../actions/familyGroupActions";
import Loader from "../common/Loader";
import ConfirmDialog from "../common/ConfirmDialog";
import EeIdCodeValidator from "../../validators/EeIdCodeValidator";
import { StartPath } from "../../const/routes";

import "../../styles/components/_family-group-selector.scss";

const MAX_NUMBER_OF_MEMBERS = 6;

const FamilyGroupForm = ({ isModal = false, onClose }) => {
    const submitFamilyGroup = useSelector(state => state.createFamilyGroup);
    const deleteFamilyGroup = useSelector(state => state.deleteFamilyGroup);
    const familyGroup = useSelector(state => state.familyGroup);
    const removeGroupMember = useSelector(state => state.removeFamilyGroupMember);
    const acceptInvite = useSelector(state => state.acceptFamilyGroupInvitation);
    const rejectInvite = useSelector(state => state.rejectFamilyGroupInvitation);
    const leaveGroup = useSelector(state => state.leaveFamilyGroup);
    const { isMobileOrTabletView } = useSelector(state => state.ui);

    const [familyGuySsn, setFamilyGuySsn] = useState();
    const [familyMembers, setFamilyMembers] = useState([]);
    const [isSubmitActive, setIsSubmitActive] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [memberToBeDeleted, setMemberToBeDeleted] = useState(null);
    const [isLeaveGroupDialogOpen, setIsLeaveGroupDialogOpen] = useState(false);

    const intl = useIntl();
    const history = useHistory();
    const idCodeValidator = new EeIdCodeValidator();
    const dispatch = useDispatch();
    const [validator,] = useValidator({
        ...IdCodeValidatorRule.rule(),
        over16: {
            message: intl.formatMessage({ id: "Campaign.FamilyGroup.Error.Under16Years" }),
            rule: (val) => {
                return idCodeValidator.validateIsOver16(val);
            }
        }
    });

    const createMember = (index, ssn, email, isNew = false, isHeadOfFamily = false) => {
        return {
            index,
            ssn,
            email,
            isNew,
            isHeadOfFamily
        }
    }

    useEffect(() => {
        if (!familyGroup.data?.members || familyGroup.data.members.length === 0) {
            const ssn = auth.getRole()?.nationalIdentityNumber;
            setFamilyMembers([createMember(null, ssn, "", false, true)]);
            setFamilyGuySsn(ssn);
        } else {
            setFamilyMembers(familyGroup.data.members.sort((a, b) => b.isHeadOfFamily - a.isHeadOfFamily).map(m => createMember(null, m.personalCode, m.email, false, m.isHeadOfFamily)));
            setFamilyGuySsn(familyGroup.data.members.find(m => m.isHeadOfFamily)?.personalCode || null);
        }
    }, [familyGroup.data]);

    useEffect(() => {
        if ((submitFamilyGroup.fetched && !submitFamilyGroup.error && !submitFamilyGroup.data?.error) ||
            (deleteFamilyGroup.fetched && !deleteFamilyGroup.error) ||
            (acceptInvite.fetched && !acceptInvite.error) ||
            (rejectInvite.fetched && !rejectInvite.error) ||
            (leaveGroup.fetched && !leaveGroup.error)) {
            onClose?.();

            if (!isModal) {
                dispatch(fetchFamilyGroup(familyGroup.data.groupId));
            }
        }
    }, [dispatch, deleteFamilyGroup, submitFamilyGroup, acceptInvite, rejectInvite, leaveGroup]);

    useEffect(() => {
        const clearData = () => dispatch => {
            dispatch(clearCreateFamilyGroup());
            dispatch(clearDeleteGroup());
            dispatch(clearAcceptInvite());
            dispatch(clearRejectInvite());
            dispatch(clearLeaveFamilyGroup());
        }

        return () => {
            dispatch(clearData());
        }
    }, [dispatch]);

    useEffect(() => {
        if (removeGroupMember.fetched && !removeGroupMember.fetching && !removeGroupMember.error) {
            onDeleteMemberClick(memberToBeDeleted);
            setMemberToBeDeleted(null);
            setIsConfirmDialogOpen(false);
            dispatch(fetchFamilyGroup(familyGroup.data.groupId));
        }

        if (removeGroupMember.error) {
            setIsConfirmDialogOpen(false);
        }
    }, [dispatch, removeGroupMember]);

    const isCurrentUserPendingInvitation = useMemo(() => {
        const ssn = auth.getRole()?.nationalIdentityNumber;
        const currentMember = familyGroup.data?.members?.find(m => m.personalCode === ssn);

        return currentMember?.isPendingApplication ?? false;
    }, [familyGroup])

    const isUserHeadOfFamily = useMemo(() => {
        const role = auth.getRole();
        return role?.nationalIdentityNumber === familyGuySsn
    }, [familyGuySsn]);

    const onCancel = () => {
        validator.visibleFields = [];
        validator.hideMessages();

        if (onClose) {
            setIsSubmitActive(false);
            onClose();
        } else {
            history.replace(StartPath);
        }
    }

    const onChangeField = (index, field, value) => {
        const memberIndex = familyMembers.findIndex(m => m.index === index && m.isNew);
        const memberToChange = familyMembers[memberIndex];
        memberToChange[field] = value;
        const updatedMembers = [
            ...familyMembers.slice(0, memberIndex),
            memberToChange,
            ...familyMembers.slice(memberIndex + 1)
        ];

        setFamilyMembers(updatedMembers);
    }

    const onAddNewMemberRow = () => {
        if (familyMembers.length >= MAX_NUMBER_OF_MEMBERS) return;
        if (familyMembers.find(m => m.isNew && (m.ssn.length === 0 || m.email.length === 0))) return;

        const newMembers = familyMembers.filter(m => m.isNew);
        const newIndex = newMembers.length ? Math.max(...newMembers.map(m => m.index)) + 1 : 0;
        setFamilyMembers(prev => [...prev, createMember(newIndex, "", "", true)]);

        validator.visibleFields = [];
        validator.hideMessages();

        setIsSubmitActive(true);
    }

    const onDeleteMemberClick = (ssn) => {
        setFamilyMembers(prev => [...prev].filter(m => m.ssn !== ssn));
        setIsSubmitActive(true);
    }

    const onDeleteNewMemberClick = (index) => {
        setFamilyMembers(prev => [...prev].filter(m => m.index !== index));
        setIsSubmitActive(false);
    }

    const onLeaveGroup = () => {
        if (!familyGroup.data.groupId) {
            return;
        }

        const ssn = auth.getRole()?.nationalIdentityNumber;
        dispatch(leaveFamilyGroup({ groupId: familyGroup.data.groupId, memberSsn: ssn }));

        setIsLeaveGroupDialogOpen(false);
        setIsSubmitActive(false);
        dispatch(clearLeaveFamilyGroup());
    }

    const onUpdateMembers = () => dispatch => {
        dispatch(
            createFamilyGroup({
                groupId: familyGroup.data.groupId || null,
                members: familyMembers.map(member => ({
                    isHeadOfFamily: member.isHeadOfFamily,
                    personalCode: member.ssn,
                    email: member.email
                }))
            })
        );
        dispatch(clearDeleteGroup());
    }

    const onSubmitMembers = () => {
        for (const member of familyMembers) {
            if (!member.isNew) {
                continue;
            }

            if (!idCodeValidator.validate(member.ssn)) {
                validator.showMessages();
                return;
            }

            if (!idCodeValidator.validateIsOver16(member.ssn)) {
                validator.showMessages();
                return;
            }

            if (!member.email.includes("@") && !member.email.includes(".")) {
                validator.showMessages();
                return;
            }
        }

        dispatch(onUpdateMembers());
        dispatch(clearDeleteGroup());
        setIsSubmitActive(false);
    }

    const onDeleteGroup = () => {
        dispatch(deleteGroup(familyGroup.data.groupId));
        dispatch(clearCreateFamilyGroup());
    }

    const onAcceptInvitation = () => {
        dispatch(acceptInvitation(familyGroup.data.groupId));
    }

    const onRejectInvitation = () => {
        dispatch(rejectInvitation(familyGroup.data.groupId));
    }

    const renderDeleteButton = (onDelete, showText) => {
        return (
            <ListItemMeta
                onClick={onDelete}
                icon={{
                    icon: "trash-can-solid",
                    strategy: "className",
                    basename: "icon",
                    prefix: "icon-",
                    className: "delete"
                }}
            >
                {showText ? <FormattedMessage id="Campaign.FamilyGroup.Dialog.Button.Remove" /> : ""}
            </ListItemMeta>
        );
    }

    const onDeleteMemberFunc = (ssn) => {
        if (isMobileOrTabletView) {
            onDeleteMemberClick(ssn);
        } else {
            setMemberToBeDeleted(ssn);
            setIsConfirmDialogOpen(true);
        }
    }

    const onDeleteMemberDialogConfirm = () => {
        dispatch(removeMember({
            memberSsn: memberToBeDeleted,
            groupId: familyGroup.data.groupId || null
        }));
    }

    const onLeaveGroupButtonClicked = () => {
        if (!isMobileOrTabletView) {
            setIsLeaveGroupDialogOpen(true);
        } else {
            setIsSubmitActive(true);
        }
    }

    if (familyGroup.fetching) {
        return null;
    }

    const renderLeaveGroupButtons = () => {
        return isSubmitActive ?
            <Button
                className="family-group-button"
                unelevated
                label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.Confirm" })}
                onClick={onLeaveGroup}
            /> :
            <Button
                className="family-group-button"
                unelevated
                label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.Button.Leave" })}
                onClick={onLeaveGroupButtonClicked}
            />
    }

    const renderButtons = () => {
        if (removeGroupMember.fetching) {
            return <Loader type={Loader.TYPE_CENTER} />;
        }

        const newMembers = familyMembers.filter(m => m.isNew);

        return (
            <>
                {isUserHeadOfFamily && (familyMembers.length < MAX_NUMBER_OF_MEMBERS || isSubmitActive) &&
                    <>
                        <GridCell span={4}>
                            {(newMembers.length > 0 || (isSubmitActive && isMobileOrTabletView)) &&
                                <Button
                                    className="family-group-button"
                                    unelevated
                                    label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.Confirm" })}
                                    onClick={onSubmitMembers}
                                />
                            }
                        </GridCell>
                        <GridCell span={4} className="family-group-icon">
                            {(!isSubmitActive || !isMobileOrTabletView) &&
                                <Button
                                    outlined
                                    className="family-group-button"
                                    label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.AddMember" })}
                                    icon={{
                                        icon: "plus",
                                        strategy: "className",
                                        basename: "icon",
                                        prefix: "icon-",
                                        className: ""
                                    }}
                                    onClick={onAddNewMemberRow}
                                    disabled={familyMembers.length >= MAX_NUMBER_OF_MEMBERS}
                                />
                            }
                        </GridCell>
                    </>
                }
                {!isUserHeadOfFamily &&
                    <GridCell span={4}>
                        {isCurrentUserPendingInvitation ?
                            <Button
                                className="family-group-button"
                                unelevated
                                label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.Confirm" })}
                                onClick={onAcceptInvitation}
                            /> :
                            renderLeaveGroupButtons()
                        }
                    </GridCell>
                }
                <GridCell span={4}>
                    {isCurrentUserPendingInvitation ?
                        <Button
                            className="family-group-button"
                            outlined
                            label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.Reject" })}
                            onClick={onRejectInvitation}
                        /> :
                        (newMembers.length > 0 || isMobileOrTabletView) &&
                            <Button
                                className="family-group-button"
                                outlined
                                label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.Cancel" })}
                                onClick={onCancel}
                            />
                    }
                </GridCell>
                {familyGroup.data?.members?.length > 0 && isUserHeadOfFamily && familyMembers.length === 1 &&
                    <>
                        {!isMobileOrTabletView && <GridCell span={4}></GridCell>}
                        <GridCell desktop={4} phone={12}>
                            <Button
                                className="family-group-button"
                                danger
                                unelevated
                                label={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.Button.DeleteGroup" })}
                                onClick={onDeleteGroup}
                            />
                        </GridCell>
                        {!isMobileOrTabletView && <GridCell span={4}></GridCell>}
                    </>
                }
            </>
        );
    }

    const renderMembersList = () => {
        const role = auth.getRole();

        return (
            <GridCell span={12}>
                <List>
                    {familyMembers.filter(m => !m.isNew).map(({ ssn, email }, index) => {
                        const memberInfo = familyGroup.data?.members?.find(m => m.personalCode === ssn);
                        const isCurrentUser = role?.nationalIdentityNumber === memberInfo?.personalCode;

                        // Non-head of family member must not see other members except head of family and self
                        if (!isUserHeadOfFamily && !memberInfo?.isHeadOfFamily && !isCurrentUser) {
                            return null;
                        }

                        const canEditRow = isUserHeadOfFamily && index > 0; // Current user cannot remove self

                        const customerName = memberInfo?.customerName ?? (familyGuySsn === ssn ? role?.title ?? "" : "");
                        const dash = isMobileOrTabletView ? "" : " - ";
                        const pendingText = memberInfo?.isPendingApplication && !isCurrentUser
                            ? `${dash}${intl.formatMessage({ id: "Campaign.FamilyGroup.Member.Pending" })} `
                            : "";
                        const displayName = customerName ? `${customerName} (${ssn})` : `${ssn} (${email})`;

                        return (
                            <ListItem key={index} className={classNames("family-group--member", {
                                "family-group--member-pending": memberInfo?.isPendingApplication
                            })}>
                                <ListItemGraphic
                                    icon={{
                                        icon: ssn === familyGuySsn ? "user" : "",
                                        strategy: "className",
                                        basename: "icon",
                                        prefix: "icon-",
                                        className: ""
                                    }}
                                />
                                <div>
                                    <span className={role?.nationalIdentityNumber === ssn ? "text-bold" : ""}>{displayName}</span>
                                    <span className={isMobileOrTabletView ? "family-group--member-pending-mobile-text" : ""}>{pendingText}</span>
                                </div>
                                {canEditRow && renderDeleteButton(() => onDeleteMemberFunc(ssn), !isMobileOrTabletView)}
                            </ListItem>
                        );
                    })}
                </List>
            </GridCell>
        );
    }

    const renderContent = () => {
        return (
            <>
                {renderMembersList()}
                {isUserHeadOfFamily && familyMembers.length <= MAX_NUMBER_OF_MEMBERS &&
                    familyMembers.filter(m => m.isNew).map((member, index) => {
                        return (
                            <GridCell key={index} span={12} className="family-group--input-group">
                                <GridCell desktop={5} tablet={5} phone={4}>
                                    <TextFieldGroup
                                        field={`ssn${index}`}
                                        onChange={e => onChangeField(member.index, "ssn", e.target.value)}
                                        validator={validator}
                                        rules="required|idCode:EE|over16"
                                        value={member.ssn}
                                        label="isikukood"
                                        validationLabel="isikukood"
                                        noLabel={true}
                                        placeholder={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.SsnPlaceholder" })}
                                    />
                                </GridCell>
                                <GridCell desktop={5} tablet={5} phone={4}>
                                    <TextFieldGroup
                                        field={`email${index}`}
                                        onChange={e => onChangeField(member.index, "email", e.target.value)}
                                        validator={validator}
                                        rules="required|email"
                                        value={member.email}
                                        label="email"
                                        validationLabel="email"
                                        noLabel={true}
                                        placeholder={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.EmailFieldPlaceholder" })}
                                    />
                                </GridCell>
                                <GridCell
                                    desktop={2}
                                    tablet={2}
                                    phone={1}
                                    className={classNames("family-group--input-delete-icon", { "right": isMobileOrTabletView })}
                                >
                                    {renderDeleteButton(() => onDeleteNewMemberClick(member.index), true)}
                                </GridCell>
                            </GridCell>
                        );
                    })
                }
                {renderButtons()}
            </>
        );
    }

    const renderSuccessMessage = () => {
        if (isModal) {
            return null;
        }

        let message = null;

        if (submitFamilyGroup.fetched && !submitFamilyGroup.error && !submitFamilyGroup.data?.error) {
            message = "Campaign.FamilyGroup.Section.SuccessMessage";
        }

        if (leaveGroup.fetched && !leaveGroup.error) {
            message = "Campaign.FamilyGroup.Section.SuccessMessageLeave";
        }

        if (!message) {
            return null;
        }

        return (
            <GridCell span={12}>
                <AlertMessage
                    isSmall
                    type={AlertMessage.TYPE_DONE}
                    title={intl.formatMessage({ id: message })}
                />
            </GridCell>
        );
    }

    return (
        <Grid className="family-group">
            {(submitFamilyGroup.error || removeGroupMember.error || submitFamilyGroup.data?.error) && !submitFamilyGroup.fetching &&
                <GridCell span={12}>
                    {submitFamilyGroup.data?.error ?
                        <AlertMessage
                            isSmall
                            type={AlertMessage.TYPE_ALERT}
                            title={intl.formatMessage({ id: `Campaign.FamilyGroup.Error.${submitFamilyGroup.data.error}` }, {
                                code: submitFamilyGroup.data.offendingPersonalCode
                            })}
                        /> :
                        <AlertMessage
                            isSmall
                            type={AlertMessage.TYPE_ALERT}
                            title={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.SubmitError.Title" })}
                            description={intl.formatMessage({ id: "Campaign.FamilyGroup.Dialog.SubmitError.Description" })}
                        />
                    }
                </GridCell>
            }
            {submitFamilyGroup.fetching &&
                <Loader type={Loader.TYPE_CENTER} />
            }
            {renderSuccessMessage()}
            {!isModal && deleteFamilyGroup.fetched && !submitFamilyGroup.error && !submitFamilyGroup.data?.error &&
                <GridCell span={12}>
                    <AlertMessage
                        isSmall
                        type={AlertMessage.TYPE_DONE}
                        title={intl.formatMessage({ id: "Campaign.FamilyGroup.Section.GroupDeleted" })}
                    />
                </GridCell>
            }
            <GridCell span={12}>
                {isModal &&
                    <Typography
                        use="headline4"
                        tag="h4"
                        className="mdc-typography mdc-theme--primary mdc-theme-desktop-margin"
                    >
                        <FormattedMessage id={familyGroup.length > 0
                            ? "Campaign.FamilyGroup.Dialog.Title"
                            : "Campaign.FamilyGroup.Dialog.TitleNew"}
                        />
                    </Typography>
                }
                <Typography use="body1" className={isMobileOrTabletView ? "text-center" : ""}>
                    <FormattedMessage id="Campaign.FamilyGroup.Dialog.Description" />
                    &nbsp;
                    <FormattedMessage id={isCurrentUserPendingInvitation
                        ? "Campaign.FamilyGroup.Dialog.Extra.Pending"
                        : "Campaign.FamilyGroup.Dialog.Extra.Member"}
                    />
                </Typography>
            </GridCell>

            {renderContent()}

            <ConfirmDialog
                isOpen={isConfirmDialogOpen}
                text={intl.formatMessage({ id: "Campaign.FamilyGroup.Confirm.Delete.Title" })}
                description={intl.formatMessage({ id: "Campaign.FamilyGroup.Confirm.Delete.Description" })}
                onReject={() => setIsConfirmDialogOpen(false)}
                onAccept={onDeleteMemberDialogConfirm}
            />

            <ConfirmDialog
                isOpen={isLeaveGroupDialogOpen}
                text={intl.formatMessage({ id: "Campaign.FamilyGroup.Confirm.Leave.Title" })}
                description={intl.formatMessage({ id: "Campaign.FamilyGroup.Confirm.Leave.Description" })}
                onReject={() => setIsLeaveGroupDialogOpen(false)}
                onAccept={onLeaveGroup}
            />
        </Grid>
    )
}

export default FamilyGroupForm;
