import React from "react";
import ContactChange from "../components/Settings/ContactChange";
import { fetchContact } from "../actions/accountActions";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../styles/react-components/overview.scss";
import { SettingsPath } from "../const/routes";
import { Redirect } from 'react-router-dom';

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact: (props.location && props.location.state && props.location.state.params.contact) || {},
            contactId: (props.location && props.location.state && props.location.state.params.contactId) || null,
            redirect: false
        };
        this.redirectAfterSave = this.redirectAfterSave.bind(this);
    }

    redirectAfterSave() {
        this.setState({ redirect: true});
    }
    
    componentDidMount() {
        if (this.state.contactId && (!this.props.isContactLoaded || this.props.contact.crmId !== this.state.contactId) && !this.props.isContactLoading) {
            this.props.fetchContact(this.state.contactId);
        }
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={{
                pathname: SettingsPath,
                state: { params: { finishedContactSaving: true } }
            }} />)
        }
        if (!this.state.contactId) {
            return (<Redirect to={{
                pathname: SettingsPath
            }} />)
        }

        return (
            <React.Fragment>
                {this.state.contact &&
                <ContactChange contact={this.state.contact}
                finish={this.redirectAfterSave}
                title="Settings.User.ChangeContact" />
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        contact: state.contact.data,
        isContactLoaded: state.contact.fetched,
        isContactLoading: state.contact.fetching,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchContact
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(Contact)
);
