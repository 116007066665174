export const coloredTreeRanges = [ 
    {
        label: "<4000",
        range: {
            from: 0,
            to: 4000,
        },
        colorHex: "#addb9e"
    },
    {
        label: "4001-6000",
        range: {
            from: 4001,
            to: 6000,
        },
        colorHex: "#87cc6e"
    },
    {
        label: "6001-8000",
        range: {
            from: 6001,
            to: 8000,
        },
        colorHex: "#65bc46"
    },
    {
        label: "8001-10000",
        range: {
            from: 8001,
            to: 10000,
        },
        colorHex: "#569e3c"
    },
    {
        label: ">10000", 
        range: {
            from: 10001,
            to: 99999999,
        },
        colorHex: "#427a2c"
    }
]