import { Button } from "@rmwc/button";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import AlertMessage from "../../components/common/AlertMessage";
import Loader from "../../components/common/Loader";
import TableList from "../../components/common/TableList";
import UserManagementDialog from "../../components/UserManagement/UserManagementDialog";
import { fetchUsersWithRole } from "../../actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const TableHeaders = [
    {
        label: "UserManagement.TableHeader.User",
        colspan: 1
    },
    {
        label: "UserManagement.TableHeader.Role",
        colspan: 1
    },
    {
        label: "UserManagement.TableHeader.DateAdded",
        colspan: 1
    },
    {
        label: "UserManagement.TableHeader.UserGroup",
        colspan: 1
    },
    {
        label: null,
        colspan: 1
    }
];

const UserManagement = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const usersWithRole = useSelector(state => state.usersWithRole);
    const changedUser = useSelector(state => state.putUserWithRole);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUsersWithRole());
    }, [dispatch, changedUser.data]);

    useEffect(() => {
        setIsError(changedUser.error);
        setIsSuccess(changedUser.fetched && !changedUser.error);
    }, [changedUser.error, changedUser.fetched, changedUser.fetching]);

    const clearMessages = () => {
        setIsError(false);
        setIsSuccess(false);
    }

    const openDialog = () => {
        clearMessages();
        setIsDialogOpen(true);
    }

    const getTableRows = () => {
        return usersWithRole.data.map(item => ({
            content: [
                {
                    colspan: 1,
                    content: `${item.fullName} (${item.ssn})`
                },
                {
                    colspan: 1,
                    content: item.roles
                },
                {
                    colspan: 1,
                    content: moment(item.lastModified).format("DD. MMM yyyy HH:mm")
                },
                {
                    colspan: 1,
                    content: item.userGroupCode || "-"
                }
            ]
        }));
    }

    const onAddClick = () => {
        setSelectedUser(null);
        openDialog();
    }

    const onEditClick = (index) => {
        setSelectedUser(usersWithRole.data[index]);
        openDialog();
    }

    const onCloseDialog = () => {
        setIsDialogOpen(false);
    }

    return (
        <>
            <Grid>
                <GridCell span={12}>
                    <Button outlined className="right" onClick={onAddClick}>
                        <i className="icon-plus" />
                        <FormattedMessage id="UserManagement.Create" />
                    </Button>
                </GridCell>
                <GridCell span={12}>
                    {(usersWithRole.fetching || changedUser.fetching) && <Loader type={Loader.TYPE_CENTER} />}
                    {!usersWithRole.fetching && !changedUser.fetching && usersWithRole.data.length > 0 &&
                        <main>
                            <GridInner className="mb-30">
                                <GridCell span={12}>
                                    {isSuccess &&
                                        <AlertMessage
                                            type={AlertMessage.TYPE_DONE}
                                            title={<FormattedMessage id="UserManagement.Success" />}
                                        />
                                    }
                                    {isError &&
                                        <AlertMessage
                                            type={AlertMessage.TYPE_ALERT}
                                            title={<FormattedMessage id="UserManagement.Dialog.Error" />}
                                        />
                                    }
                                </GridCell>
                                <GridCell span={12}>
                                    <TableList
                                        headers={TableHeaders}
                                        selectable={false}
                                        itemContent={getTableRows()}
                                        changeRow="UserManagement.Change"
                                        changeRowCallback={(index) => onEditClick(index)}
                                    />
                                </GridCell>
                            </GridInner>
                        </main>
                    }
                    {!usersWithRole.fetching && !changedUser.fetching && usersWithRole.data.length === 0 &&
                        <AlertMessage
                            type={AlertMessage.TYPE_NOTICE}
                            title={<FormattedMessage id="UserManagement.NotFound" />}
                        />
                    }
                </GridCell>
            </Grid>

            <UserManagementDialog
                isOpen={isDialogOpen}
                onClose={onCloseDialog}
                selectedUser={selectedUser}
            />
        </>
    );
}

export default UserManagement;