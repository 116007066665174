import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";

import { Button } from "@rmwc/button";
import { Icon } from "@rmwc/icon";
import { formatUnit } from "../../helpers/energyHelpers";

import { UsingApp } from "../../helpers/AlternativeDesign";

class ProductionTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            usingApp: UsingApp()
        }

        this.toggleVisible = this.toggleVisible.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
        this.downloadExcel = this.downloadExcel.bind(this);
    }

    toggleVisible() {
        this.setState(state => ({
            ...state,
            visible: !state.visible
        }));
    }

    renderTableRow(item, index) {
        let mnmt = moment(item.timestamp);
        return (
            <tr key={index}>
                <td>{mnmt.format(this.dateFormat)}</td>
                {this.showTimeColumn &&
                    <td>{mnmt.format("HH")}-{mnmt.add(1, 'hour').format("HH")}</td>}
                <td>{item.amount}</td>
            </tr>
        );
    }

    downloadExcel() {
        const { onExportClick } = this.props;
        onExportClick("xlsx");
    }

    downloadCsv() {
        const { onExportClick } = this.props;
        onExportClick("csv");
    }

    get dateFormat() {
        if (this.props.periodType === "year") { return "MMMM YYYY"; }
        else { return "dd DD.MM.YYYY"; }
    }

    get showTimeColumn() { return this.props.periodType === "day" || this.props.periodType === "week"; }

    get columnCount() {
        // 3 = date, time, amount
        return 3 - (this.showTimeColumn ? 0 : 1);
    }


    render() {
        const { visible } = this.state;
        const { data, totalAmount, amountFormat } = this.props;

        const amountUnit = formatUnit(amountFormat);

        return (
            <div className="energy-table">
                <div className="energy-table__toggle" onClick={this.toggleVisible}>
                    <FormattedMessage id="Production.Table.Title" />
                    <Icon icon={{

                        icon: "angle-down",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: (visible && "rotate"),
                    }} />
                </div>

                <div className="table__buttons-container">
                    <Button
                        className={classnames("mdc-button--primary", { "hidden": !visible })}
                        onClick={this.downloadCsv}
                        unelevated
                    >
                        <FormattedMessage id="Production.Table.DownloadCsv" />
                    </Button>
                    <Button
                        className={classnames("mdc-button--primary ml-24 ml-mobile-clear mt-mobile-16", { "hidden": !visible })}
                        onClick={this.downloadExcel}
                        unelevated
                    >
                        <FormattedMessage id="Production.Table.DownloadXlsx" />
                    </Button>
                </div>

                <div className={classnames("table__container", { "hidden": !visible })}>
                    <table className={classnames("table table--light table--sticky-header table--sticky-footer", `table--${this.columnCount}-columns`)}>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="Production.Table.Headers.Date" />
                                </th>
                                {this.showTimeColumn &&
                                    <th>
                                        <FormattedMessage id="Production.Table.Headers.Time" />
                                    </th>}
                                <th>
                                    <FormattedMessage id="Production.Table.Headers.Production" /> <span>({amountUnit})</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((x, i) => this.renderTableRow(x, i))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={this.columnCount - 1}></td>
                                <td><b>{totalAmount} {amountUnit}</b></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div >
        );
    }
}

ProductionTable.defaultProps = {
    data: [],
}

ProductionTable.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(null)]),
    data: PropTypes.arrayOf(
        PropTypes.shape({
            amount: PropTypes.number,
            timestamp: PropTypes.string,
        })
    ),
    isTotal: PropTypes.bool,
    amountFormat: PropTypes.string,
    totalAmount: PropTypes.number,
    isLoading: PropTypes.bool,
    onExportClick: PropTypes.func,
    periodType: PropTypes.string,
}

export default injectIntl(ProductionTable);
