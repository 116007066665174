import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

import "../../styles/components/_loading-circle.scss";

const FULL_CIRCLE = 360;

const LoadingCircle = ({ timeRemainingSeconds, messages }) => {
    const [timeoutSeconds, setTimeoutSeconds] = useState(0);
    const [angle, setAngle] = useState(0);

    const getCircleAngle = useCallback(() => {
        return Math.floor(angle + (FULL_CIRCLE / timeRemainingSeconds));
    }, [angle, timeRemainingSeconds]);

    useEffect(() => {
        const timer = timeoutSeconds < timeRemainingSeconds && setInterval(() => {
            setTimeoutSeconds(timeoutSeconds + 1);
            const newAngle = getCircleAngle();
            setAngle(newAngle);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeoutSeconds, getCircleAngle, timeRemainingSeconds]);

    const getMessage = () => {
        return messages?.find(m => timeoutSeconds <= m.displayTime)?.message ?? messages?.[0]?.message ?? "";
    }

    return (
        <div className="loading-cycle">
            <svg preserveAspectRatio="none">
                <circle cx="50%" cy="50%" r="50" fill="none" stroke="#58bbef" strokeWidth="10" strokeDasharray={`${angle}, ${FULL_CIRCLE}`}/>
                <text textAnchor="middle" dominantBaseline="central" x="50%" y="50%">{timeoutSeconds}</text>
            </svg>
            <div className="loading-message">
                {getMessage()}
            </div>
        </div>
    );
}

export default LoadingCircle;