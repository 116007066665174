import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PrimaryMenuItem, getMenu } from "../../const/MenuDesktop";
import { MenuSelectionAction } from "../../reducers/menu/setMenuSelectionReducer";
import { getSecondaryMenu } from "../breadCrumbsHelper";
import auth from "../../auth/authenticate";
import { useFeatures } from "flagged";

const useMenu = () => {
    const dispatch = useDispatch();
    const { root, secondary } = useSelector(state => state.menuSelection);
    const location = useLocation();
    const isCompany = auth.isCompany() || false;
    const features = useFeatures();

    const appMenu = useMemo(() => {
        return getMenu().filter(item => !item.featureFlag || (!!item.featureFlag && features[item.featureFlag]));
    }, [features]);

    useEffect(() => {
        // Only on initial load, when user logged in
        if (root === null || secondary === null) {
            const path = location.pathname;
            const sec = getSecondaryMenu(appMenu, path);
            const primaryMenuItem = isCompany
                ? PrimaryMenuItem.BusinessCustomer
                : PrimaryMenuItem.PrivateCustomer;

            dispatch({
                type: MenuSelectionAction.SetInitialMenuSelection,
                payload: {
                    root: primaryMenuItem,
                    secondary: sec || appMenu[0]
                }
            });
        }
    }, []);

    const setMenuSelection = useCallback(({ root, secondary, path }) => {
        if (root) {
            dispatch({ type: MenuSelectionAction.SetRootMenuSelection, payload: {
                root
            }});
        }

        if (secondary) {
            dispatch({ type: MenuSelectionAction.SetSecondaryMenuSelection, payload: {
                secondary
            }});
        }

        if (path) {
            const secMenu = getSecondaryMenu(appMenu, path);
            dispatch({ type: MenuSelectionAction.SetSecondaryMenuSelection, payload: {
                secondary: secMenu
            }});
        }
    }, [dispatch, appMenu]);

    return [appMenu, setMenuSelection];
}

export default useMenu;