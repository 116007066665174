import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";
import { directFileDownloadAction } from "../helpers/fileDownloadHelpers";

export const FETCH_BONUSCARD_TRANSACTIONS = "FETCH_BONUSCARD_TRANSACTIONS";
export const FETCH_ALL_BONUSCARD_TRANSACTIONS = "FETCH_ALL_BONUSCARD_TRANSACTIONS";
export const FETCH_CARD_TRANSACTIONS = "FETCH_CARD_TRANSACTIONS";
export const FETCH_ALL_CARD_TRANSACTIONS = "FETCH_ALL_CARD_TRANSACTIONS";
export const FETCH_BONUSCARD_RECEIPTS_PDF = "FETCH_BONUSCARD_RECEIPTS_PDF";
export const FETCH_CARD_RECEIPTS_PDF = "FETCH_CARD_RECEIPTS_PDF";

export const fetchBonuscardTransactions = (
  page,
  limit,
  start,
  end,
  bonuscardNumber,
  servicePointId
) => {
  const roleId = auth.getRoleId();
  const accountId = auth.getUserId();

  return {
    type: "FETCH_BONUSCARD_TRANSACTIONS",
    payload: BusinessAPI.get(`/api/${accountId}/${roleId}/transaction/BonusCardTransactions`, {
      params: {
        crmAccountId: roleId,
        bonuscardNumber: bonuscardNumber,
        servicePointId: servicePointId,
        periodStart: start,
        periodEnd: end,
        limit: limit,
        pageNr: page,
      },
    }),
  };
};

export const fetchAllBonuscardTransactions = (
  limit,
  start,
  end,
  bonuscardNumber,
  servicePointId
) => {
  const roleId = auth.getRoleId();
  const accountId = auth.getUserId();

  return {
    type: FETCH_ALL_BONUSCARD_TRANSACTIONS,
    payload: BusinessAPI.get(`/api/${accountId}/${roleId}/transaction/BonusCardTransactions`, {
      params: {
        crmAccountId: roleId,
        bonuscardNumber: bonuscardNumber,
        servicePointId: servicePointId,
        periodStart: start,
        periodEnd: end,
        limit: limit,
        pageNr: 1,
      },
    }),
  };
};

export const fetchCardTransactions = (
  page,
  limit,
  start,
  end,
  cardNumber,
  invoiceNumber,
  cardName,
  contractNumber
) => {
  const roleId = auth.getRoleId();
  const accountId = auth.getUserId();
  return {
    type: "FETCH_CARD_TRANSACTIONS",
    payload: BusinessAPI.get(`/api/${accountId}/${roleId}/transaction/CardTransactions`, {
      params: {
        crmAccountId: roleId,
        cardNumber: cardNumber,
        invoiceNumber: invoiceNumber,
        periodStart: start,
        periodEnd: end,
        limit: limit,
        pageNr: page,
        cardName: cardName,
        contractNumber: contractNumber
      }
    })
  };
};

export const fetchAllCardTransactions = (
  limit,
  start,
  end,
  cardNumber,
  servicePointId,
) => {
  let roleId = auth.getRoleId();
  let accountId = auth.getUserId();
  return {
    type: FETCH_ALL_CARD_TRANSACTIONS,
    payload: BusinessAPI.get(`/api/${accountId}/${roleId}/transaction/CardTransactions`, {
      params: {
        crmAccountId: roleId,
        cardNumber: cardNumber,
        servicePointId: servicePointId,
        periodStart: start,
        periodEnd: end,
        limit: limit,
        pageNr: 1,
      },
    }),
  };
};

export const fetchReceiptPdf = (documentId) => {
  const roleId = auth.getRoleId();
  const accountId = auth.getUserId();
  const endpoint = `/api/${accountId}/${roleId}/transaction/receipt-pdf/${documentId}`;

  return (dispatch, getState) => {
    const {
      appContainer: { usingApp },
  } = getState();

  if (usingApp) {
      const fileName = `receipt_${documentId}.pdf`;
      directFileDownloadAction(endpoint, fileName);
    } else {
      dispatch({
        type: "FETCH_RECEIPT_PDF",
        payload: BusinessAPI.get(endpoint, {
          responseType: "arraybuffer"
        }),
      });
    }
  }
}

export const fetchBonusCardReceiptsPdf = (
    start,
    end,
    cardNumber,
    servicePointId) => {
  let roleId = auth.getRoleId();
  let accountId = auth.getUserId();
  const role = auth.getRole()
  const endpoint = `/api/${accountId}/${roleId}/transaction/receipts-pdf/bonus-cards`;

  return (dispatch, getState) => {
    const {
      appContainer: { usingApp },
    } = getState();

    if (usingApp) {
      const fileName = `receipts_${role.nationalIdentityNumber}.pdf`;
      directFileDownloadAction(endpoint, fileName);
    } else {
      dispatch({
        type: FETCH_BONUSCARD_RECEIPTS_PDF,
        payload: BusinessAPI.get(endpoint, {
          responseType: "arraybuffer",
          params: {
            crmAccountId: roleId,
            cardNumber: cardNumber,
            servicePointId: servicePointId,
            periodStart: start,
            periodEnd: end
          },
        }),
      });
    }
  }
}

export const fetchCardReceiptsPdf = (
    start,
    end,
    cardNumber,
    servicePointId) => {
  let roleId = auth.getRoleId();
  let accountId = auth.getUserId();
  const role = auth.getRole()
  const endpoint = `/api/${accountId}/${roleId}/transaction/receipts-pdf/cards`;

  return (dispatch, getState) => {
    const {
      appContainer: { usingApp },
    } = getState();

    if (usingApp) {
      const fileName = `receipts_${role.nationalIdentityNumber}.pdf`;
      directFileDownloadAction(endpoint, fileName);
    } else {
      dispatch({
        type: FETCH_CARD_RECEIPTS_PDF,
        payload: BusinessAPI.get(endpoint, {
          responseType: "arraybuffer",
          params: {
            crmAccountId: roleId,
            cardNumber: cardNumber,
            servicePointId: servicePointId,
            periodStart: start,
            periodEnd: end
          },
        }),
      });
    }
  }
}