import { getFileNameFromResponseHeaders } from "../../helpers/fileDownloadHelpers";

export default function reducer(state = {
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case "DOWNLOAD_EV_CHARGING_CONTRACT_PDF_PENDING": {
            return { ...state, fetching: true };
        }
        case "DOWNLOAD_EV_CHARGING_CONTRACT_PDF_REJECTED": {
            return { ...state, fetching: false, error: action.payload };
        }
        case "DOWNLOAD_EV_CHARGING_CONTRACT_PDF_FULFILLED": {
            const fileName = getFileNameFromResponseHeaders(action.payload.headers) || "leping_eelvaade.pdf";
            const saveData = (function() {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style = 'display: none';
                return function(data, fileName) {
                    const blob = new Blob([data],
                        {type: 'application/pdf;charset=utf-8'});
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = fileName;
                    a.target = '_blank'
                    a.click();
                    window.URL.revokeObjectURL(url);
                };
            }());

            saveData(action.payload.data, fileName);

            return {
                ...state,
                fetching: false,
                fetched: true
            };
        }
        default:
            return state;
    }
}
