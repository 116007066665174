import auth from "../auth/authenticate";
import BusinessAPI from "./BusinessApi";

export const postActionLog = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "POST_ACTION_LOG",
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/actionLog`, data)
    };
}