export const ChargingServiceType = {
    Private: "PRIVATE",
    PrivateAuthenticated: "PRIVATE_AUTHENTICATED",
    PrivateUnauthenticated: "PRIVATE_UNAUTHENTICATED",
    SemiPublic: "SEMI_PUBLIC",
    Public: "PUBLIC"
}

export const ChargingServiceTypes = [
    {
        type: ChargingServiceType.Private,
        value: "Contract.EVCharger.ServiceType.Private"
    },
    {
        type: ChargingServiceType.PrivateAuthenticated,
        value: "Contract.EVCharger.ServiceType.PrivateAuthenticated"
    },
    {
        type: ChargingServiceType.PrivateUnauthenticated,
        value: "Contract.EVCharger.ServiceType.PrivateUnauthenticated"
    },
    {
        type: ChargingServiceType.SemiPublic,
        value: "Contract.EVCharger.ServiceType.SemiPublic"
    },
    {
        type: ChargingServiceType.Public,
        value: "Contract.EVCharger.ServiceType.Public"
    }
];

export const PaymentInterval = {
    "MONTH": "Contract.AdditionalService.PaymentInterval.Month",
    "QUARTER": "Contract.AdditionalService.PaymentInterval.Quarter",
    "YEAR": "Contract.AdditionalService.PaymentInterval.Year"
}

export const EvChargerSupply = {
    Rent: "RENT",
    Sale: "SALE",
    Personal: "PERSONAL"
}
