import { FETCH_USER_NOTIFICATIONS, PUT_ALL_USER_NOTIFICATIONS_READ, PUT_USER_NOTIFICATIONS_READ } from "../../actions/notificationActions";


export default function reducer(state = {
    data: [],
    fetching: false,
    fetched: false,
    total: 0,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_USER_NOTIFICATIONS}_PENDING`: {
            return {
                ...state,
                fetching: true
            };
        }
        case `${FETCH_USER_NOTIFICATIONS}_REJECTED`: {
            return {
                ...state,
                fetching: false,
                error: action.payload
            };
        }
        case `${FETCH_USER_NOTIFICATIONS}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                total: action.payload.data.total,
                data: action.payload.data.items,
                error: null
            };
        }
        case `${PUT_ALL_USER_NOTIFICATIONS_READ}_FULFILLED`: {
            return {
                ...state,
                data: state.data.map(x => ({ ...x, isRead: true }))
            }
        }
        case `${PUT_USER_NOTIFICATIONS_READ}_FULFILLED`: {
            return {
                ...state,
                data: state.data.map(x => {
                    let y = { ...x };
                    if (action.payload.data.indexOf(x.id) >= 0) {
                        y.isRead = true;
                    }
                    return y;
                })
            }
        }
        default:
            return state;
    }
}