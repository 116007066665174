import React from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import {
    isInvoiceNotPaidAndDueDateExceeded,
    isInvoiceNotPaidAndDueDateNotExceeded,
    isInvoicePaid,
    isPaymentPending
} from "../../helpers/invoiceHelper";

const InvoiceStatus = ({ status, deadline, className, showText, showIcon, asLabel, inline }) => {

    const invoicePaid = isInvoicePaid(status);
    const paymentPending = isPaymentPending(status);
    const invoiceNotPaidAndDueDateExceeded = isInvoiceNotPaidAndDueDateExceeded(status, deadline);
    const invoiceNotPaidAndDueDateNotExceeded = isInvoiceNotPaidAndDueDateNotExceeded(status, deadline);


    return (
        <div className={classnames(
            "invoice-status",
            "nowrap",
            className,
            { "invoice-status--inline": inline },
            { "invoice-status--label": asLabel },
            {
                "mdc-theme--secondary": invoiceNotPaidAndDueDateNotExceeded,
                "mdc-theme--alert": invoiceNotPaidAndDueDateExceeded,
                "mdc-theme--warning": paymentPending,
                "mdc-theme--done": invoicePaid,
            }
        )}>
            {showIcon && <i
                className={classnames(
                    "icon",
                    { "mr-5": showText },
                    {
                        "icon-info-fill": invoiceNotPaidAndDueDateNotExceeded,
                        "icon-alert-small": invoiceNotPaidAndDueDateExceeded,
                        "icon-done-small": invoicePaid || paymentPending,
                    }
                )}
            />}
            {showText &&
                <FormattedMessage id={`Invoice.Status.${status}`} />
            }
        </div>)
}
InvoiceStatus.defaultProps = {
    showText: true,
    showIcon: true,
    asLabel: false,
    inline: false
}

InvoiceStatus.propTypes = {
    status: PropTypes.string.isRequired,
    deadline: PropTypes.string.isRequired,
    className: PropTypes.any,
    showText: PropTypes.bool,
    showIcon: PropTypes.bool,
    asLabel: PropTypes.bool,
    inline: PropTypes.bool
};

export default InvoiceStatus;
