export const MenuSelectionAction = {
    SetInitialMenuSelection: "SET_INITIAL_MENU_SELECTION",
    SetRootMenuSelection: "SET_ROOT_MENU_SELECTION",
    SetSecondaryMenuSelection: "SET_SECONDARY_MENU_SELECTION"
}

export default function reducer(state={
    root: null,
    secondary: null
}, action) {
    switch (action.type) {
        case MenuSelectionAction.SetInitialMenuSelection: {
            return { ...state, root: action.payload.root, secondary: action.payload.secondary };
        }
        case MenuSelectionAction.SetRootMenuSelection: {
            return { ...state, root: action.payload.root }
        }
        case MenuSelectionAction.SetSecondaryMenuSelection: {
            return { ...state, secondary: action.payload.secondary }
        }
        default:
            return state;
    }
}