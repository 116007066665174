import {
    FETCH_UNREAD_USER_NOTIFICATIONS,
    PUT_ALL_USER_NOTIFICATIONS_READ,
    PUT_USER_NOTIFICATIONS_READ
} from "../../actions/notificationActions";


export default function reducer(state = {
    data: [],
    fetching: false,
    fetched: false,
    error: null,
    count: 0
}, action) {
    switch (action.type) {
        case `${FETCH_UNREAD_USER_NOTIFICATIONS}_PENDING`: {
            return {
                ...state,
                fetching: true
            };
        }
        case `${FETCH_UNREAD_USER_NOTIFICATIONS}_REJECTED`: {
            return {
                ...state,
                fetching: false,
                error: action.payload
            };
        }
        case `${FETCH_UNREAD_USER_NOTIFICATIONS}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload.data,
                count: (!!action.payload.data && action.payload.data.length) || 0,
                error: null
            };
        }
        case `${PUT_ALL_USER_NOTIFICATIONS_READ}_FULFILLED`: {
            return {
                ...state,
                data: state.data.map(x => ({ ...x, isRead: true })),
                count: 0,
            }
        }
        case `${PUT_USER_NOTIFICATIONS_READ}_FULFILLED`: {
            return {
                ...state,
                data: state.data.map(x => ({
                    ...x,
                    isRead: action.payload.data.indexOf(x.id) >= 0 ? true : x.isRead
                })),
                count: Math.max(0, state.count - action.payload.data.length)
            }
        }
        default:
            return state;
    }
}