import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import SelectField from "../common/SelectField";

const ContactSelect = ({
       field,
       value,
       label,
       className,
       wrapperClassName,
       onChange,
       afterChange,
       disabled,
       contacts,
       areContactsLoading
    }) => {

    const formattedContacts = Object.values(contacts).map((item) => ({
        label: item.firstName + ' ' + item.lastName,
        value: item.crmId
    }));

    return (
        <SelectField
            disabled={disabled || formattedContacts.length < 2}
            wrapperClassName={wrapperClassName}
            field={field}
            value={value}
            className={className}
            onChange={onChange}
            afterChange={afterChange}
            options={formattedContacts}
            label={label}
            isLoading={areContactsLoading}/>
    );
};

ContactSelect.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    afterChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

ContactSelect.defaultProps = {
    label: "Cards.Contact.Select"
};

function mapStateToProps(state) {
    return {
        areContactsLoading: state.contacts.fetching,
        contacts: state.contacts.data,
    };
}

export default connect(mapStateToProps)(ContactSelect);
