import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Button } from "@rmwc/button";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import classnames from "classnames";
import Papa from "papaparse";

import TextFieldGroup from "../common/TextFieldGroup";
import TextAreaGroup from "../common/TextAreaGroup";
import Icon, { loadingIconProps } from "../common/Icon";
import FileField from "../common/FileField";
import AlertMessage from "../common/AlertMessage";

import { addNewNotificationToAllUsers, addNewNotificationToUsers, fetchCountOfAccountsForMarketingNotification, resetCountOfAccountsForMarketingNotification } from "../../actions/notificationActions";
import { useScrollToRef, useValidator } from "../../helpers/hooks";
import { Marketing } from "../../const/notificationType";
import { Typography } from "@rmwc/typography";
import RadioField from "../common/RadioField";
import { trackError, trackInfo } from "../../appInsights";
import Loader from "../common/Loader";

const FieldAddTo = "MarketingNotifications.AddTo";
const FieldCrmIds = "MarketingNotifications.CrmIds";
const FieldCrmIdsFile = "MarketignNotifications.CrmIdsFile";
const FieldTitleEt = "MarketingNotifications.TitleEt";
const FieldContentEt = "MarketingNotifications.ContentEt";
const FieldTitleEn = "MarketingNotifications.TitleEn";
const FieldContentEn = "MarketingNotifications.ContentEn";
const FieldTitleRu = "MarketingNotifications.TitleRu";
const FieldContentRu = "MarketingNotifications.ContentRu";
const FieldTitleLv = "MarketingNotifications.TitleLv";
const FieldContentLv = "MarketingNotifications.ContentLv";

const AddMarketingNotificationsForm = forwardRef((props, ref) => {

    const addToOptions = { All: "all", Ids: "ids" };

    const [addTo, setAddTo] = useState(addToOptions.All);
    const [crmIdsText, setCrmIdsText] = useState("");
    const [crmIdsFile, setCrmIdsFile] = useState(null);
    const [type, setType] = useState(Marketing);
    const [titleEt, setTitleEt] = useState("");
    const [contentEt, setContentEt] = useState("");
    const [titleEn, setTitleEn] = useState("");
    const [contentEn, setContentEn] = useState("");
    const [titleRu, setTitleRu] = useState("");
    const [contentRu, setContentRu] = useState("");
    const [titleLv, setTitleLv] = useState("");
    const [contentLv, setContentLv] = useState("");

    const [crmIdsFromText, setCrmIdsFromText] = useState([]);
    const [crmIdsFromFile, setCrmIdsFromFile] = useState([]);

    const [areMessagesVisible, setAreMessagesVisible] = useState(false);
    const [showCrmIdsError, setShowCrmIdsError] = useState(false);

    const headingRef = useRef();

    const dispatch = useDispatch();
    const [validator,] = useValidator();
    const scrollToRef = useScrollToRef();

    const {
        data: counts,
        fetching: countsFetching,
        fetched: countsFetched,
        error: countsError
    } = useSelector(state => state.marketingNotificationAccountCount);

    const {
        data: { count: addedCount },
        fetching: isSubmitting,
        fetched: submitted,
        error: submittingError
    } = useSelector(state => state.addNewNotificationToUsers);

    const crmIdsMissing = addTo === addToOptions.Ids && !crmIdsFile && !crmIdsText;

    useImperativeHandle(ref, () => ({
        copyText(item) {
            reset();
            setType(item.type);
            setTitleEt(item.titleEt);
            setContentEt(item.contentEt);
            setTitleEn(item.titleEn);
            setContentEn(item.contentEn);
            setTitleRu(item.titleRu);
            setContentRu(item.contentRu);
            setTitleLv(item.titleLv);
            setContentLv(item.contentLv);
        }
    }))

    const passesValidation = () => {
        let isValid = true;

        if (!validator.allValid()) {
            validator.showMessages();
            console.log("validator issue", validator.getErrorMessages());
            isValid = false;
        }

        setShowCrmIdsError(crmIdsMissing);
        if (crmIdsMissing) {
            console.log("crm ids missing");
            isValid = false;
        }

        return isValid;
    }

    const submit = () => {
        if (!passesValidation()) { console.log("not valid"); return; }

        const payload = {
            titleEt,
            contentEt,
            titleEn,
            contentEn,
            titleRu,
            contentRu,
            titleLv,
            contentLv,
            type,
            crmIds: crmIdsFromText.concat(crmIdsFromFile)
        };

        setAreMessagesVisible(true);
        clear();

        if (addTo === addToOptions.All) {
            return dispatch(addNewNotificationToAllUsers(payload));
        } else {
            return dispatch(addNewNotificationToUsers(payload));
        }
    };

    const reset = () => {
        setAreMessagesVisible(false);
        clear();
    }

    const clear = () => {
        setAddTo(addToOptions.All);
        setCrmIdsText("");
        setCrmIdsFile(null);
        setTitleEt("");
        setContentEt("");
        setTitleEn("");
        setContentEn("");
        setTitleRu("");
        setContentRu("");
        setTitleLv("");
        setContentLv("");
        setType(Marketing);
        setCrmIdsFromFile([]);
        setCrmIdsFromText([]);
        setShowCrmIdsError(false);

        validator.visibleFields = [];
        validator.hideMessages();
    }

    const updateCrmIdsText = (value) => {
        setCrmIdsText(value);
        setShowCrmIdsError(!value && !crmIdsFile)
    }

    const updateCrmIdsFile = (value) => {
        setCrmIdsFile(value);
        setShowCrmIdsError(!value && !crmIdsText);
        if (!value) {
            dispatch(resetCountOfAccountsForMarketingNotification());
        }
    }

    useEffect(() => {
        if (!countsFetched && !countsFetching && !countsError) {
            dispatch(fetchCountOfAccountsForMarketingNotification());
        }
    }, []);

    useEffect(() => {
        if (!crmIdsFile) {
            setCrmIdsFromFile([]);
        } else {
            Papa.parse(crmIdsFile, {
                skipEmptyLines: true,
                complete: (res) => {
                    console.log("csv parsed", res)
                    let crmIds = res.data.map(x => x[0]);
                    // If first item is header, remove it
                    if (isNaN(crmIds[0])) {
                        crmIds.splice(0, 1);
                    }
                    console.log(crmIds, crmIdsFile);
                    trackInfo(`Read ${crmIds.length} CRM ids from file '${crmIdsFile.name}' of type '${crmIdsFile.type}' and size ${crmIdsFile.size} bytes`);
                    setCrmIdsFromFile(crmIds);
                    dispatch(fetchCountOfAccountsForMarketingNotification(crmIds));
                },
                error: (error) => {
                    console.log("COuld not parse csv");
                    trackError("Could not parse csv file for CRM ids: " + error.message);
                }
            })
        }
    }, [crmIdsFile]);

    useEffect(() => {
        if (!isSubmitting && submitted) {
            scrollToRef(headingRef);
        }
    }, [submitted, isSubmitting, scrollToRef]);

    const updateCrmIdsFromText = () => {
        console.log(crmIdsText)
        if (!crmIdsText) {
            setCrmIdsFromText([]);
        }
        else {
            let ids = crmIdsText.split(",").map(x => x.trim()).filter(x => !!x);
            setCrmIdsFromText(ids);
        }
    }

    const onAddToChanged = (value) => {
        setAddTo(value);
        dispatch(resetCountOfAccountsForMarketingNotification());
        if (value === addToOptions.All) {
            dispatch(fetchCountOfAccountsForMarketingNotification());
        }
    }

    const showResultMessage = () =>
        submittingError
            ? <AlertMessage
                isSmall={true}
                type={AlertMessage.TYPE_ALERT}
                title={<FormattedMessage id="MarketingNotifications.CouldNotSubmit" />} />
            : <AlertMessage
                isSmall={true}
                type={AlertMessage.TYPE_DONE}
                title={<FormattedMessage id="MarketingNotifications.SubmitSuccessful" values={{ count: addedCount }} />} />;

    const renderAddToSelection = () =>
        <>
            <GridCell span={12}>
                <div className="field-label">
                    <FormattedMessage id="MarketingNotifications.AddTo" />
                </div>
                <GridInner>
                    {Object.entries(addToOptions).map(([k, v], i) =>
                        <GridCell span={i === 0 ? 4 : 8} key={'send-to-' + v}>
                            <RadioField
                                index={i}
                                fieldName={FieldAddTo}
                                fieldValue={v}
                                formattedMessageId={`MarketingNotifications.AddTo.${k}`}
                                selectedValue={addTo}
                                onChange={e => onAddToChanged(e.target.value)}
                            />
                        </GridCell>)}
                </GridInner>

                <div className={classnames("mdc-text-field-helper-text text-italic")} style={{ opacity: 1 }}>
                    <FormattedMessage
                        id="MarketingNotifications.AddToCounts"
                        values={{
                            totalCount: countsFetched ? counts.notificationsTotalCount : "?",
                            forPushNotificationCount: countsFetched ? counts.pushNotificationsCount : "?"
                        }} />
                    {countsFetching &&
                        <Loader type={Loader.TYPE_INLINE} size="xs" />}
                </div>
            </GridCell>
        </>;

    const renderCrmIds = () =>
        <GridCell span={12}>
            <GridInner className="grid-no-gap">
                <GridCell desktop={6} tablet={4} phone={4}
                    className={classnames("mr-12 mr-mobile-clear", { "mdc-text-field--invalid": showCrmIdsError })}>
                    <TextFieldGroup
                        field={FieldCrmIds}
                        onChange={e => updateCrmIdsText(e.target.value)}
                        value={crmIdsText}
                        label="MarketingNotifications.CrmIds"
                        validator={validator}
                        classNames={classnames({ "mdc-text-field--invalid": showCrmIdsError })}
                        onBlur={updateCrmIdsFromText}
                    />
                </GridCell>

                <GridCell desktop={6} tablet={4} phone={4}
                    className={classnames("ml-12 ml-mobile-clear", { "mdc-text-field--invalid": showCrmIdsError })}>
                    <FileField
                        field={FieldCrmIdsFile}
                        onChange={updateCrmIdsFile}
                        validator={validator}
                        fileTypes=".csv"
                        label="MarketingNotifications.CrmIdsFile"
                        emptyText="MarketingNotifications.FilePlaceholder"
                        invalid={showCrmIdsError} />
                </GridCell>

                {showCrmIdsError &&
                    <GridCell span={12} className="mdc-text-field-helper-line">
                        <div className="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
                            <Icon name="alert-small" />
                            <FormattedMessage id="MarketingNotifications.OneOfCrmIdsFieldsIsRequired" />
                        </div>
                    </GridCell>}

                <GridCell span={12} className="mdc-text-field-helper-line">
                    <div className={classnames("mdc-text-field-helper-text text-italic")} style={{ opacity: 1 }}>
                        <FormattedMessage
                            id="MarketingNotifications.CrmIdsInfoText"
                            values={{
                                total: crmIdsFromText.length + crmIdsFromFile.length,
                                crmIdsCount: crmIdsFromText.length,
                                crmIdsFromFileCount: crmIdsFromFile.length
                            }} />
                    </div>
                </GridCell>
            </GridInner>
        </GridCell>;

    const renderTextFields = () => <>
        <GridCell span={12}>
            <TextFieldGroup
                field={FieldTitleEt}
                value={titleEt || ""}
                onChange={e => setTitleEt(e.target.value)}
                validator={validator}
                rules="required|max:50"
                label="MarketingNotifications.Title.Estonian"
                maxLength={50}
                showCounter
            />
        </GridCell>
        <GridCell span={12}>
            <TextAreaGroup
                field={FieldContentEt}
                value={contentEt || ""}
                onChange={e => setContentEt(e.target.value)}
                validator={validator}
                rules="required|max:150"
                label="MarketingNotifications.Content.Estonian"
                rows={4}
                maxLength={150}
                showCounter
            />
        </GridCell>


        <GridCell span={12}>
            <TextFieldGroup
                field={FieldTitleEn}
                value={titleEn || ""}
                onChange={e => setTitleEn(e.target.value)}
                validator={validator}
                rules="required|max:50"
                label="MarketingNotifications.Title.English"
                maxLength={50}
                showCounter
            />
        </GridCell>
        <GridCell span={12}>
            <TextAreaGroup
                field={FieldContentEn}
                value={contentEn || ""}
                onChange={e => setContentEn(e.target.value)}
                validator={validator}
                rules="required|max:150"
                label="MarketingNotifications.Content.English"
                rows={4}
                maxLength={150}
                showCounter
            />
        </GridCell>


        <GridCell span={12}>
            <TextFieldGroup
                field={FieldTitleRu}
                value={titleRu || ""}
                onChange={e => setTitleRu(e.target.value)}
                validator={validator}
                rules="required|max:50"
                label="MarketingNotifications.Title.Russian"
                maxLength={50}
                showCounter
            />
        </GridCell>
        <GridCell span={12}>
            <TextAreaGroup
                field={FieldContentRu}
                value={contentRu || ""}
                onChange={e => setContentRu(e.target.value)}
                validator={validator}
                rules="required|max:150"
                label="MarketingNotifications.Content.Russian"
                rows={4}
                maxLength={150}
                showCounter
            />
        </GridCell>

         <GridCell span={12}>
            <TextFieldGroup
                field={FieldTitleLv}
                value={titleLv || ""}
                onChange={e => setTitleLv(e.target.value)}
                validator={validator}
                rules="required|max:50"
                label="MarketingNotifications.Title.Latvian"
                maxLength={50}
                showCounter
            />
        </GridCell>
        <GridCell span={12}>
            <TextAreaGroup
                field={FieldContentLv}
                value={contentLv || ""}
                onChange={e => setContentLv(e.target.value)}
                validator={validator}
                rules="required|max:150"
                label="MarketingNotifications.Content.Latvian"
                rows={4}
                maxLength={150}
                showCounter
            />
        </GridCell>
    </>;


    return <Grid className="grid-gap-16">
        <GridCell span={12} ref={headingRef}>
            <Typography use="headline4" className="mdc-typography mdc-theme--primary mb-desktop-8">
                <FormattedMessage id="MarketingNotifications.AddNewNotification" />
            </Typography>
        </GridCell>

        {submitted && areMessagesVisible &&
            <GridCell span={12}>
                {showResultMessage()}
            </GridCell>}

        {renderAddToSelection()}
        {addTo === addToOptions.Ids &&
            renderCrmIds()}

        {renderTextFields()}

        <GridCell span={12}>
            <Button
                unelevated
                disabled={isSubmitting}
                onClick={submit}
                trailingIcon={isSubmitting ? { ...loadingIconProps } : null}>
                <FormattedMessage id="MarketingNotifications.SendNotifications" />
            </Button>
            <Button outlined onClick={reset} className="ml-16" >
                <FormattedMessage id="MarketingNotifications.ResetForm" />
            </Button>
        </GridCell>
    </Grid>;
});

export default AddMarketingNotificationsForm;