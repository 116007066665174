import React from "react";
import { FormattedMessage } from "react-intl";
import { Typography } from "@rmwc/typography";
import DoughnutChart from "./DoughnutChart";

const OverallDiscount = ({
    discount,
    isCreditCard
}) => {
    return (
        <div className="discount__overall">
            <DoughnutChart />
            <div className="info">
                <Typography use="headline6" className="title mdc-typography">
                    <FormattedMessage
                        id={isCreditCard
                            ? "Overview.Discount.CreditCard"
                            : "Overview.Discount.Bonuscard"}
                        tagName="b"
                    />
                </Typography>
                <Typography use="headline2" className="total mdc-typography">
                    {discount}
                </Typography>
            </div>
        </div>
    );
};

export default OverallDiscount;
