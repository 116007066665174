import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";

import { Icon } from "@rmwc/icon";
import { formatUnit } from "../../helpers/energyHelpers";

import { UsingApp } from "../../helpers/AlternativeDesign";

class NordpoolTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true,
            usingApp: UsingApp()
        }

        this.toggleVisible = this.toggleVisible.bind(this);
    }

    toggleVisible() {
        this.setState(state => ({
            ...state,
            visible: !state.visible
        }));
    }

    renderTableRow(item, index) {
        let mnmt = moment(item.timestamp);
        return (
            <tr key={index}>
                <td>{mnmt.format("dd DD.MM.YYYY")}</td>
                <td>{mnmt.format("HH")}-{mnmt.add(1, 'hour').format("HH")}</td>
                <td>{item.price}</td>
            </tr>
        );
    }

    render() {
        const { visible } = this.state;
        const { data,priceFormat, isTotal } = this.props;

        const priceUnit = formatUnit(priceFormat);

        return (
            <div className="energy-table">
                <div className="energy-table__toggle" onClick={this.toggleVisible}>
                    <FormattedMessage id="Nordpool.Table.Title" />
                    <Icon icon={{
                        icon: "angle-down",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: (visible && "rotate"),
                    }} />
                </div>

                <div className={classnames("table__container", { "hidden": !visible })}>
                    <table className="table table--light table--sticky-header table--sticky-footer table--3-columns">
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="Nordpool.Table.Headers.Date" />
                                </th>
                                <th>
                                    <FormattedMessage id="Nordpool.Table.Headers.Time" />
                                </th>
                                <th>
                                    <FormattedMessage id="Nordpool.Table.Headers.Price" /> <span>({priceUnit})</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((x, i) => this.renderTableRow(x, i, !isTotal))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={3}></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div >
        );
    }
}

NordpoolTable.defaultProps = {
    data: [],
}

NordpoolTable.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            price: PropTypes.number,
            timestamp: PropTypes.string,
        })
    ),
    priceFormat: PropTypes.string,
    isLoading: PropTypes.bool
}

export default injectIntl(NordpoolTable);
