import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Button } from "@rmwc/button";
import { Dialog, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { List } from "@rmwc/list";
import { Typography } from "@rmwc/typography";
import RadioField from "../common/RadioField";
import { Active } from "../../const/ContractStatus";
import { convertStampsClient } from "../../actions/digitalStampsActions";
import { DigitalStampConversionType } from "../../const/digitalStamps";

const TWO_BUTTONS = 6;
const THREE_BUTTONS = 4;

const DigitalStampsClientDialog = ({ isOpen, onClose, contracts, data }) => {
    const [contractId, setContractId] = useState();
    const [referenceNumber, setReferenceNumber] = useState();
    const [availableContracts, setAvailableContracts] = useState([]);
    const [hasPaymentCard, setHasPaymentCard] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (contracts) {
            const validContracts = contracts.filter(c => c.status === Active && c.isVirtualAccount === false);
            setAvailableContracts(validContracts);
            setContractId(validContracts?.[0]?.id);
            setReferenceNumber(validContracts?.[0]?.extraNumber);
            setHasPaymentCard(data.conversionType === DigitalStampConversionType.PaymentCard && validContracts?.length > 0);
        }
    }, [contracts, data]);

    const onChangeContractSelection = (contract) => {
        setContractId(contract?.id);
        setReferenceNumber(contract?.extraNumber);
    }

    const onSaveDigitalStamps = (isBalance = false) => {
        dispatch(convertStampsClient(({
            ...data,
            contractId: contractId,
            elmoReferenceNumber: referenceNumber,
            conversionType: isBalance
                ? DigitalStampConversionType.Balance
                : data.conversionType
        })));
        onClose?.();
    }

    const getContractName = (contract) => {
        if (data.conversionType === DigitalStampConversionType.Electricity || data.conversionType === DigitalStampConversionType.Gas) {
            return `${contract.number} (${contract.extraNumber})`;
        }

        return `${contract.number} (${contract.name})`;
    }

    const getSpan = () => {
        return hasPaymentCard ? THREE_BUTTONS : TWO_BUTTONS;
    }

    return (
        <Dialog open={isOpen} onClose={onClose} className="digital-stamps-client--dialog">
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss"
                />
            </DialogTitle>
            <DialogContent>
                <Grid className="mdc-layout-grid--padded">
                    <GridCell span={12}>
                        <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                            <FormattedMessage id={`DigitalStampsClient.Dialog.${data.conversionType}Title`} />
                        </Typography>
                        <div className="mt-30 mb-30">
                            <FormattedMessage
                                id={`DigitalStampsClient.Dialog.Description${data.conversionType}`}
                                values={{
                                    stamps: data.stampsAmount,
                                    amount: data.convertedAmount
                                }}
                            />
                        </div>
                    </GridCell>
                    {availableContracts.length > 1 &&
                        <GridCell span={12}>
                            <List>
                                {availableContracts.map((contract, index) => {
                                    return (
                                        <li className="form-fields-list__radio-item" key={index}>
                                            <RadioField
                                                index={index}
                                                fieldName={contract.id}
                                                fieldValue={contract.id}
                                                fieldLabel={getContractName(contract)}
                                                selectedValue={contractId}
                                                onChange={() => onChangeContractSelection(contract)}
                                            />
                                        </li>
                                    )
                                })}
                            </List>
                        </GridCell>
                    }
                    <GridCell span={12}>
                        <GridInner>
                            {(hasPaymentCard || data.conversionType !== DigitalStampConversionType.PaymentCard) &&
                                <GridCell span={getSpan()}>
                                    <Button
                                        unelevated
                                        onClick={() => onSaveDigitalStamps(false)}
                                    >
                                        <FormattedMessage id="DigitalStampsClient.Dialog.Button.Confirm" />
                                    </Button>
                                </GridCell>
                            }
                            {data.conversionType === DigitalStampConversionType.PaymentCard &&
                                <GridCell span={getSpan()}>
                                    <Button
                                        unelevated
                                        onClick={() => onSaveDigitalStamps(true)}
                                    >
                                        <FormattedMessage id="DigitalStampsClient.Dialog.Button.ToBalance" />
                                    </Button>
                                </GridCell>
                            }
                            <GridCell span={getSpan()}>
                                <Button
                                    unelevated
                                    onClick={onClose}
                                >
                                    <FormattedMessage id="DigitalStampsClient.Dialog.Button.Cancel" />
                                </Button>
                            </GridCell>
                        </GridInner>
                    </GridCell>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

DigitalStampsClientDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    contracts: PropTypes.array,
    data: PropTypes.object.isRequired
}

export default DigitalStampsClientDialog;