import { SummerCampaignPrize } from "../const/summerCampaign";

import Tub from "../images/summer_campaign/tub_thumbnail_small.png";
import FreeCoffeeYear from "../images/summer_campaign/coffee_year_thumbnail_small.png";
import Beans from "../images/summer_campaign/coffee_beans_thumbnail_small.png";
import Cup from "../images/summer_campaign/coffee_cup_thumbnail_small.png";
import FreeCoffee from "../images/summer_campaign/coffee_thumbnail_small.png";
import Money from "../images/summer_campaign/money_thumbnail_small.png";
import TryAgain from "../images/summer_campaign/wheel_thumbnail.png";

export const isMoneyPrize = (type) => {
    return type === SummerCampaignPrize.Money2 ||
        type === SummerCampaignPrize.Money5 ||
        type === SummerCampaignPrize.Money10 ||
        type === SummerCampaignPrize.Money20 ||
        type === SummerCampaignPrize.Money100;
};

export const getPrizeTypeByXc = (typeXc) => {
    switch (typeXc) {
        case "LOTTERY_PRIZE.NO_WIN":
            return SummerCampaignPrize.NoWin;
        case "LOTTERY_PRIZE.FREE_SPIN":
            return SummerCampaignPrize.TryAgain;
        case "LOTTERY_PRIZE.COFFEE_COUPONS":
            return SummerCampaignPrize.FreeCoffee;
        case "LOTTERY_PRIZE.ALEXELA_GIFT_CARD_2":
            return SummerCampaignPrize.Money2;
        case "LOTTERY_PRIZE.ALEXELA_GIFT_CARD_5":
            return SummerCampaignPrize.Money5;
        case "LOTTERY_PRIZE.ALEXELA_GIFT_CARD_10":
            return SummerCampaignPrize.Money10;
        case "LOTTERY_PRIZE.ALEXELA_GIFT_CARD_20":
            return SummerCampaignPrize.Money20;
        case "LOTTERY_PRIZE.COFFEE_BEANS":
            return SummerCampaignPrize.Beans;
        case "LOTTERY_PRIZE.ART_COFFEE_CUPS":
            return SummerCampaignPrize.Cup;
        case "LOTTERY_PRIZE.FREE_COFFEE_FOR_A_YEAR":
            return SummerCampaignPrize.FreeCoffeeYear;
        case "LOTTERY_PRIZE.ALEXELA_GIFT_CARD_100":
            return SummerCampaignPrize.Money100;
        default:
            return SummerCampaignPrize.NoWin;
    }
}

export const isRedirectablePrize = (type) => {
    return type !== SummerCampaignPrize.TryAgain && type !== SummerCampaignPrize.NoWin && !isMoneyPrize(type);
}

export const PrizeImageMap = {
    [SummerCampaignPrize.Tub]: Tub,
    [SummerCampaignPrize.Beans]: Beans,
    [SummerCampaignPrize.Cup]: Cup,
    [SummerCampaignPrize.FreeCoffee]: FreeCoffee,
    [SummerCampaignPrize.FreeCoffeeYear]: FreeCoffeeYear,
    [SummerCampaignPrize.Money2]: Money,
    [SummerCampaignPrize.Money5]: Money,
    [SummerCampaignPrize.Money10]: Money,
    [SummerCampaignPrize.Money20]: Money,
    [SummerCampaignPrize.Money100]: Money,
    [SummerCampaignPrize.TryAgain]: TryAgain
};