import React from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Grid, GridCell } from "@rmwc/grid";
import BonuscardTransactionContainer from "../components/transactions/BonuscardTransactionContainer";
import CardTransactionContainer from "../components/transactions/CardTransactionContainer";
import Tabs from "../components/common/Tabs";

const Transactions = () => {
    const intl = useIntl();
    const location = useLocation();

    const defaultTabIndex = !!location?.state?.params?.paymentCardTab ? 1 : 0;

    return (
        <Grid>
            <GridCell span={12}>
                <Tabs
                    defaultTabIndex={defaultTabIndex}
                    items={[
                        {
                            contents: <BonuscardTransactionContainer />,
                            label: intl.formatMessage({ id: "Transactions.BonuscardView" }),
                        },
                        {
                            contents: <CardTransactionContainer filter={location?.state?.params?.filter} />,
                            label: intl.formatMessage({ id: "Transactions.CardView" }),
                        },
                    ]}
                />
            </GridCell>
        </Grid>
    );
}

export default Transactions;