export default function reducer(state={
    data: null,
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case "UPSERT_APP_CAMPAIGNS_PENDING": {
            return {...state, fetching: true, fetched: false, error: null};
        }
        case "UPSERT_APP_CAMPAIGNS_REJECTED": {
            return {...state, fetching: false, error: action.payload.response?.data?.errorMessage ?? null, fetched: false};
        }
        case "UPSERT_APP_CAMPAIGNS_FULFILLED": {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}