import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import Loader from "../common/Loader";
import {
    getDigitalStampsTransactions,
    getDigitalStampsTransactionsForClient
} from "../../actions/digitalStampsActions";
import EeIdCodeValidator from "../../validators/EeIdCodeValidator";

const DATE_SPAN = 2;
const ACTION_SPAN = 6;
const AMOUNT_SPAN = 2;
const USER_SPAN = 2;

const CUSTOMER_SERVICE = "Customer.Service";

const DigitalStampsHistory = ({ personalCode = "", isClient = false }) => {
    const history = useSelector(state => state.digitalStampsTransactions);
    const convertStamps = useSelector(state => state.convertStamps);
    const summary = useSelector(state => state.digitalStampsSummary);
    const ui = useSelector(state => state.ui);

    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();
    const intl = useIntl();
    const personalCodeValidator = new EeIdCodeValidator();

    const getTransactions = useCallback(() => {
        if (isClient) {
            if (!personalCodeValidator.validate(personalCode)) return;

            dispatch(getDigitalStampsTransactionsForClient({
                dateTo: moment(new Date()).format("YYYY-MM-DD"),
                personalCode
            }))
        } else {
            dispatch(getDigitalStampsTransactions({
                dateTo: moment(new Date()).format("YYYY-MM-DD")
            }));
        }
    }, []);

    useEffect(() => {
        if (summary.fetched) {
            getTransactions();
        }
    }, [summary.fetched, summary.data, getTransactions]);

    useEffect(() => {
        if (convertStamps.fetched && !convertStamps.fetching && !convertStamps.error) {
            getTransactions();
        }
    }, [convertStamps.fetched, convertStamps.fetching, convertStamps.error, getTransactions]);

    const getAmountString = (item) => {
        return item.amount > 0 ? `+${item.amount}` : item.amount;
    }

    const getTypeString = (type) => {
        return type ? intl.formatMessage({ id: `DigitalStamps.TransactionType.${type}` }) : "-";
    }

    const getUserName = (name) => {
        return name === CUSTOMER_SERVICE
            ? intl.formatMessage({ id: `DigitalStamps.${CUSTOMER_SERVICE}` })
            : name;
    }

    const renderTitle = () => {
        return (
            <GridCell span={12} className={classNames("digital-stamps-history--title", {
                "digital-stamps-history--title-mobile": ui.isMobileOrTabletView
            })} onClick={() => setIsOpen(!isOpen)}>
                <h2><FormattedMessage id="DigitalStamps.Title.History" /></h2>
                <i className={classNames("icon icon-angle-right", {
                    "open": isOpen
                })} />
            </GridCell>
        );
    }

    if (history.fetching) {
        return (
            <GridCell span={12}>
                <Loader type={Loader.TYPE_CENTER} />
            </GridCell>
        )
    }

    if (history.error) {
        return (
            <>
                {renderTitle()}
                <GridCell span={12}>
                    <Typography use="body1">
                        <FormattedMessage id="DigitalStamps.History.LoadingFailed" />
                    </Typography>
                </GridCell>
            </>
        )
    }

    return (
        <div className="digital-stamps-history">
            {renderTitle()}
            <div className={`digital-stamps-history--container ${isOpen ? "open" : ""}`}>
                {!history.data || history.data.length === 0 ?
                    <div className={`digital-stamps-history--container ${isOpen ? "open" : ""} `}>
                        <GridCell span={12} className="p-10">
                            <Typography use="body1">
                                <FormattedMessage id="DigitalStamps.History.NoItems" />
                            </Typography>
                        </GridCell>
                    </div> :
                    <>
                        {!ui.isMobileOrTabletView &&
                            <GridCell span={12} className="digital-stamps-history--heading">
                                <GridInner>
                                    <GridCell span={DATE_SPAN} className="digital-stamps-history--heading-item">
                                        <FormattedMessage id="DigitalStamps.History.Date" />
                                    </GridCell>
                                    <GridCell span={ACTION_SPAN} className="digital-stamps-history--heading-item">
                                        <FormattedMessage id="DigitalStamps.History.Action" />
                                    </GridCell>
                                    <GridCell span={AMOUNT_SPAN} className="digital-stamps-history--heading-item">
                                        <FormattedMessage id="DigitalStamps.History.Amount" />
                                    </GridCell>
                                    <GridCell span={USER_SPAN} className="digital-stamps-history--heading-item">
                                        <FormattedMessage id="DigitalStamps.History.User" />
                                    </GridCell>
                                </GridInner>
                            </GridCell>
                        }
                        <GridCell span={12} className={classNames("digital-stamps-history--scrollable", {"noscroll": ui.isMobileOrTabletView})}>
                            {history.data.map((item, index) =>
                                <GridInner key={index} className={classNames("digital-stamps-history--row", {
                                    "mobile": ui.isMobileOrTabletView
                                })}>
                                    {ui.isMobileOrTabletView &&
                                        <>
                                            <GridCell desktop={DATE_SPAN} phone={12}>
                                                <span className="text-bold">{`${intl.formatMessage({ id: "DigitalStamps.History.Date" })}: `}</span>
                                                <span>{moment(item.transactionDate).format("DD.MM.YYYY")}</span>
                                            </GridCell>
                                            <GridCell desktop={ACTION_SPAN} phone={12}>{getTypeString(item.transactionType)}</GridCell>
                                            <GridCell desktop={AMOUNT_SPAN} phone={12}>
                                                <span className="text-bold">{`${intl.formatMessage({ id: "DigitalStamps.History.Amount" })}: `}</span>
                                                <span>{getAmountString(item)}</span>
                                            </GridCell>
                                            <GridCell desktop={USER_SPAN} phone={12}>
                                            <span className="text-bold">{`${intl.formatMessage({ id: "DigitalStamps.History.User" })}: `}</span>
                                                <span>{getUserName(item.userName)}</span>
                                            </GridCell>
                                        </>
                                    }
                                    {!ui.isMobileOrTabletView &&
                                        <>
                                            <GridCell desktop={DATE_SPAN} phone={12}>{moment(item.transactionDate).format("DD.MM.YYYY")}</GridCell>
                                            <GridCell desktop={ACTION_SPAN} phone={12}>{getTypeString(item.transactionType)}</GridCell>
                                            <GridCell desktop={AMOUNT_SPAN} phone={12}>{getAmountString(item)}</GridCell>
                                            <GridCell desktop={USER_SPAN} phone={12}>{getUserName(item.userName)}</GridCell>
                                        </>
                                    }
                                </GridInner>
                            )}
                        </GridCell>
                    </>
                }
            </div>
        </div>
    )
}

export default DigitalStampsHistory;