import { utils, writeFile } from 'xlsx';

export default function exportToExcel (columns, data, filename) {
    const columnInfo = columns.reduce(
        (acc, column) => {
            const headerLabel = column.title || column.field;
            acc.header.push(headerLabel);
            acc.map[column.field] = headerLabel;
            return acc;
        },
        { map: {}, header: [] }
    );

    // Translate the keys of each row object to the new header label
    // Turning `{a: 1, b: 2, c: 3}` into `{"Nice name for a": 1, "Nice name for b": 2}`.
    const mappedData = data.map(row =>
        Object.entries(row).reduce((acc, [key, value]) => {
            if (columnInfo.map[key]) {
                acc[columnInfo.map[key]] = value;
            }
            return acc;
        }, {})
    );

    const ws = utils.json_to_sheet(mappedData, {
        header: columnInfo.header,
    });
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, filename);
}
