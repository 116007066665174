import BusinessApi from "./BusinessApi";
import auth from '../auth/authenticate';
import { GetCurrentCountryCode } from "../helpers/AlternativeDesign";

const getBaseURL = (countryCode = GetCurrentCountryCode()) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return `/api/${accountId}/${roleId}/locale/${countryCode}`;
};

export const fetchTranslations = (countryCode = GetCurrentCountryCode()) => {
    const url = `/api/locale/${countryCode}/translations`;
    return BusinessApi.get(url);
};

export const fetchGroups = (countryCode) => {
    const url = `${getBaseURL(countryCode)}/groups`;
    return {
        type: 'FETCH_GROUPS',
        payload: BusinessApi.get(url)
    };
};

export const fetchGroupContent = (countryCode, group) => {
    const url = `${getBaseURL(countryCode)}/groupcontent?group=${group}`;
    return {
        type: 'FETCH_GROUP_CONTENT',
        payload: BusinessApi.get(url)
    };
};

export const putTranslation = (model) => {
    const url = `${getBaseURL(model?.countryCode)}/locale`;
    return {
        type: 'PUT_LOCALE',
        payload: BusinessApi.put(url, model)
    };
};

export const deleteTranslation = (countryCode, model) => {
    const url = `${getBaseURL(countryCode)}/delete`;
    return {
        type: 'DELETE_LOCALE',
        payload: BusinessApi.post(url, model)
    };
};

export const fetchClearCache = (countryCode) => {
    const url = `${getBaseURL(countryCode)}/clearcache`;
    return {
        type: 'FETCH_CLEAR_CACHE',
        payload: BusinessApi.get(url)
    };
};

export const fetchUnfinishedLocale = (countryCode, compareLocales) => {
    const url = `${getBaseURL(countryCode)}/unfinished`;
    return {
        type: 'FETCH_LOCALE_UNFINISHED',
        payload: BusinessApi.get(url, {
            params: {
                compareLocales: compareLocales
            }
        })
    };
};

export const fetchSyncDataToTest = (countryCode) => {
    const url = `${getBaseURL(countryCode)}/syncdata`;
    return {
        type: 'FETCH_LOCALE_SYNC_TEST',
        payload: BusinessApi.get(url)
    };
};

export const fetchSearchResults = (countryCode, phrase) => {
    const url = `${getBaseURL(countryCode)}/search?phrase=${phrase}`;
    return {
        type: 'FETCH_SEARCH_RESULTS',
        payload: BusinessApi.get(url)
    };
}