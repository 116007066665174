import React from "react";
import { fetchAllCardTransactions } from "../../actions/transactionActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "@rmwc/button";
import exportToExcel from "../common/ExcelExport";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";

class CardTransactionsExport extends React.Component {
    static MAX_TRANSACTIONS = 5000;

    constructor(props) {
        super(props);
        this.export = this.export.bind(this);
        this.fetchAllTransactions = this.fetchAllTransactions.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.exportTransactionsFetching && this.props.exportTransactionsFetched && prevProps.exportTransactionsFetching) {
            this.export();
        }
    }

    fetchAllTransactions() {
        let filter = this.props.filter;
        this.props.fetchAllCardTransactions(
            CardTransactionsExport.MAX_TRANSACTIONS,
            filter.startDate.format("YYYY-MM-DD"),
            filter.endDate.format("YYYY-MM-DD"),
            filter.card,
            filter.servicePoint === undefined || filter.servicePoint == null ? "" : filter.servicePoint.value
        );
    }

    get columns() {
        return [
        {
            field: 'transactionDate',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Date" }),
        },
        {
            field: 'transactionTime',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Time" }),
        },
        {
            field: 'cardNumber',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Card" }),
        },
        {
            field: 'cardName',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.CardName" }),
        },
        {
            field: 'contractNumber',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.ContractNumber" }),
        },
        {
            field: 'receiptNumber',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.ReceiptNumber" }),
        },
        {
            field: 'systemTraceAuditNumber',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.StanNr" }),
        },
        {
            field: 'invoiceNumber',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.InvoiceNumber" }),
        },
        {
            field: 'station',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.ServicePoint" }),
        },
        {
            field: 'productName',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Product" }),
        },
        {
            field: 'quantity',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Quantity" }),
        },
        {
            field: 'productUnit',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Unit" }),
        },
        {
            field: 'stationPrice',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.StationPrice" }),
        },
        {
            field: 'unitPrice',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.UnitPrice" }),
        },
        {
            field: 'buyPrice',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.BuyPrice" }),
        },
        {
            field: 'buyPriceVat',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.Vat" }),
        },
        {
            field: 'discountAmount',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.DiscountAmount" }),
        },
        {
            field: 'odometerValue',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.OdometerValue" }),
        },
        {
            field: 'driverName',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.DriverName" }),
        },
        {
            field: 'carNumber',
            title: this.props.intl.formatMessage({ id: "Transactions.Table.CarNumber" }),
        },
    ]};

    export() {
        const transactions = this.props.exportTransactions.map((transaction) => {
            return transaction.products.map((product) => ({
                transactionDate: moment(transaction.transactionDate).format("DD.MM.YYYY"),
                transactionTime: moment(transaction.transactionDate).format("HH:mm"),
                cardNumber: transaction.cardNumber,
                cardName: transaction.cardName,
                station: transaction.station,
                sum: transaction.sum,
                contractNumber: transaction.contractNumber,
                receiptNumber: transaction.receiptNumber,
                systemTraceAuditNumber: transaction.systemTraceAuditNumber,
                invoiceNumber: transaction.invoiceNumber,
                productName: product.productName,
                productUnit: product.productUnit,
                quantity: product.quantity,
                buyPrice: product.buyPrice,
                stationPrice: product.stationPrice,
                discountAmount: product.discountAmount,
                buyPriceVat: product.buyPriceVat,
                unitPrice: product.unitPrice,
                odometerValue: product.odometerValue,
                driverName: product.driverName,
                carNumber: product.carNumber,
            }));
        }).flat();

        exportToExcel(this.columns, transactions, "transactions.xlsx");
    }

    render() {
        return (
            <Button
                className="hidden-mobile mdc-button--outlined filter-form__export"
                outlined
                onClick={this.fetchAllTransactions}
            >
                <i className="icon-download" />
                <FormattedMessage id="Transactions.Export" />
            </Button>
        );
    }
}

function mapStateToProps(state) {
    return {
        exportTransactionsFetching: state.allCardTransactions.fetching,
        exportTransactionsFetched: state.allCardTransactions.fetched,
        exportTransactions: state.allCardTransactions.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchAllCardTransactions
        },
        dispatch
    );
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(CardTransactionsExport)
);