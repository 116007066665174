import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import BaseFormComponent from "../common/BaseFormComponent";
import { hasGasoPermission, putContract } from "../../actions/contractActions";
import { fetchConsumptionLocations } from "../../actions/consumptionlocationActions";
import { fetchCompetitors, fetchGasCompetitors } from "../../actions/competitorsActions";
import { fetchElectricityProducts, fetchGasProducts } from "../../actions/productsActions";
import { fetchAccount } from "../../actions/accountActions";
import { GridCell, Grid, GridInner } from "@rmwc/grid";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../common/Loader";
import LinkElement from "../common/LinkElement";
import axios from "axios";
import { Typography } from "@rmwc/typography";
import SelectAutoCompleteField from "../common/SelectAutoCompleteField";
import { Button } from "@rmwc/button";
import ConsumptionLocationSelect from "../ConsumptionLocations/ConsumptionLocationSelect";
import AlertMessage from "../common/AlertMessage";
import "../../styles/blocks/_contract.scss";
import "../../styles/components/_information.scss";
import { SendActionEvent } from "../../helpers/GoogleTagHelper";
import { Electricity } from "../../const/contractType";
import auth from "../../auth/authenticate";
import { IsLatvianWeb } from "../../helpers/AlternativeDesign";
import TextFieldGroup from "../common/TextFieldGroup";
import { Cashbackworld } from "../../const/campaignType";
import ProductSelection, { getFilterFunctions } from "../../components/Contract/ProductSelection";
import ProductType from "../../const/productType";
import { isCampaignLogin, removeCampaignLoginFlag } from "../../helpers/campaignLoginHelper";
import { Redirect } from "react-router-dom";
import { ServicePath } from "../../const/routes";
import { trackEvent } from "../../appInsights";
import AddressFormExtended from "../AddressForm/AddressFormExtended";
import { areEqual } from "../../helpers/objectHelper";
import { postActionLog } from "../../actions/actionLogActions";
import { ActionLogType } from "../../const/actionLogType";
import DebtNotice from "../../components/common/DebtNotice";

class Contract extends BaseFormComponent {
    static fieldProduct = "productId";
    static fieldLocation = "location";
    static fieldCompetitorId = "competitorId";
    static fieldCompetitorName = "competitorName";
    static fieldCompetitor = "competitor";
    static fieldContractType = "contractType";
    static fieldPromotionCodeInfo = "promotionCodeInfo";

    constructor(props) {
        super(props);
        this.state = {
            [Contract.fieldProduct]: null,
            [Contract.fieldCompetitorId]: null,
            [Contract.fieldCompetitorName]: null,
            [Contract.fieldCompetitor]: null,
            [Contract.fieldContractType]: props.contractType,
            [Contract.fieldPromotionCodeInfo]: "",
            [Contract.fieldTOS]: false,
            [Contract.fieldLocation]: [],
            selectedProduct: {},
            productCodeFromUrl: props.productCode,
            productFilter: "universal_price",
            green: false,
            showErrors: false,
            address: {},
            isAgent: auth.isAgent(),
            hasSentAnalyticsData: false,
            precontractProductId: null,
            precontractLocations: [],
            showPenaltyProductAlert: false,
            filterFunctions: getFilterFunctions(props.contractType),
            userCrmId: auth.getUserId(),
            isManualAddressInput: false
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.consumptionLocationChanged = this.consumptionLocationChanged.bind(this);
        this.cancelPreviousRequest = this.cancelPreviousRequest.bind(this);
        this.consumptionLocationChangedCallback = this.consumptionLocationChangedCallback.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.initProducts = this.initProducts.bind(this);
        this.isCompetitorSelectionNotRequired = this.isCompetitorSelectionNotRequired.bind(this);
        this.onCompetitorChange = this.onCompetitorChange.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.onPromotionCodeInfoChange = this.onPromotionCodeInfoChange.bind(this);
        this.getCampaignCode = this.getCampaignCode.bind(this);

        this.isElectricity = this.isElectricity.bind(this);
        this.showPenaltyProductAlert = this.showPenaltyProductAlert.bind(this);

        this.getValidConsumptionLocations = this.getValidConsumptionLocations.bind(this);
        this.getProductFromUrl = this.getProductFromUrl.bind(this);

        this.changeFilter = this.changeFilter.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);

        this.uiErrorsRef = React.createRef();
        this.cancelProductsSource = null;
        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    componentDidMount() {
        this.props.fetchConsumptionLocations();

        if (!this.isElectricity() && !this.props.competitorsGasFetching && !this.props.competitorsGasFetched) {
            this.props.fetchGasCompetitors();
        }

        if (IsLatvianWeb() && !this.props.gasoPermissionFetched) {
            this.props.hasGasoPermission();
        }

        if (IsLatvianWeb() && isCampaignLogin()) {
            const campaignInfo = auth.getLoginCampaignInfo();

            this.setState({
                productCodeFromUrl: campaignInfo.campaignItem
            });
        }

        this.props.fetchAccount()
            .then(({ value }) => this.setState({ address: value.data.address }));
    }

    componentDidUpdate(prevProps) {
        if (!areEqual(prevProps.locations, this.props.locations)) {
            const precontract = (this.props.locations.precontracts || []).find(x => x.type === this.props.contractType);
            const location = (this.props.locations.locations || []).find(x => precontract && x.id === precontract.consumptionLocations[0]);

            if (precontract) {
                this.setState({
                    [Contract.fieldProduct]: precontract.productCode,
                    [Contract.fieldLocation]: [precontract.consumptionLocations[0]],
                    [Contract.fieldCompetitorId]: location.competitorId,
                    [Contract.fieldCompetitorName]: location.competitorName,
                    [Contract.fieldCompetitor]: location.competitorId ?
                        {
                            value: location.competitorId,
                            label: location.competitorName
                        } : null,
                    precontractProductId: precontract.productCode,
                    precontractLocations: precontract.consumptionLocations
                }, this.initProducts);
            }

            if (precontract === undefined && this.state.productCodeFromUrl) {
                const validLocations = this.getValidConsumptionLocations();
                if (validLocations.length > 0) {
                    const location = validLocations[0];

                    this.setState({
                        [Contract.fieldLocation]: [location.id]
                    }, this.consumptionLocationChanged(location.id));
                }
            }
        }

        if (!areEqual(prevProps.account.address, this.props.account.address)) {
            this.setState({
                address: this.props.account.address
            });
        }

        // Electricity
        if (!areEqual(prevProps.products, this.props.products)
            && this.props.products && this.props.products.length > 0
            && this.state[Contract.fieldLocation]) {
            const productFromUrl = this.getProductFromUrl();

            if (productFromUrl !== undefined) {
                this.setProductSectionWhenNoPreContract(productFromUrl, true);
            } else {
                const precontract = (this.props.locations.precontracts || []).find(x => x.type === this.props.contractType);

                const countOfDifferentPopularityOrderValues = [...this.props.products]
                    .map(x => x.popularityOrder)
                    .filter((value, index, array) => array.indexOf(value) === index) //  select distinct
                    .length;

                if (precontract != null) {
                    let selectedProduct = this.props.products.find(x => x.id === precontract.productCode)

                    if (selectedProduct !== undefined) {
                        const isSelectedPenaltyProduct = selectedProduct.fineAmount > 0;
                        const deselectProduct = isSelectedPenaltyProduct && this.state[Contract.fieldLocations].length > 1;

                        for (const key in this.state.filterFunctions) {
                            if (this.state.filterFunctions[key](selectedProduct)) {
                                this.setState({
                                    productFilter: key,
                                    showPenaltyProductAlert: this.showPenaltyProductAlert(key),
                                    green: precontract != null ? precontract.hasActiveGreenEnergyUsage : false,
                                    [Contract.fieldProduct]: deselectProduct ? null : selectedProduct.id
                                });
                                break;
                            }
                        }
                    }
                } else if (countOfDifferentPopularityOrderValues > 1) {
                    let mostPopularProduct = [...this.props.products]
                        .sort((a, b) => a.popularityOrder - b.popularityOrder)[0];

                    if (mostPopularProduct === undefined) return;

                    this.setProductSectionWhenNoPreContract(mostPopularProduct, false);
                } else {
                    this.setState({
                        showPenaltyProductAlert: this.showPenaltyProductAlert(this.state.productFilter),
                        green: false
                    });
                }
            }
        }

        // Gas
        if (!areEqual(prevProps.productsGas, this.props.productsGas)
            && this.props.productsGas && this.props.productsGas.length > 0
            && this.state[Contract.fieldLocation]) {
            const productFromUrl = this.getProductFromUrl();
            if (productFromUrl !== undefined) {
                this.setProductSectionWhenNoPreContract(productFromUrl, true);
            } else {
                const precontract = (this.props.locations.precontracts || []).find(x => x.type === this.props.contractType);

                if (precontract != null) {
                    const selectedProduct = this.props.productsGas.find(x => x.id === precontract.productCode)
                    for (const key in this.state.filterFunctions) {
                        if (this.state.filterFunctions[key](selectedProduct)) {
                            this.setState({
                                productFilter: key,
                                showPenaltyProductAlert: this.showPenaltyProductAlert(key),
                                [Contract.fieldProduct]: selectedProduct.id
                            });
                            break;
                        }
                    }
                }
            }
        }
    }

    setProductSectionWhenNoPreContract(selectedProduct, isSelected) {
        for (const key in this.state.filterFunctions) {
            if (this.state.filterFunctions[key](selectedProduct)) {
                this.setState({
                    productFilter: key,
                    showPenaltyProductAlert: this.showPenaltyProductAlert(key),
                    green: false,
                    [Contract.fieldProduct]: isSelected ? selectedProduct.id : null
                });
                break;
            }
        }
    }

    getValidConsumptionLocations() {
        return (this.props.locations.locations || [])
            .filter(location => location.type === this.props.contractType
                && !location.isInContract
                && location.canStartNewContract);
    }

    getProductFromUrl() {
        if (this.state.productCodeFromUrl) {
            return this.props.contractType === Electricity
                ? this.props.products.find(x => x.code === this.state.productCodeFromUrl)
                : this.props.productsGas.find(x => x.code === this.state.productCodeFromUrl);
        }

        return undefined;
    }

    get validatorErrors() {
        let errors = this.validator.getErrorMessages();
        if (!this.state[Contract.fieldProduct]) {
            errors["no_products"] = this.props.intl.formatMessage({ id: "Contract.Error.Product" });
        }
        if (!this.state[Contract.fieldLocation] || this.state[Contract.fieldLocation].length < 1) {
            errors["no_locations"] = this.props.intl.formatMessage({ id: "Contract.Error.Locations" });
        }
        if (!this.state.address.postalCode) {
            errors["no_address"] = this.props.intl.formatMessage({ id: "Contract.Error.Address" });
        }
        return errors;
    }

    onSubmit() {
        const trackedEventName = this.isElectricity()
            ? "electricityContractOnSubmitClicked"
            : "gasContractOnSubmitClicked";

        if (!this.validator.allValid()
            || !this.state[Contract.fieldProduct]
            || !this.state[Contract.fieldLocation]
            || this.state[Contract.fieldLocation].length < 1
            || (!this.state.address.postalCode)) {
            this.validator.showMessages();
            this.setState({ showErrors: true }, this.scrollToRef.bind(this, this.uiErrorsRef));

            trackEvent(trackedEventName, {
                userCrmId: this.state.userCrmId,
                event: "validation failed"
            });

            return;
        }

        this.setState({ showErrors: false });
        let products = this.isElectricity() ? this.props.products : this.props.productsGas;
        const selectedProduct = products.find(product => product.id === this.state[Contract.fieldProduct]);
        let data = this.getData();

        if (this.isCompetitorSelectionNotRequired()) {
            data[Contract.fieldCompetitorId] = null;
            data[Contract.fieldCompetitorName] = null;
        }

        data['address'] = {
            LegalAddressCountry: IsLatvianWeb() ? "Latvija" : "Eesti", // TODO: this is a simplification, but it has to do for now
            LegalAddressCity: this.state.address.city,
            LegalAddressState: this.state.address.county,
            LegalAddressStreet: this.state.address.street,
            LegalAddressCode: this.state.address.postalCode,
            LegalAddressHouseNo: this.state.address.houseNr,
            LegalAddressAppartmentNo: this.state.address.apartmentNr,
            LegalAddressParish: this.state.address.parish
        };

        data["greenEnergy"] = this.state.green;

        data["sendInvoiceByRegularMail"] = auth.getRoleHasNoEmail() && this.state.isAgent;

        trackEvent(trackedEventName, {
            userCrmId: this.state.userCrmId,
            event: "pre submit",
            data: data
        });

        this.props.putContract(data).then(() => {
            SendActionEvent(this.isElectricity() ? "ElectricityCreateOffer" : "GasCreateOffer");

            trackEvent(trackedEventName, {
                userCrmId: this.state.userCrmId,
                event: "pre-contract submit successful"
            });

            this.props.postActionLog({
                action: ActionLogType.PackageSelected,
                additionalData: JSON.stringify({
                    productId: data.productId,
                    productCode: selectedProduct.code,
                    productName: selectedProduct.name,
                    consumptionLocationId: data.location[0]
                })
            });

            this.props.goNext(selectedProduct);
        }).catch(err => {
            trackEvent(trackedEventName, {
                userCrmId: this.state.userCrmId,
                event: "pre-contract submit failed",
                error: {
                    code: err.code,
                    message: err.message,
                    response: err.response?.data
                }
            });
        });
    }

    initProducts() {
        if (this.state[Contract.fieldLocation].length > 0) {
            this.consumptionLocationChangedCallback();
        }
    }

    cancelPreviousRequest() {
        if (this.cancelProductsSource) {
            this.cancelProductsSource.cancel("User cancelled request!");
        }
    }

    consumptionLocationChanged(item) {
        this.cancelPreviousRequest();

        let argumentsWithCallback = Array.prototype.slice.call(arguments);
        argumentsWithCallback.push(this.consumptionLocationChangedCallback(item));
    }

    consumptionLocationChangedCallback(item) {
        let locationArray = item !== undefined
            ? [item]
            : this.state[Contract.fieldLocation]

        if (locationArray.length > 0) {
            this.cancelProductsSource = axios.CancelToken.source();
            let precontractProductId = null;

            if (this.state.precontractLocations !== null
                && this.state.precontractLocations.length === locationArray.length
                && this.state.precontractLocations.every(x => locationArray.some(y => y === x))) {
                precontractProductId = this.state.precontractProductId;
            }

            if (this.isElectricity()) {
                this.props.fetchElectricityProducts(locationArray, this.cancelProductsSource.token, precontractProductId);
            } else {
                this.props.fetchGasProducts(locationArray, this.cancelProductsSource.token, precontractProductId);
            }

            this.setState({
                [Contract.fieldLocation]: locationArray,
                [Contract.fieldProduct]: null,
                showPenaltyProductAlert: this.showPenaltyProductAlert(this.state.productFilter)
            });

            trackEvent("consumptionLocationChanged", {
                crmId: this.state.userCrmId,
                selectedLocationId: item
            });
        }
    }

    updateAddress(address) {
        this.setState({ address });
    }

    renderAddressForm() {
        return (
            <GridCell span={12}>
                <Typography
                    className="mb-25"
                    use="headline3"
                >
                    <FormattedMessage id="Contract.Address.Title" />
                </Typography>

                <Typography
                    className="mb-15"
                    use="body1"
                >
                    <FormattedMessage id="Contract.Address.Info" />
                </Typography>

                <AddressFormExtended
                    className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
                    rules="required"
                    onChange={this.updateAddress}
                    address={this.state.address}
                    validator={this.validator}
                    isManualInsert={this.state.isManualAddressInput}
                />
            </GridCell>
        );
    }

    get isSelectedPenaltyProduct() {
        const { products } = this.props;
        const selectedProduct = products.find(product => product.id === this.state[Contract.fieldProduct]);

        const isSelectedProductFixedPriceProductWithFine = !!selectedProduct &&
            [selectedProduct].some(this.state.filterFunctions["fixed_price_fine"]);

        return isSelectedProductFixedPriceProductWithFine;
    }

    get isSelectedDynamicUniversalProduct() {
        const { products } = this.props;
        const selectedProduct = products.find(product => product.id === this.state[Contract.fieldProduct]);

        return !!selectedProduct && selectedProduct.type === ProductType.UniversalDynamicPrice;
    }

    renderLocations() {
        const locationsList = (this.props.locations.locations || [])
            .filter(location => location.type === this.props.contractType /*&& !location.isInContract */ && location.canStartNewContract)
            .map((item, i) =>
                <ConsumptionLocationSelect key={item.id}
                    onChange={this.consumptionLocationChanged}
                    fieldName={Contract.fieldLocation}
                    index={item.id}
                    location={item}
                    selectedValue={this.state[Contract.fieldLocation]}
                    noAddressText={<FormattedMessage id="Contract.Address.Unknown" />}
                    isPenaltyProduct={this.isSelectedPenaltyProduct}
                    isDynamicUniversalProduct={this.isSelectedDynamicUniversalProduct}
                />
            );
        return (
            <GridCell span={12}>
                <Typography
                    className="mb-25"
                    use="headline3"
                >
                    <FormattedMessage id="Contract.Locations.Title" />
                </Typography>
                <Typography
                    className="mb-15"
                    use="body1"
                >
                    <FormattedMessage id="Contract.Locations.MoreInfoNotice"
                        values={{
                            link: <LinkElement
                                href={this.props.intl.formatMessage({ id: "Contract.Locations.Elering.Url" })}
                                target="_blank">
                                <FormattedMessage id="Contract.Locations.Elering" />
                            </LinkElement>
                        }} />
                </Typography>
                {this.props.locationsFetched && locationsList.length < 1 &&
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="Contract.Locations.None"
                            values={{
                                link: <a
                                    href={this.props.intl.formatMessage({
                                        id: "Contract.Locations.None.Link"
                                    })}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FormattedMessage id="Contract.Locations.None.Link" />
                                </a>
                            }} />}
                    />
                }
                <div className="fields-group__list fields-group__list--text">
                    {this.props.locationsFetching ? <Loader type={Loader.TYPE_CENTER} /> : locationsList}
                </div>
            </GridCell>
        );
    }

    onProductChange(product) {
        if (!this.state.hasSentAnalyticsData) {
            SendActionEvent(this.isElectricity() ? "ElectricityProductSelection" : "GasProductSelection");
        }

        this.updateStateValues({
            [Contract.fieldProduct]: product !== null ? product.id : null,
            hasSentAnalyticsData: true
        });

        const trackedEvent = this.isElectricity()
            ? "electricityProductChange"
            : "gasProductChange";

        trackEvent(trackedEvent, {
            crmId: this.state.userCrmId,
            selectedProductCode: product.code
        });
    }

    isElectricity() {
        return this.props.contractType === Electricity;
    }

    showPenaltyProductAlert(tab) {
        return tab === "fixed_price_fine" && this.state[Contract.fieldLocation].length > 1;
    }

    changeFilter(tab) {
        this.setState({
            productFilter: tab,
            showPenaltyProductAlert: this.showPenaltyProductAlert(tab),
            [Contract.fieldProduct]: null
        });
    }

    toggleFilter(key) {
        this.setState({
            [key]: !this.state[key],
            [Contract.fieldProduct]: null
        });
    }

    toggleDialog(field) {
        this.setState({ [field]: !this.state[field] });
    }

    getCampaignCode() {
        const campaignInfoJson = localStorage.getItem("campaignInfo");
        const campaignInfo = !!campaignInfoJson && JSON.parse(campaignInfoJson);
        return !!campaignInfo && campaignInfo.campaignCode;
    }

    isPenaltyProduct(product) {
        return this.state.filterFunctions["fixed_price_fine"](product);
    }

    renderProductsBlock() {
        let products = this.isElectricity() ? this.props.products : this.props.productsGas;
        const fetching = this.isElectricity() ? this.props.productsFetching : this.props.productsGasFetching;
        const error = this.isElectricity() ? this.props.productsError : this.props.productsGasError;

        const location = (this.props.locations.locations || []).find(x => x.id === this.state[Contract.fieldLocation][0]);

        if (!fetching && products && location) {
            products = products.map(x => ({
                ...x,
                disabled: this.isPenaltyProduct(x) && !location.canStartNewPenaltyContract
            }));
        }

        return (
            <>
                <GridCell span={12}>
                    <Typography
                        use="headline3"
                        className="mb-15"
                    >
                        <FormattedMessage id="ContractProductChange.Product.Select.New" />
                    </Typography>
                </GridCell>

                {fetching
                    ? <GridCell span={12}><Loader type={Loader.TYPE_CENTER} /></GridCell>
                    : <>
                        <ProductSelection
                            onChange={this.onProductChange}
                            products={products}
                            selectedValue={this.state[Contract.fieldProduct]}
                            fieldName={Contract.fieldProduct}
                            onFilterToggle={this.toggleFilter}
                            onFilterChange={this.changeFilter}
                            contractType={this.props.contractType}
                            selectedProductFilter={this.state.productFilter}
                            isGreen={this.state.green}
                            productFromUrl={this.props.productCode}
                        />
                        {this.state.showPenaltyProductAlert &&
                            <GridCell span={12}>
                                <AlertMessage
                                    type={AlertMessage.TYPE_ALERT_NEUTRAL}
                                    isSmall={true}
                                    title={<FormattedMessage id="Contract.Product.Contact.Service" />}
                                />
                            </GridCell>
                        }
                    </>
                }

                <GridCell span={8}>
                    {error != null && this.errors(error)}
                </GridCell>
            </>
        );
    }

    isCompetitorSelectionNotRequired() {
        let selectedLocation = this.state[Contract.fieldLocation];
        let selectedNonCompetitorLocations = (this.props.locations.locations || []).filter(
            location =>
                selectedLocation.indexOf(location.id) > -1 && !location.hasCompetitorContract
        );

        return selectedLocation.length === selectedNonCompetitorLocations.length;
    }

    onCompetitorChange(item) {
        if (item) {
            this.setState({
                [Contract.fieldCompetitorId]: item.value,
                [Contract.fieldCompetitorName]: item.label,
                [Contract.fieldCompetitor]: item
            });
        } else {
            this.setState({
                [Contract.fieldCompetitorId]: null,
                [Contract.fieldCompetitorName]: null,
                [Contract.fieldCompetitor]: null
            });
        }
    }

    onPromotionCodeInfoChange(e) {
        let value = e.target.value;

        if (!!value) {
            this.setState({ [Contract.fieldPromotionCodeInfo]: value });
        } else {
            this.setState({ [Contract.fieldPromotionCodeInfo]: "" });
        }
    }

    renderAlexelaInfo() {
        return this.isElectricity()
            ? <Typography
                use="body1">
                <FormattedMessage id="Contract.Electricity.Info" />
            </Typography>
            :
            <Typography
                use="body1">
                <FormattedMessage id="Contract.Gas.Info" />
            </Typography>;
    }

    renderCompetitorSelection() {
        this.validator.purgeFields();
        if (this.isCompetitorSelectionNotRequired()) {
            return null;
        }

        const competitors = this.isElectricity() ? this.props.competitors : this.props.competitorsGas;
        const fetching = this.isElectricity() ? this.props.competitorsFetching : this.props.competitorsGasFetching;

        if (this.isElectricity()) {
            return null;
        }

        return (
            <>
                {this.isElectricity() ?
                    <GridCell span={8}>
                        <Typography
                            className="mt-20 mb-25"
                            use="headline3"
                        >
                            <FormattedMessage id="Contract.Electricity.Competitor.Title" />
                        </Typography>
                        <Typography
                            use="body1"
                        >
                            <FormattedMessage id="Contract.Electricity.Competitor.Details" />
                        </Typography>
                    </GridCell>
                    :
                    <GridCell span={8}>
                        <Typography
                            use="headline3"
                        >
                            <FormattedMessage id="Contract.Gas.Competitor.Title" />
                        </Typography>
                        <Typography
                            use="body1"
                        >
                            <FormattedMessage id="Contract.Gas.Competitor.Details" />
                        </Typography>
                    </GridCell>
                }
                <GridCell className="mb-60" desktop={6} tablet={5} phone={4} >
                    <SelectAutoCompleteField
                        validator={this.validator}
                        rules="required"
                        isLoading={fetching}
                        value={this.state[Contract.fieldCompetitor]}
                        onChange={this.onCompetitorChange}
                        field={Contract.fieldCompetitor}
                        label="Contract.Competitor.Select"
                        options={competitors} />
                </GridCell>
            </>
        );
    }

    renderCampaignExternalIdInput() {
        const campaignCode = this.getCampaignCode();

        return (!!campaignCode && campaignCode === Cashbackworld &&
            <GridCell span={12}>
                <Typography
                    className="mb-25 mt-15 mt-mobile-clear"
                    use="headline3"
                >
                    <FormattedMessage id="Contract.CampaignCodeInfo.Title" />
                </Typography>
                <GridInner>
                    <GridCell span={6}>
                        <TextFieldGroup
                            validator={this.validator}
                            rules="required|max:20"
                            value={this.state[Contract.fieldPromotionCodeInfo]}
                            onChange={this.onPromotionCodeInfoChange}
                            field={Contract.fieldPromotionCodeInfo}
                            label="Contract.CampaignCodeInfo"
                        />
                    </GridCell>
                    <GridCell span={6}></GridCell>
                </GridInner>
            </GridCell>);
    }

    render() {
        if (this.props.accountFetching) {
            return <Loader type={Loader.TYPE_CENTER} />;
        }
        if (!IsLatvianWeb() && (this.props.account.hasDebt)) {
            return <DebtNotice/>;
        }

        const hasAvailableLocations = this.getValidConsumptionLocations().length > 0;

        return (
            <Grid>
                {isCampaignLogin() && IsLatvianWeb() && this.props.gasoPermissionFetched && !this.props.gasoPermission?.hasPermission &&
                    <Redirect to={ServicePath} />
                }
                {isCampaignLogin() && !IsLatvianWeb() && ((this.props.locationsFetched && !hasAvailableLocations) || this.props.account.hasDebt) &&
                    <>
                        {removeCampaignLoginFlag()}
                        <Redirect to={ServicePath} />
                    </>
                }

                <GridCell span={8}>
                    {this.props.contractHasError && this.props.contractError && this.errors(this.props.contractError, "Contract.Error.Create")}
                    {this.props.contractHasError && !this.props.contractError && this.errors(null, "Contract.Error.Create")}
                    {this.state.showErrors && this.errorsFromObject(this.validatorErrors, this.uiErrorsRef)}
                    {this.renderAlexelaInfo()}
                </GridCell>

                {this.renderLocations()}

                {this.props.locationsFetched &&
                    hasAvailableLocations &&
                    this.state[Contract.fieldLocation] &&
                    this.state[Contract.fieldLocation].length > 0 &&
                    <>
                        {this.renderProductsBlock()}
                        {this.renderCompetitorSelection()}
                        {this.renderAddressForm()}
                        {this.renderCampaignExternalIdInput()}

                        <GridCell span={12}>
                            <Button
                                className="p-0"
                                onClick={() => this.setState({ isManualAddressInput: !this.state.isManualAddressInput})}
                                trailingIcon={{
                                    icon: this.state.isManualAddressInput ? "search" : "write",
                                    strategy: "className",
                                    basename: "icon",
                                    prefix: "icon-",
                                }}
                            >
                                <FormattedMessage id={
                                    this.state.isManualAddressInput
                                        ? "AddressForm.AddSearch"
                                        : "AddressForm.AddManually"
                                    } />
                            </Button>
                            <Button className="right"
                                unelevated
                                disabled={this.props.contractFetching || !this.state[Contract.fieldProduct] || this.props.accountFetching}
                                onClick={this.onSubmit}>
                                {this.props.contractFetching ?
                                    <Loader type={Loader.TYPE_BUTTON} />
                                    :
                                    <FormattedMessage id="Contract.Next" />
                                }
                            </Button>
                        </GridCell>
                    </>
                }

            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        locations: state.consumptionLocations.data,
        locationsFetching: state.consumptionLocations.fetching,
        locationsFetched: state.consumptionLocations.fetched,

        accountFetching: state.account.fetching,
        accountFetched: state.account.fetched,
        account: state.account.data,

        competitors: state.competitors.data,
        competitorsFetching: state.competitors.fetching,
        competitorsFetched: state.competitors.fetched,

        competitorsGas: state.gasCompetitors.data,
        competitorsGasFetching: state.gasCompetitors.fetching,
        competitorsGasFetched: state.gasCompetitors.fetched,

        products: state.electricityProducts.data,
        productsFetching: state.electricityProducts.fetching,
        productsError: state.electricityProducts.error,

        productsGas: state.gasProducts.data,
        productsGasFetching: state.gasProducts.fetching,
        productsGasError: state.gasProducts.error,

        contractFetching: state.putContract.fetching,
        contractError: state.putContract.error,
        contractHasError: state.putContract.hasError,

        gasoPermission: state.hasGasoPermission.data,
        gasoPermissionFetched: state.hasGasoPermission.fetched,

        formFields: [
            Contract.fieldLocation,
            Contract.fieldProduct,
            Contract.fieldCompetitorId,
            Contract.fieldCompetitorName,
            Contract.fieldContractType,
            Contract.fieldPromotionCodeInfo,
        ]
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchElectricityProducts,
        fetchGasProducts,
        fetchConsumptionLocations,
        fetchCompetitors,
        fetchGasCompetitors,
        putContract,
        fetchAccount,
        postActionLog,
        hasGasoPermission,
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(Contract));
