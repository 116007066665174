import BaseDialog from "../common/BaseDialog";
import {Grid, GridCell} from "@rmwc/grid";
import {Typography} from "@rmwc/typography";
import {FormattedMessage, useIntl} from "react-intl";
import TextFieldGroup from "../common/TextFieldGroup";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Button} from "@rmwc/button";
import {useDispatch, useSelector} from "react-redux";
import {createSmsTemplate, putSmsTemplate} from "../../actions/notificationActions";
import {useValidator} from "../../helpers/hooks";

export const MarketingSmsTemplateDialog = forwardRef(({isOpen, onClose}, ref) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [validator,] = useValidator();
    const [id, setId] = useState(null);
    const [content, setContent] = useState("");
    const createTemplate = useSelector(state => state.postSmsTemplate)

    useEffect(() => {
        if (createTemplate.fetched) {
            onClose?.();
        }
    }, [createTemplate.fetched]);

    useImperativeHandle(ref, () => ({
        setContent(item) {
            setId(item.id);
            setContent(item.content);
        },
        reset() {
            setId(null);
            setContent("");

            validator.visibleFields = [];
            validator.hideMessages();
        }
    }));

    const onSubmitTemplate = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        if (id == null) {
            dispatch(createSmsTemplate({content: content}));
        } else {
            dispatch(putSmsTemplate(id, {id: id, content: content}));
        }

        onClose?.();
    };

    return (<BaseDialog isOpen={isOpen} onClose={onClose}>
        <Grid>
            <GridCell span={12}>
                <Typography use="headline1">
                    {!id && <FormattedMessage id="MarketingSmsMessages.TemplateDialog.CreatingTemplate"/>}
                    {id && <FormattedMessage id="MarketingSmsMessages.TemplateDialog.EditingTemplate"/>}
                </Typography>
            </GridCell>
            <GridCell span={12}>
                <TextFieldGroup
                    value={content}
                    onChange={e => setContent(e.target.value)}
                    validator={validator}
                    rules="required"
                    label={intl.formatMessage({id: "MarketingSmsMessages.TemplateDialog.TemplateContent"})}
                    field="MarketingSmsMessages.TemplateDialog.TemplateContent"/>
            </GridCell>
            <GridCell span={12}>
                <Button unelevated onClick={onSubmitTemplate}>
                    {!id && <FormattedMessage id="MarketingSmsMessages.TemplateDialog.CreateTemplate"/>}
                    {id && <FormattedMessage id="MarketingSmsMessages.TemplateDialog.Save"/>}
                </Button>
            </GridCell>
        </Grid>

    </BaseDialog>)
});