import { POST_CONVERT_STAMPS } from "../../actions/digitalStampsActions";

export default function reducer(state={
    data: null,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${POST_CONVERT_STAMPS}_PENDING`: {
            return {...state, fetching: true};
        }
        case `${POST_CONVERT_STAMPS}_REJECTED`: {
            return {...state, fetching: false, fetched: false, error: action.payload};
        }
        case `${POST_CONVERT_STAMPS}_FULFILLED`: {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data};
        }
        case `${POST_CONVERT_STAMPS}_CLEAR`: {
            return {fetching: false, error: null, fetched: false, data: null};
        }
        default:
            return state;
    }
}