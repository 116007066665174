import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";

export const FETCH_RESPONSIBLE_PERSONS = 'FETCH_RESPONSIBLE_PERSONS';

export const fetchResponsiblePersons = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_RESPONSIBLE_PERSONS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/responsiblePerson/all`)
    };
}