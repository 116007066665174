import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import classnames from "classnames";
import Number from "../common/Number";
import { Button } from "@rmwc/button";
import Currency from "../common/Currency";

const PaymentCardInvoiceServicesTable = ({ invoice }) => {

    const [expanded, setExpanded] = useState([]);

    const toggleRowExpanded = (key) => {
        setExpanded(prev => ({
            ...prev,
            [key]: !!prev[key] ? !prev[key] : true
        }))
    }

    const isRowExpanded = (key) => !!expanded[key];

    const trailingIcon = {
        icon: "angle-down",
        strategy: "className",
        basename: "icon",
        prefix: "icon-"
    };

    const renderExpandButton = (rowKey) => {
        return (
            <Button className="mdc-button--text hidden-above-sm"
                trailingIcon={{
                    ...trailingIcon,
                    className: isRowExpanded(rowKey) ? "rotate" : ""
                }}
                onClick={() => toggleRowExpanded(rowKey)} />
        );
    }

    const renderTableRow = (item, index) => {
        const rowKey = "item-" + index;
        const isExpanded = isRowExpanded(rowKey);

        return (
            <tr key={index}>
                <td>
                    {!!item.name &&
                        <div className="name wrap">{item.name[0].toUpperCase() + item.name.substr(1)}</div>}
                    {renderExpandButton(rowKey)}
                </td>
                <td>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.Amount" /></div>
                    {<Number value={item.amount} />}
                    &nbsp;
                    {item.unit}
                </td>
                <td className={classnames({ "hidden-below-sm": !isExpanded })}>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.InitialPrice" /></div>
                    <div className="text-right"><Currency value={item.initialPrice} /></div>
                </td>
                <td className={classnames({ "hidden-below-sm": !isExpanded })}>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.Discount" /></div>
                    <div className="text-right"><Currency value={item.discount} /></div>
                </td>
                <td className={classnames({ "hidden-below-sm": !isExpanded })}>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithoutVat" /></div>
                    <div className="text-right"><Currency value={item.cost} fractionalDigits={3} /></div>
                </td>
                <td className={classnames({ "hidden-below-sm": !isExpanded })}>
                    {!!item.vatPercentage && <>
                        <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.VatPercentage" /></div>
                        <div className="text-right"><Number value={item.vatPercentage} unit="%" /></div>
                    </>}
                </td>
                <td className={classnames({ "hidden-below-sm": !isExpanded })}>
                    {!!item.vat && <>
                        <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.Vat" /></div>
                        <div className="text-right"><Currency value={item.vat} fractionalDigits={3} /></div>
                    </>}
                </td>
                <td>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithVat" /></div>
                    <div className="text-right"><Currency value={item.costWithVat} /></div>
                </td>
            </tr>
        );
    }

    const renderTotalRow = (total, index) => {
        const taxable = total.taxable?.[0] ?? {};

        return (
            <tr key={index}>
                <td colSpan={4}>
                    <div className="name">
                        <FormattedMessage id="Invoice.PaymentCard.Totals.WithVat" />
                        &nbsp;
                        <Number value={taxable.vatPercentage} unit="%"/>
                    </div>
                </td>
                <td>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithoutVat" /></div>
                    <div className="text-right"><Currency value={taxable.totalWithoutVat} /></div>
                </td>
                <td />
                <td>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.Vat" /></div>
                    <div className="text-right"><Currency value={taxable.totalVat} /></div>
                </td>
                <td>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithVat" /></div>
                    <div className="text-right"><Currency value={total.totalWithVat} /></div>
                </td>
            </tr>
        );
    }

    const renderNotTaxableTotalRow = () => {
        return (
            invoice.totalNotTaxable ?
                <tr>
                    <td colSpan={4}>
                        <div className="name"><FormattedMessage id="Invoice.PaymentCard.Totals.NonTaxable" /></div></td>
                    <td>
                        <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithoutVat" /></div>
                        <div className="text-right"><Currency value={invoice.totalNotTaxable} /></div>
                    </td>
                    <td colSpan={2} />
                    <td>
                        <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithVat" /></div>
                        <div className="text-right"><Currency value={invoice.totalNotTaxable} /></div>
                    </td>
                </tr>
                : null
        );
    }

    const renderSubTotalRow = () => {
        return (
            <tr>
                <td colSpan={4}>
                    <div className="name"><FormattedMessage id="Invoice.PaymentCard.Totals.InvoiceTotal" /></div>
                </td>
                <td>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithoutVat" /></div>
                    <div className="text-right"><Currency value={invoice.subTotalWithoutVat} /></div>
                </td>
                <td />
                <td>
                    <div className="hidden-desktop"><FormattedMessage id="Invoice.PaymentCard.Table.Vat" /></div>
                    <div className="text-right"><Currency value={invoice.subTotalVat} /></div>
                </td>
                <td>
                    <div className="hidden-desktop bold"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithVat" /></div>
                    <div className="text-right bold"><Currency value={invoice.subTotalWithVat} /></div>
                </td>
            </tr>
        );
    }

    return (
        <table className="table-list" >
            <thead>
                <tr >
                    <th><FormattedMessage id="Invoice.PaymentCard.Table.ProductName" /></th>
                    <th><FormattedMessage id="Invoice.PaymentCard.Table.Amount" /></th>
                    <th className="text-right"><FormattedMessage id="Invoice.PaymentCard.Table.InitialPrice" /></th>
                    <th className="text-right"><FormattedMessage id="Invoice.PaymentCard.Table.Discount" /></th>
                    <th className="text-right"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithoutVat" /></th>
                    <th className="text-right"><FormattedMessage id="Invoice.PaymentCard.Table.VatPercentage" /></th>
                    <th className="text-right"><FormattedMessage id="Invoice.PaymentCard.Table.Vat" /></th>
                    <th className="text-right"><FormattedMessage id="Invoice.PaymentCard.Table.CostWithVat" /></th>
                </tr>
            </thead>
            <tbody>
                {invoice.invoiceItems?.length > 0 && invoice.invoiceItems.map(renderTableRow)}
            </tbody>
            <tfoot>
                {invoice.totals.map(renderTotalRow)}
                {renderNotTaxableTotalRow()}
                {renderSubTotalRow()}
            </tfoot>
        </table>
    );
};

export default injectIntl(PaymentCardInvoiceServicesTable);
