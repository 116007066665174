import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl  } from "react-intl";
import moment from "moment";
import classnames from "classnames";
import PropTypes from "prop-types";
import BaseFormComponent from "../common/BaseFormComponent";
import DatePickerRange from "../common/DatePickerRange";
import { Typography } from "@rmwc/typography";
import CheckboxField from "../common/CheckboxField";
import TextFieldGroup from "../common/TextFieldGroup";
import { CardStatusType } from "../../const/paymentCardFilter";
import { getCardsDefaultFilter } from "../../cachedFilter";

class PaymentCardFilter extends BaseFormComponent {
    static FieldCardNumber = "filter.cardNumber";
    static FieldCardName = "filter.cardName";
    static FieldContractNumber = "filter.contractNumber";
    static FieldCardStatus = "filter.cardStatusXc";
    static FieldStartDate = "filter.startDate";
    static FieldEndDate = "filter.endDate";

    static CardStatusTypeFilter = [
        {
            label: "Cards.Status.Open",
            value: CardStatusType.StatusOpen
        },
        {
            label: "Cards.Status.Closed",
            value: CardStatusType.StatusClosed
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            cardStatusTypes: this.props.filter.cardStatusType === null
                ? [CardStatusType.StatusOpen]
                : [this.props.filter.cardStatusType],
            filter: {...this.props.filter},
        };

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.filter = this.filter.bind(this);
        this.reset = this.reset.bind(this);
    }

    onStartDateChange(value) {
        this.setState({
            filter: {...this.state.filter, startDate: value ? moment(value) : null}
        });
    }

    onEndDateChange(value) {
        this.setState({
            filter: {...this.state.filter, endDate: value ? moment(value) : null}
        });
    }

    filter() {
        const cardStatuses = this.state.cardStatusTypes;
        const filter = this.state.filter;

        filter.cardStatusType = cardStatuses.length === 1 ? cardStatuses[0] : null;
        this.props.onSubmit(filter);
    }

    reset() {
        const defaultFilter = getCardsDefaultFilter();
        this.setState({
            filter: defaultFilter,
            cardStatusTypes: [defaultFilter.cardStatusType]
        }, this.filter);
    }

    onChangeCheckboxToFilter(item) {
        const filter = [...this.state.cardStatusTypes];

        if (filter.includes(item.value)) {
            filter.splice(filter.indexOf(item.value), 1);
        } else {
            filter.push(item.value);
        }

        this.setState({ cardStatusTypes: filter });
    }

    render() {
        const startDate = this.state.filter.startDate ? this.state.filter.startDate.toDate() : null
        const endDate = this.state.filter.endDate ? this.state.filter.endDate.toDate() : null
        return (
            <Grid
                className={classnames("filter-form", {
                "filter-form--open": this.props.open,
                })}
            >
                <GridCell desktop={4} tablet={4} >
                    <DatePickerRange
                        label="Cards.ExpiryDate"
                        fieldStart={PaymentCardFilter.FieldStartDate}
                        fieldEnd={PaymentCardFilter.FieldEndDate}
                        startOnChange={this.onStartDateChange}
                        endOnChange={this.onEndDateChange}
                        startValue={startDate}
                        endValue={endDate}
                        isNullableStart={true}
                        isNullableEnd={true}
                    />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <TextFieldGroup
                        field={PaymentCardFilter.FieldCardNumber}
                        onChange={this.onChange}
                        value={this.state.filter.cardNumber || ""}
                        type="number"
                        placeholder={this.props.intl.formatMessage({
                            id: "Cards.Title.PaymentCardNumber"
                        })}
                        label="Cards.Title.PaymentCardNumber" />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <TextFieldGroup
                        field={PaymentCardFilter.FieldCardName}
                        onChange={this.onChange}
                        value={this.state.filter.cardName || ""}
                        placeholder={this.props.intl.formatMessage({
                            id: "Cards.Title.CardName"
                        })}
                        label="Cards.Title.CardName" />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <TextFieldGroup
                        field={PaymentCardFilter.FieldContractNumber}
                        onChange={this.onChange}
                        value={this.state.filter.contractNumber || ""}
                        type="number"
                        placeholder={this.props.intl.formatMessage({
                            id: "Cards.Title.ContractNumber"
                        })}
                        label="Cards.Title.ContractNumber" />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <Typography
                        use="body1"
                        className="mdc-typography mb-10">
                        <label style={{ fontSize: "0.875rem" }}>
                            <FormattedMessage id="Cards.Label.Status" />
                        </label>
                    </Typography>
                    {PaymentCardFilter.CardStatusTypeFilter.map(x =>
                        <CheckboxField
                            className="mr-5"
                            containerClassName="pr-10 mb-10"
                            field={'filter-' + x.value}
                            key={'filter-' + x.value}
                            checked={this.state.cardStatusTypes.includes(x.value)}
                            label={<FormattedMessage id={x.label} />}
                            onChange={this.onChangeCheckboxToFilter.bind(this, x)}
                        />
                    )}
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <Button unelevated onClick={this.filter} className="right mt-25">
                        <i className="icon-wave" />
                        <FormattedMessage id="General.Filter" />
                    </Button>
                    <Button outlined onClick={this.reset} className="right mt-25 mr-10 mr-mobile-clear">
                        <FormattedMessage id="General.Filter.Reset" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}

PaymentCardFilter.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    filter: PropTypes.object,
};

export default injectIntl(PaymentCardFilter);
