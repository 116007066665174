import {Elektrihind, Gaasihind, Alexela} from "../const/campaignType";

export function MapCampaign(campaign) {
    if (campaign === Elektrihind) {
        return "elektrihind";
    } else if (campaign === Gaasihind) {
        return "gaasihind";
    } else if (campaign === Alexela) {
        return "alexela";
    }
    return campaign;
}