import { Dialog, DialogContent, DialogTitle, DialogButton } from "@rmwc/dialog";
import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AddressForm from "../AddressForm/AddressForm";
import SimpleReactValidator from "simple-react-validator";
import { Button } from "@rmwc/button";
import { orderPlasticCard } from "../../actions/cardActions";
import { useDispatch } from "react-redux";
import AlertMessage from "../common/AlertMessage";

const OrderPlasticCardDialog = ({ isOpen, onClose, onSuccess, cardNumber }) => {
    const [address, setAddress] = useState({});
    const [isError, setIsError] = useState(false);

    const intl = useIntl();
    const dispatch = useDispatch();

    const validator = new SimpleReactValidator({
        element: false,
        autoForceUpdate: this,
        locale: intl.locale
    });

    const onSubmit = async () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        try {
            await dispatch(orderPlasticCard({
                cardNumber,
                ...address
            }));
        } catch {
            setIsError(true);
            return;
        }

        onSuccess?.("Cards.PlasticCardDialog.Success");
        onClose();
    }

    if (!cardNumber) {
        return null;
    }

    return (
        <Dialog open={isOpen} onClose={onClose} className="mdc-dialog--info">
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss"
                />
            </DialogTitle>
            <DialogContent>
                <Grid className="mdc-layout-grid--padded">
                    <GridCell span={12}>
                        <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                            <FormattedMessage id="Cards.PlasticCardDialog.Title" />
                        </Typography>
                        <FormattedMessage id="Cards.NewCard.Notice.Activate" />
                    </GridCell>
                    {isError &&
                        <GridCell span={12}>
                            <AlertMessage
                                type={AlertMessage.TYPE_ALERT}
                                isSmall={true}
                                title={<FormattedMessage id="Cards.PlasticCardDialog.Error" />}
                            />
                        </GridCell>
                    }
                    <GridCell span={12} className="large-address-label">
                        <AddressForm
                            label="Cards.DeliveryAddress"
                            rules="required"
                            onChange={setAddress}
                            address={address}
                            validator={validator}
                        />
                    </GridCell>
                    <GridCell span={12}>
                        <Button unelevated onClick={onSubmit} className="right mt-15">
                            <i className="icon-floppy" />
                            <FormattedMessage id="Cards.Order.NowExtend" />
                        </Button>
                    </GridCell>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default OrderPlasticCardDialog;