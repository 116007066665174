import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from "@rmwc/textfield";
import { useIntl } from 'react-intl';
import ValidationHelper from "../../helpers/validationHelper";

const TextFieldGroup = ({ field, value, label, type, onChange, autoComplete, classNames, validator, rules, noLabel, showErrorMessage, validationLabel, onBlur, placeholder, disabled, icon, trailingIcon, dontTranslateLabel, maxLength, showCounter }) => {
    const intl = useIntl();
    let labelTranslated = noLabel ? "" : dontTranslateLabel ? label : intl.formatMessage({ id: label });
    let validationLabelTranslated = validationLabel ? intl.formatMessage({ id: validationLabel }) : null;
    const { onBlur: onValidationBlur, validationMessage, helperText, isRequired } = ValidationHelper.getFieldValidationItemsRmwc(validator, rules, validationLabelTranslated || labelTranslated, value);

    let onInputBlur = onValidationBlur;

    if (onBlur) {
        onInputBlur = () => {
            onValidationBlur();
            onBlur();
        };
    }

    // When showErrorMessage is false
    // Then show only red border.
    let validationHelper = showErrorMessage && helperText;

    const textField = <TextField
        className={classNames}
        helpText={validationHelper}
        outlined
        label={noLabel ? '' : labelTranslated}
        required={isRequired}
        maxLength={maxLength}
        onBlur={onInputBlur}
        invalid={!!validationMessage}
        autoComplete={autoComplete}
        type={type}
        value={value}
        name={field}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        icon={icon}
        trailingIcon={trailingIcon}
    />;

    return (
        showCounter
            ? <div className="mdc-text-field--with-counter">
                <div className="counter">
                    {!!value ? value.length : 0}/{maxLength >= 0 ? maxLength : "-"}
                </div>
                {textField}
            </div>
            : textField
    );
}

TextFieldGroup.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    label: PropTypes.string.isRequired,
    autoComplete: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    classNames: PropTypes.string,
    validator: PropTypes.object,
    rules: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    noLabel: PropTypes.bool,
    dontTranslateLabel: PropTypes.bool,
    showErrorMessage: PropTypes.bool,
    validationLabel: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.any,
    trailingIcon: PropTypes.any,
    maxLength: PropTypes.number,
    showCounter: PropTypes.bool
};

TextFieldGroup.defaultProps = {
    type: 'text',
    noLabel: false,
    dontTranslateLabel: false,
    showErrorMessage: true,
    disabled: false,
};

export default TextFieldGroup;
