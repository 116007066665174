import { Button } from "@rmwc/button";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import Loader from "../common/Loader";
import { useDigitalSigning } from "../../helpers/hooks";
import { isNullOrEmpty } from "../../helpers/objectHelper";


const ContractDigitalSignMinimal = ({title, className, dataForSigning, getFileToSign, disabled = false, setError, onDocumentSigned}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [challengeCode, startSigningProcess] = useDigitalSigning();
    const dispatch = useDispatch();
    const intl = useIntl();

    const onStartSigning = async () => {
        setIsLoading(true);

        try {
            const fileToSign = await dispatch(getFileToSign());

            if (isNullOrEmpty(fileToSign)) {
                setError(intl.formatMessage({ id: "DigitalSignature.Error.NoFileToSign" }));
                setIsLoading(false);
                return;
            }

            await startSigningProcess({
                personalCodeForSigning: dataForSigning?.personalCodeForSigning,
                mobileNumber: dataForSigning?.mobileNumber,
                signingMethod: dataForSigning?.signingMethod
            }, fileToSign, onDocumentSigned);
        } catch (err) {
            console.error(err);
            setError(intl.formatMessage({ id: "DigitalSignature.Error.Technical"}, { details: err.message || "" } ));
            setIsLoading(false);
        }
    }

    const renderLoader = () => {
        if (!challengeCode) {
            return <Loader type={Loader.TYPE_BUTTON} />;
        }

        return (
            <FormattedMessage
                id="DigitalSignature.ControlCode"
                values={{ code: challengeCode }}
            />
        );
    }

    return (
        <Button
            unelevated
            className={classNames("digital-sign-minimal-btn", className)}
            disabled={disabled}
            onClick={onStartSigning}
        >
            {isLoading
                ? renderLoader()
                : <FormattedMessage id={title} />
            }
        </Button>
    );
}

export default ContractDigitalSignMinimal;