import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCardReceiptsPdf } from '../../actions/transactionActions';
import { Button } from '@rmwc/button';

class CardTransactionsPrintReceipts extends React.Component {
    printReceipts(filter) {
        this.props.fetchCardReceiptsPdf(
            filter.startDate.format("YYYY-MM-DD"),
            filter.endDate.format("YYYY-MM-DD"),
            filter.bonuscard,
            filter.servicePoint === undefined || filter.servicePoint == null ? "" : filter.servicePoint.value
        )
    }

    isLoading() {
        return this.props.receiptsPdfFetching;
    }

    render() {
        return (
            <Button
                className="mdc-button--outlined filter-form__pdfPrint"
                outlined
                onClick={() => this.printReceipts(this.props.filter)}
                disabled={this.isLoading()}
            >
                <i className="icon-download" />
                <FormattedMessage id="Transactions.PrintReceipts" />
            </Button>
        )
    }
}

function mapStateToProps(state) {
    return {
        receiptsPdfFetching: state.fetchCardReceiptsPdf.fetching,
        receiptsPdfFetched: state.fetchCardReceiptsPdf.fetched,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchCardReceiptsPdf
        },
        dispatch
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(CardTransactionsPrintReceipts);