import React from "react"
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { GridCell } from "@rmwc/grid";
import DigitalStampsBonusIndicatorCell from "./DigitalStampsBonusIndicatorCell";

const DigitalStampsBonusIndicator = () => {
    const stampsSummary = useSelector(state => state.digitalStampsSummary);

    const getCollectedStamps = () => {
        const collected = stampsSummary.data?.stampsCollectedInCurrentMonth ?? 0;
        return collected > 5 ? 5 : collected;
    }

    return (
        <>
            <GridCell span={12} className="digital-stamps-bonus--text-bonus">
                <FormattedMessage
                    id="DigitalStamps.Bonus.CurrentBonus"
                    values={{
                        amount: stampsSummary.data?.bonus ?? 3
                    }}
                />
            </GridCell>
            <GridCell span={12} className="digital-stamps-bonus--text-disclaimer">
                <FormattedMessage id={stampsSummary.data?.loyaltySegment === "1"
                    ? "DigitalStamps.Bonus.Disclaimer"
                    : "DigitalStamps.Bonus.DisclaimerNext"}
                />
            </GridCell>
            <GridCell span={12} className="digital-stamps-bonus--indicator-cell-container">
                <div className="digital-stamps-bonus--indicator-cell">
                    {Array.from({ length: 5}, (_, index) => {
                        return (
                            <DigitalStampsBonusIndicatorCell
                                key={index}
                                isActive={(stampsSummary.data?.stampsCollectedInCurrentMonth ?? 0) >= index + 1}
                            />
                        )
                    })}
                    <div className="digital-stamp-bonus-cell counter">
                        <span>{getCollectedStamps()}/</span>
                        <span className="max-stamps">5</span>
                    </div>
                </div>
            </GridCell>
            <GridCell span={12} className="digital-stamps-bonus--text-discount">
                <FormattedMessage id={stampsSummary.data?.loyaltySegment === "4"
                    ? "DigitalStamps.Bonus.AgrDiscount"
                    : "DigitalStamps.Bonus.Discount"}
                />
            </GridCell>
        </>
    );
}

export default DigitalStampsBonusIndicator;