import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/_fields-group.scss';
import Checkbox from '@material/react-checkbox';
import classnames from 'classnames';
import {FormattedMessage} from "react-intl";

const ConsumptionLocationMultiSelect = ({fieldName, index, onChange, selectedValues, location, noAddressText, hideFirstAvailableContractDate, disabled}) => {

    let isChecked = selectedValues !== undefined && selectedValues.indexOf(location.id) >= 0;

    return (
        <div className="fields-group__list-item">
            <div className={classnames("mdc-form-field", {"mdc-text-field--disabled": location.isInContract || disabled})}>
                <Checkbox
                    nativeControlId={"loc_" + location.id}
                    name={fieldName}
                    checked={isChecked}
                    indeterminate={false}
                    onChange={onChange.bind(this, location.id)}
                    disabled={location.isInContract || disabled}
                />
            </div>
            <label className={classnames("text", {"disabled": location.isInContract})} htmlFor={"loc_" + location.id}>
                <span className="primary-text">{location.eicCode}, {location.isInContract && <small><FormattedMessage id="ConsumptionLocation.IsInContract" /></small>}</span>
                <span>{location.address || noAddressText}&nbsp;&nbsp;</span>
                { !hideFirstAvailableContractDate && location.firstAvailableContractDate !== null &&
                    <span><b><FormattedMessage id="ConsumptionLocation.Contract.FirstPossibleStartDate"/></b>&nbsp;{location.firstAvailableContractDate}</span>
                }
                
            </label>
        </div>
    );
}

ConsumptionLocationMultiSelect.propTypes = {
    fieldName: PropTypes.string.isRequired,
    index: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired,
    selectedValues: PropTypes.array,
    location: PropTypes.object,
    noAddressText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    hideFirstAvailableContractDate: PropTypes.bool
};

ConsumptionLocationMultiSelect.defaultProps = {
    index: 1,
    selectedValues: [],
    hideFirstAvailableContractDate: false
};

export default ConsumptionLocationMultiSelect;
