import { Button } from "@rmwc/button";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../auth/authenticate";
import { acceptInvitation, fetchFamilyGroup, rejectInvitation, clearAcceptInvite, clearRejectInvite } from "../../actions/familyGroupActions";
import AlertMessage from "../common/AlertMessage";
import { useEffect } from "react";
import classNames from "classnames";

import "../../styles/components/_campaign.scss";

const FamilyGroupSection = () => {
    const familyGroup = useSelector(state => state.familyGroup);
    const createFamilyGroup = useSelector(state => state.createFamilyGroup);
    const deleteGroup = useSelector(state => state.deleteFamilyGroup);
    const acceptInvite = useSelector(state => state.acceptFamilyGroupInvitation);
    const rejectInvite = useSelector(state => state.rejectFamilyGroupInvitation);
    const leaveGroup = useSelector(state => state.leaveFamilyGroup);
    const { isMobileOrTabletView } = useSelector(state => state.ui);
    const intl = useIntl();
    const dispatch = useDispatch();
    const roleSsn = auth.getRole()?.nationalIdentityNumber;

    const clearData = () => dispatch => {
        dispatch(clearAcceptInvite());
        dispatch(clearRejectInvite());
    }

    useEffect(() => {
        if ((acceptInvite.fetched && !acceptInvite.error) || (rejectInvite.fetched && !rejectInvite.error)) {
            dispatch(clearData());
            dispatch(fetchFamilyGroup());
        }
    }, [dispatch, acceptInvite.fetched, acceptInvite.error, rejectInvite.fetched, rejectInvite.error]);

    const onJoin = () => {
        dispatch(acceptInvitation(familyGroup.data.groupId));
    }

    const onDecline = () => {
        dispatch(rejectInvitation(familyGroup.data.groupId));
    }

    const renderSuccessMessage = (translationKey) => {
        return (
            <Grid>
                <GridCell span={12}>
                    <AlertMessage
                        isSmall
                        type={AlertMessage.TYPE_DONE}
                        title={intl.formatMessage({ id: translationKey })}
                    />
                </GridCell>
            </Grid>
        );
    }

    if ((createFamilyGroup.fetched && !createFamilyGroup.error)) {
        return renderSuccessMessage("Campaign.FamilyGroup.Section.SuccessMessage");
    }

    if ((deleteGroup.fetched && !deleteGroup.error)) {
        return renderSuccessMessage("Campaign.FamilyGroup.Section.GroupDeleted");
    }

    if ((leaveGroup.fetched && !leaveGroup.error)) {
        return renderSuccessMessage("Campaign.FamilyGroup.Section.SuccessMessageLeave");
    }

    if (familyGroup.fetching || familyGroup.error) {
        return null;
    }

    const hasPendingInvitation = familyGroup.data.groupId &&
        familyGroup.data.members.length > 0 &&
        familyGroup.data.members.find(m => m.personalCode === roleSsn && m.isPendingApplication);

    if (!hasPendingInvitation) {
        return null;
    }

    const headOfFamily = familyGroup.data.members.find(m => m.isHeadOfFamily);

    return (
        <Grid>
            <GridCell span={12}>
                <GridInner className="campaign-section">
                    <GridCell span={8}>
                        <GridCell span={12} className={classNames("campaign-section-padding", {
                            "text-center": isMobileOrTabletView,
                            "pb-0": isMobileOrTabletView
                        })}>
                            <Typography use="headline4" className="mdc-typography text-bold">
                                <FormattedMessage id="Campaign.FamilyGroup.Section.Title" values={{inviterName: headOfFamily.customerName}} />
                            </Typography>
                            <Typography use="body1" className="mdc-typography mt-20">
                                <FormattedMessage id="Campaign.FamilyGroup.Section.Description" />
                            </Typography>
                        </GridCell>
                    </GridCell>
                    <GridCell span={4} className={classNames("campaign-section-padding campaign-section-buttons", {
                        "pt-0": isMobileOrTabletView
                    })}>
                        <Button
                            unelevated
                            label={intl.formatMessage({ id: "Campaign.FamilyGroup.Section.Button.Join" })}
                            onClick={onJoin}
                        />
                        <Button
                            unelevated
                            label={intl.formatMessage({ id: "Campaign.FamilyGroup.Section.Button.Decline" })}
                            onClick={onDecline}
                        />
                    </GridCell>
                </GridInner>
            </GridCell>
        </Grid>
    );
}

export default FamilyGroupSection;
