import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from "@rmwc/typography";
import { Grid, GridCell } from "@rmwc/grid";
import BaseLogin from "../components/common/BaseLogin";
import { ServicePath, getElectricityContractProductPreSelectPath, getGasContractProductPreSelectPath } from "../const/routes";
import { Gas, Electricity } from '../const/contractType';
import auth from "../auth/authenticate";
import * as Campaigns from "../const/campaignType";
import Loader from "../components/common/Loader";
import language from "../lang/lang";
import { UsingApp, AppIsCorrectVersion } from "../helpers/AlternativeDesign";
import BaseDialog from "../components/common/BaseDialog";
import { fetchTranslations } from "../actions/localeActions";
import { setCampaignLoginFlag } from "../helpers/campaignLoginHelper";
import { useDispatch } from "react-redux";
import { updateCampaignLogin } from "../actions/authActions";
import { useLocation } from 'react-router-dom';

const CampaignLogin = (props) => {
    const [showStopLogin, setShowStopLogin] = useState(false);
    const intl = useIntl();
    const dispatch = useDispatch();
    const location = useLocation();
    const requestedLocation = location?.pathname;

    const startLogin = useCallback(() => {
        const login = () => async dispatch => {
            const { match: { params } = { type: null, item: null, code: '' } } = props;
            const code = params.code ? params.code.toLowerCase() : '';

            console.debug("params", params, "code", code);

            if (code && Object.values(Campaigns).find(c => c === code)) {
                if (auth.isLoggedIn()) {
                    await dispatch(updateCampaignLogin({ code, type: params.type, item: params.item }));
                }

                auth.startLogIn(
                    intl.locale || language.getDefaultLocale(),
                    code,
                    params.type,
                    params.item,
                    getPath(params.type, params.item),
                    requestedLocation);
            } else {
                auth.startLogIn(intl.locale || language.getDefaultLocale());
            }
        }

        dispatch(login());
    }, [dispatch, intl, props]);

    useEffect(() => {
        if (!UsingApp() || AppIsCorrectVersion()) {
            const lastLocaleUpdated = localStorage.getItem(language.MessagesLastUpdate);

            if (!lastLocaleUpdated || new Date(Number(lastLocaleUpdated)) < new Date()) {
                // Got to be sure, that new users download translations before starting login process.
                // Otherwise they might not get any, until the next page refresh
                fetchTranslations()
                    .then((items) => {
                        items.data.forEach(item => {
                            localStorage.setItem(item.locale + language.Messages, JSON.stringify(item.messages));
                        });

                        const cacheTime = new Date();
                        cacheTime.setMinutes(cacheTime.getMinutes() + 5);
                        localStorage.setItem(language.MessagesLastUpdate, cacheTime.getTime());

                        startLogin();
                    }).catch(() => {
                        startLogin();
                    });
            } else {
                startLogin();
            }
        } else {
            setShowStopLogin(true);
        }
    }, [startLogin]);

    const renderTitle = () => {
        return (
            <Grid className="mdc-layout-grid--mobile-vertical">
                <GridCell span={12}>
                    <Typography
                        use="headline2"
                        tag="h2"
                        className="mdc-typography mdc-theme--primary mdc-theme-desktop-margin"
                    >
                        <FormattedMessage
                            id="LoginPage.Title"

                        />
                    </Typography>
                    <Loader type={Loader.TYPE_CENTER} />
                    <BaseDialog isOpen={showStopLogin}>
                        <Typography
                            use="headline2"
                            tag="h2"
                            className="mdc-typography mdc-theme--primary mdc-theme-desktop-margin"
                        >
                            <FormattedMessage
                                id="LoginPage.UpdateApp.Title"
                            />
                        </Typography>
                        <FormattedMessage
                            id="LoginPage.UpdateApp.Description"
                        />
                    </BaseDialog>
                </GridCell>
            </Grid>
        );
    }

    const getPath = (type, item) => {
        if (type && item) {
            if (type.toLowerCase() === Gas) {
                setCampaignLoginFlag();
                return getGasContractProductPreSelectPath(item);
            }
            if (type.toLowerCase() === Electricity) {
                setCampaignLoginFlag();
                return getElectricityContractProductPreSelectPath(item);
            }
        }

        return ServicePath
    }

    return (
        <BaseLogin redirectTo={getPath(props.match.params.type, props.match.params.item)} disableRedirect={true}>
            {renderTitle()}
        </BaseLogin>
    );
}

export default CampaignLogin;
