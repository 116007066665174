import { Button } from "@rmwc/button";
import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useValidator } from "../../helpers/hooks";
import AlertMessage from "../common/AlertMessage";
import TextFieldGroup from "../common/TextFieldGroup";
import { bindBonusCard } from "../../actions/cardActions";

const AudiCampaignDialog = () => {
    const [cardNumber, setCardNumber] = useState("");
    const [isError, setIsError] = useState(false);

    const bindCard = useSelector(state => state.bindBonusCard);

    const [validator, ] = useValidator();
    const dispatch = useDispatch();

    useEffect(() => {
        if (bindCard.error) {
            setIsError(true);
        }
    }, [bindCard]);

    const onSubmit = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        dispatch(bindBonusCard(cardNumber));
    }

    return (
        <Grid>
            <GridCell span={12}>
                <Typography use="headline2" className="mdc-typography mdc-theme--primary">
                    <FormattedMessage id="Cards.AudiCampaign.Title" />
                </Typography>
            </GridCell>
            <GridCell span={12}>
                <FormattedMessage id="Cards.AudiCampaign.Description" />
            </GridCell>
            {isError &&
                <GridCell span={12}>
                    <AlertMessage
                        isSmall={true}
                        type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id="Cards.AudiCampaign.Error" />}
                    />
                </GridCell>
            }
            <GridCell span={12}>
                <TextFieldGroup
                    field="cardnumberaudi"
                    onChange={e => setCardNumber(e.target.value)}
                    value={cardNumber}
                    label="Cards.AudiCampaign.CardNumber"
                    validator={validator}
                    rules="required|numeric|min:16|regex:^\d+$"
                />
            </GridCell>
            <GridCell span={12} className="mt-35 text-right">
                <Button unelevated onClick={onSubmit}>
                    <FormattedMessage id="Cards.AudiCampaign.Submit" />
                </Button>
            </GridCell>
        </Grid>
    )
}

export default AudiCampaignDialog;