import { Icon } from "@rmwc/icon";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import auth from "../../auth/authenticate";
import { StartPath } from "../../const/routes";
import useMenu from "../../helpers/hooks/useMenu";
import { populateCrumbArray } from "../../helpers/breadCrumbsHelper";

const BreadCrumbs = () => {
    const intl = useIntl();
    const { secondary } = useSelector(state => state.menuSelection);
    const [appMenu, setMenuSelection] = useMenu();
    const location = useLocation();

    const rootCrumb =
        <Link to={StartPath} onClick={() => setMenuSelection({ secondary: appMenu[0] })}>
            {intl.formatMessage({ id: auth.isCompany()
                ? "TopContent.Selfservice.Business"
                : "TopContent.Selfservice.Private" })
            }
        </Link>;

    const renderCrumbSeparator = () => {
        return (
            <div className="breadcrumbs--item breadcrumbs--separator">
                <Icon
                    icon={{
                        icon: "angle-right",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }}
                />
            </div>
        );
    }

    const renderCrumbs = () => {
        const path = location.pathname === "/" ? StartPath : location.pathname;

        const crumbs = [];
        if (secondary?.translationKey) {
            populateCrumbArray(secondary, crumbs, path);
        } else {
            for (const item of appMenu) {
                populateCrumbArray(item, crumbs, path);

                if (crumbs.length > 0) break;
            }
        }

        return crumbs.reverse().map((crumb, index) => {
            return (
                <React.Fragment key={index}>
                    {renderCrumbSeparator()}
                    <div className="breadcrumbs--item">
                        {intl.formatMessage({ id: crumb.translationKey })}
                    </div>
                </React.Fragment>
            );
        });
    }

    return (
        <div className="breadcrumbs">
            <div className="breadcrumbs--item breadcrumbs--logo">
                <i className="icon-alexela-m" />
            </div>
            {renderCrumbSeparator()}
            <div className="breadcrumbs--item breadcrumbs--root">{rootCrumb}</div>
            {renderCrumbs()}
        </div>
    );
}

export default BreadCrumbs;