import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from 'prop-types';
import { GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";
import AlertMessage from "../common/AlertMessage";
import TextFieldGroup from "../common/TextFieldGroup";
import PhoneFieldGroupWithFlags from "../common/PhoneFieldGroupWithFlags";
import { putAccount, putContacts } from "../../actions/accountActions";
import AddressForm from "../AddressForm/AddressForm";
import { useScrollToRef } from "../../helpers/hooks";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import { getAddressAsString, isAddressObjectValidRegex } from "../../helpers/addressHelper";
import ValidationHelper from "../../helpers/validationHelper";
import { CardApplicationType } from "../../const/cardApplication";
import { InvoiceFrequency } from "../../const/Invoice";
import SimpleReactValidator from "simple-react-validator";
import { IsLatvianWeb } from "../../helpers/AlternativeDesign";
import classNames from 'classnames';

const ContractUserData = ({
    account,
    contacts,
    additionalData,
    setUserData,
    hasDeliveryAddress = false,
    isReadOnly = false,
    isCompany = false,
    validator
}) => {
    const putContactsError = useSelector(state => state.putContacts.error);
    const putAccountError = useSelector(state => state.putAccount.error);
    const paymentCardStatus = useSelector(state => state.paymentCardStatus.data);

    const [contactData, setContactData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [contactId, setContactId] = useState("");
    const [email, setEmail] = useState("");
    const [dataCarrier, setDataCarrier] = useState("");
    const [dataCarrierPrev, setDataCarrierPrev] = useState();
    const [signerEmail, setSignerEmail] = useState("");
    const [signerEmailPrev, setSignerEmailPrev] = useState();
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState({});
    const [deliveryAddress, setDeliveryAddress] = useState({});
    const [deliveryAddressPrev, setDeliveryAddressPrev] = useState({});

    const formErrorRef = useRef();
    const intl = useIntl();

    const scrollToRef = useScrollToRef(true);
    const dispatch = useDispatch();

    const FIELD_ADDRESS = intl.formatMessage({ id: "ContractsPage.AddContract.ClientAddress" });
    const FIELD_DELIVERY_ADDRESS = intl.formatMessage({ id: "ContractsPage.AddContract.DeliveryAddress" });

    const getEmail = () => {
        return paymentCardStatus.email || (isCompany ? account.email : contactData.email);
    }

    const getPhone = () => {
        return isCompany ? account.phone : contactData.mobile;
    };

    const getSignerEmail = () => {
        return paymentCardStatus.signerEmail || getEmail();
    }

    const getDataCarrier = () => {
        return paymentCardStatus.emailDataCarrier || getEmail();
    }

    const getInvoiceFrequency = useMemo(() => {
        if (additionalData?.invoiceFrequencyXc) {
            const frequency = InvoiceFrequency.find(frq => frq.label === additionalData.invoiceFrequencyXc);
            return frequency?.numeric ?? 1;
        }

        return 1;
    }, [additionalData]);

    const getDeliveryAddress = () => {
        return (paymentCardStatus?.deliveryAddressCounty && {
            city: paymentCardStatus.deliveryAddressCityTown,
            county: paymentCardStatus.deliveryAddressCounty,
            street: paymentCardStatus.deliveryAddressStreet,
            postalCode: paymentCardStatus.deliveryAddressPostalCode,
        }) || {};
    }

    useEffect(() => {
        if (!isNullOrEmpty(contacts) && !isCompany) {
            const contactId = Object.keys(contacts)[0];
            setContactId(contactId);
            const contactData = contacts[contactId];
            setContactData(contactData);
        }

        if (!isNullOrEmpty(account) && (isCompany || !isNullOrEmpty(contactData))) {
            setEmail(getEmail());
            setDataCarrier(getDataCarrier());
            setSignerEmail(getSignerEmail());
            setPhone(getPhone());

            if (!isAddressObjectValidRegex(account.address)) {
                setIsEditMode(true);
                validator.showMessageFor(ValidationHelper.getValidationField(FIELD_ADDRESS));
                validator.showMessageFor(ValidationHelper.getValidationField(FIELD_DELIVERY_ADDRESS));
            }

            setAddress(account.address);
            setDeliveryAddress(getDeliveryAddress());
            setDeliveryAddressPrev(getDeliveryAddress());

            setUserData(prev => ({
                ...prev,
                address: account.address,
                deliveryAddress: getDeliveryAddress(),
                clientCrmId: account.crmId,
                fullName: account.name,
                code: account.companyCode,
                email: getEmail(),
                signerEmail: getSignerEmail(),
                phone: getPhone(),
                dataCarrier: getDataCarrier(),
            }));
            setIsLoading(false);
        }
    }, [account, contacts, contactData, isCompany, paymentCardStatus]);

    const onEditClick = () => {
        if (!isEditMode) {
            setIsEditMode(true);
        } else {
            updateUserData();
        }
    }

    const onCancelClick = () => {
        setEmail(getEmail());
        setDataCarrier(dataCarrierPrev || getDataCarrier());
        setSignerEmail(signerEmailPrev || getSignerEmail());
        setPhone(getPhone());
        setAddress(account.address);
        setDeliveryAddress(deliveryAddressPrev || getDeliveryAddress());
        setUserData({
            email: getEmail(),
            dataCarrier: dataCarrierPrev || getDataCarrier(),
            phone: getPhone(),
            address: account.address,
            deliveryAddress: deliveryAddressPrev || getDeliveryAddress(),
            clientCrmId: account.crmId,
            fullName: account.name,
            code: account.companyCode,
            signerEmail: signerEmailPrev || getSignerEmail()
        });
        setIsEditMode(false);
    }

    const isContactChanged = () => {
        if (isCompany) {
            return false;
        }
        return getEmail() !== email || getPhone() !== phone;
    }

    const isAccountChanged = () => {
        if (isCompany) {
            return account.address?.addressId !== address.addressId ||
                getEmail() !== email ||
                getPhone() !== phone;
        }
        return account.address?.addressId !== address.addressId;
    }

    const updateUserData = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            scrollToRef(formErrorRef);
            return;
        }

        if (isAccountChanged()) {
            const updatedAccount = createAccountModel();
            dispatch(putAccount(updatedAccount));
        }

        if (isContactChanged()) {
            const updatedContact = createContactsModel();
            dispatch(putContacts(updatedContact));
        }

        setUserData(prev => ({
            ...prev,
            email: email,
            phone: phone,
            address: address,
            deliveryAddress: deliveryAddress,
            dataCarrier: dataCarrier,
            signerEmail: signerEmail
        }));

        setDataCarrierPrev(dataCarrier);
        setSignerEmailPrev(signerEmail);
        setDeliveryAddressPrev(deliveryAddress);
        setIsEditMode(false);
    }

    const createAccountModel = () => {
        return {
            LegalAddressCountry: IsLatvianWeb() ? "Latvija" : "Eesti",
            LegalAddressCity: address.city,
            LegalAddressState: address.county,
            LegalAddressStreet: address.street,
            LegalAddressCode: address.postalCode,
            LegalAddressHouseNo: address.houseNr,
            LegalAddressAppartmentNo: address.apartmentNr,
            LegalAddressParish: address.parish,
            GroupId: account.crmId,
            email: isCompany ? email : null,
            phone: isCompany ? phone : null
        };
    }

    const createContactsModel = () => {
        return {
            email: email,
            phoneMobile: phone,
            marketingAcceptance: contactData.marketingAcceptance,
            fullName: account.name,
            id: contactId
        };
    }

    return (
        <>
            {isLoading && <Loader type={Loader.TYPE_CENTER} />}
            {!isLoading &&
                <>
                    {(putContactsError?.message || putAccountError?.message) &&
                        <GridCell span={12}>
                            <AlertMessage
                                type={AlertMessage.TYPE_NOTICE}
                                title={putContactsError?.message || putAccountError?.message}
                            />
                        </GridCell>
                    }
                    <GridCell span={12}>
                        <GridInner className="border-top border-bottom p-15">
                            <GridCell span={6} align="middle">
                                <FormattedMessage id="ContractsPage.AddContract.ClientName" />
                            </GridCell>
                            <GridCell span={6} align="middle">
                                <Typography use="body1" className="mdc-typography--bold">
                                    {account.name}
                                </Typography>
                            </GridCell>
                        </GridInner>
                        <GridInner className="border-bottom p-15">
                            <GridCell span={6} align="middle">
                                {isCompany
                                    ? <FormattedMessage id="ContractsPage.AddContract.RegCode" />
                                    : <FormattedMessage id="ContractsPage.AddContract.ClientPersonalCode" />
                                }
                            </GridCell>
                            <GridCell span={6} align="middle">
                                <Typography use="body1" className="mdc-typography--bold">
                                    {account.companyCode}
                                </Typography>
                            </GridCell>
                        </GridInner>
                        <GridInner className="border-bottom p-15">
                            <GridCell span={6} align="middle">
                                <FormattedMessage id="ContractsPage.AddContract.ClientAddress" />
                            </GridCell>
                            <GridCell span={6} align="middle">
                                {!isEditMode &&
                                    <Typography use="body1" className="mdc-typography--bold">
                                        {getAddressAsString(address)}
                                    </Typography>
                                }
                                {isEditMode &&
                                    <AddressForm
                                        rules="required|address"
                                        onChange={setAddress}
                                        address={address}
                                        validator={validator}
                                        isLoading={isLoading}
                                        field={FIELD_ADDRESS}
                                    />
                                }
                            </GridCell>
                        </GridInner>
                        <div ref={formErrorRef}></div>
                        {(hasDeliveryAddress && !isCompany) &&
                            <GridInner className="border-bottom p-15">
                                <GridCell span={6} align="middle">
                                    <FormattedMessage id="ContractsPage.AddContract.DeliveryAddress" />
                                </GridCell>
                                <GridCell span={6} align="middle">
                                    {!isEditMode &&
                                        <Typography use="body1" className="mdc-typography--bold">
                                            {getAddressAsString(deliveryAddress)}
                                        </Typography>
                                    }
                                    {isEditMode &&
                                        <AddressForm
                                            rules={hasDeliveryAddress ? "required|address" : ""}
                                            onChange={setDeliveryAddress}
                                            address={deliveryAddress}
                                            validator={validator}
                                            isLoading={isLoading}
                                            field={FIELD_DELIVERY_ADDRESS}
                                        />
                                    }
                                </GridCell>
                            </GridInner>
                        }
                        <GridInner className="border-bottom p-15">
                            <GridCell span={6} align="middle">
                                {isCompany
                                    ? <FormattedMessage id="ContractsPage.AddContract.PhoneNumber" />
                                    : <FormattedMessage id="ContractsPage.AddContract.ClientPhone" />
                                }
                            </GridCell>
                            <GridCell span={6} align="middle">
                                {!isEditMode &&
                                    <Typography use="body1" className="mdc-typography--bold">
                                        {phone}
                                    </Typography>
                                }
                                <PhoneFieldGroupWithFlags
                                    label="ContractsPage.AddContract.ClientPhone"
                                    value={phone}
                                    field="ClientPhone"
                                    onUpdate={(_, phoneNumber) => setPhone(phoneNumber)}
                                    validator={validator}
                                    rules="required|phoneformat"
                                    hasLabel={false}
                                    hidden={!isEditMode}
                                />
                            </GridCell>
                        </GridInner>
                        <GridInner className="border-bottom p-15">
                            <GridCell span={6} align="middle">
                                <FormattedMessage id="ContractsPage.AddContract.ClientEmail" />
                            </GridCell>
                            <GridCell span={6} align="middle">
                                {!isEditMode &&
                                    <Typography use="body1" className="mdc-typography--bold">
                                        {email}
                                    </Typography>
                                }
                                <TextFieldGroup
                                    label="ContractsPage.AddContract.ClientEmail"
                                    value={email}
                                    field="ClientEmail"
                                    onChange={(e) => setEmail(e.target.value)}
                                    validator={validator}
                                    validationLabel={intl.formatMessage({id: "ContractsPage.AddContract.ClientEmail"})}
                                    rules="required|email"
                                    noLabel={true}
                                    classNames={classNames({hidden: !isEditMode})}
                                />
                            </GridCell>
                        </GridInner>
                        <GridInner className="border-bottom p-15">
                            <GridCell span={6} align="middle">
                                <FormattedMessage id="ContractsPage.AddContract.DataCarrier" />
                            </GridCell>
                            <GridCell span={6} align="middle">
                                {!isEditMode &&
                                    <Typography use="body1" className="mdc-typography--bold">
                                        {dataCarrier}
                                    </Typography>
                                }
                                <TextFieldGroup
                                    label="ContractsPage.AddContract.DataCarrier"
                                    value={dataCarrier}
                                    field="ClientEmail"
                                    onChange={(e) => setDataCarrier(e.target.value)}
                                    validationLabel={intl.formatMessage({id: "ContractsPage.AddContract.DataCarrier"})}
                                    validator={validator}
                                    rules="required|email"
                                    noLabel={true}
                                    classNames={classNames({hidden: !isEditMode})}
                                />
                            </GridCell>
                        </GridInner>
                        {isCompany &&
                            <>
                                <GridInner className="border-bottom p-15">
                                    <GridCell span={6} align="middle">
                                        <FormattedMessage id="ContractsPage.AddContract.SignerEmail" />
                                    </GridCell>
                                    <GridCell span={6} align="middle">
                                        {!isEditMode &&
                                            <Typography use="body1" className="mdc-typography--bold">
                                                {signerEmail}
                                            </Typography>
                                        }
                                        <TextFieldGroup
                                            label="ContractsPage.AddContract.SignerEmail"
                                            value={signerEmail}
                                            field="ClientEmail"
                                            onChange={(e) => setSignerEmail(e.target.value)}
                                            validationLabel={isCompany ? intl.formatMessage({id: "ContractsPage.AddContract.SignerEmail"}) : null}
                                            validator={validator}
                                            rules={isCompany ? "required|email" : null}
                                            noLabel={true}
                                            classNames={classNames({hidden: !isEditMode})}
                                        />
                                    </GridCell>
                                </GridInner>
                                {(additionalData?.applicationType === CardApplicationType.OFFER ||
                                    additionalData?.applicationType === CardApplicationType.APPLICATION) &&
                                    <>
                                        <GridInner className="border-bottom p-15">
                                            <GridCell span={6} align="middle">
                                                <FormattedMessage id="ContractsPage.AddContract.InvoiceFrequency" />
                                            </GridCell>
                                            <GridCell span={6} align="middle">
                                                <Typography use="body1" className="mdc-typography--bold">
                                                    <FormattedMessage
                                                        id="ContractsPage.AddContract.InvoiceFrequency.Value"
                                                        values={{
                                                            amount: getInvoiceFrequency
                                                        }}
                                                    />
                                                </Typography>
                                            </GridCell>
                                        </GridInner>
                                        <GridInner className="border-bottom p-15">
                                            <GridCell span={6} align="middle">
                                                <FormattedMessage id="ContractsPage.AddContract.DueDays" />
                                            </GridCell>
                                            <GridCell span={6} align="middle">
                                                <Typography use="body1" className="mdc-typography--bold">
                                                    <FormattedMessage
                                                        id="ContractsPage.AddContract.DueDays.Value"
                                                        values={{
                                                            value: additionalData.dueDays
                                                        }}
                                                    />
                                                </Typography>
                                            </GridCell>
                                        </GridInner>
                                    </>
                                }
                            </>
                        }
                    </GridCell>
                    <GridCell span={12} className="text-right">
                    {!isReadOnly ?
                        <>
                            {isEditMode &&
                                <Button
                                    className="mt-15 mr-24 ml-mobile-10 mr-mobile-10"
                                    outlined
                                    onClick={onCancelClick}
                                >
                                    <FormattedMessage id="ContractsPage.AddContract.Cancel" />
                                </Button>
                            }
                            <Button
                                className="mt-15 mr-24 mb-mobile-15 ml-mobile-10 mr-mobile-10"
                                outlined
                                onClick={onEditClick}
                            >
                                <FormattedMessage
                                    id={isEditMode
                                        ? "ContractsPage.AddContract.UpdateUserData"
                                        : "ContractsPage.AddContract.EditUserData"}
                                />
                            </Button>
                        </> : <></>
                    }
                    </GridCell>
                </>
            }
        </>
    );
}

ContractUserData.propTypes = {
    account: PropTypes.object.isRequired,
    contacts: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
    additionalData: PropTypes.object,
    hasDeliveryAddress: PropTypes.bool,
    isCompany: PropTypes.bool,
    validator: PropTypes.instanceOf(SimpleReactValidator)
};

export default ContractUserData;
