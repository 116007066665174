import { FETCH_CONSUMPTION_DETAILS } from "../../actions/consumptionlocationActions";

export default function reducer(state = {
    data: null,
    params: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_CONSUMPTION_DETAILS}_PENDING`: {
            return { ...state, fetching: true };
        }
        case `${FETCH_CONSUMPTION_DETAILS}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${FETCH_CONSUMPTION_DETAILS}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, data: action.payload.data, error: null, params: action.payload.params };
        }
        default:
            return state;
    }
}