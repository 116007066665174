export const alexelaUrl = "https://www.alexela.ee/";
export const alexelaUrlLv = "https://www.alexela.lv/";
export const alexelaLinkedInUrl = "https://www.linkedin.com/company/alexela-group";
export const alexelaFacebookUrl = "https://www.facebook.com/AlexelaOil";
export const alexelaTwitterUrl = "https://www.twitter.com/alexelaoil";
export const alexelaShopUrl = 'https://pood.alexela.ee';

export const alexelaPersonalDataProcessingPdfUrl = "https://www.alexela.ee/sites/default/files/documents/2019-05/Alexela%20kaartide%20isikuandmete%20t%C3%B6%C3%B6tlemise%20teavitus.pdf";

export const alexelaGasTOSUrl = 'https://www.alexela.ee/et/elektri-ja-maagaasi-lepingu-tuuptingimused#tab-maagaasileping';
export const alexelaElectricityTOSUrl = 'https://www.alexela.ee/et/elektri-ja-maagaasi-lepingu-tuuptingimused#tab-elektrileping';
export const alexelaPriceListUrl = 'https://www.alexela.ee/et/elektri-ja-maagaasi-lepingu-tuuptingimused#tab-hinnakiri';
export const alexelaGreenEnergyUrl = 'https://www.alexela.ee/et/elektri-ja-maagaasi-lepingu-tuuptingimused';
