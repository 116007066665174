import { Button } from "@rmwc/button";
import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl";
import CheckboxField from "../common/CheckboxField";
import auth from "../../auth/authenticate";
import { fetchPurchaseRight } from "../../actions/fuelTaxiOrderActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/Loader";
import AlertMessage from "../common/AlertMessage";

const PurchaseRight = ({ isTimeout = false }) => {
    const purchaseRight = useSelector(state => state.purchaseRight);

    const [isChecked, setIsChecked] = useState(false);

    const intl = useIntl();
    const dispatch = useDispatch();
    const user = auth.getUser();

    const onCreateRequest = () => {
        dispatch(fetchPurchaseRight(user.nationalIdentityNumber));
    }

    const getLabelText = () => {
        return intl.formatMessage({ id: "FuelTaxiOrderForm.Label.Edkr" }, {
            name: `${user.firstName} ${user.lastName}`,
            code: user.nationalIdentityNumber
        });
    }

    if (purchaseRight.error) {
        return (
            <AlertMessage
                isSmall
                type={AlertMessage.TYPE_ALERT}
                title={intl.formatMessage({ id: "FuelTaxiOrderForm.Alert.EdkrError" })}
            />
        );
    }

    if (purchaseRight.fetched) {
        const isAllowed = purchaseRight.data.isAllowed;
        return (
            <AlertMessage
                isSmall
                type={isAllowed ? AlertMessage.TYPE_DONE : AlertMessage.TYPE_WARN}
                title={intl.formatMessage({ id: isAllowed
                    ? "FuelTaxiOrderForm.Alert.EdkrSuccess"
                    : "FuelTaxiOrderForm.Alert.EdkrNoPermission" })
                }
            />
        );
    }

    return (
        <>
            <div className="d-flex justify-content-between align-items-center p-10">
                <div>
                    <CheckboxField
                        field="edkr"
                        checked={isChecked}
                        label={<label htmlFor="edkr" className="mdc-checkbox__medium-text">{getLabelText()}</label>}
                        onChange={e => setIsChecked(e.target.checked)}
                    />
                </div>
                <div>
                    <Button type="button" unelevated onClick={onCreateRequest} className="mdc-button--compact" disabled={!isChecked || purchaseRight.fetching}>
                        {purchaseRight.fetching
                            ? <Loader type={Loader.TYPE_BUTTON} />
                            : <FormattedMessage id="FuelTaxiOrderForm.Button.Edkr" />
                        }
                    </Button>
                </div>
            </div>
            <div className="p-10 edkr-privacy-text">
                <FormattedMessage
                    id="FuelTaxiOrderForm.Label.Edkr.Privacy"
                    values={{
                        url: <a href={intl.formatMessage({ id: "FuelTaxiOrderForm.Label.Edkr.PrivacyUrl" })} target="_blank" rel="noreferrer">
                                <FormattedMessage id="FuelTaxiOrderForm.Label.Edkr.PrivacyUrlText" />
                            </a>
                    }}
                />
            </div>
            {isTimeout &&
                <div>
                    <AlertMessage
                        type={AlertMessage.TYPE_WARN}
                        isSmall
                        title={intl.formatMessage({ id: "FuelTaxiOrderForm.Alert.SessionTimeout" })}
                    />
                </div>
            }
        </>
    );
}

export default PurchaseRight;