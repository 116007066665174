import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import classnames from "classnames";

import moment from "moment";
import { default as ReactDatePicker, registerLocale } from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import { et, enGB, ru } from "date-fns/locale";
import range from "lodash/range";

import { default as ClickOutsideWrapper } from "react-onclickout";
import SelectField from "./SelectField";
import { TextField } from "@rmwc/textfield";
import { IconButton } from "@rmwc/icon-button";

import "../../styles/react-components/datepicker.scss";

registerLocale("et", et);
registerLocale("ru", ru);
registerLocale("en", enGB);

class DatePicker extends React.Component {
    static datepickerOptionClassName = "datepicker-option";
    static dateDisplayFormat = "DD.MM.YYYY";
    static dateFormats = [
        moment.ISO_8601,
        "MM/DD/YYYY",
        "DD.MM.YYYY",
        "DD.MM YYYY",
        "DD MMMM YYYY",
        "DD. MMMM YYYY"
    ];

    constructor(props) {
        super(props);

        this.openCalendar = this.openCalendar.bind(this);
        this.onClickOut = this.onClickOut.bind(this);

        this.state = {
            open: false,
            date: this.props.value || new Date(),
            valueString: this.props.value
                ? moment(this.props.value).format(DatePicker.dateDisplayFormat)
                : ""
        };
    }

    openCalendar = () => {
        this.setState({
            open: true,
            date: this.props.value || new Date()
        });
    };

    toggleCalendar = () => {
        this.setState({ open: !this.state.open });
    };

    onClickOut(e) {
        if (
            !e.target.classList.contains(DatePicker.datepickerOptionClassName)
        ) {
            this.setState({ open: false });
        }
    }

    onDateFieldChange = e => {
        const newValue = e.target.value;
        const date = moment(newValue, DatePicker.dateFormats, true);
        let state = {};

        const newDate = (date.isValid() && date.toDate()) || null;

        if (this.props.isNullable || newDate) {
            state.open = false;
            state.date = newDate;
            this.props.onChange(newDate);
        }
        state.valueString = newValue;
        this.setState(state);
    };

    onDateSelect = date => {
        this.setState({
            open: false,
            date: date,
            valueString: date
                ? moment(date).format(DatePicker.dateDisplayFormat)
                : ""
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                date: this.props.value || new Date(),
                valueString: this.props.value
                    ? moment(this.props.value).format(DatePicker.dateDisplayFormat)
                    : "",
            });
        }
    }

    renderDateField = () => {
        const { label, field } = this.props;

        return (
            <TextField
                invalid={
                    this.state.valueString &&
                    !moment(
                        this.state.valueString,
                        DatePicker.dateFormats,
                        true
                    ).isValid()
                }
                label={label ? <FormattedMessage id={label} /> : ""}
                autoComplete="off"
                outlined
                field={field}
                value={this.state.valueString}
                onChange={this.onDateFieldChange}
                trailingIcon={{
                    icon: "calendar",
                    strategy: "className",
                    basename: "icon",
                    prefix: "icon-",
                    onClick: this.toggleCalendar
                }}
                onClick={this.openCalendar}
                placeholder={this.props.intl.formatMessage({ id: "Filter.DateFormat" })}
            />
        );
    };

    renderDatePickerHeader = ({
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => {
        return (
            <div className="action">
                <IconButton
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    onIcon={{
                        icon: "angle-left",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }}
                />

                {this.renderMonthSelection(changeMonth)}
                {this.renderYearSelection(changeYear)}

                <IconButton
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    onIcon={{
                        icon: "angle-right",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }}
                />
            </div>
        );
    };

    renderDatePicker = () => {
        return (
            <ReactDatePicker
                calendarClassName={classnames("datepicker", {
                    open: this.state.open
                })}
                locale={this.props.locale}
                inline
                minDate={this.props.minDate || null}
                maxDate={this.props.maxDate || null}
                showMonthDropdown
                showYearDropdown
                onSelect={this.onDateSelect}
                onMonthChange={date => {
                    this.setState({ date: date });
                }}
                onYearChange={date => {
                    this.setState({ date: date });
                }}
                openToDate={this.props.value}
                renderCustomHeader={this.renderDatePickerHeader}
                selected={this.props.value}
                onChange={this.props.onChange}
            />
        );
    };

    renderMonthSelection = changeMonth => {
        const monthNames = moment.months(this.props.locale);
        const selectedMonth =
            this.state.date && monthNames[getMonth(this.state.date)].toString();

        return (
            <SelectField
                disabled={false}
                field="month"
                label={null}
                onChange={(field, value, e) => {
                    let index = e.detail.index;
                    changeMonth(index)
                }}
                value={selectedMonth}
                options={monthNames.map(option => (
                    {
                        className: DatePicker.datepickerOptionClassName,
                        value: option,
                        selected: option === selectedMonth,
                        label: option
                    }
                ))}
            >
            </SelectField>
        );
    };

    renderYearSelection = changeYear => {
        const { minYear, maxYear } = this.props;
        const years = range(
            minYear ? minYear : 2000,
            maxYear ? maxYear : getYear(new Date()) + 2,
            1
        );

        const selectedYear =
            this.state.date && getYear(this.state.date).toString();

        return (
            <SelectField
                disabled={false}
                field="year"
                label={null}
                onChange={(field, index, e) => {
                    changeYear(years[e.detail.index])
                }}
                value={selectedYear}
                options={years.map(option => (
                    {
                        className: DatePicker.datepickerOptionClassName,
                        value: option,
                        selected: option === selectedYear,
                        label: option
                    }))}
            >
            </SelectField>
        );
    };

    render() {
        return (
            <ClickOutsideWrapper onClickOut={this.onClickOut}>
                <div className="datepicker-wrapper">
                    {this.renderDateField()}
                    {this.renderDatePicker()}
                </div>
            </ClickOutsideWrapper>
        );
    }
}

DatePicker.defaultProps = {
    isNullable: false
}

DatePicker.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date),
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    isNullable: PropTypes.bool,
};

export default injectIntl(DatePicker);
