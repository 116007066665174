import moment from 'moment';
import publicIp from "public-ip";
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { UsingApp, IsLatvianWeb } from "../helpers/AlternativeDesign";
import { MapCampaign } from "../helpers/CampaignHelper";
import { MapLang } from "../helpers/LangHelper";

const storageTokenUser = "userData";
const storageTokenObj = "token";
const storageLoginCampaignInfo = "campaignInfo";
const storagePageSize = "pageSize";
const storageRequestedLocation = "requestedLocation";
const JwtRefreshIntervalMinutes = 10;
const JwtTimeoutIntervalMinutes = 60;
const AppJwtTimeoutIntervalMinutes = 60 * 24 * 30 * 3;
const ForceAppLoginDate = '2024-01-02T14:30:00.000Z';
const roleChosen = "roleChosen";

const Authenticate = {

    roleChosen: roleChosen,

    isLoggedIn() {
        return !!localStorage.getItem(storageTokenObj)
            && !!localStorage.getItem(storageTokenUser)
            && ((this.getTokenTimeDiff() < JwtTimeoutIntervalMinutes && !UsingApp())
                || (UsingApp() && this.getTokenTimeDiff() < AppJwtTimeoutIntervalMinutes
                    && (ForceAppLoginDate > moment.utc().format() || !this.getTokenGeneratedAt() || this.getTokenGeneratedAt() > ForceAppLoginDate))
            );
    },

    logIn($payload) {
        localStorage.setItem(storageTokenUser, JSON.stringify($payload.data));
        this.setTokenFromHeader($payload.headers)
    },

    logOut() {
        localStorage.removeItem(storageTokenObj);
        localStorage.removeItem(storageTokenUser);
    },

    setRole($payload) {
        if ($payload.data.roleModel) {
            let user = this.getUserData();
            user.role = $payload.data.roleModel;
            localStorage.setItem(storageTokenUser, JSON.stringify(user));
            this.setTokenFromHeader($payload.headers)
        }
    },

    changeAgentUserMode() {
        if (this.getRoleId() === this.getUserId()) {
            this.setUserMode(null);
        } else {
            this.setUserMode("Agent");
        }
    },

    setUserMode(userMode) {
        let user = this.getUserData();
        user.userMode = userMode;
        localStorage.setItem(storageTokenUser, JSON.stringify(user));
    },

    isAgent() {
        return this.isLoggedIn() ? this.getUserData().userMode === "Agent" : false;
    },

    isAdmin() {
        if (!this.isLoggedIn()) {
            return false;
        }

        return this.getUserData()?.roles?.includes("Admin") ?? false;
    },

    isPassive() {
        const userData = this.getUserData();
        if (userData) {
            return !!userData.passive;
        }
        return false;
    },

    isCompany() {
        const userData = this.getUserData();
        return userData ? userData.role.isCompany : false;
    },

    getRole() {
        let userData = this.getUserData();
        if (!userData) {
            return null;
        }
        let role = userData.role;
        return role ? role : null;
    },

    getUserData() {
        return this.isLoggedIn() ? JSON.parse(localStorage.getItem(storageTokenUser)) : null;
    },

    getUser() {
        let userData = this.getUserData();
        return userData ? userData.user : null;
    },

    getUserId() {
        let user = this.getUser();
        return user ? user.crmId : null;
    },

    getRoleId() {
        return this.getRole() != null ? this.getRole().crmId : null;
    },

    getToken() {
        return localStorage.getItem(storageTokenObj) ? JSON.parse(localStorage.getItem(storageTokenObj)) : null;
    },

    getTokenJwt() {
        let token = this.getToken();
        return token ? `Bearer ${token.jwt}` : null;
    },

    getTokenGeneratedAt() {
        let token = this.getToken();
        return token ? token.generatedAt : null;
    },

    setTokenFromHeader($token) {
        localStorage.setItem(storageTokenObj, JSON.stringify({
            jwt: $token["bearer"],
            generatedAt: $token["generatedat"],
        }));
    },

    setToken($token) {
        localStorage.setItem(storageTokenObj, JSON.stringify({
            jwt: $token.jwt,
            generatedAt: $token.generatedAt,
        }));
    },

    getTokenTimeDiff() {
        let tokenGeneratedAt = this.getTokenGeneratedAt();
        if (!tokenGeneratedAt) {
            return 0;
        }
        let currentDate = moment.utc();
        let tokenGeneratedAtMom = moment.utc(tokenGeneratedAt);
        let diff = currentDate.diff(tokenGeneratedAtMom, "minutes");
        return diff;
    },

    flagTokenRefresh() {
        let token = this.getToken();
        if (token) {
            token.generatedAt = null;
            this.setToken(token);
        }
    },

    isTokenRefreshable() {
        if (this.getTokenGeneratedAt()) {
            let diff = this.getTokenTimeDiff();
            return this.isLoggedIn() && diff > JwtRefreshIntervalMinutes;
        }
        return false;
    },
    getMainContactId() {
        let role = this.getRole();
        if (!role) {
            return null;
        }
        return role.mainContactCrmId;
    },
    setContactDataUpdated() {
        let currentItem = JSON.parse(localStorage.getItem(storageTokenUser));
        currentItem.role.contactDataUpdateRequired = false;
        currentItem.isNewUser = false;
        localStorage.setItem(storageTokenUser, JSON.stringify(currentItem));
    },
    isAppSession() {
        let userData = !!localStorage.getItem(storageTokenUser) ? JSON.parse(localStorage.getItem(storageTokenUser)) : null;
        return userData ? userData.usingApp : null;
    },
    getClientIp() {
        return publicIp.v4({
            fallbackUrls: [""]
        })
    },

    getAuthConfig(lang, campaignType) {
        const isLatvianWeb = IsLatvianWeb();
        const authority = isLatvianWeb
            ? process.env.REACT_APP_SSO_LV_URL
            : process.env.REACT_APP_SSO_URL;
        const clientId = isLatvianWeb
            ? process.env.REACT_APP_SSO_LV_CLIENT_ID
            : process.env.REACT_APP_SSO_CLIENT_ID;

        return new UserManager({
            userStore: new WebStorageStateStore({ store: localStorage }),
            authority: authority,
            client_id: clientId,
            redirect_uri: window.location.origin + '/logincallback',
            response_type: 'code',
            scope: 'openid profile',
            post_logout_redirect_uri: window.location.origin + '/login',
            loadUserInfo: true,
            response_mode: "fragment",
            extraQueryParams: {
                campaign: campaignType,
                locale: lang
            }
        });
    },

    startLogIn(lang, campaignCode, campaignType, campaignItem, redirectUrl, returnLocation) {
        const campaignInfo = {
            campaignCode: campaignCode || null,
            campaignType: campaignType ? campaignType.toLowerCase() : null,
            campaignItem: campaignItem,
            redirectUrl: redirectUrl,
            usingApp: UsingApp()
        };

        let mgr = this.getAuthConfig(MapLang(lang), MapCampaign(campaignInfo.campaignCode));
        localStorage.setItem(storageLoginCampaignInfo, JSON.stringify(campaignInfo));
        localStorage.setItem(storageRequestedLocation, JSON.stringify(returnLocation || ""));
        mgr.clearStaleState();
        mgr.signinRedirect();
    },

    startLogout() {
        this.logOut();
        let mgr = this.getAuthConfig();
        mgr.signoutRedirect();
    },

    getLoginCampaignInfo() {
        return JSON.parse(localStorage.getItem(storageLoginCampaignInfo) || "{}");
    },

    getPageSize() {
        return parseInt(localStorage.getItem(storagePageSize) || '10');
    },

    setPageSize(pageSize) {
        localStorage.setItem(storagePageSize, pageSize);
    },
    getRequestedLocation() {
        const storedLocation = localStorage.getItem(storageRequestedLocation);
        if (!storedLocation || storedLocation === "undefined") {
            return false;
        }
        return JSON.parse(storedLocation);
    },
    async getUserOidcProfile() {
        const authConfig = this.getAuthConfig();
        const user = await authConfig.getUser();

        return user.profile || null;
    },
    setRoleHasNoEmail() {
        const key = `${this.getRoleId()}_hasNoEmail`;
        localStorage.setItem(key, true);
    },
    getRoleHasNoEmail() {
        const key = `${this.getRoleId()}_hasNoEmail`;
        return !!JSON.parse(localStorage.getItem(key));
    },
    clearRoleHasNoEmail() {
        const key = `${this.getRoleId()}_hasNoEmail`;
        localStorage.removeItem(key);
    }
};

export default Authenticate;
