import { GridCell, GridInner } from "@rmwc/grid";
import React, { useCallback, useEffect, useState } from "react";
import SelectField from "../common/SelectField";
import { Counties } from "../../components/CommunityProject/Counties";

const OPTIONS = ["25", "50", "100", "200", "400"];

const CommunityAmountSelector = ({ setStampsCount, setCounty, availableStamps, showCountyDropdown = false, isDisabled = false }) => {
    const [selectedValue, setSelectedValue] = useState();
    const [selectedCounty, setSelectedCounty] = useState(Counties[0].countyName);
    const [options, setOptions] = useState([]);

    const updateSelectedValues = useCallback((value, options) => {
        setOptions(options);
        setSelectedValue(value);
        setStampsCount?.(value);
    }, []);

    useEffect(() => {
        const validOptions = OPTIONS.map(opt => ({
            value: opt,
            label: opt
        }))
        .filter(o => o.value <= availableStamps);
        const value = validOptions.length > 0 ? validOptions[0].value : "";

        updateSelectedValues(value, validOptions);
    }, [availableStamps, updateSelectedValues]);

    const onChangeValue = (val) => {
        setSelectedValue(val);
        setStampsCount?.(parseInt(val));
    }

    const onChangeCounty = (val) => {
        setSelectedCounty(val);
        setCounty?.(val);
    }

    return (
        <GridInner>
            {showCountyDropdown &&
                <GridCell span={8}>
                    <SelectField
                        field="trees-amount"
                        value={selectedCounty}
                        onChange={(_afterChange, _field, e) => onChangeCounty(e.currentTarget.value)}
                        outlined
                        options={Counties.map(c => {
                            return {
                                value: c.countyName,
                                label: c.countyName
                            }
                        })}
                        addEmptyOption
                        disabled={isDisabled}
                    />
                </GridCell>
            }
            <GridCell span={showCountyDropdown ? 4 : 12}>
                <SelectField
                    field="trees-amount"
                    value={selectedValue}
                    onChange={(_afterChange, _field, e) => onChangeValue(e.currentTarget.value)}
                    outlined
                    options={options}
                    addEmptyOption
                    disabled={isDisabled}
                />
            </GridCell>
        </GridInner>
    );
}

export default CommunityAmountSelector;