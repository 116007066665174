import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from "@rmwc/button";
import { FormattedMessage } from "react-intl";

class FilterButton extends React.Component {
    
    render() {
        return (
            <Button
                className={classnames("mdc-button--outlined", this.props.className,
                { active: this.props.active })}
                onClick={this.props.callback.bind(this, !this.props.active)}
            >
                <i className={this.props.active ? "icon-close": "icon-menu"} />
                <FormattedMessage id={this.props.active ? "General.Filter.Close": "General.Filter.Open"} />
            </Button>
        );
    }
}

FilterButton.propTypes = {
    active: PropTypes.bool,
    callback: PropTypes.func
};

export default (FilterButton);
