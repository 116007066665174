import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import BaseFormComponent from "../common/BaseFormComponent";
import DatePickerRange from "../common/DatePickerRange";
import TextFieldGroup from "../common/TextFieldGroup";
import { statisticsRanges, createRangePresets } from "../common/DateRangePreset";
import SelectDateRangePreset from "../common/SelectDateRangePreset";
import { fetchContracts } from "../../actions/contractActions";
import DropdownMultipleCombobox from "../../components/common/ContractMultiSelect";

class StatisticsFilter extends BaseFormComponent {
    static FieldPeriodStart = "filter.startDate";
    static FieldPeriodEnd = "filter.endDate";
    static FieldContractNumber = "filter.contractNumber";
    static FieldContractName = "filter.contractName";
    static FieldSelectedPeriodPreset = "filter.selectedPeriodPreset";

    constructor(props) {
        super(props);

        this.onFilterPeriodStartDateChange = this.onFilterPeriodStartDateChange.bind(this);
        this.onFilterPeriodEndDateChange = this.onFilterPeriodEndDateChange.bind(this);
        this.onFilterPeriodPresetChange = this.onFilterPeriodPresetChange.bind(this);
        this.onAddContract = this.onAddContract.bind(this);
        this.onContractRemove = this.onContractRemove.bind(this);
        this.filter = this.filter.bind(this);

        this.state = {
            selectedContracts: [],
            periodPresets: createRangePresets(statisticsRanges, this.props.intl),
            filter: {
                ...props.filter,
            }
        };

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    onFilterPeriodStartDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, dateFrom: moment(value) }
        }));
    }

    onFilterPeriodEndDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, dateTo: moment(value) }
        }));
    }

    onFilterPeriodPresetChange(value) {
      if (value) {
        let period = this.state.periodPresets[+value.value];
        if (period) {
          this.setState((prev) => ({
            filter: {
              ...prev.filter,
              selectedPeriodPreset: period,
              selectedPeriodPresetName: period.label,
              dateFrom: moment(period.range.startDate),
              dateTo: moment(period.range.endDate),
            },
          }));
        }
      }
    }

    onAddContract(contractNumber) {
        const contract = this.props.contracts.filter(contract => contract.extraNumber === contractNumber)[0];
        this.state.selectedContracts.push(contract);

        this.setState(prev => ({
            filter: { ...prev.filter, selectedContracts: this.state.selectedContracts}
        }));
    }

    onContractRemove(contractNumber) {
        const contract = this.props.contracts.filter(contract => contract.extraNumber === contractNumber)[0];
        this.state.selectedContracts.splice(this.state.selectedContracts.indexOf(contract), 1);

        this.setState(prev => ({
            filter: { ...prev.filter, selectedContracts: this.state.selectedContracts}
        }));
    }

    filter() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.props.onSubmit(this.state.filter);
    }

    render() {
        const { filter } = this.state;

        return (
            <Grid className="filter-form filter-form--open">
                <GridCell desktop={3} tablet={4} phone={4}>
                    <DropdownMultipleCombobox
                        onAdd={this.onAddContract}
                        onRemove={this.onContractRemove}
                        options={this.props.contractNumbers}
                        placeHolder={this.props.intl.formatMessage({
                            id: 'Statistics.Filter.SelectContracts'
                        })}>

                    </DropdownMultipleCombobox>
                </GridCell>
                <GridCell desktop={2} tablet={4} phone={4}>
                    <TextFieldGroup
                        field={StatisticsFilter.FieldContractName}
                        onChange={this.onChange}
                        value={filter.contractName}
                        label="Statistics.Filter.ContractName" />
                </GridCell>
                <GridCell desktop={4} tablet={4} phone={4} >
                    <DatePickerRange
                      label="Transactions.Filter.Period"
                      fieldStart={StatisticsFilter.FieldPeriodStart}
                      fieldEnd={StatisticsFilter.FieldPeriodEnd}
                      startOnChange={this.onFilterPeriodStartDateChange}
                      endOnChange={this.onFilterPeriodEndDateChange}
                      startValue={filter.dateFrom.toDate()}
                      endValue={filter.dateTo.toDate()}
                      maxStartDate={filter.dateTo.toDate()}
                      minEndDate={filter.dateFrom.toDate()}
                    />
                  </GridCell>
                  <GridCell desktop={3} tablet={4} phone={4} >
                    <SelectDateRangePreset
                        onChange={this.onFilterPeriodPresetChange}
                        field={StatisticsFilter.FieldSelectedPeriodPreset}
                        value={this.state.filter.selectedPeriodPresetName}
                        options={this.state.periodPresets}
                        label= "General.Space"
                        placeholder={this.props.intl.formatMessage({
                            id: 'Transactions.Period.Select'
                        })}
                        />
                </GridCell>

                <GridCell desktop={12} tablet={12} phone={12}>
                    <Button unelevated onClick={this.filter} className="right mt-24 mt-mobile-clear">
                        <i className="icon-wave" />
                        <FormattedMessage id="General.Filter" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}

StatisticsFilter.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    filter: PropTypes.object
};

function toContractNumberArray(contracts){
    let map = contracts.reduce(function (map, obj) {
      map[obj.extraNumber] = obj;
      return map;
    }, {});
    return Object.keys(map);
  }


function mapStateToProps(state) {
    return {
        contractNumbers: toContractNumberArray(state.contracts.data),
        contracts: state.contracts.data,
        fetchingContracts: state.contracts.fetching,
        fetchedContracts: state.contracts.fetched,
    };
  }
  
  function matchDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchContracts
    }, dispatch);
  }
  
  export default injectIntl(connect(
    mapStateToProps,
    matchDispatchToProps
  )(StatisticsFilter));
