import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from "@rmwc/textfield";
import { useIntl } from 'react-intl';
import ValidationHelper from "../../helpers/validationHelper";

const TextAreaGroup = ({ field, value, label, rows, maxLength, onChange, autoComplete, classNames, validator, rules, noLabel, showErrorMessage, validationLabel, onBlur, placeholder, disabled, showCounter }) => {
    const intl = useIntl();
    let labelTranslated = label ? intl.formatMessage({ id: label }) : null;
    let validationLabelTranslated = validationLabel ? intl.formatMessage({ id: validationLabel }) : null;
    const { onBlur: onValidationBlur, validationMessage, helperText, isRequired } = ValidationHelper.getFieldValidationItemsRmwc(validator, rules, validationLabelTranslated || labelTranslated, value);

    let onInputBlur = onValidationBlur;

    if (onBlur) {
        onInputBlur = () => {
            onValidationBlur();
            onBlur();
        };
    }

    // When showErrorMessage is false
    // Then show only red border.
    let validationHelper = showErrorMessage && helperText;

    const textField = <TextField
        textarea
        fullwidth
        rows={rows}
        maxLength={maxLength}
        className={classNames}
        helpText={validationHelper}
        outlined
        label={noLabel ? '' : labelTranslated}
        required={isRequired}
        onBlur={onInputBlur}
        invalid={!!validationMessage}
        autoComplete={autoComplete}
        type="text"
        value={value}
        name={field}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}>
    </TextField>;

    return (
        showCounter
            ? <div className="mdc-text-field--with-counter">
                <div className="counter">
                    {!!value ? value.length : 0}/{maxLength >= 0 ? maxLength : "-"}
                </div>
                {textField}
            </div>
            : textField
    );
}

TextAreaGroup.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    classNames: PropTypes.string,
    validator: PropTypes.object,
    rules: PropTypes.string,
    noLabel: PropTypes.bool,
    showErrorMessage: PropTypes.bool,
    validationLabel: PropTypes.string,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    showCounter: PropTypes.bool
};

TextAreaGroup.defaultProps = {
    type: 'text',
    noLabel: false,
    showErrorMessage: true,
    disabled: false
};

export default TextAreaGroup;
