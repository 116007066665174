import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import HeroSection from "./components/layout/HeroSection";

const DefaultLayout = (props) => {
    const isCombinedAppContainer = useSelector(state => state.appContainer.isCombinedAppContainer);

    return (
        <div id="wrapper" className={classNames({ "container--app": isCombinedAppContainer })}>
            <Header />
            <HeroSection {...props} />
            <section>
                {props.children}
            </section>
            <Footer />
        </div>
    );
}

export default DefaultLayout;