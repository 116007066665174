import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";

export const fetchBookings = (
    start,
    end,
    cardNumber,
  ) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
      type: "FETCH_BOOKINGS",
      payload: BusinessAPI.get(`/api/${accountId}/${roleId}/booking/bookings`, {
        params: {
          crmAccountId: roleId,
          cardNumber: cardNumber,
          periodStart: start,
          periodEnd: end
        },
      }),
    };
  };