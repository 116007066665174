import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";

import BaseFormComponent from "../common/BaseFormComponent";
import DatePickerRange from "../common/DatePickerRange";
import TextFieldGroup from "../common/TextFieldGroup";
import SelectField from "../common/SelectField";
import { StatusPaid, StatusUnpaid } from "../../const/Invoice";

class PaymentCardInvoicesFilter extends BaseFormComponent {
    static FieldInvoicePeriodStart = "filter.startDate";
    static FieldInvoicePeriodEnd = "filter.endDate";
    static FieldInvoiceNumber = "filter.invoiceNumber";
    static FieldContractNumber = "filter.contractNumber";
    static FieldContractName = "filter.contractName";
    static FieldInvoiceStatus = "filter.status";
    static FieldDeadlinePeriodStart = "filter.deadlinePeriodStart";
    static FieldDeadlinePeriodEnd = "filter.deadlinePeriodEnd";

    static OptionsInvoiceStatus = [
        {
            label: "General.All",
            value: ""
        },
        {
            label: "Invoices.Status.Paid",
            value: StatusPaid
        },
        {
            label: "Invoices.Status.Unpaid",
            value: StatusUnpaid
        }
    ];

    constructor(props) {
        super(props);

        this.onFilterInvoicePeriodStartDateChange = this.onFilterInvoicePeriodStartDateChange.bind(this);
        this.onFilterInvoicePeriodEndDateChange = this.onFilterInvoicePeriodEndDateChange.bind(this);
        this.onFilterDeadlinePeriodStartDateChange = this.onFilterDeadlinePeriodStartDateChange.bind(this);
        this.onFilterDeadlinePeriodEndDateChange = this.onFilterDeadlinePeriodEndDateChange.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.filter = this.filter.bind(this);

        this.state = {
            filter: {
                ...props.filter,
            }
        };

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    onFilterInvoicePeriodStartDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, invoiceStartDate: moment(value) }
        }));
    }

    onFilterInvoicePeriodEndDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, invoiceEndDate: moment(value) }
        }));
    }

    onFilterDeadlinePeriodStartDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, deadlineStartDate: !!value ? moment(value) : value }
        }));
    }

    onFilterDeadlinePeriodEndDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, deadlineEndDate: !!value ? moment(value) : value }
        }));
    }

    onChangeStatus(afterChange, field, e) {
        let index = e.detail.index;
        let selected = PaymentCardInvoicesFilter.OptionsInvoiceStatus[index];
        this.setState({
            filter: {
                ...this.state.filter,
                status: selected.value
            }
        });
    }

    filter() {

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.props.onSubmit(this.state.filter);
    }

    render() {
        const { filter } = this.state;

        const optionsStatus = PaymentCardInvoicesFilter.OptionsInvoiceStatus.map(x => (
            {
                label: this.props.intl.formatMessage({ id: x.label }),
                value: x.value,
                selected: x.value === filter.status,
            }
        ));

        return (
            <Grid className={classnames("filter-form", { "filter-form--open": this.props.open })}>

                <GridCell desktop={6} tablet={4} phone={4}>
                    <DatePickerRange
                        label="Invoices.Filter.InvoiceDatePeriod"
                        fieldStart={PaymentCardInvoicesFilter.FieldInvoicePeriodStart}
                        fieldEnd={PaymentCardInvoicesFilter.FieldInvoicePeriodEnd}
                        startOnChange={this.onFilterInvoicePeriodStartDateChange}
                        endOnChange={this.onFilterInvoicePeriodEndDateChange}
                        startValue={filter.invoiceStartDate.toDate()}
                        endValue={filter.invoiceEndDate.toDate()}
                        maxStartDate={filter.invoiceEndDate.toDate()}
                        minEndDate={filter.invoiceStartDate.toDate()}
                    />
                </GridCell>
                <GridCell desktop={6} tablet={4} phone={4}>
                    <DatePickerRange
                        label="Invoices.Filter.DeadlinePeriod"
                        fieldStart={PaymentCardInvoicesFilter.FieldDeadlinePeriodStart}
                        fieldEnd={PaymentCardInvoicesFilter.FieldDeadlinePeriodEnd}
                        startOnChange={this.onFilterDeadlinePeriodStartDateChange}
                        endOnChange={this.onFilterDeadlinePeriodEndDateChange}
                        startValue={filter.deadlineStartDate?.toDate()}
                        endValue={filter.deadlineEndDate?.toDate()}
                        maxStartDate={filter.deadlineEndDate?.toDate()}
                        minEndDate={filter.deadlineStartDate?.toDate()}
                        isNullableStart
                        isNullableEnd
                    />
                </GridCell>

                <GridCell desktop={2} tablet={4} phone={4}>
                    <TextFieldGroup
                        field={PaymentCardInvoicesFilter.FieldContractNumber}
                        onChange={this.onChange}
                        value={filter.contractNumber}
                        label="Invoices.Filter.ContractNumber" />
                </GridCell>
                <GridCell desktop={2} tablet={4} phone={4}>
                    <TextFieldGroup
                        field={PaymentCardInvoicesFilter.FieldContractName}
                        onChange={this.onChange}
                        value={filter.contractName}
                        label="Invoices.Filter.ContractName" />
                </GridCell>

                <GridCell desktop={2} tablet={3} phone={4}>
                    <TextFieldGroup
                        field={PaymentCardInvoicesFilter.FieldInvoiceNumber}
                        onChange={this.onChange}
                        value={filter.invoiceNumber}
                        label="Invoices.Filter.InvoiceNumber"
                        placeholder="1234567890"
                    />
                </GridCell>

                <GridCell desktop={2} tablet={3} phone={4}>
                    <SelectField
                        field={PaymentCardInvoicesFilter.FieldInvoiceStatus}
                        name={PaymentCardInvoicesFilter.FieldInvoiceStatus}
                        label="Invoices.Filter.Status"
                        value={filter.status}
                        onChange={this.onChangeStatus}
                        enhanced
                        menu
                        outlined
                        options={optionsStatus} />
                </GridCell>
                <GridCell desktop={4} tablet={2} phone={4}>
                    <Button unelevated onClick={this.filter} className="right mt-24 mt-mobile-clear">
                        <i className="icon-wave" />
                        <FormattedMessage id="General.Filter" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}

PaymentCardInvoicesFilter.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    filter: PropTypes.object
};

export default injectIntl(PaymentCardInvoicesFilter);
