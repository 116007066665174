import { Grid, GridCell } from "@rmwc/grid";
import { useEffect, useState } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchGasoMeterList, submitGasoMeter } from "../../actions/gasoMeterActions";
import { Button } from "@rmwc/button";
import { Typography } from "@rmwc/typography";
import { ListDivider } from "@rmwc/list";
import "../../styles/react-components/gasoMeterForm.scss";
import moment from "moment";
import Loader from "../common/Loader";
import PropTypes from "prop-types";
import AlertMessage from '../common/AlertMessage';
import { isNumber } from '../../helpers/numberHelper';

const GasoMeterForm = ({selectedLocation}) => {
    const { data: item, fetched: gasoFetched, fetching: gasoFetching } = useSelector((state) => state.gasoMeter);
    const { fetching: isSubmitting, error: submitError, fetched: submitDone } = useSelector((state) => state.submitGasoMeter);
    const dispatch = useDispatch();
    const formInitialState = {
        oldValue: null,
        newValue: "",
        difference: '',
    }
    const [form, setForm] = useState(formInitialState);

    useEffect(() => {
        setForm(formInitialState);
        if (selectedLocation) {
            dispatch(fetchGasoMeterList({locationId: selectedLocation.eicCode}));
        }
    }, [dispatch, selectedLocation])

    useEffect(() => {
        if (!form.oldValue && gasoFetched) {
            setForm({
                ...form,
                newValue: item?.currentReading ?? '',
                oldValue: item?.priorReading ?? ''
            })
        }
    }, [gasoFetched, item]);

    if (!item && gasoFetching) {
        return <Loader type={Loader.TYPE_CENTER} />
    }

    if (!selectedLocation || !item || !gasoFetched) {
        return null;
    }

    const onSubmit = () => {
        dispatch(submitGasoMeter({
            reading: form.newValue,
            meterId: item.meterId,
            locationId: selectedLocation.eicCode,
            currentReadingDate: item.currentReadingDate
        }));
    }

    const getDiff = () => {
        if (!isNumber(form.newValue) || !isNumber(form.oldValue) || item.priorReadingType !== "SUBMITTED" || form.newValue <= form.oldValue) {
            return '';
        }
        return Number(form.newValue) - Number(form.oldValue);
    }

    return (
        <>
            <Grid className="gaso-meter-header">
                <GridCell span={6}>
                    <Typography use="headline6" tag="h2">
                        <FormattedMessage id="Service.GasoMeterSubmission.Address" />
                    </Typography>
                    <Typography use="subtitle2" tag="h3">
                        { selectedLocation.address ?? "" }
                    </Typography>
                </GridCell>
                <GridCell span={3}>
                    <Typography use="headline6" tag="h2">
                        <FormattedMessage id="Service.GasoMeterSubmission.ObjectEic" />
                    </Typography>
                    <Typography use="subtitle2" tag="h3">
                        { selectedLocation.eicCode ?? "" }
                    </Typography>
                </GridCell>
                <GridCell span={3}>
                    <Typography use="headline6" tag="h2">
                        <FormattedMessage id="Service.GasoMeterSubmission.MeterId" />
                    </Typography>
                    <Typography use="subtitle2" tag="h3">
                        {item.meterId ?? "" }
                    </Typography>
                </GridCell>
            </Grid>
            <Grid className="gaso-meter-body">
                {submitError &&
                    <GridCell span={12}>
                        <AlertMessage
                            type={AlertMessage.TYPE_ALERT}
                            isSmall={true}
                            title={<FormattedMessage id="Service.GasoMeterSubmission.Error" values={{ errorMessage: submitError }} />}
                        />
                    </GridCell>
                }
                {submitDone &&
                    <GridCell span={12}>
                        <AlertMessage
                            type={AlertMessage.TYPE_DONE}
                            isSmall={true}
                            title={<FormattedMessage id="Service.GasoMeterSubmission.Success" values={{ reading: item.newValue }} />}
                        />
                    </GridCell>
                }
                <GridCell span={12}>
                    <Grid>
                        <GridCell span={4}>
                            <TextFieldGroup
                                label="Service.GasoMeterSubmission.NewValue"
                                value={form.newValue}
                                type="number"
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setForm({
                                        ...form,
                                        newValue: Number(value),
                                        difference: Number(form.oldValue) - value,
                                    })
                                }}
                                field=""
                                disabled={false}
                            />
                        </GridCell>
                        <GridCell span={4}>
                            <TextFieldGroup
                                label="Service.GasoMeterSubmission.Difference"
                                value={getDiff()}
                                onChange={() => {}}
                                field=""
                                disabled={true}
                            />
                        </GridCell>
                    </Grid>
                </GridCell>
                <GridCell span={12}>
                    <Typography use="body1">
                        <FormattedMessage id="Service.GasoMeterSubmission.PreviousReading" values={{
                            currentReading: item.currentReading,
                            currentReadingDate: moment(item.currentReadingDate).format("DD.MM.YYYY"),
                            priorReading: item.priorReading,
                            priorReadingDate: moment(item.priorReadingDate).format("DD.MM.YYYY")
                        }} />
                    </Typography>
                </GridCell>
                <GridCell span={12}>
                    <ListDivider />
                </GridCell>
                <GridCell span={12}>
                    <Button unelevated onClick={onSubmit} className="right mt-15" disabled={gasoFetching || isSubmitting}>
                        <FormattedMessage id="Service.GasoMeterSubmission.Submit" />
                    </Button>    
                </GridCell>
            </Grid>
        </>
    )
}

GasoMeterForm.propTypes = {
    selectedLocation: PropTypes.shape({
        eicCode: PropTypes.string,
        address: PropTypes.string
    })
}

export default GasoMeterForm;