import BaseIdCodeValidator from "./BaseIdCodeValidator";

class EeIdCodeValidator extends BaseIdCodeValidator {
    /**
     *  Gets the control number of personal ID.
     *
     *  @return Number
     */
    getControlNumber(code) {
        const multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
        const multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];

        let total = this.calculateCheckSum(code, multiplier1);

        let mod = total % 11;

        if (10 === mod) {

            total = this.calculateCheckSum(code, multiplier2);

            mod = total % 11;
            if (10 === mod) {
                mod = 0;
            }
        }
        return mod;
    }

    /**
     *  Validates the Estonian personal ID.
     *
     *  @return boolean
     */
    validate(code) {
        if (code.length !== 11) {
            return false;
        }

        let control = this.getControlNumber(code);

        return control === parseInt(code.charAt(10), 10);
    }

    validateIsOver16(code) {
        let digit = parseInt(code.charAt(0));
        const century = (17 + Math.floor(digit / 2) + digit % 2) * 100;
        digit = parseInt(code.substring(1, 3));

        const year = century + digit;
        const day = parseInt(code.substring(5, 7));
        const month = parseInt(code.substring(3, 5)) - 1;
        const birthDate = new Date(year, month, day);

        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 16);
        return birthDate <= currentDate;
    }
}

export default EeIdCodeValidator;