import axios from "axios";
import 'moment/locale/et';
import 'moment/locale/ru';
import 'moment/locale/lv';
import moment from "moment/moment";
import etMessages from "./et.json";
import enMessages from "./en.json";
import ruMessages from "./ru.json";
import lvMessages from "./lv.json";

const Lang = {

    Et: "et",
    En: "en",
    Ru: "ru",
    Lv: "lv",

    Messages: "messages",
    MessagesLastUpdate: "messagesLastUpdate",
    /**
     * Set current lang.
     * NB! Also sets default header for axios query.
     * So server can know in what lang it should return messages.
     *
     * @param {String} lang For example, "et", "en", "ru", "lv"
     */
    setLang(lang) {
        localStorage.setItem("lang", lang);
        localStorage.setItem("updateLangToSSO", "true");
        this.setMomentLang(lang);
        axios.defaults.headers.common['Accept-Language'] = lang;
        this.setHtmlLang(lang);
    },

    setMomentLang(lang) {
        moment.locale(lang);
    },

    setHtmlLang(lang) {
        document.documentElement.lang = lang;
    },

    /**
     * Get messages for lang.
     *
     * @param {String} lang For example, "et", "en", "ru"
     *
     * @return {Object}
     */
    getMessages(lang) {
        let messages = null;

        if (lang) {
            messages = JSON.parse(localStorage.getItem(lang + this.Messages));
        }

        return messages;
    },

    /**
     * Get initial state for Store.
     *
     * @return {Object}
     */
    getInitialState() {
        this.setDefaultMessages();
        const lang = this.getLang();
        this.setMomentLang(lang);
        this.setHtmlLang(lang);

        return {
            intl: {
                locale: lang,
                defaultLocale: this.getDefaultLocale(),
                onError: () => {},
                messages: this.getMessages(lang),
            },
        };
    },

    /**
     * Set Default Messages to LocalStorage, as that's where the translations are taken from
     * Done so we have at least the base login screen in translations available.
     * The other translations are available from api.
     */
    setDefaultMessages() {
        if (!localStorage.getItem(this.MessagesLastUpdate)) {
            localStorage.setItem(this.Et + this.Messages, JSON.stringify(etMessages));
            localStorage.setItem(this.En + this.Messages, JSON.stringify(enMessages));
            localStorage.setItem(this.Ru + this.Messages, JSON.stringify(ruMessages));
            localStorage.setItem(this.Lv + this.Messages, JSON.stringify(lvMessages));
        }
    },

    /**
     * Get the default fallback language for the site.
     *
     * @returns {String}
     */
    getDefaultLocale() {
        const path = window.location.hostname;
        return path.includes("alexela.lv") ? this.Lv : this.Et;
    },

    /**
     * Get current language.
     * When there is no current language then fallback to 'et'.
     *
     * @return {String}
     */
    getLang() {
        return localStorage.getItem("lang") || this.getDefaultLocale();
    },

    /**
     * Return the subset of languages that are supported under the current country/website.
     *
     * @param {String} country Current country
     * @returns {Array} All supported languages for the current country
     */
    getLanguagesByCountry(country) {
        switch (country) {
            case "lv":
                return [
                    { label: 'LV', value: this.Lv },
                    { label: 'RU', value: this.Ru },
                    { label: 'EN', value: this.En }
                ];
            case "et":
            default:
                return [
                    { label: 'ET', value: this.Et },
                    { label: 'RU', value: this.Ru },
                    { label: 'EN', value: this.En }
                ];
        }
    },

    exists(lang, country) {
        let languages = this.getLanguagesByCountry(country);

        return !!languages.find(o => o.value === lang);
    },

    getLangToSSO() {
        if (localStorage.getItem("updateLangToSSO")) {
            localStorage.removeItem("updateLangToSSO");
            return this.getLang();
        }
        return null;
    }
};

export default Lang;
