import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@rmwc/button";
import { Link } from "react-router-dom";
import { InvoicesPath } from "../../const/routes";

const InvoiceBackButton = () => {
    return (
        <Button
            className="mdc-button--text mdc-button--secondary"
            icon={{
                icon: "arrow-left",
                strategy: "className",
                basename: "icon",
                prefix: "icon-",
            }}
            tag={Link}
            to={InvoicesPath}
        >
            <FormattedMessage id="Invoice.Back" />
        </Button>
    );
}

export default InvoiceBackButton;