import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";

export const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";
export const FETCH_UNREAD_USER_NOTIFICATIONS = "FETCH_UNREAD_USER_NOTIFICATIONS";
export const PUT_USER_NOTIFICATIONS_READ = "PUT_USER_NOTIFICATION_READ";
export const PUT_ALL_USER_NOTIFICATIONS_READ = "PUT_ALL_USER_NOTIFICATION_READ";
export const FETCH_MARKETING_NOTIFICATIONS = 'FETCH_MARKETING_NOTIFICATIONS';
export const FETCH_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS = 'FETCH_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS';
export const ADD_NEW_NOTIFICATION_TO_USERS = 'ADD_NEW_NOTIFICATION_TO_USERS';
export const RESET_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS = 'RESET_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS';
export const SEND_SMS_MESSAGE = 'SEND_SMS_MESSAGE';
export const FETCH_SMS_MESSAGE_LOG = 'FETCH_SMS_MESSAGE_LOG';
export const FETCH_SMS_TEMPLATES = 'FETCH_SMS_TEMPLATES';
export const CREATE_SMS_TEMPLATE = 'CREATE_SMS_TEMPLATE';
export const PUT_SMS_TEMPLATE = 'PUT_SMS_TEMPLATE';

export const fetchUserNotifications = (page, limit) => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: FETCH_USER_NOTIFICATIONS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/notification`, {
            params: {
                pageNr: page,
                limit: limit
            },
        })
    };
};

export const fetchUnreadUserNotifications = () => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: FETCH_UNREAD_USER_NOTIFICATIONS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/notification/unread`)
    }
}

export const fetchPreviousMarketingNotificationsAsync = (page, limit, text, types) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_MARKETING_NOTIFICATIONS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/notification/marketing`, {
            params: {
                pageNr: page,
                limit: limit,
                text: text,
                types: types
            }
        })
    };
}

export const putUserNotificationsAsRead = (ids) => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: PUT_USER_NOTIFICATIONS_READ,
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/notification/mark-as-read`, ids)
    };
}

export const putAllUserNotificationsAsRead = () => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: PUT_ALL_USER_NOTIFICATIONS_READ,
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/notification/mark-all-as-read`)
    };
}

export const fetchCountOfAccountsForMarketingNotification = (crmIds = []) => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: FETCH_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS,
        payload: BusinessAPI.post(
            `/api/${accountId}/${roleId}/notification/marketing/get-account-counts`,
            crmIds
        )
    };
}

export const resetCountOfAccountsForMarketingNotification = () => {
    return {
        type: RESET_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS
    }
}

export const addNewNotificationToUsers = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: ADD_NEW_NOTIFICATION_TO_USERS,
        payload: BusinessAPI.post(
            `/api/${accountId}/${roleId}/notification/marketing/add-to-users`,
            payload
        )
    };
}

export const addNewNotificationToAllUsers = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: ADD_NEW_NOTIFICATION_TO_USERS,
        payload: BusinessAPI.post(
            `/api/${accountId}/${roleId}/notification/marketing/add-to-all-users`,
            payload
        )
    };
}

export const sendSmsMessage = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: SEND_SMS_MESSAGE,
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/notification/marketing/sms`, payload)
    }
}

export const fetchSmsMessageLog = (page, limit) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_SMS_MESSAGE_LOG,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/notification/marketing/sms-messages-log`, {
            params: {
                pageNr: page,
                limit: limit,
            }
        })
    }
}

export const fetchSmsTemplates = () => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: FETCH_SMS_TEMPLATES,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/smstemplates`)
    }
}

export const createSmsTemplate = (payload) => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: CREATE_SMS_TEMPLATE,
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/smstemplates`, payload)
    }
}

export const putSmsTemplate = (id, payload) => {
    let accountId = auth.getUserId();
    let roleId = auth.getRoleId();

    return {
        type: PUT_SMS_TEMPLATE,
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/smstemplates/${id}`, payload)
    }
}