import auth from "../auth/authenticate";
import BusinessApi from './BusinessApi';

export const FETCH_CARDS_DISCOUNTS = 'FETCH_CARDS_DISCOUNTS';
export const fetchCardsDiscounts = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: FETCH_CARDS_DISCOUNTS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/bonuscard/cards-discounts`)
    };
};
