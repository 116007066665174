const FixedPrice = "fixed_price";
const Payback = "payback";
const StockPrice = "stock_price";
const DynamicPrice = "dynamic_price";
const FixedDegrade = "fix_degrade";
const UniversalPrice = "universal_price";
const UniversalDynamicPrice = "universal_dynamic_price";

const ProductType = { FixedPrice, Payback, StockPrice, DynamicPrice, FixedDegrade, UniversalPrice, UniversalDynamicPrice };

export default ProductType;

