import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const fetchCompetitors = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'FETCH_COMPETITORS',
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/contract/competitors`)
    };
};

export const fetchGasCompetitors = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'FETCH_GAS_COMPETITORS',
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/contract/competitors/gas`)
    };
};