export const PaymentTypes =  [
    {
        value: "",
        label: "Payment.Type.All"
    },
    {
        value: "PAYMENT_TYPE.CASH",
        label: "Payment.Type.PAYMENT_TYPE.CASH"
    },
    {
        value: "PAYMENT_TYPE.TRANSFER",
        label: "Payment.Type.PAYMENT_TYPE.TRANSFER"
    },
    {
        value: "PAYMENT_TYPE.BALANCE_TRANSFER",
        label: "Payment.Type.PAYMENT_TYPE.BALANCE_TRANSFER"
    },
    {
        value: "PAYMENT_TYPE.EQUALIZATION",
        label: "Payment.Type.PAYMENT_TYPE.EQUALIZATION"
    },
    {
        value: "PAYMENT_TYPE.SPONSORSHIP",
        label: "Payment.Type.PAYMENT_TYPE.SPONSORSHIP"
    },    {
        value: "PAYMENT_TYPE.ROUNDING_OFF",
        label: "Payment.Type.PAYMENT_TYPE.ROUNDING_OFF"
    },
    {
        value: "PAYMENT_TYPE.COLLECTION_OF_PAYMENTS",
        label: "Payment.Type.PAYMENT_TYPE.COLLECTION_OF_PAYMENTS"
    },    
    {
        value: "PAYMENT_TYPE.OWN_COST",
        label: "Payment.Type.PAYMENT_TYPE.OWN_COST"
    },    
    {
        value: "PAYMENT_TYPE.ENDING_OF_CONTRACT",
        label: "Payment.Type.PAYMENT_TYPE.ENDING_OF_CONTRACT"
    },    
    {
        value: "PAYMENT_TYPE.CORRECTION",
        label: "Payment.Type.PAYMENT_TYPE.CORRECTION"
    },    
    {
        value: "PAYMENT_TYPE.HOPELESS_DEPT",
        label: "Payment.Type.PAYMENT_TYPE.HOPELESS_DEPT"
    },
    {
        value: "PAYMENT_TYPE.PENALTY_FROM_SALARY",
        label: "Payment.Type.PAYMENT_TYPE.PENALTY_FROM_SALARY"
    },
    {
        value: "PAYMENT_TYPE.CONVERT_TO_LOAN",
        label: "Payment.Type.PAYMENT_TYPE.CONVERT_TO_LOAN"
    },
    {
        value: "PAYMENT_TYPE.TRANSFER_CLAIM",
        label: "Payment.Type.PAYMENT_TYPE.TRANSFER_CLAIM"
    },
    {
        value: "PAYMENT_TYPE.VIRTUAL",
        label: "Payment.Type.PAYMENT_TYPE.VIRTUAL"
    },
    {
        value: "PAYMENT_TYPE.VIRTUAL_LV",
        label: "Payment.Type.PAYMENT_TYPE.VIRTUAL_LV"
    },
    {
        value: "PAYMENT_TYPE.VIRTUAL_LT",
        label: "Payment.Type.PAYMENT_TYPE.VIRTUAL_LT"
    },
    {
        value: "PAYMENT_TYPE.BALANCE CORRECTION",
        label: "Payment.Type.PAYMENT_TYPE.BALANCE CORRECTION"
    },
    {
        value: "PAYMENT_TYPE.LV MUUDELT ARVETELT TAGASTATUD KM",
        label: "Payment.Type.PAYMENT_TYPE.LV MUUDELT ARVETELT TAGASTATUD KM"
    },
    {
        value: "PAYMENT_TYPE.LT MUUDELT ARVETELT TAGASTATUD KM",
        label: "Payment.Type.PAYMENT_TYPE.LT MUUDELT ARVETELT TAGASTATUD KM"
    },
    {
        value: "PAYMENT_TYPE.PL TAGASTAMISELE KUULUV KM",
        label: "Payment.Type.PAYMENT_TYPE.PL TAGASTAMISELE KUULUV KM"
    },
    {
        value: "PAYMENT_TYPE.PL MUUDELT ARVETELT TAGASTATUD KM",
        label: "Payment.Type.PAYMENT_TYPE.PL MUUDELT ARVETELT TAGASTATUD KM"
    },
    {
        value: "PAYMENT_TYPE.AEGUNUD_KINKEKAARDID",
        label: "Payment.Type.PAYMENT_TYPE.AEGUNUD_KINKEKAARDID"
    },
    {
        value: "PAYMENT_TYPE.VIRTUAL_SE",
        label: "Payment.Type.PAYMENT_TYPE.VIRTUAL_SE"
    },
    {
        value: "PAYMENT_TYPE.SE MUUDELT ARVETELT TAGASTATUD KM",
        label: "Payment.Type.PAYMENT_TYPE.SE MUUDELT ARVETELT TAGASTATUD KM"
    },
    {
        value: "PAYMENT_TYPE.GIFT_CARD",
        label: "Payment.Type.PAYMENT_TYPE.GIFT_CARD"
    },
    {
        value: "PAYMENT_TYPE.E-SHOP_GIFT_CARD",
        label: "Payment.Type.PAYMENT_TYPE.E-SHOP_GIFT_CARD"
    },
    {
        value: "PAYMENT_TYPE._REFUND",
        label: "Payment.Type.PAYMENT_TYPE._REFUND"
    },
    {
        value: "PAYMENT_TYPE._ARVE SAF-I",
        label: "Payment.Type.PAYMENT_TYPE._ARVE SAF-I"
    },
]
