import React, { useEffect, useRef, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Typography } from "@rmwc/typography";

import { fetchUnreadUserNotifications, putUserNotificationsAsRead } from "../../actions/notificationActions";
import NotificationItem from "./NotificationItem";
import { NotificationsPath } from "../../const/routes";
import { Button } from "@rmwc/button";
import AlertMessageInline from "../common/AlertMessageInline";
import useMenu from "../../helpers/hooks/useMenu";
import { getNotificationActionInfo } from "../../helpers/notificationHelper";

const NOTIFICATIONS_READ_TIMEOUT = 6000;


const NotificationsPanel = ({ isOpen, onHide, isFullpage }) => {

    const unreadFetching = useSelector(state => state.unreadUserNotifications.fetching);
    const unreadFetched = useSelector(state => state.unreadUserNotifications.fetched);
    const unread = useSelector(state => state.unreadUserNotifications.data);
    const hasItems = !!unread && unread.length > 0;

    const dispatch = useDispatch();
    const [, setMenuSelection] = useMenu();
    const history = useHistory();
    const intl = useIntl();

    let timeout = useRef();

    const goToNotificationsPage = () => {
        setMenuSelection({ path: NotificationsPath });
        history.push(NotificationsPath);
        if (!!onHide) {
            onHide();
        }
    }

    useEffect(() => {
        if (!unreadFetched && !unreadFetching) {
            dispatch(fetchUnreadUserNotifications());
        }
    }, []);

    const markAsRead = useCallback(() => {
        console.debug("timeout reached", timeout);

        clearTimeout(timeout.current);

        if (!!unread && unread.length > 0) {
            const ids = unread.map(x => x.id);
            dispatch(putUserNotificationsAsRead(ids));
        }
    }, [dispatch, unread]);

    useEffect(() => {
        clearTimeout(timeout.current);

        if (isOpen) {
            timeout.current = setTimeout(() => markAsRead(), NOTIFICATIONS_READ_TIMEOUT);
        }
    }, [isOpen, hasItems, markAsRead]);

    return (
        <div className={classNames("notifications-panel", { "notifications-panel--fullpage": isFullpage })}>
            <div className="notifications-panel__header">
                <Typography className="mdc-typography mdc-theme--secondary">
                    <FormattedMessage id="Notifications.Panel.Title" />
                </Typography>
            </div>
            <div className={classNames(
                "notifications-panel__content",
                { "notifications-panel__content--no-items": !hasItems }
            )}>
                {!hasItems &&
                    <AlertMessageInline type={AlertMessageInline.TYPE_NOTICE}>
                        <FormattedMessage id="Notifications.Panel.NoNewItems" />
                    </AlertMessageInline>}

                {hasItems &&
                    unread.map((x, i) => (
                        <NotificationItem
                            key={"notification-" + i}
                            item={x}
                            actionAs="click"
                            cta={getNotificationActionInfo(x.type, x.metaInfo, history, intl)} />
                    ))}
            </div>
            <div className="notifications-panel__footer">
                <Button
                    onClick={goToNotificationsPage}
                    className="mdc-button mdc-button--outlined mdc-button--thin mdc-button--wide mdc-button--primary">
                    <FormattedMessage id="Notifications.Panel.ShowAll" />
                </Button>
            </div>
        </div>
    );
}

NotificationsPanel.defaultProps = {
    isOpen: true,
    isFullpage: false
}

NotificationsPanel.propTypes = {
    isOpen: PropTypes.bool,
    isFullpage: PropTypes.bool,
    onHide: PropTypes.func,
}

export default NotificationsPanel;