import {GridCell, GridInner} from "@rmwc/grid";
import {Typography} from "@rmwc/typography";
import {FormattedMessage} from "react-intl";
import AlertMessage from "../common/AlertMessage";
import Loader from "../common/Loader";
import CheckboxField from "../common/CheckboxField";
import {Button} from "@rmwc/button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {postPrintPaperReceipt} from "../../actions/accountActions";
import {withFeature} from "flagged";
import {PrintPaperReceiptSetting} from "../../const/featureFlags";

const PaperReceiptSettings = () => {
    const account = useSelector(state => state.account);
    const [printPaperReceipt, setPrintPaperReceipt] = useState(false);
    const saving = useSelector(state => state.postPrintPaperReceipt);
    const dispatch = useDispatch();

    useEffect(() => {
        if (account.fetched) {
            setPrintPaperReceipt(account.data.printPaperReceipt);
        }
    }, [account]);

    const saveSettings = () => {
        dispatch(postPrintPaperReceipt({printPaperReceipt}));
    };

    return (
        <GridInner>
            <GridCell span={12}>
                <Typography
                    use="headline3"
                    className="mdc-typography mdc-theme--primary mb-mobile-20">
                    <FormattedMessage id="Settings.PaperReceiptSettings.Title"/>
                </Typography>
            </GridCell>

            <GridCell span={12}>
                {saving.error &&
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        isSmall
                        scrollToMessage
                        title={<FormattedMessage id="Settings.PaperReceiptSettings.Error"/>}
                        className="mb-15"/>
                }

                {saving.fetched &&
                    <AlertMessage
                        type={AlertMessage.TYPE_DONE}
                        isSmall
                        scrollToMessage
                        title={<FormattedMessage id="Settings.PaperReceiptSettings.Saved"/>}
                        className="mb-15"/>
                }

                <GridInner style={{position: "relative"}}>
                    {(account.fetching) &&
                        <Loader type={Loader.TYPE_COVER}/>}

                    {account.fetched &&
                        <>
                            <GridCell span={12}>
                                <CheckboxField
                                    field={`PaperReceiptSettings`}
                                    checked={printPaperReceipt}
                                    label={`Settings.PaperReceiptSettings`}
                                    onChange={e => setPrintPaperReceipt(e.target.checked)}
                                    containerClassName="mt-8 mb-8 mr-24"/>
                            </GridCell>
                            <GridCell span={12}>
                                <Button
                                    unelevated
                                    onClick={saveSettings}>
                                    {saving.fetching
                                        ? <Loader type={Loader.TYPE_BUTTON}/>
                                        : <FormattedMessage id="Settings.Notifications.Save"/>}
                                </Button>
                            </GridCell>
                        </>
                    }
                </GridInner>
            </GridCell>
        </GridInner>
    )
}

export default withFeature(PrintPaperReceiptSetting)(PaperReceiptSettings);
