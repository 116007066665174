import { POST_COMMUNITY_PROJECT_REGISTER } from "../../actions/communityProjectActions";

export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${POST_COMMUNITY_PROJECT_REGISTER}_PENDING`: {
            return {...state, fetching: true, fetched: false, error: null};
        }
        case `${POST_COMMUNITY_PROJECT_REGISTER}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${POST_COMMUNITY_PROJECT_REGISTER}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}