import React from "react";
import moment from "moment";
import BaseFormComponent from "../common/BaseFormComponent";
import { List } from "@rmwc/list";
import RadioField from "../common/RadioField";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GridCell, GridInner } from "@rmwc/grid";
import TextFieldGroup from "../common/TextFieldGroup";
import TextAreaGroup from "../common/TextAreaGroup";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";
import PhoneFieldGroup from "../../components/common/PhoneFieldGroup";
import SimpleReactValidator from "simple-react-validator";
import AddressForm from "../AddressForm/AddressForm";
import { sendOrder, fetchWholesaleFuels, resetPurchaseRight } from "../../actions/fuelTaxiOrderActions"
import AlertMessage from "../common/AlertMessage";
import CheckboxField from "../common/CheckboxField";
import PurchaseRight from "./PurchaseRight";
import auth from "../../auth/authenticate";

import "../../styles/react-components/fuelTaxiOrderForm.scss";

class FuelTaxiOrderForm extends BaseFormComponent {

    static fieldRadioSelectProduct = "product";
    static fieldAmount = "amount";
    static fieldEmail = "email";
    static fieldCompany = "company";
    static fieldPhoneNr = "phone";
    static fieldInvoiceAddress = "invoiceAddress";
    static fieldDeliveryAddress = "deliveryAddress";
    static fieldInvoiceAddressDiffersFromDelivery = "invoiceAddressDiffersFromDelivery";
    static comment = "comment";

    constructor(props) {
        super(props);

        this.state = {
            [FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery]: false,
            [FuelTaxiOrderForm.fieldRadioSelectProduct]: { name: "" },
            [FuelTaxiOrderForm.fieldAmount]: "",
            [FuelTaxiOrderForm.fieldEmail]: "",
            [FuelTaxiOrderForm.fieldCompany]: "",
            [FuelTaxiOrderForm.fieldPhoneNr]: "",
            [FuelTaxiOrderForm.comment]: "",
            selectedCompany: "",
            selectedPurpose: "",
            invoiceAddress: "",
            deliveryAddress: "",
            addressRefresh: 0,
            role: auth.getRole(),
            isCompany: auth.isCompany(),
            edkrRequestStartTime: null,
            isSessionTimedOut: false
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeSelectedProduct = this.onChangeSelectedProduct.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.updateInvoiceAddress = this.updateInvoiceAddress.bind(this);
        this.updateDeliveryAddress = this.updateDeliveryAddress.bind(this);
        this.onChangeEdkrCompany = this.onChangeEdkrCompany.bind(this);

        this.edkrSessionTimeout = 30 * 60 * 1000; // 30 minutes

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale,

            validators: {
                phoneformat: {
                    message: this.props.intl.formatMessage({ id: "Error.Validator.Phoneformat" }),
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^\d{7,14}$/i)
                    },
                    messageReplace: (message, params) => message.replace(":values", this.helpers.toSentence(params)),
                },
                amount: {
                    message: this.props.intl.formatMessage({ id: "Error.Validator.Amount" }),
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /([5-9]\d{2}|[1-9]\d{3})/)
                    },
                    messageReplace: (message, params) => message.replace(":values", this.helpers.toSentence(params)),
                }
            }
        });
    }


    componentDidMount() {
        if (!this.props.wholesaleFuelsFetching && !this.props.wholesaleFuelsFetched) {
            this.props.fetchWholesaleFuels();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { wholesaleFuels, purchaseRight, purchaseRightFetched } = this.props;
        if (wholesaleFuels !== prevProps.wholesaleFuels && wholesaleFuels && wholesaleFuels.length > 0) {
            this.setState({
                [FuelTaxiOrderForm.fieldRadioSelectProduct]: wholesaleFuels[0]
            });
        }
        if (prevProps.purchaseRightFetched !== purchaseRightFetched && purchaseRight.isAllowed) {
            this.setDefaultCompany();
            this.setSessionTimeout();
        }
    }

    setSessionTimeout() {
        this.setState({ edkrRequestStartTime: moment(), isSessionTimedOut: false });
        setTimeout(() => {
            this.setState({ isSessionTimedOut: true });
            this.props.resetPurchaseRight();
        }, this.edkrSessionTimeout);
    }

    get isSessionTimedOut() {
        const currentTime = moment();
        const edkrRequestTime = this.state.edkrRequestStartTime || currentTime;

        return !currentTime.isSame(edkrRequestTime, "day") || this.state.isSessionTimedOut;
    }

    setDefaultCompany() {
        if (!this.props.purchaseRightFetched || !this.props.purchaseRight.isAllowed) {
            return;
        }

        let defaultCompany;
        if (this.state.isCompany) {
            defaultCompany = this.props.purchaseRight.companies.find(c => c.registryCode === this.state.role.nationalIdentityNumber);
        } else {
            defaultCompany = this.props.purchaseRight.companies[0];
        }

        this.onChangeEdkrCompany(defaultCompany);
    }

    onChangeSelectedProduct(product) {
        this.setState({ [FuelTaxiOrderForm.fieldRadioSelectProduct]: product });

        if (product.isSpecialPurposeFuel) {
            this.setDefaultCompany();
        }
    }

    onChangeEdkrCompany(company, purpose) {
        this.setState({
            selectedCompany: company,
            selectedPurpose: this.getPurposeFieldValue(company, purpose || company.purpose?.[0] || ""),
            [FuelTaxiOrderForm.fieldCompany]: `${company.name} (reg. kood: ${company.registryCode})`
        });
    }

    getPurposeFieldValue(company, purpose) {
        return `${purpose};${company.registryCode}`;
    }

    onSubmit(e) {
        const { noFuels: disableForm, purchaseRight } = this.props;

        if (disableForm) {
            return;
        }

        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            // hack: Fixes showing error message that address is required
            this.setState({ addressRefresh: ++this.state.addressRefresh });
            return;
        }

        if (this.props.orderFetching) {
            return false;
        }

        const selectedProduct = this.state[FuelTaxiOrderForm.fieldRadioSelectProduct];

        let order = {
            product: selectedProduct.name,
            productPurpose: "",
            amount: this.state[FuelTaxiOrderForm.fieldAmount],
            company: this.state[FuelTaxiOrderForm.fieldCompany],
            email: this.state[FuelTaxiOrderForm.fieldEmail],
            phone: this.state[FuelTaxiOrderForm.fieldPhoneNr],
            deliveryAddress: this.state[FuelTaxiOrderForm.fieldDeliveryAddress],
            invoiceAddress: this.state[FuelTaxiOrderForm.fieldDeliveryAddress],
            comment: this.state[FuelTaxiOrderForm.comment]
        };
        if (selectedProduct.isSpecialPurposeFuel) {
            order.productPurpose = this.state.selectedPurpose.split(";")[0];
            order.edkrTimeStamp = purchaseRight.timeStamp;
            order.edkrCompanies = purchaseRight.companies.map(c => `${c.name} (${c.registryCode})`);
        }
        if (this.state[FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery]) {
            order.invoiceAddress = this.state[FuelTaxiOrderForm.fieldInvoiceAddress]
        }
        console.log(order);
        this.props.sendOrder(order);
    }

    updateDeliveryAddress(address) {
        this.setState({ deliveryAddress: address });
    }

    updateInvoiceAddress(address) {
        this.setState({ invoiceAddress: address });
    }

    isFormDisabled() {
        return this.props.noFuels ||
            (!!this.state[FuelTaxiOrderForm.fieldRadioSelectProduct].isSpecialPurposeFuel &&
                (this.props.purchaseRightError || !this.props.purchaseRight.isAllowed || this.isSessionTimedOut));
    }

    renderFuelSelection() {
        const { wholesaleFuels, wholesaleFuelsFetched, wholesaleFuelsFetching, noFuels } = this.props;

        const selectedfuelType = this.state[FuelTaxiOrderForm.fieldRadioSelectProduct];
        const showPurposeSelection = (fuel) => selectedfuelType && fuel === selectedfuelType && fuel.isSpecialPurposeFuel;

        return (
            <div>
                <div>
                    <div className="mdc-typography body mdc-typography--body1">
                        <b>
                            <FormattedMessage
                                id="FuelTaxiOrderForm.PriceOfferWishFor" />
                        </b>
                    </div>

                    <List>
                        {wholesaleFuelsFetching &&
                            <Loader type={Loader.TYPE_CENTER} />}
                        {wholesaleFuelsFetched &&
                            (
                                noFuels
                                    ? <AlertMessage title={<FormattedMessage id="FuelTaxiOrderForm.NoFuels" />} />
                                    : wholesaleFuels.map((fuel, i) =>
                                        <React.Fragment key={"fuel-" + i}>
                                            <li className="form-fields-list__radio-item">
                                                <RadioField
                                                    index={i}
                                                    fieldName={FuelTaxiOrderForm.fieldRadioSelectProduct}
                                                    fieldValue={fuel.name}
                                                    fieldLabel={
                                                        <div className="mdc-typography mt-5 mdc-typography--body1">
                                                            {fuel.name}
                                                            {fuel.isSpecialPurposeFuel &&
                                                                <p className="field-sub-text">
                                                                    <FormattedMessage
                                                                        id="FuelTaxiOrderForm.RadioBtn.EdkDescription"
                                                                        defaultMessage="FuelTaxiOrderForm.RadioBtn.EdkDescription"
                                                                    />
                                                                </p>}
                                                        </div>
                                                    }
                                                    selectedValue={this.state[FuelTaxiOrderForm.fieldRadioSelectProduct].name}
                                                    onChange={() => this.onChangeSelectedProduct(fuel)}
                                                />
                                            </li>
                                            {showPurposeSelection(fuel) && <PurchaseRight isTimeout={this.isSessionTimedOut} />}
                                        </React.Fragment>
                                    )
                            )}
                    </List>
                </div>
            </div >
        );
    }

    renderTextFields() {
        const isSpecialPurposeFuel = this.state[FuelTaxiOrderForm.fieldRadioSelectProduct].isSpecialPurposeFuel;
        const showEdkrFields = this.props.purchaseRightFetched && this.props.purchaseRight.isAllowed && isSpecialPurposeFuel;

        const isFormDisabled = this.isFormDisabled();
        const companyRegCode = this.state.role.nationalIdentityNumber;
        const isCompany = this.state.isCompany;

        return (
            <>
                {showEdkrFields &&
                    <div className="form-fields-list__text-field">
                        {this.props.purchaseRight.companies.map((company, i) =>
                            <div className="pb-10 ml-45 mt-10" key={`company-${i}`}>
                                <RadioField
                                    index={i}
                                    fieldName="edkr-company"
                                    fieldValue={company.name}
                                    fieldLabel={
                                        <div className="mdc-typography mt-5 mdc-typography--body1">
                                            <strong>{`${company.name} (${company.registryCode})`}</strong>
                                        </div>
                                    }
                                    selectedValue={this.state.selectedCompany?.name || ""}
                                    onChange={() => this.onChangeEdkrCompany(company)}
                                    disabled={isCompany && companyRegCode !== company.registryCode}
                                />
                                {company.purpose.map((p, j) =>
                                    <div key={`purpose-${j}`} className="ml-45 mt-10">
                                        <RadioField
                                            index={j}
                                            fieldName="edkr-company-product"
                                            fieldValue={this.getPurposeFieldValue(company, p)}
                                            fieldLabel={
                                                <div className="mdc-typography mt-5 mdc-typography--body1">
                                                    {this.props.intl.formatMessage({ id: `FuelTaxiOrderForm.Edkr.Purpose.${p}`})}
                                                </div>
                                            }
                                            selectedValue={this.state.selectedPurpose}
                                            onChange={() => this.onChangeEdkrCompany(company, p)}
                                            disabled={isCompany && companyRegCode !== company.registryCode}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }
                <div className="form-fields-list__text-field">
                    <TextFieldGroup
                        type="Number"
                        field={FuelTaxiOrderForm.fieldAmount}
                        label="FuelTaxiOrderForm.LiterAmount"
                        disabled={isFormDisabled}
                        validator={this.validator}
                        rules="required|amount"
                        onChange={this.onChange}
                        value={this.state[FuelTaxiOrderForm.fieldAmount].trim()}
                    />
                    <Typography className="field-sub-text">
                        <FormattedMessage
                            id="FuelTaxiOrderForm.LiterAmountDescription" />
                    </Typography>
                </div>
                <div className="form-fields-list__text-field fix-margin">
                    <TextFieldGroup
                        field={FuelTaxiOrderForm.fieldCompany}
                        label="FuelTaxiOrderForm.Company"
                        disabled={isFormDisabled || isSpecialPurposeFuel}
                        validator={this.validator}
                        rules="required"
                        onChange={this.onChange}
                        value={this.state[FuelTaxiOrderForm.fieldCompany]}
                    />
                </div>
                <div className="form-fields-list__text-field fix-margin">
                    <AddressForm
                        label="FuelTaxiOrderForm.DeliveryAddress"
                        labelSubText="FuelTaxiOrderForm.AddressInfo"
                        onChange={this.updateDeliveryAddress}
                        address={this.state.deliveryAddress}
                        disabled={isFormDisabled}
                        validator={this.validator}
                        rules="required"
                        searchBuildings={true}
                        refreshComponentToReRenderValidator={this.state.addressRefresh} />
                </div>
                <div className="form-fields-list__text-field fix-margin">
                    <GridInner>
                        <GridCell span={12}>
                            <CheckboxField
                                nativeControlId={FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery}
                                field={FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery}
                                checked={this.state[FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery]}
                                indeterminate={false}
                                onChange={this.onChangeCheckbox}
                                disabled={isFormDisabled}
                                label={
                                    <label htmlFor={FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery}>
                                        <FormattedMessage
                                            id="FuelTaxiOrderForm.InvoiceAddressDiffersFromDelivery"
                                            values={{
                                                terms: (
                                                    <a href={FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery} target="_blank" rel="noopener noreferrer">
                                                        <FormattedMessage id="FuelTaxiOrderForm.InvoiceAddressDiffersFromDelivery" />
                                                    </a>
                                                )
                                            }}
                                        />
                                    </label>
                                }
                            />
                        </GridCell>
                    </GridInner>
                </div>
                {this.state[FuelTaxiOrderForm.fieldInvoiceAddressDiffersFromDelivery] === true && (
                    <div className="form-fields-list__text-field fix-margin">
                        <AddressForm
                            label="FuelTaxiOrderForm.InvoiceAddress"
                            onChange={this.updateInvoiceAddress}
                            address={this.state.invoiceAddress}
                            searchBuildings={true}
                            disabled={isFormDisabled}
                        />
                    </div>
                )}


                <div className="form-fields-list__text-field fix-margin">
                    <GridInner>
                        <GridCell span={12}>
                            <TextFieldGroup
                                type="email"
                                field={FuelTaxiOrderForm.fieldEmail}
                                label="FuelTaxiOrderForm.Email"
                                disabled={isFormDisabled}
                                validator={this.validator}
                                rules="required|email"
                                onChange={this.onChange}
                                value={this.state[FuelTaxiOrderForm.fieldEmail].trim()}
                            />
                        </GridCell>
                    </GridInner>
                </div>

                <div className="form-fields-list__text-field phone fix-margin">
                    <GridInner>
                        <GridCell span={12}>
                            <PhoneFieldGroup
                                validator={this.validator}
                                rules="required|phoneformat"
                                field={FuelTaxiOrderForm.fieldPhoneNr}
                                disabled={isFormDisabled}
                                onUpdate={this.updateState.bind(this)}
                                value={this.state[FuelTaxiOrderForm.fieldPhoneNr].trim()}
                                label="Contract.Step1.Contact.Phone" />
                        </GridCell>
                    </GridInner>
                </div>

                <div className="form-fields-list__text-field fix-margin">
                    <GridInner>
                        <GridCell span={12}>
                            <TextAreaGroup
                                field={FuelTaxiOrderForm.comment}
                                label="FuelTaxiOrderForm.Comment"
                                rows={4}
                                maxLength={1024}
                                disabled={isFormDisabled}
                                validator={this.validator}
                                onChange={this.onChange}
                                value={this.state[FuelTaxiOrderForm.comment]}
                                placeholder={this.props.intl.formatMessage({ id: "FuelTaxiOrderForm.CommentPlaceholder" })}
                                showCounter
                            />
                            <Typography className="field-sub-text">
                                <FormattedMessage
                                    id="FuelTaxiOrderForm.CommentDescription"
                                    values={{ count: 1024 }}
                                />
                            </Typography>
                        </GridCell>
                    </GridInner>
                </div>
            </>
        );
    }

    render() {
        const {
            orderFetched,
            orderFetching,
            orderErrors
        } = this.props;

        this.validator.purgeFields();
        return (
            <GridInner>
                {!orderFetched &&
                    <GridCell span={12}>
                        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                            {this.renderFuelSelection()}
                            {this.renderTextFields()}

                            {orderErrors &&
                                <AlertMessage className="alert-message__error" type={AlertMessage.TYPE_ALERT}
                                    description={<FormattedMessage id="FuelTaxiOrderForm.ErrorDescription" />}
                                    isSmall={true} />}

                            <div className="submit-btn-container">
                                <Button
                                    unelevated
                                    disabled={this.isFormDisabled()}>
                                    {orderFetching
                                        ? <Loader type={Loader.TYPE_BUTTON} />
                                        : <FormattedMessage
                                            id="FuelTaxiOrderForm.Order"
                                            defaultMessage="FuelTaxiOrderForm.Order"
                                        />}
                                </Button>
                            </div>
                        </form>
                    </GridCell>
                }

                {orderFetched &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_DONE}
                            description={<FormattedMessage id="FuelTaxiOrderForm.SuccessDescription" />}
                            isSmall={true} />
                    </GridCell>
                }
            </GridInner>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,

        orderFetched: state.fuelTaxiOrder.fetched,
        orderFetching: state.fuelTaxiOrder.fetching,
        orderErrors: state.fuelTaxiOrder.error,

        wholesaleFuelsFetched: state.wholesaleFuels.fetched,
        wholesaleFuelsFetching: state.wholesaleFuels.fetching,
        wholesaleFuels: state.wholesaleFuels.data,

        noFuels: state.wholesaleFuels.fetched && (!state.wholesaleFuels.data || state.wholesaleFuels.data.length <= 0),

        purchaseRightError: state.purchaseRight.error,
        purchaseRight: state.purchaseRight.data,
        purchaseRightFetched: state.purchaseRight.fetched,

        formFields: [
            FuelTaxiOrderForm.fieldRadioSelectProduct,
            FuelTaxiOrderForm.fieldAmount,
            FuelTaxiOrderForm.fieldEmail,
            FuelTaxiOrderForm.fieldCompany,
            FuelTaxiOrderForm.fieldPhoneNr,
            FuelTaxiOrderForm.fieldInvoiceAddress,
            FuelTaxiOrderForm.fieldDeliveryAddress,
            FuelTaxiOrderForm.comment
        ]
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        sendOrder,
        fetchWholesaleFuels,
        resetPurchaseRight
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(FuelTaxiOrderForm));