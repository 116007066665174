import React from 'react';
import PropTypes from 'prop-types';
import TextFieldGroup from "./TextFieldGroup";

const PhoneFieldGroup = ({ validator, rules, field, value, label, onUpdate, disabled }) => {

    const mobileWithoutRegionalPrefix = (value || "").replace(/^\+*372/, "");
    return (
        <>
            <TextFieldGroup
                classNames="mdc-text-field--phone-input"
                validator={validator}
                rules={rules}
                field={field}
                disabled={disabled}
                onChange={(e, callback) => {
                    let name = e.target.name;
                    let val = e.target.value.replace(/^\+*372/, "");
                    val = val.length > 0 ? "372" + val : "";
                    onUpdate(name, val, callback);
                }}
                value={mobileWithoutRegionalPrefix}
                label={label}
                placeholder="1234567"
                icon="+372" />
        </>
    );
}

PhoneFieldGroup.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    validator: PropTypes.object,
    rules: PropTypes.string,
    disabled: PropTypes.bool,
};

PhoneFieldGroup.defaultProps = {
    disabled: false
};

export default PhoneFieldGroup;
