import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";

export const postPrepareSignature = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PREPARE_SIGNATURE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/prepare`, payload)
    };
};

export const postFinalizeSignature = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_SIGNATURE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/finalize`, payload)
    };
};

export const postPrepareSignatureSmartId = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PREPARE_SIGNATURE_SMART_ID',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/prepare/smartId`, payload)
    };
};

export const postFinalizeSignatureSmartId = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_SIGNATURE_SMART_ID',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/finalize/smartId`, payload)
    };
};


export const postPrepareSignatureMobileId = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PREPARE_SIGNATURE_MOBILE_ID',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/prepare/mobileId`, payload)
    };
};

export const postFinalizeSignatureMobileId = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_SIGNATURE_MOBILE_ID',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/finalize/mobileId`, payload)
    };
};

export const postPrepareSignatureEParakstsMobile = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PREPARE_SIGNATURE_EPARAKSTS_MOBILE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/prepare/eparakstsmobile`, payload)
    };
}

export const postFinalizeSignatureEParakstsMobile = (payload) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_SIGNATURE_EPARAKSTS_MOBILE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/digitalsignature/finalize/eparakstsmobile`, payload)
    };
}
