import { useEffect, useMemo } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { GridCell, GridInner } from "@rmwc/grid";
import { IconButton } from "@rmwc/icon-button";
import moment from "moment";
import { addYears } from "date-fns";
import { fetchElectricityInvoices, fetchPaymentCardInvoices, downloadElectricityInvoicePdf, downloadPaymentCardInvoicePdf } from "../../actions/invoiceActions";
import { getInvoiceDetailsPath, InvoicesPath } from "../../const/routes";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import LinkElement from "../common/LinkElement";
import TableList from "../common/TableList";
import { InvoiceStatus } from "../Invoice";
import Currency from "../common/Currency";
import AlertMessage from "../common/AlertMessage";
import { StatusUnpaid, InvoiceType } from "../../const/Invoice";
import { sortByDate } from "../../helpers/sortHelper";
import { defaultIconProps } from "../common/Icon";
import TitleWithLoader from "../common/TitleWithLoader";

const BusinessOverviewInvoices = () => {
    const dispatch = useDispatch();

    const {
        data: paymentCardInvoices,
        fetched: paymentCardInvoicesFetched,
        fetching: paymentCardInvoicesFetching,
        hasError: paymentCardInvoicesHasError
    } = useSelector(state => state.paymentCardInvoices);
    const {
        data: elmoInvoices,
        fetched: elmoInvoicesFetched,
        fetching: elmoInvoicesFetching,
        hasError: elmoInvoicesHasError
    } = useSelector(state => state.electricityInvoices);

    useEffect(() => {
        const now = new Date();
        const endDate = moment(now).format("YYYY-MM-DD 23:59:59");
        const startDate = moment(addYears(now, -1)).format("YYYY-MM-DD 00:00:00");

        dispatch(fetchElectricityInvoices(1, 10, startDate, endDate));
        dispatch(fetchPaymentCardInvoices(1, 10, startDate, endDate));

    }, [dispatch,]);

    const sortByStatus = (a, b) => {
        if (a.status === StatusUnpaid && b.status !== StatusUnpaid) { return -1; }
        if (a.status !== StatusUnpaid && b.status === StatusUnpaid) { return 1; }
        return 0;
    }

    const invoicesFetching = paymentCardInvoicesFetching || elmoInvoicesFetching;
    const invoicesFetched = !invoicesFetching && (paymentCardInvoicesFetched || elmoInvoicesFetched);
    const hasInvoiceErrors = paymentCardInvoicesHasError || elmoInvoicesHasError;

    const invoices = useMemo(() => {
        let invoices = [];

        if (!isNullOrEmpty(elmoInvoices)) { invoices = invoices.concat(elmoInvoices); }
        if (!isNullOrEmpty(paymentCardInvoices)) { invoices = invoices.concat(paymentCardInvoices); }

        return [...invoices]
            .sort((a, b) => sortByStatus(a, b) || sortByDate(a, b, "invoiceDate")) // first by status, then by date
            .slice(0, 10);
    }, [elmoInvoices, paymentCardInvoices]);

    const downloadPdf = (type, id, number) => {
        if (type === InvoiceType.ElectricityAndGas) {
            dispatch(downloadElectricityInvoicePdf(id, number));
        } else if (type === InvoiceType.PaymentCard) {
            dispatch(downloadPaymentCardInvoicePdf(id, number));
        }
    }

    const invoicesTableHeaders = [
        { label: "Invoices.Table.InvoiceNumber" },
        { label: "Invoices.Table.ContractType" },
        { label: "Invoices.Table.InvoiceDate" },
        { label: "Invoices.Table.DueDate" },
        { label: "Invoices.Table.Status" },
        { label: "Invoices.Table.Sum" },
        { label: "Invoices.Table.Unpaid", colspan: 2 }
    ];

    const getInvoiceTableRow = (invoice) => {
        const invoiceDeadline = invoice.deadline && moment(invoice.deadline);
        const invoiceDate = invoice.invoiceDate && moment(invoice.invoiceDate);
        const invoiceDetailsPath = getInvoiceDetailsPath(invoice.id, invoice.type);
        const dateFormat = "DD.MM.YYYY";

        return {
            content: [
                {
                    content: <LinkElement href={invoiceDetailsPath} className="mdc-theme--primary">
                        {invoice.number}
                    </LinkElement>
                },
                {
                    content: !isNullOrEmpty(invoice.contractTypes) && invoice.contractTypes
                        .map(type => <FormattedMessage id={`Invoice.ContractType.${type}`} defaultMessage={type} />)
                        .reduce((a, b) => a + ", " + b)
                },
                { content: invoiceDate && invoiceDate.format(dateFormat) },
                {
                    content: invoiceDeadline
                        ? invoiceDeadline.format(dateFormat)
                        : "-"
                },
                { content: <InvoiceStatus status={invoice.status} deadline={invoice.deadline} showIcon={false} asLabel /> },
                { content: <Currency value={invoice.totalToBePaid} currency={invoice.currency} /> },
                { content: <Currency value={invoice.unpaid} /> },
                {
                    content: <div className="d-flex justify-content-end">
                        <IconButton
                            checked
                            onIcon={{ ...defaultIconProps, icon: "pdf" }}
                            onClick={() => downloadPdf(invoice.type, invoice.id, invoice.number)} />
                    </div>,
                    className: "pt-0 pb-0"
                }
            ]
        };
    };

    return (
        <GridInner>
            <GridCell span={12}>
                <TitleWithLoader
                    title={<FormattedMessage id="BusinessOverview.Invoices.Title" />}
                    isLoading={invoicesFetching}
                    href={InvoicesPath}
                    linkText={<FormattedMessage id="BusinessOverview.Invoices.All" />} />
            </GridCell>

            {!invoicesFetching && hasInvoiceErrors &&
                <GridCell span={9}>
                    {elmoInvoicesHasError &&
                        <AlertMessage
                            type={AlertMessage.TYPE_ALERT}
                            isSmall={true}
                            title={<FormattedMessage id="BusinessOverview.Invoices.Error.ElectricityInvoices" />} />}
                    {paymentCardInvoicesHasError &&
                        <AlertMessage
                            type={AlertMessage.TYPE_ALERT}
                            isSmall={true}
                            title={<FormattedMessage id="BusinessOverview.Invoices.Error.PaymentCardInvoices" />} />}
                </GridCell>}

            {invoicesFetched &&
                (isNullOrEmpty(invoices)
                    ? <GridCell span={9}>
                        <AlertMessage
                            type={AlertMessage.TYPE_NOTICE}
                            isSmall={true}
                            title={<FormattedMessage id="BusinessOverview.Invoices.NoItems" />} />
                    </GridCell>
                    : <GridCell span={12}>
                        <TableList
                            className="mb-0"
                            headers={invoicesTableHeaders}
                            itemContent={invoices.map(getInvoiceTableRow)}
                        />
                    </GridCell>)}
        </GridInner>
    );
}

export default injectIntl(BusinessOverviewInvoices);