import React, { useState } from "react";
import { useCombobox, useMultipleSelection } from 'downshift'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MenuList, MenuListItem } from '@material/react-menu';
import "../../styles/components/_multi-select.scss";

const DropdownMultipleCombobox = ({ options, placeHolder, onAdd, onRemove }) => {
    const [inputValue, setInputValue] = useState('')
    const {
        getSelectedItemProps,
        getDropdownProps,
        addSelectedItem,
        removeSelectedItem,
        selectedItems,
    } = useMultipleSelection({ initialSelectedItems: [] })

    const getFilteredItems = items =>
        items.filter(
            item =>
                selectedItems.indexOf(item) < 0 &&
                item.toLowerCase().startsWith(inputValue.toLowerCase()),
        )

    const removeItem = item => {
        removeSelectedItem(item);
        onRemove(item);
    }

    const {
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        getItemProps,
        selectItem,
    } = useCombobox({
        inputValue,
        items: getFilteredItems(options),
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges
            switch (type) {
              case useCombobox.stateChangeTypes.InputKeyDownEnter:
              case useCombobox.stateChangeTypes.ItemClick:
                return {
                  ...changes,
                  isOpen: true, // keep the menu open after selection.
                }
                default:
                    return changes;
            }
          },
        onStateChange: ({ inputValue, type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(inputValue)

                    break
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        setInputValue('')
                        addSelectedItem(selectedItem)
                        selectItem(null)
                        onAdd(selectedItem)
                    }
                    break
                default:
                    break
            }
        },
    })

    return (
        <div className='mdc-menu-surface--anchor mdc-menu-search'>
            <div {...getToggleButtonProps()} className='multi-select-selection'>
                {selectedItems.length > 0 ?
                <div className='multi-select-content'>
                    {selectedItems.map((selectedItem, index) => (
                        <div className="multi-select-tag"
                            key={`selected-item-${index}`}
                            {...getSelectedItemProps({ selectedItem, index })}
                        >
                                {selectedItem}
                                <span className="tag-remove-btn"
                                    onClick={() => removeItem(selectedItem)}
                                >
                                    <b>&#10005;</b>
                                </span>
                            </div>
                    ))}
                </div>

                 : placeHolder}
                <span {...getComboboxProps(getInputProps(getDropdownProps()))} className={classnames('mdc-text-field__icon mdc-menu-search__dropdown-icon dropdown-arrow', { "rotate": isOpen })}></span>
            </div>
            <div {...getMenuProps({ className: classnames("mdc-menu mdc-menu-surface full-width", { "mdc-menu-surface--open": isOpen }) })}>
                <MenuList className="mdc-list--select">
                    {isOpen ? getFilteredItems(options).map((item, index) => (
                            <MenuListItem key={index}
                            {...getItemProps({
                                index,
                                item,
                            })}
                        >
                            {item}
                        </MenuListItem>
                    )) : null}
                </MenuList>
            </div>
        </div>
    )
}

DropdownMultipleCombobox.propTypes = {
    placeHolder: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default DropdownMultipleCombobox;