import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Dialog, DialogTitle, DialogContent, DialogButton } from "@rmwc/dialog";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import { orderCard } from "../../actions/cardActions";
import { fetchAccount } from "../../actions/accountActions";
import Loader from "../common/Loader";
import TextFieldGroup from "../common/TextFieldGroup";
import BaseFormComponent from "../common/BaseFormComponent";
import SimpleReactValidator from "simple-react-validator";
import AlertMessage from "../common/AlertMessage";
import SelectField from "../common/SelectField";
import { Fuel } from "../../const/contractType";
import { Active } from "../../const/ContractStatus";
import { Products, FuelTypes } from "../../const/FuelItems";
import AddressForm from "../AddressForm/AddressForm";
import Checkbox from '@material/react-checkbox';
import { IconButton } from "@rmwc/icon-button";
import classNames from "classnames";
import PaymentCards from "../../pages/PaymentCards";

import "../../styles/react-components/dialog.scss";

const FALLBACK_ALLOWED_CARDS_AMOUNT = 100;

class NewCardDialog extends BaseFormComponent {
    static FieldDailyLimit = "dailyLimit";
    static FieldMonthlyLimit = "monthlyLimit";
    static FieldContractSelectedItem = "selectedContract";
    static FieldProductSelectedItem = "selectedProductXc";
    static FieldFuelSelectedItem = "selectedFuelXc";
    static FieldIsPlasticCard = "isPlasticCard";
    static FieldCardName = "cardName";

    constructor(props) {
        super(props);

        const contracts = props.contracts ? props.contracts
            .filter(x => x.type === Fuel && x.status === Active)
            .map(x => (
                {
                    label: x.number,
                    value: x.number
                }))
        : []

        const selectedContract = contracts.length > 0 ? props.contracts.find(c => c.number === contracts[0].value) : null;

        this.newCardObj = {
            [NewCardDialog.FieldCardName]: "",
            [NewCardDialog.FieldFuelSelectedItem]: FuelTypes[0].value,
            [NewCardDialog.FieldProductSelectedItem]: Products[0].value,
            [NewCardDialog.FieldMonthlyLimit]: "300",
            [NewCardDialog.FieldDailyLimit]: "100",
            [NewCardDialog.FieldIsPlasticCard]: false
        };

        this.state = {
            showError: false,
            errorMessage: "Cards.Create.Error",
            showLoading: false,
            contracts: contracts,
            selectedContract: contracts.length > 0 ? contracts[0].value : null,
            newCards: [this.newCardObj],
            deliveryAddress: {},
            numberOfCards: selectedContract?.numberOfCards ?? 0,
            allowedCardsAmount: selectedContract?.allowedCardsAmount ?? FALLBACK_ALLOWED_CARDS_AMOUNT,
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
        this.onChangeCardProperty = this.onChangeCardProperty.bind(this);
        this.addCard = this.addCard.bind(this);
        this.removeCard = this.removeCard.bind(this);
        this.onChangeContract = this.onChangeContract.bind(this);
        this.onChangeSelected = this.onChangeSelected.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    canOrderNewCards() {
        const contract = this.props.contracts.find(c => c.number === this.state.selectedContract);
        const totalCards = (contract?.numberOfCards ?? 0) + this.state.newCards.length;
        return contract && totalCards <= this.state.allowedCardsAmount;
    }

    showOrderNewCardsValidationMessage() {
        this.setState({ showError: true, errorMessage: "Cards.ExceedsAllowedLimit" });
    }

    sendRequest() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        if (!this.canOrderNewCards()) {
            this.showOrderNewCardsValidationMessage();
            return;
        }

        this.setState({
            showError: false,
            showLoading: true
        })

        this.props.orderCard({
            cards: this.state.newCards.map(c => ({
                ...c,
                [NewCardDialog.FieldDailyLimit]: parseFloat(c[NewCardDialog.FieldDailyLimit]),
                [NewCardDialog.FieldMonthlyLimit]: parseFloat(c[NewCardDialog.FieldMonthlyLimit])
            })),
            contractNumber: parseInt(this.state.selectedContract),
            deliveryAddress: this.state.deliveryAddress
        })
        .then(() => {
            this.setState({
                showLoading: false
            })
        })
        .then(() => this.props.onSuccess("Cards.Ordered"))
        .then(this.props.onClose)
        .catch(() => {
            this.setState({
                showError: true,
                errorMessage: "Cards.Create.Error",
                showLoading: false
            })
        })
    }

    renderDialogTitle() {
        return (
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss" />
            </DialogTitle>
        );
    }

    renderTitle() {
        return (
            <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                <FormattedMessage id="Cards.Title.OrderCard"/>
            </Typography>
        );
    }

    renderActivationNotice() {
        return <FormattedMessage id="Cards.NewCard.Notice.Activate" />
    }

    onChangeCardProperty(index, e, field) {
        const fieldName = e.target?.name || field;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        if (!fieldName) {
            return;
        }
        this.setState(prevState => ({
            newCards: prevState.newCards.map((item, i) => i === index ? {...item, [fieldName]: value } : item)
        }));
    }

    onChangeSelected(afterChange, field, e, index) {
        this.onSelectChange(afterChange, field, e);
        this.onChangeCardProperty(index, e, field);
    }

    onChangeContract(afterChange, field, e) {
        this.onSelectChange(afterChange, field, e);
        const currentContract = this.props.contracts.find(c => c.number === e.target.value);
        this.setState({
            deliveryAddress: {
                county: currentContract.deliveryAddressCounty,
                city: currentContract.deliveryAddressTown,
                street: currentContract.deliveryAddressStreet,
                postalCode: currentContract.deliveryAddressPostalCode,
            },
            numberOfCards: currentContract.numberOfCards,
            allowedCardsAmount: currentContract.allowedCardsAmount,
            newCards: [this.newCardObj]
        });
    }

    addCard() {
        if (!this.canOrderNewCards()) {
            this.showOrderNewCardsValidationMessage();
            return;
        }

        this.setState(prevState => ({
            newCards: [...prevState.newCards, this.newCardObj]
        }));
    }

    removeCard(index) {
        if (this.state.newCards.length > 1) {
            this.setState(prevState => ({
                newCards: prevState.newCards.filter((card, i) => i !== index),
                showError: false
            }));
        }
    }

    render() {
        const options = this.state.contracts
            .map(x => (
                {
                    ...x,
                    selected: x.value === this.state.selectedContract,
                }))

        const productOptions = Products.map((x, i) => (
            {
                label: this.props.intl.formatMessage({ id: x.label }),
                value: x.value,
                selected: i === 0,
            }
        ))

        const fuelOptions = FuelTypes.map((x, i) => (
            {
                label: this.props.intl.formatMessage({ id: x.label }),
                value: x.value,
                selected: i === 0,
            }
        ))

        const cardNameTranslation = this.props.intl.formatMessage({ id: "Cards.Title.CardName" });
        const hasRemoveButton = this.state.newCards.length > 1;

        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.props.onClose}
                className="mdc-dialog--wide"
            >
                {this.renderDialogTitle()}
                <DialogContent>
                    {this.state.showLoading ? <Loader type={Loader.TYPE_CENTER}/> :
                    <Grid className="mdc-layout-grid--padded">
                        <GridCell span={12}>
                            {this.renderTitle()}
                            {this.renderActivationNotice()}
                            {this.state.showError &&
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_ALERT}
                                    title={<FormattedMessage id={this.state.errorMessage} values={{
                                        amount: this.state.allowedCardsAmount
                                    }}/>}
                                />
                            }
                        </GridCell>
                        <GridCell tablet={4} desktop={4}>
                            <SelectField
                                field={NewCardDialog.FieldContractSelectedItem}
                                label="Cards.Title.ContractNumber"
                                value={this.state.selectedContract}
                                onChange={this.onChangeContract}
                                enhanced
                                menu
                                outlined
                                options={options} />
                        </GridCell>
                        <GridCell tablet={8} desktop={8} align="middle" className="pt-25">
                            <Typography use="body2" className="mdc-typography--bold">
                                <FormattedMessage id="Cards.Amount" />
                                <span>&nbsp;</span>
                                {this.state.numberOfCards}
                            </Typography>
                        </GridCell>
                        <GridCell span={8}>
                            <AddressForm
                                label="Cards.DeliveryAddress"
                                rules={this.state.newCards.filter(x => x[NewCardDialog.FieldIsPlasticCard]).length > 0 ? 'required' : ''}
                                onChange={deliveryAddress => this.setState({ deliveryAddress })}
                                address={this.state.deliveryAddress}
                                validator={this.validator}
                                isLoading={this.props.accountFetching}
                            />
                        </GridCell>
                        <GridCell span={4}></GridCell>
                        {this.state.newCards.map((card, i) => {
                            return (
                                <React.Fragment key={i}>
                                <GridCell span={10}>
                                    <GridInner>
                                        <GridCell span={2}>
                                            <TextFieldGroup
                                                field={NewCardDialog.FieldCardName}
                                                onChange={(e) => this.onChangeCardProperty(i, e)}
                                                value={card[NewCardDialog.FieldCardName]}
                                                dontTranslateLabel={true}
                                                label={`${i + 1}. ${cardNameTranslation.toLowerCase()}`}
                                                validator={this.validator}
                                                rules={`required|max:${PaymentCards.MaxAllowedCardNameLength}`}
                                            />
                                        </GridCell>
                                        <GridCell span={3}>
                                            <SelectField
                                                field={NewCardDialog.FieldFuelSelectedItem}
                                                label="Cards.Title.Fuels"
                                                value={card[NewCardDialog.FieldFuelSelectedItem]}
                                                onChange={(afterChange, field, e) => this.onChangeSelected(afterChange, field, e, i)}
                                                enhanced
                                                menu
                                                outlined
                                                options={fuelOptions} />
                                        </GridCell>
                                        <GridCell span={3}>
                                            <SelectField
                                                field={NewCardDialog.FieldProductSelectedItem}
                                                label="Cards.Title.Products"
                                                value={card[NewCardDialog.FieldProductSelectedItem]}
                                                onChange={(afterChange, field, e) => this.onChangeSelected(afterChange, field, e, i)}
                                                enhanced
                                                menu
                                                outlined
                                                options={productOptions} />
                                        </GridCell>
                                        <GridCell span={2}>
                                            <TextFieldGroup
                                                field={NewCardDialog.FieldMonthlyLimit}
                                                onChange={(e) => this.onChangeCardProperty(i, e)}
                                                value={card[NewCardDialog.FieldMonthlyLimit]}
                                                type="number"
                                                validator={this.validator}
                                                rules={`required|numeric`}
                                                placeholder={this.props.intl.formatMessage({
                                                    id: "Cards.MonthlyLimit"
                                                })}
                                                label="Cards.MonthlyLimit" />
                                        </GridCell>
                                        <GridCell span={2}>
                                            <TextFieldGroup
                                                field={NewCardDialog.FieldDailyLimit}
                                                onChange={(e) => this.onChangeCardProperty(i, e)}
                                                value={card[NewCardDialog.FieldDailyLimit]}
                                                type="number"
                                                validator={this.validator}
                                                rules={`required|numeric`}
                                                placeholder={this.props.intl.formatMessage({
                                                    id: "Cards.DailyLimit"
                                                })}
                                                label="Cards.DailyLimit" />
                                        </GridCell>
                                    </GridInner>
                                </GridCell>
                                <GridCell span={2} className="pt-25">
                                    <GridInner>
                                        <GridCell align="middle">
                                            <div className={classNames("mdc-form-field field", hasRemoveButton ? "" : "pt-10")}>
                                                <Checkbox
                                                    field={NewCardDialog.FieldIsPlasticCard}
                                                    nativeControlId="plastic-card"
                                                    name={NewCardDialog.FieldIsPlasticCard}
                                                    checked={card[NewCardDialog.FieldIsPlasticCard]}
                                                    indeterminate={false}
                                                    onChange={(e) => this.onChangeCardProperty(i, e)}
                                                />
                                                <label htmlFor="plastic-card">
                                                    <FormattedMessage id="Cards.IsPlastic" />
                                                </label>
                                            </div>
                                        </GridCell>
                                        {hasRemoveButton &&
                                            <GridCell>
                                                <IconButton
                                                    className=""
                                                    onClick={() => this.removeCard(i)}
                                                    onIcon={{
                                                        icon: "close",
                                                        strategy: "className",
                                                        basename: "icon",
                                                        prefix: "icon-",
                                                        className: "icon-delete"
                                                    }}
                                                />
                                            </GridCell>
                                        }
                                    </GridInner>
                                </GridCell>
                            </React.Fragment>
                            )
                        })}

                        <GridCell span={12}>
                            <p>
                                {`${this.props.intl.formatMessage({ id: "Cards.Amount" })} ${this.state.newCards.length}`}
                            </p>
                        </GridCell>

                        <GridCell span={12}>
                            {this.state.newCards.length < 20 &&
                                <Button type="submit"
                                    onClick={this.addCard}
                                    unelevated
                                    className="mb-30">
                                    <i className="icon-plus" />
                                    <FormattedMessage id="Cards.AddCard" />
                                </Button>
                            }

                            <Button type="submit"
                                onClick={this.sendRequest}
                                unelevated
                                className="mdc-button--primary mb-30 right">
                                <i className="icon-floppy" />
                                <FormattedMessage id="Cards.SendOrder" />
                            </Button>
                        </GridCell>
                    </Grid>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    return {
        orderCard: state.orderCard.data,
        fetched: state.orderCard.fetched,
        fetching: state.orderCard.fetching,
        error: state.orderCard.error,

        contracts: state.contracts.data,
        account: state.account.data,
        accountFetching: state.account.fetching
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ orderCard, fetchAccount }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(NewCardDialog)));