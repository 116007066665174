import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import DatePickerRange from "../common/DatePickerRange";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BaseFormComponent from "../common/BaseFormComponent";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import SelectAutoCompleteField from "../common/SelectAutoCompleteField";
import TextFieldGroup from "../common/TextFieldGroup";
import { fetchCardNumbers } from "../../actions/cardActions";
import { defaultRanges, createRangePresets } from "../common/DateRangePreset";
import SelectDateRangePreset from "../common/SelectDateRangePreset";

class CardTransactionFilter extends BaseFormComponent {
  static FieldPeriodStart = "filter.startDate";
  static FieldPeriodEnd = "filter.endDate";
  static FieldCardSelected = "filter.card";
  static FieldSelectedPeriodPreset = "filter.selectedPeriodPreset";
  static FieldInvoiceNumber = "filter.invoiceNumber";
  static FieldCardName = "filter.cardName";
  static FieldContractNumber = "filter.contractNumber";

  constructor(props) {
    super(props);
    this.onFilterPeriodStartDateChange = this.onFilterPeriodStartDateChange.bind(this);
    this.onFilterPeriodEndDateChange = this.onFilterPeriodEndDateChange.bind(this);
    this.onFilterCardChange = this.onFilterCardChange.bind(this);
    this.onFilterPeriodPresetChange = this.onFilterPeriodPresetChange.bind(this);
    this.filter = this.filter.bind(this);

    this.state = {
      periodPresets: createRangePresets(defaultRanges, this.props.intl),
      filter: {
        startDate: this.props.filter.startDate,
        endDate: this.props.filter.endDate,
        card: this.props.filter.card,
        selectedPeriodPreset: this.props.filter.selectedPeriodPreset,
        selectedPeriodPresetName: this.props.filter.selectedPeriodPresetName,
        invoiceNumber: this.props.filter.invoiceNumber,
        cardName: this.props.filter.cardName,
        contractNumber: this.props.filter.contractNumber
      }
    };
  }

  componentDidMount() {
    if (!this.props.areCardsFetched) {
      this.props.fetchCardNumbers();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this.setState({
        address: this.props.account.address
      });
    }
    if (prevProps.isPutAccountLoading && !this.props.isPutAccountLoading) {
      this.setState({
        showAddressResponse: true
      });
    }
  }

  onFilterPeriodStartDateChange(value) {
    this.setState((prev) => ({
      filter: {
        ...prev.filter,
        startDate: !!value ? moment(value) : value,
        selectedPeriodPresetName: null,
      },
    }));
  }

  onFilterPeriodEndDateChange(value) {
    this.setState((prev) => ({
      filter: {
        ...prev.filter,
        endDate: !!value ? moment(value) : value,
        selectedPeriodPresetName: null,
      },
    }));
  }

  onFilterPeriodPresetChange(value) {
    if (value) {
      let period = this.state.periodPresets[+value.value];
      if (period) {
        this.setState((prev) => ({
          filter: {
            ...prev.filter,
            selectedPeriodPreset: period,
            selectedPeriodPresetName: period.label,
            startDate: moment(period.range.startDate),
            endDate: moment(period.range.endDate),
          },
        }));
      }
    }
  }

  onFilterCardChange(value) {
    this.setState((prev) => ({
      filter: { ...prev.filter, card: value },
    }));
  }

  filter() {
    this.props.onSubmit(this.state.filter);
  }

  render() {
    return (
      <Grid
        className={classnames("filter-form", {
          "filter-form--open": this.props.open,
        })}
      >
        <GridCell desktop={4} tablet={4} phone={4} >
          <DatePickerRange
            label="Transactions.Filter.Period"
            fieldStart={CardTransactionFilter.FieldPeriodStart}
            fieldEnd={CardTransactionFilter.FieldPeriodEnd}
            startOnChange={this.onFilterPeriodStartDateChange}
            endOnChange={this.onFilterPeriodEndDateChange}
            startValue={this.state.filter.startDate?.toDate()}
            endValue={this.state.filter.endDate?.toDate()}
            maxStartDate={this.state.filter.endDate?.toDate()}
            minEndDate={this.state.filter.startDate?.toDate()}
            isNullableStart
            isNullableEnd
          />
        </GridCell>
        <GridCell desktop={4} tablet={4} phone={4} >
          <SelectDateRangePreset
            onChange={this.onFilterPeriodPresetChange}
            field={CardTransactionFilter.FieldSelectedPeriodPreset}
            value={this.state.filter.selectedPeriodPresetName}
            options={this.state.periodPresets}
            label="General.Space"
            placeholder={this.props.intl.formatMessage({
              id: 'Transactions.Period.Select'
            })}
          />
        </GridCell>
        <GridCell desktop={4} tablet={4} phone={4} >
          <SelectAutoCompleteField
            placeholder={this.props.intl.formatMessage({
              id: 'Transactions.Card.Select'
            })}
            isLoading={this.props.areCardsFetching}
            value={this.state.filter.card}
            onChange={v => this.onChangeByField(CardTransactionFilter.FieldCardSelected, v)}
            field={CardTransactionFilter.FieldCardSelected}
            label="Transactions.Filter.Card"
            options={this.props.cards} />
        </GridCell>
        <GridCell desktop={3} tablet={4} phone={4}>
          <TextFieldGroup
            field={CardTransactionFilter.FieldInvoiceNumber}
            onChange={this.onChange}
            value={this.state.filter.invoiceNumber}
            label="Transactions.Filter.InvoiceNumber"
          />
        </GridCell>
        <GridCell desktop={3} tablet={4} phone={4}>
          <TextFieldGroup
            field={CardTransactionFilter.FieldCardName}
            onChange={this.onChange}
            value={this.state.filter.cardName}
            label="Transactions.Filter.CardName"
          />
        </GridCell>
        <GridCell desktop={3} tablet={4} phone={4}>
          <TextFieldGroup
            field={CardTransactionFilter.FieldContractNumber}
            onChange={this.onChange}
            value={this.state.filter.contractNumber}
            label="Transactions.Filter.ContractNumber"
          />
        </GridCell>
        <GridCell desktop={3} tablet={8}>
          <div>
            <Button unelevated onClick={this.filter} className="right mt-24">
              <i className="icon-wave" />
              <FormattedMessage id="General.Filter" />
            </Button>
          </div>
        </GridCell>
      </Grid>
    );
  }
}

CardTransactionFilter.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  filter: PropTypes.object,
};

function toCardNumberArray(cards) {
  let map = cards.reduce(function (map, obj) {
    map[obj.cardNumber] = obj;
    return map;
  }, {});
  return Object.keys(map);
}

function mapStateToProps(state) {
  return {
    cards: toCardNumberArray(state.cardNumbers.data),
    areCardsFetched: state.cardNumbers.fetched,
    areCardsFetching: state.cardNumbers.fetching,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCardNumbers
  }, dispatch);
}

export default injectIntl(connect(
  mapStateToProps,
  matchDispatchToProps
)(CardTransactionFilter));
