import { SAVE_PUSH_NOTIFICATION_SETTINGS, CLEAR_SAVED_PUSH_NOTIFICATION_MESSAGES } from "../../actions/pushNotificationActions";

export default function reducer(state = {
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${SAVE_PUSH_NOTIFICATION_SETTINGS}_PENDING`: {
            return { ...state, fetching: true, error: null };
        }
        case `${SAVE_PUSH_NOTIFICATION_SETTINGS}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload.data };
        }
        case `${SAVE_PUSH_NOTIFICATION_SETTINGS}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, error: null };
        }
        case CLEAR_SAVED_PUSH_NOTIFICATION_MESSAGES: {
            return { ...state, error: null }
        }
        default:
            return state;
    }
}