import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import { Typography } from "@rmwc/typography";
import { Icon } from "@rmwc/icon";
import { EnergyType } from "../../const/EnergyType";

const EnergyWidget = ({ name, type, items, onClick }) => {
    return (
        <div className="energy-widget" onClick={onClick} style={{ cursor: !!onClick ? "pointer" : "default" }}>
            <div className="d-flex d-flex--inline justify-content-start">
                {!!type &&
                    <Icon
                        icon={{
                            icon: type,
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                            className: "energy-widget__icon",
                        }}
                    />}
                <Typography use="headline6" className="mdc-typograhpy energy-widget__title">
                    {name}
                </Typography>
            </div>

            <div className="energy-widget__data mt-8">
                {items.map((x, i) => {
                    return !!x.subtitle || (!!x.value || 0) || !!x.unit
                        ? <div className="energy-widget__item" key={i}>
                            {!!x.subtitle &&
                                <div className="energy-widget__item__subtitle">{x.subtitle}</div>}

                            <div className="energy-widget__item__value">{x.value || 0}</div>

                            {!!x.unit &&
                                <div className="energy-widget__item__unit">{x.unit}</div>}
                        </div>
                        : null
                }
                )}
            </div>
        </div>
    );
}

EnergyWidget.defaultProps = {
    items: [],
}

EnergyWidget.propTypes = {
    name: PropTypes.string,
    type: PropTypes.oneOf(Object.values(EnergyType)),
    onClick: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            subtitle: PropTypes.string,
            value: PropTypes.number,
            unit: PropTypes.string
        })
    ),
}

export default injectIntl(EnergyWidget);
