import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import Tabs from "../components/common/Tabs";
import { useIntl } from "react-intl";
import BonusCards from "../components/common/BonusCards";
import PaymentCards from "./PaymentCards";
import { useLocation } from "react-router-dom";

const Cards = () => {
    const intl = useIntl();
    const { state } = useLocation();

    const tabItems = [
        {
            label: intl.formatMessage({ id: "Cards.Tab.BonusCard" }),
            contents: <BonusCards />
        },
        {
            label: intl.formatMessage({ id: "Cards.Tab.PaymentCard" }),
            contents: <PaymentCards />
        }
    ];

    return (
        <Grid>
            <GridCell span={12}>
                <Tabs
                    defaultTabIndex={state?.tabIndex || 0}
                    items={tabItems.map(item => ({
                        label: item.label,
                        contents: item.contents
                    }))}
                />
            </GridCell>
        </Grid>
    )
}

export default Cards;
