import React from "react";
import PropTypes from "prop-types";
import { Button } from "@rmwc/button";
import { defaultIconProps } from "./Icon";
import classNames from "classnames";

const DownloadButton = ({ onClick, className, ...props }) => {

    return (<Button
        outlined
        className={classNames("mdc-button--thin", className)}
        trailingIcon={{
            ...defaultIconProps,
            icon: "download"
        }}
        onClick={onClick}
    >
        {props.children}
    </Button>);
}

DownloadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
}

export default DownloadButton;