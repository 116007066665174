import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import DatePicker from "./DatePicker";

class DatePickerRange extends React.Component {
    render() {
        // Conditionally set default values when they cannot be null
        let startValue = (!this.props.isNullableStart && !this.props.startValue) ? new Date() : this.props.startValue;
        let endValue = (!this.props.isNullableEnd && !this.props.endValue) ? new Date() : this.props.endValue;
        return (
            <div className="datepicker-range">
                <DatePicker
                    label={this.props.label}
                    field={this.props.fieldStart}
                    value={startValue}
                    autoComplete="off"
                    onChange={this.props.startOnChange}
                    locale={this.props.intl.locale}
                    maxDate={this.props.maxStartDate}
                    isNullable={this.props.isNullableStart}
                />
                <span className="delimiter">&#8211;</span>
                <DatePicker
                    field={this.props.fieldEnd}
                    value={endValue}
                    autoComplete="off"
                    onChange={this.props.endOnChange}
                    locale={this.props.intl.locale}
                    minDate={this.props.minEndDate}
                    isNullable={this.props.isNullableEnd}
                />
            </div>
        );
    }
}

DatePickerRange.propTypes = {
    fieldStart: PropTypes.string,
    fieldEnd: PropTypes.string,
    startValue: PropTypes.instanceOf(Date),
    endValue: PropTypes.instanceOf(Date),
    endOnChange: PropTypes.func.isRequired,
    startOnChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    minEndDate: PropTypes.instanceOf(Date),
    maxStartDate: PropTypes.instanceOf(Date),
    isNullableStart: PropTypes.bool,
    isNullableEnd: PropTypes.bool,
};

DatePickerRange.defaultProps = {
    label: "Invoices.Filter.Period",
    minEndDate: null,
    maxStartDate: null,
};

export default injectIntl(DatePickerRange);
