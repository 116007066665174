import React from 'react';
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseFormComponent from "../common/BaseFormComponent";
import { Button } from "@rmwc/button";
import "../../styles/components/_green-energy-dialog.scss";
import { Grid, GridCell } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { deactivateGreenEnergy, activateGreenEnergy } from "../../actions/contractActions";
import moment from "moment";
import CheckboxField from "../common/CheckboxField";
import greenEnergyLogo from "../../images/rohesarts-main.svg";
import { alexelaGasTOSUrl, alexelaElectricityTOSUrl, alexelaPriceListUrl } from "../../const/urls";
import Loader from "../../components/common/Loader";

class GreenEnergyService extends BaseFormComponent {

    static MODAL_TYPE_ACTIVATE = "activate";
    static MODAL_TYPE_DEACTIVATE = "deactivate";

    static fieldTOS = "tos-accept";

    constructor(props) {
        super(props);
        this.state = {
            contract: this.props.contract,
            [GreenEnergyService.fieldTOS]: false,
         }
    }

    getTOSLink(isElecricity) {
        if (isElecricity) {
            return alexelaElectricityTOSUrl;
        } else {
            return alexelaGasTOSUrl;
        }
    }

    getStartOfNextMounth() {
        return moment().add(1, 'M').startOf('month').format('DD.MM.YYYY');
    }

    getEndOfMonth () {
        return moment().endOf('month').format('DD.MM.YYYY');
    }

    activateGreenEnergy() {
        this.props.activateGreenEnergy(this.props.match.params.id);
    }

    deactivateGreenEnergy() {
        this.props.deactivateGreenEnergy(this.props.match.params.id);
    }

    render() {
        const contract = this.props.contract;

        return (
                <Grid>
                    <GridCell span={12}>
                        <div className="green-energy__logo">
                            <img alt="Rohesärts logo" src={greenEnergyLogo} />
                        </div>

                        <div className="green-energy__container">
                            <div className="green-energy__info">
                                <Typography
                                    use="headline2"
                                >
                                    <FormattedMessage id="ContractDetails.GreenEnergyService.Title" />
                                </Typography>
                                <div className="information__description lighter mb-15">
                                    <FormattedMessage id="ContractDetails.GreenEnergyService.Description" />
                                </div>
                            </div>

                            <div className="green-energy__prices mt-30">
                                <Typography
                                    use="headline2"
                                >
                                    <FormattedMessage id="ContractDetails.GreenEnergyService.Details" />
                                </Typography>

                                <div className="table-wrap mb-10 mb-mobile-30">
                                    <table className="table-detail-list">
                                        <tbody>
                                            <tr>
                                                <td className="bold">
                                                    <FormattedMessage id="ContractDetails.GreenEnergyService.Marginal" />
                                                </td>
                                                <td>
                                                    {contract.greenEnergy?.marginal?.value} {contract.greenEnergy?.marginal?.unit}
                                                </td>
                                            </tr>
                                            <tr>
                                                {this.props.modalType === GreenEnergyService.MODAL_TYPE_ACTIVATE &&
                                                    <>
                                                        <td className="bold">
                                                            <FormattedMessage id="ContractDetails.GreenEnergyService.StartDate" />
                                                        </td>
                                                        <td>
                                                            {this.getStartOfNextMounth()}
                                                        </td>
                                                    </>
                                                }
                                                {this.props.modalType === GreenEnergyService.MODAL_TYPE_DEACTIVATE &&
                                                    <><td className="bold">
                                                            <FormattedMessage id="ContractDetails.GreenEnergyService.EndDate" />
                                                        </td>
                                                        <td>
                                                            {this.getEndOfMonth()}
                                                        </td>
                                                    </>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="green-energy__terms mt-30">
                                <CheckboxField
                                    nativeControlId={GreenEnergyService.fieldTOS}
                                    field={GreenEnergyService.fieldTOS}
                                    checked={this.state[GreenEnergyService.fieldTOS]}
                                    indeterminate={false}
                                    label={
                                        <label htmlFor="tos-accept">
                                            {this.props.modalType === GreenEnergyService.MODAL_TYPE_ACTIVATE &&
                                                <FormattedMessage id="Contract.AcceptTerms"/>
                                            }
                                            {this.props.modalType === GreenEnergyService.MODAL_TYPE_DEACTIVATE &&
                                                <FormattedMessage id="ContractDetails.GreenEnergyService.DeactivateTerms"/>
                                            }
                                        </label>
                                    }
                                    onChange={this.onChangeCheckbox}
                                />

                                <div className="information__description lighter mb-15">
                                    {this.props.modalType === GreenEnergyService.MODAL_TYPE_ACTIVATE &&
                                        <FormattedMessage id="Contract.Terms.GreenService"
                                            values={{
                                                TOSLink: <a
                                                    href={this.getTOSLink("electricity")} target="_blank" rel="noopener noreferrer">
                                                    <FormattedMessage id="Contract.Terms.Here"/>
                                                </a>,
                                                priceLink: <a
                                                    href={alexelaPriceListUrl} target="_blank" rel="noopener noreferrer">
                                                    <FormattedMessage id="Contract.Terms.Here"/>
                                                </a>,
                                                greenLink: <a
                                                    href={this.getTOSLink("electricity")} target="_blank" rel="noopener noreferrer">
                                                    <FormattedMessage id="Contract.Terms.Here"/>
                                                </a>,
                                            }}/>
                                    }
                                </div>
                            </div>

                            <div className="green-energy__controls mt-30">
                                {this.props.modalType === GreenEnergyService.MODAL_TYPE_ACTIVATE ?
                                    <Button
                                        outlined
                                        disabled={!this.state[GreenEnergyService.fieldTOS]}
                                        onClick={this.activateGreenEnergy.bind(this)}
                                    >
                                        {this.props.activateGreenEnergyFetching ?
                                            <Loader type={Loader.TYPE_BUTTON} />
                                            :
                                            <FormattedMessage id="ContractDetails.GreenEnergyService.Activate" />
                                        }
                                    </Button>
                                    :
                                    <Button
                                        outlined
                                        disabled={!this.state[GreenEnergyService.fieldTOS]}
                                        onClick={this.deactivateGreenEnergy.bind(this)}
                                    >
                                        {this.props.deactivateGreenEnergyFetching ?
                                            <Loader type={Loader.TYPE_BUTTON} />
                                            :
                                            <FormattedMessage id="ContractDetails.GreenEnergyService.Deactivate" />
                                        }
                                    </Button>
                                }
                            </div>
                        </div>
                    </GridCell>
                </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        activateGreenEnergyFetching: state.activateGreenEnergy.fetching,
        deactivateGreenEnergyFetching: state.deactivateGreenEnergy.fetching,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ deactivateGreenEnergy, activateGreenEnergy }, dispatch);
}

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(GreenEnergyService)));