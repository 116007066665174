import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateIntl } from "react-intl-redux";
import classnames from "classnames";
import language from "../lang/lang";
import * as queryString from "query-string/index";
import { Button } from "@rmwc/button";
import { MapLang } from "../helpers/LangHelper";
import { IsLatvianWeb } from "../helpers/AlternativeDesign";

/**
 * Renders language change component
 * but does not render selected language.
 *
 * Basic output:
 * <div class="language-selector">
 *     <button class="language-item">EST</button>
 *     <button class="language-item">ENG</button>
 *     <button class="language-item">RUS</button>
 *     <button class="language-item">LAT</button>
 * </div>
 */
class LanguageSelector extends React.Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);
        this.state = {
            updatedLang: language.getLangToSSO(),
            isLatvianWeb: IsLatvianWeb(),
            country: IsLatvianWeb() ? "lv" : "ee"
        };
        this.initLang(params);
        this.getSSOLink = this.getSSOLink.bind(this);
    }

    getSSOLink() {
        const ssoUrl = this.state.isLatvianWeb
            ? process.env.REACT_APP_SSO_LV_URL
            : process.env.REACT_APP_SSO_URL;

        return `${ssoUrl}home/SetExternalLang?locale=${MapLang(this.state.updatedLang)}`;
    }

    initLang(params) {
        const lang = params.lang;

        if (lang && language.exists(lang, this.state.country)) {
            this.changeLanguage(lang);
        }
    }

    /**
     * Change content langue.
     *
     * @param {string} lang For example "et".
     */
    changeLanguage(lang) {
        this.props.changeLanguage({
            locale: lang,
            messages: language.getMessages(lang)
        });

        language.setLang(lang);
    }

    render() {
        const languages = language.getLanguagesByCountry(this.state.country);
        const selectedLanguage = language.getLang();

        const items = languages.map((item, index) => {
            return selectedLanguage !== item.value && (
                <Button
                    key={index}
                    className={classnames(
                        "mdc-button--text",
                        "nav__link",
                        "nav__link--language",
                        "language-item"
                    )}
                    onClick={this.changeLanguage.bind(this, item.value)}
                >
                    {item.label}
                </Button>
            );
        });

        return (
        <>
            <div className="language-selector">{items}</div>
            {this.state.updatedLang &&
                <img src={this.getSSOLink()} alt="Set locale to system" className="hide" />
            }
        </>);
    }
}

/**
 * Pass data to our component.
 */
function mapStateToProps(state) {
    return {};
}

/**
 * Pass your actions to your container.
 */
function matchDispatchToProps(dispatch) {
    return bindActionCreators({ changeLanguage: updateIntl }, dispatch);
}

export default withRouter(
    connect(mapStateToProps, matchDispatchToProps)(LanguageSelector)
);
