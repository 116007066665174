import auth from "../auth/authenticate";
import BusinessAPI from './BusinessApi';

export const fetchPayments = (filter) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_PAYMENTS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/payment`, {params: filter})
    };
};