import React from "react";

const DoughnutChart = () => {
    return (
        <div className="chart doughnut">
            <div className="sector done"></div>
            <div className="sector done"></div>
            <div className="sector done"></div>
            <div className="sector done"></div>
        </div>
    );
};

export default DoughnutChart;
