import React from "react";
import { Select } from "@rmwc/select";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import "../../styles/react-components/phoneInput.scss";
import classnames from "classnames";
import ValidationHelper from "../../helpers/validationHelper";
import Loader from "../common/Loader";
import { Icon } from "@rmwc/icon";

class SelectField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: null
        };

        this.generateOptions = this.generateOptions.bind(this);
        this.setReferenceWidth = this.setReferenceWidth.bind(this);
        this.selectRef = React.createRef();
    }

    componentDidMount() {
        this.setReferenceWidth();
        window.addEventListener('resize', this.setReferenceWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setReferenceWidth);
    }

    componentDidUpdate() {
        this.setReferenceWidth();
    }

    setReferenceWidth() {
        if (
            this.selectRef.current &&
            this.state.width !== this.selectRef.current.offsetWidth
        ) {
            this.setState({ width: this.selectRef.current.offsetWidth });
        }
    }

    isObject(item) {
        return typeof item === 'object';
    }

    generateOptions(options) {
        let formattedOptions = options ?
            options.map((m, i) => {
                let value = this.isObject(m) ? m.value : m;
                let label = this.isObject(m) ? m.label : m

                return (
                    {
                        ...m,
                        style: m.style || {
                            width: this.state.width
                                ? this.state.width - 5
                                : 0
                        },
                        label: label,
                        value: value

                    }
                );
            }) : []
            ;
        return formattedOptions;
    }

    render() {
        let labelTranslated = this.props.label
            ? this.props.intl.formatMessage({ id: this.props.label })
            : "";
        const { onBlur, validationMessage, isRequired } =
            ValidationHelper.getFieldValidationItems(
                this.props.validator, this.props.rules, labelTranslated, this.props.value);

        let leadingIcon = this.props.isLoading ? <Icon tag={Loader} type={Loader.TYPE_SELECT_FIELD} /> : this.props.leadingIcon;
        let options = this.generateOptions(this.props.options);

        return (
            <div
                className={classnames(
                    "select-field--wrapper",
                    this.props.wrapperClassName
                )}
                ref={this.selectRef}
                onClick={() => !this.state.width ? this.setReferenceWidth() : null}
            >
                <Select
                    required={isRequired}
                    onBlur={onBlur}
                    outlined
                    icon={leadingIcon}
                    className={classnames(this.props.className, {
                        "mdc-select--invalid": !!validationMessage,
                    })}
                    disabled={this.props.disabled}
                    enhanced
                    label={labelTranslated}
                    value={this.props.value}
                    name={this.props.field}
                    onChange={this.props.onChange.bind(
                        this,
                        this.props.afterChange,
                        this.props.field,
                    )}
                    afterChange={this.props.afterChange}
                    helperText={ValidationHelper.getSelectHelperText(
                        validationMessage
                    )}
                    options={options}
                    placeholder={this.props.placeholder}
                >
                </Select>
            </div>
        );
    }
}

SelectField.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func.isRequired,
    afterChange: PropTypes.func,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    children: PropTypes.array,
    validator: PropTypes.object,
    rules: PropTypes.string,
    wrapperClassName: PropTypes.string,
    leadingIcon: PropTypes.object,
    isLoading: PropTypes.bool,
    placeholder: PropTypes.string
};

SelectField.defaultProps = {
    options: [],
    disabled: false,
    isLoading: false
};

export default injectIntl(SelectField);
