import {
    FETCH_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS,
    RESET_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS
} from "../../actions/notificationActions";

export default function reducer(state = {
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS}_PENDING`: {
            return { ...state, fetching: true, error: null };
        }
        case `${FETCH_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload.data };
        }
        case `${FETCH_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, error: null, data: action.payload.data };
        }
        case RESET_ACCOUNT_COUNT_FOR_MARKETING_NOTIFICATIONS: {
            return { ...state, fetching: false, fetched: false, error: null, data: {} };
        }
        default:
            return state;
    }
}