import auth from "../auth/authenticate";
import BusinessAPI from './BusinessApi';

export const FETCH_USER_STATUS="FETCH_USER_STATUS";
export const POST_PRINT_PAPER_RECEIPT="POST_PRINT_PAPER_RECEIPT";

export const fetchRoles = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_ROLES',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/roles`)
    };
};

export const fetchAuthorizedUsers = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_AUTHORIZED_USER',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/authorizedUser`)
    };
};

export const fetchCanSetAuthorizations = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_CAN_SET_AUTHORIZATIONS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/canSetAuthorizations`)
    };
};

export const fetchUserRoleInCompany = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_USER_ROLE_IN_COMPANY',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/boardMember`)
    };
}

export const createAuthorizedUser = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'POST_AUTHORIZED_USER',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/account/authorizedUser`, data)
    };
};

export const updateAuthorizedUsers = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'PUT_AUTHORIZED_USERS',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/account/authorizedUser`, data)
    };
};
export const fetchContact = (contactId) => {
    const accountId = auth.getUserId();
    const roleId = auth.getRoleId();

    return {
        type: "FETCH_CONTACT",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/contact/${contactId}`)
    };
};

export const fetchContacts = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: "FETCH_CONTACTS",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/contacts`)
    };
};

export const fetchAccount = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: "FETCH_ACCOUNT",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account`)
    };
};

export const postPrintPaperReceipt = (model) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: POST_PRINT_PAPER_RECEIPT,
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/account/print-paper-receipt`, model)
    };
};

export const fetchMainContact = () => {
    // Company does not have a main contact so we make a dummy fetch
    if (auth.isCompany()) {
        return {
            type: "FETCH_MAIN_CONTACT",
            payload: {}
        };
    }

    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: "FETCH_MAIN_CONTACT",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/main-contact`)
    };
};

export const setRole = (newRoleId) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'ROLE_SET',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/account/roles/${newRoleId}`)
    };
};

export const setRoleToBusiness = (regCode) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'ROLE_SET',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/account/roles/business/${regCode}`)
    };
};

export const setAgentRole = (ssn) => {
    if (ssn) {
        ssn = ssn.trim();
    } else {
        ssn = '';
    }

    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'AGENT_ROLE_SET',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/account/roles/agent/${ssn}`)
    };
};

export const putAccount = (model) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    model.groupId = roleId;

    return {
        type: 'PUT_ACCOUNT',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/account`, model)
    };
};

export const putContacts = (contact) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: 'PUT_CONTACTS',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/account/contacts`, contact)
    };
};

export const postContact = (contact) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_CONTACT',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/account/contact`, contact)
    };
}

export const fetchUserGroupCode = () => {
    const accountId = auth.getUserId();
    const roleId = auth.getRoleId();

    return {
        type: "FETCH_USER_GROUP_CODE",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/get-user-group`)
    };
}

export const fetchAuthorizedContracts = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "FETCH_AUTHORIZED_CONTRACTS",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/authorizedContracts`)
    };
}

export const fetchUsersWithRole = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "FETCH_USERS_WITH_ROLE",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/usersWithRole`)
    }
}

export const putUserWithRole = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "PUT_USER_WITH_ROLE",
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/account/userWithRole`, data)
    }
}

export const getUserCompanyRoles = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: "GET_USER_COMPANY_ROLES",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/userCompanyRoles`)
    }
}

export const fetchUserStatus = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_USER_STATUS,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/user-status`)
    }
}

export const verifyUserSigningRight = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'VERIFY_SIGNING_RIGHT',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/account/verify-signing-right`)
    };
}