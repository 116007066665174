import {PUT_SMS_TEMPLATE} from "../../actions/notificationActions";

export default function reducer(state = {
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${PUT_SMS_TEMPLATE}_PENDING`: {
            return {...state, fetching: true, fetched: false, error: null};
        }
        case `${PUT_SMS_TEMPLATE}_REJECTED`: {
            return {...state, fetching: false, error: action.payload};
        }
        case `${PUT_SMS_TEMPLATE}_FULFILLED`: {
            return {...state, fetching: false, fetched: true, data: action.payload.data, error: null};
        }
        default:
            return state;
    }
}
