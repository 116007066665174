
export const coopCardPrefix = "92339990000";

export function isCreditcard(cardNumber) {
    return !!cardNumber && cardNumber.startsWith("92337454");
}

export function isCoopcard(cardNumber) {
    return !!cardNumber && cardNumber.startsWith(coopCardPrefix);
}

export const formatCardNumber = (number) => number.replace(/\d{4}/g, "$& ").trim();
