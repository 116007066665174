import React, { useState } from "react";
import classnames from "classnames";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@rmwc/button";
import InfoTooltip from "./../common/InfoTooltip";
import { fetchReceiptPdf } from "../../actions/transactionActions";
import { useDispatch } from "react-redux";

const BonuscardTransactionRow = ({ transaction }) => {
    const [openInMobile, setOpenInMobile] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);

    const date = transaction.transactionDate && moment(transaction.transactionDate);
    const isOlderThenMonth = moment().diff(date, 'months') > 1;
    const googleQuery = encodeURIComponent("Alexela " + transaction.station);

    const intl = useIntl();
    const dispatch = useDispatch();

    const toggleOpenInMobileView = () => {
        setOpenInMobile(prev => !prev);
    }

    const toggleDetailsView = () => {
        setOpenDetails(prev => !prev);
    }

    const renderTransactionSum = (sum, unit) => {
        return `${intl.formatNumber(sum, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        })} ${unit ? unit : "€"}`;
    };

    const renderDetails = (transaction) => {
        return (
            <div className="table-list table-list--nested">
                <div className="table-list__header">
                    <div className="table-list__cell pl-15">
                        <FormattedMessage id="Transactions.Table.Product" />
                    </div>
                    <div className="table-list__cell">
                        <FormattedMessage id="Transactions.Table.Quantity" />
                    </div>
                    <div className="table-list__cell">
                        <FormattedMessage id="Transactions.Table.Unit" />
                    </div>
                </div>
                {transaction.products?.map((product, index) =>
                    <div className="table-list__row border-bottom" key={index}>
                        <div className="table-list__cell pl-15">{product.productName}</div>
                        <div className="table-list__cell">{product.quantity}</div>
                        <div className="table-list__cell">{product.productUnit}</div>
                    </div>
                )}
            </div>
        );
    }

    return (
        <React.Fragment>
            <tr
                className={classnames({
                "open": !isOlderThenMonth || openInMobile
                })}
                onClick={toggleOpenInMobileView}
            >
                <td className="hidden-desktop wrap">{transaction.station}</td>
                <td className="table--card-body-info">{date && date.format("DD.MM.YYYY HH:mm")}</td>
                <td className="show-open hidden-desktop wrap">
                    {transaction.products?.map((product, i) =>
                        <div key={i}>{`${product.productName} | ${product.quantity} ${product.productUnit}`}</div>
                    )}
                </td>
                <td className="show-open">{transaction.cardNumber}</td>
                <td className="show-open">{transaction.cardName}</td>
                <td className="hidden-mobile">{transaction.station}</td>
                <td className="text-right pull-right">
                    {renderTransactionSum(transaction.sum, transaction.unit)}
                </td>
                <td className="show-open hidden-desktop">
                    {transaction.collectedStamps && transaction.collectedStamps > 0
                        ? `${intl.formatMessage({ id: "DigitalStamps.MobileCard.CollectedStamps"})} ${transaction.collectedStamps ?? ""}`
                        : transaction.isFixPrice &&
                        <>
                            <FormattedMessage id="DigitalStamps.MobileCard.CollectedStamps" />
                            <InfoTooltip content={intl.formatMessage({ id: "Transactions.Table.DigitalStamps.FixPriceToolTip"})} />
                        </>
                    }
                </td>
                <td className="hidden-mobile text-center">
                    {transaction.collectedStamps && transaction.collectedStamps > 0
                        ? transaction.collectedStamps
                        : transaction.isFixPrice &&
                        <InfoTooltip content={intl.formatMessage({ id: "Transactions.Table.DigitalStamps.FixPriceToolTip"})} />}
                </td>
                <td className="show-open hidden-desktop">
                    {transaction.receiptId &&
                        <Button
                            className="mdc-theme--secondary mdc-button--link"
                            onClick={() => dispatch(fetchReceiptPdf(transaction.receiptId))}
                        >
                            <FormattedMessage id="Transactions.Table.DownloadReceipt" />
                        </Button>
                    }
                </td>
                <td className="show-open hidden-desktop">
                    <a className="icon icon-angle-right" href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${googleQuery}`} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="Transactions.Table.ServicePoint.GoThere" />
                    </a>
                </td>
                <td className="hidden-mobile">
                    {transaction.receiptId &&
                        <Button
                            className="mdc-theme--secondary mdc-button--link"
                            onClick={() => dispatch(fetchReceiptPdf(transaction.receiptId))}
                        >
                            <FormattedMessage id="Transactions.Table.Download" />
                        </Button>
                    }
                </td>
                <td className="hidden-mobile">
                    <Button
                        className="mdc-button__icon mdc-button--tiny"
                        onClick={toggleDetailsView}
                    >
                        <i className={classnames("icon icon-angle-down p-0", openDetails ? "rotate" : "")} />
                    </Button>
                </td>
            </tr>
            {openDetails &&
                <tr className={classnames("hidden-mobile",{ open: openDetails })}>
                    <td className="p-0" colSpan={9}>{renderDetails(transaction)}</td>
                </tr>
            }
        </React.Fragment>
    );
}

export default BonuscardTransactionRow;
