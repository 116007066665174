import React from "react";
import { Route } from "react-router-dom";
import LoginLayout from "../../LoginLayout";
import AgentLayout from "../../AgentLayout";
import PrivateRoute from "./PrivateRoute";
import DefaultLayout from "../../DefaultLayout";
import EmptyLayout from "../../EmptyLayout";
import { useSelector } from "react-redux";

const AppRoute = ({ component: Component, layout: Layout, isPrivate, layoutParams, ...rest }) => {
    const role = useSelector(state => state.auth.role);

    const layouts = {
        DefaultLayout,
        AgentLayout,
        LoginLayout,
        EmptyLayout
    };

    const routes = {
        PrivateRoute: PrivateRoute,
        Route: Route
    };
    const isPrivateRoute = isPrivate === undefined ? true : isPrivate;
    const LayoutTemplate = layouts[Layout] || layouts.DefaultLayout;
    const RouteTemplate = isPrivateRoute ? routes.PrivateRoute : routes.Route;
    window.scrollTo(0, 0);

    return (
        <LayoutTemplate key={role ? role.crmId : 0} {...layoutParams}>
            <RouteTemplate {...rest} component={Component}/>
        </LayoutTemplate>
    );
}

export default AppRoute;