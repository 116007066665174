import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";

import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import BaseFormComponent from "../common/BaseFormComponent";
import { nordpoolRanges, createRangePresets } from "../common/DateRangePreset";
import Loader from "../common/Loader";

class NordpoolFilter extends BaseFormComponent {
    static FieldPeriodStart = "filter.startDate";
    static FieldPeriodEnd = "filter.endDate";
    static FieldSelectedPeriodPreset = "filter.selectedPeriodPreset";

    constructor(props) {
        super(props);

        this.state = {
            periodPresets: createRangePresets(nordpoolRanges, this.props.intl),
            filter: {
                startDate: this.props.filter.startDate,
                endDate: this.props.filter.endDate,
                selectedPeriodPreset: this.props.filter.selectedPeriodPreset,
                selectedPeriodPresetName: this.props.filter.selectedPeriodPresetName,
            },
        };

        this.isPeriodPresetSelected = this.isPeriodPresetSelected.bind(this);
        this.onFilterPeriodPresetChange = this.onFilterPeriodPresetChange.bind(this);
        this.filter = this.filter.bind(this);
    }

    onFilterPeriodPresetChange(value) {
        if (value) {
            let period = this.state.periodPresets[+value.value];

            if (period) {
                this.setState((prev) => ({
                    filter: {
                        ...prev.filter,
                        selectedPeriodPreset: period,
                        selectedPeriodPresetName: period.label,
                        startDate: moment(period.range.startDate),
                        endDate: moment(period.range.endDate),
                    },
                }), this.filter);
            }
        }
    }

    isPeriodPresetSelected(period) {
        const { filter: { selectedPeriodPreset } } = this.state;
        return !!selectedPeriodPreset && +selectedPeriodPreset.value === +period.value;
    }

    filter() {
        this.props.onSubmit(this.state.filter);
    }

    render() {
        const { periodPresets } = this.state;
        const { loading } = this.props;

        return (
            <Grid className={classnames("filter-form", { "filter-form--open": this.props.open })}>
                {loading &&
                    <Loader type={Loader.TYPE_COVER} />}

                <GridCell span={12}>
                    <div className="d-flex d-flex--inline d-flex--wrap justify-content-center">
                        {periodPresets.map((x, i) => (
                            <Button
                                key={"preset-" + i}
                                className={classnames(
                                    "filter-form__quick-link",
                                    { "active": this.isPeriodPresetSelected(x) }
                                )}
                                onClick={this.onFilterPeriodPresetChange.bind(this, x)}>
                                {x.label}
                            </Button>
                        ))}
                    </div>
                </GridCell>
            </Grid>
        );
    }
}

NordpoolFilter.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    filter: PropTypes.object,
    loading: PropTypes.bool
};

export default injectIntl(NordpoolFilter);
