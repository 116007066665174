import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router"
import auth from "../../auth/authenticate";
import * as Path from "../../const/routes";
import { setRoleToBusiness,  } from "../../actions/accountActions";
import { updateUser } from "../../actions/authActions";

const useRoute = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const role = auth.getRole();
    const setRole = useSelector(state => state.setRole);

    const checkAgentPath = (currentPath) => {
        const isAgent = auth.isAgent();
        if (isAgent && currentPath !== Path.AgentServicePath
            && currentPath !== Path.AgentGasPath
            && currentPath !== Path.AgentElectricityPath
            && currentPath !== Path.AgentCommunityPath
            && currentPath !== Path.AgentContractThankYouPath) {
            history.push(Path.AgentServicePath);
        } else if (!isAgent && (currentPath === Path.AgentServicePath
            || currentPath === Path.AgentGasPath
            || currentPath === Path.AgentElectricityPath
            || currentPath === Path.AgentCommunityPath)) {
            history.push(Path.StartPath);
        }
    }

    const checkContractOfferPath = (currentPath) => {
        const regex = /\/service\/contract\/(?:electricity|gas|card|cylindergas)\/offer\/\d+\/\d+$/;
        if (regex.test(currentPath)) {
            const regCode = currentPath.split('/').pop();

            if (role?.nationalIdentityNumber === regCode) {
                return;
            }

            dispatch(updateUserRoleToBusiness(regCode));
        }
    }

    const updateUserRoleToBusiness = (regCode) => {
        return (dispatch) => {
            return dispatch(setRoleToBusiness(regCode))
                .then(() => dispatch(updateUser()))
                .catch(error => console.error("inner error", error));
        }
    }

    const redirectIfMatch = (location) => {
        if (setRole.fetching) return;

        const currentPath = location?.pathname?.toLowerCase() || "";

        checkAgentPath(currentPath);
        checkContractOfferPath(currentPath);
    }

    return redirectIfMatch;
}

export default useRoute;