import { IconButton } from "@rmwc/icon-button";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { defaultIconProps } from "../common/Icon";
import { SummerCampaignPrize } from "../../const/summerCampaign";

import Tub from "../../images/summer_campaign/tub_thumbnail_large.png";
import FreeCoffeeYear from "../../images/summer_campaign/coffee_year_thumbnail_large.png";
import Beans from "../../images/summer_campaign/coffee_beans_thumbnail_large.png";
import Cup from "../../images/summer_campaign/coffee_cup_thumbnail_large.png";
import FreeCoffee from "../../images/summer_campaign/coffee_thumbnail_large.png";
import Money from "../../images/summer_campaign/money_thumbnail_large.png";
import { isMoneyPrize } from "../../helpers/summerCampaignHelper";

const PrizeImageMap = {
    [SummerCampaignPrize.Tub]: Tub,
    [SummerCampaignPrize.Beans]: Beans,
    [SummerCampaignPrize.Cup]: Cup,
    [SummerCampaignPrize.FreeCoffee]: FreeCoffee,
    [SummerCampaignPrize.FreeCoffeeYear]: FreeCoffeeYear,
    [SummerCampaignPrize.Money2]: Money,
    [SummerCampaignPrize.Money5]: Money,
    [SummerCampaignPrize.Money10]: Money,
    [SummerCampaignPrize.Money20]: Money,
    [SummerCampaignPrize.Money100]: Money,
};

const PrizeOverview = ({ item, onClose }) => {
    const isMobile = useSelector(state => state.ui.isMobileOrTabletView);

    return (
        <div className="summer-campaign summer-campaign__prize-overview">
            <IconButton
                checked
                onIcon={{ ...defaultIconProps, icon: "close" }}
                onClick={onClose}
                width={32}
                height={32}
            />
            <h2>{item.title}</h2>
            <div className="summer-campaign__prize-overview_image">
                <img
                    className={classNames({
                        "winner": item.isWin,
                        "mobile": isMobile,
                        "tub": item.type === SummerCampaignPrize.Tub
                    })}
                    src={PrizeImageMap[item.type]}
                    alt={item.title}
                />
                {item.isWin && item.winAmount > 0 &&
                    <div className="summer-campaign__prize-overview_badge">
                        <FormattedMessage
                            id="SummerCampaign.Prizes.Unredeemed"
                            values={{
                                amount: item.winAmount
                            }}
                        />
                    </div>
                }
                <p>
                    <FormattedHTMLMessage
                        id={`SummerCampaign.Prizes.${isMoneyPrize(item.type) ? "Money" : item.type}.Description`}
                    />
                </p>
            </div>
        </div>
    );
}

export default PrizeOverview;