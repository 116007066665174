import { FETCH_GASO_METER_LIST } from "../../actions/gasoMeterActions";

export default function reducer(state = {
    data: null,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_GASO_METER_LIST}_PENDING`: {
            return { ...state, fetching: true, data: null };
        }
        case `${FETCH_GASO_METER_LIST}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload, data: null };
        }
        case `${FETCH_GASO_METER_LIST}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, data: action.payload.data };
        }
        default:
            return state;
        
    }
}