import React from "react";

const EmptyLayout = (props) => {
    return (
        <section>
            {props.children}
        </section>
    );
}

export default EmptyLayout;