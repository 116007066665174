import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import { Button } from "@rmwc/button";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";

import Loader from "../../components/common/Loader";
import { resetDeviceRegistration, sendTestNotification } from "../../actions/pushNotificationActions";
import { resetBannerForEveryone } from "../../actions/bannerActions";
import { getDeviceRegistrationInfo } from "../../helpers/pushNotificationHelper";
import TestNotificationsList from "../../components/DebugPage/TestNotificationsList";
import NotificationHubInstallations from "../../components/DebugPage/NotificationHubInstallations";
import DebugVariables from "../../components/DebugPage/DebugVariables";
import {EnabledFeatures} from "../../components/DebugPage/EnabledFeatures";

const Debug = () => {
    const dispatch = useDispatch();

    const { fetching: resetDeviceRegistrationFetching } = useSelector(state => state.resetDeviceRegistration);
    const { fetching: sendingTestNotification } = useSelector(state => state.sendTestNotification);
    const { fetching: resetBannerForEveryoneFetching } = useSelector(state => state.resetBannerForEveryone);

    const resetPushNotificationRegistration = () => {
        const payload = getDeviceRegistrationInfo();
        if (!!payload) {
            dispatch(resetDeviceRegistration(payload));
        }
    }

    const resetBannersForAllUsers = () => {
        dispatch(resetBannerForEveryone());
    }

    const sendTestNotificationToUsers = () => {
        dispatch(sendTestNotification([]));
    }


    return <Grid>
        <GridCell span={12}>
            <Button
                className="mdc-button--red"
                unelevated
                disabled={resetDeviceRegistrationFetching || resetBannerForEveryoneFetching}
                onClick={resetBannersForAllUsers}>
                {resetBannerForEveryoneFetching
                    ? <Loader type={Loader.TYPE_BUTTON} />
                    : <FormattedMessage id="Reset banners for everyone" />}
            </Button>
        </GridCell>

        <GridCell span={12}>
            <GridInner>
                <GridCell span={4}>
                    <Button
                        className="mdc-button--red"
                        unelevated
                        disabled={!isMobile || resetDeviceRegistrationFetching || resetBannerForEveryoneFetching}
                        onClick={resetPushNotificationRegistration}>
                        {resetDeviceRegistrationFetching
                            ? <Loader type={Loader.TYPE_BUTTON} />
                            : <FormattedMessage id="Settings.Notifications.ResetPushNotifications" />}
                    </Button>
                </GridCell>
                <GridCell span={8}>
                    <Button
                        className="mdc-button--red"
                        unelevated
                        disabled={sendingTestNotification}
                        onClick={sendTestNotificationToUsers}>
                        {sendingTestNotification
                            ? <Loader type={Loader.TYPE_BUTTON} />
                            : <FormattedMessage id="Debug.SendTestNotifications" defaultMessage="Saada test-teavitus" />}
                    </Button>
                </GridCell>
            </GridInner>
        </GridCell>

        <GridCell span={12}>
            <DebugVariables />
        </GridCell>

        <GridCell span={12}>
            <EnabledFeatures />
        </GridCell>

        <GridCell span={12}>
            <Typography use="headline4" className="mdc-typography mb-24">
                <FormattedMessage id="Debug.PushNotification.InstallationsTitle" defaultMessage="Notification hub'i installatsioonid" />
            </Typography>
            <NotificationHubInstallations />
        </GridCell>

        <GridCell span={12}>
            <Typography use="headline4" className="mdc-typograhpy mb-24">
                <FormattedMessage id="Debug.TestNotifications" defaultMessage="Test-teavitused" />
            </Typography>
            <TestNotificationsList />
        </GridCell>

    </Grid>;
}

export default Debug;