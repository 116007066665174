import { Dialog, DialogTitle, DialogButton, DialogContent } from "@rmwc/dialog";
import { Grid, GridCell } from "@rmwc/grid";
import TextFieldGroup from "../common/TextFieldGroup";
import { useValidator } from "../../helpers/hooks";
import { useEffect, useState } from "react";
import { Typography } from "@rmwc/typography";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneFieldGroupWithFlags from "../common/PhoneFieldGroupWithFlags";
import SelectField from "../common/SelectField";
import { Language } from "../../const/language";
import CheckboxField from "../common/CheckboxField";
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";
import { postContact } from "../../actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../common/AlertMessage";
import IdCodeValidatorRule from "../../validators/IdCodeValidatorRule";

const CreateContactDialog = ({ isOpen, onClose, onSuccess }) => {
    const [contact, setContact] = useState();
    const { fetched, fetching, error } = useSelector(state => state.postContact);

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!fetching && fetched) {
            onSuccess();
            setContact({});
        }
    }, [fetched, fetching]);

    const [validator, ] = useValidator({
        ...IdCodeValidatorRule.rule(),
        phoneformat: {
            message: intl.formatMessage({id: "Error.Validator.Phoneformat" }),
            rule: (val, _, validator) => {
               return validator.helpers.testRegex(val, /^\d{7,14}$/i);
            }
        }
    });

    const languageOptions = Language.map((c, i) => ({
        selected: i === 0,
        value: c.value,
        label: intl.formatMessage({ id: c.value }),
    }));

    const onSubmit = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        dispatch(postContact(contact));
    }

    return (
        <Dialog open={isOpen} onClose={onClose} className="mdc-dialog--info">
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary",
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss"
                />
            </DialogTitle>
            <DialogContent>
                <Grid>
                    <GridCell span={12}>
                        <Typography
                            use="headline4"
                            className="mdc-typography mdc-theme--primary"
                        >
                            <FormattedMessage id="Settings.User.AddNewContactDialogTitle" />
                        </Typography>
                        {error && (
                            <AlertMessage
                                isSmall={true}
                                type={AlertMessage.TYPE_ALERT}
                                title={<FormattedMessage id="Settings.User.Error" />}
                            />
                        )}
                    </GridCell>
                    <GridCell span={6}>
                        <TextFieldGroup
                            field="firstName"
                            validator={validator}
                            rules="required"
                            onChange={(e, _) =>
                                setContact(prev => ({ ...prev, firstName: e.target.value }))
                            }
                            value={contact?.firstName || ""}
                            label="Settings.User.Name"
                        />
                    </GridCell>
                    <GridCell span={6}>
                        <TextFieldGroup
                            field="lastName"
                            validator={validator}
                            rules="required"
                            onChange={(e, _) =>
                                setContact(prev => ({ ...prev, lastName: e.target.value }))
                            }
                            value={contact?.lastName || ""}
                            label="Settings.User.LastName"
                        />
                    </GridCell>
                    <GridCell span={12}>
                        <TextFieldGroup
                            field="code"
                            validator={validator}
                            rules="required|idCode:EE"
                            onChange={(e, _) =>
                                setContact(prev => ({ ...prev, idCode: e.target.value }))
                            }
                            value={contact?.idCode || ""}
                            label="Settings.User.IdentificationCode"
                        />
                    </GridCell>
                    <GridCell span={12}>
                        <TextFieldGroup
                            field="email"
                            validator={validator}
                            rules={["required", "email"]}
                            onChange={(e, _) => setContact(prev => ({ ...prev, email: e.target.value }))}
                            value={contact?.email || ""}
                            label="Settings.User.Email"
                        />
                    </GridCell>
                    <GridCell span={12}>
                        <PhoneFieldGroupWithFlags
                            validator={validator}
                            rules="required|phoneformat"
                            field="phone"
                            onUpdate={(name, val, _) => setContact(prev => ({ ...prev, phoneMobile: val }))}
                            value={contact?.phoneMobile || ""}
                            label="Settings.User.Phone"
                        />
                    </GridCell>
                    <GridCell span={12}>
                        <SelectField
                            field="language"
                            onChange={(a, b, e) => setContact(prev => ({
                                ...prev,
                                language: Language[e.detail.index].key
                            }))}
                            value={contact?.language}
                            className="mdc-select--outlined"
                            options={languageOptions}
                            isLoading={false}
                            disabled={false}
                            validator={validator}
                            rules="required"
                            label="Settings.User.Language"
                        />
                    </GridCell>
                    <GridCell tablet={8} desktop={12}>
                        <CheckboxField
                            nativeControlId="marketing-accept"
                            field="marketing"
                            checked={contact?.marketingAcceptance || false}
                            indeterminate={false}
                            label={
                                <label htmlFor="marketing-accept">
                                    <FormattedMessage id="Settings.User.Terms.AcceptNotifications.Checkbox" />
                                </label>
                            }
                            onChange={(e) => setContact(prev => ({ ...prev, marketingAcceptance: e.target.checked }))}
                        />
                        <div
                            className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent information__description lighter medium">
                            <FormattedMessage
                                id="Settings.User.Terms.AcceptNotifications.Details"
                                values={{
                                    additionalLink:
                                        <a
                                            href={intl.formatMessage({ id: "Settings.Privacy.AlexelaUrl" })}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FormattedMessage id="Settings.User.Terms.Link.Here" />
                                        </a>
                                }}
                            />
                        </div>
                    </GridCell>
                    <GridCell tablet={8} desktop={12}>
                        <div
                            className="medium mb-25">
                            <FormattedMessage id="Settings.User.AcceptTerms.Description"
                                values={{
                                    location:
                                        <a
                                            href={intl.formatMessage({ id: "Settings.Privacy.AlexelaUrl" })}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                                <FormattedMessage id="Settings.User.AcceptTerms.Description.Link" />
                                        </a>
                                }}
                            />
                        </div>
                        <Button
                            unelevated
                            onClick={onSubmit}>
                            {fetching ?
                                <Loader type={Loader.TYPE_BUTTON} /> :
                                <>
                                    <i className="icon-floppy" />
                                    <FormattedMessage id="Settings.User.Save" />
                                </>
                            }
                        </Button>
                    </GridCell>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CreateContactDialog;