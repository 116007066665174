import React from "react";
import Service from "./Service";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, GridCell } from "@rmwc/grid";
import ContactChange from "../components/Settings/ContactChange";
import { Typography } from "@rmwc/typography";
import { FormattedMessage, injectIntl } from "react-intl";
import AlertMessage from "../components/common/AlertMessage";
import {
    fetchMainContact,
    fetchAccount,
    putAccount,
    fetchUserGroupCode,
} from "../actions/accountActions";
import { sendHintEmail } from "../actions/hintActions";
import Loader from "../components/common/Loader";
import { Button } from '@rmwc/button';
import SimpleReactValidator from "simple-react-validator";
import AddressForm from "../components/AddressForm/AddressForm";
import auth from "../auth/authenticate";
import { IsLatvianWeb } from "../helpers/AlternativeDesign";
import BaseFormComponent from "../components/common/BaseFormComponent";
import BaseDialog from "../components/common/BaseDialog";
import TextAreaGroup from "../components/common/TextAreaGroup";
import { renderFormatMobile } from "../helpers/RenderHelper";

class AgentService extends BaseFormComponent {
    static FieldUserGroupCode = "userGroupCode";
    static HintEmailContent = "hintEmailContent";

    constructor(props) {
        super(props);
        this.state = {
            contact:null,
            userGroupCode: "",
            savedContact: false,
            address: {},
            showAddressResponse: false,
            groupId: auth.getRoleId(),
            isHintEmailDialogOpen: false,
            showHintEmailSuccessMessage: false,
            [AgentService.HintEmailContent]: ""
        };
        this.updateAddress = this.updateAddress.bind(this);
        this.submit = this.submit.bind(this);
        this.sendHintEmail = this.sendHintEmail.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    componentDidMount() {
        this.props.fetchAccount();

        this.props.fetchUserGroupCode().then(() => {
            this.setState({
                userGroupCode: this.props.userGroupCode !== null ? this.props.userGroupCode.userGroupCode : ""
            })
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.account !== this.props.account) {
            this.setState({
                address: this.props.account.address
            });
        }
        if (prevProps.isPutAccountLoading && !this.props.isPutAccountLoading) {
            this.setState({
                showAddressResponse: true
            });
        }

        if (prevProps.mainContact !== this.props.mainContact) {
            this.setState({
                contact: this.props.mainContact
            });
        }
    }

    submit() {
        if (this.props.isPutAccountLoading) {
            return;
        }
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        const body = {
            LegalAddressCountry: IsLatvianWeb() ? "Latvija" : "Eesti",
            LegalAddressCity: this.state.address.city,
            LegalAddressState: this.state.address.county,
            LegalAddressStreet: this.state.address.street,
            LegalAddressCode: this.state.address.postalCode,
            LegalAddressHouseNo: this.state.address.houseNr,
            LegalAddressAppartmentNo: this.state.address.apartmentNr,
            LegalAddressParish: this.state.address.parish,
            GroupId: this.props.account.crmId
        };

        this.props.putAccount(body);
    }

    finishContactChange() {
        this.props.fetchMainContact();

        this.setState({
            savedContact: true
        });
    }

    updateAddress(address) {
        this.setState({ address });
    }

    sendHintEmail() {
        const mainContact = this.props.mainContact;

        let data = {
            CustomerName: `${mainContact.firstName} ${mainContact.lastName}`,
            CustomerEmail: mainContact.email,
            CustomerIdCode: mainContact.idCode,
            CustomerPhone: mainContact.mobile,
            Hint: this.state[AgentService.HintEmailContent]
        };

        this.props.sendHintEmail(data).then(() => {
            this.setState({
                isHintEmailDialogOpen: false,
                showHintEmailSuccessMessage: true,
                [AgentService.HintEmailContent]: ""
            });
        }) ;
    }

    renderEmailHintDialog() {
        const mainContact = this.props.mainContact;

        return (
            <>
                <BaseDialog
                    isOpen={this.state.isHintEmailDialogOpen}
                    onClose={() => this.setState({isHintEmailDialogOpen: false})}
                >
                    <Grid>
                        {this.props.isHintEmailError &&
                            <GridCell span={12}>
                                <AlertMessage
                                    type={AlertMessage.TYPE_ALERT}
                                    isSmall={true}
                                    scrollToMessage={true}
                                    title={<FormattedMessage id="Settings.User.HintEmail.Error" />}
                                    className="mb-15" />
                            </GridCell>
                        }
                        <GridCell span={12}>
                            <Typography use="headline1" className="mb-15">
                                <FormattedMessage id="Settings.User.HintEmail.Title" />
                            </Typography>
                            <Typography use="body1">
                                {mainContact.firstName} {mainContact.lastName}
                            </Typography>
                            <Typography use="body1">
                                {mainContact.email}
                            </Typography>
                            <Typography use="body1">
                                {renderFormatMobile(mainContact.mobile)}
                            </Typography>
                            <Typography use="body1">
                                {mainContact.idCode}
                            </Typography>
                        </GridCell>
                        <GridCell span={12}>
                            <TextAreaGroup
                                field={AgentService.HintEmailContent}
                                label="Settings.User.HintEmail.Content"
                                rows={5}
                                maxLength={1024}
                                validator={this.validator}
                                onChange={this.onChange}
                                value={this.state[AgentService.HintEmailContent]}
                                showCounter
                            />

                            <Button
                                className="mb-35 mt-10"
                                unelevated
                                onClick={this.sendHintEmail}
                            >
                                {this.props.isSendingHintEmail
                                    ? <Loader type={Loader.TYPE_BUTTON} />
                                    : <FormattedMessage id="Settings.User.HintEmail.Send" />
                                }
                            </Button>
                        </GridCell>
                    </Grid>
                </BaseDialog>
            </>
        );
    }

    renderAddressForm() {
        return (
            <>
                <GridCell span={12}>
                    {this.state.showAddressResponse && this.props.putAccountError && this.errors(this.props.putAccountError, "Settings.User.Error")}
                    {this.state.showAddressResponse && this.props.isPutAccountLoaded &&
                        <AlertMessage
                            type={AlertMessage.TYPE_DONE}
                            isSmall={true}
                            scrollToMessage={true}
                            title={<FormattedMessage id="Settings.User.Saved" />}
                            className="mb-15" />
                    }

                    <AddressForm className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
                        label="AddressForm.Address"
                        rules="required"
                        onChange={this.updateAddress}
                        address={this.props.account.address}
                        validator={this.validator}
                        isLoading={this.props.accountFetching} />
                </GridCell>
                <GridCell span={12}>
                    <Button
                        className="mb-35 mt-10"
                        unelevated
                        disabled={!this.props.isAccountLoaded}
                        onClick={this.submit}>
                        {this.props.isPutAccountLoading ?
                            <Loader type={Loader.TYPE_BUTTON} />
                            :
                            <FormattedMessage id="Settings.User.Save" />
                        }
                    </Button>
                </GridCell>
            </>
        );
    }

    renderHintEmailSection() {
        return (
            <>
                {this.props.mainContactFetched &&
                    <>
                        <GridCell span={12} >
                            <Typography
                                use="headline3"
                                className="mdc-typography mdc-theme--primary mb-20 mt-40"
                            >
                                <FormattedMessage id={"Menu.HintEmail"} />
                            </Typography>
                            <div
                                className="medium">
                                <FormattedMessage id="Settings.User.HintEmail.Desc" />
                            </div>
                        </GridCell>

                        {this.state.showHintEmailSuccessMessage &&
                            <GridCell span={12}>
                                <AlertMessage
                                    type={AlertMessage.TYPE_DONE}
                                    isSmall={true}
                                    scrollToMessage={true}
                                    title={<FormattedMessage id="Settings.User.HintEmail.Success" />}
                                    className="mb-15" />
                            </GridCell>
                        }

                        <GridCell span={12}>
                            <Button
                                className="mb-35 mt-10"
                                unelevated
                                disabled={!this.props.isAccountLoaded}
                                onClick={() => this.setState({isHintEmailDialogOpen: true})}
                            >
                                <FormattedMessage id="Settings.User.HintEmail" />
                            </Button>
                        </GridCell>

                        {this.renderEmailHintDialog()}
                    </>
                }
            </>
        );
    }

    render() {
        return (
            <>
                <Grid className="mdc-layout-grid--base">
                    {this.state.savedContact &&
                        <GridCell span={12} >
                                <AlertMessage type={AlertMessage.TYPE_DONE}
                                    isSmall={true}
                                    title={
                                        <FormattedMessage id={"Agent.ContactChangedSuccess"} />
                                    } />
                        </GridCell>
                    }
                </Grid>

                {this.props.mainContactFetched && this.state.contact !== null &&
                    <ContactChange contact={this.state.contact}
                        finish={this.finishContactChange.bind(this)}
                        title="Agent.Client"
                        changeAccountNameGroupId={this.state.groupId} />
                }

                <Grid>
                    {this.renderAddressForm()}
                    {this.renderHintEmailSection()}

                    <GridCell span={12} >
                        <Typography
                            use="headline3"
                            className="mdc-typography mdc-theme--primary mb-20 mt-40"
                        >
                            <FormattedMessage id={"Menu.Service"} />
                        </Typography>
                    </GridCell>
                </Grid>

                <Service />

            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        mainContact: state.mainContact.data,
        mainContactFetched: state.mainContact.fetched,

        isAccountLoading: state.account.fetching,
        isAccountLoaded: state.account.fetched,
        account: state.account.data,
        isPutAccountLoaded: state.putAccount.fetched,
        putAccountError: state.putAccount.error,
        isPutAccountLoading: state.putAccount.fetching,

        userGroupCode: state.userGroupCode.data,
        userGroupCodeFetched: state.userGroupCode.fetched,

        isSendingHintEmail: state.sendHintEmail.fetching,
        isHintEmailSent: state.sendHintEmail.fetched,
        isHintEmailError: state.sendHintEmail.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
            fetchMainContact,
            putAccount,
            fetchAccount,
            fetchUserGroupCode,
            sendHintEmail
        }, dispatch
    );
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(AgentService));
