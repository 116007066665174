import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";
import BaseFormComponent from "../common/BaseFormComponent";
import { Typography } from "@rmwc/typography";

import { updateUserGroupCode } from "../../actions/cardActions";
class BonusCardUserGroupChange extends BaseFormComponent { 
    submit() {
        let data = {
            BonuscardNr: this.props.cardNumber,
            UserGroupCode: this.props.userGroupCode
        };
        this.props.updateUserGroupCode(data);
    }

    render() {
        return (
            <Grid className="mdc-layout-grid--base">
                <GridCell span={10} className="mdc-layout-grid__cell--pull">
                    <div className="information upper">
                        <Typography
                            use="headline2"
                            className="mdc-typography mdc-theme--primary information__head upper"
                        >
                            <FormattedMessage id="Service.UserGroup" />
                        </Typography>
                        <div className="information__description lighter">
                            <Typography use="body3">
                                <FormattedMessage id="Service.UserGroup.Description" 
                                    values={{
                                        cardNumber: this.props.cardNumber,
                                        userGroupCode: this.props.userGroupCode
                                    }}
                                /> 
                            </Typography>
                        </div>
                    </div>
                    {this.props.updateUserGroupError != null && this.errors(this.props.updateUserGroupError, "Service.UserGroup.BonusCardUpdateError")} 

                    <GridCell span={12}>
                            <Button
                                unelevated
                                className="mdc-button--primary left"
                                onClick={this.submit.bind(this)}
                            >
                                {this.props.userGroupSaving ?
                                    <Loader type={Loader.TYPE_BUTTON} />
                                    :
                                    <FormattedMessage id="Service.UserGroup.Bind" />
                                } 
                            </Button>
                        </GridCell>
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        userGroupSaving: state.updateUserGroupCode.fetching,
        updateUserGroupError: state.updateUserGroupCode.error,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({ 
            updateUserGroupCode
        }, 
        dispatch
    );
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(BonusCardUserGroupChange));
