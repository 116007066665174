import React from "react";
import classnames from 'classnames';
import PropTypes from 'prop-types';

class AlertMessage extends React.Component {
    static TYPE_NOTICE = "notice";
    static TYPE_ALERT = "alert";
    static TYPE_ALERT_NEUTRAL = "alert-neutral";
    static TYPE_DONE = "done";
    static TYPE_WARN = "warn";

    static CLASSES = {
        [AlertMessage.TYPE_NOTICE]: {
            className: "",
            icon: "icon-info"
        },
        [AlertMessage.TYPE_ALERT]: {
            className: "message--alert",
            icon: "icon-alert"
        },
        [AlertMessage.TYPE_ALERT_NEUTRAL]: {
            className: "message--alert-neutral",
            icon: "icon-alert"
        },
        [AlertMessage.TYPE_DONE]: {
            className: "message--done",
            icon: "icon-done"
        },
        [AlertMessage.TYPE_WARN]: {
            className: "message--warn",
            icon: "icon-alert"
        }
    };

    constructor(props) {
        super(props);
        let head = props.title;
        let description = props.description;
        let isSmall = props.isSmall;
        let className = props.className;
        let type = props.type;
        let scrollToMessage = props.scrollToMessage;
        this.ref = props.inneRref || React.createRef();

        if (!AlertMessage.CLASSES[type]) {
            type = AlertMessage.TYPE_NOTICE;
        }

        this.state = {
            head,
            description,
            isSmall,
            className,
            type,
            scrollToMessage
        };

        this.isInViewport = this.isInViewport.bind(this);
    }

    /**
     * Check if an element is in viewport
     *
     * @param {number} [offset]
     *
     * @returns {boolean}
     */
    isInViewport(offset = 0) {
        if (!this.ref) return false;
        const top = this.ref.current.getBoundingClientRect().top;

        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    scrollToRef(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    componentDidMount() {
        if (this.state.scrollToMessage) {
            if (!this.isInViewport(-100)) {
                this.scrollToRef(this.ref)
            }
        }
    }

    render() {
        let classNames = classnames("message", AlertMessage.CLASSES[this.state.type].className, this.state.className, {
            "small": this.state.isSmall,
            "align": !this.state.description,
            "column-direction": this.state.description
        });

        return (
            <div ref={this.ref} className={classNames}>
                <span className={classnames("icon", AlertMessage.CLASSES[this.state.type].icon)} />
                {this.state.head && <h4 className="message__head">
                    <b>{this.state.head}</b>
                </h4>}

                {this.state.description && <div className="message__description">
                    {this.state.description}
                </div>}

                {this.props.children && <div className="message__description">
                    {this.props.children}
                </div>}
            </div>
        );
    }
}

AlertMessage.propTypes = {
    type: PropTypes.string,
    innerRef: PropTypes.object,
    isSmall: PropTypes.bool,
    scrollToMessage: PropTypes.bool,
    title: PropTypes.any,
    description: PropTypes.any,
    className: PropTypes.any
};


export default AlertMessage;