import AlertMessage from "../components/common/AlertMessage";

const CompletedFormStatus = {
    ACCEPTED: "ACCEPTED",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    SENT_TO_REVIEW: "SENT_TO_REVIEW",
    REJECTED: "REJECTED"
};

const CompletedMessages = {
    ACCEPTED: {
        type: AlertMessage.TYPE_DONE,
        title: "ContractsPage.CreditInfo.ValidationSuccessful"
    },
    TECHNICAL_ERROR: {
        type: AlertMessage.TYPE_NOTICE,
        title: "Error.Technical"
    },
    SENT_TO_REVIEW: {
        type: AlertMessage.TYPE_NOTICE,
        title: "ContractsPage.Notice.ApplicationPending"
    },
    REJECTED: {
        type: AlertMessage.TYPE_NOTICE,
        title: "ContractsPage.Notice.ApplicationRejected"
    }
};

const PaymentCardStatus = {
    NONE: "NONE",
    OK: "OK",
    PENDING_DECISION: "PENDING_DECISION",
    PENDING_SIGNATURE: "PENDING_SIGNATURE",
    REJECTED: "REJECTED",
    IN_PRODUCTION: "IN_PRODUCTION"
};

const CardApplicationType = {
    OFFER: "Offer",
    CYLINDER_GAS_OFFER: "CylinderGasOffer",
    APPLICATION: "Application",
    EV_CHARGING_OFFER: "EvChargingOffer"
};

export {
    CardApplicationType,
    CompletedFormStatus,
    CompletedMessages,
    PaymentCardStatus
};
