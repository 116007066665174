import React from "react";
import BaseContractJoin from "../common/BaseContractJoin";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { fetchMainContact, fetchUserGroupCode } from "../../actions/accountActions";
import { orderBonusCard, getBonusCard, getBonusCardEncrypted } from "../../actions/cardActions";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import Loader from "../common/Loader";
import PlasticCardSelection from "./PlasticCardSelection";
import AddressForm from "../AddressForm/AddressForm";
import auth from "../../auth/authenticate";
import ReferralLink from "../common/ReferralLink";
import { SendGoalEvent, SendActionEvent } from '../../helpers/GoogleTagHelper';

const BackUrl = "/cards";

class ContractJoinCard extends BaseContractJoin {
    constructor(props) {
        super(props);
        this.titlePrefix = this.titlePrefix.bind(this);
        this.fetchPreData = this.fetchPreData.bind(this);
        this.isExtendBonuscard = this.isExtendBonuscard.bind(this);
        this.fillContactWithBonusCardData = this.fillContactWithBonusCardData.bind(this);
        this.setSelectedContact = this.setSelectedContact.bind(this);
    }

    componentDidMount() {
        if (this.props.isEncrypted) {
            this.props.getBonusCardEncrypted(this.props.bonusCardId)
                .then(this.fetchPreData)
                .then(this.fillContactWithBonusCardData)
                .catch(() => this.props.history.push(BackUrl));
        } else {
            this.fetchPreData();
        }
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate.apply(this, arguments);

        if (this.props.bonusCardId && (prevProps.bonusCardId !== this.props.bonusCardId)) {
            this.props.getBonusCard(this.props.bonusCardId).then(this.fillContactWithBonusCardData);
        }

        if (!this.props.bonusCardId && !this.props.isEncrypted && (prevProps.bonusCardId !== this.props.bonusCardId)) {
            this.setSelectedContact();
        }
    }

    fetchPreData() {
        this.props.fetchMainContact();
        if (auth.isAgent()) {
            this.props.fetchUserGroupCode();
        }
    }

    setSelectedContact() {
        let contact = Object.assign({}, this.props.mainContact);
        this.setState({ contact: contact });
    }

    isExtendBonuscard() {
        return !!this.props.bonusCardId;
    }

    fillContactWithBonusCardData() {
        let copyContact = this.state.contact;
        Object.assign(copyContact, this.props.bonusCardData);
        this.setState({ contact: copyContact });
    }

    onSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.setState(
                { showErrors: true },
                this.scrollToRef.bind(this, this.uiErrorsRef)
            );
            return;
        }

        this.setState({ showErrors: false });
        let data = this.getFormData(e);
        data.crmAccountId = auth.getRoleId();
        data.crmContactId = auth.getMainContactId();
        data.orderVirtualCard = !data.plasticCard;
        data.isAgentOrder = auth.isAgent();

        if (auth.isAgent()) {
            data.userGroupCode = this.props.userGroupCode.userGroupCode;
        }

        Object.assign(data, data.contact);
        delete data.terms;

        let referral = ReferralLink.getReferralLinkParams();
        if (referral) {
            Object.assign(data, referral);
            ReferralLink.clearReferralLinkParams();
        }

        if (this.isExtendBonuscard()) {
            data.bonusCardNr = this.props.bonusCardData.bonusCardNr;
            data.orderVirtualCard = false;
            if (this.props.isEncrypted) {
                this.props.orderBonusCard(data).then(() => {
                    SendGoalEvent('new-card');
                    SendActionEvent("CardFinalized")
                    this.props.history.push(BackUrl);
                });
                return;
            }
        }

        this.props.orderBonusCard(data).then(() => {
            SendGoalEvent('new-card')
            SendActionEvent("CardFinalized")
        });
    }

    titlePrefix() {
        return this.isExtendBonuscard() ? "Extend" : "";
    }

    renderAddressContactBlock() {
        const address = this.state.address;
        return (
            <>
                {this.renderTitleBlock("Cards.Order.Contact.Title")}
                {this.renderCardOwnerBlock()}
                <AddressForm className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12"
                    onChange={this.updateAddress}
                    address={address}
                    rules="required"
                    label="AddressForm.Address"
                    validator={this.validator}
                    isLoading={this.props.isAccountLoading}
                />
            </>
        );
    }

    renderCardsBlock() {
        return (
            <>
                <PlasticCardSelection
                    onChangeCheckbox={this.onChangeCheckbox}
                    checked={this.state.plasticCard}
                    checkboxName={BaseContractJoin.FieldPlasticCard}>
                </PlasticCardSelection>
            </>
        );
    }

    renderForm() {
        return (
            <form noValidate onSubmit={this.onSubmit}>
                <Grid className="mdc-layout-grid--base mdc-layout-grid--form">
                    {this.renderAddressContactBlock()}
                    {this.props.bonusCardFetching ? <Loader Type={Loader.TYPE_CENTER} /> : this.renderContactBlock(false)}
                    {!this.isExtendBonuscard() && this.renderCardsBlock()}
                </Grid>

                <div className="action-buttons">
                    <Button unelevated disabled={this.props.orderBonusCardFetching}>
                        {this.props.orderBonusCardFetching ?
                            <Loader type={Loader.TYPE_BUTTON} />
                            :
                            <FormattedMessage id={"Cards.Order.Now" + this.titlePrefix()} />
                        }
                    </Button>
                </div>
            </form>
        );
    }



    render() {
        return (
            <Grid className="mdc-layout-grid--base mdc-layout-grid--base-narrow">
                <GridCell span={12}>
                    <main>
                        {this.renderErrorsBlock()}
                        {this.renderInformationBlock(
                            "Cards.Order.BonusCard" + this.titlePrefix(),
                            "Cards.Order.BonusCard.Information" + this.titlePrefix()
                        )}
                        {this.renderForm()}
                    </main>
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAccountLoading: state.account.fetching,
        isAccountLoaded: state.account.fetched,
        account: state.account.data,

        errors: state.orderBonusCard.error,
        orderBonusCard: state.orderBonusCard.data,
        orderBonusCardFetching: state.orderBonusCard.fetching,

        bonusCardData: state.bonusCard.data,
        bonusCardFetching: state.bonusCard.fetching,
        bonusCardFetched: state.bonusCard.fetched,

        mainContact: state.mainContact.data,
        mainContactFetched: state.mainContact.fetched,

        isUserGroupCodeLoaded: state.userGroupCode.fetched,
        userGroupCode: state.userGroupCode.data,

        formFields: [
            BaseContractJoin.FieldContactId,
            BaseContractJoin.FieldContactEmail,
            BaseContractJoin.FieldContactMobile,
            BaseContractJoin.FieldPlasticCard,
            BaseContractJoin.FieldPromotionCode,
            BaseContractJoin.FieldTermsAddressCity,
            BaseContractJoin.FieldTermsAddressCounty,
            BaseContractJoin.FieldTermsAddressStreet,
            BaseContractJoin.FieldTermsAddressParish,
            BaseContractJoin.FieldTermsAddressPostalCode,
            BaseContractJoin.FieldTermsAddressHouseNr,
            BaseContractJoin.FieldTermsAddressApartmentNr
        ]
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchMainContact,
            orderBonusCard,
            getBonusCard,
            getBonusCardEncrypted,
            fetchUserGroupCode
        },
        dispatch
    );
}

export default injectIntl(
    withRouter(connect(mapStateToProps, matchDispatchToProps)(ContractJoinCard))
);
