import { Grid, GridCell } from "@rmwc/grid";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

const TermsAndConditions = () => {
    return (
        <Grid className="summer-campaign">
            <GridCell span={12}>
                <h2>
                    <FormattedMessage id="SummerCampaign.Terms.Title" />
                </h2>
            </GridCell>
            <GridCell span={12} className="summer-campaign__terms">
                <FormattedHTMLMessage id="SummerCampaign.Terms.Body" tagName="div" />
            </GridCell>
        </Grid>
    );
}

export default TermsAndConditions;