import { GridCell, GridInner } from "@rmwc/grid";
import { Typography } from "@rmwc/typography";
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "react-intl";
import { formatPhoneNumber } from "../../helpers/phoneHelper";
import Icon from "../common/Icon";
import LinkElement from "../common/LinkElement";

const BusinessOverviewContacts = ({ intl }) => {
    const email = intl.formatMessage({ id: "BusinessOverview.Contact.Email" });

    return <GridInner>
        <GridCell span={12}>
            <div className="contact-info-item">
                <Icon name="phone" className="contact-info-item__icon" />
                <div className="contact-info-item__content">
                    <FormattedHTMLMessage id="BusinessOverview.Contact.Phone.Subtitle" />
                    <Typography use="body1" className="mdc-typography mdc-typography--bold">
                        {formatPhoneNumber(intl.formatMessage({ id: "BusinessOverview.Contact.Phone" }))}
                    </Typography>
                </div>
            </div>
        </GridCell>

        <GridCell span={12}>
            <div className="contact-info-item">
                {/* <Icon name="envelope" className="contact-info-item__icon" /> */}

                <Icon name="envelope" className="contact-info-item__icon" />
                <div className="contact-info-item__content">

                    <FormattedMessage id="BusinessOverview.Contact.Email.Subtitle" />
                    <p> <LinkElement className="mdc-typography mdc-typography--bold" href={`mailto:${email}`}>
                        {email}
                    </LinkElement>
                    </p>
                </div>
            </div>
        </GridCell>

        <GridCell span={12}>
            <div className="contact-info-item">
                <Icon name="location" className="contact-info-item__icon" />
                <div className="contact-info-item__content">
                    <FormattedMessage id="BusinessOverview.Contact.Office.Subtitle" />
                    <Typography use="body1" className="mdc-typography mdc-typography--bold">
                        <FormattedMessage id="BusinessOverview.Contact.Office" />
                    </Typography>
                </div>
            </div>
        </GridCell>
    </GridInner>;
}

export default injectIntl(BusinessOverviewContacts);
