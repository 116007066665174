import { Button } from "@rmwc/button";
import { Grid, GridCell } from "@rmwc/grid";
import { TextField } from "@rmwc/textfield";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

const PartnerApiForm = ({ username, secret, hasPartnerApiAccess = false, onClickButton }) => {
    const [showUserName, setShowUserName] = useState(false);
    const [showSecret, setShowSecret] = useState(false);

    const renderApiAccessCredentials = () => {
        if (!hasPartnerApiAccess) {
            return null;
        }

        return (
            <>
                <GridCell span={12} className="service-access-item">
                    <TextField
                        label={<FormattedMessage id="ContractDetails.Fuel.PartnerApi.Username" />}
                        type={showUserName ? "text" : "password"}
                        outlined
                        readOnly={true}
                        value={username}
                    />
                    <Button
                        className="show-credentials-btn"
                        outlined
                        onClick={() => setShowUserName(prev => !prev)}
                    >
                        {showUserName
                            ? <FormattedMessage id="ContractDetails.Fuel.Secret.Hide" />
                            : <FormattedMessage id="ContractDetails.Fuel.Secret.Show" />
                        }
                    </Button>
                </GridCell>

                <GridCell span={12} className="service-access-item">
                    <TextField
                        label={<FormattedMessage id="ContractDetails.Fuel.PartnerApi.Secret" />}
                        type={showSecret ? "text" : "password"}
                        outlined
                        readOnly={true}
                        value={secret}
                    />
                    <Button
                        className="show-credentials-btn"
                        outlined
                        onClick={() => setShowSecret(prev => !prev)}
                    >
                        {showSecret
                            ? <FormattedMessage id="ContractDetails.Fuel.Secret.Hide" />
                            : <FormattedMessage id="ContractDetails.Fuel.Secret.Show" />
                        }
                    </Button>
                </GridCell>
            </>
        );
    }

    const renderButtons = () => {
        return (
            <GridCell span={12}>
                <Button
                    type="submit"
                    className="mdc-button--primary"
                    unelevated
                    onClick={onClickButton}
                >
                    {hasPartnerApiAccess
                        ? <FormattedMessage id="ContractDetails.Fuel.Deactivate" />
                        : <FormattedMessage id= "ContractDetails.Fuel.Activate" />
                    }
                </Button>
            </GridCell>
        );
    }

    return (
        <Grid>
            <GridCell span={12} className="mdc-typography mdc-typography--subtitle1 mt-25">
                <FormattedMessage id="ContractDetails.Fuel.Services.PartnerApi" />
            </GridCell>

            <GridCell span={12}>
                <div className="information__description lighter">
                    <FormattedMessage id="ContractDetails.Fuel.PartnerApi.Desc" />
                </div>
                <hr className="divider divider--secondary" />
            </GridCell>

            {renderApiAccessCredentials()}

            {renderButtons()}
        </Grid>
    );
}

export default PartnerApiForm;