import { Button } from "@rmwc/button";
import { Grid, GridCell } from "@rmwc/grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchHintHistory, fetchHintHistoryAgentList } from "../../actions/hintActions";
import auth from "../../auth/authenticate";
import AlertMessage from "../../components/common/AlertMessage";
import DatePickerRange from "../../components/common/DatePickerRange";
import exportToExcel from "../../components/common/ExcelExport";
import Loader from "../../components/common/Loader";
import Pagination from "../../components/common/Pagination";
import SelectAutoCompleteField from "../../components/common/SelectAutoCompleteField";
import TableList from "../../components/common/TableList";

const HintsHistory = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const [filter, setFilter] = useState({
        page: 1,
        pageSize: auth.getPageSize(),
        startDate: moment().subtract(4, "months").startOf("month"),
        endDate: moment().endOf("day"),
        agent: {}
    });

    const {
        fetching: fetchingHintHistory,
        data: hintHistory,
        total: historyCount
    } = useSelector(state => state.hintHistory);

    const {
        fetching: fetchingHintAgents,
        data: hintAgents,
    } = useSelector(state => state.hintAgents);

    useEffect(() => {
        if (!fetchingHintAgents) {
            dispatch(fetchHintHistoryAgentList());
            search();
        }
    }, []);

    function onFilterPeriodStartDateChange(value) {
        setFilter({ ...filter, startDate: moment(value)});
    }

    function onFilterPeriodEndDateChange(value) {
        setFilter({ ...filter, endDate: moment(value).endOf("day")});
    }

    function onSelectAgent(option) {
        setFilter({...filter, agent: option})
    }

    function search() {
        if (!fetchingHintHistory) {
            dispatch(fetchHintHistory({
                pageNr: filter.page,
                limit: filter.pageSize,
                from: filter.startDate.format("YYYY-MM-DD"),
                to: filter.endDate.format("YYYY-MM-DD HH:mm:ss"),
                agentCrmId: filter.agent.value
            }));
        }
    }

    function onPageChange(page) {
        setFilter({ ...filter, pageNr: page});
        search();
    }

    function renderTableRowJson(item) {
        return {
            content: [
                {
                    colspan: 1,
                    content: `${item.agent.name} (${item.agent.idCode})`
                },
                {
                    colspan: 1,
                    content: `${item.customer.name} (${item.customer.idCode})`
                },
                {
                    colspan: 1,
                    content: moment(item.sentOn).format("DD.MM.YYYY HH:mm:ss")
                },
                {
                    colspan: 1,
                    content: item.agentElmoGroups
                },
            ]
        };
    }

    function excelExport() {
        const history = hintHistory.items.map((item) => {
            item.sentOn = moment(item.sentOn).format("DD.MM.YYYY HH:mm:ss");
            item.agentName = `${item.agent.name} (${item.agent.idCode})`;
            item.customerName = `${item.customer.name} (${item.customer.idCode})`;
            return item;
        });

        const columns = [
            {
                field: 'agentName',
                title: intl.formatMessage({ id: "HintHistory.Table.Agent" }),
            },
            {
                field: 'customerName',
                title: intl.formatMessage({ id: "HintHistory.Table.Customer" }),
            },
            {
                field: 'sentOn',
                title: intl.formatMessage({ id: "HintHistory.Table.SentOn" }),
            },
            {
                field: 'agentElmoGroups',
                title: intl.formatMessage({ id: "HintHistory.Table.AgentElmoGroups" }),
            },
        ];

        exportToExcel(columns, history, "hint-history.xlsx")
    }

    function renderFilter() {
        const hasItems = hintHistory.items && hintHistory.items.length > 0;

        return (
            <>
                <GridCell desktop={6} tablet={5} phone={4} className="mdc-layout-grid__cell--start-3-desktop">
                    <DatePickerRange
                        label="HintHistory.Filter.HistoryPeriod"
                        fieldStart={"filter.startDate"}
                        fieldEnd={"filter.endDate"}
                        startOnChange={onFilterPeriodStartDateChange}
                        endOnChange={onFilterPeriodEndDateChange}
                        startValue={filter.startDate?.toDate()}
                        endValue={filter.endDate?.toDate()}
                    />
                    <SelectAutoCompleteField
                        value={filter.agent}
                        onChange={onSelectAgent}
                        field="agents"
                        label="HintHistory.Agent"
                        options={hintAgents.map(s => ({
                            label: s.name,
                            value: s.crmId
                        }))}
                        rules="required"
                    />
                </GridCell>
                <GridCell desktop={2} tablet={3} phone={4}>
                    <Button
                        unelevated
                        onClick={search}
                        disabled={fetchingHintAgents || fetchingHintHistory}
                        className="right mt-24"
                    >
                        <i className="icon-wave" />
                        <FormattedMessage id="General.Filter" />
                    </Button>

                    {hasItems &&
                        <Button className="mdc-button--outlined right mt-24 mr-mobile-up-10 mb-mobile-10 hidden-mobile" 
                            onClick={excelExport}
                            disabled={fetchingHintHistory}
                        >
                            <i className="icon-download" />
                            <FormattedMessage id="HintHistory.Export" />
                        </Button>
                    }
                </GridCell>
            </>
        );
    }

    function renderResults() {
        const hasItems = hintHistory.items && hintHistory.items.length > 0;

        const history = hintHistory.items || [];
        const tableRowsJson = history.map(item => renderTableRowJson(item));

        return (
            <>
                {fetchingHintHistory &&
                    <Loader type={Loader.TYPE_CENTER} />
                }

                {!fetchingHintHistory && !hasItems &&
                    <AlertMessage
                        className="mb-30"
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="HintHistory.NotFound"/>}
                    />
                }

                {history.length > 0 &&
                    <>
                        <TableList
                            headers={[
                                {
                                    label: "HintHistory.Table.Agent",
                                    colspan: 1
                                },
                                {
                                    label: "HintHistory.Table.Customer",
                                    colspan: 1
                                },
                                {
                                    label: "HintHistory.Table.SentOn",
                                    colspan: 1
                                },
                                {
                                    label: "HintHistory.Table.AgentElmoGroups",
                                    colspan: 1
                                },
                            ]}
                            itemContent={tableRowsJson}
                        />
                        <Pagination
                            total={historyCount}
                            selectedPage={filter.page}
                            onChange={onPageChange}
                        />
                    </>
                }
            </>
        );
    }

    return (
        <Grid>
            <GridCell span={12}>
                {renderFilter()}
            </GridCell>

            <GridCell span={12}>
                {renderResults()}
            </GridCell>
        </Grid>
    );
}

export default HintsHistory;
