import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";
import { MapLangToCultureXc } from "../helpers/LangHelper";
import { directFileDownloadAction } from '../helpers/fileDownloadHelpers';

export const fetchContracts = (isCached = true) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_CONTRACTS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/contracts?isCached=${isCached}`)
    };
};

export const fetchSignedContracts = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: "FETCH_SIGNED_CONTRACTS",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/contracts`, {
            params: {
                isCached: true,
                includePrecontracts: false
            }
        })
    };
};

export const fetchContract = (contractId) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_CONTRACT',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/${contractId}`)
    };
};

export const updateContract = (form) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'UPDATE_CONTRACT',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/contract/update`, form)
    };
};

export const putContract = (form) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'PUT_CONTRACT',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/contract/create-contract`, form)
    };
};

export const fetchContractStatistics = (
    contractNumbers,
    contractIds,
    contractName,
    dateFrom,
    dateTo,
    lang
) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: "FETCH_CONTRACT_STATISTICS",
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/statistics`, {
            params: {
                ContractIds: contractIds,
                ContractNumbers: contractNumbers,
                ContractName: contractName,
                DateFrom: dateFrom,
                DateTo: dateTo,
                lang: MapLangToCultureXc(lang)
            },
        }),
    };
}

export const fetchBanks = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'FETCH_BANKS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/banks`)
    };
};

export const postFinalizeContract = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_CONTRACT',
        payload: BusinessAPI.put(`/api/${accountId}/${roleId}/contract/finalize`, data)
    };
};

export const activateGreenEnergy = (contractId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_ACTIVATE_GREEN_ENERGY',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/${contractId}/activate-green-energy`)
    };
};

export const deactivateGreenEnergy = (contractId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_DEACTIVATE_GREEN_ENERGY',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/${contractId}/deactivate-green-energy`)
    };
};

export const downloadContractDigiDoc = (fileName) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/contract/digidoc/${fileName}`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            directFileDownloadAction(endpoint, `${fileName}.asice`);
        } else {
            dispatch({
                type: 'DOWNLOAD_CONTRACT_DIGIDOC',
                payload: BusinessAPI.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
};

export const downloadContractPdf = (fileName) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/contract/pdf/${fileName}`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            directFileDownloadAction(endpoint, `${fileName}.pdf`);
        } else {
            dispatch({
                type: 'DOWNLOAD_CONTRACT_PDF',
                payload: BusinessAPI.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
};

export const downloadElmoContractPdf = (contractId, contractNumber) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/contract/electricity/${contractId}/pdf`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = !!contractNumber
                ? `${contractNumber}.pdf`
                : "contract.pdf";
            directFileDownloadAction(endpoint, fileName);
        } else {
            dispatch({
                type: 'DOWNLOAD_CONTRACT_PDF',
                payload: BusinessAPI.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
}

export const downloadElmoFile = (contractNumber, fileType = "pdf") => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/contract/elmo/file/${contractNumber}/${fileType}`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = !!contractNumber
                ? `${contractNumber}.${fileType}`
                : `contract.${fileType}`;
            directFileDownloadAction(endpoint, fileName);
        } else {
            dispatch({
                type: fileType === "pdf" ? "DOWNLOAD_CONTRACT_PDF" : "DOWNLOAD_CONTRACT_DIGIDOC",
                payload: BusinessAPI.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
}

export const downloadElmoFileByFileId = (contractId, fileId, fileName = "") => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/contract/${contractId}/elmo/file/${fileId}`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            directFileDownloadAction(endpoint, fileName);
        } else {
            dispatch({
                type: 'DOWNLOAD_CONTRACT_PDF',
                payload: BusinessAPI.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
}


export const fetchContractLimitApplications = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: 'FETCH_CONTRACT_LIMIT_APPLICATIONS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/contract-limit-applications`)
    };
};

export const acceptNewContractLimit = (limitApplicationId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_ACCEPT_NEW_CONTRACT_LIMIT',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/contract-limit-application/accept`, { limitApplicationId })
    };
};

export const rejectNewContractLimit = (limitApplicationId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_REJECT_NEW_CONTRACT_LIMIT',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/contract-limit-application/reject`, { limitApplicationId })
    };
};

export const confirmPackageChange = (productId, contractId, changeStart) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    const body = {
        crmId: accountId,
        productId,
        contractId,
        changeStart
    }

    return {
        type: 'POST_CONFIRM_PACKAGE_CHANGE',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/confirm-package-change`, body)
    };
};

export const extendContract = (productId, contractId, changeStart) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    const body = {
        crmId: accountId,
        productId,
        contractId,
        changeStart
    }

    return {
        type: 'POST_EXTEND_CONTRACT',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/extend`, body)
    };
};

export const prepareElmoContractOfferSinging = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_PREPARE_ELECTRICITY_CONTRACT_OFFER_SIGNING',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/elmo/offer/prepare`, data)
    };
};

export const finalizeElmoContractOfferSinging = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'POST_FINALIZE_ELECTRICITY_CONTRACT_OFFER_SIGNING',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/elmo/offer/finalize`, data)
    };
};

export const fetchElmoContractDetails = (contractId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'GET_ELMO_CONTRACT_DETAILS',
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/contract/elmo/details/${contractId}`)
    }
}

export const hasGasoPermission = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'HAS_GASO_PERMISSION',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/elmo/gaso/hasPermission`)
    }
}

export const registerGasoPermission = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'REGISTER_GASO_PERMISSION',
        payload: BusinessAPI.post(`/api/${accountId}/${roleId}/contract/elmo/gaso/registerPermission`)
    }
}

export const clearRegisterGasoPermission = () => {
    return {
        type: 'CLEAR_REGISTER_GASO_PERMISSION'
    }
}