import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { GridCell, GridInner } from "@rmwc/grid";
import {
    DigitalStampsDialog,
    DigitalStampsClientDialog,
    AmountSelector,
    CommunityAmountSelector
} from "./";
import Tabs from "../../components/common/Tabs";
import { DigitalStampConversionType, DigitalStampParams } from "../../const/digitalStamps";
import { Fuel, ElectricityNew, GasNew } from "../../const/contractType";
import { Active } from "../../const/ContractStatus";
import { convertStampsToMoney, convertStampsToTrees } from "../../helpers/digitalStampsHelper";
import DigitalStampsConvertOption from "./DigitalStampsConvertOption";

const DigitalStampsConverter = ({ personalCode, isClient = false }) => {
    const stampsSummary = useSelector(state => state.digitalStampsSummary);
    const contractSummary = useSelector(state => state.contracts);
    const recentElectricityInvoices = useSelector(state => state.recentElectricityInvoices);
    const uiState = useSelector(state => state.ui);

    const [selectedPaymentCardAmount, setSelectedPaymentCardAmount] = useState("");
    const [selectedElectricityAmount, setSelectedElectricityAmount] = useState("");
    const [selectedTreesAmount, setSelectedTreesAmount] = useState("");

    const [selectedCounty, setSelectedCounty] = useState();
    const [isElectricityDisabled, setIsElectricityDisabled] = useState(false);
    const [isCardDisabled, setIsCardDisabled] = useState(false);
    const [isCommunityDisabled, setIsCommunityDisabled] = useState(false);
    const [data, setData] = useState({});
    const [validContracts, setValidContracts] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const intl = useIntl();

    const filterActiveContractsByType = useCallback((contracts, type) => {
        const activeContracts = contracts?.filter(c => c.status === Active && c.isVirtualAccount === false) || [];

        return filterContractsByType(activeContracts, type);
    }, []);

    const filterContractsByType = (contracts, type) => {
        switch (type) {
            case DigitalStampConversionType.PaymentCard:
                return contracts.filter(contract => contract.type === Fuel);
            case DigitalStampConversionType.Electricity:
                return contracts.filter(contract => contract.type === ElectricityNew || contract.type === GasNew);
            default:
                return [];
        }
    }

    const filterRecentInvoicesByContractType = useCallback((invoices, type) => {
        const invoicesOfType = invoices?.filter(x => x.contractTypes.indexOf(type) >= 0);
        return invoicesOfType || [];
    }, []);

    useEffect(() => {
        if (contractSummary.data) {
            const hasElectricityContracts = filterActiveContractsByType(contractSummary.data, DigitalStampConversionType.Electricity).length > 0;
            const hasGasContracts = filterActiveContractsByType(contractSummary.data, DigitalStampConversionType.Gas).length > 0;
            const hasElectricityInvoices = filterRecentInvoicesByContractType(recentElectricityInvoices.data, ElectricityNew).length > 0;
            const hasGasInvoices = filterRecentInvoicesByContractType(recentElectricityInvoices.data, GasNew).length > 0;

            setIsElectricityDisabled((hasElectricityContracts === false && hasGasContracts === false) ||
                (hasElectricityInvoices === false && hasGasInvoices === false) ||
                stampsSummary.data?.availableStamps < DigitalStampParams.MinAmountAllowedForConverting);
        }
    }, [contractSummary.data, stampsSummary.data, recentElectricityInvoices.data, filterActiveContractsByType, filterRecentInvoicesByContractType]);

    useEffect(() => {
        setIsCardDisabled(stampsSummary.data?.availableStamps < DigitalStampParams.MinAmountAllowedForConverting);
        setIsCommunityDisabled(stampsSummary.data?.availableStamps < DigitalStampParams.MinAmountAllowedForCommunity);
    }, [stampsSummary.data]);

    const isAmountValid = (type) => {
        switch (type) {
            case DigitalStampConversionType.PaymentCard:
                return selectedPaymentCardAmount >= DigitalStampParams.MinAmountAllowedForConverting &&
                    selectedPaymentCardAmount <= stampsSummary.data?.availableStamps;
            case DigitalStampConversionType.Electricity:
                return selectedElectricityAmount >= DigitalStampParams.MinAmountAllowedForConverting &&
                    selectedElectricityAmount <= stampsSummary.data?.availableStamps;
            case DigitalStampConversionType.Community:
                return selectedTreesAmount >= DigitalStampParams.MinAmountAllowedForCommunity;
            default:
                return false;
        }
    }

    const getSelectedAmount = (type) => {
        switch (type) {
            case DigitalStampConversionType.PaymentCard:
                return selectedPaymentCardAmount;
            case DigitalStampConversionType.Electricity:
                return selectedElectricityAmount;
            case DigitalStampConversionType.Community:
                return selectedTreesAmount;
            default:
                return 0;
        }
    }

    const clearAmounts = () => {
        setSelectedPaymentCardAmount("");
        setSelectedElectricityAmount("");
        setSelectedTreesAmount("");
    }

    const onConvert = (type) => {
        if (!isAmountValid(type)) {
            return;
        }

        const amount = getSelectedAmount(type);

        const conversionData = {
            crmAccountId: stampsSummary.data?.crmAccountId,
            personalCode: personalCode,
            stampsAmount: amount,
            conversionType: type,
            communityCounty: selectedCounty,
            contractId: undefined,
            convertedAmount: getConvertedSum(amount, type !== DigitalStampConversionType.Community)
        };

        setData(conversionData);
        setValidContracts(filterActiveContractsByType(contractSummary.data, type));

        setIsDialogOpen(true);
        clearAmounts();
    }

    const getConvertedSum = (amount, isCurrency = true) => {
        return isCurrency
            ? convertStampsToMoney(amount, stampsSummary.data?.availableStamps ?? 0)
            : convertStampsToTrees(amount);
    }

    const tabItems = [
        {
            label: intl.formatMessage({ id: 'DigitalStamps.Tab.PaymentCard' }),
            type: DigitalStampConversionType.PaymentCard,
            isContentSelectionDisabled: isCardDisabled,
            contents:
                <DigitalStampsConvertOption
                    icon="card"
                    title="DigitalStamps.PaymentCard.Option.Title"
                    description="DigitalStamps.PaymentCard.Option.Description"
                    tooltip="DigitalStamps.PaymentCard.Option.Tooltip"
                    sumDescription="DigitalStamps.PaymentCard.Option.SumDescription"
                    convertedSum={getConvertedSum(selectedPaymentCardAmount)}
                    isDisabled={isCardDisabled}
                    onConvert={() => onConvert(DigitalStampConversionType.PaymentCard)}
                >
                    <AmountSelector
                        selectedAmount={selectedPaymentCardAmount}
                        availableStamps={stampsSummary.data?.availableStamps}
                        setStampsCount={setSelectedPaymentCardAmount}
                        isDisabled={isCardDisabled}
                    />
                </DigitalStampsConvertOption>
        },
        {
            label: intl.formatMessage({ id: 'DigitalStamps.Tab.Energy' }),
            type: DigitalStampConversionType.Electricity,
            isContentSelectionDisabled: isElectricityDisabled,
            contents:
                <DigitalStampsConvertOption
                    icon="electricity"
                    title="DigitalStamps.Electricity.Option.Title"
                    description="DigitalStamps.Electricity.Option.Description"
                    tooltip="DigitalStamps.Electricity.Option.Tooltip"
                    sumDescription="DigitalStamps.Electricity.Option.SumDescription"
                    convertedSum={getConvertedSum(selectedElectricityAmount)}
                    isDisabled={isElectricityDisabled}
                    onConvert={() => onConvert(DigitalStampConversionType.Electricity)}
                >
                    <AmountSelector
                        selectedAmount={selectedElectricityAmount}
                        availableStamps={stampsSummary.data?.availableStamps}
                        setStampsCount={setSelectedElectricityAmount}
                        isDisabled={isElectricityDisabled}
                    />
                </DigitalStampsConvertOption>
        },
        {
            label: intl.formatMessage({ id: 'DigitalStamps.Tab.Community' }),
            type: DigitalStampConversionType.Community,
            isContentSelectionDisabled: isCommunityDisabled,
            contents:
                <DigitalStampsConvertOption
                    icon="trees"
                    title="DigitalStamps.Community.Option.Title"
                    description="DigitalStamps.Community.Option.Description"
                    tooltip="DigitalStamps.Community.Option.Tooltip"
                    sumDescription="DigitalStamps.Community.Option.SumDescription"
                    convertedSum={getConvertedSum(selectedTreesAmount, false)}
                    isCurrency={false}
                    isDisabled={isCommunityDisabled}
                    onConvert={() => onConvert(DigitalStampConversionType.Community)}
                >
                    <CommunityAmountSelector
                        setStampsCount={setSelectedTreesAmount}
                        setCounty={setSelectedCounty}
                        availableStamps={stampsSummary.data?.availableStamps}
                        isDisabled={isCommunityDisabled}
                        showCountyDropdown={isClient && !stampsSummary.data?.isCommunityMember}
                    />
                </DigitalStampsConvertOption>
        }
    ];

    return (
        <>
            {uiState.isMobileOrTabletView
                ?
                <GridCell span={4}>
                    <Tabs
                        items={tabItems.map(item => ({
                            label: item.label,
                            contents: item.contents
                        }))}
                    />
                </GridCell>
                :
                <GridCell span={12} className="digital-stamps-converter">
                    <GridInner>
                        {tabItems.map((item, i) => {
                            return (
                                <GridCell span={4} key={i}>
                                    {item.contents}
                                </GridCell>
                            );
                        })
                        }
                    </GridInner>
                </GridCell>
            }

            {isClient &&
                <DigitalStampsClientDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    contracts={validContracts}
                    data={data}
                />
            }
            {!isClient &&
                <DigitalStampsDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    contracts={validContracts}
                    data={data}
                />
            }
        </>
    )
}

export default DigitalStampsConverter;