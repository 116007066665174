import {Grid, GridCell} from "@rmwc/grid";
import {Typography} from "@rmwc/typography";
import {FormattedMessage} from "react-intl";
import ContributionSection from "./ContributionSection";
import React, {useEffect} from "react";
import {getDaysSince} from "../../helpers/dateHelper";
import {useDispatch, useSelector} from "react-redux";
import LinkToCommunityPage from "./LinkToCommunityPage";
import {BasketballCommunityPath} from "../../const/routes";
import {fetchCustomerBasketballProjectContribution} from "../../actions/basketballCommunityProjectActions";

export const BasketballUserContribution = ({showLink = false, titleId = "Community.Me"}) => {
    const customerContribution = useSelector(state => state.basketballProjectCustomerContribution);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!customerContribution.fetching && !customerContribution.fetched) {
            dispatch(fetchCustomerBasketballProjectContribution);
        }
    }, [dispatch, customerContribution]);

    const daysSince = customerContribution.data?.isActive ? getDaysSince(customerContribution.participationStart) : 0;

    const durationProps = {
        iconClassName: "icon-duration",
        number: daysSince.toString(),
        numberUnit: "Community.Unit.Day",
        title: "Community.Title.Member",
        info: "Community.Info.Member"
    };

    const customerContributionProps = {
        iconClassName: "icon-basketball-euros",
        number: customerContribution.data?.contributionSum || '0',
        numberUnit: "€",
        title: "Community.Basketball.Title.MyContributionAmount",
        info: "Community.Basketball.Info.MyContributionAmount",
    };

    const contributedClubProps = {
        iconClassName: "icon-location",
        name: customerContribution.data?.contributedClub || "-",
        title: "Community.Basketball.Title.MyContributedClub",
        info: "Community.Basketball.Info.MyContributedClub",
    };

    const renderLink = () => {
        return (
            <div className="contribution-link-mobile">
                <LinkToCommunityPage overrideLinkHref={BasketballCommunityPath} isUserJoined={true}
                                     linkTextId="Community.BasketballCommunity"
                                     joinLinkTextId="Community.Basketball.Join"/>
            </div>
        )
    }

    return (
        <Grid className="mdc-layout-grid--base mb-60">
            <GridCell span={12}>
                {showLink && renderLink()}
                <Typography
                    use="headline3"
                    tag="h3"
                    className="mdc-typography mdc-theme--primary mb-mobile-15"
                >
                    <FormattedMessage id={titleId}/>
                </Typography>
            </GridCell>

            <ContributionSection
                firstProps={durationProps}
                secondProps={customerContributionProps}
                thirdProps={contributedClubProps}
            />
        </Grid>
    )
}