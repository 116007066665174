import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import Loader from "../common/Loader";
import { Icon } from "@rmwc/icon";

class DashboardTile extends React.Component {
    static BACKGROUND_COMMUNITY = "dashboard-tile--community";
    static BACKGROUND_LIGHT_BULB = "dashboard-tile--light_bulb";
    static BACKGROUND_TRAILER = "dashboard-tile--trailer";
    static BACKGROUND_CHARGER = "dashboard-tile--charger";
    static BACKGROUND_PRICE_ALERT = "dashboard-tile--price-alert"
    static BACKGROUND_REFUEL = "dashboard-tile--refuel";
    static BACKGROUND_STAMP = "dashboard-tile--stamp";
    static BACKGROUND_DIGITAL_STAMPS = "dashboard-tile--digital-stamps";
    static BACKGROUND_SHOP = "dashboard-tile--shop";
    static BACKGROUND_CYLINDERGAS = "dashboard-tile--cylinder-gas";
    static BACKGROUND_STATIONS = "dashboard-tile--stations";
    static BACKGROUND_FAMILY_GROUP = "dashboard-tile--family-group";

    render() {
        const { title, icon, value, arrow, arrowText, minFractionDigits, background, onClick, isLoading, isNew } = this.props;

        const hasValue = !!value || value === 0;
        return (
            <div className="dashboard-tile-wrapper" >

                {isLoading &&
                    <Loader type={Loader.TYPE_COVER} />}

                <div onClick={onClick}
                    className={classnames(
                        "dashboard-tile",
                        background,
                        { "dashboard-tile--new": isNew }
                    )}>
                    <div className="dashboard-tile__title">{title}</div>

                    <div className="dashboard-tile__content">
                        {!!icon &&
                            <Icon
                                icon={{
                                    icon: icon,
                                    strategy: "className",
                                    basename: "icon",
                                    prefix: "icon-",
                                    className: "dashboard-tile__icon"
                                }}
                            />}

                        {hasValue &&
                            <div className="dashboard-tile__value">{
                                this.props.intl.formatNumber(value, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: minFractionDigits
                                })
                            }</div>}

                        {!hasValue &&
                            arrow &&
                            <div className="dashboard-tile__arrow">
                                {!!arrowText &&
                                    <div className="text">{arrowText}</div>}

                                <Icon
                                    icon={{
                                        icon: "arrow-fat",
                                        strategy: "className",
                                        basename: "icon",
                                        prefix: "icon-",
                                        className: (!arrowText ? "icon--no-text" : "")
                                    }}
                                />
                            </div>}
                    </div>
                </div>
            </div>
        );
    }
}

DashboardTile.defaultProps = {
    minFractionDigits: 0,
    isNew: false
}

DashboardTile.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.string,
    value: PropTypes.number,
    arrow: PropTypes.bool,
    arrowText: PropTypes.string,
    onClick: PropTypes.func,
    background: PropTypes.string,
    isLoading: PropTypes.bool,
    minFractionDigits: PropTypes.number,
    isNew: PropTypes.bool,
}

export default injectIntl(DashboardTile);
