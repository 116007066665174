import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../actions/authActions";

const appContainerPostMessageEvent = "appContainerPostMessage";

const useAppContainerListener = () => {
    const dispatch = useDispatch();

    const handleAppContainerMessage = useCallback((type, data) => {
        switch (type) {
            case "logout":
                dispatch(logout());
                break;
            default:
                console.warn(`Received message of unknown type "${type}"`);
                break;
        }
    }, [dispatch]);

    // Register listener
    const removelistener = useEffect(() => {
        const onAppContainerPostMessage = (e) => {
            console.debug("Received " + appContainerPostMessageEvent + " event", e);
            const { type, data } = e.detail;
            console.debug("type", type, "data", data);

            handleAppContainerMessage(type, data);
        }

        window.addEventListener(appContainerPostMessageEvent, onAppContainerPostMessage);

        return () => {
            window.removeEventListener(appContainerPostMessageEvent, onAppContainerPostMessage);
        };
    }, [handleAppContainerMessage]);

    return removelistener;
}

const withAppContainerMessageListener = (Component) => {
    return (props) => {
        useAppContainerListener();

        return <Component {...props} />;
    }
};

export { useAppContainerListener };
export default withAppContainerMessageListener;