import { Button } from "@rmwc/button";
import { GridCell, GridInner } from "@rmwc/grid";
import moment from "moment";
import React, { useEffect, useCallback } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { fetchCardTransactions, fetchReceiptPdf } from "../../actions/transactionActions";
import { ContractFuelPath, TransactionsPath } from "../../const/routes";
import { formatCardNumber } from "../../helpers/cardHelpers";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import AlertMessage from "../common/AlertMessage";
import Currency from "../common/Currency";
import { defaultIconProps } from "../common/Icon";
import TableList from "../common/TableList";
import TitleWithLoader from "../common/TitleWithLoader";

const BusinessOverviewTransactions = () => {

    const dispatch = useDispatch();

    const {
        data: transactions,
        fetched: transactionsFetched,
        fetching: transactionsFetching,
        error: transactionsError
    } = useSelector(state => state.cardTransactions);



    const fetchData = useCallback(() => {
        dispatch(fetchCardTransactions(1, 5));
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const transactionsTableHeaders = [
        { label: "Transactions.Table.DateTime" },
        { label: "Transactions.Table.CardNumber" },
        { label: "Transactions.Table.CardName" },
        { label: "Transactions.Table.ServicePoint" },
        { label: "Transactions.Table.Sum" },
        { label: "Transactions.Table.Receipt", colspan: 2 },
    ];

    const getTransactionTableRow = (transaction) => {
        const transactionDate = transaction.transactionDate && moment(transaction.transactionDate);
        const dateFormat = "DD.MM.YYYY HH:mm";

        const result = {
            content: [
                { content: transactionDate && transactionDate.format(dateFormat) },
                { content: formatCardNumber(transaction.cardNumber) },
                { content: transaction.cardName },
                { content: transaction.station },
                {
                    content: <Currency value={transaction.sum} />,
                    colspan: transaction.receiptId ? 1 : 2
                }
            ],
            openedClassName: "no-padding",
            expanded: [
                {
                    colspan: 7,
                    content:
                        <div className="table-list table-list--nested">
                            <div className="table-list__header">
                                <div className="table-list__cell">
                                    <FormattedMessage id="Transactions.Table.ProductName" />
                                </div>
                                <div className="table-list__cell">
                                    <FormattedMessage id="Transactions.Table.Quantity" />
                                </div>
                                <div className="table-list__cell">
                                    <FormattedMessage id="Transactions.Table.Unit" />
                                </div>
                                <div className="table-list__cell">
                                    <FormattedMessage id="Transactions.Table.UnitPrice" />
                                </div>
                            </div>

                            {transaction.products.map((product, i) =>
                                <div className="table-list__row border-bottom" key={i}>
                                    <div className="table-list__cell">{product.productName}</div>
                                    <div className="table-list__cell">{product.quantity}</div>
                                    <div className="table-list__cell">{product.productUnit}</div>
                                    <div className="table-list__cell">
                                        <Currency value={product.unitPrice} />
                                    </div>
                                </div>
                            )}
                        </div>
                }
            ]
        };

        if (transaction.receiptId) {
            result.content.push({
                content: (
                    <Button
                        className="mdc-theme--secondary mdc-button--link"
                        onClick={() => dispatch(fetchReceiptPdf(transaction.receiptId))}
                    >
                        <FormattedMessage id="Transactions.Table.Download" />
                    </Button>
                )
            });
        }

        return result;
    };

    const paymentCardsLink =
        <Button tag="a"
            href={ContractFuelPath}
            className="mdc-button--link"
            trailingIcon={{
                icon: "arrow-right",
                ...defaultIconProps
            }}>
            <FormattedMessage id="BusinessOverview.Contracts.NoItems.Link" />
        </Button>;

    return (
        <GridInner>
            <GridCell span={12}>
                <TitleWithLoader
                    title={<FormattedMessage id="BusinessOverview.PaymentCardTransactions.Title" />}
                    isLoading={transactionsFetching}
                    href={TransactionsPath}
                    linkText={<FormattedMessage id="BusinessOverview.PaymentCardTransactions.All" />} />
            </GridCell>

            {!transactionsFetching && !!transactionsError &&
                <GridCell span={9}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        isSmall={true}
                        title={<FormattedMessage id="BusinessOverview.Transactions.Error" />} />
                </GridCell>}

            {transactionsFetched &&
                (isNullOrEmpty(transactions)
                    ? <GridCell span={9}>
                        <AlertMessage
                            type={AlertMessage.TYPE_NOTICE}
                            isSmall={true}
                            title={<FormattedMessage id="BusinessOverview.Transactions.NoItems" />}
                            description={paymentCardsLink} />
                    </GridCell>
                    : <GridCell span={12}>
                        <TableList
                            className="mb-0"
                            headers={transactionsTableHeaders}
                            itemContent={transactions.map(getTransactionTableRow)}
                            expandable
                        />
                    </GridCell>)}
        </GridInner>

    );
}

export default injectIntl(BusinessOverviewTransactions);
