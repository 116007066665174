import { useEffect, useMemo, useState } from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../common/AlertMessage";
import { SummerCampaignPrize } from "../../const/summerCampaign";
import { PrizeImageMap, getPrizeTypeByXc, isMoneyPrize } from "../../helpers/summerCampaignHelper";
import { getCustomerCouponBalance } from "../../actions/customerCouponActions";

import PrizeItem from "./PrizeItem";
import PrizeOverview from "./PrizeOverview";

import "../../styles/components/_summer-campaign.scss";

const Prizes = () => {
    const customerCouponBalance = useSelector(state => state.customerCouponBalance);
    const spinWheel = useSelector(state => state.spinWheel);

    const [isPrizeOverviewOpen, setIsPrizeOverviewOpen] = useState(false);
    const [selectedPrizeItem, setSelectedPrizeItem] = useState();

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCustomerCouponBalance());
    }, [dispatch, spinWheel]);

    const onClickPrizeItem = (item) => {
        setSelectedPrizeItem(item);
        setIsPrizeOverviewOpen(true);
    }

    const prizeList = useMemo(() => ([
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Tub" }),
            subTitle: intl.formatMessage({ id: "SummerCampaign.Prizes.Tub.SubTitle" }),
            img: PrizeImageMap[SummerCampaignPrize.Tub],
            type: SummerCampaignPrize.Tub
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.FreeCoffeeYear" }),
            img: PrizeImageMap[SummerCampaignPrize.FreeCoffeeYear],
            type: SummerCampaignPrize.FreeCoffeeYear
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Beans" }),
            img: PrizeImageMap[SummerCampaignPrize.Beans],
            type: SummerCampaignPrize.Beans
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Cup" }),
            img: PrizeImageMap[SummerCampaignPrize.Cup],
            type: SummerCampaignPrize.Cup
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.FreeCoffee" }),
            img: PrizeImageMap[SummerCampaignPrize.FreeCoffee],
            type: SummerCampaignPrize.FreeCoffee
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Money" }, {
                amount: 100
            }),
            img: PrizeImageMap[SummerCampaignPrize.Money100],
            type: SummerCampaignPrize.Money100
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Money" }, {
                amount: 20
            }),
            img: PrizeImageMap[SummerCampaignPrize.Money20],
            type: SummerCampaignPrize.Money20
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Money" }, {
                amount: 10
            }),
            img: PrizeImageMap[SummerCampaignPrize.Money10],
            type: SummerCampaignPrize.Money10
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Money" }, {
                amount: 5
            }),
            img: PrizeImageMap[SummerCampaignPrize.Money5],
            type: SummerCampaignPrize.Money5
        },
        {
            title: intl.formatMessage({ id: "SummerCampaign.Prizes.Money" }, {
                amount: 2
            }),
            img: PrizeImageMap[SummerCampaignPrize.Money2],
            type: SummerCampaignPrize.Money2
        }
    ]), [intl]);

    // Prize list does not contain money, because it will be added to
    // customer's virtual account at the moment of winning and cannot be redeemed
    const renderMyPrizes = () => {
        const coupons = customerCouponBalance?.data ?? [];

        if (coupons.length === 0) {
            return <FormattedMessage id="SummerCampaign.MyPrizes.None" />;
        }

        return (
            coupons
            .filter(coupon => !isMoneyPrize(getPrizeTypeByXc(coupon.prizeTypeXc)))
            .map((coupon, index) => {
                const type = getPrizeTypeByXc(coupon.prizeTypeXc);
                const item = {
                    title: intl.formatMessage({ id: `SummerCampaign.Prizes.${type}` }),
                    subTitle: intl.formatMessage({ id: "SummerCampaign.Prizes.ValidTo" }),
                    img: PrizeImageMap[type],
                    type: type,
                    isWin: true,
                    winAmount: coupon.amount,
                };

                return (
                    <PrizeItem
                        item={item}
                        key={index}
                        onClick={() => onClickPrizeItem(item)}
                    />
                )})
        );
    }

    if (isPrizeOverviewOpen) {
        return (
            <PrizeOverview
                item={selectedPrizeItem}
                onClose={() => setIsPrizeOverviewOpen(false)}
            />
        );
    }

    return (
        <Grid className="summer-campaign">
            {customerCouponBalance.error &&
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={intl.formatMessage({ id: "SummerCampaign.Error.LoadingData" })}
                    />
                </GridCell>
            }
            <GridCell span={12}>
                <h2 className="mb-20">
                    <FormattedMessage id="SummerCampaign.MyPrizes" />
                </h2>
                {renderMyPrizes()}
            </GridCell>
            <GridCell span={12}>
                <h2 className="mb-20">
                    <FormattedMessage id="SummerCampaign.Prizes.Title" />
                </h2>
                {prizeList.map((item, index) =>
                    <PrizeItem
                        item={item}
                        key={index}
                        onClick={() => onClickPrizeItem(item)}
                    />
                )}
            </GridCell>
        </Grid>
    );
}

export default Prizes;