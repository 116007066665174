import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import Header from "./components/Contract/Header";

const AgentLayout = (props) => {
    const isCombinedAppContainer = useSelector(state => state.appContainer.isCombinedAppContainer);

    return (
        <div id="wrapper" className={classNames({ "container--app": isCombinedAppContainer })} >
            <Header />
            <section>
                {props.children}
            </section>
        </div>
    );
}

export default AgentLayout;
