import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@rmwc/button";
import { Dialog, DialogButton, DialogContent, DialogTitle } from "@rmwc/dialog";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { List } from "@rmwc/list";
import { Typography } from "@rmwc/typography";
import RadioField from "../common/RadioField";
import { ContractFuelPath, CommunityPath } from "../../const/routes";
import { useHistory } from "react-router-dom";
import { Active } from "../../const/ContractStatus";
import { convertStamps, clearConvertStamps } from "../../actions/digitalStampsActions";
import { PaymentCardStatus } from "../../const/cardApplication";
import { DigitalStampConversionType } from "../../const/digitalStamps";
import {resetCustomerContribution} from "../../actions/communityProjectActions";
import {resetCustomerBasketballProjectContribution} from "../../actions/basketballCommunityProjectActions";

const TWO_BUTTONS = 6;
const THREE_BUTTONS = 4;

const DigitalStampsDialog = ({ isOpen, onClose, contracts, data }) => {
    const [contractId, setContractId] = useState();
    const [referenceNumber, setReferenceNumber] = useState();
    const [availableContracts, setAvailableContracts] = useState([]);
    const [hasPaymentCard, setHasPaymentCard] = useState(false);
    const [isRejectedOrReview, setIsRejectedOrReview] = useState(false);
    const [selectedContractType, setSelectedContractType] = useState(data.type);

    const paymentCardStatus = useSelector(state => state.paymentCardStatus);
    const stampsSummary = useSelector(state => state.digitalStampsSummary);
    const postStamps = useSelector(state => state.convertStamps);

    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    useEffect(() => {
        if (contracts) {
            const validContracts = contracts.filter(c => c.status === Active && c.isVirtualAccount === false);
            setAvailableContracts(validContracts);
            setContractId(validContracts?.[0]?.id);
            setReferenceNumber(validContracts?.[0]?.extraNumber);
        }
    }, [contracts]);

    useEffect(() => {
        setHasPaymentCard(stampsSummary.data?.hasActivePaymentCardContract || false);

        const status = paymentCardStatus.data?.cardStatus;
        setIsRejectedOrReview(data.conversionType === DigitalStampConversionType.PaymentCard &&
            (status === PaymentCardStatus.REJECTED || status === PaymentCardStatus.PENDING_DECISION || status === PaymentCardStatus.PENDING_SIGNATURE));
    }, [data, paymentCardStatus.data, stampsSummary.data]);

    useEffect(() => {
        if (postStamps.fetched) {
            dispatch(resetCustomerContribution());
            dispatch(resetCustomerBasketballProjectContribution());
            dispatch(clearConvertStamps());
        }
    }, [dispatch, postStamps.fetched]);

    const getFormattedMessage = (messageId) => {
        return intl.formatMessage({ id: messageId }, {
            stamps: data.stampsAmount,
            amount: data.convertedAmount
        });
    }

    const getPaymentCardDescription = () => {
        if (!paymentCardStatus.data?.cardStatus) {
            return "-";
        }

        if (hasPaymentCard) {
            return getFormattedMessage("DigitalStamps.Dialog.PaymentCardDescription");
        }

        let messageId = "-";

        switch (paymentCardStatus.data?.cardStatus) {
            case PaymentCardStatus.OK:
            case PaymentCardStatus.IN_PRODUCTION:
                messageId = "DigitalStamps.Dialog.PaymentCardDescription";
                break;
            case PaymentCardStatus.PENDING_DECISION:
            case PaymentCardStatus.PENDING_SIGNATURE:
                messageId = "DigitalStamps.Dialog.PaymentCardDescriptionPending";
                break;
            case PaymentCardStatus.REJECTED:
                messageId = "DigitalStamps.Dialog.PaymentCardDescriptionRejected";
                break;
            case PaymentCardStatus.NONE:
                messageId = "DigitalStamps.Dialog.PaymentCardDescriptionNone";
                break;
            default:
                break;
        }

        return getFormattedMessage(messageId);
    }

    const getDescription = () => {
        let messageId = "-";
        if (data.conversionType === DigitalStampConversionType.Community) {
            messageId = stampsSummary.data?.isCommunityMember
                ? "DigitalStamps.Dialog.CommunityDescription"
                : "DigitalStamps.Dialog.NoCommunityDescription";
        }
        if (data.conversionType === DigitalStampConversionType.Electricity) {
            messageId = "DigitalStamps.Dialog.ElectricityDescription";
        }
        if (data.conversionType === DigitalStampConversionType.Gas) {
            messageId = "DigitalStamps.Dialog.GasDescription";
        }

        return getFormattedMessage(messageId);
    }

    const getButtonText = () => {
        if (data.conversionType === DigitalStampConversionType.Community) {
            return stampsSummary.data?.isCommunityMember
                ? "DigitalStamps.Dialog.ButtonSave"
                : "DigitalStamps.Dialog.ButtonJoin";
        }

        return "DigitalStamps.Dialog.ButtonSave";
    }

    const getButtonSpan = () => {
        return data.conversionType === DigitalStampConversionType.PaymentCard && !isRejectedOrReview ? THREE_BUTTONS : TWO_BUTTONS;
    }

    const getContractText = (contract) => {
        if (data.conversionType === DigitalStampConversionType.PaymentCard) {
            return `${contract.number} (${contract.name})`;
        }

        if (data.conversionType === DigitalStampConversionType.Electricity ||
            data.conversionType === DigitalStampConversionType.Gas) {
                return (
                    <FormattedMessage id={"DigitalStamps.Dialog.ContractSelection"}
                        values={{
                            contractNr: contract.number,
                            contractExtraNr: contract.extraNumber
                        }}
                    />
                );
        }

        return contract.number;
    }

    const onChangeContractSelection = (contract) => {
        setContractId(contract?.id);
        setReferenceNumber(contract?.extraNumber);
        setSelectedContractType(contract?.type);
    }

    const onSaveDigitalStamps = (isBalance = false) => {
        switch (data.conversionType) {
            case DigitalStampConversionType.Community:
                convertToCommunity();
                break;
            case DigitalStampConversionType.Electricity:
            case DigitalStampConversionType.Gas:
                convertRegular();
                break;
            default:
                convertRegular(isBalance);
                break;
        }

        onClose?.();
    }

    const convertRegular = (isBalance) => {
        dispatch(convertStamps(({
            ...data,
            contractId: isBalance ? undefined : contractId,
            elmoReferenceNumber: referenceNumber,
            conversionType: isBalance
                ? DigitalStampConversionType.Balance
                : selectedContractType ?? data.conversionType
        })));
    }

    const convertToCommunity = () => {
        if (stampsSummary.data?.isCommunityMember === false) {
            history.push(CommunityPath, {
                digitalStamps: {
                    count: data.stampsAmount
                }
            });
            return;
        }

        dispatch(convertStamps(data));
    }

    const onApplyPaymentCardContract = () => {
        if (!hasPaymentCard && !isRejectedOrReview) {
            history.push(ContractFuelPath, {
                isFromDigitalStamps: true
            });
        }
    }

    const renderPaymentCardButtons = () => {
        return (
            <>
                { hasPaymentCard &&
                    <GridCell span={THREE_BUTTONS}>
                        <Button
                            unelevated
                            onClick={() => onSaveDigitalStamps(false)}
                        >
                            <FormattedMessage id="DigitalStamps.Dialog.ButtonAddToCard" />
                        </Button>
                    </GridCell>
                }
                <GridCell span={getButtonSpan()}>
                    <Button
                        unelevated
                        onClick={() => onSaveDigitalStamps(true)}
                    >
                        <FormattedMessage id="DigitalStamps.Dialog.ButtonBalance" />
                    </Button>
                </GridCell>
                {!hasPaymentCard && !isRejectedOrReview &&
                    <GridCell span={THREE_BUTTONS}>
                        <Button
                            unelevated
                            onClick={onApplyPaymentCardContract}
                        >
                            <FormattedMessage id="DigitalStamps.Dialog.ButtonCreate" />
                        </Button>
                    </GridCell>
                }
            </>
        );
    }

    const renderButtons = () => {
        return (
            <GridCell span={TWO_BUTTONS}>
                <Button
                    unelevated
                    onClick={() => onSaveDigitalStamps(true)}
                >
                    <FormattedMessage id={getButtonText()} />
                </Button>
            </GridCell>
        );
    }

    return (
        <Dialog open={isOpen} onClose={onClose} className="digital-stamps-converter--dialog">
            <DialogTitle className="mdc-dialog__title--compact">
                <DialogButton
                    trailingIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-theme--secondary"
                    }}
                    className="mdc-dialog__button--dismiss mdc-dialog__button--close uppercase"
                    action="dismiss"
                />
            </DialogTitle>
            <DialogContent>
                <Grid className="mdc-layout-grid--padded">
                    <GridCell span={12}>
                        <Typography use="headline4" className="mdc-typography mdc-theme--primary">
                            <FormattedMessage id={`DigitalStamps.Dialog.${data.conversionType}Title`} />
                        </Typography>
                        <div className="mt-30 mb-30">
                            {data.conversionType === DigitalStampConversionType.PaymentCard
                                ? getPaymentCardDescription()
                                : getDescription()
                            }
                        </div>
                    </GridCell>
                    {availableContracts.length > 1 &&
                        <GridCell span={12}>
                            <List>
                                {availableContracts.map((contract, index) => {
                                    return (
                                        <li className="form-fields-list__radio-item" key={index}>
                                            <RadioField
                                                index={index}
                                                fieldName={contract.id}
                                                fieldValue={contract.id}
                                                fieldLabel={getContractText(contract)}
                                                selectedValue={contractId}
                                                onChange={() => onChangeContractSelection(contract)}
                                            />
                                        </li>
                                    )
                                })}
                            </List>
                        </GridCell>
                    }
                    <GridCell span={12}>
                        <GridInner>
                            {data.conversionType === DigitalStampConversionType.PaymentCard
                                ? renderPaymentCardButtons()
                                : renderButtons()
                            }
                            <GridCell span={getButtonSpan()}>
                                <Button
                                    outlined
                                    onClick={onClose}
                                >
                                    <FormattedMessage id="DigitalStamps.Dialog.ButtonCancel" />
                                </Button>
                            </GridCell>
                        </GridInner>
                    </GridCell>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

DigitalStampsDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    contracts: PropTypes.array,
    data: PropTypes.object.isRequired
}

export default DigitalStampsDialog;