import {
    FuelPlusElectricityRegister
} from '../components/FuelPlusElectricity/FuelPlusElectricityRegister';
import {
    FuelPlusElectricityRegistered
} from '../components/FuelPlusElectricity/FuelPlusElectricityRegistered';
import {
    FuelPlusElectricitySign
} from '../components/FuelPlusElectricity/FuelPlusElectricitySign';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getFuelPlusElectricityInfo } from '../actions/campaignActions';
import Loader from '../components/common/Loader';
import "../styles/components/_fuel-plus-electricity-campaign.scss";
import {Grid, GridCell} from '@rmwc/grid';
import AlertMessage from '../components/common/AlertMessage';
import { useIntl } from 'react-intl';

const FuelPlusElectricityCampaign = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { data, fetching, error } = useSelector(state => state.fuelPlusElectricityInfo)

    useEffect(() => {
        dispatch(getFuelPlusElectricityInfo())
    }, [dispatch]);

    if (fetching) {
        return (<Loader type={Loader.TYPE_CENTER} />);
    }
    if (error) {
        return (
            <Grid>
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={intl.formatMessage({ id: "Error.Technical" })}
                    />
                </GridCell>
            </Grid>
        );
    }

    return (
        <>
            {data?.hasElectricityContract && !data.isFuelPlusElectricityCampaignActive  && <FuelPlusElectricityRegister />}
            {data?.hasElectricityContract && data.isFuelPlusElectricityCampaignActive && <FuelPlusElectricityRegistered />}
            {!data?.hasElectricityContract && <FuelPlusElectricitySign />}
        </>
    )
}

export default FuelPlusElectricityCampaign;