import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";

import SelectField from "./SelectField";
import classNames from "classnames";
import { Button } from "@rmwc/button";

class SelectScroll extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        };

        this.onChange = this.onChange.bind(this);
        this.onLeftButtonClick = this.onLeftButtonClick.bind(this);
        this.onRightButtonClick = this.onRightButtonClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                value: this.props.value
            });
        }
    }

    onChange(value, field, e) {
        const { onChange, options } = this.props;

        const index = e.detail.index;
        const nextValue = options[index].value;

        this.setState({ value: nextValue });

        if (!!onChange) {
            onChange(index);
        }
    }

    onLeftButtonClick() {
        const { options, onChange, onLeftClick } = this.props;
        const { value } = this.state;

        const index = options.findIndex(x => "" + x.value === "" + value);
        const previousIndex = index > 0 ? index - 1 : options.length - 1;
        const previousValue = options[previousIndex].value;

        this.setState({ value: previousValue });

        if (!!onChange) {
            onChange(previousIndex);
        }

        if (!!onLeftClick) {
            onLeftClick(previousIndex, index);
        }
    }

    onRightButtonClick() {
        const { options, onChange, onRightClick } = this.props;
        const { value } = this.state;

        const index = options.findIndex(x => "" + x.value === "" + value);
        const nextIndex = index < options.length - 1 ? index + 1 : 0;
        const nextValue = options[nextIndex].value;

        this.setState({ value: nextValue });

        if (!!onChange) {
            onChange(nextIndex);
        }

        if (!!onRightClick) {
            onRightClick(nextIndex, index);
        }
    }

    render() {
        const { field, label, options, fieldClassName } = this.props;
        const { value } = this.state;

        return (
            <div className="select-scroll d-flex d-flex--inline">
                <Button
                    trailingIcon={{
                        icon: "angle-left",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }}
                    onClick={this.onLeftButtonClick} />
                <SelectField
                    disabled={false}
                    field={field}
                    label={label}
                    onChange={this.onChange}
                    value={value}
                    options={options}
                    className={classNames(fieldClassName, { "mdc-select--no-margin": !label })}
                >
                </SelectField>
                <Button
                    icon={{
                        icon: "angle-right",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-",
                        className: "mdc-icon-button__icon",
                    }}
                    onClick={this.onRightButtonClick} />
            </div>
        );
    }
}

SelectScroll.propTypes = {
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onLeftClick: PropTypes.func,
    onRightClick: PropTypes.func,
    options: PropTypes.array,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fieldClassName: PropTypes.any
};

SelectScroll.defaultProps = {
    options: [],
    value: "",
};

export default injectIntl(SelectScroll);