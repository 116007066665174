import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { bindActionCreators } from 'redux';

import { Typography } from "@rmwc/typography";
import { Grid, GridCell, GridInner } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { List } from "@rmwc/list";
import BaseFormComponent from '../components/common/BaseFormComponent';
import CheckboxField from "../components/common/CheckboxField";
import Loader from "../components/common/Loader";
import RadioField from "../components/common/RadioField";
import SimpleReactValidator from "simple-react-validator";
import UserContribution from "../components/CommunityProject/UserContribution";
import CommunityContribution from "../components/CommunityProject/CommunityContribution";
import CountyContributionMap from "../components/CommunityProject/CountyContributionMap";
import { ContractTerms, ContractTermsCheckbox, ContractDigitalSign } from "../components/Contract";
import {
    fetchCustomerContribution,
    communityProjectRegister,
    communityProjectPrepareRegisterCompany,
    downloadCommunityProjectContractPreviewPdf,
    POST_COMMUNITY_PROJECT_REGISTER,
    POST_COMMUNITY_PROJECT_PREPARE_REGISTER_COMPANY
} from "../actions/communityProjectActions";
import { fetchAccount, fetchContacts } from "../actions/accountActions";
import { alexelaLinkedInUrl, alexelaTwitterUrl  } from "../const/urls";
import { CommunityPath, AgentServicePath, ContractsPath } from "../const/routes";
import CountySelectMap from "../components/CommunityProject/CountySelectMap";
import ValidationHelper from "../helpers/validationHelper";
import AlertMessage from "../components/common/AlertMessage";
import auth from "../auth/authenticate";
import lang from "../lang/lang";
import { isActiveMember, isActiveMemberByContractNumber } from "../helpers/communityProjectHelper";

import "../styles/react-components/communityProject.scss";

const contributionAmountPerMonth = [1000];
const contributionAmountsPerLitre = [1, 2, 3, 4, 5];

class CommunityProject extends BaseFormComponent {

    static fieldLocation = 'county';
    static fieldAcceptTOS = 'acceptTOS';

    static fieldChooseLocation = "selectedLocation";
    static fieldDefaultLocation = "defaultLocation";
    static formFields = [this.fieldLocation];

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            [CommunityProject.fieldLocation]: '',
            [CommunityProject.fieldAcceptTOS]: false,
            isAgent: auth.isAgent(),
            locale: lang.getLang(),
            selectedContributionType: contributionAmountsPerLitre[0],
            isCompany: auth.isCompany(),
            clientData: null,
            contractNumber: this.props.location?.state?.contractNumber || null,
            contributionAmounts: contributionAmountsPerLitre.concat(contributionAmountPerMonth),
            isTermsAccepted: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.renderActiveUser = this.renderActiveUser.bind(this);
        this.renderNewUser = this.renderNewUser.bind(this);
        this.openFB = this.openFB.bind(this);
        this.onChangeSelectedContribution = this.onChangeSelectedContribution.bind(this);
        this.setClientData = this.setClientData.bind(this);
        this.onPrepareSigning = this.onPrepareSigning.bind(this);
        this.onContractSigned = this.onContractSigned.bind(this);
        this.onDownloadPreviewPdf = this.onDownloadPreviewPdf.bind(this);
        this.onClickCompanyJoin = this.onClickCompanyJoin.bind(this);
        this.getCompanyData = this.getCompanyData.bind(this);
        this.renderCompanyJoinSection = this.renderCompanyJoinSection.bind(this);
        this.onSetIsTermsAcceptedChanged = this.onSetIsTermsAcceptedChanged.bind(this);

        this.topOfPageRef = React.createRef();
        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
        this.uiErrorsRef = React.createRef();
    }

    componentDidMount() {
        if (!this.props.customerContributionFetching && !this.props.customerContributionFetched) {
            this.props.fetchCustomerContribution();
        }
        if (this.state.isCompany) {
            if (!this.props.contactsFetching && !this.props.contactsFetched) {
                this.props.fetchContacts();
            }
            if (!this.props.accountFetching && !this.props.accountFetched) {
                this.props.fetchAccount();
            }
        }
    }

    onSubmit(e) {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        let data = {};
        CommunityProject.formFields.forEach(field => data[field] = this.state[field]);

        if (!this.props.customerContributionFetched || this.props.customerContribution.isActive) {
            return false;
        }

        this.props.communityProjectRegister(data).then(() => {
            return this.props.fetchCustomerContribution();
        }).then(() => {
            this.scrollToRef(this.topOfPageRef);
        }).catch(() => {});
    }

    renderBanner() {
        return (
            <Grid>
                <GridCell desktop={6} tablet={4}>

                    <Typography use="body1" className="mdc-typography">
                        <b><FormattedMessage id={this.state.isCompany
                            ? "Community.Banner2.Description.Company"
                            : "Community.Banner2.Description"} /></b>
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        <FormattedMessage id={this.state.isCompany
                            ? "Community.Banner2.Info.Company"
                            : "Community.Banner2.Info"} />
                    </Typography>
                </GridCell>
                <GridCell desktop={6} tablet={4}>
                    <div className={classnames("contribution-banner-2", "contribution-banner-2--" + this.state.locale)}></div>
                    <Typography className="mdc-typography mdc-theme--primary mt-20">
                        <FormattedMessage id={this.state.isCompany
                            ? "Community.Banner2.PlantingInfo.Company"
                            : "Community.Banner2.PlantingInfo"} />
                    </Typography>
                </GridCell>
            </Grid>
        );
    }

    openFB() {
        window.FBShare(this.props.intl.formatMessage({ id: "Community.AlexelaUrl" }));
    }

    onClickCompanyJoin() {
        this.props.history.push(ContractsPath);
    }

    renderMyPermanentDonation() {
        if (this.state.isCompany) {
            return null;
        }

        return (
            <Grid>
                <GridCell span={12}>
                    <Typography
                        use="headline2"
                        className="mdc-typography mdc-theme--primary"
                    >
                        <FormattedMessage id="Community.MyPermanentDonation" />
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        <FormattedMessage id="Community.DonateCent"/>
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        <FormattedMessage
                            id="Community.AlexelaTermsIsLocated"
                            values={{
                                location:
                                    <a href={this.props.intl.formatMessage({ id: "Community.Project.AlexelaUrl" })
                                    } target="_blank" rel="noopener noreferrer" >
                                        <FormattedMessage id="Community.Here" />
                                    </a>
                            }}
                        />
                    </Typography>
                    <Typography use="body1" className="mdc-typography mt-20">
                        <FormattedMessage id="Community.Unsubscribe" />
                    </Typography>
                </GridCell>
            </Grid>
        )
    }

    renderActiveUser() {
        return (
        <div ref={this.topOfPageRef}>
            {this.props.communityProjectRegisterFetched &&
                <Grid>
                    <GridCell span={12} className="contribution-confirmation mb-30">
                        <div className="contribution-wrap" >
                            <Typography
                                    use="headline3"
                                    className="mdc-typography mdc-theme--primary"
                                >
                                <FormattedMessage id="Community.Confirmation.Title" />
                            </Typography>
                            <Typography
                                    use="headline5"
                                    className="mdc-typography mdc-theme--primary  mt-20"
                                >
                                <FormattedMessage id="Community.Confirmation.DonationAccepted" />
                            </Typography>

                            <Typography
                                    use="body1"
                                    className="mdc-typography mdc-theme--primary  mt-20"
                                >
                                <b>
                                    <FormattedMessage
                                        id="Community.Confirmation.OverviewInfo"
                                        values={{
                                        location:
                                            <a href={CommunityPath} >
                                                <FormattedMessage id="Community.Confirmation.SelfService" />
                                            </a>
                                        }}
                                    />
                                </b>
                            </Typography>

                            <Typography
                                    use="body1"
                                    className="mdc-typography mdc-theme--primary  mt-20"
                                >
                                <FormattedMessage id="Community.Confirmation.Share" />
                            </Typography>

                            <div className="contribution-icon mt-20">
                                <i className="icon icon-facebook" onClick={this.openFB}></i>
                                <a href={alexelaTwitterUrl} target="_blank" rel="noopener noreferrer">
                                    <i className="icon icon-twitter"></i>
                                </a>
                                <a href={alexelaLinkedInUrl} target="_blank" rel="noopener noreferrer">
                                    <i className="icon icon-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </GridCell>
                </Grid>
            }
            {this.renderBanner()}
            {this.renderCompanyJoinSection()}
            <UserContribution/>
            <CommunityContribution/>
            <CountyContributionMap/>
            {this.renderMyPermanentDonation()}
        </div>
        );
    }

    onChangeSelectedContribution(type) {
        this.setState({ selectedContributionType: type });
    }

    setClientData(data) {
        this.setState({ clientData: data });
    }

    getCompanyData() {
        const companyName = auth.getRole()?.title;
        const contributionCentsPerLitre = this.state.selectedContributionType === 1000
            ? null
            : this.state.selectedContributionType;

        return {
            contractNumber: this.state.contractNumber,
            contributionCentsPerLitre,
            isMonthlyFee: this.state.selectedContributionType === 1000,
            companyName,
            county: this.state[CommunityProject.fieldLocation],
            registryCode: this.props.account?.companyCode || null
        }
    }

    onPrepareSigning() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        return async dispatch => {
            const data = this.getCompanyData();

            try {
                const { value } = await this.props.communityProjectPrepareRegisterCompany(data);
                dispatch({
                    type: `${POST_COMMUNITY_PROJECT_PREPARE_REGISTER_COMPANY}_FULFILLED`,
                    payload: value
                });

                return {
                    fileName: value.data.fileName
                }
            } catch (error) {
                dispatch({
                    type: `${POST_COMMUNITY_PROJECT_PREPARE_REGISTER_COMPANY}_REJECTED`,
                    error
                });
            }
        }
    }

    onContractSigned() {
        return async dispatch => {
            const data = this.getCompanyData();

            try {
                await this.props.communityProjectRegister(data);

                dispatch({
                    type: `${POST_COMMUNITY_PROJECT_REGISTER}_FULFILLED`,
                    payload: {
                        data: null
                    }
                });
            } catch (error) {
                dispatch({
                    type: `${POST_COMMUNITY_PROJECT_REGISTER}_REJECTED`,
                    error
                })
                throw error;
            }

            await this.props.fetchCustomerContribution();

            if (this.topOfPageRef.current !== null) {
                this.scrollToRef(this.topOfPageRef);
            }
        }
    }

    onDownloadPreviewPdf() {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        const data = this.getCompanyData();
        this.props.downloadCommunityProjectContractPreviewPdf(data);
    }

    onSetIsTermsAcceptedChanged(e) {
        this.setState({ isTermsAccepted: e.target.checked });
    }

    renderCompanyJoinSection() {
        if (!this.state.isCompany || this.state.contractNumber) {
            return null;
        }

        return (
            <Grid>
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="Community.Company.Unregistered.Notice.Title" />}
                        description={
                            <GridInner span={12} className="p-0">
                                <GridCell desktop={10} phone={12} tablet={6}>
                                    <FormattedMessage id="Community.Company.Unregistered.Notice.Description" />
                                </GridCell>
                                <GridCell desktop={2} phone={12} tablet={2} className="alert--child-right">
                                    <Button unelevated onClick={this.onClickCompanyJoin}>
                                        <FormattedMessage id="Community.Company.Join" />
                                    </Button>
                                </GridCell>
                            </GridInner>
                        }
                    />
                </GridCell>
            </Grid>
        )
    }

    renderNewUser() {
        // When user is in company role, then don't display the form when user comes
        // to this page directly, i.e. the state does not include a contract number
        const isFormHidden = this.state.isCompany && !this.state.contractNumber;
        const showCompanyForm = this.state.isCompany && this.state.contractNumber;

        return (
            <>
                {this.renderBanner()}
                {this.renderCompanyJoinSection()}
                <div className="mb-60">
                    <CommunityContribution />
                </div>
                {!this.state.isCompany && this.state.customerContribution?.bonusCard?.treesPlanted > 0 &&
                    <UserContribution />
                }
                <CountyContributionMap />
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={this.onSubmit}
                    className={isFormHidden ? "hidden" : ""}
                >
                    <Grid>
                        <GridCell span={12}>
                            <Typography
                                use="headline2"
                                className="mdc-typography mdc-theme--primary contribution-shifted"
                            >
                                <FormattedMessage id="Community.PlantingATreeIsSimple" />
                            </Typography>

                            <Typography
                                use="headline4 mt-20"
                                className="mdc-typography mdc-theme--primary contribution-first-step"
                            >
                                <FormattedMessage id="Community.AlreadyCustomer" />
                            </Typography>

                            <Typography
                                use="headline4 mt-20"
                                className="mdc-typography mdc-theme--primary contribution-second-step"
                            >
                                <FormattedMessage id="Community.JoinCommunity" />
                            </Typography>
                        </GridCell>

                        <GridCell span={12} className="contribution-shifted">
                            <Typography use="body1" className="mdc-typography">
                                <FormattedMessage id="Community.DiscountInfo" />
                            </Typography>
                            <Typography use="body1" className="mdc-typography mt-20">
                                <FormattedMessage id="Community.DonateInfo" />
                            </Typography>
                        </GridCell>

                        {showCompanyForm &&
                            <GridCell span={12} className="contribution-shifted">
                                <Typography use="body1" className="mdc-typography mt-20 mdc-typography--bold">
                                    <FormattedMessage id="Community.ChooseContributionType" />
                                </Typography>
                                <List className="mt-20">
                                    {this.state.contributionAmounts.map((key, i) =>
                                        <li className="form-fields-list__radio-item" key={i}>
                                            <RadioField
                                                index={i}
                                                fieldName="contribution-type-item"
                                                fieldValue={key}
                                                fieldLabel={
                                                    <div className="mdc-typography mt-5 mdc-typography--body1">
                                                        {key === 1000
                                                            ? <FormattedMessage id="Community.ContributionType.PerMonth" values={{ amount: key }} />
                                                            : <FormattedMessage id="Community.ContributionType.CentsPerLitre" values={{ cents: key }} />
                                                        }
                                                    </div>
                                                }
                                                selectedValue={this.state.selectedContributionType}
                                                onChange={() => this.onChangeSelectedContribution(key)}
                                            />
                                        </li>
                                    )}
                                </List>
                            </GridCell>
                        }

                        <GridCell span={12} className="contribution-shifted">
                            <Typography use="body1" className="mdc-typography mt-15 mdc-typography--bold">
                                <FormattedMessage id="Community.ChoosePlantedTreeLocation" />
                            </Typography>
                        </GridCell>

                        <GridCell span={12} className="contribution-shifted">
                            <CountySelectMap
                                selectedCounty={this.state[CommunityProject.fieldLocation]}
                                onChange={v => this.onChangeByField(CommunityProject.fieldLocation, v)}
                            />
                            {ValidationHelper.getHelperMessage(
                                this.validator.message('', this.state[CommunityProject.fieldLocation], 'required',
                                    {
                                        messages: {
                                            required: this.props.intl.formatMessage({ id: 'Community.Error.CountyNotSelected' })
                                        }
                                    }
                                ))
                            }
                        </GridCell>

                        {this.state[CommunityProject.fieldLocation] &&
                            <GridCell span={12}
                                className="contribution-shifted">
                                <FormattedMessage
                                    id="Community.ChosenTreeLocation"
                                    values={{
                                        location: <b>{this.state[CommunityProject.fieldLocation]}</b>
                                    }}
                                />
                            </GridCell>
                        }

                        {showCompanyForm &&
                            <>
                                <GridCell span={12} className="contribution-shifted">
                                    <ContractTerms termsTitleId="ContractsPage.Contract.TermsAndConditions" termsTextId="Community.TOS.Company" />
                                </GridCell>
                                <GridCell span={12} className="contribution-shifted">
                                    <ContractDigitalSign
                                        validator={this.validator}
                                        getFileToSign={this.onPrepareSigning}
                                        onDocumentSigned={this.onContractSigned}
                                        onDownloadPreview={this.onDownloadPreviewPdf}
                                        disabled={!this.state.isTermsAccepted}
                                    >
                                        <ContractTermsCheckbox
                                            checked={this.state.isTermsAccepted}
                                            onSetIsTermsAcceptedChanged={this.onSetIsTermsAcceptedChanged}
                                            titleKey="Community.TOS"
                                            termsValues={[{
                                                termsKey: "terms",
                                                termsLinkKey: "Community.Project.AlexelaUrlBusiness",
                                                termsLinkTextKey: "Community.AlexelaTermsLink",
                                            }]}
                                        />
                                    </ContractDigitalSign>
                                </GridCell>
                            </>
                        }
                        {!showCompanyForm &&
                            <>
                                <GridCell span={12} className="mt-20 contribution-shifted">
                                    <CheckboxField
                                        nativeControlId={CommunityProject.fieldAcceptTOS}
                                        field={CommunityProject.fieldAcceptTOS}
                                        checked={this.state[CommunityProject.fieldAcceptTOS]}
                                        indeterminate={false}
                                        onChange={this.onChangeCheckbox}
                                        label={
                                            <label htmlFor={CommunityProject.fieldAcceptTOS}>
                                                <FormattedMessage
                                                    id="Community.TOS"
                                                    values={{
                                                        terms: (
                                                            <a href={this.props.intl.formatMessage({ id: "Community.Project.AlexelaUrl" })}
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                <FormattedMessage id="Community.AlexelaTermsLink" />
                                                            </a>
                                                        )
                                                    }}
                                                />
                                            </label>
                                        }
                                    />
                                </GridCell>
                                <GridCell span={12} className="contribution-shifted">
                                    {this.props.communityProjectRegisterError ?
                                        <AlertMessage type={AlertMessage.TYPE_ALERT}
                                            title={<FormattedMessage id="Community.ErrorTitle" />}
                                            description={<FormattedMessage id="Community.Error" />}
                                            isSmall={true}
                                        />
                                        :
                                        <Button
                                            className="mt-20"
                                            unelevated
                                            disabled={!this.state[CommunityProject.fieldAcceptTOS] || !this.props.customerContributionFetched || this.props.communityProjectRegisterFetching}
                                        >
                                            {this.props.communityProjectRegisterFetching
                                                ? <Loader type={Loader.TYPE_BUTTON} />
                                                : <FormattedMessage id="Community.Button.Confirm" />
                                            }
                                        </Button>
                                    }
                                </GridCell>
                            </>
                        }
                        <GridCell span={12} className="mt-20">
                            <Typography
                                use="headline4"
                                className="mdc-typography mdc-theme--primary contribution-third-step"
                            >
                                <FormattedMessage id="Community.RefillToPlantTree" />
                            </Typography>
                        </GridCell>

                        <GridCell span={12} className="contribution-shifted">
                            <Typography
                                use="body1"
                                className="mdc-typography"
                            >
                                <FormattedMessage id="Community.EveryRefillEmpowersEstonia" />
                            </Typography>
                        </GridCell>
                    </Grid>
                </form>
            </>
        );
    }

    render() {
        let isActive = false;
        if (this.state.isCompany) {
            isActive = this.state.contractNumber
                ? isActiveMemberByContractNumber(this.state.contractNumber, this.props.customerContribution)
                : isActiveMember(this.props.customerContribution);
        } else {
            isActive = isActiveMember(this.props.customerContribution);
        }

        return (
            <>
                {this.state.isAgent &&
                    <Grid>
                        <GridCell>
                            <Button
                                className="mdc-button--secondary mdc-button--text uppercase other"
                                tag="a"
                                href={AgentServicePath}
                            >
                                <i className="icon icon-arrow-left mr-10" />
                                <FormattedMessage id="Agent.GoBackToService" />
                            </Button>
                        </GridCell>
                    </Grid>
                }
                {this.props.customerContributionFetched &&
                    isActive &&
                    this.renderActiveUser()
                }
                {this.props.customerContributionFetched &&
                    !isActive &&
                    this.renderNewUser()}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        counties: ["Harju maakond", "Hiiu maakond", "Ida-viru maakond", "Järva maakond", "Jõgeva maakond", "Lääne maakond", "Lääne-viru maakond", "Pärnu maakond", "Põlva maakond", "Rapla maakond", "Saare maakond", "Tartu maakond", "Valga maakond", "Viljandi maakond", "Võru maakond"],
        customerContributionFetching: state.customerContribution.fetching,
        customerContributionFetched: state.customerContribution.fetched,
        customerContribution: state.customerContribution.data,
        communityProjectRegisterFetched: state.communityProjectRegister.fetched,
        communityProjectRegisterFetching: state.communityProjectRegister.fetching,
        communityProjectRegister: state.communityProjectRegister.data,
        communityProjectRegisterError: state.communityProjectRegister.error,

        contacts: state.contacts.data,
        contactsFetching: state.contacts.fetching,
        contactsFetched: state.contacts.fetched,
        account: state.account.data,
        accountFetching: state.account.fetching,
        accountFetched: state.account.fetched
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCustomerContribution,
        communityProjectRegister,
        communityProjectPrepareRegisterCompany,
        fetchAccount,
        fetchContacts,
        downloadCommunityProjectContractPreviewPdf
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(CommunityProject));
