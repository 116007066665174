import { FETCH_HUB_INSTALLATION } from "../../actions/pushNotificationActions";

export default function reducer(state = {
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_HUB_INSTALLATION}_PENDING`: {
            return { ...state, fetching: true };
        }
        case `${FETCH_HUB_INSTALLATION}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${FETCH_HUB_INSTALLATION}_FULFILLED`: {
            let newData = state.data;

            if (!!action.payload.data && !!action.payload.data.installationId) {
                newData[action.payload.data.installationId] = action.payload.data;
            }

            return {
                ...state,
                fetching: false,
                fetched: true,
                data: newData
            };
        }
        default:
            return state;
    }
}
