import { Grid, GridCell } from "@rmwc/grid";
import { FormattedMessage, useIntl } from "react-intl";
import LinkElement from "../components/common/LinkElement";
import Icon from "../components/common/Icon";

import { isMobile } from 'react-device-detect';

import "../styles/react-components/helpAndContacts.scss";

const HelpAndContacts = () => {
    const intl = useIntl();

    const renderBlock = (blockName) => {
        const phone = intl.formatMessage({ id: `Help.${blockName}.Tel` });
        const email = intl.formatMessage({ id: `Help.${blockName}.Email` });

        return (
            <div className="contact-block">
                <div className="contact-block__title">
                    <div>
                        <span className="text-bold pr-10"><FormattedMessage id={`Help.${blockName}.Name`} /></span>
                        <span><FormattedMessage id={`Help.${blockName}.OpenHours`} /></span>
                    </div>
                    <div>
                        {phone} <span className="ml-5 mr-5" >|</span> {email}
                    </div>
                </div>
                <div className="contact-block__actions">
                    <div className="contact-block__action">
                        <Icon name="phone" className="contact-block__action__icon" />
                        <LinkElement className="mdc-typography mdc-typography--bold help-contact" href={`tel:${phone}`}>
                            <FormattedMessage id={'Help.Call'} />
                        </LinkElement>
                    </div>
                    <div className="contact-block__action">
                        <Icon name="envelope" className="contact-block__action__icon" />
                        <LinkElement className="mdc-typography mdc-typography--bold help-contact" href={`mailto:${email}`}>
                            <FormattedMessage id={'Help.SendEmail'} />
                        </LinkElement>
                    </div>
                </div>
            </div>);
    }

    if (!isMobile) {
        return null;
    }

    return (
        <Grid className="help-and-contacts">
            <GridCell span={12}>
                {renderBlock("Private")}
                {renderBlock("Business")}
                {renderBlock("Station")}
            </GridCell>
        </Grid>
    )
}

export default HelpAndContacts;