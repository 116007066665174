import BaseFormComponent from "../common/BaseFormComponent"
import React from "react";
import { GridCell } from "@rmwc/grid";
import { FormattedMessage, injectIntl } from "react-intl";
import { Typography } from "@rmwc/typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from '@rmwc/button';
import { fetchAlert, putAlert} from "../../actions/stockExchangePriceAlertActions";
import Loader from "../../components/common/Loader";
import AlertMessage from "../../components/common/AlertMessage";
import CheckboxField from "../../components/common/CheckboxField";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import SimpleReactValidator from "simple-react-validator";

class StockExchangePriceAlertSettings extends BaseFormComponent {
    static FieldIsStockExchangePriceAlertActive = "alertIsActive";
    static FieldStockExchangePriceAlertEmail = "alertEmail";
    static FieldStockExchangePriceAlertLimit = "alertExchangePriceLimit";

    constructor(props) {
        super(props);

        this.state = {
            showSuccessResponse: false,
            showErrors: false,
            [StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive]: false,
            [StockExchangePriceAlertSettings.FieldStockExchangePriceAlertEmail]: "",
            [StockExchangePriceAlertSettings.FieldStockExchangePriceAlertLimit]: ""
        }

        this.submitAlert = this.submitAlert.bind(this);
        this.isSaveButtonDisabled = this.isSaveButtonDisabled.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale,

            validators: {
                maxLimit: {
                    message: props.intl.formatMessage({id: "Error.Validator.StockPriceMaxLimit"}),
                    rule: (val, params) => {
                    return val != null && parseInt(val) <= parseInt(params[0]);
                    },
                    messageReplace: (message, params) => message.replace(':max', params[0])
                }
            }
        });
        this.uiErrorsRef = React.createRef();
    }

    componentDidMount() {
        this.props.fetchAlert();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.stockExchangePriceAlert !== undefined && prevProps.stockExchangePriceAlert !== this.props.stockExchangePriceAlert) {
            this.setState({
                [StockExchangePriceAlertSettings.FieldStockExchangePriceAlertEmail]: this.props.stockExchangePriceAlert.email ?? '',
                [StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive]: this.props.stockExchangePriceAlert.isActive ?? false,
                [StockExchangePriceAlertSettings.FieldStockExchangePriceAlertLimit]: this.props.stockExchangePriceAlert.exchangePriceLimit ?? ''
            });
        }
    }

    submitAlert() {
        this.setState({
            showErrors: false,
            showSuccessResponse: false
        });

        if (this.props.isPutAlertLoading) {
            return;
        }
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.setState(
                { showErrors: true },
                this.scrollToRef.bind(this, this.uiErrorsRef)
            );
            return;
        }

        const {accountCrmId} = this.props
        let isActive = this.state[StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive];

        if (!isActive) {
            this.state[StockExchangePriceAlertSettings.FieldStockExchangePriceAlertLimit] = 0
        }

        let data = {
            AccountCrmId: accountCrmId,
            IsActive: this.state[StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive],
            ExchangePriceLimit: this.state[StockExchangePriceAlertSettings.FieldStockExchangePriceAlertLimit],
            Email: this.state[StockExchangePriceAlertSettings.FieldStockExchangePriceAlertEmail]
        };

        this.props.putAlert(data).then(() => {
            this.setState({
                showSuccessResponse: true
            });
        });
    }

    isSaveButtonDisabled() {
        let isAlertActive = this.state[StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive];
        if (isAlertActive) {
            if (this.state[StockExchangePriceAlertSettings.FieldStockExchangePriceAlertEmail] === "" || this.state[StockExchangePriceAlertSettings.FieldStockExchangePriceAlertLimit] === "") {
                return true;
            }
        }
    }

    handleCheckboxChange(e) {
        this.onChangeCheckbox(e);
        this.validator.purgeFields();
    }

    render() {
        return (
            <GridCell span={12}>
            <Typography
                    use="headline3"
                    className="mdc-typography mdc-theme--primary mb-25 mt-30"
                >
                <FormattedMessage id="Settings.User.StockExchangePriceAlert" />
            </Typography>

            {this.state.showErrors &&
                this.errorsFromObject(
                    this.validator.getErrorMessages(),
                    this.uiErrorsRef
                )
            }

            {this.state.showSuccessResponse &&
                <AlertMessage
                    type={AlertMessage.TYPE_DONE}
                    isSmall={true}
                    scrollToMessage={true}
                    title={<FormattedMessage id="Settings.User.Saved" />}
                    className="mb-15" />
            }
            {this.props.putAlertError &&
                <AlertMessage type={AlertMessage.TYPE_ALERT}
                    title={<FormattedMessage id="Settings.User.StockExchangePrice.Error" />}
                    scrollToMessage={true}
                    className="mb-15"
                />
            }

            <CheckboxField
                nativeControlId={StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive}
                field={StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive}
                checked={this.state[StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive]}
                indeterminate={false}
                label={
                    <label htmlFor={StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive}>
                        <FormattedMessage id="Settings.User.IsStockExchangePriceAlertActive"/>
                    </label>
                }
                onChange={this.handleCheckboxChange}
            />

            {this.state[StockExchangePriceAlertSettings.FieldIsStockExchangePriceAlertActive] === true && 
                <GridCell span={12} className= "mt-60">
                    <TextFieldGroup
                        type="email"
                        field={StockExchangePriceAlertSettings.FieldStockExchangePriceAlertEmail}
                        label="Settings.User.StockExchangePriceAlertEmail"
                        disabled={false}
                        validator={this.validator}
                        rules="required|email"
                        onChange={this.onChange}
                        value={this.state[StockExchangePriceAlertSettings.FieldStockExchangePriceAlertEmail]}
                    />

                    <TextFieldGroup
                        classNames="mdc-text-field--long-desc"
                        type="number"
                        field={StockExchangePriceAlertSettings.FieldStockExchangePriceAlertLimit}
                        label="Settings.User.StockExchangePriceAlertLimit"
                        disabled={false}
                        validator={this.validator}
                        rules="required|maxLimit:999"
                        onChange={this.onChange}
                        value={this.state[StockExchangePriceAlertSettings.FieldStockExchangePriceAlertLimit]}
                    />

                </GridCell>
            }

                <GridCell span={12}>
                    <Button
                        className="mb-35 mt-20"
                        unelevated
                        disabled={this.isSaveButtonDisabled()}
                        onClick={this.submitAlert}>
                        {this.props.isPutAlertLoading ?
                            <Loader type={Loader.TYPE_BUTTON} />
                            :
                            <FormattedMessage id="Settings.User.Save" />
                        }
                    </Button>
                </GridCell>
            </GridCell>
        );
    }
}

function mapStateToProps(state) {
    return {
        stockExchangePriceAlert: state.stockExchangePriceAlert.data,
        isPutAlertLoading: state.putAlert.fetching,
        isPutAlertLoaded: state.putAlert.fetched,
        putAlertError: state.putAlert.error
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAlert,
        putAlert
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(StockExchangePriceAlertSettings)
);