import React from "react";
import classnames from "classnames";
import { Typography } from "@rmwc/typography";

const DiscountBadge = ({ discount, discountUnit, isDone, className }) => {
    if (!discount && !discountUnit) {
        return null;
    }

    return (
        <div
            className={classnames(
                "badge",
                "badge--discount",
                { done: isDone },
                className
            )}
        >
            <Typography use="headline6" tag="b" className="mdc-typography">
                {discount}
            </Typography>
        </div>
    );
};

export default DiscountBadge;
