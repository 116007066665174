const templateEt = {
    "accepted": "Поле \":attribute\" должно быть выбрано.",
    "after": ":attribute должен быть больше чем :date.",
    "after_or_equal": ":attribute должен быть больше или равен :date.",
    "alpha": ":attribute может содержать только буквы.",
    "alpha_num": ":attribute может содержать только буквы и цифры.",
    "alpha_num_dash": ":attribute может содержать только буквы, цифры и дефисы.",
    "alpha_num_dash_space": ":attribute может содержать только буквы, цифры, дефисы и пробелы.",
    "alpha_num_space": ":attribute может содержать только буквы, цифры и пробелы.",
    "alpha_space": ":attribute может содержать только буквы и пробелы.",
    "array": ":attribute должен быть массивом.",
    "before": ":attribute должен быть до :date.",
    "before_or_equal": ":attribute должен быть до или в :date.",
    "between": ":attribute должен быть между :min и :max:type.",
    "boolean": ":attribute должен быть логическим.",
    "card_exp": ":attribute должен быть действительной датой истечения срока действия.",
    "card_num": ":attribute должен быть действительным номером кредитной карты.",
    "cashback": "Пожалуйста введите \":attribute\" без точек и пробелов!",
    "coop": "Пожалуйста введите \":attribute\" без точек и пробелов!",
    "currency": ":attribute должен быть действительной валютой.",
    "date": ":attribute должен быть датой.",
    "date_equals": ":attribute должен быть :date.",
    "email": ":attribute не в правильном формате!",
    "idCode": ":attribute недействителен.",
    "in": ":attribute должен быть :values.",
    "integer": ":attribute должен быть целым числом.",
    "max": ":attribute должен быть не больше чем :max:type.",
    "min": ":attribute должен содержать не менее :min символов.",
    "not_in": ":attribute не должен быть :values.",
    "not_regex": ":attribute не должен соответствовать требуемому шаблону.",
    "numeric": ":attribute должен быть числом.",
    "phone": ":attribute не в правильном формате.",
    "regex": "Поле \":attribute\" не соответствует требуемому шаблону.",
    "required": "Поле \":attribute\" обязательно для заполнения.",
    "requiredIf": "Поле \":attribute\" обязательно для заполнения.",
    "size": ":attribute должен быть :size:type.",
    "string": ":attribute должен быть строкой.",
    "typeof": ":attribute не является правильным :type типом.",
    "url": ":attribute должен быть URL-адресом."
};

export default templateEt;