import { CylinderGas, ElectricityNew, EvCharging, GasNew } from "../const/contractType";

export function GetContractLocale(contractType) {
    switch (contractType) {
        case ElectricityNew:
            return "Contract.Electricity.Title";
        case GasNew:
            return "Contract.Gas.Title";
        case EvCharging:
            return "Contract.EvCharging.Title";
        case CylinderGas:
            return "Contract.CylinderGas.Title";
        default:
            return "Contract.Fuel.Title";
    }
}

