import { useEffect } from "react";
import { useIntl } from 'react-intl';
import { postPrepareSignature, postFinalizeSignature } from "../../actions/digitalSignatureActions";

const useEParakstsEID = () => {
    const { locale } = useIntl();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = process.env.PUBLIC_URL + "/eparaksts-hwcrypto.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    };

    const byesToHex = (byteArray) => {
        return Array.from(byteArray, (byte) => {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join("");
    }

    const hexToBytes = (hex) => {
        const bytes = [];
        for (let c = 0; c < hex.length; c += 2) {
            bytes.push(parseInt(hex.substr(c, 2), 16));
        }

        return bytes;
    }

    const signEParakstsIdCard = (fileToSign) => async (dispatch) => {
        if (!window.eparakstshwcrypto) {
            throw new Error("eParaksts eID signing component not found");
        }

        const certificate = await window.eparakstshwcrypto.getCertificate({ lang: locale, operation: "sign" });
        const certificateAsBase64 = window.btoa(String.fromCharCode.apply(null, certificate.encoded));

        const digestResponse = await dispatch(postPrepareSignature({
            base64Certificate: certificateAsBase64,
            fileName: fileToSign.fileName
        }));

        if (digestResponse.value.status !== 200) {
            throw new Error("Could not get digest to sign");
        }

        const { hash, fileName } = digestResponse.value.data;
        const signatureValue = base64ToArrayBuffer(hash);
        const signatureHex = byesToHex(signatureValue);

        const signedHash = await window.eparakstshwcrypto.sign(certificate, { type: "SHA", hex: signatureHex }, { lang: locale, operation: "sign" });
        const signedBytes = hexToBytes(signedHash.hex);
        const signature = window.btoa(String.fromCharCode.apply(null, signedBytes));

        if (!signature || !fileName) {
            throw new Error("Could not sign document");
        }

        const finalizeResponse = await dispatch(postFinalizeSignature({
            signature,
            fileName
        }));

        if (finalizeResponse.value.status !== 200) {
            throw new Error("Could not finalize signature");
        }
    }

    return signEParakstsIdCard;
}

export default useEParakstsEID;