export default function reducer(state = {
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case 'FETCH_CLIENT_CONTRACT_CONSUMPTION_LOCATIONS_PENDING': {
            return { ...state, fetching: true };
        }
        case 'FETCH_CLIENT_CONTRACT_CONSUMPTION_LOCATIONS_REJECTED': {
            return { ...state, fetching: false, error: action.payload };
        }
        case 'FETCH_CLIENT_CONTRACT_CONSUMPTION_LOCATIONS_FULFILLED': {
            return { ...state, fetching: false, fetched: true, data: action.payload.data };
        }
        default:
            return state;
    }
}