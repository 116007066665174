import React from "react";
import { useIntl } from "react-intl";
import TextFieldGroup from "../common/TextFieldGroup";
import { useValidator } from "../../helpers/hooks";
import { DigitalStampParams } from "../../const/digitalStamps";

const SELECTED_STAMPS_FIELD = "collectedStamps";

const AmountSelector = ({ availableStamps, selectedAmount, setStampsCount, isDisabled = false }) => {
    const intl = useIntl();
    const [validator, ] = useValidator({
        collectedStamps: {
            message: intl.formatMessage({id: "DigitalStamps.Validation.OverMaxLimit"}),
            rule: (val, params) => {
               return val && (parseInt(val) <= parseInt(params[0]));
            },
            messageReplace: (message, params) => message.replace(':max', params[0])
        },
        minLimit: {
            message: intl.formatMessage({id: "DigitalStamps.Validation.MinStamps"}),
            rule: (val) => {
               return val && (parseInt(val) >= DigitalStampParams.MinAmountAllowedForConverting);
            },
        },
        noDecimal: {
            message: intl.formatMessage({id: "DigitalStamps.Validation.NoDecimal"}),
            rule: (val, _, validator) => {
               return val && validator.helpers.testRegex(val, /^\d+$/i);
            },
        }
    });

    const onChangeStampsToConvert = (e) => {
        if (!validator.allValid()) {
            validator.showMessages();
        }

        const newValue = e.target.value;
        setStampsCount?.(newValue);
    }

    return (
        <TextFieldGroup
            noLabel={true}
            field={SELECTED_STAMPS_FIELD}
            value={selectedAmount}
            onChange={onChangeStampsToConvert}
            dontTranslateLabel={true}
            validator={validator}
            type="number"
            rules={`collectedStamps:${availableStamps}|noDecimal|minLimit`}
            label={intl.formatMessage({ id: "DigitalStamps.Field.DigitalStampsCount" })}
            placeholder={intl.formatMessage({ id: "DigitalStamps.Placeholder.MinAmount" })}
            disabled={isDisabled}
        />
    )
}

export default AmountSelector;