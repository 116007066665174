import BusinessApi from './BusinessApi';
import auth from "../auth/authenticate";
import { directFileDownloadAction } from '../helpers/fileDownloadHelpers';
import moment from 'moment';

export const FETCH_CONSUMPTION_LOCATIONS = "FETCH_CONSUMPTION_LOCATIONS";
export const FETCH_CONSUMPTION_DETAILS = "FETCH_CONSUMPTION_DETAILS";
export const DOWNLOAD_CONSUMPTION_DETAILS_FILE = "DOWNLOAD_CONSUMPTION_DETAILS_FILE";

export const FETCH_PRODUCTION_DETAILS = "FETCH_PRODUCTION_DETAILS";
export const DOWNLOAD_PRODUCTION_DETAILS_FILE = "DOWNLOAD_PRODUCTION_DETAILS_FILE";

const FileTypes = {
    CSV: "csv",
    XLSX: "xlsx"
};

const getResponseType = (fileType) => {
    switch (fileType) {
        case FileTypes.CSV:
            return "";
        case FileTypes.XLSX:
            return "arraybuffer";
        default:
            return "";
    }
}

export const fetchConsumptionLocations = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: FETCH_CONSUMPTION_LOCATIONS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/consumptionLocation/list`)
    };
};

export const fetchClientContractConsumptionLocations = (contractId) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: 'FETCH_CLIENT_CONTRACT_CONSUMPTION_LOCATIONS',
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/consumptionLocation/contract/${contractId}`)
    };
};


export const fetchConsumptionDetails = (params) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: FETCH_CONSUMPTION_DETAILS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/consumption`, {
            params: {
                ...params,
                crmId: roleId
            }
        }).then(({ data }) => ({ data, params }))
    }
};

export const downloadConsumptionDetailsFile = (start, locationId, energyType, periodType, fileType) => {
    if (!fileType) {
        return;
    }

    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    const endpoint = `/api/${accountId}/${roleId}/consumption/${fileType}`;
    let parameters = {
        crmId: roleId,
        periodStart: start,
        energyType: energyType,
        locationId: locationId,
        periodType: periodType,
    };

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = `consumption_${energyType}_${periodType}_${locationId}_${moment(start).format("DD/MM/yyyy")}.${fileType}`;
            directFileDownloadAction(endpoint, fileName, parameters);
        } else {
            dispatch({
                type: DOWNLOAD_CONSUMPTION_DETAILS_FILE,
                payload: BusinessApi.get(endpoint, {
                    params: parameters,
                    responseType: getResponseType(fileType)
                })
            });
        }
    }
}

export const fetchProductionDetails = (params) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: FETCH_PRODUCTION_DETAILS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/production`, {
            params: {
                ...params,
                crmId: roleId
            }
        }).then(({ data }) => ({ data, params }))
    }
};

export const downloadProductionDetailsFile = (start, locationId, periodType, fileType) => {
    if (!fileType) {
        return;
    }

    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    const endpoint = `/api/${accountId}/${roleId}/production/${fileType}`;
    const parameters = {
        crmId: roleId,
        periodStart: start,
        locationId: locationId,
        periodType: periodType,
    };

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = `production_${locationId}_${periodType}_${moment(start).format("DD/MM/yyyy")}.${fileType}`;
            directFileDownloadAction(endpoint, fileName, parameters);
        } else {
            dispatch({
                type: DOWNLOAD_PRODUCTION_DETAILS_FILE,
                payload: BusinessApi.get(endpoint, {
                    params: parameters,
                    responseType: getResponseType(fileType)
                })
            });
        }
    }
}
