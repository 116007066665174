import { FamilyGroupForm } from "../components/FamilyGroup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchFamilyGroup } from "../actions/familyGroupActions";
import {withFeature} from "flagged";
import {FamilyGroupAsHidden} from "../const/featureFlags";

const FamilyGroup = () => {
    const { fetched } = useSelector(state => state.familyGroup);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchFamilyGroup());
    }, [dispatch]);

    if (!fetched) {
        return null;
    }

    return <FamilyGroupForm />;
}

export default withFeature(FamilyGroupAsHidden)(FamilyGroup);