import auth from "../../auth/authenticate"

export default function reducer(state={
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case "AGENT_ROLE_SET_PENDING": {
            return {...state, fetching: true};
        }
        case "AGENT_ROLE_SET_REJECTED": {
            return {...state, fetching: false, error: action.payload};
        }
        case "AGENT_ROLE_SET_FULFILLED": {
            auth.setRole(action.payload);
            auth.changeAgentUserMode();
            return {...state, fetching: false, fetched: true};
        }
        default:
            return state;
    }
}