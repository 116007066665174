import { ADD_NEW_NOTIFICATION_TO_USERS } from "../../actions/notificationActions";

export default function reducer(state = {
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${ADD_NEW_NOTIFICATION_TO_USERS}_PENDING`: {
            return { ...state, fetching: true, error: null };
        }
        case `${ADD_NEW_NOTIFICATION_TO_USERS}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload.data };
        }
        case `${ADD_NEW_NOTIFICATION_TO_USERS}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, error: null, data: action.payload.data };
        }
        default:
            return state;
    }
}