import {Grid, GridCell} from "@rmwc/grid";
import TextAreaGroup from "../common/TextAreaGroup";
import TextFieldGroup from "../common/TextFieldGroup";
import {useEffect, useState} from "react";
import {Button} from "@rmwc/button";
import {FormattedMessage, useIntl} from "react-intl";
import {useValidator} from "../../helpers/hooks";
import {useDispatch, useSelector} from "react-redux";
import {fetchSmsTemplates, sendSmsMessage} from "../../actions/notificationActions";
import AlertMessage from "../common/AlertMessage";
import SelectField from "../common/SelectField";
import {MarketingSmsTemplates} from "./MarketingSmsTemplates";
import classNames from "classnames";
import {MarketingSmsSentSmsesList} from "./MarketingSmsSentSmsesList";
import FileField from "../common/FileField";
import Papa from "papaparse";
import ValidationHelper from "../../helpers/validationHelper";
import languageEncoding from "detect-file-encoding-and-language";

const MarketingSmsMessages = () => {
    const csvDataLimit = 500000;
    const intl = useIntl();
    const [crmIds, setCrmIds] = useState("");
    const [csvFile, setCsvFile] = useState(null);
    const [csvParseError, setCsvParseError] = useState(null);
    const [csvContent, setCsvContent] = useState([]);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [templateId, setTemplateId] = useState(0);
    const [content, setContent] = useState("");
    const [isSmsTemplatesTabActive, setIsSmsTemplatesTabActive] = useState(false);
    const [contentError, setContentError] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [validator,] = useValidator();
    const sendSms = useSelector(state => state.sendMarketingSms);
    const dispatch = useDispatch();
    const smsTemplates = useSelector(state => state.smsTemplates)
    const isLocalOrTestEnv = process.env.REACT_APP_ENV === "local" || process.env.REACT_APP_ENV === "test";

    useEffect(() => {
        if (!smsTemplates.fetching && !smsTemplates.fetched) {
            dispatch(fetchSmsTemplates())
        }
    });

    useEffect(() => {
        const template = smsTemplates.data?.find(t => t.id === parseInt(templateId));

        setContent(template?.content ?? "");
    }, [templateId]);

    useEffect(() => {
        setCsvContent([])
        setCsvHeaders([]);
        setCrmIds("")
        setCsvParseError(null);

        if (csvFile) {
            languageEncoding(csvFile).then((fileInfo) => {
                Papa.parse(csvFile, {
                    skipEmptyLines: true,
                    header: true,
                    encoding: fileInfo.encoding ?? "ASCII", // Fallback encoding since it's not detected by languageEncoding nor Papa
                    complete(results) {
                        if (results.errors.length > 0) {
                            setCsvParseError(results.errors[0].message);
                            return;
                        }

                        if (!results.meta.fields.includes("crmid")) {
                            setCsvParseError(intl.formatMessage({id: "Error.Validator.CsvMissingCrmid"}));
                            return;
                        }

                        if (results.data.length > csvDataLimit) {
                            setCsvParseError(intl.formatMessage({id: "Error.Validator.CsvTooManyRows"}, {limit: csvDataLimit}));
                            return;
                        }

                        setCsvContent(results.data);
                        setCsvHeaders(results.meta.fields);setCrmIds(results.data.map(value => value.crmid).join(","));
                    },
                    error(error) {
                        setCsvParseError(error.message);
                    }
                });
            }, reason => setCsvParseError(reason));
        }
    }, [csvFile]);

    useEffect(() => {
        setContentError(null);

        if (content && csvHeaders.length > 0) {
            const fields = content.match(/{{[\wšžõäöüŠŽÕÄÖÜ]+}}/g)?.map(value => value.replace(/{|}/g, ''));
            const invalidFields = fields?.filter(field => !csvHeaders.includes(field)) ?? [];

            if (invalidFields.length > 0) {
                setContentError(intl.formatMessage({id: "Error.Validator.ContentInvalidFields"}, {
                    invalidFields: invalidFields.join(", "),
                    validFields: csvHeaders.join(", ")
                }));
            }
        }
    }, [content, csvHeaders]);

    useEffect(() => {
        setSubmitDisabled(!validator.allValid() || contentError || csvParseError)
    }, [crmIds, content, contentError, csvParseError]);

    const submit = () => {
        if (!validator.allValid() || contentError || csvParseError) {
            validator.showMessages();
            return;
        }

        const payload = {
            crmIds: crmIds.split(",").map(x => x.trim()).filter(x => !!x),
            additionalData: csvContent,
            template: content
        }

        dispatch(sendSmsMessage(payload));
    };

    const toggleTabs = () => {
        setIsSmsTemplatesTabActive(prevState => !prevState);
    }

    const updateCsvFile = (value) => {
        setCsvFile(value);
    };

    return (<>
        <Grid>
            {!sendSms.fetching && (sendSms.fetched || sendSms.error) && <GridCell span={12}>
                {sendSms.error ? <AlertMessage
                    isSmall
                    type={AlertMessage.TYPE_ALERT}
                    title={<FormattedMessage id="MarketingSmsMessages.SendFailed"/>}
                /> : <AlertMessage
                    isSmall
                    type={AlertMessage.TYPE_DONE}
                    title={<FormattedMessage id="MarketingSmsMessages.SendSuccessful"/>}
                />}
            </GridCell>}
            {isLocalOrTestEnv && <GridCell span={12}>
                <AlertMessage
                    isSmall
                    type={AlertMessage.TYPE_WARN}
                    title={<FormattedMessage id="MarketingSmsMessages.Warning.SendingSms"/>}
                />
            </GridCell>}
            <GridCell desktop={4} tablet={4} phone={4}>
                <TextFieldGroup
                    onChange={e => setCrmIds(e.target.value)}
                    value={crmIds}
                    label="MarketingSmsMessages.CrmIds"
                    field="MarketingSmsMessages.CrmIds"
                    validator={validator}
                    rules="required"
                    placeholder={intl.formatMessage({ id: "MarketingSmsMessages.CrmIds.Placeholder" })}
                />
            </GridCell>
            <GridCell desktop={4} tablet={4} phone={4}>
                <FileField
                    onChange={updateCsvFile}
                    fileTypes=".csv"
                    label="MarketingSmsMessages.CsvFile"
                    field="MarketingSmsMessages.CsvFile"
                    validator={validator}
                />
                {ValidationHelper.getHelperMessage(csvParseError)}
            </GridCell>
            <GridCell desktop={4} tablet={4} phone={4}>
                <SelectField
                    field="MarketingSmsMessages.Template"
                    label="MarketingSmsMessages.Template"
                    value={templateId}
                    addEmptyOption
                    onChange={(afterChange, field, e) => setTemplateId(e.currentTarget.value)}
                    options={[{}].concat(smsTemplates.data?.map(t => ({label: t.content, value: t.id})))}
                />
            </GridCell>
            <GridCell span={12}>
                <TextAreaGroup
                    onChange={e => setContent(e.target.value)}
                    field="MarketingSmsMessages.Content"
                    label="MarketingSmsMessages.Content"
                    value={content}
                    validator={validator}
                    rules="required|max:150"
                    rows={4}
                    maxLength={150}
                    showCounter
                    placeholder={intl.formatMessage({ id: "MarketingSmsMessages.Content.Placeholder" })}
                />
                {ValidationHelper.getHelperMessage(contentError)}
            </GridCell>
            <GridCell span={12}>
                <Button unelevated className="right" onClick={submit} disabled={submitDisabled}>
                    <FormattedMessage id="MarketingSmsMessages.Send"/>
                </Button>
            </GridCell>
        </Grid>

        <Grid className="mdc-layout-grid--base mdc-layout-grid--mobile-full mdc-layout-grid--no-gap">
            <GridCell desktop={6} tablet={4} phone={2}>
                <Button
                    className={classNames("mdc-button-tab-underlined mdc-button--tab mdc-button--wide", {"active": !isSmsTemplatesTabActive})}
                    onClick={toggleTabs}>
                    <FormattedMessage id="MarketingSmsMessages.SentSmses"/>
                </Button>
            </GridCell>
            <GridCell desktop={6} tablet={4} phone={2}>
                <Button
                    className={classNames("mdc-button-tab-underlined mdc-button--tab mdc-button--wide", {"active": isSmsTemplatesTabActive})}
                    onClick={toggleTabs}>
                    <FormattedMessage id="MarketingSmsMessages.Templates"/>
                </Button>
            </GridCell>
        </Grid>
        {isSmsTemplatesTabActive ? <MarketingSmsTemplates/> : <MarketingSmsSentSmsesList/>}

    </>)
}

export default MarketingSmsMessages