import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useTimedRedirect = () => {
    const history = useHistory();

    const redirectToPage = useCallback((page, state, delay = 5000) => {
        setTimeout(() => {
            history.push({
                pathname: page,
                state
            });
        }, delay);
    }, [history]);

    return redirectToPage;
}

export default useTimedRedirect;