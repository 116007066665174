import {FlagsProvider} from "flagged";
import * as React from "react";
import {useEffect, useState} from "react";
import {fetchFeatures} from "./actions/featuresActions";

const FeatureFlagsProvider = ({ children }) => {
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        fetchFeatures().then(items => {
            setFeatures(items.data);
        });
    }, []);

    return (<FlagsProvider features={features}>{children}</FlagsProvider>)
}

export default FeatureFlagsProvider;