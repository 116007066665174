import { Icon } from "@rmwc/icon";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { SummerCampaignPrize } from "../../const/summerCampaign";

import "../../styles/components/_summer-campaign.scss";

const PrizeItem = ({ item, onClick }) => {
    const isMobile = useSelector(state => state.ui.isMobileOrTabletView);

    return (
        <div className="summer-campaign__prize-item" onClick={onClick}>
            <img
                src={item.img}
                alt={item.title}
                className={classNames({
                    "tub": item.type === SummerCampaignPrize.Tub,
                    "winner": item.isWin,
                    "mobile": isMobile
                })}
            />
            <div className="summer-campaign__prize-item__content">
                <div className="summer-campaign__prize-item__title">{item.title}</div>
                {item.subTitle && <div className="summer-campaign__prize-item__sub-title">{item.subTitle}</div>}
            </div>
            <Icon
                icon={{
                    icon: "angle-right",
                    strategy: "className",
                    basename: "icon",
                    prefix: "icon-"
                }}
            />
        </div>
    );
}

export default PrizeItem;