import { REJECT_OFFER, RESET_REJECT_OFFER } from "../../actions/offerActions";

export default function reducer(state={
    data: null,
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case `${REJECT_OFFER}_PENDING`: {
            return {...state, error: null, fetching: true, fetched: false};
        }
        case `${REJECT_OFFER}_REJECTED`: {
            return {...state, fetched: false, fetching: false, error: action.payload.response.data};
        }
        case `${REJECT_OFFER}_FULFILLED`: {
            return {...state, fetching: false, error: null, fetched: true, data: action.payload.data};
        }
        case RESET_REJECT_OFFER:
            return {...state, fetching: false, error: null, fetched: false, data: null}
        default:
            return state;
    }
}