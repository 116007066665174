import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from "react-intl";
import { IconButton } from "@rmwc/icon-button";

const Banner = ({ onHide }) => {
    const intl = useIntl();

    return (
        <>
            <div className="hero-banner-close">
                <IconButton
                    checked
                    onIcon={{
                        icon: "close",
                        strategy: "className",
                        basename: "icon",
                        prefix: "icon-"
                    }}
                    icon={{}}
                    className="align-left mdc-icon-button--clear item close"
                    onClick={onHide}
                />
            </div>
            <a
                href={intl.formatMessage({ id: "Overview.Banner.ElectricityCampaignUrl" })}
                target="_blank"
                rel="noreferrer"
                className="hero--banner-parent"
            >
                <div className={classnames(
                    `hero-banner`,
                    `hero-banner-image--${intl.locale}`)}
                />
            </a>
        </>
    );
}

Banner.propTypes = {
    onHide: PropTypes.func
}

export default Banner;