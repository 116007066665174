import React from "react";
import { GridCell, GridInner } from "@rmwc/grid";
import { FormattedMessage, injectIntl } from "react-intl";
import { Typography } from "@rmwc/typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from '@rmwc/button';
import { fetchPushNotificationSettings, savePushNotificationSettings, clearSavedPushNotificationMessages } from "../../actions/pushNotificationActions";
import BaseFormComponent from "../common/BaseFormComponent";
import Loader from "../common/Loader";
import AlertMessage from "../common/AlertMessage";
import CheckboxField from "../common/CheckboxField";
import auth from "../../auth/authenticate";
import { isNullOrEmpty } from "../../helpers/objectHelper";

class PushNotificationSettings extends BaseFormComponent {

    constructor(props) {
        super(props);

        this.state = {
            selectedTypes: [],
            isAdmin: auth.isAdmin()
        };

        this.isTypeSelected = this.isTypeSelected.bind(this);
        this.fetchPushNotificationSettings = this.fetchPushNotificationSettings.bind(this);
        this.updateSelectedNotificationTypes = this.updateSelectedNotificationTypes.bind(this);
        this.isSaveButtonDisabled = this.isSaveButtonDisabled.bind(this);
        this.savePushNotificationSettings = this.savePushNotificationSettings.bind(this);
        this.displaySaveErrors = this.displaySaveErrors.bind(this);
    }

    componentDidMount() {
        this.props.clearSavedPushNotificationMessages();
        this.fetchPushNotificationSettings();
    }

    fetchPushNotificationSettings() {
        this.props.fetchPushNotificationSettings()
            .then(this.updateSelectedNotificationTypes);
    }

    updateSelectedNotificationTypes() {
        const { pushNotificationSettings } = this.props;

        console.debug(pushNotificationSettings.selectedTypes);
        this.setState({
            selectedTypes: pushNotificationSettings.selectedTypes
        });
    }

    isTypeSelected(type) {
        return this.state.selectedTypes && this.state.selectedTypes.indexOf(type) >= 0;
    }

    toggleSelectedType(type) {
        this.setState((prev) => {
            const index = prev.selectedTypes.indexOf(type);
            const selectedTypes = [...prev.selectedTypes];

            if (index < 0) {
                selectedTypes.push(type);
            }
            else {
                selectedTypes.splice(index, 1);
            }

            return {
                ...prev,
                selectedTypes: selectedTypes
            }
        });
    }

    isSaveButtonDisabled() {
        if (this.props.pushNotificationSettingsSaving) {
            return true;
        }
    }

    savePushNotificationSettings() {
        this.props.savePushNotificationSettings({ selectedTypes: this.state.selectedTypes })
            .then(this.fetchPushNotificationSettings);
    }

    displaySaveErrors(error, fallbackMessage) {
        if (typeof error === 'string') {
            return <AlertMessage
                type={AlertMessage.TYPE_ALERT}
                title={error}
                scrollToMessage={true}
                className="mb-15" />
        }

        return this.errors(error, fallbackMessage);
    }

    render() {
        const {
            pushNotificationSettingsSaving,
            pushNotificationSettingsSaved,
            pushNotificationSettings,
            pushNotificationSettingsFetching,
            pushNotificationSettingsSaveError,
            pushNotificationSettingsError,
        } = this.props;

        let notificationTypes = !isNullOrEmpty(pushNotificationSettings) ? pushNotificationSettings.allTypes : [];

        return (
            <GridInner>
                <GridCell span={12}>
                    <Typography
                        use="headline3"
                        className="mdc-typography mdc-theme--primary mb-mobile-20">
                        <FormattedMessage id="Settings.Notifications.Title" />
                    </Typography>
                </GridCell>

                <GridCell span={12}>
                    <Typography use="body1"                    >
                        <FormattedMessage id="Settings.Notifications.Description" />
                    </Typography>
                </GridCell>
                <GridCell span={12}>
                    {pushNotificationSettingsSaveError != null && !!pushNotificationSettingsSaveError &&
                        this.displaySaveErrors(pushNotificationSettingsSaveError, "Settings.Notifications.SaveError")}
                    {pushNotificationSettingsError != null && !!pushNotificationSettingsError &&
                        this.errors(pushNotificationSettingsError, "Settings.Notifications.Error")}

                    {pushNotificationSettingsSaved &&
                        <AlertMessage
                            type={AlertMessage.TYPE_DONE}
                            isSmall
                            scrollToMessage
                            title={<FormattedMessage id="Settings.Notifications.Saved" />}
                            className="mb-15" />
                    }

                    <GridInner style={{ position: "relative" }}>
                        {(pushNotificationSettingsFetching || pushNotificationSettingsSaving) &&
                            <Loader type={Loader.TYPE_COVER} />}

                        <GridCell span={12}>
                            {notificationTypes &&
                                notificationTypes.map(x =>
                                    <CheckboxField
                                        key={x}
                                        field={`NotificationType[${x}]`}
                                        checked={this.isTypeSelected(x)}
                                        label={`Settings.Notifications.Type.${x}`}
                                        onChange={() => this.toggleSelectedType(x)}
                                        containerClassName="mt-8 mb-8 mr-24" />
                                )}
                        </GridCell>
                        <GridCell span={12}>
                            <Button
                                unelevated
                                disabled={this.isSaveButtonDisabled()}
                                onClick={this.savePushNotificationSettings}>
                                {pushNotificationSettingsSaving
                                    ? <Loader type={Loader.TYPE_BUTTON} />
                                    : <FormattedMessage id="Settings.Notifications.Save" />}
                            </Button>
                        </GridCell>
                    </GridInner>
                </GridCell>
            </GridInner>
        );
    }
}

function mapStateToProps(state) {
    return {
        pushNotificationSettings: state.pushNotificationSettings.data,
        pushNotificationSettingsFetched: state.pushNotificationSettings.fetched,
        pushNotificationSettingsFetching: state.pushNotificationSettings.fetching,
        pushNotificationSettingsError: state.pushNotificationSettings.error,
        pushNotificationSettingsSaved: state.savePushNotificationSettings.fetched,
        pushNotificationSettingsSaving: state.savePushNotificationSettings.fetching,
        pushNotificationSettingsSaveError: state.savePushNotificationSettings.error,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPushNotificationSettings,
        savePushNotificationSettings,
        clearSavedPushNotificationMessages
    }, dispatch);
}

export default injectIntl(
    connect(mapStateToProps, matchDispatchToProps)(PushNotificationSettings)
);
