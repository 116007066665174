import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";
import { directFileDownloadAction } from "../helpers/fileDownloadHelpers";

export const FETCH_CUSTOMER_CONTRIBUTION = 'FETCH_CUSTOMER_CONTRIBUTION';
export const FETCH_COMMUNITY_PROJECT_STATISTICS = 'FETCH_COMMUNITY_PROJECT_STATISTICS';
export const POST_COMMUNITY_PROJECT_REGISTER = 'POST_COMMUNITY_PROJECT_REGISTER';
export const FETCH_COUNTY_CONTRIBUTION = 'FETCH_COUNTY_CONTRIBUTION';
export const FETCH_COUNTY_CONTRIBUTION_COLOR_SCHEME = 'FETCH_COUNTY_CONTRIBUTION_COLOR_SCHEME';
export const POST_COMMUNITY_PROJECT_PREPARE_REGISTER_COMPANY = 'POST_COMMUNITY_PROJECT_PREPARE_REGISTER_COMPANY';
export const DOWNLOAD_COMMUNITY_PROJECT_CONTRACT_PREVIEW_PDF = 'DOWNLOAD_COMMUNITY_PROJECT_CONTRACT_PREVIEW_PDF';
export const DOWNLOAD_COMMUNITY_CONTRACT_DIGIDOC = 'DOWNLOAD_COMMUNITY_CONTRACT_DIGIDOC';

export const fetchCustomerContribution = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: FETCH_CUSTOMER_CONTRIBUTION,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/communityProject/community-project-contribution`)
    };
};

export const resetCustomerContribution = () => {
    return {
        type: `${FETCH_CUSTOMER_CONTRIBUTION}_RESET`
    };
};

export const fetchCommunityProjectStatistics = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: FETCH_COMMUNITY_PROJECT_STATISTICS,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/communityProject/statistics`)
    };
};

export const communityProjectRegister = (preferences) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: POST_COMMUNITY_PROJECT_REGISTER,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/communityProject/register-community-project`, preferences)
    };
};

export const fetchCountyContribution = () => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: FETCH_COUNTY_CONTRIBUTION,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/communityProject/county-statistics`)
    };
};

export const communityProjectPrepareRegisterCompany = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    return {
        type: POST_COMMUNITY_PROJECT_PREPARE_REGISTER_COMPANY,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/communityProject/register-community-project/prepare-company`, data)
    };
}

export const downloadCommunityProjectContractPreviewPdf = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/communityProject/download-preview`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = "contract_preview.pdf";
            directFileDownloadAction(endpoint, fileName, data);
        } else {
            dispatch({
                type: DOWNLOAD_COMMUNITY_PROJECT_CONTRACT_PREVIEW_PDF,
                payload: BusinessApi.get(endpoint, {
                    params: data,
                    responseType: "arraybuffer"
                })
            });
        }
    }
}

export const downloadCommunityContractDigiDoc = (data) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/communityProject/digidoc`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            let fileName = "";

            if (!!data.customerName && !!data.contractNumber) {
                const nameFixed = data.customerName.toUpperCase().trim().replace(' ', '_');
                fileName = `KKP${data.contractNumber}_${nameFixed}.asice`;
            }

            directFileDownloadAction(endpoint, fileName, data);
        } else {
            dispatch({
                type: DOWNLOAD_COMMUNITY_CONTRACT_DIGIDOC,
                payload: BusinessApi.get(endpoint, {
                    params: data,
                    responseType: "arraybuffer"
                })
            });
        }
    }
};