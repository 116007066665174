import auth from "../auth/authenticate";
import BusinessApi from "./BusinessApi";

export const FETCH_GASO_METER_LIST = "FETCH_GASO_METER_LIST";
export const SUBMIT_GASO_METER = "SUBMIT_GASO_METER";

export const fetchGasoMeterList = ({locationId}) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: FETCH_GASO_METER_LIST,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/gasoMeter/${locationId}`)
    };
};

export const submitGasoMeter = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: SUBMIT_GASO_METER,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/gasoMeter`, data)
    }; 
}