import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";

import auth from "../auth/authenticate";
import { fetchUserNotifications, putUserNotificationsAsRead, putAllUserNotificationsAsRead, fetchUnreadUserNotifications } from "../actions/notificationActions";
import NotificationItem from "../components/Notifications/NotificationItem";
import Loader from "../components/common/Loader";
import Pagination from "../components/common/Pagination";
import AlertMessage from "../components/common/AlertMessage";
import { getNotificationActionInfo } from "../helpers/notificationHelper";


class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPage: 1,
            updatingIds: {}
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.fetchUserNotifications = this.fetchUserNotifications.bind(this);
        this.markAllAsRead = this.markAllAsRead.bind(this);
        this.markNotificationAsRead = this.markNotificationAsRead.bind(this);
    }

    componentDidMount() {
        this.fetchUserNotifications(this.state.selectedPage);
    }

    fetchUserNotifications(page, pageSize) {
        return this.props.fetchUserNotifications(
            page,
            pageSize ?? auth.getPageSize()
        );
    }

    markAllAsRead() {
        const { userNotifications } = this.props;

        if (userNotifications.some(x => !x.isRead)) {
            this.props.putAllUserNotificationsAsRead();
        }
    }

    onPageChange({ page, pageSize }) {
        this.setState(
            { selectedPage: page },
            this.fetchUserNotifications.bind(this, page, pageSize)
        );
    }

    markNotificationAsRead(item) {
        if (!item.isRead) {
            this.toggleItemUpdating(item.id);
            this.props.putUserNotificationsAsRead([item.id])
                .finally(() => this.toggleItemUpdating(item.id));
        }
    }

    toggleItemUpdating(id) {
        this.setState((prev) => {
            let updating = { ...prev.updatingIds };
            updating[+id] = !updating[+id];
            return { ...prev, updatingIds: updating };
        });
    }

    render() {
        const {
            userNotifications,
            userNotificationsFetched,
            userNotificationsFetching,
            totalUserNotifications,
            markingNotificationsAsRead
        } = this.props;

        const hasNoNotifications = userNotificationsFetched && (!totalUserNotifications || totalUserNotifications <= 0);

        return (
            <Grid className="mdc-layout-grid--base mb-60">
                <GridCell span={12} ref={this.scrollToRef}>
                    {userNotificationsFetching &&
                        <Loader type={Loader.TYPE_CENTER} />}

                    {!userNotificationsFetching && hasNoNotifications &&
                        <AlertMessage
                            type={AlertMessage.TYPE_NOTICE}
                            title={<FormattedMessage id="Notifications.NotFound" />} />}

                    {!userNotificationsFetching && !hasNoNotifications &&
                        <>
                            <div className="d-flex justify-content-end">
                                <Button className="mdc-button mdc-button--secondary"
                                    outlined
                                    onClick={this.markAllAsRead}
                                    disabled={markingNotificationsAsRead}>
                                    <FormattedMessage id="Notifications.MarkAllOnPageAsRead" />
                                </Button>
                            </div>
                            <div className="mt-45 mt-mobile-30">
                                {userNotifications.map((x, i) => (
                                    <NotificationItem
                                        key={"notification-" + i}
                                        markReadAction={this.markNotificationAsRead.bind(this, x)}
                                        loading={!!this.state.updatingIds[x.id]}
                                        cta={getNotificationActionInfo(x.type, x.metaInfo, this.props.history, this.props.intl)}
                                        item={x} />
                                ))}
                            </div>
                        </>
                    }
                </GridCell>
                <GridCell span={12}>
                    <Pagination
                        total={totalUserNotifications || 0}
                        selectedPage={this.state.selectedPage}
                        onChange={this.onPageChange}
                        scrollToRef={this.scrollToRef}
                    />
                </GridCell>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        totalUserNotifications: state.userNotifications.total,
        userNotifications: state.userNotifications.data,
        userNotificationsFetching: state.userNotifications.fetching,
        userNotificationsFetched: state.userNotifications.fetched,
        markingNotificationsAsRead: state.putAllUserNotificationsAsRead.fetching,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchUserNotifications,
        fetchUnreadUserNotifications,
        putUserNotificationsAsRead,
        putAllUserNotificationsAsRead
    }, dispatch);
}

export default injectIntl(
    withRouter(
        connect(mapStateToProps, matchDispatchToProps)(Notifications)
    )
);
