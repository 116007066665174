export const Products =  [
    {
        value: "CARD_PRODUCTS.ALL",
        label: "Cards.CARD_PRODUCTS.ALL"
    },
    {
        value: "CARD_PRODUCTS.ALL_EXCEPT_IN_STORE",
        label: "Cards.CARD_PRODUCTS.ALL_EXCEPT_IN_STORE"
    },
    {
        value: "CARD_PRODUCTS.CAR_SERVICE_AND_WASH",
        label: "Cards.CARD_PRODUCTS.CAR_SERVICE_AND_WASH"
    },
    {
        value: "CARD_PRODUCTS.CAR_SERVICE_AND_WASH_AND_MATERIALS",
        label: "Cards.CARD_PRODUCTS.CAR_SERVICE_AND_WASH_AND_MATERIALS"
    },    {
        value: "CARD_PRODUCTS.CARWASH",
        label: "Cards.CARD_PRODUCTS.CARWASH"
    },
    {
        value: "CARD_PRODUCTS.LUBES_AND_FLUIDS",
        label: "Cards.CARD_PRODUCTS.LUBES_AND_FLUIDS"
    },
    {
        value: "CARD_PRODUCTS.NONE",
        label: "Cards.CARD_PRODUCTS.NONE"
    }
]

export const FuelTypes =  [
    {
        value: "CARD_FUEL_TYPE.ALL",
        label: "Cards.CARD_FUEL_TYPE.ALL"
    },
    {
        value: "CARD_FUEL_TYPE.ALL_AND_DERIM",
        label: "Cards.CARD_FUEL_TYPE.ALL_AND_DERIM"
    },
    {
        value: "CARD_FUEL_TYPE.DIESEL",
        label: "Cards.CARD_FUEL_TYPE.DIESEL"
    },
    {
        value: "CARD_FUEL_TYPE.DIESEL_AND_DERIM",
        label: "Cards.CARD_FUEL_TYPE.DIESEL_AND_DERIM"
    },
    {
        value: "CARD_FUEL_TYPE.DIESEL_AND_GASOLINE",
        label: "Cards.CARD_FUEL_TYPE.DIESEL_AND_GASOLINE"
    },
    {
        value: "CARD_FUEL_TYPE.GASOLINE",
        label: "Cards.CARD_FUEL_TYPE.GASOLINE"
    },
    {
        value: "CARD_FUEL_TYPE.GASOLINE_AND_LPG",
        label: "Cards.CARD_FUEL_TYPE.GASOLINE_AND_LPG"
    },
    {
        value: "CARD_FUEL_TYPE.LPG",
        label: "Cards.CARD_FUEL_TYPE.LPG"
    },
    {
        value: "CARD_FUEL_TYPE.NONE",
        label: "Cards.CARD_FUEL_TYPE.NONE"
    }
]