import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";

export const SEND_ORDER = "SEND_ORDER";
export const FETCH_WHOLESALE_FUELS = "FETCH_WHOLESALE_FUELS";
export const FETCH_PURCHASE_RIGHT = "FETCH_PURCHASE_RIGHT"
export const RESET_PURCHASE_RIGHT = "RESET_PURCHASE_RIGHT";

export const fetchWholesaleFuels = () => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: FETCH_WHOLESALE_FUELS,
        payload: BusinessApi.get(`api/${accountId}/${roleId}/FuelTaxiOrder/wholesale-fuels`)
    };
};

export const sendOrder = (data) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: SEND_ORDER,
        payload: BusinessApi.post(`/api/${accountId}/${roleId}/FuelTaxiOrder/order`, data)
    }
}

export const fetchPurchaseRight = (personalCode) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();

    return {
        type: FETCH_PURCHASE_RIGHT,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/FuelTaxiOrder/purchase-right/${personalCode}`)
    }
}

export const resetPurchaseRight = () => {
    return {
        type: RESET_PURCHASE_RIGHT
    }
}
