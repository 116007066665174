import BusinessApi from './BusinessApi';
import auth from "../auth/authenticate";

export const fetchElectricityProducts = (consumptionLocationsIds, cancelToken, productId, contractId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    let params = {
        consumptionLocations: consumptionLocationsIds,
    };

    if (productId) {
        params.productId = productId;
    }

    if (contractId) {
        params.contractId = contractId;
    }

    let config = {};
    if (cancelToken) {
        config = { cancelToken: cancelToken };
    }

    return {
        type: 'FETCH_ELECTRICITY_PRODUCTS',
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/contract/electricity-products`, {
            params: params
        }, config)
    };
};

export const fetchGasProducts = (consumptionLocationsIds, cancelToken, productId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    let params = {
        consumptionLocations: consumptionLocationsIds,
    };

    if (productId) {
        params.productId = productId;
    }

    let config = {};
    if (cancelToken) {
        config = { cancelToken: cancelToken };
    }

    return {
        type: 'FETCH_GAS_PRODUCTS',
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/contract/gas-products`, {
            params: params
        }, config)
    };
};