import React, { useState } from 'react';
import classnames from "classnames";
import { isAndroid, isIOS } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { Button } from "@rmwc/button";

import wazeImage from "../../images/navi/waze.svg";
import googleMapsImage from "../../images/navi/google-maps.svg";
import appleMapsImage from "../../images/navi/apple-maps.svg";


const StationInfo = ({ name, address, fuelIcons, serviceIcons, latitude, longitude, headerIsToggle, links }) => {
    const appleUrl = `http://maps.apple.com/?daddr=${latitude},${longitude}`;
    const wazeUrl = `https://waze.com/ul?ll=${latitude},${longitude}&z=10&navigate=yes&zoom=17`;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${latitude},${longitude}`;

    const [isOpen, setIsOpen] = useState(headerIsToggle ? false : true)

    const renderNavigationLink = (imgSource, name, url) => {
        return (
            <a className="station-info__directions__link" href={url} target="_blank" rel="noopener noreferrer">
                <img src={imgSource} alt={name} />
                <div className="mdc-typography station-info__directions__link__text">{name}</div>
            </a>
        );
    }

    const toggleIsOpen = () => setIsOpen(!isOpen);

    return (
        <div className={classnames("station-info", { "station-info--open": isOpen })}>
            <div className={classnames("station-info__header",
                { "station-info__header--toggle": headerIsToggle }
            )}
                onClick={headerIsToggle ? toggleIsOpen : null}
            >
                <div>
                    <div className="mdc-typography station-info__title">
                        {name}
                    </div>
                    <div className="mdc-typography station-info__address">
                        {address}
                    </div>
                </div>
                {headerIsToggle &&
                    <Button
                        onClick={() => toggleIsOpen()}
                        className="toggle"
                        icon={{
                            icon: "angle-down",
                            strategy: "className",
                            basename: "icon",
                            prefix: "icon-",
                            className: isOpen ? "rotate" : ""
                        }}
                    />}
            </div>
            {isOpen &&
                <>
                    <div className="station-info__directions">
                        <div className="station-info__directions__title">
                            <FormattedMessage id="Stations.Map.NavigateThere" />
                        </div>
                        <div className="station-info__directions__links">
                            {isAndroid &&
                                <>
                                    {renderNavigationLink(wazeImage, "Waze", wazeUrl)}
                                    {renderNavigationLink(googleMapsImage, "Google Maps", googleMapsUrl)}
                                </>}

                            {isIOS &&
                                <>
                                    {renderNavigationLink(wazeImage, "Waze", wazeUrl)}
                                    {renderNavigationLink(googleMapsImage, "Google Maps", googleMapsUrl)}
                                    {renderNavigationLink(appleMapsImage, "Apple Maps", appleUrl)}
                                </>}

                            {!(isAndroid || isIOS) &&
                                renderNavigationLink(googleMapsImage, "Google Maps", googleMapsUrl)}
                        </div>
                    </div>
                    <div className="station-info__icons">
                        {fuelIcons && fuelIcons.length > 0 &&
                            <div className="row">
                                {fuelIcons.map((icon, iconindex) =>
                                (
                                    <i key={`fuel-${iconindex}`}
                                        className={`icon ${icon}`}></i>
                                ))}
                            </div>}

                        {serviceIcons && serviceIcons.length > 0 &&
                            <div className="row">
                                {serviceIcons.map((icon, iconindex) =>
                                (
                                    <i key={`service-${iconindex}`}
                                        className={`icon ${icon}`}></i>
                                ))}
                            </div>}
                    </div>
                    {!!links &&
                        <div className="station-info__links">
                            {links}
                        </div>}
                </>}
        </div>)
}

export default StationInfo;