export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case "GET_BONUS_CARD_PENDING": {
            return {...state, fetching: true, error: null};
        }
        case "GET_BONUS_CARD_REJECTED": {
            return {...state, fetching: false, error: action.payload.response.data};
        }
        case "GET_BONUS_CARD_FULFILLED": {
            return {...state, fetching: false, fetched: true, data: action.payload.data};
        }
        default:
            return state;
    }
}