import React from "react";

import OverviewDiscountChart from "../components/overview/OverviewDiscountChart";

class CardDetails extends React.Component {

    render() {
        const { match } = this.props;

        return (
            <div className="mt-tablet-20 mb-30 dashboard-discount-details">
                <OverviewDiscountChart selectedCardNr={match.params.id} />
            </div>
        );
    }
}

export default CardDetails;
