import { MenuSelectionAction } from "../reducers/menu/setMenuSelectionReducer";

const populateCrumbArray = (input, output, pathToFind) => {
    if (input.path === pathToFind) {
        output.push({path: input.path, translationKey: input.translationKey });
        return;
    }

    if (input.children && Array.isArray(input.children)) {
        for (const child of input.children) {
            populateCrumbArray(child, output, pathToFind);

            if (output.length > 0) {
                output.push({path: input.path, translationKey: input.translationKey });
                break;
            }
        }
    }
}

const getSecondaryMenu = (menu, pathToFind) => {
    for (const item of menu) {
        const hasPath = getSecondaryMenuUtil(item, pathToFind);

        if (hasPath) {
            return item;
        }
    }
}

const getSecondaryMenuUtil = (item, path) => {
    if (item.path === path) {
        return true;
    }

    if (item.children && Array.isArray(item.children)) {
        for (const child of item.children) {
            const result = getSecondaryMenuUtil(child, path);
            if (result) return true;
        }
    }

    return false;
}

const setSecondaryMenuItemByPath = (menu, path) => dispatch => {
    const secMenu = getSecondaryMenu(menu, path);
    dispatch({ type: MenuSelectionAction.SetSecondaryMenuSelection, payload: {
        secondary: secMenu
    }});
}

export {
    populateCrumbArray,
    getSecondaryMenu,
    setSecondaryMenuItemByPath
}