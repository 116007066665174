import React from "react";
import { injectIntl} from 'react-intl';
import { GridCell, Grid } from "@rmwc/grid";
import BaseFormComponent from '../../components/common/BaseFormComponent';
import Tabs from "../../components/common/Tabs";
import { Country } from "../../const/country";
import TranslationTab from "../../components/locale/TranslationTab";

class TranslationSettings extends BaseFormComponent {
    constructor(props) {
        super(props);
        this.state = {
            countries: [
                { type: Country.EE, label: this.props.intl.formatMessage({ id: "TranslationSettings.AlexelaEstonia" }) },
                { type: Country.LV, label: this.props.intl.formatMessage({ id: "TranslationSettings.AlexelaLatvia" }) },
            ],
            selectedCountry: this.props.selectedCountry ?? Country.EE
        };

        this.onCountryChanged = this.onCountryChanged.bind(this);
    }

    renderCountryTab(countryCode) {
        const isActive = this.state.selectedCountry === countryCode;
        return (
            <>
                { isActive ? <TranslationTab countryCode={countryCode}/> : <></> }
            </>
        );
    }

    onCountryChanged(index) {
        const { countries } = this.state;
        const selectedCountry= countries[index].type;

        this.setState({ selectedCountry });
    }

    render() {
        const { countries, selectedCountry } = this.state;
        const tabContentsMap = {
            [Country.EE]: this.renderCountryTab(Country.EE),
            [Country.LV]: this.renderCountryTab(Country.LV),
        };
        return (
            <Grid>
                <GridCell span={12}>
                    <Tabs
                        defaultTabIndex={countries.findIndex(x => x.type === selectedCountry)}
                        items={countries.map((x, i) => ({
                            label: x.label,
                            contents: tabContentsMap[x.type],
                            onSelect: this.onCountryChanged
                        }))} />
                </GridCell>
            </Grid>
        );
    }
}

export default injectIntl(TranslationSettings);