import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQueryParams = () => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    const queryParamsObj = {};
    for (const [key, value] of queryParams.entries()) {
        queryParamsObj[key] = value;
    }

    return queryParamsObj;
}

export default useQueryParams;