const templateLv = {
    "accepted": "Lauciņam \":attribute\" jābūt izvēlētam.",
    "after": ":attribute jābūt lielākam par :date.",
    "after_or_equal": ":attribute jābūt lielākam vai vienādam ar :date.",
    "alpha": ":attribute var saturēt tikai burtus.",
    "alpha_num": ":attribute var saturēt tikai burtus un ciparus.",
    "alpha_num_dash": ":attribute var saturēt tikai burtus, ciparus un domuzīmes.",
    "alpha_num_dash_space": ":attribute var saturēt tikai burtus, ciparus, domuzīmes un atstarpes.",
    "alpha_num_space": ":attribute var saturēt tikai burtus, ciparus un atstarpes.",
    "alpha_space": ":attribute var saturēt tikai burtus un atstarpes.",
    "array": ":attribute jābūt masīvam.",
    "before": ":attribute jābūt pirms :date.",
    "before_or_equal": ":attribute jābūt pirms vai vienādam ar šo datumu - :date.",
    "between": ":attribute jābūt intervālā no :min līdz :max:type.",
    "boolean": ":attribute jābūt patiesam vai nepatiesam.",
    "card_exp": ":attribute jābūt derīgam derīguma termiņam.",
    "card_num": ":attribute jābūt derīgam kredītkartes numuram.",
    "cashback": "Lūdzu ievadiet \":attribute\" bez punktiem un atstarpēm!",
    "coop": "Lūdzu ievadiet \":attribute\" bez punktiem un atstarpēm!",
    "currency": ":attribute jābūt derīgai valūtai.",
    "date": ":attribute jābūt datumam.",
    "date_equals": ":attribute jābūt datumam - :date.",
    "email": ":attribute nav pareizā formātā!",
    "idCode": ":attribute nav derīgs.",
    "in": ":attribute jābūt vienam no :values.",
    "integer": ":attribute jābūt veselam skaitlim.",
    "max": ":attribute nedrīkst būt garāks par :max zīmēm.",
    "min": ":attribute jābūt vismaz :min zīmju garumā.",
    "not_in": ":attribute nedrīkst būt starp :values.",
    "not_regex": ":attribute nedrīkst atbilst prasītajam rakstam.",
    "numeric": ":attribute jābūt skaitlim.",
    "phone": ":attribute nav pareizā formātā.",
    "regex": "Lauciņam \":attribute\" neatbilst nepieciešamajam rakstam.",
    "required": "Lauciņš \":attribute\" ir obligāts.",
    "requiredIf": "Lauciņš \":attribute\" ir obligāts.",
    "size": ":attribute jābūt :size:type garumā.",
    "string": ":attribute jābūt teksta virknē.",
    "typeof": ":attribute jābūt tipa :type.",
    "url": ":attribute jābūt URL."
};

export default templateLv;