import { FETCH_DEVICE_REGISTRATION_LIST } from "../../actions/pushNotificationActions";

export default function reducer(state = {
    data: {},
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_DEVICE_REGISTRATION_LIST}_PENDING`: {
            return { ...state, fetching: true };
        }
        case `${FETCH_DEVICE_REGISTRATION_LIST}_REJECTED`: {
            return { ...state, fetching: false, error: action.payload };
        }
        case `${FETCH_DEVICE_REGISTRATION_LIST}_FULFILLED`: {
            return { ...state, fetching: false, fetched: true, data: action.payload.data };
        }
        default:
            return state;
    }
}