import React, { useState, useEffect, useCallback, useRef } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

import { IconButton } from "@rmwc/icon-button";
import MenuSurface, { Corner } from "@material/react-menu-surface";
import { MenuSurfaceAnchor } from "@rmwc/menu";

import { fetchUnreadUserNotifications } from "../../actions/notificationActions";
import NotificationsPanel from "../Notifications/NotificationsPanel";
import { NotificationsPath } from "../../const/routes";

const REFRESH_FREQUENCY = 60000 * 5; // 5 minutes


const MenuNotification = ({ history }) => {

    const {
        fetched: unreadFetched,
        fetching: unreadFetching,
        count: unreadCount
    } = useSelector(state => state.unreadUserNotifications);

    const dispatch = useDispatch();

    const [isOpen, toggleOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState(null);
    let refreshTimeout = useRef();

    const close = () => {
        toggleOpen(false);
    };

    const refresh = useCallback(() => {
        clearTimeout(refreshTimeout.current);
        if (!unreadFetching) {
            console.debug("refreshing notificaitons");
            dispatch(fetchUnreadUserNotifications())
                .finally(() => {
                    refreshTimeout.current = setTimeout(refresh, REFRESH_FREQUENCY)
                });
        } else {
            console.debug("already running");
            refreshTimeout.current = setTimeout(refresh, REFRESH_FREQUENCY);
        }
    }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

    const getMenuOffset = () => {
        // Source: https://www.thetopsites.net/article/54127255.shtml
        if (!anchorElement) return { top: 0, left: 0 };

        const rect = anchorElement.getBoundingClientRect();
        const left = rect.left + window.scrollX + anchorElement.offsetWidth;
        const top = rect.top + window.scrollY + anchorElement.offsetHeight;

        return { left: left, top: top };
    }

    // useEffect(() => {
    //     if (!unreadFetched && !unreadFetching) {
    //         refresh();
    //     }
    // }, [refresh]);

    const renderIconButton = (onClick) =>
        <IconButton
            checked
            className="mdc-icon-button--clear nav__link nav__link--notifications notification-icon"
            icon={{
                icon: "bell",
                strategy: "className",
                basename: "icon",
                prefix: "icon-",
                className: "mdc-icon-button__icon--on"
            }}
            onIcon={{
                className: classnames({
                    "marked": unreadCount > 0
                })
            }}
            onClick={onClick}
        />;

    const renderDesktopIconButton = () => renderIconButton(() => toggleOpen(true));
    const renderMobileIconButton = () => renderIconButton(() => history.push(NotificationsPath));

    const renderMenu = () =>
        <MenuSurface
            open={isOpen}
            anchorCorner={Corner.BOTTOM_START}
            anchorElement={anchorElement}
            anchorMargin={getMenuOffset()}
            onClose={close}
            // onOpen={onOpen}
            className="mdc-menu mdc-menu--notifications"
        >
            <NotificationsPanel onHide={close} isOpen={isOpen} />
        </MenuSurface>;

    return (
        // isMobile
        //  ? 
        <>
            {renderMobileIconButton()}
        </>
        // : <MenuSurfaceAnchor ref={setAnchorElement}>
        //     {renderDesktopIconButton()}
        //     {renderMenu()}
        // </MenuSurfaceAnchor>
    )
}

export default injectIntl(withRouter(MenuNotification));
