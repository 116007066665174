import React from "react";
import { useSelector } from "react-redux";
import HeaderDesktop from "./header/HeaderDesktop";
import HeaderMobile from "./header/HeaderMobile";
import auth from "../../auth/authenticate";

const Header = () => {
    const isMobileOrTabletView = useSelector(state => state.ui.isMobileOrTabletView);

    if (auth.isAgent()) return null;

    return (isMobileOrTabletView
        ? <HeaderMobile />
        : <HeaderDesktop />
    );
}

export default Header;
