import React from "react"
import { FormattedMessage } from "react-intl";
import { GridCell, GridInner } from "@rmwc/grid";
import auth from "../../auth/authenticate";

const ContractFuelDiscounts = ({ model }) => {
    const isCompany = auth.isCompany();

    const getDiscountValue = (product) => {
        switch (product.discountType) {
            case "CENTS":
                return `-${product.discount} ${product.unit}`;
            case "PERCENT":
                return `-${product.discount}%`;
            case "FIXED_PRICE":
                return `${product.discount} ${product.unit}`
            default:
                return `${product.discount} ${product.unit}`;
        }
    }

    if (!model.products || model.products.length === 0 || !isCompany) {
        return null;
    }

    return (
        <>
            <GridCell span={12} className="mt-20 mdc-typography mdc-typography--headline4 mdc-typography--bold">
                <FormattedMessage id="ContractsPage.Discounts.Title" />
                <hr className="divider divider--secondary" />
            </GridCell>
            <GridCell span={12}>
                {model.products.map((product, i) => {
                    return (
                        <GridInner className="border-bottom" key={i}>
                            <GridCell className="p-10 text-bold" span={6}>{product.name}</GridCell>
                            <GridCell className="p-10 text-bold" span={6}>
                                {getDiscountValue(product)}
                            </GridCell>
                        </GridInner>
                    );
                })}
            </GridCell>
        </>
    );
}

export default ContractFuelDiscounts;