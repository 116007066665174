import React from "react";
import PropTypes from 'prop-types';
import { Icon as RmwcIcon } from "@rmwc/icon";
import Loader from "./Loader";

const defaultIconProps = { strategy: "className", basename: "icon", prefix: "icon-" };
const loadingIconProps = {
    strategy: "custom", 
    render: () => <Loader type={Loader.TYPE_BUTTON} size="small" />
}

const Icon = ({ name, iconProps, ...rest }) => {

    return <RmwcIcon icon={{ icon: name, ...defaultIconProps, ...iconProps }} {...rest} />
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    iconProps: PropTypes.shape({
        strategy: PropTypes.string,
        basename: PropTypes.string,
        prefix: PropTypes.string,
        className: PropTypes.any
    }),
    props: PropTypes.any
};

export default Icon;
export { defaultIconProps, loadingIconProps };