import React from "react";
import "../../styles/react-components/footer-fm.scss";
import FooterMenu from "./footer/FooterMenu";
import FooterMenuMobile from "./footer/FooterMenuMobile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class Footer extends React.Component {
  render() {
    return (
      <>
      <footer className="footer">
        <FooterMenu />
      </footer>
      {this.props.isLoggedIn &&
        <FooterMenuMobile />
      }
      </>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
  return {
      isLoggedIn: state.auth.isLoggedIn
  };
}

export default connect(mapStateToProps, matchDispatchToProps)(Footer);
