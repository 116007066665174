import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl} from 'react-intl';
import { GridCell, Grid } from "@rmwc/grid";
import BaseFormComponent from '../../components/common/BaseFormComponent';
import { fetchAlerts } from "../../actions/stockExchangePriceAlertActions";
import StockPriceAlertList from "../../components/StockPriceAlert/StockPriceAlertList";
import StockPriceAlertFilter from "../../components/StockPriceAlert/StockPriceAlertFilter";

class StockPriceAlerts extends BaseFormComponent {
    constructor(props) {
        super(props);

        this.onFilterSubmit = this.onFilterSubmit.bind(this);

        this.initialState = {
            filter: {
                email: "",
                crmId: ""
            },
        };

        this.state = { ...this.initialState };
    }

    onFilterSubmit(filter) {
        this.setState({filter: filter });
        this.fetchAlerts(filter);
    }

    fetchAlerts(filter) {
        const email = filter.email;
        const crmId = filter.crmId;

        this.props.fetchAlerts(email, crmId);
    }

    renderAlerts = (alerts, filter) => {
        return alerts.length > 0 ? (
            <main>
                <StockPriceAlertList
                    items={alerts}
                    filter={filter}
                />
            </main>
        ) : null;
    }

    render() {
        return(
            <Grid className="mdc-layout-grid--base">
                <GridCell span={12}>
                        <StockPriceAlertFilter
                            onSubmit={this.onFilterSubmit}
                            filter={this.state.filter}
                        />
                    </GridCell>
                <GridCell span={12}>
                    {this.renderAlerts(this.props.stockPriceAlerts, this.state.filter)}
                </GridCell>
            </Grid>
        )
        
    }
}


function mapStateToProps(state) {
    return {
        stockPriceAlerts: state.stockPriceAlerts.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAlerts
    }, dispatch);
}

export default injectIntl(connect(mapStateToProps, matchDispatchToProps)(StockPriceAlerts));
