import {GridCell, GridInner} from "@rmwc/grid";
import TableList from "../common/TableList";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import Loader from "../common/Loader";
import React from "react";
import {Button} from "@rmwc/button";
import {FormattedMessage} from "react-intl";

export const MarketingSmsTemplatesList = ({onEditButtonClick}) => {
    const {
        fetching,
        fetched,
        data
    } = useSelector(state => state.smsTemplates);

    const TableHeaders = [
        {label: "MarketingSmsMessages.Templates.TableHeader.DateAdded"},
        {label: "MarketingSmsMessages.Templates.TableHeader.AddedBy"},
        {label: "MarketingSmsMessages.Templates.TableHeader.TemplateContent", colspan: 2}
    ];

    const getEditButton = (item) => <Button
        unelevated
        className="mdc-button--thin mdc-button--tiny"
        onClick={() => onEditButtonClick(item)}>
        <FormattedMessage id="MarketingSmsMessages.Templates.Edit"/>
    </Button>;

    const getTableRow = (item) => {
        return {
            content: [
                {content: moment(item.createdAt).format("DD. MMM yyyy HH:mm")},
                {content: item.createdBy},
                {content: item.content},
                {content: getEditButton(item)}
            ]
        }
    };

    return (
        <GridInner>
            <GridCell span={12}>
                {fetching && <Loader type={Loader.TYPE_COVER}/>}

                {fetched &&
                    <TableList
                        headers={TableHeaders}
                        itemContent={data.map(getTableRow)}
                    />}
            </GridCell>
        </GridInner>
    )
}