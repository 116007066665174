export default function reducer(state={
    data: {},
    fetching: false,
    fetched:false,
    error: null
}, action) {
    switch (action.type) {
        case "FETCH_INVOICE_PAYMENTS_PENDING": {
            return {...state, fetching: true};
        }

        case "FETCH_INVOICE_PAYMENTS_REJECTED": {
            return {...state, fetching: false, error: action.payload};
        }

        case "FETCH_INVOICE_PAYMENTS_FULFILLED": {
            let data = {...state.data};
            let invoiceId = action.meta;
            data[invoiceId] = action.payload.data;
            return {...state, fetching: false, fetched: true, data: data};
        }

        case "CLEAR_INVOICE_PAYMENTS": {
            return {...state, data: {}};
        }

        default:
            return state;
    }
}