import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { FormattedHTMLMessage, injectIntl } from "react-intl";
import { Grid, GridCell } from "@rmwc/grid";

import { fetchCustomerContribution } from "../../actions/communityProjectActions";
import { getDigitalStampsSummary } from "../../actions/digitalStampsActions";

import { CommunityPath, DigitalStampsPath, FamilyGroupPath, NordpoolPath } from "../../const/routes";

import DashboardTile from "./DashboardTile";
import DashboardInvoices from "./DashboardInvoices";
import OverviewBanners from "./OverviewBanners";
import AppLinks from "../../helpers/appLinksHelper";
import {
    redirectToAppContainer,
    redirectToAppContainerCylinderGasView,
    redirectToAppContainerElectricChargerView,
    redirectToAppContainerStationsView
} from "../../helpers/appContainerHelper";
import { Feature } from "flagged";
import {FamilyGroupAsPublic} from "../../const/featureFlags";

class DashboardEe extends React.Component {

    static CREDIT_CARD_BONUS = 0.06;

    constructor(props) {
        super(props);

        this.renderTrailerRentTile = this.renderTrailerRentTile.bind(this);
        this.renderCommunityTile = this.renderCommunityTile.bind(this);
        this.renderElectricChargerTile = this.renderElectricChargerTile.bind(this);
        this.renderPriceAlertTile = this.renderPriceAlertTile.bind(this);
        this.renderRefuelingAppTile = this.renderRefuelingAppTile.bind(this);
    }

    componentDidMount() {
        if (!this.props.customerContributionFetched && !this.props.customerContributionFetching) {
            this.props.fetchCustomerContribution();
        }

        if (!this.props.digitalStampsSummaryFetched && !this.props.digitalStampsSummaryFetching) {
            this.props.getDigitalStampsSummary();
        }
    }

    goToLink(link) {
        if (!link) { return; }

        if (link.startsWith("/")) {
            this.props.history.push(link);
        } else {
            window.location.href = link;
        }
    }

    renderCommunityTile() {
        const {
            customerContributionFetched,
            customerContributionFetching,
            customerContribution,
        } = this.props;

        const isActive = customerContributionFetched && customerContribution && customerContribution.bonusCard && customerContribution.bonusCard.isActive;

        const title = isActive
            ? this.props.intl.formatMessage({ id: "Dashboard.Tiles.Trees" })
            : this.props.intl.formatMessage({ id: "Dashboard.Tiles.Community" });

        return (
            <DashboardTile
                title={title}
                value={isActive ? customerContribution.bonusCard.treesPlanted : null}
                icon="trees"
                background={DashboardTile.BACKGROUND_COMMUNITY}
                arrow={true}
                arrowText={this.props.intl.formatMessage({ id: "Dashboard.Tiles.Join" })}
                isLoading={customerContributionFetching}
                onClick={this.goToLink.bind(this, CommunityPath)}
            />

        );
    }

    renderTrailerRentTile() {
        const link = AppLinks.getHaagisAppLink();

        return (
            <DashboardTile
                title={this.props.intl.formatMessage({ id: "Dashboard.Tiles.RentATrailer" })}
                icon="trailer-rent-2"
                background={DashboardTile.BACKGROUND_TRAILER}
                arrow={true}
                onClick={this.goToLink.bind(this, link)}
            />
        );
    }

    renderElectricChargerTile() {
        const link = AppLinks.getAlexelaAppLink();

        return (
            <DashboardTile
                title={<FormattedHTMLMessage id="Dashboard.Tiles.ElectricCharger" />}
                icon="electricity-full"
                background={DashboardTile.BACKGROUND_CHARGER}
                arrow={true}
                onClick={() => this.props.isCombinedAppContainer
                    ? redirectToAppContainerElectricChargerView()
                    : this.goToLink(link)}
            />
        );
    }

    renderPriceAlertTile() {
        return (
            <DashboardTile
                title={this.props.intl.formatMessage({ id: "Dashboard.Tiles.PriceAlert" })}
                icon="stock-line"
                background={DashboardTile.BACKGROUND_PRICE_ALERT}
                arrow={true}
                onClick={this.goToLink.bind(this, NordpoolPath)}
            />
        );
    }

    renderRefuelingAppTile() {
        const link = AppLinks.getAlexelaAppLink();

        return (
            <DashboardTile
                title={this.props.intl.formatMessage({ id: "Dashboard.Tiles.RefuelingApp" })}
                icon="wallet-new"
                background={DashboardTile.BACKGROUND_REFUEL}
                arrow={true}
                onClick={() => this.props.isCombinedAppContainer
                    ? redirectToAppContainer()
                    : this.goToLink(link)}
            />
        );
    }

    renderDigitalStampsTile() {
        return (
            <DashboardTile
                title={this.props.intl.formatMessage({ id: "Dashboard.Tiles.DigitalStamps" })}
                icon="stamp-filled"
                background={DashboardTile.BACKGROUND_DIGITAL_STAMPS}
                onClick={this.goToLink.bind(this, DigitalStampsPath)}
                value={this.props.digitalStampsSummary?.availableStamps ?? 0}
            />
        );
    }

    renderCylinderGasTile() {
        const link = this.props.intl.formatMessage({ id: "Dashboard.CylinderGas.Url" });

        return (
            <DashboardTile
                title={this.props.intl.formatMessage({ id: "Dashboard.Tiles.CylinderGas" })}
                icon="cylinder-gas-2"
                background={DashboardTile.BACKGROUND_CYLINDERGAS}
                arrow={true}
                onClick={() => this.props.isCombinedAppContainer
                ? redirectToAppContainerCylinderGasView()
                : this.goToLink(link)}
            />
        );
    }

    renderFamilyGroupTile() {
        return (
            <DashboardTile
                title={this.props.intl.formatMessage({ id: "Dashboard.Tiles.FamilyGroup" })}
                icon="members"
                background={DashboardTile.BACKGROUND_FAMILY_GROUP}
                arrow={true}
                onClick={this.goToLink.bind(this, FamilyGroupPath)}
            />
        );
    }

    renderStationsTile() {
        return (
            <DashboardTile
                title={this.props.intl.formatMessage({ id: "Dashboard.Tiles.Stations" })}
                icon="fuel-pump"
                background={DashboardTile.BACKGROUND_STATIONS}
                arrow={true}
                onClick={() => this.props.isCombinedAppContainer
                    ? redirectToAppContainerStationsView()
                    : this.goToLink.bind(this, FamilyGroupPath)}
            />
        );
    }

    render() {
        return (
            <>
                <OverviewBanners isMobileOrTablet /> {/* a bit hacky solution to only show some banners on mobiles and tablets */}
                <Grid className="mdc-layout-grid--base mdc-layout-grid--dashboard mb-25">
                    <GridCell phone={2} tablet={2} desktop={4}>
                        {this.renderDigitalStampsTile()}
                    </GridCell>
                    <GridCell phone={2} tablet={2} desktop={4}>
                        {this.renderCommunityTile()}
                    </GridCell>
                    <GridCell phone={2} tablet={2} desktop={4}>
                        {this.renderRefuelingAppTile()}
                    </GridCell>
                    <GridCell phone={2} tablet={2} desktop={4}>
                        {this.renderElectricChargerTile()}
                    </GridCell>
                    <GridCell phone={2} tablet={2} desktop={4} className="mdc-layout-grid__cell--start-1-tablet">
                        {this.renderCylinderGasTile()}
                    </GridCell>
                    <GridCell phone={2} tablet={2} desktop={4}>
                        <Feature name={FamilyGroupAsPublic}>
                            {(isEnabled) => (isEnabled
                                ? this.renderFamilyGroupTile()
                                : this.renderStationsTile())
                            }
                        </Feature>
                    </GridCell>
                    <GridCell phone={2} tablet={2} desktop={4}>
                        {this.renderTrailerRentTile()}
                    </GridCell>
                    <GridCell phone={2} tablet={2} desktop={4}>
                        {this.renderPriceAlertTile()}
                    </GridCell>
                </Grid>
                <DashboardInvoices />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        customerContributionFetching: state.customerContribution.fetching,
        customerContributionFetched: state.customerContribution.fetched,
        customerContribution: state.customerContribution.data,

        digitalStampsSummary: state.digitalStampsSummary.data,
        digitalStampsSummaryFetching: state.digitalStampsSummary.fetching,
        digitalStampsSummaryFetched: state.digitalStampsSummary.fetched,
        isCombinedAppContainer: state.appContainer.isCombinedAppContainer,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCustomerContribution,
        getDigitalStampsSummary
    }, dispatch);
}

export default injectIntl(
    withRouter(connect(mapStateToProps, matchDispatchToProps)(DashboardEe))
);
