import BusinessAPI from './BusinessApi';
import auth from "../auth/authenticate";

export const FETCH_NORDPOOL_PRICES = "FETCH_NORDPOOL_PRICES";

export const fetchNordpoolPrices = (params) => {
    let roleId = auth.getRoleId();
    let accountId = auth.getUserId();
    return {
        type: FETCH_NORDPOOL_PRICES,
        payload: BusinessAPI.get(`/api/${accountId}/${roleId}/stockdata/nordpool`, { params })
            .then(({ data }) => ({ data, params }))
    }
};