import React, { useMemo, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { GridCell, GridInner } from "@rmwc/grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@rmwc/button";
import moment from "moment";
import Currency from "../common/Currency";
import { Fuel } from "../../const/contractType";
import { Archived } from "../../const/ContractStatus";
import { defaultIconProps } from "../common/Icon";
import { ContractsPath } from "../../const/routes";
import AlertMessage from "../common/AlertMessage";
import { isNullOrEmpty } from "../../helpers/objectHelper";
import TableList from "../common/TableList";
import TitleWithLoader from "../common/TitleWithLoader";
import { GetContractLocale } from "../../helpers/ContractHelper";
import { sortByDate } from "../../helpers/sortHelper";
import { fetchContracts } from "../../actions/contractActions";

const BusinessOverviewContracts = () => {
    const dispatch = useDispatch();

    const {
        data: contracts,
        fetched: contractsFetched,
        fetching: contractsFetching,
        error: contractsError
    } = useSelector(state => state.contracts);

    useEffect(() => {
        if (!contractsFetched && !contractsFetching && !contractsError)
            dispatch(fetchContracts());
    }, [dispatch, contractsFetched, contractsFetching, contractsError]);

    const contractsTableHeaders = [
        { label: "ContractsPage.Contract.Nr" },
        { label: "ContractsPage.Contract.Type" },
        { label: "ContractsPage.Contract.Status" },
        { label: "ContractsPage.Contract.Start" },
        { label: "ContractsPage.Contract.End" },
        { label: "ContractsPage.Contract.LimitBalance" },
        { label: "ContractsPage.Contract.BookingAmount" },
    ];

    const getContractsTableRow = (contract) => {

        let result = {
            content: [
                {
                    content: contract.type === Fuel
                        ? contract.extraNumber
                        : contract.number
                },
                { content: <FormattedMessage id={GetContractLocale(contract.type)} /> },
                {
                    content: <span className={`status-label status-label--${contract.status}`}>
                        <FormattedMessage id={`Contract.Status.${contract.status}`} />
                    </span>
                },
                { content: moment(contract.start).format("DD.MM.YYYY") },
                {
                    content: contract.end
                        ? moment(contract.end).format("DD.MM.YYYY")
                        : <FormattedMessage id="ContractsPage.Contract.NoEnd" />
                },
                {
                    content: contract.type === Fuel
                        ? <Currency value={contract.limitBalance} />
                        : "-"
                },
                {
                    content: contract.type === Fuel
                        ? <Currency value={contract.bookingAmount} />
                        : "-"
                }
            ]
        }

        return result;
    };

    const sortedContracts = useMemo(() =>
        contracts
            .filter(x => x.status !== Archived)
            .sort((a, b) => sortByDate(a, b, "start")),
        [contracts]);

    const newContractLink = <Button tag="a"
        href={ContractsPath}
        className="mdc-button--link"
        trailingIcon={{
            icon: "arrow-right",
            ...defaultIconProps
        }}>
        <FormattedMessage id="BusinessOverview.Contracts.NoItems.Link" />
    </Button>;

    return (

        <GridInner>
            <GridCell span={12}>
                <TitleWithLoader
                    title={<FormattedMessage id="BusinessOverview.Contracts.Title" />}
                    isLoading={contractsFetching}
                    href={ContractsPath}
                    linkText={<FormattedMessage id="BusinessOverview.Contracts.All" />} />
            </GridCell>

            {!contractsFetching && !!contractsError &&
                <GridCell span={9}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        isSmall={true}
                        title={<FormattedMessage id="BusinessOverview.Contracts.Error" />} />
                </GridCell>}

            {contractsFetched &&
                (isNullOrEmpty(contracts)
                    ? <GridCell span={9}>
                        <AlertMessage
                            type={AlertMessage.TYPE_NOTICE}
                            isSmall={true}
                            title={<FormattedMessage id="BusinessOverview.Contracts.NoItems" />}
                            description={newContractLink} />

                    </GridCell>
                    : <GridCell span={12}>
                        <TableList
                            className="mb-0"
                            headers={contractsTableHeaders}
                            itemContent={sortedContracts.map(getContractsTableRow)}
                        />
                    </GridCell>)}
        </GridInner>
    );
}

export default injectIntl(BusinessOverviewContracts);
