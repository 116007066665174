/**
 * Case insensitive search for one string in another
 * @param {String} string to search from
 * @param {String} string to search
 * @returns true if input string contains searchstring
 */
const contains = (input, searchStr) => !!input && input.toLowerCase().includes(searchStr.toLowerCase());

const capitalize = (input) => {
    if (!input) return input;

    if (input.length === 1) return input.toUpperCase();

    return input[0].toUpperCase() + input.substring(1);
}

const isBlank = (str) => !str || /^\s*$/.test(str);

export {
    contains,
    capitalize,
    isBlank
}