import { EnergyType } from "../const/EnergyType";

export function formatUnit(input) {
    if (input === "s") { return "senti"; }
    if (input === "s/kWh") { return "senti/kWh"; }
    return input;
}

function getItems(data, type) {
    const totalAmount = !!data ? data.totalAmount : 0;
    const totalPriceWithVat = !!data ? data.totalPriceWithVat : 0;
    const totalAmountKwh = !!data ? data.totalAmountKwh : 0;

    const priceFormatTotal = !!data ? data.priceFormatTotal : "€"

    if (type === EnergyType.Electricity) {
        const amountFormat = !!data ? data.amountFormat : "kWh"

        return [
            { value: totalAmount, unit: amountFormat },
            { value: totalPriceWithVat, unit: priceFormatTotal }
        ];
    }

    if (type === EnergyType.Gas) {
        const amountFormat = !!data ? data.amountFormat : "m3"

        return [
            { value: totalAmountKwh, unit: "kWh" },
            { value: totalAmount, unit: amountFormat },
            { value: totalPriceWithVat, unit: priceFormatTotal }
        ];
    }
}

export function mapToWidgetItems(data, type, totalsTitle) {
    const items = data.map(x => ({
        name: x.isTotal ? totalsTitle : x.address,
        type: type,
        isTotal: x.isTotal,
        items: getItems(x, type),
    }));

    return items;
}

export const locationsOrderFunc = (a, b) => b.isTotal - a.isTotal || b.name - a.name; // First totals, then alphabetically
