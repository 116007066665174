export default function reducer(
    state = {
        data: {
            verificationCode: null,
            sessionId: null
        },
        fetching: false,
        fetched: false,
        error: null
    },
    action
) {
    switch (action.type) {
        case "POST_FINALIZE_CONTRACT_PENDING": {
            return { ...state, fetching: true };
        }
        case "POST_FINALIZE_CONTRACT_REJECTED": {
            return { ...state, fetching: false, error: action.payload };
        }
        case "POST_FINALIZE_CONTRACT_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload.data
            };
        }
        default:
            return state;
    }
}
