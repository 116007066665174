import { Statuses } from "../const/Invoice";
import moment from "moment";

const isInvoicePaid = (status) => status === Statuses.StatusPaid || status === Statuses.StatusCredited;
const isPaymentPending = (status) => status === Statuses.StatusPaymentPending;
const isDueDateExceeded = (deadline) => moment(deadline).isBefore(moment(), "day");
const isInvoiceNotPaidAndDueDateExceeded = (status, deadline) => !isInvoicePaid(status) && !isPaymentPending(status) && isDueDateExceeded(deadline);
const isInvoiceNotPaidAndDueDateNotExceeded = (status, deadline) => !isInvoicePaid(status) && !isPaymentPending(status) && !isDueDateExceeded(deadline);

export {
    isInvoicePaid,
    isPaymentPending,
    isDueDateExceeded,
    isInvoiceNotPaidAndDueDateExceeded,
    isInvoiceNotPaidAndDueDateNotExceeded
};
