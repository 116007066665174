import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCommunityProjectStatistics, fetchCustomerContribution } from "../../actions/communityProjectActions";
import { isActiveMember } from "../../helpers/communityProjectHelper";
import CommunityContribution from "./CommunityContribution";
import UserContribution from "./UserContribution";
import {
    fetchBasketballProjectStatistics,
    fetchCustomerBasketballProjectContribution
} from "../../actions/basketballCommunityProjectActions";
import {BasketballContribution} from "./BasketballContribution";
import {BasketballUserContribution} from "./BasketballUserContribution";

const OverviewContribution = () => {
    const customerContribution = useSelector(state => state.customerContribution);
    const communityContribution = useSelector(state => state.communityProjectStatistics);
    const customerBasketballContribution = useSelector(state => state.basketballProjectCustomerContribution);
    const basketballCommunityContribution = useSelector(state => state.basketballProjectStatistics);
    const [isCommunityMember, setIsCommunityMember] = useState(false);
    const [isBasketballCommunityMember, setIsBasketballCommunityMember] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!customerContribution.fetched && !customerContribution.fetching && !customerContribution.error) {
            setIsLoading(true);
            dispatch(fetchCustomerContribution());
        }
        if (!communityContribution.fetched && !communityContribution.fetching && !communityContribution.error) {
            setIsLoading(true);
            dispatch(fetchCommunityProjectStatistics());
        }
        if (!customerBasketballContribution.fetched && !customerBasketballContribution.fetching && !customerBasketballContribution.error) {
            setIsLoading(true);
            dispatch(fetchCustomerBasketballProjectContribution());
        }
        if (!basketballCommunityContribution.fetched && !basketballCommunityContribution.fetching && !basketballCommunityContribution.error) {
            setIsLoading(true);
            dispatch(fetchBasketballProjectStatistics());
        }
    }, [dispatch, customerContribution, communityContribution, customerBasketballContribution, basketballCommunityContribution]);

    useEffect(() => {
        if (customerContribution.data) {
            setIsCommunityMember(isActiveMember(customerContribution.data));
        }

        if (customerBasketballContribution.data) {
            setIsBasketballCommunityMember(customerBasketballContribution.data.isActive);
        }
    }, [customerContribution, customerBasketballContribution]);

    useEffect(() => {
        setIsLoading(customerContribution.fetching || communityContribution.fetching || customerBasketballContribution.fetching || basketballCommunityContribution.fetching);
    }, [customerContribution.fetching, communityContribution.fetching, customerBasketballContribution.fetching, basketballCommunityContribution.fetching]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            {isCommunityMember
                ? <UserContribution showLink titleId="Community.TreeCommunity"/>
                : <CommunityContribution showLink titleId="Community.TreeCommunity"/>}
            {isBasketballCommunityMember
                ? <BasketballUserContribution showLink titleId="Community.BasketballCommunity"/>
                : <BasketballContribution showLink titleId="Community.BasketballCommunity"/>}
        </>
    );
}

export default OverviewContribution;