import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import classnames from "classnames";
import { GridCell, GridInner } from "@rmwc/grid";
import { Button } from "@rmwc/button";

import AlertMessage from "../../components/common/AlertMessage";
import Pagination from "../common/Pagination";
import TextFieldGroup from "../common/TextFieldGroup";
import Loader from "../common/Loader";
import TableList from "../common/TableList";

import { fetchPreviousMarketingNotificationsAsync } from "../../actions/notificationActions";
import auth from "../../auth/authenticate";
import { Marketing } from "../../const/notificationType";

const marketingNotificationTypes = [Marketing];

const TableHeaders = [
    { label: "MarketingNotifications.TableHeader.Type" },
    { label: "MarketingNotifications.TableHeader.DateAdded" },
    { label: "MarketingNotifications.TableHeader.RecipientCount" },
    { label: "MarketingNotifications.TableHeader.Title.Estonian", colspan: 3 },
];

const TableHeadersMobile = [
    { label: "MarketingNotifications.TableHeader.DateAdded" },
    { label: "MarketingNotifications.TableHeader.Title.Estonian", colspan: 2 },
];

const MarketingNotificationsList = ({ onReuseButtonClick }) => {

    const [filter, setFilter] = useState({
        page: 1,
        pageSize: auth.getPageSize(),
        text: "",
        types: marketingNotificationTypes
    });

    const dispatch = useDispatch();
    const scrollToRef = useRef();
    const listRef = useRef();

    const {
        fetching,
        fetched,
        data,
        error,
        total
    } = useSelector(state => state.marketingNotifications);

    const hasPreviousNotifications = fetched && data && data.length > 0;
    const hasNoPreviousNotifications = fetched && (!data || data.length <= 0);

    const refresh = () => {
        listRef.current?.clearCache();
        dispatch(fetchPreviousMarketingNotificationsAsync(filter.page, filter.pageSize, filter.text, filter.types));
    };

    const onFilterTextChange = (value) => setFilter({ ...filter, text: value });

    const onPageChange = ({ page, pageSize }) => {
        setFilter({ ...filter, page: page, pageSize: pageSize });
        refresh();
    }

    useEffect(() => {
        if (!fetching && !fetched && !error) {
            refresh();
        }
    });

    const renderFilter = () => <GridInner className={classnames("filter-form", { "filter-form--open": true })}>
        <GridCell desktop={6} tablet={5} phone={4} className="mdc-layout-grid__cell--start-3-desktop">
            <TextFieldGroup
                field="text"
                onChange={e => onFilterTextChange(e.target.value)}
                value={filter.text}
                label="MarketingNotifications.Filter.Text" />
        </GridCell>
        {/* Uncomment when more than one type of notifications */}
        {/* <GridCell desktop={4} tablet={3} phone={4}>
            <Typography
                use="body1"
                className="mdc-typography mdc-typography--body1--bold mb-10">
                <FormattedMessage id="MarketingNotifications.Filter.Type" />
            </Typography>
            {marketingNotificationTypes.map((x, i) =>
                <CheckboxField
                    key={i}
                    className="mr-5"
                    containerClassName="pr-10 mb-10"
                    field={'filter-' + x.value}
                    checked={isTypeSelected(x)}
                    label={intl.formatMessage({ id: `Settings.Notifications.Type.${x}` })}
                    onChange={_ => toggleFilterType(x)}
                />
            )}
        </GridCell> */}
        <GridCell desktop={2} tablet={3} phone={4}>
            <Button
                unelevated
                onClick={refresh}
                disabled={fetching}
                className="right mt-24">
                <i className="icon-wave" />
                <FormattedMessage id="General.Filter" />
            </Button>
        </GridCell>
    </GridInner>;

    const renderRow = (titleId, content, isLast) =>
        <div className={classnames("d-tr", { "border-bottom": !isLast })}>
            <div className="d-td bold p-15">
                <FormattedMessage id={titleId} />
            </div>
            <div className="d-td p-15">
                {content}
            </div>
        </div>;

    const getReuseButton = (item) => <Button
        unelevated
        className="mdc-button--thin mdc-button--tiny"
        onClick={() => onReuseButtonClick(item)}>
        <FormattedMessage id="MarketingNotifications.ReuseText" />
    </Button>;

    const getTableRow = (item) => {
        return {
            content: [
                { content: <FormattedMessage id={`Settings.Notifications.Type.${item.type}`} /> },
                { content: moment(item.createdOn).format("DD. MMM yyyy HH:mm") },
                { content: item.accountNotificationCount || 0 },
                { content: item.titleEt },
                { content: getReuseButton(item) }
            ],
            openedClassName: "no-padding field--colored",
            expanded: [
                {
                    colspan: 6,
                    content:
                        <div className="d-table w-full">
                            {renderRow("MarketingNotifications.TableHeader.Title.Estonian", item.titleEt)}
                            {renderRow("MarketingNotifications.TableHeader.Content.Estonian", item.contentEt)}
                            {renderRow("MarketingNotifications.TableHeader.Title.English", item.titleEn)}
                            {renderRow("MarketingNotifications.TableHeader.Content.English", item.contentEn)}
                            {renderRow("MarketingNotifications.TableHeader.Title.Russian", item.titleRu)}
                            {renderRow("MarketingNotifications.TableHeader.Content.Russian", item.contentRu)}
                            {renderRow("MarketingNotifications.TableHeader.Title.Latvian", item.titleLv)}
                            {renderRow("MarketingNotifications.TableHeader.Content.Latvian", item.contentLv, true)}
                        </div>
                }
            ]
        };
    }

    const getTableRowMobile = (item) => {

        return {
            content: [
                { content: moment(item.createdOn).format("DD. MMM yyyy HH:mm") },
                { content: item.titleEt },
            ],
            openedClassName: "no-padding field--colored",
            expanded: [
                {
                    colspan: 4,
                    content:
                        <div className="d-table w-full">
                            {renderRow("MarketingNotifications.TableHeader.Type", <div className="d-flex d-flex--inline justify-content-between">
                                <FormattedMessage id={`Settings.Notifications.Type.${item.type}`} />
                                {getReuseButton(item)}
                            </div>)}
                            {renderRow("MarketingNotifications.TableHeader.RecipientCount", item.accountNotificationCount || 0)}
                            {renderRow("MarketingNotifications.TableHeader.Title.Estonian", item.titleEt)}
                            {renderRow("MarketingNotifications.TableHeader.Content.Estonian", item.contentEt)}
                            {renderRow("MarketingNotifications.TableHeader.Title.English", item.titleEn)}
                            {renderRow("MarketingNotifications.TableHeader.Content.English", item.contentEn)}
                            {renderRow("MarketingNotifications.TableHeader.Title.Russian", item.titleRu)}
                            {renderRow("MarketingNotifications.TableHeader.Content.Russian", item.contentRu)}
                            {renderRow("MarketingNotifications.TableHeader.Title.Latvian", item.titleLv)}
                            {renderRow("MarketingNotifications.TableHeader.Content.Latvian", item.contentLv, true)}
                        </div>
                }
            ]
        };
    }


    return (
        <>
            <GridInner>
                <GridCell span={12} >
                    {renderFilter()}
                </GridCell>

                <GridCell span={12} style={{ position: "relative" }}>
                    <GridInner>
                        {fetching &&
                            <Loader type={Loader.TYPE_COVER} />}

                        {hasNoPreviousNotifications &&
                            <GridCell span={12} ref={scrollToRef}>
                                <AlertMessage
                                    isSmall={true}
                                    type={AlertMessage.TYPE_NOTICE}
                                    title={<FormattedMessage id="MarketingNotifications.NoPreviousItems" />} />
                            </GridCell>}

                        {hasPreviousNotifications &&
                            <GridCell span={12} ref={scrollToRef}>
                                <TableList
                                    innerRef={listRef}
                                    headers={TableHeaders}
                                    itemContent={data.map(getTableRow)}
                                    mobileHeaders={TableHeadersMobile}
                                    mobileItemContent={data.map(getTableRowMobile)}
                                    selectable={false}
                                    expandable={true}
                                />
                            </GridCell>}

                        <GridCell span={12}>
                            <Pagination
                                total={total || 0}
                                selectedPage={filter.page}
                                onChange={onPageChange}
                                scrollToRef={scrollToRef} />
                        </GridCell>
                    </GridInner>
                </GridCell>
            </GridInner>
        </>
    );
}

export default MarketingNotificationsList;