import auth from "../auth/authenticate";
import { isNullOrEmpty } from "./objectHelper";

const isActiveMember = (customerContribution) => {
    if (isNullOrEmpty(customerContribution)) {
        return false;
    }

    return auth.isCompany()
        ? (!isNullOrEmpty(customerContribution?.contracts) && customerContribution.contracts.findIndex(c => c.isActive === true) !== -1) ||
        (!isNullOrEmpty(customerContribution?.bonusCard) && customerContribution.bonusCard.isActive === true)
        : customerContribution?.bonusCard?.isActive === true;
}

/**
 * Checks if community project for company contract with specified number is active
 * @param {String|Number} contractNumber 
 * @param {Object} customerContribution 
 * @returns true if specified contract is currently associated with a community project
 */
const isActiveMemberByContractNumber = (contractNumber, customerContribution) => {
    if (!contractNumber || isNullOrEmpty(customerContribution) || !auth.isCompany()) {
        return false;
    }

    const number = contractNumber.toString();
    return !isNullOrEmpty(customerContribution?.contracts) &&
        customerContribution.contracts.findIndex(c => c.contractNumber.toString() === number && c.isActive === true) !== -1;
}

export {
    isActiveMember,
    isActiveMemberByContractNumber
}