import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";

import BaseFormComponent from "../common/BaseFormComponent";
import DatePickerRange from "../common/DatePickerRange";

class ElectricityInvoicesFilter extends BaseFormComponent {
    static FieldPeriodStart = "filter.startDate";
    static FieldPeriodEnd = "filter.endDate";

    constructor(props) {
        super(props);

        this.onFilterPeriodStartDateChange = this.onFilterPeriodStartDateChange.bind(this);
        this.onFilterPeriodEndDateChange = this.onFilterPeriodEndDateChange.bind(this);
        this.filter = this.filter.bind(this);

        this.state = {
            filter: {
                ...props.filter,
            }
        };

        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    onFilterPeriodStartDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, startDate: moment(value) }
        }));
    }

    onFilterPeriodEndDateChange(value) {
        this.setState(prev => ({
            filter: { ...prev.filter, endDate: moment(value) }
        }));
    }

    filter() {

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.props.onSubmit(this.state.filter);
    }

    render() {
        const { filter } = this.state;

        return (
            <Grid className={classnames("filter-form", { "filter-form--open": this.props.open })}>
                
                <GridCell desktop={8} tablet={6} phone={4} className="mdc-layout-grid__cell--start-2-desktop">
                    <DatePickerRange
                        label="Invoices.Filter.Period"
                        fieldStart={ElectricityInvoicesFilter.FieldPeriodStart}
                        fieldEnd={ElectricityInvoicesFilter.FieldPeriodEnd}
                        startOnChange={this.onFilterPeriodStartDateChange}
                        endOnChange={this.onFilterPeriodEndDateChange}
                        startValue={filter.startDate.toDate()}
                        endValue={filter.endDate.toDate()}
                        maxStartDate={filter.endDate.toDate()}
                        minEndDate={filter.startDate.toDate()}
                    />
                </GridCell>
                <GridCell desktop={3} tablet={2} phone={4}>
                    <Button unelevated onClick={this.filter} className="right mt-24">
                        <i className="icon-wave" />
                        <FormattedMessage id="General.Filter" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}


ElectricityInvoicesFilter.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    filter: PropTypes.object
};


export default injectIntl(ElectricityInvoicesFilter);

