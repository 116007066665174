import React from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import BaseFormComponent from "../common/BaseFormComponent";
import { confirmPackageChange, extendContract, fetchElmoContractDetails } from "../../actions/contractActions";
import { fetchClientContractConsumptionLocations } from "../../actions/consumptionlocationActions";
import { fetchElectricityProducts, fetchGasProducts } from "../../actions/productsActions";
import { fetchUserStatus } from "../../actions/accountActions";
import { GridCell, Grid } from "@rmwc/grid";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../common/Loader";
import axios from "axios";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import ConsumptionLocationMultiSelect from "../ConsumptionLocations/ConsumptionLocationMultiSelect";
import AlertMessage from "../common/AlertMessage";
import "../../styles/blocks/_contract.scss";
import "../../styles/components/_information.scss";
import { SendActionEvent } from "../../helpers/GoogleTagHelper";
import {Electricity, Gas} from "../../const/contractType";
import auth from "../../auth/authenticate";
import RadioField from "../common/RadioField";
import moment from "moment";
import { ContractTerms, ContractTermsCheckbox } from "../Contract";
import ProductSelection, { getFilterFunctions } from "./ProductSelection";
import {
    ContractProductChangeThankYouPath,
    ContractRenewalThankYouPath,
    ContractsPath,
    getElectricityContractDetailsPath,
    getGasContractDetailsPath
} from "../../const/routes";
import ContractTOS from "../Contract/ContractTOS";
import BackButton from "../common/BackButton";
import { withRouter } from "react-router-dom";
import { postActionLog } from "../../actions/actionLogActions";
import { ActionLogType } from "../../const/actionLogType";
import { trackEvent } from "../../appInsights";

class ProductChange extends BaseFormComponent {
    static fieldRadioContractStartDate = "startDate";
    static fieldProduct = "productId";
    static fieldLocations = "consumptionLocations";
    static fieldContractType = "contractType";
    static fieldTOS = "tos-accept";
    static fieldTOSPenalty = "tos-accept-penalty";

    constructor(props) {
        super(props);
        this.state = {
            [ProductChange.fieldProduct]: null,
            [ProductChange.fieldLocations]: [],
            [ProductChange.fieldContractType]: props.contractType,
            [ProductChange.fieldRadioContractStartDate]: {},
            [ProductChange.fieldTOS]: false,
            [ProductChange.fieldTOSPenalty]: false,
            showErrors: false,
            green: false,
            isAgent: auth.isAgent(),
            isCompany: auth.isCompany(),
            filterFunctions: getFilterFunctions(props.contractType),
            isSelectedProductFixedPriceProductWithFine: false,
            productFilter: "universal_price",
            isRenewal: props.isRenewal,
            userCrmId: auth.getUserId()
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.initProducts = this.initProducts.bind(this);
        this.onStartDateChanged = this.onStartDateChanged.bind(this);
        this.isGreen = this.isGreen.bind(this);
        this.selectDefaultProductTab = this.selectDefaultProductTab.bind(this);
        this.onTOSCheckedChanged = this.onTOSCheckedChanged.bind(this);
        this.onPenaltyTOSCheckedChanged = this.onPenaltyTOSCheckedChanged.bind(this);
        this.addActionLog = this.addActionLog.bind(this);

        this.uiErrorsRef = React.createRef();
        this.cancelProductsSource = null;
        this.validator = new SimpleReactValidator({
            element: false,
            autoForceUpdate: this,
            locale: props.intl.locale
        });
    }

    get renewStartDate() {
        // They suppose to be the same for all consumption locations when extending is allowed. Not nice, but this is how ELMO is.
        return moment(this.props.contract.consumptionLocations[0].product.eleringEndMax).add(1, "day")
    }

    get getContractId() {
        return this.props.contractId;
    }

    componentDidMount() {
        if (Object.keys(this.props.contract).length === 0) {
            this.props.fetchElmoContractDetails(this.getContractId);
        }

        this.props.fetchClientContractConsumptionLocations(this.getContractId);

        if (!this.props.userStatusFetching && !this.props.userStatusFetched) {
            this.props.fetchUserStatus();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            clientContractConsumptionLocations: locations,
            clientContractConsumptionLocationsFetching: locationsFetching,
            clientContractConsumptionLocationsFetched: locationsFetched,
        } = this.props;

        const products = this.getProducts();

        if (locations !== undefined && prevProps.clientContractConsumptionLocations !== locations) {
            if (!locationsFetching && locationsFetched) {
                this.setState({
                    [ProductChange.fieldLocations]: locations.consumptionLocations.map(item => item.id),
                    [ProductChange.fieldRadioContractStartDate]: locations.startDates[0]
                }, this.initProducts);
            }
        }

        if (prevProps.products !== products
            && products && products.length > 0) {
            this.selectDefaultProductTab();
        }
    }

    selectDefaultProductTab() {
        const products = this.getProducts();

        const countOfDifferentPopularityOrderValues = [...products]
            .map(x => x.popularityOrder)
            .filter((value, index, array) => array.indexOf(value) === index) // select distinct
            .length;

        if (countOfDifferentPopularityOrderValues > 1) {
            const mostPopularProduct = [...products]
                .sort((a, b) => a.popularityOrder - b.popularityOrder)[0];

            for (const key in this.state.filterFunctions) {
                if (this.state.filterFunctions[key](mostPopularProduct)) {
                    this.setState({ productFilter: key });
                    break;
                }
            }
        }
    }

    get validatorErrors() {
        let errors = this.validator.getErrorMessages();
        if (!this.state[ProductChange.fieldProduct]) {
            errors["no_products"] = this.props.intl.formatMessage({ id: "Contract.Error.Product" });
        }
        if (!this.state[ProductChange.fieldLocations] || this.state[ProductChange.fieldLocations].length < 1) {
            errors["no_locations"] = this.props.intl.formatMessage({ id: "Contract.Error.Locations" });
        }
        return errors;
    }

    addActionLog(action, extraProps) {
        const { consumptionLocations, number } = this.props.contract;

        this.props.postActionLog({
            action: action,
            additionalData: JSON.stringify({
                contractId: this.getContractId,
                contractNumber: number,
                productCode: consumptionLocations?.[0]?.product?.code,
                productName: consumptionLocations?.[0]?.product?.name,
                productId: consumptionLocations?.[0]?.product?.id,
                ...extraProps
            })
        });
    }

    getStartDate(startDateObj) {
        const { isSelectedProductFixedPriceProductWithFine } = this.state;
        const startDate = isSelectedProductFixedPriceProductWithFine && !!startDateObj.penaltyStartDate
            ? startDateObj.penaltyStartDate
            : startDateObj.startDate;

        return startDate;
    }

    onSubmit() {
        if (!this.validator.allValid()
            || !this.state[ProductChange.fieldProduct]
            || !this.state[ProductChange.fieldLocations]
            || this.state[ProductChange.fieldLocations].length < 1) {
            this.validator.showMessages();
            this.setState({ showErrors: true }, this.scrollToRef.bind(this, this.uiErrorsRef));
            return;
        }

        this.setState({ showErrors: false });

        let changeStart = this.getStartDate(this.state[ProductChange.fieldRadioContractStartDate]);
        if (this.state.isRenewal) {
            changeStart = this.renewStartDate.format("YYYY-MM-DD");
        }

        let data = {
            changeStart: changeStart,
            productId: this.state[ProductChange.fieldProduct],
            contractId: this.getContractId
        };

        const newProduct = this.getProducts().find(x => x.id === data.productId);

        if (this.state.isRenewal) {
            this.props.extendContract(data.productId, data.contractId, data.changeStart)
                .then(() => {
                    SendActionEvent("ElectricityProductSelection");
                    this.addActionLog(ActionLogType.PackageChanged, {
                        isRenewal: true,
                        changeStart: changeStart,
                        newProductId: newProduct?.id,
                        newProductCode: newProduct?.code,
                        newProductName: newProduct?.name
                    });
                    this.props.history.push(ContractRenewalThankYouPath);
                });
        } else {
            this.props.confirmPackageChange(data.productId, data.contractId, data.changeStart)
                .then(() => {
                    SendActionEvent("ElectricityProductSelection");
                    this.addActionLog(ActionLogType.PackageChanged, {
                        isRenewal: false,
                        changeStart: changeStart,
                        newProductId: newProduct?.id,
                        newProductCode: newProduct?.code,
                        newProductName: newProduct?.name
                    });
                    this.props.history.push(ContractProductChangeThankYouPath);
                });
        }
    }

    initProducts() {
        if (this.state[ProductChange.fieldLocations].length > 0) {
            this.cancelProductsSource = axios.CancelToken.source();

            if (this.props.contractType === Gas)
                this.props.fetchGasProducts(this.state[ProductChange.fieldLocations], this.cancelProductsSource.token, null, this.getContractId);
            else
                this.props.fetchElectricityProducts(this.state[ProductChange.fieldLocations], this.cancelProductsSource.token, null, this.getContractId);
        }
    }

    getProducts() {
        if (this.props.contractType === Gas)
            return this.props.productsGas;
        else
            return this.props.products;
    }

    renderLocations() {
        const locationsList = (this.props.clientContractConsumptionLocations.consumptionLocations || [])
            .map((item, i) =>
                <ConsumptionLocationMultiSelect key={i}
                    disabled={true}
                    onChange={this.onChange}
                    fieldName={ProductChange.fieldLocations}
                    index={item.id}
                    location={item}
                    selectedValues={this.state[ProductChange.fieldLocations]}
                    noAddressText={<FormattedMessage id="ContractProductChange.Address.Unknown" />}
                    hideFirstAvailableContractDate={true}
                />
            );

        return (
            <GridCell span={12}>
                <Typography
                    className="mb-25"
                    use="headline3"
                >
                    <FormattedMessage id="ContractProductChange.ConsumptionLocations.Title" />
                </Typography>

                {(this.props.clientContractConsumptionLocationsError || locationsList.length === 0) &&
                    <AlertMessage type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="ContractProductChange.Locations.None" />}
                    />
                }
                <div className="fields-group__list fields-group__list--text">
                    {locationsList}
                </div>
            </GridCell>
        );
    }

    onProductChange(product) {
        const isSelectedProductFixedPriceProductWithFine = product !== null && [product].some(this.state.filterFunctions["fixed_price_fine"]);

        this.setState({
            [ProductChange.fieldProduct]: product !== null ? product.id : null,
            [ProductChange.fieldTOS]: false,
            [ProductChange.fieldTOSPenalty]: false,
            isSelectedProductFixedPriceProductWithFine: isSelectedProductFixedPriceProductWithFine
        });
    }

    changeFilter() {
        this.setState({
            [ProductChange.fieldProduct]: null
        });
    }

    toggleFilter(key) {
        this.setState({
            [key]: !this.state[key],
            [ProductChange.fieldProduct]: null
        });
    }

    toggleDialog(field) {
        this.setState({ [field]: !this.state[field] });
    }

    onStartDateChanged(date) {
        this.setState({ [ProductChange.fieldRadioContractStartDate]: date });
    }

    // Temp solution. Should probably get the date from ELMO
    // Waiting for ELMO solution
    renderContractRenewStartDate() {
        const startDate = this.renewStartDate.format("YYYY-MM-DDT00:00:00");
        return (
            <GridCell span={12}>
                <Typography
                    use="body1"
                >
                    <FormattedMessage id="ContractProductChange.Contract.Start" />
                </Typography>
                <div className="mt-10">
                    <RadioField
                        fieldName={ProductChange.fieldRadioContractStartDate}
                        fieldValue={startDate}
                        fieldLabel={
                            <div className="mdc-typography mt-5 mdc-typography--body1">
                                {this.renewStartDate.format("DD.MM.YYYY")}
                            </div>
                        }
                        selectedValue={startDate}
                        onChange={this.onChangeCheckbox}
                    />
                </div>
            </GridCell>
        )
    }

    renderContractStartDates() {
        const datesList = (this.props.clientContractConsumptionLocations.startDates || [])
            .map((item, i) =>
                <div className="mt-10" key={i}>
                    <RadioField
                        index={i}
                        fieldName={ProductChange.fieldRadioContractStartDate}
                        fieldValue={this.getStartDate(item)}
                        fieldLabel={
                            <div className="mdc-typography mt-5 mdc-typography--body1">
                                <FormattedMessage id={moment(this.getStartDate(item)).format("DD.MM.YYYY")} />
                            </div>
                        }
                        selectedValue={this.getStartDate(this.state[ProductChange.fieldRadioContractStartDate])}
                        onChange={() => this.onStartDateChanged(item)}
                    />
                </div>
            );
        return (
            <GridCell span={12}>
                <Typography
                    use="body1"
                >
                    <FormattedMessage id="ContractProductChange.Contract.Start" />
                </Typography>
                {datesList}
            </GridCell>
        )
    }

    renderErrorMessage() {
        return (
            <Grid>
                <GridCell span={12}>
                    {this.renderBackButton(true)}
                </GridCell>
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_NOTICE}
                        title={<FormattedMessage id="ContractProductChange.Error" />}
                    />
                </GridCell>
            </Grid>
        );
    }

    get fineCostText() {
        return this.state[ProductChange.fieldRadioContractStartDate].fineCostText;
    }

    isGreen() {
        if (this.props.contract.type === Gas)
            return false;

        return moment() >= moment(this.props.contract.greenEnergy.start)
            && (this.props.contract.greenEnergy.end == null || moment() <= moment(this.props.contract.greenEnergy.end));
    }

    isHomeuser() {
        return !this.props.userStatusFetching && this.props.userStatusFetched && this.props.userStatus.isHomeuser;
    }

    onTOSCheckedChanged(event) {
        const isChecked = event.target.checked;

        this.setState({
            [ProductChange.fieldTOS]: isChecked
        });

        const trackedEventName = "electricityProductChangeTOSCheckedChanged";

        trackEvent(trackedEventName, {
            userCrmId: this.state.userCrmId,
            checked: isChecked
        });

        this.addActionLog(ActionLogType.ConfirmCheckboxChecked, {trackedEventName, isChecked});
    }

    onPenaltyTOSCheckedChanged(event) {
        const isChecked = event.target.checked;

        this.setState({
            [ProductChange.fieldTOSPenalty]: isChecked
        });

        const trackedEventName = "electricityProductChangePenaltyProductTOSCheckedChanged";

        trackEvent(trackedEventName, {
            userCrmId: this.state.userCrmId,
            checked: isChecked
        });

        this.addActionLog(ActionLogType.ConfirmCheckboxChecked, {trackedEventName, isChecked});
    }

    renderProductChangeTermsBlock() {
        const products = this.getProducts();
        const productId = this.state[ProductChange.fieldProduct];

        if (productId === null) {
            return null;
        }

        const product = products.find(x => x.id === productId);
        const isPenaltyProduct = product.fineAmount > 0;

        return (
            <>
                {isPenaltyProduct &&
                    <>
                        <ContractTerms termsTitleId="ContractProductChange.PenaltyProduct.TermsTitle" termsTextId="ContractProductChange.PenaltyProduct.TermsText" />
                        <GridCell span={12}>
                            <div className="mdc-form-field">
                                <ContractTermsCheckbox
                                    checked={this.state[ProductChange.fieldTOSPenalty]}
                                    onSetIsTermsAcceptedChanged={this.onPenaltyTOSCheckedChanged}
                                    titleKey="Contract.AcceptTerms.PenaltyProduct"
                                    disabled={this.props.confirmingPackageChange || this.props.extendingContract}
                                />
                            </div>
                        </GridCell>
                    </>
                }

                {!this.state.isAgent &&
                    <GridCell span={12}>
                        <ContractTOS
                            fieldName={ProductChange.fieldTOS}
                            isChecked={this.state[ProductChange.fieldTOS]}
                            onChange={this.onTOSCheckedChanged}
                            isGreen={this.isGreen()}
                            contractType={this.props.contractType}
                            productType={product.type}
                            showBackDownText={false}
                            disabled={this.props.confirmingPackageChange || this.props.extendingContract}
                        />
                    </GridCell>
                }
            </>
        );
    }

    renderProductSelectionBlock() {
        return (
            <>
                <GridCell>
                    <Typography
                        use="headline3"
                        className="mb-15"
                    >
                        <FormattedMessage id="ContractProductChange.Product.Select.New" />
                    </Typography>
                </GridCell>

                {this.state.isCompany &&
                    <GridCell span={12}>
                        <AlertMessage type={AlertMessage.TYPE_WARN}
                            isSmall={true}
                            title={<FormattedMessage id="ContractProductChange.Warning.FineIfLeavingFixedPrice" />} />
                    </GridCell>}

                <ProductSelection
                    onChange={this.onProductChange}
                    products={this.getProducts()}
                    selectedValue={this.state[ProductChange.fieldProduct]}
                    fieldName={ProductChange.fieldProduct}
                    onFilterToggle={this.toggleFilter}
                    onFilterChange={this.changeFilter}
                    contractType={this.props.contractType}
                    selectedProductFilter={this.state.productFilter}
                    showGreenEnergyToggle={false}
                    showNetworkToggle={false}
                    isGreen={this.isGreen()}
                />
            </>
        );
    }

    renderFineCostAlert() {
        if (this.fineCostText === null || this.fineCostText.length === 0) return null;

        return (
            <GridCell span={12}>
                <AlertMessage
                    key={this.fineCostText}
                    type={AlertMessage.TYPE_ALERT}
                    isSmall={true}
                    title={
                        <FormattedMessage
                            id="ContractProductChange.Fine.Alert"
                            values={{
                                fineAmount: this.state[ProductChange.fieldRadioContractStartDate].fineCostText
                            }}
                        />
                    }
                />
            </GridCell>
        );
    }

    renderBackButton(toList = false) {
        if (!toList) {
            const path = this.props.contractType === Electricity
                ? getElectricityContractDetailsPath(this.getContractId)
                : getGasContractDetailsPath(this.getContractId);
            return <BackButton toPath={path} label="ContractProductChange.BackToContractDetails" />;
        }

        return <BackButton toPath={ContractsPath} label="ContractProductChange.BackToContracts" />;
    }

    renderSubmitButton() {
        if (this.props.failedToExtendContract || this.props.rejectedPackageChange) {
            return (
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={<FormattedMessage id="ContractProductChange.Submit.Error" />}
                    />
                </GridCell>
            );
        }

        const product = this.getProducts().find(p => p.id === this.state[ProductChange.fieldProduct]);
        const isPenaltyProduct = product?.fineAmount > 0;
        const areTOSBoxesChecked = isPenaltyProduct
            ? !!this.state[ProductChange.fieldTOS] && !!this.state[ProductChange.fieldTOSPenalty]
            : !!this.state[ProductChange.fieldTOS];

        return (
            <GridCell span={12}>
                <Button className="right"
                    unelevated
                    disabled={
                        this.props.extendingContract ||
                        this.props.confirmingPackageChange ||
                        this.props.failedToExtendContract ||
                        this.props.rejectedPackageChange ||
                        !this.state[ProductChange.fieldProduct] ||
                        !areTOSBoxesChecked ||
                        (this.state.isCompany && !this.isHomeuser())
                    }
                    onClick={this.onSubmit}
                >
                    {this.props.extendingContract || this.props.confirmingPackageChange ?
                        <Loader type={Loader.TYPE_BUTTON} /> :
                        <FormattedMessage
                            id={this.state.isRenewal
                                ? "ContractProductChange.Submit.Renewal"
                                : "ContractProductChange.Submit.Change"}
                        />
                    }
                </Button>
            </GridCell>
        );
    }

    renderProductChangeView(products) {
        const contractChangeAllowed = this.props.clientContractConsumptionLocations.contractChangeAllowed;
        const hasClientChange = this.props.clientContractConsumptionLocations.hasClientChange;
        const allowedToChange = !hasClientChange && contractChangeAllowed;

        return (
            <>
                {products.length > 0 && allowedToChange &&
                    <>
                        {this.state.isRenewal
                            ? this.renderContractRenewStartDate()
                            : this.renderContractStartDates()
                        }

                        {/* Don't know about this */}
                        {!this.state.isRenewal &&
                            this.renderFineCostAlert()
                        }

                        {this.renderProductSelectionBlock()}
                        {this.renderProductChangeTermsBlock()}
                        {this.renderSubmitButton()}
                    </>
                }

                {(products.length === 0 || hasClientChange) &&
                    <GridCell span={12}>
                        <AlertMessage
                            type={AlertMessage.TYPE_ALERT_NEUTRAL}
                            isSmall={true}
                            title={<FormattedMessage id="ContractProductChange.Contact.Client.Service" />}
                        />
                    </GridCell>
                }
                {!contractChangeAllowed &&
                    <GridCell span={12}>
                        <AlertMessage
                            type={AlertMessage.TYPE_ALERT_NEUTRAL}
                            isSmall={true}
                            title={<FormattedMessage id="ContractProductChange.ContractChangeNotAllowed" />}
                        />
                    </GridCell>
                }
            </>
        );
    }

    render() {
        const contractLoaded = Object.keys(this.props.contract).length > 0;

        return (
            <>
                {contractLoaded &&
                    <Grid>
                        <GridCell span={12}>
                            {this.renderBackButton()}
                        </GridCell>
                        <GridCell span={8}>
                            {this.props.failedToExtendContract && this.errors(this.props.failedToExtendContract, "ContractProductChange.Error.Confirm")}
                            {this.state.showErrors && this.errorsFromObject(this.validatorErrors, this.uiErrorsRef)}
                        </GridCell>

                        {this.props.clientContractConsumptionLocationsFetching
                            ? <GridCell span={12}>
                                <Loader type={Loader.TYPE_CENTER} />
                            </GridCell>
                            : this.renderLocations()}

                        {this.props.contractType === Electricity && this.props.clientContractConsumptionLocationsFetched && (this.props.clientContractConsumptionLocations.consumptionLocations || [])
                            .filter(location => location.type === "electricity")
                            .length > 0 && this.state[ProductChange.fieldLocations] && this.state[ProductChange.fieldLocations].length > 0 &&
                            <>
                                {this.props.productsFetching
                                    ? <GridCell span={12}><Loader type={Loader.TYPE_CENTER} /></GridCell>
                                    : this.renderProductChangeView(this.props.products)
                                }
                            </>
                        }

                        {this.props.contractType === Gas && this.props.clientContractConsumptionLocationsFetched && (this.props.clientContractConsumptionLocations.consumptionLocations || [])
                            .filter(location => location.type === "gas")
                            .length > 0 && this.state[ProductChange.fieldLocations] && this.state[ProductChange.fieldLocations].length > 0 &&
                            <>
                                {this.props.productsGasFetching
                                    ? <GridCell span={12}><Loader type={Loader.TYPE_CENTER} /></GridCell>
                                    : this.renderProductChangeView(this.props.productsGas)
                                }
                            </>
                        }
                    </Grid>
                }
                {!contractLoaded && (!!this.props.contractError || !!this.props.clientContractConsumptionLocationsError) &&
                    this.renderErrorMessage()}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        contract: state.elmoContractDetails.data,
        contractFetching: state.elmoContractDetails.fetching,
        contractFetched: state.elmoContractDetails.fetched,
        contractError: state.elmoContractDetails.error,

        products: state.electricityProducts.data,
        productsFetching: state.electricityProducts.fetching,
        productsError: state.electricityProducts.error,

        productsGas: state.gasProducts.data,
        productsGasFetching: state.gasProducts.fetching,
        productsGasError: state.gasProducts.error,

        confirmingPackageChange: state.contractPackageChange.fetching,
        confirmedPackageChange: state.contractPackageChange.fetched,
        rejectedPackageChange: state.contractPackageChange.error,

        extendingContract: state.extendContract.fetching,
        extendedContract: state.extendContract.fetched,
        failedToExtendContract: state.extendContract.error,

        clientContractConsumptionLocations: state.clientContractConsumptionLocations.data,
        clientContractConsumptionLocationsFetching: state.clientContractConsumptionLocations.fetching,
        clientContractConsumptionLocationsFetched: state.clientContractConsumptionLocations.fetched,
        clientContractConsumptionLocationsError: state.clientContractConsumptionLocations.error,

        userStatusFetching: state.userStatus.fetching,
        userStatusFetched: state.userStatus.fetched,
        userStatus: state.userStatus.data,
    };
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchElmoContractDetails,
        fetchElectricityProducts,
        fetchGasProducts,
        fetchClientContractConsumptionLocations,
        confirmPackageChange,
        extendContract,
        fetchUserStatus,
        postActionLog
    }, dispatch);
}

ProductChange.propTypes = {
    contractType: PropTypes.string,
    isRenewal: PropTypes.bool,
    contractId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

ProductChange.defaultProps = {
    contractType: Electricity,
    isRenewal: false,
    contractId: null
};

export default injectIntl(withRouter(connect(mapStateToProps, matchDispatchToProps)(ProductChange)));
