import BusinessApi from "./BusinessApi";
import auth from "../auth/authenticate";
import { InvoicePaymentResponsePath } from "../const/routes";
import { InvoiceType } from "../const/Invoice";
import { directFileDownloadAction } from '../helpers/fileDownloadHelpers';
import moment from "moment";
import {IsLatvianWeb} from "../helpers/AlternativeDesign";

export const FETCH_RECENT_ELECTRICITY_INVOICES = 'FETCH_RECENT_ELECTRICITY_INVOICES';
export const FETCH_ELECTRICITY_INVOICES = 'FETCH_ELECTRICITY_INVOICES';
export const FETCH_PAYMENTCARD_INVOICES = 'FETCH_PAYMENTCARD_INVOICES';
export const FETCH_INVOICE = 'FETCH_INVOICE';
export const FETCH_INVOICE_PAYMENT_METHODS = 'FETCH_INVOICE_PAYMENT_METHODS';
export const FETCH_INVOICE_PAYMENT_STATUS = 'FETCH_INVOICE_PAYMENT_STATUS';
export const DOWNLOAD_INVOICE_PDF = 'DOWNLOAD_INVOICE_PDF';

export const fetchRecentElectricityInvoices = (months = 3) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    const start = moment().subtract(months, 'months').startOf('month').format("YYYY-MM-DD 00:00:00");
    const end = moment().format("YYYY-MM-DD 23:59:59");

    return {
        type: FETCH_RECENT_ELECTRICITY_INVOICES,
        payload: BusinessApi.get(`api/${accountId}/${roleId}/invoice/electricity`, {
            params: {
                periodStart: start,
                periodEnd: end,
                limit: 20,
                pageNr: 1
            }
        })
    }
};

export const fetchElectricityInvoices = (page, limit, start, end) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_ELECTRICITY_INVOICES,
        payload: BusinessApi.get(`api/${accountId}/${roleId}/invoice/electricity`, {
            params: {
                periodStart: start,
                periodEnd: end,
                limit: limit,
                pageNr: page
            }
        })
    };
};

export const fetchPaymentCardInvoices = (page, limit, start, end, deadlineStart, deadlineEnd, invoiceNumber, contractNumber, contractName, status) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_PAYMENTCARD_INVOICES,
        payload: BusinessApi.get(`api/${accountId}/${roleId}/invoice/payment-card`, {
            params: {
                periodStart: start,
                periodEnd: end,
                deadlinePeriodStart: deadlineStart,
                deadlinePeriodEnd: deadlineEnd,
                limit: limit,
                pageNr: page,
                invoiceNumber: invoiceNumber,
                contractNumber: contractNumber,
                contractName: contractName,
                status: status
            }
        })
    };
};

export const fetchPayments = (invoiceId) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: 'FETCH_INVOICE_PAYMENTS',
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/invoice/electricity/${invoiceId}/payments`)

    };
};

export const clearPayments = () => {
    return {
        type: 'CLEAR_INVOICE_PAYMENTS',
    };
};

export const fetchPaymentCardInvoice = (invoiceId, cancelToken) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    let config = {};
    if (cancelToken) {
        config = { cancelToken: cancelToken };
    }

    return {
        type: FETCH_INVOICE,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/invoice/payment-card/${invoiceId}`, config)
    };
};

export const fetchElectricityInvoice = (invoiceId, cancelToken) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    let config = {};
    if (cancelToken) {
        config = { cancelToken: cancelToken };
    }

    return {
        type: FETCH_INVOICE,
        payload: BusinessApi.get(`/api/${accountId}/${roleId}/invoice/electricity/${invoiceId}`, config)
    };
}

export const fetchInvoicePaymentMethods = (invoiceId, invoiceType) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    const baseUrl = window.location.hostname === "localhost" ? "https://testitk.alexela.ee" : window.location.origin;

    const paymentCallbackUrl = encodeURIComponent(`${baseUrl}${InvoicePaymentResponsePath}?invoiceId=${invoiceId}&invoiceType=${invoiceType}`);

    const type = invoiceType === InvoiceType.ElectricityAndGas ? "electricity" : "payment-card";

    const url = `/api/${accountId}/${roleId}/invoice/${type}/${invoiceId}/payment-methods?callbackUrl=${paymentCallbackUrl}`;

    return {
        type: FETCH_INVOICE_PAYMENT_METHODS,
        payload: BusinessApi.get(url, {
            headers: { "PaymentName": getPaymentName(invoiceType) }
        })
    };
};

export const fetchInvoicePaymentStatus = (paymentReference, invoiceType) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();

    return {
        type: FETCH_INVOICE_PAYMENT_STATUS,
        payload: BusinessApi.get(`api/${accountId}/${roleId}/everypay/check-status?paymentReference=${paymentReference}`, {
            headers: { "PaymentName": getPaymentName(invoiceType) }
        })
    };
}

export const downloadPaymentCardInvoicePdf = (invoiceId, invoiceNumber) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/invoice/payment-card/${invoiceId}/pdf`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = !!invoiceNumber
                ? `invoice-${invoiceNumber}.pdf`
                : "invoice.pdf";
            directFileDownloadAction(endpoint, fileName);
        } else {
            dispatch({
                type: DOWNLOAD_INVOICE_PDF,
                payload: BusinessApi.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
}

export const downloadElectricityInvoicePdf = (invoiceId, invoiceNumber) => {
    const roleId = auth.getRoleId();
    const accountId = auth.getUserId();
    const endpoint = `/api/${accountId}/${roleId}/invoice/electricity/${invoiceId}/pdf`;

    return (dispatch, getState) => {
        const {
            appContainer: { usingApp },
        } = getState();

        if (usingApp) {
            const fileName = !!invoiceNumber
                ? `${invoiceNumber}.pdf`
                : "invoice.pdf";
            directFileDownloadAction(endpoint, fileName);
        } else {
            dispatch({
                type: DOWNLOAD_INVOICE_PDF,
                payload: BusinessApi.get(endpoint, {
                    responseType: "arraybuffer"
                })
            });
        }
    }
}

const getPaymentName = (invoiceType) => {
    return invoiceType === InvoiceType.PaymentCard
        ? "AlexelaOcc"
        : IsLatvianWeb()
            ? "AlexelaElmoLv"
            : "AlexelaElmo";
}