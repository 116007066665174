
import { Button } from "@rmwc/button";
import { FormattedMessage } from "react-intl";

import Loader from "../common/Loader";
import AlertMessageInline from "../common/AlertMessageInline";
import { useSelector } from "react-redux";


const DashboardTable = ({ reducerName, errorMessageKey, noItemsMessageKey, moreLink, itemRenderFunction }) => {

    const {
        data: items,
        fetching,
        hasError: hasFetchError
    } = useSelector(state => state[reducerName]);

    const hasItems = !hasFetchError && !fetching && items && items.length > 0;
    const hasNoItems = !hasFetchError && !fetching && (!items || items.length <= 0);

    return (
        <>
            {fetching &&
                <Loader type={Loader.TYPE_CENTER} />}

            {hasFetchError &&
                <AlertMessageInline
                    className="mt-25 mb-15"
                    type={AlertMessageInline.TYPE_ALERT}
                    head={<FormattedMessage id={errorMessageKey} />}
                />}

            {hasNoItems &&
                <AlertMessageInline
                    className="mt-25 mb-15"
                    type={AlertMessageInline.TYPE_NOTICE}
                    head={<FormattedMessage id={noItemsMessageKey} />} />}

            {hasItems &&
                <div className="dashboard-list mt-25">
                    {items.map(itemRenderFunction)}
                </div>}

            {!fetching &&
                <Button
                    href={moreLink}
                    tag="a"
                    className="mdc-button--text mdc-button--secondary mdc-button--bold mdc-button--wide mt-15 mb-15" >
                    <FormattedMessage id="Dashboard.List.More" />
                </Button>}
        </>
    );
}

export default DashboardTable;