const formatPhoneNumber = (phone, addMissingCountryCode = true) => {
    if (!phone || phone === '') return '';

    const r = new RegExp(/(\(?(?:\+|0{2})\d{1,3}\)?)?.*/, "g");
    const result = r.exec(phone);

    if (!result) return phone;

    let  countryCode =  result[1];
    let number = !!countryCode ? phone.replace(countryCode, '').trim() : phone;

    countryCode = !!countryCode
        ? countryCode.replace('(', '').replace(')', '').replace('+', '').replace('00', '')
        : '';
    if (!countryCode && addMissingCountryCode) { countryCode = "372"; }

    number = !!number ? addSpacesToNumber(number) : '';

    return !!countryCode
        ? `(+${countryCode}) ${number} `
        : number;
}

const addSpacesToNumber = (number) => {

    const addSpace = (number, index) => number.substring(0, index) + " " + number.substring(index);

    switch (true) {
        case number.length <= 4:
            return number;
        case number.length === 5:
            return addSpace(number, 2);
        case number.length === 6:
            return addSpace(number, 3);
        case number.length === 7:
            return addSpace(number, 3);
        case number.length === 8:
            return addSpace(number, 4);
        default:
            return number;
    }
}


export { formatPhoneNumber };