import { FETCH_TEST_NOTIFICATIONS } from "../../actions/pushNotificationActions";


export default function reducer(state = {
    data: [],
    total: 0,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case `${FETCH_TEST_NOTIFICATIONS}_PENDING`: {
            return {
                ...state,
                fetching: true
            };
        }
        case `${FETCH_TEST_NOTIFICATIONS}_REJECTED`: {
            return {
                ...state,
                fetching: false,
                error: action.payload
            };
        }
        case `${FETCH_TEST_NOTIFICATIONS}_FULFILLED`: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                data: action.payload.data.items,
                total: action.payload.data.total,
                error: null
            };
        }
        default:
            return state;
    }
}