import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogButton } from "@rmwc/dialog";

import "../../styles/react-components/dialog-base.scss";

class BaseDialog extends React.Component {
    render() {
        return (
            <Dialog
                className={this.props.className ?? "mdc-dialog--info mdc-dialog--scrollable"}
                open={this.props.isOpen}
                onClose={this.props.onClose}
                preventOutsideDismiss={this.props.preventOutsideDismiss}
            >
                <DialogTitle>
                    {this.props.onClose &&
                        <DialogButton
                            icon={{
                                icon: "close",
                                strategy: "className",
                                basename: "icon",
                                prefix: "icon-",
                                className: "mdc-theme--secondary"
                            }}
                            type="button"
                            className="mdc-dialog__button--close uppercase"
                            action="dismiss"
                        ></DialogButton>
                    }
                </DialogTitle>
                <DialogContent>{this.props.children}</DialogContent>
            </Dialog>
        );
    }
}

export default BaseDialog;
