import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import '../../styles/react-components/panel.scss';
import classnames from 'classnames';
import BaseFormComponent from "../common/BaseFormComponent";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import LinkElement from "../common/LinkElement"
import "../../styles/blocks/_product.scss";
import { IsLatvianWeb } from "../../helpers/AlternativeDesign";
import auth from "../../auth/authenticate";
import { isNullOrEmpty } from "../../helpers/objectHelper";

class Product extends BaseFormComponent {
    static FIXED_TYPE = "fixed_price";
    static PAYBACK_TYPE = "payback";
    static STOCK_TYPE = "stock_price";
    static DYNAMIC_TYPE = "dynamic_price";
    static DEGRADE_TYPE = "fix_degrade";
    static UNIVERSAL_TYPE = "universal_price";
    static UNIVERSAL_DYNAMIC_TYPE = "universal_dynamic_price";

    constructor(props) {
        super(props);
        this.state = {
            isCompany: auth.isCompany(),
            isLatvianWeb: IsLatvianWeb()
        }
        this.fieldName = this.props.fieldName;
        this.onProductClick = this.onProductClick.bind(this);
        this.getReadMoreLink = this.getReadMoreLink.bind(this);
    }

    onProductClick(isProductDisabled, product) {
        if (!isProductDisabled) {
            this.props.onChange(product);
        }
    }

    getDayText(product) {
        if (product.type === Product.DEGRADE_TYPE) {
            return this.props.intl.formatMessage({ id: "Contract.Product.Avarage.Day" });
        } else if (!product.zonePrices.night) {
            return this.props.intl.formatMessage({ id: "Contract.Product.Price" });
        } else {
            return this.props.intl.formatMessage({ id: "Contract.Product.Day" });
        }
    }

    getNightText(product) {
        if (product.type === Product.DEGRADE_TYPE) {
            return this.props.intl.formatMessage({ id: "Contract.Product.Avarage.Night" });
        } else {
            return this.props.intl.formatMessage({ id: "Contract.Product.Night" });
        }
    }

    getReadMoreLink(product) {
        if (!this.props.isGas) {
            return this.props.intl.formatMessage({ id: "Contract.Electricity.AlexelaUrl" });
        } else if (product.type === Product.DYNAMIC_TYPE) {
            return this.props.intl.formatMessage({ id: "Contract.Gas2.AlexelaUrl" });
        }

        return this.props.intl.formatMessage({ id: "Contract.Gas.AlexelaUrl" });
    }

    getMonthKey(priceObj, month) {
        return Object.keys(priceObj).find(key => {
            const keyAsDate = new Date(key);
            return month === keyAsDate.getMonth();
        });
    }

    getMonthPrice(priceObj, unit, month) {
        if (!isNullOrEmpty(priceObj)) {
            const monthKey = this.getMonthKey(priceObj, month);

            if (!monthKey) return null;

            return `${priceObj[monthKey]} ${unit}`;
        }

        return null;
    }

    renderDynamicProductPrices(product, panelClass) {
        if (isNullOrEmpty(product.dynamicPrices) && isNullOrEmpty(product.dynamicPricesWithVat)) {
            return null;
        }

        const currentMonth = new Date().getMonth();

        const currentMonthPrice = this.getMonthPrice(product.dynamicPrices, product.priceUnit, currentMonth);
        const currentMonthPriceWithVat = this.getMonthPrice(product.dynamicPricesWithVat, product.priceUnit, currentMonth);
        const nextMonthPrice = this.getMonthPrice(product.dynamicPrices, product.priceUnit, currentMonth + 1);
        const nextMonthPriceWithVat = this.getMonthPrice(product.dynamicPricesWithVat, product.priceUnit, currentMonth + 1);

        return (
            <>
                {currentMonthPrice && (this.state.isCompany || this.state.isLatvianWeb) &&
                    <div className={panelClass + "__row"}>
                        <div className={panelClass + "__cell"}>
                            <FormattedMessage id="Contract.Product.CurrentMonthPrice" />
                        </div>
                        <div className={panelClass + "__cell"}>
                            {currentMonthPrice}
                        </div>
                    </div>
                }
                {currentMonthPriceWithVat && !this.state.isCompany &&
                    <div className={panelClass + "__row"}>
                        <div className={panelClass + "__cell"}>
                            <FormattedMessage id="Contract.Product.CurrentMonthPriceWithVat" />
                        </div>
                        <div className={panelClass + "__cell"}>
                            {currentMonthPriceWithVat}
                        </div>
                    </div>
                }
                {nextMonthPrice && (this.state.isCompany || this.state.isLatvianWeb) &&
                    <div className={panelClass + "__row"}>
                        <div className={panelClass + "__cell"}>
                            <FormattedMessage id="Contract.Product.NextMonthPrice" />
                        </div>
                        <div className={panelClass + "__cell"}>
                            {nextMonthPrice}
                        </div>
                    </div>
                }
                {nextMonthPriceWithVat && !this.state.isCompany &&
                    <div className={panelClass + "__row"}>
                        <div className={panelClass + "__cell"}>
                            <FormattedMessage id="Contract.Product.NextMonthPriceWithVat" />
                        </div>
                        <div className={panelClass + "__cell"}>
                            {nextMonthPriceWithVat}
                        </div>
                    </div>
                }
            </>
        )
    }

    renderProduct(product) {
        const isAgent = auth.isAgent();
        let panelClass = this.props.isGreen
            ? `panel-green${this.props.isLatvian ? '-lv' : ''}`
            : `panel${this.props.isLatvian ? '-lv' : ''}`;

        let { selectedValue, disabled } = this.props;
        let isProductDisabled = disabled || product.disabled

        return (
            <div className={classnames(
                panelClass,
                "d-flex",
                "flex-dir-column",
                {
                    "panel--checked": selectedValue === product.id,
                    "panel--disabled": isProductDisabled
                }
            )}
                onClick={this.onProductClick.bind(this, isProductDisabled, product)}>
                <Typography
                    use={"headline4 " + panelClass + "-title"}
                >
                    {this.props.isGreen
                        ? <FormattedMessage id={"Contract.Product.Name"}
                            values={{
                                productName: product.name,
                                addition: <FormattedMessage id="Contract.Product.GreenEnergyAddition" />
                            }} />
                        : product.name
                    }
                </Typography>
                <div className={panelClass + "__table"}>
                    {/* Product code in agent view */}
                    {product.code && isAgent &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.Code" />
                            </div>
                            <div className={panelClass + "__cell"}>
                                {product.code}
                            </div>
                        </div>
                    }
                    {/* Electricity zones (FIXED PRICE & PAYBACK) */}
                    {product.zonePrices?.day &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                {this.getDayText(product)}
                            </div>
                            {this.props.isGreen ?
                                <div className={panelClass + "__cell"}>
                                    <span>{product.greenDayPrice} {product.priceUnit}</span>
                                </div>
                                :
                                <div className={panelClass + "__cell"}>
                                    <span>{product.zonePrices.day.price} {product.priceUnit}</span>
                                </div>
                            }
                        </div>
                    }

                    {product.zonePrices?.night &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                {this.getNightText(product)}
                            </div>
                            {this.props.isGreen ?
                                <div className={panelClass + "__cell"}>
                                    <span>{product.greenNightPrice} {product.priceUnit}</span>
                                </div>
                                :
                                <div className={panelClass + "__cell"}>
                                    <span>{product.zonePrices.night.price} {product.priceUnit}</span>
                                </div>
                            }
                        </div>
                    }

                    {/* Base price (for universal electricity package) */}
                    {product.basePrice > 0 &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.BasePrice" />
                            </div>
                            <div className={panelClass + "__cell"}>
                                {product.basePrice} {product.priceUnit}
                            </div>
                        </div>
                    }

                    {/* Electricity stock */}
                    {product.marginal &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.Margin" />
                            </div>
                            {this.props.isGreen ?
                                <div className={panelClass + "__cell"}>
                                    {product.greenMarginal}
                                </div>
                                :
                                <div className={panelClass + "__cell"}>
                                    {product.marginal}
                                </div>
                            }
                        </div>
                    }

                    {/* Gas fixed price without VAT */}
                    {this.props.isGas && product.type === Product.FIXED_TYPE && this.state.isLatvianWeb &&
                        <div className="panel__row">
                            <div className="panel__cell">
                                <FormattedMessage id="Contract.Product.PriceWithoutVat" />
                            </div>
                            <div className="panel__cell">{product.unitPriceWithoutVat} {product.priceUnit}</div>
                        </div>
                    }

                    {/* Gas fixed price */}
                    {this.props.isGas && product.type === Product.FIXED_TYPE &&
                        <div className="panel__row">
                            <div className="panel__cell">
                                <FormattedMessage id="Contract.Product.Price" />
                            </div>
                            <div className="panel__cell">{product.unitPrice} {product.priceUnit}</div>
                        </div>
                    }

                    {/* Gas stock price */}
                    {this.props.isGas && product.type === Product.STOCK_TYPE &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.Margin" />
                            </div>
                            <div className={panelClass + "__cell"}>
                                {`${product.stockPriceMarginal > 0 ? product.stockPriceMarginal : 0} ${product.priceUnit}`}
                            </div>
                        </div>
                    }

                    {/* cachback price */}
                    {product.depositAmount > 0 &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.DepositAmount" />
                            </div>
                            <div className={panelClass + "__cell"}>
                                {`${product.depositAmount}€`}
                            </div>
                        </div>
                    }

                    {product.fineAmount > 0 &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.Fine" />
                            </div>
                            <div className={panelClass + "__cell"}>
                                {`${product.fineAmount}€`}
                            </div>
                        </div>
                    }

                    {/* Package duration in months */}
                    {product.durationMonths > 0 && product.type !== Product.UNIVERSAL_TYPE && product.type !== Product.UNIVERSAL_DYNAMIC_TYPE && product.type !== Product.STOCK_TYPE &&
                        <div className={panelClass + "__row"}>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.Duration.Title" />
                            </div>
                            <div className={panelClass + "__cell"}>
                                <FormattedMessage id="Contract.Product.Duration.Value" values={{ durationMonths: product.durationMonths }} />
                            </div>
                        </div>
                    }

                    {/* monthly fee */}
                    <div className={panelClass + "__row"}>
                        <div className={panelClass + "__cell"}>
                            <FormattedMessage id="Contract.Product.MonthlyFee" />
                        </div>
                        <div className={panelClass + "__cell"}>
                            {product.monthlyFee > 0 ? `${product.monthlyFee}€` : "0€"}
                        </div>
                    </div>

                    {this.renderDynamicProductPrices(product, panelClass)}

                </div>
                <div className="product__additional-info">
                    <Typography
                        use="body1"
                        className="product__additional-info__text"
                    >
                        <span className="product__additional-info__text__item">
                            <FormattedMessage id="Contract.Product.MonthlyFee.Desc" />
                        </span>
                        {(product.type === Product.UNIVERSAL_TYPE || product.type === Product.UNIVERSAL_DYNAMIC_TYPE) &&
                            <span className="product__additional-info__text__item">
                                <FormattedMessage id="Contract.Product.CurrentMonthPriceShown" />
                            </span>}
                        <span className="product__additional-info__text__item">
                            <FormattedHTMLMessage id="Contract.Product.VatRateDesc" />
                        </span>
                    </Typography>
                </div>

                {
                    !isProductDisabled ?
                        <>
                            {selectedValue === product.id ?
                                <Button className="panel__button active"
                                    disabled={true}
                                    unelevated >
                                    <FormattedMessage id="Contract.Product.Selected" />
                                </Button>
                                :
                                <Button className="panel__button"
                                    unelevated>
                                    <FormattedMessage id="Contract.Product.Select" />
                                </Button>
                            }
                        </>
                        : <div className='mt-15'>&nbsp;</div>
                }

                <LinkElement
                    target="_blank"
                    href={this.getReadMoreLink(product)}
                >
                    <FormattedMessage id="Contract.ReadMore" />
                </LinkElement>
            </div >
        );
    }

    render() {
        return (
            this.renderProduct(this.props.product)
        );
    }
}

Product.propTypes = {
    fieldName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.string,
    product: PropTypes.object,
    isGas: PropTypes.bool,
    isGreen: PropTypes.bool,
    isLatvian: PropTypes.bool,
    fine: PropTypes.number,
    disabled: PropTypes.bool
};

Product.defaultProps = {
    index: 1,
    aetSelectedValues: [],
    disabled: false
};

export default injectIntl(Product);
