import React from "react";
import {FormattedMessage} from "react-intl";
import {Button} from "@rmwc/button";
import {CommunityPath, AgentCommunityPath} from "../../const/routes";
import auth from "../../auth/authenticate";

const LinkToCommunityPage = ({isUserJoined, overrideLinkHref, linkTextId = "Community.MySettings", joinLinkTextId = "Community.Join"}) => {
    const isAgent = auth.isAgent();

    const getHref = () => {
        if (overrideLinkHref) {
            return overrideLinkHref;
        }

        return isAgent ? AgentCommunityPath : CommunityPath;
    }

    return (
        <Button
            className="mdc-button--secondary mdc-button--text uppercase other filter-form__link"
            tag="a"
            href={getHref()}
        >
            <FormattedMessage id={isUserJoined ? linkTextId : joinLinkTextId}/>
            <i className="icon icon-arrow-right contribution-big-icon"/>
        </Button>
    );
};

export default LinkToCommunityPage;
