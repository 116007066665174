import React from "react";
import { Grid, GridCell } from "@rmwc/grid";
import { Button } from "@rmwc/button";
import { FormattedMessage, injectIntl  } from "react-intl";
import moment from "moment";
import classnames from "classnames";
import PropTypes from "prop-types";
import BaseFormComponent from "../common/BaseFormComponent";
import DatePickerRange from "../common/DatePickerRange";
import SelectField from "../common/SelectField";
import TextFieldGroup from "../common/TextFieldGroup";
import { PaymentTypes } from "../../const/PaymentType";

class PaymentFilter extends BaseFormComponent {
    static FieldAmount = "filter.startAmount";
    static FieldBank = "filter.bankName";
    static FieldContractNumber = "filter.contractNumber";
    static FieldStartDate = "filter.startDate";
    static FieldEndDate = "filter.endDate";
    static FieldPaymentType = "filter.paymentTypeXc";
    static BankOptions = [
        {
            label: "Payment.AllBanks",
            value: ""
        },
        {
            label: "LHV",
            value: "LHV"
        },
        {
            label: "SEB",
            value: "SEB"
        },
        {
            label: "Swedbank",
            value: "Swedbank"
        },
        {
            label: "Danske Bank",
            value: "Danske Bank"
        },
        {
            label: "Luminor Bank",
            value: "Luminor Bank"
        },
        {
            label: "Kontor",
            value: "Kontor"
        },
        {
            label: "EO SEB",
            value: "EO SEB"
        },
        {
            label: "EO Swedbank",
            value: "EO Swedbank"
        },
        {
            label: "EO LHV",
            value: "EO LHV"
        },
        {
            label: "Hansabanka Leedu",
            value: "Hansabanka Leedu (vana)"
        },
        {
            label: "AB Bankas Hansabankas",
            value: "AB Bankas Hansabankas (vana)"
        },
        {
            label: "AS Hansabanka",
            value: "AS Hansabanka (vana)"
        },
        {
            label: "Ofisas",
            value: "Ofisas (vana)"
        },        
    ];

    constructor(props) {
        super(props);

        this.state = {
            filter: {...this.props.filter},

        };

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.filter = this.filter.bind(this);
    }

    onStartDateChange(value) {
        this.setState({
            filter: {...this.state.filter, startDate: moment(value)}
        });
    }
    
    onEndDateChange(value) {
        this.setState({
            filter: {...this.state.filter, endDate: moment(value)}
        });
    }

    filter() {
        this.props.onSubmit(this.state.filter);
    }

    render() {
        const bankOptions = PaymentFilter.BankOptions.map(x => (
            {
                ...x,
                selected: x.value === this.state.filter.bankName,
            }
        ))
        bankOptions[0].label  = this.props.intl.formatMessage({ id: bankOptions[0].label })
        const paymentTypeOptions = PaymentTypes.map(x => (
            {
                value: x.value,
                label: this.props.intl.formatMessage({ id: x.label }),
                selected: x.value === this.state.filter.paymentOptionsXc,
            }
        ))
        const startDate = this.state.filter.startDate.toDate()
        const endDate = this.state.filter.endDate.toDate()
        return (
            <Grid
                className={classnames("filter-form", {
                "filter-form--open": this.props.open,
                })}
            >
                <GridCell desktop={4} tablet={4} >
                    <DatePickerRange
                        label="Payment.Title.Date"
                        fieldStart={PaymentFilter.FieldStartDate}
                        fieldEnd={PaymentFilter.FieldEndDate}
                        startOnChange={this.onStartDateChange}
                        endOnChange={this.onEndDateChange}
                        startValue={startDate}
                        endValue={endDate}
                    />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <TextFieldGroup
                        field={PaymentFilter.FieldAmount}
                        onChange={this.onChange}
                        value={this.state.filter.startAmount || ""}
                        type="number"
                        placeholder={this.props.intl.formatMessage({
                            id: "Payment.Title.Amount"
                        })}
                        label="Payment.Title.Amount" />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <TextFieldGroup
                        field={PaymentFilter.FieldContractNumber}
                        onChange={this.onChange}
                        value={this.state.filter.contractNumber || ""}
                        type="number"
                        placeholder={this.props.intl.formatMessage({
                            id: "Payment.Title.ContractNumber"
                        })}
                        label="Payment.Title.ContractNumber" />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <SelectField
                        field={PaymentFilter.FieldBank}
                        label="Payment.Title.Bank"
                        value={this.state.filter.bankName}
                        onChange={this.onSelectChange}
                        options={bankOptions} />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <SelectField
                        field={PaymentFilter.FieldPaymentType}
                        label="Payment.Title.PaymentType"
                        value={this.state.filter.paymentTypeXc}
                        onChange={this.onSelectChange}
                        options={paymentTypeOptions} />
                </GridCell>
                <GridCell desktop={4} tablet={4}>
                    <Button unelevated onClick={this.filter} className="right mt-25">
                        <i className="icon-wave" />
                        <FormattedMessage id="General.Filter" />
                    </Button>
                </GridCell>
            </Grid>
        );
    }
}

PaymentFilter.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    filter: PropTypes.object,
};

export default injectIntl(PaymentFilter);
