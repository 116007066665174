import { Grid, GridCell } from "@rmwc/grid";
import { useIntl } from "react-intl";
import ConfirmDialog from "../components/common/ConfirmDialog";
import { useEffect, useState } from "react";
import { activatePartnerApi, deactivatePartnerApi, hasPartnerApiAccess } from "../actions/partnerApiActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/common/Loader";
import AlertMessage from "../components/common/AlertMessage";
import PartnerApiForm from "../components/PartnerApi/PartnerApiForm";

const PartnerApi = () => {
    const partnerApiAccessState = useSelector(state => state.partnerApiAccess);
    const activatePartnerApiState = useSelector(state => state.activatePartnerApi);
    const deactivatePartnerApiState = useSelector(state => state.deactivatePartnerApi);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hasPartnerApiAccess());
    }, [dispatch]);

    useEffect(() => {
        if (!activatePartnerApiState.fetching && activatePartnerApiState.fetched && !activatePartnerApiState.error) {
            dispatch(hasPartnerApiAccess());
        }
    }, [dispatch, activatePartnerApiState]);

    useEffect(() => {
        if (!deactivatePartnerApiState.fetching && deactivatePartnerApiState.fetched && !deactivatePartnerApiState.error) {
            dispatch(hasPartnerApiAccess());
        }
    }, [dispatch, deactivatePartnerApiState])

    const renderErrorMessage = () => {
        if (!partnerApiAccessState.error && !activatePartnerApiState.error) {
            return null;
        }

        return (
            <Grid>
                <GridCell span={12}>
                    <AlertMessage
                        type={AlertMessage.TYPE_ALERT}
                        title={intl.formatMessage({ id: "Error.Technical" })}
                    />
                </GridCell>
            </Grid>
        );
    }

    const renderCredentials = () => {
        if (!partnerApiAccessState.data) {
            return null;
        }

        return (
            <PartnerApiForm
                username={partnerApiAccessState.data.userName}
                secret={partnerApiAccessState.data.secret}
                hasPartnerApiAccess={partnerApiAccessState.data.hasAccess}
                onClickButton={() => setIsDialogOpen(true)}
            />
        );
    }

    const onAcceptButtonClicked = () => {
        if (partnerApiAccessState.data.hasAccess) {
            dispatch(deactivatePartnerApi());
        } else {
            dispatch(activatePartnerApi());
        }
        setIsDialogOpen(false);
    }

    const renderDialog = () => {
        const message = partnerApiAccessState.data?.hasAccess
            ? "ContractDetails.Fuel.Confirm.Deactivate"
            : "ContractDetails.Fuel.Confirm.Activate";

        return (
            <ConfirmDialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onAccept={onAcceptButtonClicked}
                onReject={() => setIsDialogOpen(false)}
                text={intl.formatMessage({ id: message })}
            />
        );
    }

    if (partnerApiAccessState.fetching) {
        return <Loader  type={Loader.TYPE_CENTER} />
    }

    return (
        <>
            {renderErrorMessage()}
            {renderCredentials()}
            {renderDialog()}
        </>
    );
}

export default PartnerApi;