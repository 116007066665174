export function renderFormatMobile(mobile) {
    if (!mobile) {
        return '';
    }

    const numbers = mobile.replace('+','');
	const numberLessThanTen = numbers.length < 10
		? { 0: '', 3: ' ', 5: ' ' }
		: { 0: '', 3: ' ', 5: ' ', 8: ' ' };
    const char = (numbers.length === 8) ? { 0: '', 3: ' ' } : numberLessThanTen;
    mobile = '';
    for (let i = 0; i < numbers.length; i++) {
        mobile += (char[i] || '') + numbers[i];
    }
    return '+'+mobile;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// Source: https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
	let timeout;
	return function() {
		const context = this, args = arguments;
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	}
}