import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from "@rmwc/icon";
import LineRipple from '@material/react-line-ripple';
import {injectIntl} from 'react-intl';
import TextFieldGroup from "./TextFieldGroup";
import classnames from 'classnames'
import {HelperText} from '@material/react-text-field';
import ValidationHelper from '../../helpers/validationHelper';
import SelectField from "../common/SelectField"

import '../../styles/react-components/phoneInput.scss';

export const countries = [
    {iconSuffix: "ee", value: "372"},
    {iconSuffix: "lv", value: "371"},
    {iconSuffix: "lt", value: "370"},
    {iconSuffix: "fi", value: "358"},
    {iconSuffix: "se", value: "46"},
    {iconSuffix: "no", value: "47"},
];

class PhoneFieldGroupWithFlags extends React.Component {
    constructor(props) {
        super(props);
        let trimmedPhoneNumber = (this.props.value || "").replace(/^\+/, "");
        let phoneNumberWithoutPrefix = trimmedPhoneNumber.replace(/^(372|371|370|358|46|47)/, "");
        let selectedCountry = countries.find(c => trimmedPhoneNumber.startsWith(c.value)) || countries[0];
        let countryValue = selectedCountry ? selectedCountry.value : null;
        this.state = {
            mobileValue: phoneNumberWithoutPrefix,
            selectedCountry: selectedCountry,
            countryValue: countryValue,
            countryMobileValue: props.value,
            minWidth: null,
            hasLabel: props.hasLabel === false ? false : true
        };

        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.onChangeField = this.onChangeField.bind(this);
    }

    onChangeDropdown(afterChange, field, e) {
        let index = e.detail.index;
        let stateCopy = Object.assign({}, this.state);
        let selectedCountry = countries[index];
        let countryValue = selectedCountry ? selectedCountry.value : null;
        stateCopy.countryValue = countryValue;
        stateCopy.selectedCountry = selectedCountry;
        stateCopy.countryMobileValue = stateCopy.countryValue + stateCopy.mobileValue;
        this.setState(stateCopy);
        this.props.onUpdate(this.props.field, stateCopy.countryMobileValue, afterChange);
    }

    onChangeField(e, callback) {
        let name = e.target.name;
        let val = e.target.value;
        let stateCopy = Object.assign({}, this.state);
        stateCopy.mobileValue = val.trim();
        stateCopy.countryMobileValue = stateCopy.countryValue + stateCopy.mobileValue;
        this.setState(stateCopy);
        this.props.onUpdate(name, stateCopy.countryMobileValue, callback);
    }

    createListItems(selected) {
        let options = countries ? countries.map(
            c => {
                return ({
                    selected: c.value === selected,
                    value: c.value,
                    style: {
                        minWidth: this.state.minWidth ? this.state.minWidth - 4 : 0
                    },
                    label: c.value,
                    className: 'mdc-select--code flag-icon flag-icon--phone flag-icon-' + c.iconSuffix
                })
            }
        ) : [];

        return options;
    }

    render() {
        let createdListItems = this.createListItems(this.state.countryValue);
        let translatedLabel = this.props.intl.formatMessage({id: this.props.label});
        let validationField = ValidationHelper.getValidationField(translatedLabel);
        let validationMessage =this.props.validator.message(validationField, this.state.mobileValue, this.props.rules);
        let validationHelper = validationMessage ?
            <HelperText className="mdc-text-field-helper-text--persistent mdc-theme--error" validation><i className="icon icon-alert-small"></i>{validationMessage}</HelperText>
            : null;
        return (
            <>
                {this.state.hasLabel &&
                    <label>{translatedLabel}{this.props?.rules.indexOf('required') !== -1 ? "*" : ""}</label>
                }
                <div className={classnames("fields-group", {"hidden": this.props.hidden})}>
                    <span>
                        <SelectField
                            field="countryValue"
                            disabled={this.props.disabled} name={this.props.dropDownName}
                            value={this.state.countryValue}
                            onChange={this.onChangeDropdown}
                            leadingIcon={<Icon
                                icon={{
                                    strategy: 'className',
                                    icon: this.state.selectedCountry ?
                                        'flag-icon flag-icon-' + this.state.selectedCountry.iconSuffix :
                                        'mdc-select__dropdown-icon'
                                }}
                            ></Icon>}
                            enhanced
                            menu
                            outlined
                            options={createdListItems}
                            className={classnames("mdc-select--code mdc-element-pre", {"mdc-select--invalid": !!validationMessage})}
                        >
                        </SelectField>
                        <LineRipple/>
                    </span>
                    <TextFieldGroup
                        field={this.props.field}
                        value={this.state.mobileValue}
                        label={this.props.label}
                        onChange={this.onChangeField}
                        autoComplete={this.props.fieldAutoComplete}
                        disabled={this.props.disabled}
                        validator={this.props.validator}
                        rules={this.props.rules}
                        type="number"
                        noLabel={true}
                        showErrorMessage={false}
                        placeholder="1234567"
                        classNames="mdc-element-post"
                    />
                </div>
                {validationHelper}
            </>
        );
    }
}

PhoneFieldGroupWithFlags.propTypes = {
    field: PropTypes.string.isRequired,
    fieldAutoComplete: PropTypes.string,
    onFieldValueChange: PropTypes.func,
    dropDownValue: PropTypes.string,
    label: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDropDownChange: PropTypes.func,
    disabled: PropTypes.bool,
    validator: PropTypes.object,
    rules: PropTypes.string,
    value: PropTypes.string,
    hasLabel: PropTypes.bool,
    hidden: PropTypes.bool
};

export default injectIntl(PhoneFieldGroupWithFlags);
