import { Grid, GridCell } from "@rmwc/grid";
import BaseDialog from "../../components/common/BaseDialog";
import { Typography } from "@rmwc/typography";
import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import { Button } from "@rmwc/button";
import { AppCampaignIllustrationsMap } from "../../helpers/appCampaignHelper";
import { useEffect, useState } from "react";
import { CollapsibleList, SimpleListItem } from "@rmwc/list";
import TextFieldGroup from "../../components/common/TextFieldGroup";
import DatePicker from "../../components/common/DatePicker";
import moment from "moment";
import { insertOrUpdateAppCampaign } from "../../actions/campaignActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { useValidator } from "../../helpers/hooks";

const defaultIllustration = Object.keys(AppCampaignIllustrationsMap)[0];
const imageSize = 50;

const AppCampaignDialog = ({ isOpen, onClose, item, onSubmitted }) => {
    const [isIllustrationListOpen, setIsIllustrationListOpen] = useState(false);
    const [campaignItem, setCampaignItem] = useState();

    const upsertCampaign = useSelector(state => state.upsertAppCampaign);
    const locales = JSON.parse(localStorage.getItem("etmessages"));

    const intl = useIntl();
    const dispatch = useDispatch();
    const [validator] = useValidator({
        alxAppTranslation: {
            message: intl.formatMessage({id: "Campaign.App.Dialog.Validation.AlxAppTranslation"}),
            rule: (val, _, validator) => {
               return validator.helpers.testRegex(val, /^(AlxApp\.).+/);
            }
        },
        keyExists: {
            message: intl.formatMessage({id: "Campaign.App.Dialog.Validation.NoSuchKey"}),
            rule: (val) => {
                console.log(val);
               return Object.keys(locales).indexOf(val) > -1;
            }
        }
    });

    useEffect(() => {
        if (item) {
            setCampaignItem(item);
        }
    }, [item]);

    const onListItemClicked = (illustration) => {
        setCampaignItem(prev => ({ ...prev, illustration }));
        setIsIllustrationListOpen(false);
    }

    const getDate = (unformattedDate) => {
        return unformattedDate ? moment(unformattedDate).toDate() : null;
    }

    const onInsertOrUpdate = () => {
        if (!validator.allValid()) {
            validator.showMessages();
            return;
        }

        if (!campaignItem.illustration) {
            campaignItem.illustration = defaultIllustration;
        }

        campaignItem.validFrom = moment(campaignItem.validFrom).format("YYYY-MM-DD")
        campaignItem.validTo = campaignItem?.validTo ? moment(campaignItem.validTo).format("YYYY-MM-DD") : null;

        dispatch(insertOrUpdateAppCampaign(campaignItem))
            .then(() => onSubmitted?.(true))
            .catch(() => onSubmitted?.(false));

        setCampaignItem(null);
    }

    const onCloseDialog = () => {
        setCampaignItem(null);
        onClose();
    }

    const getCustomErrorLabel = (message) => {
        return (
            <div className="mdc-text-field-helper-line">
                <div className="mdc-text-field-helper-text mdc-text-field-helper-text--validation-msg">
                    <i className="icon icon-alert-small" />
                    {message}
                </div>
            </div>
        );
    }

    if (upsertCampaign.fetching) {
        return <Loader type={Loader.TYPE_CENTER} />;
    }

    return (
        <BaseDialog isOpen={isOpen} onClose={onCloseDialog} className="mdc-dialog--simple app-campaigns">
            <Grid>
                <GridCell span={12}>
                    <Typography use="headline2">
                        {item
                            ? <FormattedMessage id="Campaign.App.Dialog.Title.Edit" />
                            : <FormattedMessage id="Campaign.App.Dialog.Title.New" />
                        }
                    </Typography>
                </GridCell>
                <GridCell span={12}>
                    <FormattedHTMLMessage id="Campaign.App.Instructions" />
                </GridCell>
                <GridCell span={12}>
                    <Typography use="body1" className="icon-label">
                        <FormattedMessage id="Campaign.App.Dialog.Icon" />
                    </Typography>
                    <CollapsibleList
                        handle={
                            <SimpleListItem onClick={() => setIsIllustrationListOpen(true)}>
                                <img
                                    width={imageSize}
                                    height={imageSize}
                                    src={AppCampaignIllustrationsMap[campaignItem?.illustration ?? defaultIllustration]}
                                    alt={campaignItem?.illustration ?? defaultIllustration}
                                />
                            </SimpleListItem>
                        }
                        open={isIllustrationListOpen}
                    >
                        {Object.keys(AppCampaignIllustrationsMap).map((img) => (
                            <SimpleListItem key={img} onClick={() => onListItemClicked(img)}>
                                <img width={imageSize} height={imageSize} src={AppCampaignIllustrationsMap[img]} alt={img} />
                            </SimpleListItem>
                        ))}
                    </CollapsibleList>
                </GridCell>
                <GridCell span={12}>
                    <TextFieldGroup
                        field="textKey"
                        rules="required|alxAppTranslation|keyExists"
                        validator={validator}
                        onChange={(e) => setCampaignItem(prev => ({ ...prev, textKey: e.target.value}))}
                        value={campaignItem?.textKey ?? ""}
                        label="Campaign.App.Dialog.TextKey"
                    />
                </GridCell>
                <GridCell span={12}>
                    <TextFieldGroup
                        field="ctaKey"
                        rules="required|alxAppTranslation|keyExists"
                        validator={validator}
                        onChange={(e) => setCampaignItem(prev => ({ ...prev, ctaKey: e.target.value}))}
                        value={campaignItem?.ctaKey ?? ""}
                        label="Campaign.App.Dialog.CtaKey"
                    />
                </GridCell>
                <GridCell span={12}>
                    <TextFieldGroup
                        field="ctaLinkKey"
                        rules="required|alxAppTranslation|keyExists"
                        validator={validator}
                        onChange={(e) => setCampaignItem(prev => ({ ...prev, ctaLinkKey: e.target.value}))}
                        value={campaignItem?.ctaLinkKey ?? ""}
                        label="Campaign.App.Dialog.CtaLinkKey"
                    />
                </GridCell>
                <GridCell span={12}>
                    <DatePicker
                        field="validFrom"
                        onChange={(e) => setCampaignItem(prev => ({ ...prev, validFrom: e }))}
                        value={getDate(campaignItem?.validFrom)}
                        autoComplete="off"
                        locale={intl.locale}
                        label="Campaign.App.Dialog.ValidFrom"
                    />
                    {validator.message('validFrom', campaignItem?.validFrom, "required", { element: getCustomErrorLabel })}
                </GridCell>
                <GridCell span={12}>
                    <DatePicker
                        field="validTo"
                        onChange={(e) => setCampaignItem(prev => ({ ...prev, validTo: e }))}
                        value={getDate(campaignItem?.validTo)}
                        autoComplete="off"
                        locale={intl.locale}
                        label="Campaign.App.Dialog.ValidTo"
                        isNullable
                    />
                </GridCell>
                <GridCell span={12}>
                    <Button
                        type="submit"
                        unelevated
                        onClick={onInsertOrUpdate}
                        className="mdc-button--primary mt-20"
                    >
                        <FormattedMessage id="Campaign.App.Dialog.SubmitBtn" />
                    </Button>
                </GridCell>
            </Grid>
        </BaseDialog>
    );
}

export default AppCampaignDialog;